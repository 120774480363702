import React, {
  FC,
} from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';

type BudgetSubcategoryHeaderProps = {
  name: string | undefined;
  onDelete: () => void;
};

const BudgetSubcategoryHeader: FC<BudgetSubcategoryHeaderProps> = ({
  name,
  onDelete,
}) => (
  <Stack alignItems="center" direction="row" justifyContent={name ? 'space-between' : 'flex-end'}>
    {Boolean(name) && (<Typography variant="subtitle2">{name}</Typography>)}
    <HIDIconButton
      Icon={DeleteOutline}
      color="blank"
      onClick={onDelete}
    />
  </Stack>
);

export default BudgetSubcategoryHeader;
