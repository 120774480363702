import {
  SxProps,
  alpha,
  colors,
  createTheme,
} from '@mui/material';

const pxToRem = (fontSizeInPx: number) => fontSizeInPx / 16;
const getRem = (fontSizeInPx: number) => `${pxToRem(fontSizeInPx)}rem`;

export const getLargerText = (fontSize: number): SxProps => ({
  fontSize: {
    lg: getRem(fontSize + 2),
    xxs: getRem(fontSize),
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
      premium: '#541566',
      premiumDark: '#380F43',
      transparent: 'transparent',
    },
    primary: {
      lightest: '#F4FBF9',
      lighter: '#E3F7F1',
      light: '#2FA7A7',
      main: '#289595',
      dark: '#227F7F',
    },
    warning: {
      lightest: '#FBF8F4',
      lighter: '#F7EFE3',
      light: '#FFC329',
      main: '#FEB700',
      dark: '#E5A600',
    },
    info: {
      lightest: '#F4F7FB',
      lighter: '#E3ECF7',
      light: '#3E85CB',
      main: '#3378BD',
      dark: '#296BAE',
    },
    error: {
      lightest: '#FBF4F4',
      lighter: '#F7E3E5',
      light: '#F4525F',
      main: '#EE414F',
      dark: '#DC1828',
    },
    shadow: {
      50: alpha(colors.common.black, 0.05),
      100: alpha(colors.common.black, 0.1),
      200: alpha(colors.common.black, 0.2),
      300: alpha(colors.common.black, 0.3),
      400: alpha(colors.common.black, 0.4),
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { size: 'small' },
          style: { padding: '3px 12px' },
        },
        {
          props: { size: 'medium' },
          style: { padding: '7px 24px' },
        },
        {
          props: { size: 'large' },
          style: { padding: '13px 24px' },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '& +.MuiInputBase-root': {
            marginTop: 0,
            paddingTop: '16px',
          },
        },
      },
      defaultProps: {
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: colors.common.black,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.07)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
        },
      },
    },
  },
});

const themeWithTypography = createTheme(theme, {
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: getRem(48),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(45),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(43),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(40),
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: getRem(38),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(36),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(34),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(32),
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: getRem(26),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(26),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(24),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(24),
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: getRem(22),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(22),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(20),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(20),
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: getRem(20),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(20),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(18),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(18),
      },
    },
    h6: {
      fontWeight: 700,
      fontSize: getRem(18),
      [theme.breakpoints.down('lg')]: {
        fontSize: getRem(18),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: getRem(16),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: getRem(16),
      },
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default themeWithTypography;
