import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../utils/date';
import { TaskExpirationTypes } from './types.task';

export const getExpirationString = (expiration: { type: TaskExpirationTypes, date: string } | null) => {
  if (!expiration) {
    return undefined;
  }

  return expiration.type === TaskExpirationTypes.DATE
    ? formatDate(new Date(expiration?.date), DateTimeFormats.DATE_ONLY_TEXT)
    : expiration.type === TaskExpirationTypes.TIME
      ? formatDate(new Date(expiration?.date), DateTimeFormats.DATE_AND_TIME)
      : '';
};
