import React, {
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  throttle,
  debounce,
} from 'throttle-debounce';
import { useEffectOnce } from 'usehooks-ts';

import { FCC } from '../types/common';

export type InfiniteScrollProps = {
  hasMore: boolean;
  isFetching: boolean;
  threshold?: number;
  throttleTime?: number;
  debounceTime?: number;
  render?: ({ sentinel, children }: { sentinel: React.JSX.Element, children: React.ReactNode }) => React.ReactNode;
  onLoadMore: () => void;
};

type CallbackRefProps = {
  onLoadMore: () => void;
  onWindowScroll: () => void;
};

const InfiniteScroll: FCC<InfiniteScrollProps> = ({
  hasMore,
  isFetching,
  threshold = 100,
  throttleTime = 64,
  debounceTime = 250,
  children,
  onLoadMore,
}) => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const callbacksRef = useRef<CallbackRefProps>(null);

  const handleLoadMore = debounce(
    debounceTime,
    () => callbacksRef.current?.onLoadMore(),
    { atBegin: true },
  );

  const handleWindowScroll = () => {
    const fromTop = sentinelRef.current?.getBoundingClientRect()?.top || 0;

    if (!isFetching && hasMore && fromTop - window.innerHeight < threshold) {
      handleLoadMore();
    }
  };

  useImperativeHandle(callbacksRef, () => ({
    onLoadMore,
    onWindowScroll: handleWindowScroll,
  }));

  useEffect(() => {
    const scrollHandler = throttle(throttleTime, () => callbacksRef.current?.onWindowScroll());
    const resizeHandler = throttle(throttleTime, () => callbacksRef.current?.onWindowScroll());

    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  // NOTE: Trigger initial load
  useEffectOnce(handleWindowScroll);

  const sentinel = <div ref={sentinelRef} />;

  return (
    <>
      {children}
      {sentinel}
    </>
  );
};

export default InfiniteScroll;
