import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import * as R from 'ramda';
import {
  DynamicInputCommonField,
  ValidationDataType,
} from '../types/types.dynamicContent';
import {
  parseIntNumber,
  parseFloatNumber,
} from '../../../../../../../utils/string';

type ValidationSchemaSettings = { mandatory: boolean };

const getCustomizableSchema = <TSchema extends Yup.Schema>(
  schema: TSchema,
  { mandatory }: ValidationSchemaSettings,
  positiveIntegerErrorMessage: string,
) => {
  const fieldSchema = schema as unknown as Yup.AnyObjectSchema;
  if (mandatory) {
    return fieldSchema.required(positiveIntegerErrorMessage);
  }
  return fieldSchema.optional();
};

export const useGetValidationDataTypeSchemas = () => {
  const { t } = useTranslation(['forms_common']);

  const numberSchema = Yup
    .number()
    .transform((_, v: string) => !R.isNil(v) ? Number(String(v).replace(/,/, '.')) : undefined)
    .typeError(t('forms_common:forms_common_field_must_be_number'));

  const positiveNumberSchema = numberSchema.positive(t('forms_common:forms_common_positive_integer'));
  const positiveIntegerErrorMessage = t('forms_common:forms_common_positive_integer');

  return {
    [ValidationDataType.POSITIVE_INTEGER]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(positiveNumberSchema, options, positiveIntegerErrorMessage),
    [ValidationDataType.INTEGER]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(numberSchema, options, positiveIntegerErrorMessage),
    [ValidationDataType.DECIMAL]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(numberSchema, options, positiveIntegerErrorMessage),
    [ValidationDataType.PHONE]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(numberSchema, options, t('forms_common:forms_common_required_field_generic')),
    [ValidationDataType.STRING]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(Yup.string(), options, positiveIntegerErrorMessage),
    [ValidationDataType.DATE]: (options: ValidationSchemaSettings) =>
      getCustomizableSchema(Yup.string(), options, positiveIntegerErrorMessage),
    [ValidationDataType.EMAIL]: () =>
      Yup.string().email(t('forms_common:forms_common_email_generic')),
    [ValidationDataType.LIST]: () =>
      Yup.array().min(1, t('forms_common:forms_common_required_field_generic')),
    [ValidationDataType.CURRENCY]: (options: ValidationSchemaSettings) => Yup.object().shape({
      amount: getCustomizableSchema(Yup.string(), options, positiveIntegerErrorMessage),
    }),
  };
};

const parseValue = (value: string | undefined, customParser: (value?: string) => unknown) => value === undefined
  ? undefined
  : value === ''
    ? null
    : customParser(value);

const validationDataParser = {
  [ValidationDataType.POSITIVE_INTEGER]: (value?: string) => parseValue(value, (value) => parseIntNumber(value, 0)),
  [ValidationDataType.INTEGER]: (value?: string) => parseValue(value, (value) => parseIntNumber(value, 0)),
  [ValidationDataType.DECIMAL]: (value?: string) => parseValue(value, (value) => parseFloatNumber(value, 0)),
  [ValidationDataType.STRING]: (value?: string) => parseValue(value, R.identity),
  [ValidationDataType.DATE]: (value?: string) => parseValue(value, R.identity),
  [ValidationDataType.PHONE]: (value?: string) => parseValue(value, R.identity),
  [ValidationDataType.EMAIL]: (value?: string) => parseValue(value, R.identity),
  [ValidationDataType.LIST]: R.identity,
  [ValidationDataType.CURRENCY]: ({ amount, currency }: { amount?: string, currency?: string } = {}) => (
    amount
      ? {
        amount: parseIntNumber(amount, 0),
        currency,
      }
      : null
  ),

};

export const getDynamicInputFieldsData = (fields: Array<DynamicInputCommonField>, values: Record<string, unknown>) => {
  const fieldValidationTypes: Record<string, ValidationDataType> = fields
    .reduce(
      (prev, curr: DynamicInputCommonField) => ({ ...prev, [curr.id]: curr.validationType || ValidationDataType.STRING }),
      {},
    );

  return R.pipe(
    R.toPairs,
    R.map(([key, value]: [string, unknown]) => {
      const validationType = fieldValidationTypes[key];

      if (!validationType) {
        return [key, value] as [string, string];
      }
      const parser = validationDataParser[validationType];
      if (!parser) {
        return [key, value] as [string, string];
      }
      return [key, parser(value as string)] as [string, string];
    }),
    R.fromPairs,
  )(values);
};
