import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HIDClickable from '../../../../../../../components/HIDClickable';
import VerticalImagePlaceholder from '../../../../../../../components/svg/VerticalImagePlaceholder';
import HIDImage from '../../../../../../../components/image/HIDImage';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import Document from '../../../../../../../components/icons/Document';
import { getNoWrapStyle } from '../../../../../../../utils/style';

type SearchResultsListItemProps = {
  entityType: EntityType;
  title: string;
  thumbnailUrl?: string;
  onClick: () => void;
};

const SearchResultsListItem: FC<SearchResultsListItemProps> = ({
  entityType,
  title,
  thumbnailUrl,
  onClick,
}) => {
  const theme = useTheme();
  const getEntityInfo = useGetEntityInfo();
  const entityInfo = getEntityInfo(entityType);
  const Icon = entityInfo?.Icon || Document;

  return (
    <HIDClickable
      spacing={2.5}
      sx={{
        padding: 1.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        minHeight: 210,
      }}
      onClick={onClick}
    >
      {
        thumbnailUrl
          ? (
            <HIDImage
              showSkeleton
              SkeletonIcon={VerticalImagePlaceholder}
              skeletonSx={{
                width: '100%',
                height: 'unset',
                aspectRatio: 3 / 4,
                color: theme.palette.shadow[100],
                borderRadius: 'inherit',
              }}
              sx={{
                width: '100%',
                height: 'unset',
                objectFit: 'cover',
                aspectRatio: 3 / 4,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.palette.grey[200],
                borderRadius: theme.spacing(0.5),
                maxHeight: theme.spacing(15),
              }}
              url={thumbnailUrl}
            />
          )
          : (
            <Stack alignItems="center" justifyContent="center" sx={{ height: theme.spacing(15) }}>
              <Icon size={theme.spacing(10)} />
            </Stack>
          )
      }
      <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
        <Typography align="center" sx={getNoWrapStyle(2)} variant="subtitle2">
          {title}
        </Typography>
      </Stack>
    </HIDClickable>
  );
};

export default SearchResultsListItem;
