import {
  DateTimeFormats,
  formatDate,
} from '../../../utils/date';
import { useGetProperty } from '../api/api.property';
import { PropertyId } from '../types/types.property';

type UseGetPropertyAccessDate = ({ propertyId }: PropertyId) => string | undefined;

const now = new Date();

const useGetPropertyAccessDate: UseGetPropertyAccessDate = ({ propertyId }) => {
  const { data: property } = useGetProperty({ propertyId });

  const accessData = property?.accessDate ? new Date(property?.accessDate) : undefined;

  return accessData && accessData > now
    ? formatDate(accessData, DateTimeFormats.DATE_ONLY_CALENDAR)
    : undefined;
};

export default useGetPropertyAccessDate;
