import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import { FCC } from '../../types/common';

type ChartLegendProps = {
  payload: Array<Payload>;
};

const ChartLegend: FCC<ChartLegendProps> = ({
  payload,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1.5}
      sx={sx}
    >
      {
        payload.map(({ value, color }, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                display: 'inline-block',
                width: 14,
                height: 14,
                borderRadius: 7,
                backgroundColor: color,
              }}
            />
            <Typography
              sx={{ margin: theme.spacing(0, 1) }}
              variant="body1"
            >
              {value}
            </Typography>
          </React.Fragment>
        ))
      }
    </Stack>
  );
};

export default ChartLegend;
