import React, {
  FC,
  PropsWithChildren,
  Suspense,
} from 'react';
import {
  Navigate,
  useLocation,
} from 'react-router';
import { ScrollRestoration } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getActiveAuth } from '../../../external-services/firebase';
import RouteNames from '../../../routes/RouteNames';
import { useRouteParams } from '../../../utils/routes';
import { useGetTokenDataQuery } from '../../Auth/api/api.user';
import HIDFullScreenLoader from '../../../components/HIDFullScreenLoader';
import { getLoginPath } from '../../Auth/navigation/navigation.auth';

type AdminProtectedRouteProps = PropsWithChildren;

const AdminProtectedRoute: FC<AdminProtectedRouteProps> = ({
  children,
}) => {
  const [currentUser] = useAuthState(getActiveAuth());
  const location = useLocation();

  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const params = useRouteParams<{ propertyId?: string }>();
  const routePropertyId = params.propertyId;

  if (!currentUser) {
    return <Navigate replace state={{ from: location, propertyId: routePropertyId }} to={getLoginPath()} />;
  }

  if (!tokenData?.isAdmin) {
    return <Navigate replace to={RouteNames.INIT} />;
  }

  return (
    <Suspense fallback={<HIDFullScreenLoader />}>
      {children}
      <ScrollRestoration />
    </Suspense>
  );
};

export default AdminProtectedRoute;
