import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Database: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Database;
