import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../types/common';
import {
  convertToMb,
  formatBytes,
} from '../../../../../utils/string';
import { getNoWrapStyle } from '../../../../../utils/style';
import StoragePieChart from '../../PropertyStorage/components/StoragePieChart';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import PieChartLoaderSkeleton from '../../../../../components/skeletonLoaders/PieChartLoaderSkeleton';

const useGetPieChartValues = (storageItems: Array<{ totalSize: number, name: string }>, quota: number) => {
  const { t } = useTranslation(['property']);

  const theme = useTheme();

  const storageItemsValue = storageItems
    .map((item) => ({
      name: item.name,
      value: item.totalSize,
    }));

  const storageLeft = quota - R.sum(storageItemsValue.map(({ value }) => value));
  const groupedUsed = storageItemsValue.reduce((acc, { value }) => (value / quota) < 0.01 ? acc + value : acc, 0);
  const bigStorageItemsValue = storageItemsValue.filter(({ value }) => (value / quota) >= 0.01);

  const storageItemsValueWithLeft = [
    ...bigStorageItemsValue.map(({ name, value }) => ({ name, value: convertToMb(value) })),
    groupedUsed > 0 && { name: t('property:other_used_space'), value: convertToMb(groupedUsed) },
    storageLeft > 0 && { name: t('property:free_space'), value: convertToMb(storageLeft), color: theme.palette.primary.lighter },
  ].filter(Boolean);

  return storageItemsValueWithLeft;
};

type StorageWidgetProps = {
  storageItems: Array<{ name: string, totalSize: number }>;
  quota: number;
  isLoading: boolean;
};

const StorageWidget: FCC<StorageWidgetProps> = ({
  storageItems,
  quota,
  isLoading,
}) => {
  const { t } = useTranslation(['common', 'property']);
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  const storageSum = R.sum(storageItems.map(({ totalSize }) => totalSize));

  const pieChartData = useGetPieChartValues(storageItems, quota);

  if (!storageItems.length && !isLoading) {
    return null;
  }

  return (
    <PieChartLoaderSkeleton isLoading={isLoading}>
      <Card
        sx={{
          padding: 2,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          width: '100%',
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={1}
        >
          {Boolean(storageItems.length) && (
            <StoragePieChart data={pieChartData} size={120} />
          )}
          <Stack justifyContent="center">
            <Typography
              sx={getNoWrapStyle(1)}
              variant="subtitle1"
            >
              {t('common:account')}
            </Typography>
            <Typography variant={isDownSm ? 'h6' : 'h3'}>
              {t('property:property_storage_status', {
                used: formatBytes(storageSum),
                available: formatBytes(quota),
              })}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </PieChartLoaderSkeleton>
  );
};

export default StorageWidget;
