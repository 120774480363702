import React, {
  FC,
  JSX,
} from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as R from 'ramda';
import { PropertyProgress } from '@house-id/houseid-types/dist/propertyProgress';

import { HomeIcon } from '../../../../../../components/icons/Icons';
import HIDLinearProgress from '../../../../../../components/progress/HIDLinearProgress';
import { hidSpacing } from '../../../../../../utils/number';
import { getPropertyProgressPath } from '../../../../modules/PropertyProgress/navigation.propertyProgress';
import cupImage from '../../../../../../assets/images/cup.png';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getPropertyProgressPercent } from '../../../../modules/PropertyProgress/utils/utils.propertyProgress';

export enum DoneStages {
  FIRST_STAGE = 0,
  SECOND_STATE = 20,
  THIRD_STAGE = 60,
  FORTH_STAGE = 90,
}

type OverviewSlideProps = {
  propertyProgress?: PropertyProgress;
  SlideHeader: JSX.Element;
};

const OverviewSlide: FC<OverviewSlideProps> = ({
  propertyProgress,
  SlideHeader,
}) => {
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const propertyProgressPercent = getPropertyProgressPercent(propertyProgress?.progress);

  const { t } = useTranslation(['home', 'common']);
  const theme = useTheme();

  const description = R.cond([
    [R.gte(DoneStages.SECOND_STATE), R.always(t('home:home_progress_get_started_and_be_100'))],
    [R.gte(DoneStages.THIRD_STAGE), R.always(t('home:home_progress_well_done_final_push'))],
    [R.gte(DoneStages.FORTH_STAGE), R.always(t('home:home_progress_almost_there'))],
    [R.T, R.always(t('home:home_progress_your_home_is_under_control'))],
  ])(propertyProgressPercent);

  const isDoneState = propertyProgressPercent >= DoneStages.FORTH_STAGE;

  const handleClick = () => propertyId ? navigate(getPropertyProgressPath({ propertyId })) : undefined;

  return (
    <>
      {SlideHeader}
      <Stack
        justifyContent="flex-end"
        sx={{
          flex: 1,
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2.5}
        >
          {
            isDoneState
              ? (
                <Stack
                  alignSelf="flex-start"
                  style={{
                    padding: hidSpacing(2),
                    borderRadius: hidSpacing(5),
                    backgroundColor: theme.palette.warning.lighter,
                  }}
                >
                  <Box
                    component="img"
                    src={cupImage}
                    sx={{
                      width: hidSpacing(6),
                      height: hidSpacing(6),
                    }}
                  />
                </Stack>
              )
              : (
                <Stack
                  alignSelf="flex-start"
                  style={{
                    padding: hidSpacing(3),
                    borderRadius: hidSpacing(5),
                    backgroundColor: theme.palette.primary.lighter,
                  }}
                >
                  <HomeIcon
                    fillColor={theme.palette.common.transparent}
                    iconColor={theme.palette.primary.dark}
                    size={hidSpacing(4)}
                  />
                </Stack>
              )
          }
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            spacing={0.5}
          >
            <Typography variant="h5">
              {`${propertyProgressPercent}% ${t('common:clear').toLowerCase()}`}
            </Typography>
            <Typography variant="body1">
              {description}
            </Typography>
          </Stack>
        </Stack>
        <HIDLinearProgress
          sx={{
            marginTop: 2.5,
            height: 9,
            borderRadius: '5px',
          }}
          value={propertyProgressPercent}
        />
      </Stack>
    </>
  );
};

export default OverviewSlide;
