import React from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import ReceiptCategories from '../modules/Content/modules/Receipt/pages/ReceiptCategories/ReceiptCategories';
import CreateUpdateReceipt from '../modules/Content/modules/Receipt/pages/CreateUpdateReceipt/CreateUpdateReceipt';
import Receipts from '../modules/Content/modules/Receipt/pages/Receipts/Receipts';
import ViewReceipt from '../modules/Content/modules/Receipt/pages/ViewReceipt/ViewReceipt';
import DocumentCategories from '../modules/Content/modules/Document/pages/DocumentCategories';
import Documents from '../modules/Content/modules/Document/pages/Documents';
import ViewDocument from '../modules/Content/modules/Document/pages/ViewDocument/ViewDocument';
import CreateUpdateDocument from '../modules/Content/modules/Document/pages/CreateUpdateDocument/CreateUpdateDocument';
import Photos from '../modules/Content/modules/Photo/pages/Photos';
import ContentFiles from '../modules/Content/modules/ContentFile/pages/ContentFiles/ContentFiles';
import CreateUpdatePhotos from '../modules/Content/modules/Photo/pages/CreateUpdatePhotos';
import Notes from '../modules/Content/modules/Note/pages/Notes';
import ViewNote from '../modules/Content/modules/Note/pages/ViewNote';
import CreateUpdateNote from '../modules/Content/modules/Note/pages/CreateUpdateNote';
import Products from '../modules/Content/modules/Product/pages/Products/Products';
import ViewProduct from '../modules/Content/modules/Product/pages/ViewProduct/ViewProduct';
import CreateUpdateProduct from '../modules/Content/modules/Product/pages/CreateUpdateProduct';
import Timeline from '../modules/Content/modules/Timeline/pages/Timeline/Timeline';
import CreateUpdateTimelineEvent from '../modules/Content/modules/Timeline/pages/CreateTimelineEvent/CreateUpdateTimelineEvent';
import SuggestionCategories from '../modules/Content/modules/Suggestion/pages/SuggestionCategories';
import Suggestions from '../modules/Content/modules/Suggestion/pages/Suggestions';
import SuggestionsSettings from '../modules/Content/modules/Suggestion/pages/SuggestionsSettings';
import ViewTimelineEvent from '../modules/Content/modules/Timeline/pages/ViewTimelineEvent/ViewTimelineEvent';
import PropertyBlueprints from '../modules/PropertyProgress/pages/PropertyBlueprints/PropertyBlueprints';
import PropertyDocumentation from '../modules/PropertyProgress/pages/PropertyDocumentation/PropertyDocumentation';
import PropertyFinance from '../modules/PropertyProgress/pages/PropertyFinance/PropertyFinance';
import PropertyInsuranceAndSafety from '../modules/PropertyProgress/pages/PropertyInsuranceAndSafety/PropertyInsuranceAndSafety';
import PropertyToDo from '../modules/PropertyProgress/pages/PropertyToDo/PropertyToDo';
import PropertyPlanning from '../modules/PropertyProgress/pages/PropertyPlanning/PropertyPlanning';
import PropertyProducts from '../modules/PropertyProgress/pages/PropertyProducts/PropertyProducts';
import PropertyProgress from '../modules/PropertyProgress/pages/PropertyProgress/PropertyProgress';
import PropertyPurchase from '../modules/PropertyProgress/pages/PropertyPurchase/PropertyPurchase';
import PropertyHome from '../pages/PropertyHome/PropertyHome';
import PropertySpecification from '../modules/PropertyProgress/pages/PropertySpecifications/PropertySpecification';
import PropertySettings from '../pages/PropertySettings/PropertySettings';
import Feedback from '../../Auth/pages/Feedback';
import Messages from '../modules/Content/modules/Message/pages/Messages';
import ViewMessage from '../modules/Content/modules/Message/pages/ViewMessage/ViewMessage';
import ExternalServices from '../pages/ExternalServices/ExternalServices';
import PropertyStorage from '../pages/PropertyStorage/PropertyStorage';
import UserSettings from '../../Auth/pages/userSettings/UserSettings';
import ResetPassword from '../../Auth/pages/ResetPassword';
import UserDetails from '../../Auth/pages/UserDetails';
import SearchResults from '../modules/Content/modules/Search/pages/SearchResults';
import PropertyPermission from '../constants/constants.propertyPermissions';
import SellingPropertyChecklist from '../modules/SellingProperty/pages/SellingPropertyChecklist/SellingPropertyChecklist';
import SellingPropertyInformation from '../modules/SellingProperty/pages/SellingPropertyInformation/SellingPropertyInformation';
import SellingPropertyArchive from '../modules/SellingProperty/pages/SellingPropertyArchive/SellingPropertyArchive';
import SellingPropertyHandover from '../modules/SellingProperty/pages/SellingPropertyHandover/SellingPropertyHandover';
import MyTotalBonusPoints from '../modules/BonusOffers/pages/MyTotalBonusPoints/MyTotalBonusPoints';
import BonusOffersLogs from '../modules/BonusOffers/pages/BonusOffersLogs/BonusOffersLogs';
import HouseIdBonusOffers from '../modules/BonusOffers/pages/HouseIdBonusOffers/HouseIdBonusOffers';
import MoveInBonusOffers from '../modules/BonusOffers/pages/MoveInBonusOffers/MoveInBonusOffers';
import BonusOfferDetails from '../modules/BonusOffers/pages/BonusOfferDetails/BonusOfferDetails';
import FinancesOverview from '../modules/Finances/pages/financeOverview/FinancesOverview';
import RecurringExpensesCategories from '../modules/Finances/modules/RecurringExpenses/pages/RecurringExpensesCategories';
import BudgetOverview from '../modules/Finances/modules/Budgets/pages/BudgetOverview/BudgetOverview';
import CreateUpdateBudget from '../modules/Finances/modules/Budgets/pages/CreateUpdateBudget/CreateUpdateBudget';
import RecurringExpenses from '../modules/Finances/modules/RecurringExpenses/pages/RecurringExpenses';
import ManageRecurringExpensesBankAccounts
  from '../modules/Finances/modules/RecurringExpenses/pages/manageRecurringExpensesBankAccounts/ManageRecurringExpensesBankAccounts';
import RecurringExpenseTransactions from '../modules/Finances/modules/RecurringExpenses/pages/RecurringExpenseTransactions';
import CreateUpdateExpense from '../modules/Finances/modules/RecurringExpenses/pages/CreateUpdateExpense';
import BudgetCategoryPage from '../modules/Finances/modules/Budgets/pages/BudgetCategory/BudgetCategory';

const HomeRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.PROPERTY_HOME,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_HOME}
        propertyChangePath={RouteNames.PROPERTY_HOME}
      >
        <PropertyHome />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECEIPTS_CATEGORIES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECEIPTS_CATEGORIES}
        propertyChangePath={RouteNames.RECEIPTS_CATEGORIES}
      >
        <ReceiptCategories />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECEIPTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECEIPTS}
        propertyChangePath={RouteNames.RECEIPTS}
      >
        <Receipts />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECEIPT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECEIPT}
        propertyChangePath={RouteNames.RECEIPTS}
      >
        <ViewReceipt />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECEIPT_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECEIPT_CREATE}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.RECEIPTS}
      >
        <CreateUpdateReceipt />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECEIPT_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECEIPT_UPDATE}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.RECEIPTS}
      >
        <CreateUpdateReceipt />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DOCUMENTS_CATEGORIES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.DOCUMENTS_CATEGORIES}
        propertyChangePath={RouteNames.DOCUMENTS_CATEGORIES}
      >
        <DocumentCategories />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DOCUMENTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.DOCUMENTS}
        propertyChangePath={RouteNames.DOCUMENTS}
      >
        <Documents />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DOCUMENT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.DOCUMENT}
        propertyChangePath={RouteNames.DOCUMENTS}
      >
        <ViewDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DOCUMENT_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.DOCUMENT_CREATE}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.DOCUMENTS}
      >
        <CreateUpdateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DOCUMENT_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.DOCUMENT_UPDATE}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.DOCUMENTS}
      >
        <CreateUpdateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.CONTENT_FILES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.CONTENT_FILES}
        propertyChangePath={RouteNames.CONTENT_FILES}
      >
        <ContentFiles />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PHOTOS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PHOTOS}
        propertyChangePath={RouteNames.PHOTOS}
      >
        <Photos />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PHOTOS_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PHOTOS_CREATE}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.PHOTOS}
      >
        <CreateUpdatePhotos />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PHOTOS_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PHOTOS_UPDATE}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.PHOTOS}
      >
        <CreateUpdatePhotos />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.NOTES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.NOTES}
        propertyChangePath={RouteNames.NOTES}
      >
        <Notes />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.NOTE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.NOTE}
        propertyChangePath={RouteNames.NOTES}
      >
        <ViewNote />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.NOTE_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.NOTE_CREATE}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.NOTES}
      >
        <CreateUpdateNote />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.NOTE_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.NOTE_UPDATE}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.NOTES}
      >
        <CreateUpdateNote />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PRODUCTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PRODUCTS}
        propertyChangePath={RouteNames.PRODUCTS}
      >
        <Products />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PRODUCT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PRODUCT}
        propertyChangePath={RouteNames.PRODUCTS}
      >
        <ViewProduct />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PRODUCT_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PRODUCT_CREATE}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.PRODUCTS}
      >
        <CreateUpdateProduct />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PRODUCT_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PRODUCT_UPDATE}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.PRODUCTS}
      >
        <CreateUpdateProduct />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.TIMELINE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.TIMELINE}
        propertyChangePath={RouteNames.TIMELINE}
      >
        <Timeline />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.TIMELINE_EVENT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.TIMELINE_EVENT}
        propertyChangePath={RouteNames.TIMELINE}
      >
        <ViewTimelineEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.TIMELINE_CREATE_EVENT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.TIMELINE_CREATE_EVENT}
        permissions={[PropertyPermission.CAN_CREATE]}
        propertyChangePath={RouteNames.TIMELINE}
      >
        <CreateUpdateTimelineEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.TIMELINE_UPDATE_EVENT,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.TIMELINE_UPDATE_EVENT}
        propertyChangePath={RouteNames.TIMELINE}
      >
        <CreateUpdateTimelineEvent />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUGGESTION_CATEGORIES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SUGGESTION_CATEGORIES}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.SUGGESTION_CATEGORIES}
      >
        <SuggestionCategories />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUGGESTIONS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SUGGESTIONS}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.SUGGESTIONS}
      >
        <Suggestions />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUGGESTION_SETTINGS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SUGGESTION_SETTINGS}
        permissions={[PropertyPermission.CAN_UPDATE]}
        propertyChangePath={RouteNames.SUGGESTION_SETTINGS}
      >
        <SuggestionsSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_PROGRESS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_PROGRESS}
        propertyChangePath={RouteNames.PROPERTY_PROGRESS}
      >
        <PropertyProgress />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_PROGRESS_SPECIFICATION,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_PROGRESS_SPECIFICATION}
        propertyChangePath={RouteNames.PROPERTY_PROGRESS_SPECIFICATION}
      >
        <PropertySpecification />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_BLUEPRINTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_BLUEPRINTS}
        propertyChangePath={RouteNames.PROPERTY_BLUEPRINTS}
      >
        <PropertyBlueprints />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_PURCHASE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_PURCHASE}
        propertyChangePath={RouteNames.PROPERTY_PURCHASE}
      >
        <PropertyPurchase />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_TODO,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_TODO}
        propertyChangePath={RouteNames.PROPERTY_TODO}
      >
        <PropertyToDo />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_PLANNING,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_PLANNING}
        propertyChangePath={RouteNames.PROPERTY_PLANNING}
      >
        <PropertyPlanning />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_DOCUMENTATION,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_DOCUMENTATION}
        propertyChangePath={RouteNames.PROPERTY_DOCUMENTATION}
      >
        <PropertyDocumentation />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_PRODUCTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_PRODUCTS}
        propertyChangePath={RouteNames.PROPERTY_PRODUCTS}
      >
        <PropertyProducts />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_INSURANCE_AND_SAFETY,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_INSURANCE_AND_SAFETY}
        propertyChangePath={RouteNames.PROPERTY_INSURANCE_AND_SAFETY}
      >
        <PropertyInsuranceAndSafety />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_FINANCE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_FINANCE}
        propertyChangePath={RouteNames.PROPERTY_FINANCE}
      >
        <PropertyFinance />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_SETTINGS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_SETTINGS}
        propertyChangePath={RouteNames.PROPERTY_SETTINGS}
      >
        <PropertySettings />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.EXTERNAL_SERVICES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.EXTERNAL_SERVICES}
        propertyChangePath={RouteNames.EXTERNAL_SERVICES}
      >
        <ExternalServices />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SELLING_PROPERTY_CHECKLIST,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SELLING_PROPERTY_CHECKLIST}
        propertyChangePath={RouteNames.SELLING_PROPERTY_CHECKLIST}
      >
        <SellingPropertyChecklist />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SELLING_PROPERTY_INFO,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SELLING_PROPERTY_INFO}
        propertyChangePath={RouteNames.SELLING_PROPERTY_INFO}
      >
        <SellingPropertyInformation />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SELLING_PROPERTY_ARCHIVE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SELLING_PROPERTY_ARCHIVE}
        propertyChangePath={RouteNames.SELLING_PROPERTY_ARCHIVE}
      >
        <SellingPropertyArchive />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SELLING_PROPERTY_TRANSFER,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SELLING_PROPERTY_TRANSFER}
        propertyChangePath={RouteNames.SELLING_PROPERTY_TRANSFER}
      >
        <SellingPropertyHandover />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BONUS_OFFERS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BONUS_OFFERS}
        propertyChangePath={RouteNames.BONUS_OFFERS}
      >
        <HouseIdBonusOffers />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BONUS_OFFERS_FROM_MOVE_IN,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BONUS_OFFERS_FROM_MOVE_IN}
        propertyChangePath={RouteNames.BONUS_OFFERS_FROM_MOVE_IN}
      >
        <MoveInBonusOffers />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BONUS_OFFERS_MY_BONUS_POINTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BONUS_OFFERS_MY_BONUS_POINTS}
        propertyChangePath={RouteNames.BONUS_OFFERS_MY_BONUS_POINTS}
      >
        <MyTotalBonusPoints />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BONUS_OFFERS_LOGS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BONUS_OFFERS_LOGS}
        propertyChangePath={RouteNames.BONUS_OFFERS_LOGS}
      >
        <BonusOffersLogs />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BONUS_OFFER_DETAILS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BONUS_OFFER_DETAILS}
        propertyChangePath={RouteNames.BONUS_OFFER_DETAILS}
      >
        <BonusOfferDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PROPERTY_STORAGE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.PROPERTY_STORAGE}
        propertyChangePath={RouteNames.PROPERTY_STORAGE}
      >
        <PropertyStorage />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.MESSAGES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.MESSAGES}
        propertyChangePath={RouteNames.MESSAGES}
      >
        <Messages />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.MESSAGE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.MESSAGE}
        propertyChangePath={RouteNames.MESSAGE}
      >
        <ViewMessage />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.USER_SETTINGS,
    element: (
      <ProtectedRoute
        path={RouteNames.USER_SETTINGS}
        propertyChangePath={RouteNames.USER_SETTINGS}
      >
        <UserSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FEEDBACK,
    element: (
      <ProtectedRoute
        path={RouteNames.FEEDBACK}
        propertyChangePath={RouteNames.FEEDBACK}
      >
        <Feedback />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RESET_PASSWORD,
    element: (
      <ProtectedRoute
        path={RouteNames.RESET_PASSWORD}
        propertyChangePath={RouteNames.RESET_PASSWORD}
      >
        <ResetPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.USER_DETAILS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.USER_DETAILS}
        propertyChangePath={RouteNames.USER_DETAILS}
      >
        <UserDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SEARCH_RESULTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.SEARCH_RESULTS}
        propertyChangePath={RouteNames.SEARCH_RESULTS}
      >
        <SearchResults />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FINANCES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.FINANCES}
        propertyChangePath={RouteNames.FINANCES}
      >
        <FinancesOverview />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECURRING_EXPENSES_CATEGORIES,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECURRING_EXPENSES_CATEGORIES}
        propertyChangePath={RouteNames.RECURRING_EXPENSES_CATEGORIES}
      >
        <RecurringExpensesCategories />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECURRING_EXPENSES_CATEGORY,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECURRING_EXPENSES_CATEGORY}
        propertyChangePath={RouteNames.RECURRING_EXPENSES_CATEGORY}
      >
        <RecurringExpenses />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECURRING_EXPENSES_BANK_ACCOUNTS,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECURRING_EXPENSES_BANK_ACCOUNTS}
        propertyChangePath={RouteNames.RECURRING_EXPENSES_BANK_ACCOUNTS}
      >
        <ManageRecurringExpensesBankAccounts />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECURRING_EXPENSE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECURRING_EXPENSE}
        propertyChangePath={RouteNames.RECURRING_EXPENSE}
      >
        <RecurringExpenseTransactions />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RECURRING_EXPENSE_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.RECURRING_EXPENSE_UPDATE}
        propertyChangePath={RouteNames.RECURRING_EXPENSE_UPDATE}
      >
        <CreateUpdateExpense />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BUDGETS_OVERVIEW,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BUDGETS_OVERVIEW}
        propertyChangePath={RouteNames.BUDGETS_OVERVIEW}
      >
        <BudgetOverview />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BUDGET_CATEGORY,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BUDGET_CATEGORY}
        propertyChangePath={RouteNames.BUDGETS_OVERVIEW}
      >
        <BudgetCategoryPage />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BUDGETS_CREATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BUDGETS_CREATE}
        propertyChangePath={RouteNames.BUDGETS_CREATE}
      >
        <CreateUpdateBudget />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.BUDGETS_UPDATE,
    element: (
      <ProtectedRoute
        shouldCheckTrialPeriod
        path={RouteNames.BUDGETS_UPDATE}
        propertyChangePath={RouteNames.BUDGETS_UPDATE}
      >
        <CreateUpdateBudget />
      </ProtectedRoute>
    ),
  },
];

export default HomeRouteConfig;
