import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Logout,
  MailOutline,
  PersonOutline,
  SmsOutlined,
  SupervisorAccountRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useAuthState } from 'react-firebase-hooks/auth';
import { skipToken } from '@reduxjs/toolkit/query';
import { Language } from '@house-id/houseid-types/dist/common';

import { getActiveAuth } from '../../../../../external-services/firebase';
import HIDAvatar from '../../../../../components/HIDAvatar';
import HomeSettings from '../../../../../components/icons/HomeSettings';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  useGetCurrentUserQuery,
  useGetTokenDataQuery,
} from '../../../../Auth/api/api.user';
import { ContextMenuListItem } from '../../../../../components/contextMenu/ContextMenu';
import { useGetSignOut } from '../../../../Auth/hooks/useGetSignOut';
import {
  getPropertySettingsPath,
} from '../../../navigation/navigation.property';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import HIDIconButtonContextMenu from '../../../../../components/contextMenu/HIDIconButtonContextMenu';
import { getCurrentLanguage } from '../../../../../utils/locale';
import { useGetMessagesQuery } from '../../../modules/Content/modules/Message/api/api.message';
import {
  getFeedbackPath,
  getFeedbackOnboardingPath,
  getUserSettingsPath,
  getUserSettingsOnboardingPath,
  getLoginPath,
} from '../../../../Auth/navigation/navigation.auth';
import FlagSV from '../../../../../components/icons/FlagSV';
import FlagGB from '../../../../../components/icons/FlagGB';
import { getMessagesPath } from '../../../modules/Content/modules/Message/navigation.message';
import { getAdminHomePath } from '../../../../Admin/navigation/navigation.admin';
import { getPartnersLoginPath } from '../../../../Partners/navigation/navigation.partners';
import * as HouseIdMoveEvents from '../../../../../utils/move';

const UserSettingsMenu: FC = () => {
  const { t } = useTranslation(['home']);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { data: user } = useGetCurrentUserQuery();

  const { data: propertyId } = useGetCurrentPropertyId();

  const auth = getActiveAuth();

  const [signOut] = useGetSignOut(auth);

  const { isDownSm } = useBreakPointsSizes();

  const { data: { unreadCount = 0 } = {} } = useGetMessagesQuery(propertyId ? { propertyId, pageSize: 0 } : skipToken);

  const [currentUser] = useAuthState(auth);
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const changeLanguage = () => {
    const newLanguage = getCurrentLanguage() === Language.EN ? Language.SV : Language.EN;
    i18n.changeLanguage(newLanguage);
    HouseIdMoveEvents.changeLanguage(newLanguage);
  };

  const handleSignOut = () => {
    signOut();
    const isPartner = false;
    if (isPartner) {
      navigate(getPartnersLoginPath(), { state: { skipOrigin: true } });
    } else {
      navigate(getLoginPath(), { state: { skipOrigin: true } });
    }
  };

  const menuListItems: Array<ContextMenuListItem> = [
    {
      id: 'user_settings',
      label: t('home:home_user_settings_menu_user_settings_label'),
      Icon: PersonOutline,
      onClick: () => propertyId ? navigate(getUserSettingsPath({ propertyId })) : navigate(getUserSettingsOnboardingPath()),
    },
    propertyId && {
      id: 'property_settings',
      label: t('home:home_user_settings_menu_property_settings_label'),
      Icon: HomeSettings,
      onClick: () => navigate(getPropertySettingsPath({ propertyId })),
    },
    isDownSm && propertyId && {
      id: 'messages',
      label: t('home:home_user_settings_menu_messages_label'),
      Icon: MailOutline,
      count: unreadCount,
      onClick: () => navigate(getMessagesPath({ propertyId })),
    },
    {
      id: 'language',
      label: t('home:home_user_settings_menu_language_alternate_label'),
      Icon: getCurrentLanguage() === Language.EN ? FlagSV : FlagGB,
      onClick: changeLanguage,
    },
    {
      id: 'feedback',
      label: t('home:home_feedback'),
      Icon: () => <SmsOutlined sx={{ marginTop: 0.25 }} />,
      onClick: () => propertyId ? navigate(getFeedbackPath({ propertyId })) : navigate(getFeedbackOnboardingPath()),
    },
    tokenData?.isAdmin && {
      id: 'admin',
      Icon: SupervisorAccountRounded,
      label: t('home:home_user_settings_menu_admin_label'),
      onClick: () => navigate(getAdminHomePath()),
    },
    {
      id: 'logout',
      label: t('home:home_user_settings_menu_logout_label'),
      Icon: Logout,
      onClick: handleSignOut,
    },
  ].filter(Boolean);

  return (
    <HIDIconButtonContextMenu
      Icon={() => (
        <HIDAvatar
          blob={R.head(user?.blobs || [])}
          givenName={user?.givenName}
          surname={user?.surname}
        />
      )}
      color="secondary"
      listItems={menuListItems}
      sx={{ padding: 0 }}
    />
  );
};

export default UserSettingsMenu;
