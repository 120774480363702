import React, {
  ReactNode,
  useState,
} from 'react';
import {
  Card,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import HIDImage from '../../../../../components/image/HIDImage';
import { ICON_SIZE } from '../../../../../constants/layout';
import { FCC } from '../../../../../types/common';
import PropertyProgressCategoryBadge from './PropertyProgressCategoryBadge';
import { PropertyProgressSection } from '@house-id/houseid-types/dist/propertyProgress';

type PropertyProgressCategorySectionContainerProps = {
  section: PropertyProgressSection;
  Content: ReactNode;
  isLoading?: boolean;
};

const PropertyProgressCategorySectionContainer: FCC<PropertyProgressCategorySectionContainerProps> = ({
  section: {
    progress,
    name,
    description,
    iconUrl,
  },
  Content,
  isLoading,
  sx,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(true);

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        width: '100%',
        ...sx,
      }}
    >
      <Stack spacing={1.5}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1.5}
          onClick={() => setExpanded((expanded) => !expanded)}
        >
          {iconUrl ? <HIDImage externalUrl={iconUrl} /> : null}
          <Typography sx={{ flex: 1 }} variant="subtitle1">
            {name}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
          >
            {
              isLoading
                ? <CircularProgress size={ICON_SIZE} />
                : <PropertyProgressCategoryBadge progress={progress} />
            }
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Stack>
        </Stack>
        <Collapse
          in={expanded}
        >
          <Stack marginTop={theme.spacing(1)} spacing={1}>
            {description && (
              <Typography>{description}</Typography>
            )}
            {Content}
          </Stack>
        </Collapse>
      </Stack>
    </Card>
  );
};
export default PropertyProgressCategorySectionContainer;
