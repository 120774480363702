const PROD_HOSTNAME = 'houseid.app';
const STAGE_HOSTNAME = 'stage.houseid.app';
const TEST_HOSTNAME = 'test.houseid.services';
const FAKE_IMAGES_HOSTNAME = 'houseid.services';

export const isLocal = ['127.0.0.1', 'localhost'].includes(document.location.hostname);
export const isTest = document.location.hostname === TEST_HOSTNAME;
export const isStage = document.location.hostname === STAGE_HOSTNAME;
export const isProd = document.location.hostname === PROD_HOSTNAME;
export const isTestEnv = isLocal || isTest;

export function resolveImageUrl(url: string): string;
export function resolveImageUrl(url: string | undefined): string | undefined;
export function resolveImageUrl(url?: string) {
  return isLocal
    ? url?.replace(import.meta.env.VITE_APP_BLOBS_SERVICE_URL, `${document.location.origin}/image`)
    : isProd
      ? url?.replace(FAKE_IMAGES_HOSTNAME, PROD_HOSTNAME)
      : isStage
        ? url?.replace(FAKE_IMAGES_HOSTNAME, STAGE_HOSTNAME)
        : url;
}

export function resolveUploadUrl(url?: string) {
  return isLocal
    ? url?.replace(import.meta.env.VITE_APP_GOOGLE_STORAGE_URL, `${document.location.origin}/upload`)
    : url;
}
