import React from 'react';
import { PropertyStageType } from '@house-id/houseid-types/dist/property';

import {
  FCC,
} from '../../../../../types/common';
import HIDInfo from '../../../../../components/HIDInfo';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import useGetSellingCurrentPropertyStageBasedText from '../hooks/useGetSellingCurrentPropertyStageBasedText';

const propertyStages = [PropertyStageType.SELLING, PropertyStageType.SOLD, PropertyStageType.PREVIOUS_OWNED];

type SellingPropertyStageLabelProps = {
  includeDate?: boolean;
};

export const SellingPropertyStageLabel: FCC<SellingPropertyStageLabelProps> = ({
  includeDate,
  style,
}) => {
  const { data: { stage } = {} } = useGetCurrentProperty();
  const isNotOwnedProperty = !stage || propertyStages.includes(stage);

  const currentText = useGetSellingCurrentPropertyStageBasedText({
    includeDate,
  });

  return isNotOwnedProperty
    ? (
      <HIDInfo
        bodyTextSx={{ whiteSpace: 'pre-line', textAlign: 'center' }}
        color="orange"
        description={currentText.description}
        iconSize="small"
        label={PropertyStageType.PREVIOUS_OWNED === stage ? currentText.title : currentText.label}
        labelVariant="body2"
        sx={style}
      />
    )
    : null;
};

export default SellingPropertyStageLabel;
