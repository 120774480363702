import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  PropertyFinancesLoansSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import LoansWidget from '../../../../Finances/components/LoansWidget';
import NoLoansCard from '../../../../Finances/components/NoLoansCard';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';

const PropertyFinancesLoansSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesLoansSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleSeeMore = () => openMobileAppDialog({ unavailableFeature: EntityType.LOAN });

  const handleAdd = () => openMobileAppDialog({ unavailableFeature: EntityType.LOAN });

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),
    categoryId: PropertyProgressCategorySection.FINANCE,
    sectionId: section.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_loans_section_add_loan')}
          viewAllButtonText={t('property:property_progress_property_loans_section_view_loans')}
          onAddClick={handleAdd}
          onViewAllClick={handleSeeMore}
        >
          {
            section.data.loans?.length
              ? <LoansWidget lastSyncDate={section.data.synchronizeTimestamp} loans={section.data.loans || []} />
              : <NoLoansCard description={description} hasNoLoans={section.data.hasNoLoans} />
          }
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesLoansSection;
