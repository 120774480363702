import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const LineChart: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m6.26052,14.86973l4.30463,0l0,-3.82629l4.78284,0l0,-3.82634l5.26123,0m-17.21843,-3.82631l0,14.34867c0,1.58488 1.28482,2.86973 2.86973,2.86973l14.3487,0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default LineChart;
