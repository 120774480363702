import { Receipt } from '@house-id/houseid-types/dist/content/receipt';

import { HIDDocumentWithType } from '../Content/modules/DynamicContent/types/types.dynamicContent';
import { Note } from '../Content/modules/Note/types.note';
import {
  PropertyProgressCategoryDetails,
  PropertyDocumentationData,
  PropertyPhotosSectionData,
  PropertyProductTypesSectionData,
  PropertyTimelineSectionData,
  PropertyTasksSectionData,
  PropertyFinancesOperatingCostSectionData,
  PropertyProgressDynamicContentSectionData,
} from '../PropertyProgress/types.propertyProgress';

export enum SellingPropertyHandoverSectionId {
  DOCUMENT_TYPES = 'property-seller-document-types',
  RECEIPTS = 'property-seller-receipts',
  TASKS = 'property-seller-tasks',
  TIMELINE_ENTRIES = 'property-seller-timeline-entries',
}

export enum SellingPropertyInformationSectionId {
  PHOTOS = 'property-seller-photos',
  BLUEPRINTS = 'property-seller-blueprints',
  OPERATING_COSTS = 'property-seller-operating-costs',
  HOUSING_FACTS = 'property-seller-housing-facts',
  DOCUMENTS = 'property-seller-documents',
  PURCHASE_INFORMATION = 'property-seller-purchase-info',
  HOME_AND_ACCOMMODATION = 'property-seller-home-and-accommodation',
  ANSWERS_TO_QUESTIONNAIRE = 'property-seller-answers-to-questionnaire',
}

export enum SellingPropertyArchiveSectionId {
  PHOTOS = 'property-seller-photos',
  PRODUCTS = 'property-seller-products',
  DOCUMENTS = 'property-seller-document-types',
  NOTES = 'property-seller-notes',
  TIMELINE_ENTRIES = 'property-seller-timeline-entries',
}

export type SellingPropertyHandoverReceiptsSectionData = {
  receipts: Array<Receipt>;
};

export type SellingPropertyHandoverSectionData = PropertyDocumentationData
| SellingPropertyHandoverReceiptsSectionData
| PropertyTasksSectionData
| PropertyTimelineSectionData;

export type SellingPropertyTransferData = PropertyProgressCategoryDetails<SellingPropertyHandoverSectionData>;

export type SellingPropertyInformationTextData = {
  description: string;
};

export type SellingPropertyDocumentationData = {
  documents: Array<HIDDocumentWithType>;
};

export type SellingPropertyInformationDocuments = {
  documents: Array<HIDDocumentWithType>;
};

export type SellingPropertyInformationSectionData = PropertyPhotosSectionData
| SellingPropertyInformationTextData
| PropertyDocumentationData
| PropertyProgressDynamicContentSectionData
| PropertyFinancesOperatingCostSectionData;

export type SellingPropertyInformationData = PropertyProgressCategoryDetails<SellingPropertyInformationSectionData>;

export type SellingPropertyArchiveNotesSectionData = {
  notes: Array<Note>;
};

export type SellingPropertyArchiveSectionData = PropertyDocumentationData
| PropertyPhotosSectionData
| SellingPropertyArchiveNotesSectionData
| PropertyProductTypesSectionData
| PropertyTimelineSectionData;

export type SellingPropertyArchiveData = PropertyProgressCategoryDetails<SellingPropertyArchiveSectionData>;
