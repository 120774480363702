import React from 'react';
import { useTheme } from '@mui/material';
import {
  FileIcon as ReactFileIcon,
  FileIconProps as ReactFileIconProps,
  IconType,
} from 'react-file-icon';
import { FCC } from '../../../../../../types/common';
import { FileMimeType } from '../../../../../../constants/mimeTypes';

export const FileIconPropsMap: Record<FileMimeType, { extension: string | undefined, type: IconType }> = {
  [FileMimeType.JPEG]: {
    extension: 'jpeg',
    type: 'image',
  },
  [FileMimeType.PNG]: {
    extension: 'png',
    type: 'image',
  },
  [FileMimeType.SVG]: {
    extension: 'svg',
    type: 'image',
  },
  [FileMimeType.HEIC]: {
    extension: 'heic',
    type: 'image',
  },
  [FileMimeType.TIFF]: {
    extension: 'tiff',
    type: 'image',
  },
  [FileMimeType.PDF]: {
    extension: 'pdf',
    type: 'document',
  },
  [FileMimeType.CSV]: {
    extension: 'csv',
    type: 'document',
  },
  [FileMimeType.DOC]: {
    extension: 'doc',
    type: 'document',
  },
  [FileMimeType.DOCX]: {
    extension: 'docx',
    type: 'document',
  },
  [FileMimeType.XLS]: {
    extension: 'xls',
    type: 'document',
  },
  [FileMimeType.XLSX]: {
    extension: 'xlsx',
    type: 'document',
  },
  [FileMimeType.PPT]: {
    extension: 'xls',
    type: 'document',
  },
  [FileMimeType.PPTX]: {
    extension: 'xlsx',
    type: 'document',
  },
};

type FileIconProps = ReactFileIconProps & {
  size?: number;
};

const FileIcon: FCC<FileIconProps> = ({
  size = 26.66,
  style,
  color,
  gradientColor = 'transparent',
  gradientOpacity = 1,
  labelColor,
  glyphColor,
  foldColor,
  ...props
}) => {
  const theme = useTheme();
  const width = size;
  const height = size * 1.2;

  return (
    <div
      style={{
        width,
        minWidth: width,
        height,
        ...style,
      }}
    >
      <ReactFileIcon
        labelUppercase
        color={color || theme.palette.primary.lighter}
        foldColor={foldColor || theme.palette.primary.main}
        glyphColor={glyphColor || theme.palette.primary.main}
        gradientColor={gradientColor}
        gradientOpacity={gradientOpacity}
        labelColor={labelColor || theme.palette.primary.main}
        {...props}
      />
    </div>
  );
};

export default FileIcon;
