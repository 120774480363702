import React from 'react';
import {
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { FCC } from '../../types/common';

type HidProgressSize = 'small' | 'medium' | 'large';

type HIDCircularProgressProps = {
  progressColorBackground?: string;
  progressColor?: string;
  size?: HidProgressSize;
  showLabel?: boolean;
  value: number;
  thickness?: number;
  valueLabel?: string;
  label?: string;
};

const SizesMap: Record<HidProgressSize, { variant: Variant; fontSize?: string; circleSize: number; }> = {
  small: { circleSize: 36, variant: 'caption', fontSize: '0.625rem' },
  medium: { circleSize: 48, variant: 'caption' },
  large: { circleSize: 96, variant: 'h3' },
};

const HIDCircularProgress: FCC<HIDCircularProgressProps> = ({
  progressColorBackground: progressColorBackgroundProp,
  progressColor: progressColorProp,
  size = 'medium',
  showLabel = false,
  thickness,
  valueLabel,
  value,
  label,
  sx,
}) => {
  const theme = useTheme();

  const progressColorBackground = progressColorBackgroundProp || theme.palette.grey[200];
  const progressColor = progressColorProp || theme.palette.primary.main;

  const { circleSize, fontSize, variant } = SizesMap[size];

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: circleSize,
        height: circleSize,
        ...sx,
      }}
    >
      <CircularProgress
        size={circleSize}
        sx={{
          color: progressColorBackground,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        thickness={thickness}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        size={circleSize}
        sx={{
          color: progressColor,
          strokeLinecap: 'round',
        }}
        thickness={thickness}
        value={value}
        variant="determinate"
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'absolute' }}
      >
        {showLabel && (
          <Typography variant="caption">&nbsp;</Typography>
        )}
        <Typography
          sx={{
            color: theme.palette.common.black,
            lineHeight: 0.9,
            fontSize,
          }}
          variant={variant}
        >
          {valueLabel || `${value}%`}
        </Typography>
        {showLabel && (
          <Typography variant="caption">{label}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default HIDCircularProgress;
