import {
  ForumAuthor,
  ForumAuthorType,
  ForumExpert,
  ForumReply,
  ForumThread,
  ForumThreadTarget,
} from '../types.forum';

export const createFakeAuthor = (id: string): ForumAuthor => ({
  id: `${id}_author`,
  type: ForumAuthorType.ANONYMOUS,
});

export const createFakeReply = (id: string): ForumReply => ({
  id: `${id}_reply`,
  body: '',
  author: createFakeAuthor(id),
  createdAt: new Date().toISOString(),
  threadId: `${id}_thread`,
  anonymous: true,
});

export const createFakeForumThread = (id: string): ForumThread => ({
  id: `${id}_thread`,
  topicId: `${id}_topic`,
  author: createFakeAuthor(id),
  subject: id.toString(),
  body: id.toString(),
  replyCount: 0,
  usersReadCount: 0,
  target: ForumThreadTarget.COMMUNITY,
  createdAt: new Date().toISOString(),
});

export const createFakeExpert = (id: string): ForumExpert & { topicId: string } => ({
  id: `${id}_expert`,
  topicId: `${id}_topic`,
  name: id,
  description: id,
});
