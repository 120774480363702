import React, { useState } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';

import {
  useSendPasswordResetEmail,
} from 'react-firebase-hooks/auth';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getActiveAuth } from '../../../../external-services/firebase';
import LoginLayout from '../LoginLayout';
import HIDButton from '../../../../components/buttons/HIDButton';
import HIDLink from '../../../../components/HIDLink';
import forgotPasswordImageUrl from '../../../../assets/images/forgot_password.jpg';
import HIDTextField from '../../../../components/HIDTextField';
import InfoBox from '../../../../components/InfoBox';
import { getSignUpPath } from '../../navigation/navigation.auth';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['auth', 'forms_common']);

  const [showInformationBox, setShowInformationBox] = useState<boolean>(false);

  const [sendPasswordResetEmail, loading, resetPasswordError] = useSendPasswordResetEmail(getActiveAuth());

  const handleResetPassword = (email: string) => sendPasswordResetEmail(email)
    .finally(() => setShowInformationBox(true));

  const handleForgotPasswordClick = () => navigate(getSignUpPath());

  const schema = Yup.object({
    email: Yup.string().email(t('forms_common:email_format_wrong')).required(t('forms_common:email_required')),
  });

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => handleResetPassword(values.email),
  });

  return (
    <LoginLayout
      RightComponent={
        <Stack
          justifyContent="center"
          spacing={2}
        >
          <Typography
            gutterBottom
            fontWeight="bold"
            variant="h3"
          >
            {t('auth:forgot_password_title')}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
          >
            {t('auth:forgot_password_description')}
          </Typography>
          {showInformationBox && (
            <InfoBox
              // TODO: add error message mapping
              message={resetPasswordError?.message || t('auth:forgot_password_email_sent_message', { email: formik.values.email })}
            />
          )}
          <HIDTextField
            required
            showValidationIcon
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : undefined}
            id="email"
            label={t('forms_common:email_title')}
            type="email"
            value={formik.values.email}
            onBlur={formik.handleBlur('email')}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          <HIDButton
            fullWidth
            disabled={!formik.dirty || !formik.isValid || loading}
            loading={loading}
            size="large"
            style={{ marginTop: theme.spacing(4) }}
            onClick={() => formik.submitForm()}
          >
            {t('auth:forgot_password_reset_password_button')}
          </HIDButton>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Typography
              paddingRight={theme.spacing(1)}
              variant="body1"
            >
              {t('auth:common_new_user_button')}
            </Typography>
            <HIDLink
              label={t('auth:common_create_account_here')}
              variant="body1"
              onClick={handleForgotPasswordClick}
            />
          </Stack>
        </Stack>
      }
      backgroundImageUrl={forgotPasswordImageUrl}
      message={t('auth:forgot_password_message')}
    />
  );
};

export default ForgotPassword;
