import {
  UserCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { useState } from 'react';

import { getActiveAuth } from '../../../external-services/firebase';

type UseGetSignInWithTokenAndKey = () => {
  signInWithTokenAndKey: (token: string) => Promise<UserCredential | undefined>;
  isLoading: boolean;
  error: Error | undefined;
};

export const useGetSignInWithTokenAndKey: UseGetSignInWithTokenAndKey = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const signInWithTokenAndKey = (token: string) => {
    setLoading(true);
    setError(undefined);

    return signInWithCustomToken(getActiveAuth(), token)
      .catch((e: Error) => {
        setError(e);
        return undefined;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    signInWithTokenAndKey,
    isLoading: loading,
    error,
  };
};
