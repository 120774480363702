import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Discount: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.common.white;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M12 1.25L14.106 3.70571L17.112 2.50998L17.8355 5.66311L21.0528 6.00129L20.2281 9.12948L22.9198 10.9241L20.7358 13.3107L22.2852 16.1507L19.2423 17.249L19.2943 20.4836L16.0896 20.042L14.6325 22.9304L12 21.05L9.36753 22.9304L7.91044 20.042L4.70565 20.4836L4.75774 17.249L1.71482 16.1507L3.26416 13.3107L1.0802 10.9241L3.77186 9.12948L2.94718 6.00129L6.16452 5.66311L6.88805 2.50998L9.89402 3.70571L12 1.25Z"
        fill={color}
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M7.875 8.8125C7.875 8.43334 8.18336 8.125 8.56241 8.125C8.94164 8.125 9.25 8.43334 9.25 8.8125C9.25 9.19166 8.94164 9.5 8.56241 9.5C8.18336 9.5 7.875 9.19166 7.875 8.8125Z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M14.75 15.6875C14.75 15.3083 15.0584 15 15.4374 15C15.8166 15 16.125 15.3083 16.125 15.6875C16.125 16.0667 15.8166 16.375 15.4374 16.375C15.0584 16.375 14.75 16.0667 14.75 15.6875Z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.875 16.375L16.125 8.125"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Discount;
