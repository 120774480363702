import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const BarChart: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m17.61192,19.48258l0,-9.3532m-5.61193,9.3532l0,-14.96516m-5.61191,14.96516l0,-5.61193"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default BarChart;
