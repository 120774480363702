import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PropertyBlobsSectionData } from '../../../types.propertyProgress';
import PropertyPhotosList from './PropertyPhotosList';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import { ImageMimeTypes } from '../../../../../../../constants/mimeTypes';
import ContentFilePicker from '../../../../../../../components/filePicker/ContentFilePicker';
import { ContentFile } from '../../../../Content/modules/ContentFile/types.contentFile';

export type PropertyBlueprintsCommonImagesSectionProps = PropertyBlobsSectionData & {
  multipleFileUpload?: boolean;
  onAddImage?: () => void;
  onViewImages?: () => void;
  onContentFilesSelected: (files: Array<ContentFile>) => void;
};

type PropertyBlueprintsImagesSectionProps = PropertyBlueprintsCommonImagesSectionProps & {
  description?: string;
  buttonText?: string;
};

const PropertyBlueprintsImagesSection: FC<PropertyBlueprintsImagesSectionProps> = ({
  blobs,
  description,
  buttonText,
  multipleFileUpload = true,
  onAddImage,
  onViewImages,
  onContentFilesSelected,
}) => {
  const { t } = useTranslation(['property']);

  const hasBlobs = Boolean(blobs?.length);

  return (
    <PropertyProgressCategorySectionContent
      addButtonText={buttonText}
      description={description}
      viewAllButtonText={t('property:property_progress_property_blueprints_cover_images_view_all')}
      onAddClick={hasBlobs ? onAddImage : undefined}
      onViewAllClick={hasBlobs ? onViewImages : undefined}
    >
      {
        hasBlobs
          ? (
            <PropertyPhotosList blobs={blobs} />
          )
          : (
            <ContentFilePicker
              mimeTypes={ImageMimeTypes}
              multiple={multipleFileUpload}
              sx={{
                width: '100%',
              }}
              onContentFilesSelected={onContentFilesSelected}
            />
          )
      }
    </PropertyProgressCategorySectionContent>
  );
};
export default PropertyBlueprintsImagesSection;
