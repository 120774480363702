import {
  Progress,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';
import { HIDBlob } from '@house-id/houseid-types/dist/common';
import { Loan } from '@house-id/houseid-types/dist/finances/finances';
import { Budget } from '@house-id/houseid-types/dist/finances/budgets';
import { Product } from '@house-id/houseid-types/dist/content/product';

import {
  IconResource,
  ExternalService,
} from '../../../../types/common';
import { BuildingEntity } from '../Content/modules/Building/types.building';
import { HIDDocument } from '../Content/modules/Document/types.document';
import {
  DynamicInputField,
  HIDDocumentWithType,
} from '../Content/modules/DynamicContent/types/types.dynamicContent';
import { Task } from '../Content/modules/GoodToDoTask/types.task';
import {
  InsuranceWithParams,
  CustomInsuranceType,
} from '../Content/modules/Insurance/types.insurance';
import { DateSeparator } from '../Content/modules/Timeline/pages/Timeline/components/TimelineItem';
import { TimelineEvent } from '../Content/modules/Timeline/types.timeline';

export enum PropertyPurchaseDocumentRequisite {
  required = 'required',
  recommended = 'recommended',
}

export type PropertyLink = {
  name: string;
  url: string;
  icon?: IconResource
};

export type PropertyDocumentType = {
  type: string;
  name: string;
  iconUrl: string;
  documents: Array<Omit<HIDDocument, 'classificationType'>>;
  requisite: PropertyPurchaseDocumentRequisite;
};

export type PropertyProgressCategoryDetails<TData = any> = {
  progress?: Progress;
  sections: Array<PropertyProgressSection<TData>>;
};

export type PropertyProgressCategorySectionProps<TData = any> = {
  section: PropertyProgressSection<TData>;
};

export type PropertySpecificationComponent = DynamicInputField & {
  progress: Progress;
};

export type PropertySpecificationData = { components: Array<PropertySpecificationComponent> };

export type PropertyPurchaseDocumentsSectionData = {
  documentTypes: Array<PropertyDocumentType>,
};

export type PropertyProspectLinksSectionData = {
  links: Array<PropertyLink>;
};

export type PropertyToDoPropertyCheckSectionData = object;

export type PropertyPurchaseData =
  PropertyPurchaseDocumentsSectionData |
  PropertyProspectLinksSectionData;

export type PropertyProductType = {
  type: string;
  name: string;
  icon: IconResource;
  products: Array<Product>;
  completed: boolean;
};

export type PropertyProductTypesSectionData = {
  productTypes?: Array<PropertyProductType>;
};

export type Photo = {
  id: string;
  type: string;
  name: string;
  blobs: Array<HIDBlob>;
  createdAt: string;
};

export type PropertyBlueprintsSectionData = PropertyDocumentationData;

export type PropertyBlobsSectionData = {
  blobs: Array<HIDBlob>,
};

export type PropertyPhotosSectionData = {
  photos: Array<Photo>,
};

export type PropertyBlueprintSectionData =
  PropertyBlueprintsSectionData |
  PropertyBlobsSectionData |
  PropertyPhotosSectionData |
  PropertyProspectLinksSectionData;

export type PropertyPlanningRoomsSectionData = {
  rooms: Array<BuildingEntity>,
};

export type PropertyTasksSectionData = {
  tasks: Array<Task>;
};

export type PropertyTimelineSectionData = {
  events: Array<TimelineEvent | DateSeparator>,
};

export type PropertyToDoSectionData = PropertyTasksSectionData;

export type PropertyPlanningSectionData = PropertyTimelineSectionData;

export type PropertyDocumentationData = {
  documentTypes: Array<PropertyDocumentType>,
  externalServices: Array<ExternalService>,
};

export type PropertyFinancesLoansSectionData = {
  loans?: Array<Loan>;
  totalLoanAmount?: number;
  synchronizeTimestamp?: string;
  hasNoLoans: boolean;
};

export type WeightedAverageComparisonInterestRate = {
  regionType: string;
  regionId: string;
  interestRate: number;
  isFallbackToVariableInterestComparisonRate: boolean;
};

export type OrdnaInterestRate = {
  averageInterestRates: {
    lastMonthTimestamp: string;
    lastMonthAverage: number;
    lastTwelveMonthAverage: number;
  },
  proposal: {
    interestRate: number;
    monthlySaving: number;
  }
};

export type PropertyFinancesLoanInterestRateSectionData = {
  numberOfLoans?: number;
  loans: Array<Loan>;
  weightedAverageInterestRate?: number;
  weightedAverageComparisonInterestRates: Array<WeightedAverageComparisonInterestRate>;
  synchronizeTimestamp?: string | null;
  ordna: OrdnaInterestRate;
};

export type PropertyFinancesOperatingCostSectionData = {
  budget: Budget;
};

export type PropertyFinancesFixedCostSectionData = object;

export type PropertyFinancesValuationSectionData = {
  movestaValuation: object;
  valuation: {
    id: string;
    timestamp: string;
    amount: number;
    currencyCode: string,
  };
};

export type PropertyFinancesPurchaseInfoSectionData = PropertyProgressDynamicContentSectionData;

export type PropertyFinancesTaxationSectionData = PropertyProgressDynamicContentSectionData;

export type PropertyFinancesEasementSectionData = PropertyProgressDynamicContentSectionData;

export type PropertyFinancesMortgageSectionData = PropertyProgressDynamicContentSectionData;

export type PropertyFinancesSectionData =
  PropertyFinancesLoansSectionData
  | PropertyFinancesLoanInterestRateSectionData
  | PropertyFinancesOperatingCostSectionData
  | PropertyFinancesFixedCostSectionData
  | PropertyFinancesValuationSectionData
  | PropertyFinancesTaxationSectionData;

export type PropertyInsuranceSectionData = {
  insurances: Array<InsuranceWithParams>;
  synchronizeTimestamp?: string;
};
export type PropertyProgressDynamicContentSectionData = {
  components: Array<DynamicInputField>;
};

export type PropertySecuritySectionData = PropertyProgressDynamicContentSectionData;

export type PropertyVarudeklareratInsuranceSectionData = {
  type: CustomInsuranceType.CONDOMINIUM | CustomInsuranceType.HOUSE;
  expirationDate?: string;
  externalDocuments: Array<PropertyLink>;
  documents: Array<HIDDocumentWithType>;
};

export type PropertyIfInsuranceSectionData = {
  type: CustomInsuranceType;
  startDate?: string;
  expirationDate?: string;
  externalDocuments: Array<PropertyLink>;
  documents: Array<HIDDocumentWithType>;
};

export type PropertyInsuranceAndSafetySectionData =
  PropertyInsuranceSectionData
  | PropertySecuritySectionData
  | PropertyVarudeklareratInsuranceSectionData
  | PropertyIfInsuranceSectionData;

export enum PropertyBlueprintsSectionSubSection {
  PROPERTY_COVER_PHOTOS = 'property-cover-photos',
  PROPERTY_PHOTOS = 'property-photos',
  BLUEPRINT_DOCUMENT_TYPES = 'blueprint-document-types',
  PROSPECT_LINKS = 'prospect-links',
}

export enum PropertyToDoSectionSubSection {
  PROPERTY_TODO_TASKS = 'property-planning-tasks',
  PROPERTY_TODO_PROPERTY_CHECK = 'property-planning-property-check',
}

export enum PropertyPlaningSectionSubSection {
  PROPERTY_PLANNING_TIMELINE_ENTRIES = 'property-planning-timeline-entries',
}

export enum PropertyFinanceSectionSubSection {
  FINANCE_LOANS = 'finance-loans',
  FINANCE_LOANS_INTEREST_RATE = 'finance-loans-interest-rate',
  FINANCE_RECURRING_EXPENSES = 'finance-recurring-expenses',
  FINANCE_BUDGET = 'finance-budget',
  FINANCE_VALUATION = 'finance-valuation',
  PURCHASE_INFO = 'purchase-info',
  TAXATION = 'taxation',
  EASEMENT = 'easement',
  MORTGAGE = 'mortgage',
  PROPERTY_SOLD = 'property-sold',
}

export enum PropertySpecificationSectionSubSection {
  BASIC_INFO = 'basic-info',
  COOPERATIVE_INFO = 'cooperative-info',
  ADDRESS = 'address',
  PROPERTY_INFO = 'property-info',
  BUILDINGS = 'buildings',
  HEATING = 'heating',
  OUTDOOR = 'outdoor',
  POWER = 'power',
  ENERGY = 'energy',
  WATER = 'water',
}

export enum PropertyDocumentationSectionSubSection {
  PROPERTY_DOCUMENTATION_DOCUMENT_TYPES = 'property-documentation-document-types',
  PROPERTY_DOCUMENTATION_RECEIPT_SUMMARY = 'property-documentation-receipt-summary',
}

export enum PropertyPurchaseSectionSubSection {
  PROPERTY_PURCHASE_DOCUMENT_TYPES = 'property-purchase-document-types',
  PROSPECT_LINKS = 'prospect-links',
}

export enum PropertyProductsSectionSubSection {
  PROPERTY_PRODUCTS = 'property-products',
}
