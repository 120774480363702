import React, {
  FC,
  useEffect,
  useRef,
} from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';

import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import {
  useLocationState,
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../utils/routes';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { useGetCurrentUserQuery } from '../api/api.user';
import HIDTextField from '../../../components/HIDTextField';
import HIDButton from '../../../components/buttons/HIDButton';
import {
  useCreateSupportTicketMutation,
  useGetSupportCategoriesQuery,
} from '../../Property/api/api.support';
import {
  getUserDetailsOnboardingPath,
  getUserSettingsPath,
} from '../navigation/navigation.auth';
import HIDTypography from '../../../components/HIDTypography';
import HIDFormSelect from '../../../components/HIDFormSelect';
import {
  SupportTicket,
  SupportTicketCategoryId,
} from '../../Property/types/types.support';

const Feedback: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['home', 'common', 'forms_common']);
  const theme = useTheme();
  const isPrefilledBody = useRef<boolean>(false);

  const queryParams = useRouteQueryParams<{ categoryId?: SupportTicketCategoryId }>();
  const additionalData = useLocationState<Record<string, string> | undefined>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: user, isLoading: isLoadingCurrentUser } = useGetCurrentUserQuery();
  const userName = user?.givenName || '';

  const [createSupportTicket, { isLoading: isCreating }] = useCreateSupportTicketMutation();
  const { data: categories = [], isLoading: isCategoriesLoading } = useGetSupportCategoriesQuery();
  const handleGoBack = () => navigateBackOr(propertyId ? getUserSettingsPath({ propertyId }) : getUserDetailsOnboardingPath());

  const schema = Yup.object({
    categoryId: Yup.string().required(t('home:home_feedback_category_error_required')),
    subject: Yup.string().required(t('home:home_feedback_subject_error_required')),
    body: Yup.string().required(t('home:home_feedback_text_error_required')),
  });

  const handleSend = (values: SupportTicket) => {
    const contextInformation = {
      propertyId,
      userId: user?.id,
      ...additionalData,
    };

    createSupportTicket({
      categoryId: values.categoryId,
      subject: values.subject,
      contextInformation,
      body: values.body,
    })
      .then(() => {
        enqueueSnackbar(t('home:home_feedback_sent_title'), {
          description: t('home:home_feedback_sent_description'),
          variant: 'success',
        });

        handleGoBack();
      });
  };

  const {
    isValid,
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik<SupportTicket>({
    initialValues: {
      categoryId: '',
      subject: '',
      body: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSend,
  });

  useEffect(() => {
    const category = categories?.find(({ id }) => id === values.categoryId);

    if (values.categoryId) {
      if (category) {
        isPrefilledBody.current = true;
        setValues({
          categoryId: values.categoryId,
          subject: category.subjectTemplate,
          body: category.bodyTemplate,
        }, true);
      }
    }
  }, [values.categoryId, categories]);

  useEffect(() => {
    if (categories?.length) {
      setFieldValue('categoryId', queryParams.categoryId);
    }
  }, [queryParams.categoryId, categories]);

  return (
    <HomeLayout
      Header={() => (
        <Stack
          sx={{
            padding: {
              xl: theme.spacing(4, 4, 0, 4),
              md: theme.spacing(3, 3, 0, 3),
              xxs: theme.spacing(0, 0, 2, 0),
            },
            marginBottom: {
              xl: -1,
              md: -1,
            },
          }}
        >
          <HIDTypography color={theme.palette.primary.main} isLoading={isLoadingCurrentUser} variant="h4">
            {t('home:home_feedback_hi_title', { name: userName })}
          </HIDTypography>
        </Stack>
      )}
      SideColumn={<div />}
      isLoading={isCategoriesLoading}
      title={t('home:home_give_feedback')}
      onBack={handleGoBack}
    >
      <Stack direction="column" spacing={3}>
        <HIDTypography isLoading={isCategoriesLoading}>
          {t('home:home_feedback_description_1')}
        </HIDTypography>
        <HIDTypography isLoading={isCategoriesLoading}>
          {t('home:home_feedback_description_2')}
        </HIDTypography>
        <HIDFormSelect
          required
          error={Boolean(touched.categoryId && errors.categoryId)}
          helperText={(touched.categoryId) ? errors.categoryId : undefined}
          items={categories}
          label={t('forms_common:select_category')}
          value={values.categoryId}
          onBlur={handleBlur('categoryId')}
          onChange={(event) => setFieldValue('categoryId', event.target.value)}
        />
        <HIDTextField
          required
          error={Boolean(touched.subject && errors.subject)}
          helperText={(touched.subject) ? errors.subject : undefined}
          label={t('home:home_feedback_subject')}
          value={values.subject}
          onBlur={handleBlur('subject')}
          onChange={(event) => setFieldValue('subject', event.target.value)}
        />
        <Stack direction="column" spacing={1.5}>
          <HIDTypography isLoading={isCategoriesLoading} variant="subtitle1">
            {`${t('home:home_feedback_description_label')} *`}
          </HIDTypography>
          <HIDTextField
            multiline
            required
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.body && errors.body)}
            helperText={(touched.body) ? errors.body : undefined}
            placeholder={t('home:home_feedback_description_label')}
            value={values.body}
            onBlur={handleBlur('body')}
            onChange={(event) => setFieldValue('body', event.target.value)}
            onFocus={(event) => {
              if (event.target.value && isPrefilledBody.current) {
                isPrefilledBody.current = false;
                setFieldValue('body', '');
              }
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <HIDButton
            disabled={!isValid}
            loading={isCreating}
            onClick={submitForm}
          >
            {t('common:send')}
          </HIDButton>
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default Feedback;
