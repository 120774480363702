import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Clean: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;
  const rectStroke = String(strokeWidth / 2);

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M5.80232 14.7791C5.91486 13.7663 6.77099 13 7.79009 13H15.7655C16.9575 13 17.8849 14.0361 17.7532 15.2209L17 22H5L5.80232 14.7791Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.8656 4.34404C10.9419 3.58104 11.5839 3 12.3507 3V3C13.2338 3 13.9238 3.76242 13.8359 4.64107L13 13H10L10.8656 4.34404Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M8 21.5L8.5 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.85}
      />
      <path
        d="M11 21.5L11.5 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.85}
      />
      <path
        d="M14 21.5L14.5 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.85}
      />
      <rect
        fill={color}
        height="2.5"
        rx="1.25"
        stroke={rectStroke}
        width="2.5"
        x="0.5"
        y="17"
      />
      <rect
        fill={color}
        height="2.5"
        rx="1.25"
        stroke={rectStroke}
        width="2.5"
        x="20"
        y="20.5"
      />
    </HIDSvgIcon>
  );
};

export default Clean;
