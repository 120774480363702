import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetLastAddedProductsQuery } from '../../../modules/Content/modules/Product/api/api.product';
import ProductWidgetItem from './ProductWidgetItem';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';

const PRODUCTS_COUNT = 4;

const AddByOthersProductsWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'forms_common']);

  const { data: products = [] } = useGetLastAddedProductsQuery();

  const firstProducts = products.slice(0, PRODUCTS_COUNT);

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.ADD_BY_OTHERS_PRODUCTS);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight,
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ minWidth: 0 }}
        >
          <Typography
            noWrap
            variant="h5"
          >
            {t('home:home_others_add')}
          </Typography>
        </Stack>
        <Stack>
          {
            firstProducts.map((product, index) => (
              <ProductWidgetItem key={index} product={product} />
            ))
          }
        </Stack>
      </Stack>
    </Card>
  );
};

export default AddByOthersProductsWidget;
