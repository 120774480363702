import { BUDGET_COLLAPSIBLE_SECTION_ANIMATION_TIMEOUT } from '../constants/animationTimeout';

const INITIAL_STEP_SIZE = 2; // px
const EXPONENTIAL_MULTIPLIER = 1.2;

const scrollToElementAndFocus = (element: HTMLInputElement | null) => {
  if (element) {
    const offset = window.innerHeight / 4;
    let stepSize = INITIAL_STEP_SIZE;
    let previousPosition = window.scrollY;

    const step = () => {
      const elementRect = element.getBoundingClientRect();
      const targetPosition = elementRect.top + window.scrollY - offset;

      const distance = targetPosition - window.scrollY;

      stepSize *= EXPONENTIAL_MULTIPLIER;

      const nextStepSize = Math.min(stepSize, Math.abs(distance)) * Math.sign(distance);

      window.scrollBy(0, nextStepSize);

      if (Math.abs(distance) > 1 && window.scrollY !== previousPosition) {
        previousPosition = window.scrollY;
        requestAnimationFrame(step);
      } else {
        element.focus();
      }
    };

    if (window.scrollY === 0) {
      setTimeout(() => {
        requestAnimationFrame(step);
      }, BUDGET_COLLAPSIBLE_SECTION_ANIMATION_TIMEOUT + 100);
    } else {
      requestAnimationFrame(step);
    }
  }
};

export default scrollToElementAndFocus;
