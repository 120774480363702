import React from 'react';
import {
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../types/common';
import BonusOffersSectionCard from './BonusOffersSectionCard';
import HIDLinearProgress from '../../../../../../../components/progress/HIDLinearProgress';

type BonusPointsProgressProps = {
  title?: string;
  total: number;
  current: number;
  sx?: SxProps<Theme>;
};

const BonusPointsProgress: FCC<BonusPointsProgressProps> = ({
  title,
  total,
  current,
  sx,
}) => {
  const theme = useTheme();

  const { t } = useTranslation(['common']);

  const currentPercent = (current * 100) / total;
  const left = total - current;

  return (
    <BonusOffersSectionCard sx={sx}>
      {title && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography fontWeight="bold" variant="body1">
            {t('common:points', { points: left })}
          </Typography>
          <Typography variant="body1">{title}</Typography>
        </Stack>
      )}
      <HIDLinearProgress
        fillColor={theme.palette.warning.dark}
        sx={{
          width: '100%',
          height: 12,
          borderRadius: 8,
        }}
        value={currentPercent}
        variant="determinate"
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          marginTop: theme.spacing(1),
        }}
      >
        <Typography variant="body1">
          {t('common:points', { points: current })}
        </Typography>
        <Typography variant="body1">
          {t('common:points', { points: total })}
        </Typography>
      </Stack>
    </BonusOffersSectionCard>
  );
};

export default BonusPointsProgress;
