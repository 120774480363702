import {
  useState,
  useEffect,
} from 'react';

type UseLock = (observable?: number | string | boolean) => { locked: boolean; lock: () => void };

const useLock: UseLock = (observable) => {
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    if (locked) {
      setLocked(false);
    }
  }, [observable]);

  return {
    locked: locked && Boolean(observable),
    lock: () => setLocked(true),
  };
};

export default useLock;
