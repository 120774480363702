import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getRecurringExpensesCategoriesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.RECURRING_EXPENSES_CATEGORIES,
  { propertyId },
);

type GetRecurringExpensesUpdatePathParams = PropertyIdNavigation;
export const getRecurringExpensesUpdatePath = ({ propertyId }: GetRecurringExpensesUpdatePathParams) => generateRoutePath(
  RouteNames.RECURRING_EXPENSES_CATEGORIES,
  { propertyId },
  { manageExpenses: true },
);

type GetRecurringExpenseCategoryPath = PropertyIdNavigation & { categoryId: string };
export const getRecurringExpenseCategoryPath = ({ propertyId, categoryId }: GetRecurringExpenseCategoryPath) => generateRoutePath(
  RouteNames.RECURRING_EXPENSES_CATEGORY,
  { propertyId, categoryId },
);

export const getRecurringExpensesBankAccountsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.RECURRING_EXPENSES_BANK_ACCOUNTS,
  { propertyId },
);

type GetRecurringExpenseParams = PropertyIdNavigation & { categoryId?: string, id: string };
export const getRecurringExpense = ({ propertyId, id, categoryId }: GetRecurringExpenseParams) => generateRoutePath(
  RouteNames.RECURRING_EXPENSE,
  { propertyId, id },
  { categoryId },
);

type GetUpdateRecurringExpensePathParams = PropertyIdNavigation & { id: string };
export const getUpdateRecurringExpensePath = (params: GetUpdateRecurringExpensePathParams) => generateRoutePath(
  RouteNames.RECURRING_EXPENSE_UPDATE,
  params,
);
