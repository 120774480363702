import React from 'react';
import { FCC } from '../../types/common';

const HorizontalOvalImageCut: FCC<{ color: string }> = ({ color, style }) => (
  <svg
    fill="none"
    style={{
      position: 'absolute',
      bottom: -1,
      width: 'calc(100% + 0.1px)',
      ...style,
    }}
    viewBox="0 0 2 0.075"
  >
    <path
      d="M 2 0 C 1.2 0.1 0.8 0.1 0 0 L 0 0.075 L 2 0.075 Z"
      fill={color}
    />
  </svg>
);

export default HorizontalOvalImageCut;
