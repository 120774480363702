import React, { FC } from 'react';
import {
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { Photo } from '../types.photo';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import PhotoImage from './PhotoImage';
import PhotosListLayoutWrapper from './PhotosListLayoutWrapper';

const StyledImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  borderBottomLeftRadius: theme.spacing(0.5),
  borderBottomRightRadius: theme.spacing(0.5),
  margin: '0 1px 1px 1px',
  '& .MuiImageListItemBar-titleWrap': {
    padding: theme.spacing(1, 1.5),
  },
}));

type PhotosListProps = {
  photos: Array<Photo>;
  renderIcon?: (photo: Photo) => React.ReactElement | null,
  onClick: (photo: Photo, index: number) => void;
};

const PhotosList: FC<PhotosListProps> = ({
  photos,
  renderIcon,
  onClick,
}) => {
  const theme = useTheme();

  if (!photos.length) {
    return null;
  }

  return (
    <PhotosListLayoutWrapper>
      {photos.map((photo, index) => (
        <ImageListItem key={photo.id} sx={{ overflow: 'hidden', borderRadius: theme.spacing(0.5) }}>
          <PhotoImage
            photo={photo}
            onClick={() => onClick(photo, index)}
          />
          {renderIcon && renderIcon(photo)}
          {Boolean(photo.createdAt) && (
            <StyledImageListItemBar
              subtitle={
                <Stack spacing={0.5}>
                  {photo.date && (
                    <Typography variant="inherit">
                      {formatDate(new Date(photo.date), DateTimeFormats.DATE_ONLY_TEXT)}
                    </Typography>
                  )}
                  {photo.place && (
                    <Typography
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        alignSelf: 'flex-start',
                        padding: theme.spacing(0.125, 0.25),
                        borderRadius: '2px',
                      }}
                      variant="inherit"
                    >
                      {photo.place}
                    </Typography>
                  )}
                </Stack>
              }
              sx={{ backgroundColor: theme.palette.shadow[400] }}
              title={photo.name}
            />
          )}
        </ImageListItem>
      ))}
    </PhotosListLayoutWrapper>
  );
};

export default PhotosList;
