import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HandHome: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} viewBox="0 0 28 28">
      <path
        clipRule="evenodd"
        d="M8 5.32066C8 4.99435 8.15921 4.68856 8.42654 4.50143L11.4265 2.40143C11.7709 2.1604 12.2291 2.1604 12.5735 2.40143L15.5734 4.50143C15.8408 4.68856 16 4.99434 16 5.32066C16 6.54712 16 7.77359 16 9.00005C16 9.55232 15.5523 10 15 10H9C8.44772 10 8 9.55228 8 9C8 7.77355 8 6.54711 8 5.32066Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.5 17.75L12.1334 16.7847C12.8899 16.6271 13.3778 15.8889 13.2262 15.1312V15.1312C13.0947 14.4734 12.5172 14 11.8464 14H5.67796C4.70748 14 3.87705 14.6967 3.70839 15.6524L2.73069 21.1927C2.61514 21.8475 3.15891 22.4306 3.82016 22.361L11.2289 21.5812C11.7347 21.5279 12.2186 21.347 12.6352 21.0553L20.7746 15.3577C21.4649 14.8746 21.6639 13.9399 21.2305 13.2174V13.2174C20.8134 12.5223 19.9434 12.2474 19.2026 12.5766L16 14"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default HandHome;
