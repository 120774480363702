import {
  PropertyOriginFlow,
  PropertyStageType,
} from '@house-id/houseid-types/dist/property';

import {
  QueryHook,
} from '../../../types/common';
import useCurrentProperty from './useCurrentProperty';

type UseGetIsSfSellerSellingProperty = QueryHook<boolean | undefined>;

const sellingPropertyStages = [PropertyStageType.SELLING, PropertyStageType.SOLD];

const useGetIsSfSellerSellingProperty: UseGetIsSfSellerSellingProperty = (args, options) => {
  const { data: property, ...rest } = useCurrentProperty(args, options);

  const isSellingPropertyOrigin = property?.origin?.flow === PropertyOriginFlow.THIRD_PARTY_CONSENT;

  return {
    data: property ? isSellingPropertyOrigin && sellingPropertyStages.includes(property?.stage) : undefined,
    ...rest,
  };
};

export default useGetIsSfSellerSellingProperty;
