import React, {
  FC,
} from 'react';

import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../../utils/useGetWidgetHeight';
import MyTotalBonusPointsSlide from './MyTotalBonusPointsSlide';
import HouseIdBonusOffersSlide from './HouseIdBonusOffersSlide';
import MoveInBonusOffersSlide from './MoveInBonusOffersSlide';
import ContentSliderContainer from '../../../../../../components/contentSlider/ContentSliderContainer';
import useGetBonusOffersCategoryAvailability from '../../../../modules/BonusOffers/hooks/useGetBonusOffersCategoryAvailability';

enum BonusOffersWidgetSlideId {
  MOVE_IN_BONUS_OFFERS,
  HOUSE_ID_BONUS_OFFERS,
  MY_TOTAL_BONUS_POINTS,
}

const BonusOffersWidgetSlideMap: Record<BonusOffersWidgetSlideId, React.FC> = {
  [BonusOffersWidgetSlideId.MY_TOTAL_BONUS_POINTS]: MyTotalBonusPointsSlide,
  [BonusOffersWidgetSlideId.HOUSE_ID_BONUS_OFFERS]: HouseIdBonusOffersSlide,
  [BonusOffersWidgetSlideId.MOVE_IN_BONUS_OFFERS]: MoveInBonusOffersSlide,
};

const BonusOffersWidget: FC = () => {
  const widgetHeight = useGetWidgetHeight(HomeWidgetType.BONUS_OFFERS);

  const handleRenderSlide = (slideId: BonusOffersWidgetSlideId) => {
    const SlideComponent = BonusOffersWidgetSlideMap[slideId];
    return <SlideComponent />;
  };

  const {
    data: { hasMoveInBonusOffers },
  } = useGetBonusOffersCategoryAvailability();

  const slideIds = hasMoveInBonusOffers
    ? Object.keys(BonusOffersWidgetSlideMap) as unknown as Array<BonusOffersWidgetSlideId>
    : [
      BonusOffersWidgetSlideId.HOUSE_ID_BONUS_OFFERS,
      BonusOffersWidgetSlideId.MY_TOTAL_BONUS_POINTS,
    ];

  return (
    <ContentSliderContainer<BonusOffersWidgetSlideId>
      renderSlide={handleRenderSlide}
      sliderHeight={widgetHeight}
      slides={slideIds}
    />
  );
};

export default BonusOffersWidget;
