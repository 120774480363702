import { PropertyProgressSection } from '@house-id/houseid-types/dist/propertyProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { FCC } from '../../../../../../../../types/common';
import BuildingEntitiesSection from './BuildingEntitiesSection';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import HouseProfilingImage from '../../../../../../../../assets/images/configure_house_5.png';
import { getPropertySpecificationDeepLink } from '../../../../../../../../utils/links';

type PropertyProductTypesSectionProps = {
  section: PropertyProgressSection;
};

const PropertyStructureOutdoorsSection: FCC<PropertyProductTypesSectionProps> = ({
  section,
  style,
}) => {
  const { t } = useTranslation(['property_progress', 'buildings']);
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleOpenMobileAppDialog = () => openMobileAppDialog({
    linkExtractor: getPropertySpecificationDeepLink,
    unavailableFeature: EntityType.OUTDOOR,
  });

  const handleConfigure = handleOpenMobileAppDialog;

  const handleOpen = handleOpenMobileAppDialog;

  return (
    <BuildingEntitiesSection
      buildingEntityType={EntityType.OUTDOOR}
      description={t('property_progress:property_progress_housing_facts_outdoors_description')}
      emptyStateAddText={t('buildings:property_structure_configure_house_structure_step_five_intro_title')}
      emptyStateDescription={t('buildings:property_structure_configure_house_structure_step_five_intro_description')}
      emptyStateImage={HouseProfilingImage}
      emptyStateTitle={t('buildings:property_structure_configure_house_structure_step_five_intro_title')}
      parentRequired={false}
      section={section}
      showAllText={t('property_progress:property_progress_housing_facts_outdoors_show_all_text')}
      style={style}
      onConfigure={handleConfigure}
      onShowAll={handleOpen}
    />
  );
};

export default PropertyStructureOutdoorsSection;
