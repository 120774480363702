import React, {
  ChangeEvent,
  useMemo,
  FC,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormikProps,
} from 'formik';
import { debounce } from 'throttle-debounce';
import { Stack } from '@mui/material';
import { CreateUpdateBudgetValues } from '@house-id/houseid-types/dist/finances/budgets';

import HIDTextField from '../../../../../../../../../components/HIDTextField';
import HIDFormDatePicker from '../../../../../../../../../components/datePicker/HIDFormDatePicker';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { INPUT_FIELDS_DEBOUNCE_TIME } from '../../../../../../../../../constants/layout';
import { ErrorFieldInfo } from '../hooks/useGetFormikErrorFieldPath';
import scrollToElementAndFocus from '../utils/scrollToElementAndFocus';

type BudgetHeaderFieldsProps = {
  formik: FormikProps<CreateUpdateBudgetValues>
};

const BudgetHeaderFields: FC<BudgetHeaderFieldsProps> = ({ formik }) => {
  const { t } = useTranslation(['finances']);

  const nameInputFieldRef = useRef<HTMLInputElement | null>(null);
  const yearInputFieldRef = useRef<HTMLInputElement | null>(null);

  const [currentName, setCurrentName] = useState<string | undefined>('');

  const errorFieldsInfo = formik.status as ErrorFieldInfo | null;

  useEffect(() => {
    if (errorFieldsInfo && errorFieldsInfo.errorFieldPath === 'name') {
      scrollToElementAndFocus(nameInputFieldRef.current);
    }
    if (errorFieldsInfo && errorFieldsInfo.errorFieldPath === 'year') {
      scrollToElementAndFocus(yearInputFieldRef.current);
    }
  }, [errorFieldsInfo]);

  useEffect(() => {
    if (formik.values.name && !currentName) {
      setCurrentName(formik.values.name);
    }
  }, [formik.values.name]);

  const debounceName = useMemo(() => debounce(
    INPUT_FIELDS_DEBOUNCE_TIME,
    (value: string | undefined) => formik.setFieldValue('name', value),
  ), []);

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentName(event.target.value);
    debounceName(event.target.value);
  };

  const handleChangeDate = (date: Date | undefined) => {
    formik.setFieldValue('year', formatDate(date, DateTimeFormats.YEAR_ONLY));
  };

  return (
    <Stack
      direction={{ sm: 'column', md: 'row' }}
      spacing={{ sm: 1, md: 2 }}
    >
      <HIDTextField
        required
        error={Boolean(formik.touched.name && formik.errors.name)}
        helperText={(formik.touched.name) ? formik.errors.name : undefined}
        id="name"
        label={t('finances:budget_name_label')}
        ref={nameInputFieldRef}
        value={currentName}
        onBlur={formik.handleBlur('name')}
        onChange={handleChangeName}
      />
      <HIDFormDatePicker
        required
        dateTimeFormat={DateTimeFormats.YEAR_ONLY}
        error={Boolean(formik.touched.year && formik.errors.year)}
        helperText={(formik.touched.year) ? formik.errors.year : undefined}
        label={t('finances:budget_year_label')}
        ref={yearInputFieldRef}
        value={formik.values.year ? new Date(formik.values.year) : undefined}
        views={['year']}
        onBlur={formik.handleBlur('year')}
        onChange={handleChangeDate}
      />
    </Stack>
  );
};

export default BudgetHeaderFields;
