import React from 'react';
import {
  Add,
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { ManageConnections } from '../../../../../../components/icons/Icons';
import HIDActionsSection from '../../../../../../components/HIDActionsSection';
import {
  FCC,
  HIDListItemProps,
} from '../../../../../../types/common';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';

type ViewEntityActionsProps = {
  title?: string;
  customActions?: Array<HIDListItemProps>
  onAdd?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  onManageConnections?: () => void;
};

const ViewEntityActions: FCC<ViewEntityActionsProps> = ({
  title,
  customActions,
  onAdd,
  onUpdate,
  onDelete,
  onManageConnections,
  children,
}) => {
  const { t } = useTranslation(['common', 'receipts']);

  const { data: { canCreate, canUpdate, canDelete } = {} } = useGetPropertyPermissions();

  const actions = [
    onManageConnections && canCreate && canUpdate && {
      id: 'manage_connections',
      Icon: ManageConnections,
      label: t('common:manage_connections'),
      onClick: onManageConnections,
    },
    onAdd && canCreate && {
      id: 'add',
      Icon: Add,
      label: t('common:add_label'),
      onClick: onAdd,
    },
    onUpdate && canUpdate && {
      id: 'update',
      Icon: EditOutlined,
      label: t('common:update_label'),
      onClick: onUpdate,
    },
    onDelete && canDelete && {
      id: 'delete',
      Icon: DeleteOutline,
      label: t('common:delete_label'),
      onClick: onDelete,
    },
    ...(customActions || []),
  ].filter(Boolean);

  return actions?.length
    ? (
      <HIDActionsSection
        items={actions}
        title={title || t('common:manage')}
      >
        {children}
      </HIDActionsSection>
    )
    : null;
};

export default ViewEntityActions;
