import React from 'react';
import {
  DialogActions,
  Stack,
  useTheme,
} from '@mui/material';
import { FCC } from '../../types/common';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';

const HIDDialogActions: FCC = ({
  children,
  sx,
  style,
}) => {
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  return (
    <DialogActions
      style={style}
      sx={{
        padding: theme.spacing(2.5),
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 1,
        borderColor: theme.palette.grey[300],
        ...sx,
      }}
    >
      <Stack
        alignItems={isDownSm ? 'stretch' : 'center'}
        direction={isDownSm ? 'column' : 'row'}
        justifyContent={isDownSm ? 'center' : 'flex-end'}
        spacing={1.5}
        width={{ width: '100%' }}
      >
        {children}
      </Stack>
    </DialogActions>
  );
};

export default HIDDialogActions;
