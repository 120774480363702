import React, { FC } from 'react';
import { Stack } from '@mui/material';

import {
  DynamicContainerInputStyle,
  DynamicTextField,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

const DynamicContentText: FC<DynamicTextField & DynamicContainerInputStyle> = ({
  value,
  description,
  source,
  textStyle,
}) => {
  // TODO: Make it better on the api side
  const variant = textStyle?.toLocaleLowerCase()?.includes('bold') ? 'subtitle1' : 'body1';

  return (
    <Stack>
      <HIDInfo
        isMarkdown
        description={description}
        label={value}
        labelVariant={variant}
      />
      <DynamicContentSource source={source} />
    </Stack>
  );
};
export default DynamicContentText;
