import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
} from '@mui/material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import PropertyProgressCategorySectionContainer from './PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from './PropertyProgressCategorySectionContent';
import { Task } from '../../Content/modules/GoodToDoTask/types.task';
import { PropertyProgressCategorySectionProps } from '../types.propertyProgress';
import HIDInfo from '../../../../../components/HIDInfo';
import PropertyTask from './PropertyTask';

type PropertyTasksSectionProps = PropertyProgressCategorySectionProps<{ tasks: Array<Task> }> & {
  description?: string;
  taskSectionTitle?: string;
  infoTitle?: string;
  infoDescription?: string;
};

const PropertyTasksSection: FC<PropertyTasksSectionProps> = ({
  section,
  description,
  taskSectionTitle,
  infoTitle,
  infoDescription,
}) => {
  const { t } = useTranslation();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleNavigateToAddTask = () => openMobileAppDialog({ unavailableFeature: EntityType.TASK });

  const handleNavigateToTask = () => openMobileAppDialog({ unavailableFeature: EntityType.TASK });

  const handleNavigateToTasks = () => openMobileAppDialog({ unavailableFeature: EntityType.TASK });

  const tasks = section.data.tasks || [];

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_planning_add_task')}
          description={description}
          viewAllButtonText={t('property:property_progress_property_planning_show_everyone_to_do_tasks')}
          onAddClick={handleNavigateToAddTask}
          onViewAllClick={handleNavigateToTasks}
        >
          {Boolean(infoTitle && infoDescription) && (
            <HIDInfo
              isMarkdown
              bodyTextSx={{ whiteSpace: 'pre-line' }}
              description={infoDescription as string}
              label={infoTitle as string}
            />
          )}
          {Boolean(tasks.length && taskSectionTitle) && (
            <Typography variant="subtitle1">{taskSectionTitle}</Typography>
          )}
          <Grid
            container
            spacing={1.5}
            xxs={12}
          >
            {tasks?.map((task) => (
              <Grid
                item
                key={task.id}
                md={6}
                xxs={12}
              >
                <PropertyTask
                  task={task}
                  onClick={handleNavigateToTask}
                />
              </Grid>
            ))}
          </Grid>
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyTasksSection;
