import React, {
  FC,
  ElementType,
  HTMLAttributes,
} from 'react';
import {
  Typography,
  useTheme,
  Stack,
  AutocompleteRenderOptionState,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

type AutocompleteOptionProps = {
  Icon: ElementType;
  label: string;
  category?: string;
  attributes: HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
};

const AutocompleteOption: FC<AutocompleteOptionProps> = ({
  Icon,
  label,
  category,
  attributes,
  state,
}) => {
  const theme = useTheme();
  const matches = match(label, state.inputValue, { insideWords: true });
  const parts = parse(label, matches);

  return (
    <Stack
      {...attributes}
      alignItems="center"
      component="li"
      direction="row"
    >
      <Icon sx={{
        color: theme.palette.common.black,
        marginRight: theme.spacing(1),
      }}
      />
      <Typography
        sx={{
          marginRight: 'auto',
        }}
        variant="body2"
      >
        {(parts as Array<{ text: string; highlight: boolean }>).map((part, index) => (
          <span
            key={part.text + index}
            style={{
              color: part.highlight ? theme.palette.primary.main : theme.palette.common.black,
            }}
          >
            {part.text}
          </span>
        ))}
      </Typography>
      {
        category && (
          <Typography
            color={theme.palette.grey[500]}
            variant="body2"
          >
            {category}
          </Typography>
        )
      }
    </Stack>
  );
};

export default AutocompleteOption;
