import * as React from 'react';

import { Icon } from '../../types/common';
import { ICON_SIZE } from '../../constants/layout';

const Google: Icon = ({ size = ICON_SIZE }) => (
  <svg
    fill="none"
    height={20}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.825 10.224c0-.659-.058-1.284-.158-1.892H10.25v3.758h5.392c-.242 1.234-.95 2.275-2 2.984v2.5h3.216c1.884-1.742 2.967-4.309 2.967-7.35Z"
      fill="#4285F4"
    />
    <path
      d="M10.25 20.002c2.7 0 4.959-.9 6.608-2.425l-3.216-2.5c-.9.6-2.042.967-3.392.967-2.608 0-4.816-1.759-5.608-4.134H1.325v2.575c1.642 3.267 5.017 5.517 8.925 5.517Z"
      fill="#34A853"
    />
    <path
      d="M4.642 11.907A5.803 5.803 0 0 1 4.325 10c0-.667.117-1.308.317-1.908V5.516H1.325a9.884 9.884 0 0 0 0 8.966l3.317-2.575Z"
      fill="#FBBC05"
    />
    <path
      d="M10.25 3.958c1.475 0 2.792.509 3.834 1.5l2.85-2.85C15.207.992 12.95 0 10.25 0 6.342 0 2.967 2.25 1.325 5.517l3.317 2.575c.792-2.375 3-4.134 5.608-4.134Z"
      fill="#EA4335"
    />
  </svg>
);
export default Google;
