import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const PiggyBank: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m21.52922,16.83936l0,-2.79681l-1.95116,-0.92682l-0.81691,-1.70901l0,-2.25213l-1.9661,0.84188c0,0 -1.60701,-0.61532 -3.07032,-0.61532l-2.43893,0c-3.41447,0 -6.82895,2.20511 -6.82894,5.59369c0,3.26772 2.43891,5.12754 2.43891,5.12754l0.48778,2.33073l2.43894,0l0.48777,-1.39844l3.41447,0l0.48777,1.39844l2.43893,0l0.48777,-2.33073l2.43886,-2.33073l1.95116,-0.93229z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m2.47078,12.94857l0,0.63232c0,0.69556 0.56908,1.26463 1.26462,1.26463l0.63231,0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m10.14765,12.4741l3.79383,0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m9.19907,1.567l0,4.74232"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.75}
      />
      <path
        d="m7.30226,3.46392l1.89695,-1.89692l1.89688,1.89692"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.75}
      />
      <path
        d="m14.88985,6.3092l0,-4.74232"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.75}
      />
      <path
        d="m16.78666,4.41227l-1.89695,1.89693l-1.89688,-1.89693"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.75}
      />
    </HIDSvgIcon>
  );
};

export default PiggyBank;
