import React, {
  FC,
  useMemo,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDInlineSelect from '../../../../../../../../../components/HIDInlineSelect';
import ListStructureLoaderSkeleton from '../../../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import HIDFiltersContainer from '../../../../../../../../../components/HIDFiltersContainer';
import useScrollWithShadow from '../../../../../../../../../hooks/useScrollWithShadow';
import { useGetBudgetQuery } from '../../../api/api.budgets';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { ALL_BUDGET_CATEGORY } from '../../../constants.budgets';

type BudgetCategoriesQuickNavigationProps = {
  budgetId: string;
  categoryId: string;
  onCategoryIdChanged: (categoryId: string) => void;
};

const BudgetCategoriesQuickNavigation: FC<BudgetCategoriesQuickNavigationProps> = ({
  budgetId,
  categoryId,
  onCategoryIdChanged,
}) => {
  const { t } = useTranslation(['common']);

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: budget,
    isLoading: isBudgetLoading,
  } = useGetBudgetQuery(propertyId ? { propertyId, budgetId } : skipToken);

  const mappedCategories = useMemo(
    () =>
      budget
        ? [
          {
            id: ALL_BUDGET_CATEGORY,
            label: t('common:all_label'),
          },
          ...budget.categories.map((category) => ({ id: category.categoryId, label: category.label })),
        ]
        : [],
    [budget],
  );

  return (
    <ListStructureLoaderSkeleton isLoading={isBudgetLoading}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('common:categories')}
        </Typography>
        <HIDFiltersContainer
          ref={scrollContainerRef}
          sx={{
            boxShadow,
            mt: 1,
            pr: 1,
            minHeight: 'unset',
          }}
          onScroll={onScrollHandler}
        >
          <HIDInlineSelect
            items={mappedCategories}
            value={categoryId}
            onChange={(id) => onCategoryIdChanged(id)}
          />
        </HIDFiltersContainer>
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default BudgetCategoriesQuickNavigation;
