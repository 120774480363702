import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { SelectItem } from '../../../../../../../components/HIDInlineSelect';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import { SearchResultItem } from '../types.search';
import { ALL_SEARCH_RESULTS_CATEGORY } from '../constants.search';

const useGetSearchResultsCategoriesInfo = (
  data: SearchResultItem[] | undefined,
) => {
  const { t, i18n: { language } } = useTranslation(['common', 'search']);
  const getEntityInfo = useGetEntityInfo();

  return useMemo(() => {
    if (data?.length) {
      const counters = {} as Record<string, number>;
      const categories: Array<SelectItem> = [];

      data.forEach((item) => {
        counters[item.type] = item.hits.length;
        categories.push({
          id: item.type,
          label: getEntityInfo(item.type)?.name || item.type,
          count: item.hits.length,
        });
      });

      const totalCount = R.sum(R.values(counters));

      return {
        counters: { ...counters, [ALL_SEARCH_RESULTS_CATEGORY]: totalCount },
        categories: [{
          id: ALL_SEARCH_RESULTS_CATEGORY,
          label: t('common:all_label'),
          count: totalCount,
        }, ...categories],
      };
    }

    return {
      counters: {},
      categories: [],
    };
  }, [data, language]);
};

export default useGetSearchResultsCategoriesInfo;
