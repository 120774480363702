import {
  Stack,
  useTheme,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { getTypographyHeight } from '../../../../../../utils/style';
import useGetEntityInfo from '../../hooks/useGetEntityInfo';

type ListEntitiesToolbarCountLabelProps = {
  label: string | undefined;
  count: number;
  entity: EntityType | undefined;
  isUpdating: boolean | undefined;
};

const ListEntitiesToolbarCountLabel: FC<ListEntitiesToolbarCountLabelProps> = ({
  count,
  label,
  entity,
  isUpdating,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'entities']);

  const getEntityInfo = useGetEntityInfo();
  const entityInfo = entity ? getEntityInfo(entity) : undefined;

  if (entity && !label) {
    return (
      <Stack alignItems="center" direction="row" spacing={1}>
        {isUpdating && <CircularProgress size={getTypographyHeight(theme.typography.body1)} />}
        <Typography variant="body1">
          {isUpdating ? null : count}{' '}
          {count > 1 ? entityInfo?.namePlural : entityInfo?.name}
        </Typography>
      </Stack>
    );
  }

  return (
    <Typography variant="body1">{label ?? t('common:items_count', { count })}</Typography>
  );
};

export default ListEntitiesToolbarCountLabel;
