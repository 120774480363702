import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Prospect: Icon = ({
  fillColor: fillColorProp,
  size,
}) => {
  const theme = useTheme();

  const fillColor = fillColorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path d="M8 10H7C7 10.5523 7.44772 11 8 11V10ZM16 10V11C16.5523 11 17 10.5523 17 10H16ZM16 6H17C17 5.44772 16.5523 5 16 5V6ZM8 6V5C7.44772 5 7 5.44772 7 6H8ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15V13ZM16 15C16.5523 15 17 14.5523 17 14C17 13.4477 16.5523 13 16 13V15ZM8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19V17ZM16 19C16.5523 19 17 18.5523 17 18C17 17.4477 16.5523 17 16 17V19ZM6 3H18V1H6V3ZM19 4V20H21V4H19ZM18 21H6V23H18V21ZM5 20V4H3V20H5ZM6 21C5.44772 21 5 20.5523 5 20H3C3 21.6569 4.34315 23 6 23V21ZM19 20C19 20.5523 18.5523 21 18 21V23C19.6569 23 21 21.6569 21 20H19ZM18 3C18.5523 3 19 3.44772 19 4H21C21 2.34315 19.6569 1 18 1V3ZM6 1C4.34315 1 3 2.34315 3 4H5C5 3.44772 5.44772 3 6 3V1ZM8 11H16V9H8V11ZM17 10V6H15V10H17ZM16 5H8V7H16V5ZM7 6V10H9V6H7ZM8 15H16V13H8V15ZM8 19H16V17H8V19Z" fill={fillColor} />
    </HIDSvgIcon>
  );
};

export default Prospect;
