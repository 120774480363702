import React, {
  FC,
} from 'react';

import {
  DynamicExternalServiceInput,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import { ExternalService } from '../../../../../../../../../types/common';
import ExternalServiceCard from '../../../../../../../pages/ExternalServices/components/ExternalServiceCard';

type DynamicContentExternalServiceCardProps = DynamicExternalServiceInput & DynamicInputCommonFieldProps<ExternalService>;

const DynamicContentExternalServiceCard: FC<DynamicContentExternalServiceCardProps> = ({
  value,
}) => {
  const service = value;
  return service ? <ExternalServiceCard externalService={service} /> : null;
};

export default DynamicContentExternalServiceCard;
