import React, { FC } from 'react';

import PropertyProgressDynamicContentSection from '../../../components/PropertyProgressDynamicContentSection';
import {
  PropertyProgressCategorySectionProps,
  PropertyProgressDynamicContentSectionData,
} from '../../../types.propertyProgress';

const PropertyFinancesPropertySoldSection: FC<PropertyProgressCategorySectionProps<PropertyProgressDynamicContentSectionData>> = ({
  section,
}) => (
  <PropertyProgressDynamicContentSection
    key={section.id}
    section={section}
  />
);

export default PropertyFinancesPropertySoldSection;
