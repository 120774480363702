import React from 'react';
import {
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import {
  FCC,
} from '../../../types/common';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.error.lightest,
  padding: theme.spacing(2.5),
  borderRadius: '5px',
  border: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
}));

type ErrorBoxProps = {
  message: string;
};

const ErrorBox: FCC<ErrorBoxProps> = ({
  message,
  sx,
  style,
}) => (
  <StyledStack flexDirection="row" style={style} sx={sx}>
    <Cancel color="error" />
    <Typography marginLeft={1.5}>
      {message}
    </Typography>
  </StyledStack>
);

export default ErrorBox;
