import React from 'react';
import {
  Box,
  LinearProgressProps,
  Stack,
  Typography,
} from '@mui/material';
import { FCC } from '../../types/common';
import HIDLinearProgress from './HIDLinearProgress';

type HIDLinearProgressWithLabelProps = {
  value: number,
  variant?: LinearProgressProps['variant'],
};

const HIDLinearProgressWithLabel: FCC<HIDLinearProgressWithLabelProps> = ({
  value,
  variant = 'determinate',
  sx,
}) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="center"
  >
    <Box sx={{ width: '100%', marginRight: 1 }}>
      <HIDLinearProgress
        sx={{
          width: '100%',
          ...sx,
        }}
        value={value}
        variant={variant}
      />
    </Box>
    <Typography
      alignSelf="center"
      color="text.secondary"
      justifySelf="center"
      variant="body2"
    >
      {`${Math.round(value)}%`}
    </Typography>
  </Stack>
);

export default HIDLinearProgressWithLabel;
