import {
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material';
import React from 'react';

import { FCC } from '../../types/common';

const ChartLoaderSkeleton: FCC<{ isLoading: boolean; height?: number }> = ({
  height = 300,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack
        alignItems="flex-end"
        direction="row"
        gap={3}
        height={height}
        justifyContent="center"
        sx={{
          borderLeft: 1,
          borderBottom: 1,
          borderColor: theme.palette.grey[300],
          width: '100%',
        }}
      >
        <Skeleton height="40%" variant="rounded" width="10%" />
        <Skeleton height="60%" variant="rounded" width="10%" />
        <Skeleton height="90%" variant="rounded" width="10%" />
        <Skeleton height="70%" variant="rounded" width="10%" />
        <Skeleton height="35%" variant="rounded" width="10%" />
      </Stack>
    );
  }

  return children;
};

export default ChartLoaderSkeleton;
