import React, {
  FC,
} from 'react';
import {
  PaymentTypeBasedComponentProps,
} from '@house-id/houseid-types/dist/finances/budgets';

import MonthlyAllocatedBudget from './MonthlyAllocatedBudget';

const IndividualMonthlyAllocatedBudget: FC<PaymentTypeBasedComponentProps> = ({
  data,
  categoryId,
  subcategoryIndex,
}) => data.paymentMonths?.length
  ? (
    <MonthlyAllocatedBudget
      categoryId={categoryId}
      paymentMonths={data.paymentMonths}
      subcategoryIndex={subcategoryIndex}
    />
  )
  : null;

export default IndividualMonthlyAllocatedBudget;
