import React, { FC } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { YesNoEnum } from '../types/common';

type HIDYesNoGroupProps = {
  value: boolean;
  direction?: 'row' | 'column';
  yesLabel?: string;
  noLabel?: string;
  onChange: (newValue: boolean) => void;
};

const HIDYesNoGroup: FC<HIDYesNoGroupProps> = ({
  value,
  direction = 'column',
  yesLabel,
  noLabel,
  onChange,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  return (
    <RadioGroup
      aria-labelledby="yes-no-group-label"
      row={direction === 'row'}
      value={value ? YesNoEnum.YES : YesNoEnum.NO}
      onChange={(_event, value) => onChange(value as YesNoEnum === YesNoEnum.YES)}
    >
      <FormControlLabel
        control={<Radio />}
        label={yesLabel || t('common:yes')}
        style={{ marginRight: theme.spacing(3) }}
        value={YesNoEnum.YES}
      />
      <FormControlLabel
        control={<Radio />}
        label={noLabel || t('common:no')}
        style={{ marginRight: theme.spacing(3) }}
        value={YesNoEnum.NO}
      />
    </RadioGroup>
  );
};

export default HIDYesNoGroup;
