import {
  Badge,
  BadgeProps,
  styled,
} from '@mui/material';

const HIDBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.common.white,
  },
}));

export default HIDBadge;
