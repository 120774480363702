import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import {
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import {
  Product,
  ExternalMedia,
  ExternalMediaType,
  SpecificationDataType,
  SpecificationItem,
  SpecificationItemLink,
  SpecificationItemRange,
  SpecificationItemInterval,
  SpecificationCategory,
  ProductSummaryItem,
} from '@house-id/houseid-types/dist/content/product';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { range } from '../../../../../../utils/array';
import {
  useGetFormatMonth,
  useGetFormatYear,
} from '../../../../../../utils/translation';
import {
  ParsedSpecificationCategory,
  ProductExternalImage,
  ProductIdentifier,
} from './types.product';
import { TimeIntervalsGroupingType } from '../../types/types.content';
import {
  getDateChartGroupingKey,
  getDateChartGroupingKeyByIndex,
} from '../../utils/chartData';
import { getCreateDocumentPath } from '../Document/navigation.document';
import { getCreatePhotosPath } from '../Photo/navigation.photo';
import { getCreateNotePath } from '../Note/navigation.note';
import { EMPTY_VALUE } from '../../../../../../utils/string';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';

export const getFirstExternalImageUrl = (externalMedia?: Array<ExternalMedia>) => {
  const externalImage = externalMedia?.find((media) => media.type === ExternalMediaType.IMAGE && (media.thumbnailUrl || media.url));
  return externalImage?.thumbnailUrl || externalImage?.url;
};
export const getExternalMediaByType = (mediaType: ExternalMediaType, externalMedia?: Array<ExternalMedia>) => externalMedia
  ?.filter((media) => media.type === mediaType);

const LIFE_TIME_OPTIONS = [
  { years: 0, month: 1 }, // 1m
  ...range(3, 9, 3).map((i) => ({ years: 0, month: i })), // 3m 6m 9m
  ...range(1, 20).map((i) => ({ years: i, month: 0 })), // 1y ... 20y
  ...range(25, 60, 5).map((i) => ({ years: i, month: 0 })), // 25y 30y 35y ... 60y
];

export const useGetPeriodLabelFromMonth = () => {
  const formatMonth = useGetFormatMonth();
  const formatYear = useGetFormatYear();

  return (monthCount: number) => {
    const years = Math.floor(monthCount / 12);
    const month = monthCount - years * 12;

    return `${formatYear(years)} ${formatMonth(month)}`.trim();
  };
};

export const useGetPeriodListItems = (dateOptions = LIFE_TIME_OPTIONS) => {
  const formatMonth = useGetFormatMonth();
  const formatYear = useGetFormatYear();

  return dateOptions
    .map(({ years, month }) => ({
      id: (month + 12 * years).toString(),
      name: `${formatYear(years)} ${formatMonth(month)}`.trim(),
    }));
};

export const getIdentifierValue = (identifier: ProductIdentifier, product: Partial<Product>) => product.identifiers?.[identifier];

export const getIdentifiers = (product: Product) => ([ProductIdentifier.RSK, ProductIdentifier.GTIN] as Array<ProductIdentifier>)
  .map((identifier) => ({
    label: identifier.toUpperCase(),
    value: getIdentifierValue(identifier, product),
  }))
  .filter(({ value }) => value);

const formatLink = (value: SpecificationItemLink | Array<SpecificationItemLink>) => Array.isArray(value)
  ? value.find(({ url }) => url)?.url
  : value.url;

const formatInterval = (interval: SpecificationItemInterval) => `${interval.from} - ${interval.to}`;
const formatRange = (range: SpecificationItemRange) => `${range[0]} - ${range[1]}`;

export const useParseSpecificationValue = () => {
  const { t } = useTranslation(['common']);

  return (item: SpecificationItem) => {
    const unit = item.unit ? ` ${item.unit}` : '';
    return match(item.dataType)
      .with(SpecificationDataType.STRING, () => `${(item.value as string) || EMPTY_VALUE} ${unit}`)
      .with(SpecificationDataType.INTEGER, () => `${(item.value as number).toString()} ${unit}`)
      .with(SpecificationDataType.DECIMAL, () => `${(item.value as number).toString()} ${unit}`)
      .with(SpecificationDataType.LINK, () => formatLink(item.value as SpecificationItemLink | Array<SpecificationItemLink>))
      .with(SpecificationDataType.LIST, () => (item.value as Array<string>).join(', '))
      .with(SpecificationDataType.BOOLEAN, () => item.value ? t('common:yes') : t('common:no'))
      .with(SpecificationDataType.INTERVAL, () => `${formatInterval(item.value as SpecificationItemInterval)}${unit}`)
      .with(SpecificationDataType.RANGE, () => `${formatRange(item.value as SpecificationItemRange)}${unit}`)
      .exhaustive();
  };
};

export const useParseSpecificationCategory = () => {
  const parseSpecificationValue = useParseSpecificationValue();
  const parseSpecificationItem = (item: SpecificationItem) => ({
    name: item.name,
    value: parseSpecificationValue(item),
    isLink: item.dataType === SpecificationDataType.LINK,
  });

  const parseSpecificationCategory = (category: SpecificationCategory): ParsedSpecificationCategory => ({
    name: category.name,
    children: category.children
      ?.map((categoryOrItem) => (categoryOrItem as SpecificationCategory).children
        ? parseSpecificationCategory(categoryOrItem as SpecificationCategory)
        : parseSpecificationItem(categoryOrItem as SpecificationItem)),
  });

  return parseSpecificationCategory;
};

const mapProductSummaryValue = (
  chartGroupingType: TimeIntervalsGroupingType,
  {
    date,
    totalPrice,
    count,
  }: ProductSummaryItem,
): {
  name: string,
  amount?: number,
  lastAmount?: number,
  count: number,
} => ({
  name: getDateChartGroupingKey(chartGroupingType, new Date(date)),
  amount: totalPrice,
  count,
});

export const getProductsSummaryChartData = (
  summaryValues: Array<ProductSummaryItem>,
  chartGroupingType = TimeIntervalsGroupingType.Yearly,
) => {
  if (summaryValues.length <= 1) {
    return summaryValues.map((value) => mapProductSummaryValue(chartGroupingType, value));
  }

  const summaryDatesMap = R.indexBy(({ date }) => getDateChartGroupingKey(chartGroupingType, new Date(date)), summaryValues);

  const fromDate = new Date((R.head(summaryValues) as ProductSummaryItem).date);
  const toDate = new Date((R.last(summaryValues) as ProductSummaryItem).date);

  const dataPointsCount = chartGroupingType === TimeIntervalsGroupingType.Yearly
    ? differenceInYears(toDate, fromDate)
    : differenceInMonths(toDate, fromDate);

  const mappedSummaryValues = R.range(0, dataPointsCount + 1)
    .map((index: number) => {
      const key = getDateChartGroupingKeyByIndex(chartGroupingType, fromDate, index);

      return summaryDatesMap[key]
        ? mapProductSummaryValue(chartGroupingType, summaryDatesMap[key])
        : {
          name: key,
          amount: 0,
          count: 0,
        };
    });

  if (mappedSummaryValues.length) {
    mappedSummaryValues[mappedSummaryValues.length - 1].lastAmount = mappedSummaryValues[mappedSummaryValues.length - 1].amount;
    mappedSummaryValues[mappedSummaryValues.length - 1].amount = undefined;
  }

  return mappedSummaryValues;
};

export const useGetNavigateAfterExternalMediaCreate = (propertyId?: string, productId?: string) => {
  const navigate = useNavigate();

  const navigateAfterExternalMediaCreate = (
    {
      mediaType,
      contentFileId,
      url,
      name,
    }:
    {
      mediaType: ExternalMediaType,
      contentFileId?: string,
      url: string,
      name?: string,
    },
  ) => {
    const contentFileIds = contentFileId ? [contentFileId] : undefined;
    const state = productId
      ? { connections: [{ entityId: productId, entityType: EntityType.PRODUCT }] }
      : undefined;

    return match(mediaType)
      .with(
        ExternalMediaType.DOCUMENT,
        () => navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, contentFileIds }), { state }),
      )
      .with(
        ExternalMediaType.IMAGE,
        () => navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId, contentFileIds }), { state }),
      )
      .with(
        ExternalMediaType.LINK,
        () => navigate(getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId, url, name }), { state }),
      )
      .with(
        ExternalMediaType.VIDEO,
        () => navigate(getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId, url, name }), { state }),
      )
      .exhaustive();
  };

  return navigateAfterExternalMediaCreate;
};

export const fromSearchResultToExternalSource = (image: ProductExternalImage): ExternalMedia => ({
  type: ExternalMediaType.IMAGE,
  url: (image.url || image.thumbnailUrl) as string,
  thumbnailUrl: (image.thumbnailUrl || image.url) as string,
});

export const hasExternalMedia = (product: Product) => Boolean(
  product.externalMedia?.length
  || product.specification?.length
  || product.descriptions?.length,
);
