import React, {
  FC,
  forwardRef,
} from 'react';
import {
  Cancel,
  Check,
  Clear,
} from '@mui/icons-material';
import {
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';

import {
  FCC,
  FCCProps,
} from '../types/common';
import { Currency } from '../utils/string';
import HIDIconButton from './buttons/HIDIconButton';
import { HIDZIndex } from '../constants/layout';

export const useGetTextFieldProps = ({
  endAdornment,
  error,
  helperTextWrap,
  InputLabelProps,
  InputProps,
  showValidationIcon,
  valid,
  value,
}: {
  endAdornment?: React.JSX.Element;
  error?: TextFieldProps['error'];
  helperTextWrap?: boolean;
  InputLabelProps?: TextFieldProps['InputLabelProps'];
  InputProps?: TextFieldProps['InputProps'];
  showValidationIcon?: boolean;
  valid?: boolean;
  value?: string;
}) => {
  const theme = useTheme();

  return {
    FormHelperTextProps: {
      sx: {
        textWrap: helperTextWrap ? 'wrap' : 'nowrap',
        marginLeft: 0,
      },
    },
    InputLabelProps: {
      sx: {
        zIndex: HIDZIndex.LABEL,
        pointerEvents: 'none',
      },
      ...InputLabelProps,
    },
    InputProps: {
      endAdornment: endAdornment || (
        showValidationIcon && (valid || error)
          ? (
            <InputAdornment position="end">
              <Stack marginBottom={2} paddingX={theme.spacing(1)}>
                {
                  error
                    ? <Cancel color="error" />
                    : valid
                      ? <Check color="primary" />
                      : undefined
                }
              </Stack>
            </InputAdornment>
          )
          : undefined
      ),
      sx: {
        ...(!value && { backgroundColor: theme.palette.primary.lightest }),
        ...InputProps?.sx,
      },
      ...InputProps,
    },
  };
};

export type HIDTextFieldProps = {
  id?: TextFieldProps['id'];
  label?: TextFieldProps['label'];
  placeholder?: TextFieldProps['placeholder'];
  type?: TextFieldProps['type'];
  multiline?: TextFieldProps['multiline'];
  minRows?: TextFieldProps['minRows'];
  variant?: TextFieldProps['variant'];
  inputProps?: TextFieldProps['inputProps'];
  value?: string;
  fullWidth?: TextFieldProps['fullWidth'];
  required?: TextFieldProps['required'];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  showHelperText?: boolean;
  helperTextWrap?: boolean;
  InputProps?: TextFieldProps['InputProps'];
  InputLabelProps?: TextFieldProps['InputLabelProps'];
  valid?: boolean;
  disabled?: boolean;
  showValidationIcon?: boolean;
  endAdornment?: React.JSX.Element;
  onBlur?: TextFieldProps['onBlur'];
  onChange?: TextFieldProps['onChange'];
  onClick?: TextFieldProps['onClick'];
  onFocus?: TextFieldProps['onBlur'];
  onKeyDown?: TextFieldProps['onKeyDown'];
};

type HIDTextFieldPropsWithRef = FCCProps<HIDTextFieldProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDTextField: FCC<HIDTextFieldProps> = forwardRef<HTMLInputElement, HIDTextFieldPropsWithRef>((
  {
    id,
    label,
    placeholder,
    type,
    multiline,
    minRows,
    variant = 'standard',
    inputProps,
    value = '',
    fullWidth = true,
    required,
    error,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    InputProps,
    InputLabelProps,
    valid,
    disabled,
    showValidationIcon = false,
    sx,
    endAdornment,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
  },
  ref,
) => {
  const textFieldProps = useGetTextFieldProps({
    endAdornment,
    error,
    helperTextWrap,
    InputLabelProps,
    InputProps,
    showValidationIcon,
    valid,
    value,
  });

  return (
    <TextField
      {...textFieldProps}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText || (showHelperText ? ' ' : undefined)}
      id={id}
      inputProps={inputProps}
      inputRef={ref}
      label={label}
      minRows={multiline ? (minRows || '3') : undefined}
      multiline={multiline}
      placeholder={placeholder}
      required={required}
      sx={sx}
      type={type}
      value={value}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  );
});

HIDTextField.displayName = 'HIDTextField';

export default HIDTextField;

export const CurrencyAdornment: FC = () => {
  const theme = useTheme();

  return (
    <Typography
      color={theme.palette.grey[500]}
      sx={{ marginLeft: 0.5 }}
      variant="body2"
    >
      {Currency.label}
    </Typography>
  );
};

export const CustomAdornment: FC<{ label: string }> = ({ label }) => {
  const theme = useTheme();

  return (
    <Typography
      color={theme.palette.grey[500]}
      sx={{ marginLeft: 0.5, whiteSpace: 'nowrap' }}
      variant="body2"
    >
      {label}
    </Typography>
  );
};

export const ClearInputAdornment: FC<{ onClick: () => void; }> = ({ onClick }) => (
  <HIDIconButton
    Icon={Clear}
    color="blank"
    size="small"
    sx={{ marginLeft: 0.5, marginBottom: 0.125 }}
    onClick={onClick}
  />
);
