import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Star: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="m12,2.79878l2.98971,6.05672l6.68564,0.97721l-4.83764,4.71193l1.14165,6.65658l-5.97936,-3.14443l-5.97932,3.14443l1.14168,-6.65658l-4.83765,-4.71193l6.68565,-0.97721l2.98965,-6.05672z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Star;
