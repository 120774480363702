import React, {
  FC,
  JSX,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as R from 'ramda';
import { ChevronRight } from '@mui/icons-material';
import {
  PropertyProgress,
  PropertyProgressCategory,
  PropertyProgressCategorySection,
} from '@house-id/houseid-types/dist/propertyProgress';

import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { hidSpacing } from '../../../../../../utils/number';
import useGetPropertyProgressCategoryMap from '../../../../modules/PropertyProgress/hooks/useGetPropertyProgressCategoryMap';
import { getPropertyProgressPercent } from '../../../../modules/PropertyProgress/utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';

const mapCategoryToCategoryWithPercent = (category: PropertyProgressCategory) => ({
  id: category.id,
  name: category.name,
  progress: category.progress,
  percent: getPropertyProgressPercent(category.progress),
});

type CategoryProgressItemProps = {
  id: string;
  name: string;
  percent: number;
};

const CategoryProgressItem: FC<CategoryProgressItemProps> = ({
  id,
  name,
  percent,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const propertyProgressCategoryMap = useGetPropertyProgressCategoryMap();
  const { Icon, getActionPath, onClick } = propertyProgressCategoryMap[id] || {};

  const fillColor = theme.palette.primary.lighter;

  const handleNavigateToCategory = () => {
    const path = getActionPath ? getPathWithPropertyIdOrInit(getActionPath, { propertyId }) : undefined;

    if (path) {
      navigate(path);
    } else if (onClick && propertyId) {
      onClick({ propertyId });
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: 1,
        minWidth: 0,
        borderRadius: '4px',
        background: theme.palette.grey[50],
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        ':before': {
          content: '""',
          position: 'absolute',
          background: fillColor,
          top: 0,
          bottom: 0,
          left: 0,
          width: `${percent}%`,
        },
      }}
      onClick={handleNavigateToCategory}
    >
      <Stack direction="row" spacing={1.5} sx={{ minWidth: 0, zIndex: 1 }}>
        {Boolean(Icon) && <Icon />}
        <Typography
          noWrap
          sx={{ lineHeight: `${hidSpacing(3)}px` }}
          variant="subtitle1"
        >
          {name}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ zIndex: 1 }}>
        <Typography noWrap>{`${percent}% ${t('common:clear')}`}</Typography>
        <ChevronRight />
      </Stack>
    </Stack>
  );
};

type CategoriesSlideProps = {
  propertyProgress?: PropertyProgress;
  SlideHeader: JSX.Element;
};
const HIDDEN_PROPERTY_PROGRESS_CATEGORIES = [
  PropertyProgressCategorySection.SETTINGS, PropertyProgressCategorySection.OFFERS, PropertyProgressCategorySection.SUGGESTION,
];

const CategoriesSlide: FC<CategoriesSlideProps> = ({
  propertyProgress,
  SlideHeader,
}) => {
  const viableCategories = R.filter(
    ({ id }) => !HIDDEN_PROPERTY_PROGRESS_CATEGORIES.includes(id),
    propertyProgress?.categories || [],
  );

  const propertyProgressCategories = R.sortBy(
    R.prop('percent'),
    R.map(mapCategoryToCategoryWithPercent, viableCategories),
  );

  const top3PropertyProgressCategories = propertyProgressCategories
    ? [propertyProgressCategories.at(-1), ...R.slice(0, 2, propertyProgressCategories)].filter(Boolean)
    : [];

  return (
    <>
      {SlideHeader}
      <Stack spacing={1}>
        {top3PropertyProgressCategories.map(({ id, name, percent }) => (
          <CategoryProgressItem
            id={id}
            key={id}
            name={name}
            percent={percent}
          />
        ))}
      </Stack>
    </>
  );
};

export default CategoriesSlide;
