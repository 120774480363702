import React, { FC } from 'react';
import {
  Card,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../constants/columns';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import { useNavigateBackOr } from '../../../../utils/routes';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import {
  getHomePath,
  getPropertyStoragePath,
} from '../../navigation/navigation.property';
import HomeListLayout from '../Home/components/HomeListLayout';
import { PropertyStorageUsage } from '../../types/types.property';
import { formatBytes } from '../../../../utils/string';
import StorageWidget from './components/StorageWidget';
import { getPathWithPropertyIdOrInit } from '../../../Auth/navigation/navigation.auth';
import { useGetUserStorageUsageQuery } from '../../../Auth/api/api.user';
import { useGetPropertiesQuery } from '../../api/api.property';
import { arrToMap } from '../../../../utils/array';
import HIDTypography from '../../../../components/HIDTypography';
import { useSetUserSettingsMutation } from '../../../Auth/api/api.settings';
import PropertySettingsQuickNavigation, { PropertySettingsActionId } from '../../components/PropertySettingsQuickNavigation';
import { Property } from '@house-id/houseid-types/dist/property';

const UserStorage: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'property']);
  const { isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [setUserSettings] = useSetUserSettingsMutation();

  const { data: properties = [] } = useGetPropertiesQuery();
  const { data: storageUsage, isLoading } = useGetUserStorageUsageQuery();

  const propertiesIdNameMap = arrToMap(
    (property: Property) => ([property.id, property.displayName]),
    properties,
  );

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.5 : 0.8,
      type: 'string',
      sortable: !isDownSm,
      renderCell: (params: GridRenderCellParams) => {
        const propertyStorageUsage = params?.row as PropertyStorageUsage;
        return (
          <HIDTypography noWrap variant="subtitle1">
            {propertiesIdNameMap[propertyStorageUsage.propertyId]}
          </HIDTypography>
        );
      },
    },
    {
      field: 'totalSize',
      headerName: t('common:total_size'),
      flex: isDownSm ? 0.5 : 0.4,
      type: 'number',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const propertyStorageUsage = params?.row as PropertyStorageUsage;
        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {formatBytes(propertyStorageUsage.totalSize)}
          </Typography>
        );
      },
    } as GridColDef,
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const { propertyId } = params?.row as PropertyStorageUsage;
    if (propertyId) {
      setUserSettings({ currentPropertyId: propertyId });
      navigate(getPropertyStoragePath({ propertyId }));
    }
  };

  const storageWidgetItems = storageUsage?.properties
    ?.map(({ totalSize, propertyId }) => ({
      totalSize,
      name: propertiesIdNameMap[propertyId] || t('property:unverified_property'),
    }));

  return (
    <HomeListLayout
      ListHeaderComponent={(
        <StorageWidget
          isLoading={isLoading}
          quota={storageUsage?.quota || 0}
          storageItems={storageWidgetItems || []}
        />
      )}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <PropertySettingsQuickNavigation currentPropertySettingsActionId={PropertySettingsActionId.STORAGE} />
        </Card>
      }
      columns={columns}
      getRowId={(row: PropertyStorageUsage) => row.propertyId}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={storageUsage?.properties || []}
      sideDrawerElements={[
        <PropertySettingsQuickNavigation
          currentPropertySettingsActionId={PropertySettingsActionId.STORAGE}
          key={PropertySettingsQuickNavigation.name}
        />,
      ]}
      title={t('property:property_settings_storage_action')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
    />
  );
};

export default UserStorage;
