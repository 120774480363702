import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useNavigate } from 'react-router';

import HistoryIcon from '@mui/icons-material/History';
import HIDInlineSelect, { SelectItem } from '../../../components/HIDInlineSelect';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';
import { FCC } from '../../../types/common';
import {
  Crown,
} from '../../../components/icons/Icons';
import {
  getSubscriptionPlanPaymentHistoryPath,
  getSubscriptionPlansPath,
} from '../navigation/navigation.subscriptionPlans';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import theme from '../../../theme';
import useGetCurrentUserSubscriptionPlanId from '../hooks/useGetCurrentUserSubscriptionPlanId';

export enum SubscriptionPlansActionId {
  SUBSCRIPTION_PLANS = 'subscriptionPlans',
  SUBSCRIPTION_HISTORY = 'subscriptionHistory',
}

type SubscriptionPlansQuickNavigationProps = {
  subscriptionsActionId: SubscriptionPlansActionId;
};

const SubscriptionPlansQuickNavigation: FCC<SubscriptionPlansQuickNavigationProps> = ({
  subscriptionsActionId,
}) => {
  const { user: currentUser } = useGetActiveAuthUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['subscriptions']);

  const { data: { isFree } = {} } = useGetCurrentUserSubscriptionPlanId();

  const customActions: Record<string, SelectItem & { onClick: () => void; enabled: boolean }> = {
    [SubscriptionPlansActionId.SUBSCRIPTION_PLANS]: {
      id: SubscriptionPlansActionId.SUBSCRIPTION_PLANS,
      label: t('subscriptions:our_subscription_plans'),
      Icon: () => <Crown fillColor={theme.palette.common.white} />,
      enabled: true,
      onClick: () => navigate(getSubscriptionPlansPath()),
    },
    [SubscriptionPlansActionId.SUBSCRIPTION_HISTORY]: {
      id: SubscriptionPlansActionId.SUBSCRIPTION_HISTORY,
      label: t('subscriptions:subscription_history'),
      Icon: HistoryIcon,
      enabled: !isFree && Boolean(currentUser),
      onClick: () => navigate(getSubscriptionPlanPaymentHistoryPath()),
    },
  };

  const handleCategoryChange = (categoryId: string) => {
    const currentCategory = customActions[categoryId];
    if (currentCategory?.onClick) {
      currentCategory.onClick();
      dispatch(toggleSideDrawerOpen(false));
    }
  };

  const mappedCategories = R.values(customActions)
    .filter(({ enabled }) => enabled)
    .map((action) => ({
      id: action.id,
      label: action.label,
      Icon: action.Icon,
    }));

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {t('subscriptions:our_subscription_plans')}
      </Typography>
      <HIDInlineSelect
        items={mappedCategories}
        value={subscriptionsActionId}
        onChange={handleCategoryChange}
      />
    </Stack>
  );
};

export default SubscriptionPlansQuickNavigation;
