import React, {
  FC,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListStructureLoaderSkeleton from '../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import HIDInlineSelect, { SelectItem } from '../../../../../../../components/HIDInlineSelect';

type SearchResultsQuickNavigationProps = {
  isLoading: boolean;
  selectedCategoryId: string;
  categories: Array<SelectItem>;
  onSetSelectedCategoryId: (id: string) => void;
};

const SearchResultsQuickNavigation: FC<SearchResultsQuickNavigationProps> = ({
  isLoading,
  selectedCategoryId,
  categories,
  onSetSelectedCategoryId,
}) => {
  const { t } = useTranslation(['common', 'search']);

  return (
    <ListStructureLoaderSkeleton isLoading={isLoading}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('search:sidebar_title')}
        </Typography>
        <HIDInlineSelect
          items={categories}
          value={selectedCategoryId}
          onChange={onSetSelectedCategoryId}
        />
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default SearchResultsQuickNavigation;
