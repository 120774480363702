import React, {
  FC,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import { useNavigateBackOr } from '../../../utils/routes';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import HIDTextField from '../../../components/HIDTextField';
import { getActiveAuth } from '../../../external-services/firebase';
import InfoBox from '../../../components/InfoBox';
import HIDButton from '../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import {
  getUserSettingsOnboardingPath,
  getUserSettingsPath,
} from '../navigation/navigation.auth';

const ResetPassword: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['settings', 'forms_common', 'auth']);

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [showInformationBox, setShowInformationBox] = useState(false);

  const [sendPasswordResetEmail, loading, resetPasswordError] = useSendPasswordResetEmail(getActiveAuth());

  const handleResetPassword = (email: string) => sendPasswordResetEmail(email)
    .finally(() => setShowInformationBox(true));

  const schema = Yup.object({
    email: Yup.string().email(t('forms_common:email_format_wrong')).required(t('forms_common:email_required')),
  });

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => handleResetPassword(values.email),
  });

  const handleGoBack = () => navigateBackOr(propertyId ? getUserSettingsPath({ propertyId }) : getUserSettingsOnboardingPath());

  return (
    <HomeLayout
      SideColumn={<div />}
      title={t('settings:settings_reset_password')}
      onBack={handleGoBack}
    >
      <Stack direction="column" spacing={3}>
        {showInformationBox && (
          <InfoBox
            message={
              // TODO: add error message mapping
              resetPasswordError?.message || t('auth:forgot_password_email_sent_message', { email: formik.values.email })
            }
          />
        )}
        <HIDTextField
          required
          showValidationIcon
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email ? formik.errors.email : undefined}
          id="email"
          label={t('forms_common:email_title')}
          type="email"
          value={formik.values.email}
          onBlur={formik.handleBlur('email')}
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
        />
        <HIDButton
          disabled={!formik.dirty || !formik.isValid || loading}
          fullWidth={isDownSm}
          loading={loading}
          size="large"
          sx={{ alignSelf: isDownSm ? 'center' : 'flex-end' }}
          onClick={() => formik.submitForm()}
        >
          {t('auth:forgot_password_reset_password_button')}
        </HIDButton>
      </Stack>
    </HomeLayout>
  );
};

export default ResetPassword;
