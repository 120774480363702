import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Building: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="M5 16V12H9V16"
        fill={fillColor}
        fillRule="evenodd"
      />
      <path
        d="M5 16V12H9V16H5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M5 16H4C4 16.5523 4.44772 17 5 17V16ZM5 12V11C4.44772 11 4 11.4477 4 12H5ZM9 12H10C10 11.4477 9.55229 11 9 11V12ZM9 16V17C9.55229 17 10 16.5523 10 16H9ZM12 23C12 23.5523 12.4477 24 13 24C13.5523 24 14 23.5523 14 23H12ZM13 12V11C12.4477 11 12 11.4477 12 12H13ZM23 12H24C24 11.4477 23.5523 11 23 11V12ZM23 23V24C23.5523 24 24 23.5523 24 23H23ZM1 8.35001L0.344621 7.59471C0.125728 7.78465 0 8.0602 0 8.35001H1ZM9.47061 1L10.126 0.244702C9.74998 -0.0815666 9.19124 -0.0815673 8.81523 0.2447L9.47061 1ZM17.9412 8.35001H18.9412C18.9412 8.0602 18.8155 7.78465 18.5966 7.59471L17.9412 8.35001ZM16.9412 11.45C16.9412 12.0023 17.3889 12.45 17.9412 12.45C18.4935 12.45 18.9412 12.0023 18.9412 11.45H16.9412ZM1 23H0V24H1V23ZM16.5 15C15.9477 15 15.5 15.4477 15.5 16C15.5 16.5523 15.9477 17 16.5 17V15ZM19.5 17C20.0523 17 20.5 16.5523 20.5 16C20.5 15.4477 20.0523 15 19.5 15V17ZM6 16V12H4V16H6ZM5 13H9V11H5V13ZM8 12V16H10V12H8ZM9 15H5V17H9V15ZM14 23V12H12V23H14ZM13 13H23V11H13V13ZM22 12V23H24V12H22ZM1.65538 9.10531L10.126 1.7553L8.81523 0.2447L0.344621 7.59471L1.65538 9.10531ZM8.81523 1.7553L17.2858 9.10531L18.5966 7.59471L10.126 0.244702L8.81523 1.7553ZM16.9412 8.35001V11.45H18.9412V8.35001H16.9412ZM2 23V8.35001H0V23H2ZM23 22H1V24H23V22ZM16.5 17H19.5V15H16.5V17Z"
        fill={color}
      />
    </HIDSvgIcon>
  );
};

export default Building;
