import i18next from 'i18next';
import {
  enUS,
  sv,
} from 'date-fns/locale';
import { Language } from '@house-id/houseid-types/dist/common';

export const getCurrentLanguage = () => i18next.language as Language === Language.EN ? Language.EN : Language.SV;

export const getCurrentLocale = () => getCurrentLanguage() === Language.EN ? enUS : sv;
