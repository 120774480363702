import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Card,
  useTheme,
  Skeleton,
  Stack,
  Box,
} from '@mui/material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { useLazyGetSuggestionsQuery } from '../api/api.suggestion';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../utils/routes';
import { getSuggestionCategoriesPath } from '../navigation.suggestion';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import SuggestionCard from '../components/SuggestionCard';
import { ALL_SUGGESTIONS_CATEGORY } from '../contacts.suggestion';
import InfiniteScroll from '../../../../../../../components/InfiniteScroll';
import { Suggestion } from '../types.suggestion';
import SuggestionsQuickNavigation from '../components/SuggestionsQuickNavigation';
import useLock from '../../../../../../../hooks/useLock';
import { getTypographyHeight } from '../../../../../../../utils/style';
import { FCC } from '../../../../../../../types/common';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type SuggestionLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const SuggestionLoaderSkeleton: FCC<SuggestionLoaderSkeletonProps> = ({
  itemsCount = 4,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    const height = getTypographyHeight(theme.typography.body2);

    return (
      <Stack spacing={3}>
        {R.range(0, itemsCount).map((i) => (
          <Box
            key={i}
            sx={{
              padding: 1.5,
              borderRadius: theme.spacing(1.25),
              border: `1px solid ${theme.palette.grey[300]}`,
              width: '100%',
            }}
          >
            <Skeleton
              height={height}
              sx={{ margin: theme.spacing(2) }}
              variant="rounded"
              width="30%"
            />
            <Skeleton
              height={height}
              sx={{ margin: theme.spacing(2) }}
              variant="rounded"
              width="70%"
            />
            <Stack direction="row-reverse" spacing={2} sx={{ padding: theme.spacing(2) }}>
              <Skeleton
                height={height + 10}
                sx={{ borderRadius: '20px' }}
                variant="rounded"
                width="50px"
              />
              <Skeleton
                height={height + 10}
                sx={{ borderRadius: '20px' }}
                variant="rounded"
                width="70px"
              />
            </Stack>
          </Box>
        ))}
      </Stack>
    );
  }

  return children;
};

const PAGE_SIZE = 20;

const Suggestions: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'suggestion']);

  const getEntityInfo = useGetEntityInfo();
  const { entityType: category } = useRouteParams<{ entityType: string }>();

  const isAllSuggestionsCategory = category === ALL_SUGGESTIONS_CATEGORY;

  const { data: propertyId } = useGetCurrentPropertyId();

  const [getSuggestions, { isLoading, isFetching }] = useLazyGetSuggestionsQuery({
    // TODO: remove when APIs will be combined
    refetchOnFocus: true,
  });

  const isItemsLoading = isLoading || isFetching;

  const [suggestionsState, setSuggestionsState] = useState<{
    suggestions: Array<Suggestion>;
    fetchedCount: number;
    totalCount?: number;
  }>({
    suggestions: [],
    fetchedCount: 0,
    totalCount: 0,
  });

  const { locked: fetchLocked, lock: lockFetch } = useLock(suggestionsState.fetchedCount);

  const handleFetch = (offset = 0) => {
    if (isItemsLoading || fetchLocked || !propertyId) {
      return;
    }

    lockFetch();

    getSuggestions({
      propertyId,
      entityType: isAllSuggestionsCategory ? undefined : category,
      pageSize: PAGE_SIZE,
      offset,
    })
      .unwrap()
      .then(({ suggestions, totalCount }) => {
        setSuggestionsState(
          (state) => ({
            suggestions: R.concat(state.suggestions, suggestions),
            fetchedCount: state.fetchedCount + suggestions.length,
            totalCount: state.totalCount || totalCount || 0,
          }),
        );
      });
  };

  const hasMore = suggestionsState.totalCount === undefined
    || suggestionsState.suggestions.length < suggestionsState.totalCount;

  useEffect(
    () => {
      setSuggestionsState({
        suggestions: [],
        totalCount: undefined,
        fetchedCount: 0,
      });

      handleFetch();
    },
    [category],
  );

  const isLoadingFirstTime = !suggestionsState.suggestions.length && isFetching;

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <SuggestionsQuickNavigation />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }),
          name: t('suggestion:smart_suggestions'),
        },
      ]}
      isLoading={isLoadingFirstTime}
      sideDrawerElements={[
        <SuggestionsQuickNavigation key={SuggestionsQuickNavigation.name} />,
      ]}
      title={!isAllSuggestionsCategory ? getEntityInfo(category as EntityType)?.name : t('common:all_label')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }))}
    >
      <InfiniteScroll
        hasMore={hasMore}
        isFetching={isFetching}
        onLoadMore={() => handleFetch(suggestionsState.suggestions.length)}
      >
        <SuggestionLoaderSkeleton isLoading={isLoadingFirstTime}>
          {
            suggestionsState.suggestions.map((suggestion) => (
              <SuggestionCard
                key={suggestion.entityId}
                suggestion={suggestion}
                sx={{ marginBottom: 2.5 }}
              />
            ))
          }
        </SuggestionLoaderSkeleton>
      </InfiniteScroll>
    </HomeLayout>
  );
};

export default Suggestions;
