import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import NoInsuranceBackground from '../../../../../../../assets/images/no_insurance.png';

import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { Insurance } from '../../../../../../../components/icons/Icons';

const NoInsuranceCard: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances']);
  const imageUrl = NoInsuranceBackground;

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const headerHeight = R.cond([
    [() => isDownXs, R.always(160)],
    [() => isDownSm, R.always(160)],
    [() => isDownMd, R.always(320)],
    [() => isDownLg, R.always(400)],
    [() => isDownXl, R.always(400)],
    [R.T, R.always(320)],
  ])();

  const logoHeight = R.cond([
    [() => isDownXs, R.always(48)],
    [() => isDownSm, R.always(64)],
    [() => isDownMd, R.always(80)],
    [() => isDownLg, R.always(80)],
    [() => isDownXl, R.always(80)],
    [R.T, R.always(80)],
  ])();

  return (
    <Stack spacing={2}>
      <Box sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: headerHeight,
      }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          padding={2}
          spacing={2}
          sx={{ height: headerHeight }}
        >
          <Card sx={{
            borderRadius: logoHeight / 2,
          }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: logoHeight,
                width: logoHeight,
              }}
            >
              <Insurance size={36} sx={{ flex: 1 }} />
            </Stack>
          </Card>
          <Typography color={theme.palette.common.white} textAlign="center" typography={{ sm: 'h3', xxs: 'h5' }}>
            {t('insurances:no_insurance_congratulations')}
          </Typography>
        </Stack>
      </Box>
      <Typography>
        {t('insurances:no_insurance_description')}
      </Typography>
    </Stack>
  );
};

export default NoInsuranceCard;
