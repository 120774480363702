import qs from 'query-string';
import {
  format,
  subYears,
} from 'date-fns';

import { userCommonApi } from '../../Auth/api/api.user.common';
import { UserStatistics } from '../types/types.userStatistics';

export const statisticsApi = userCommonApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<UserStatistics, void>({
      query: () => {
        const fromPeriod = format(subYears(new Date(), 99), 'yyyy-MM-dd');
        const toPeriod = format(new Date(), 'yyyy-MM-dd');

        return `/statistics?${qs.stringify({ from: fromPeriod, to: toPeriod })}`;
      },
    }),
  }),
});

export const {
  useGetStatisticsQuery,
} = statisticsApi;
