import qs from 'query-string';
import * as R from 'ramda';

import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { BuildingEntityType } from '../../../../../constants/entityType';
import { propertyApi } from '../../../api/api.property';
import {
  OrderItem,
  BuildingEntity,
  BuildingTemplate,
  RecommendedBuildingTemplate,
  BuildingShortEntity,
  MainBuildingSummary,
} from '../types.buildings';
import { PropertyId } from '../../../types/types.property';

type BuildingEntityTypeId = { buildingEntityType: BuildingEntityType };

type UpdateBuildingEntitiesOrder = PropertyId & { buildingEntityType: BuildingEntityType } & { orderList: Array<OrderItem> };

type BuildingEntityId = PropertyId & { buildingEntityType: BuildingEntityType } & { id: string };

export type TemplatedBuildingEntity = {
  templateId: string;
  name: string;
  parentId?: string;
};

type CreateBuildingEntityRequest = PropertyId & {
  buildingEntityType: BuildingEntityType,
  buildingEntity: Omit<BuildingEntity, 'id'> | TemplatedBuildingEntity
};

type CreateBuildingEntitiesRequest = PropertyId & {
  buildingEntityType: BuildingEntityType,
  buildingEntities: Array<Omit<BuildingEntity, 'id'> | TemplatedBuildingEntity>,
};

type UpdateBuildingEntityRequest = Omit<BuildingEntityId, 'id'> & {
  buildingEntity: { id: string } & Record<string, any>;
};

export const buildingApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBuildingTemplates: builder.query<Array<BuildingTemplate>, PropertyId & BuildingEntityTypeId>({
      query: ({ propertyId, buildingEntityType }) => buildingEntityType
        ? `/v2/properties/${propertyId}/buildings/templates/${buildingEntityType}`
        : `/v2/properties/${propertyId}/buildings/templates`,
      transformResponse: (result: Record<BuildingEntityType, Array<BuildingTemplate>>, _meta, args) =>
        args.buildingEntityType
          ? result[args.buildingEntityType]
          : [],
    }),
    getRecommendedBuildingTemplates: builder.query<Array<RecommendedBuildingTemplate>, PropertyId & BuildingEntityTypeId>({
      query: ({ propertyId, buildingEntityType }) => buildingEntityType
        ? `/properties/${propertyId}/buildings/templates/${buildingEntityType}/recommended`
        : `/properties/${propertyId}/buildings/templates/recommended`,
      providesTags: (_result, _meta, args) => [{ type: HIDApiTags.BUILDING_RECOMMENDED_TEMPLATE, id: args.buildingEntityType }],
      transformResponse: (result: Record<BuildingEntityType, Array<RecommendedBuildingTemplate>>, _meta, args) =>
        args.buildingEntityType
          ? result[args.buildingEntityType]
          : [],
    }),
    getBuildingEntities: builder.query<Array<BuildingShortEntity>, PropertyId & BuildingEntityTypeId>({
      query: ({ propertyId, buildingEntityType }) => buildingEntityType
        ? `/properties/${propertyId}/buildings/${buildingEntityType}`
        : `/properties/${propertyId}/buildings`,
      providesTags: (_result, _meta, args) => [{ type: HIDApiTags.BUILDING_ENTITIES_LIST, id: args.buildingEntityType }],
      transformResponse: (result: Record<BuildingEntityType, Array<BuildingShortEntity>>, _meta, args) =>
        args.buildingEntityType
          ? result[args.buildingEntityType]
          : [],
    }),
    getBuildingEntity: builder.query<BuildingEntity, BuildingEntityId>({
      query: ({ propertyId, buildingEntityType, id }) =>
        `/properties/${propertyId}/buildings/${buildingEntityType}/${id}?${qs.stringify({ groupConnections: true })}`,
      providesTags: (_result, _meta, args) => [{ type: HIDApiTags.BUILDING_ENTITY, id: args.id }],
      transformResponse: (result: Record<BuildingEntityType, BuildingEntity>, _meta, args) =>
        result[args.buildingEntityType],
    }),
    updateBuildingEntitiesOrder: builder.mutation<any, UpdateBuildingEntitiesOrder>({
      query: ({ propertyId, buildingEntityType, orderList }) => ({
        url: `/properties/${propertyId}/buildings/${buildingEntityType}/order`,
        method: 'PATCH',
        body: orderList,
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: HIDApiTags.BUILDING_ENTITIES_LIST },
        { type: HIDApiTags.CONTENT_CONNECTIONS, entityType: args.buildingEntityType },
      ],
    }),
    createBuildingEntity: builder.mutation<BuildingEntity, CreateBuildingEntityRequest>({
      query: ({
        propertyId, buildingEntityType, buildingEntity,
      }) => ({
        url: `/properties/${propertyId}/buildings/${buildingEntityType}?${qs.stringify({ groupConnections: true })}`,
        method: 'POST',
        body: buildingEntity,
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: HIDApiTags.BUILDING_ENTITIES_LIST, id: args.buildingEntityType },
        { type: HIDApiTags.PROPERTY_MAIN_BUILDING },
      ],
      transformResponse: (result: Record<BuildingEntityType, BuildingEntity>, _meta, args) =>
        result[args.buildingEntityType],
    }),
    updateBuildingEntity: builder.mutation<BuildingEntity, UpdateBuildingEntityRequest>({
      query: ({
        propertyId, buildingEntityType, buildingEntity,
      }) => ({
        url: `/properties/${propertyId}/buildings/${buildingEntityType}/${buildingEntity.id}?${qs.stringify({ groupConnections: true })}`,
        method: 'PATCH',
        body: R.omit(['id'], buildingEntity),
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: HIDApiTags.BUILDING_ENTITY, id: args.buildingEntity.id },
        { type: HIDApiTags.BUILDING_ENTITIES_LIST, id: args.buildingEntityType },
        { type: HIDApiTags.CONTENT_CONNECTIONS, entityType: args.buildingEntityType },
        { type: HIDApiTags.PROPERTY_MAIN_BUILDING },
      ],
      transformResponse: (result: Record<BuildingEntityType, BuildingEntity>, _meta, args) =>
        result[args.buildingEntityType],
    }),
    batchCreateBuildingEntities: builder.mutation<Array<BuildingEntity>, CreateBuildingEntitiesRequest>({
      query: ({
        propertyId, buildingEntityType, buildingEntities,
      }) => ({
        url: `/properties/${propertyId}/buildings/${buildingEntityType}/batch?${qs.stringify({ groupConnections: true })}`,
        method: 'POST',
        body: buildingEntities,
      }),
      transformResponse: (result: Record<BuildingEntityType, Array<BuildingEntity>>, _meta, args) =>
        result[args.buildingEntityType],
      invalidatesTags: (_result, _meta, args) => [
        { type: HIDApiTags.BUILDING_ENTITIES_LIST, id: args.buildingEntityType },
        { type: HIDApiTags.PROPERTY_MAIN_BUILDING },
      ],
    }),
    deleteBuildingEntity: builder.mutation<any, PropertyId & BuildingEntityId & { newParentId?: string }>({
      query: ({
        propertyId, buildingEntityType, id, newParentId,
      }) => ({
        url: `/properties/${propertyId}/buildings/${buildingEntityType}/${id}`,
        method: 'DELETE',
        body: newParentId ? { newParentId } : undefined,
      }),
      invalidatesTags: (_result, _meta, args) => [
        { type: HIDApiTags.BUILDING_ENTITIES_LIST },
        { type: HIDApiTags.PROPERTY_MAIN_BUILDING },
        { type: HIDApiTags.CONTENT_CONNECTIONS, entityType: args.buildingEntityType },
      ],
    }),
    getPropertyMainBuilding: builder.query<MainBuildingSummary, PropertyId>({
      query: ({ propertyId }) => `properties/${propertyId}/buildings/building/main`,
      providesTags: [HIDApiTags.PROPERTY_MAIN_BUILDING],
      transformResponse: (response: { building: MainBuildingSummary }) => response.building,
      transformErrorResponse: (response, meta) => meta?.response?.status === 404
        ? undefined
        : response,
    }),
  }),
});

export const {
  useGetAllBuildingTemplatesQuery,
  useGetRecommendedBuildingTemplatesQuery,
  useGetBuildingEntitiesQuery,
  useGetBuildingEntityQuery,
  useUpdateBuildingEntitiesOrderMutation,
  useCreateBuildingEntityMutation,
  useUpdateBuildingEntityMutation,
  useBatchCreateBuildingEntitiesMutation,
  useDeleteBuildingEntityMutation,
  useGetPropertyMainBuildingQuery,
} = buildingApi;
