import {
  AttachFile,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
  Icon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { Message } from '../types.message';
import { APP_NAME } from '../../../../../../../constants/links';
import MessageReadDot from './MessageReadDot';
import HIDClickable from '../../../../../../../components/HIDClickable';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import { getNoWrapStyle } from '../../../../../../../utils/style';

const removeHtmlTags = (str: string) => str.replace(/(<([^>]+)>)/gi, '');

type MessageListItemProps = {
  message: Message;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClick: (id: string, isSelected: boolean) => void;
};

const MessageListItem: FC<MessageListItemProps> = ({
  message,
  isSelectable = false,
  isSelected = false,
  onClick,
}) => {
  const theme = useTheme();

  const attachmentsFormatted = message.blobs?.map(({ name }) => name)?.join(', ');

  return (
    <HIDClickable
      spacing={0.25}
      sx={{
        padding: theme.spacing(1.5, 1.5, 1.5, 3),
        backgroundColor: isSelected
          ? theme.palette.primary.lighter
          : message.read
            ? theme.palette.common.white
            : theme.palette.primary.lightest,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: theme.palette.grey[300],
        cursor: 'pointer',
        borderRadius: 0,
      }}
      onClick={() => onClick(message.id, isSelected)}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        style={{ marginLeft: theme.spacing(-2) }}
      >
        <MessageReadDot isRead={message.read} />
        <Typography variant="subtitle1">
          {message.sender.name || APP_NAME}
        </Typography>
        <Stack
          direction="row"
          spacing={1.5}
          style={{ marginLeft: 'auto' }}
        >
          <Typography
            sx={{ color: theme.palette.grey[500] }}
            variant="body2"
          >
            {formatDate(new Date(message.createdAt), DateTimeFormats.DATE_ONLY_TEXT)}
          </Typography>
          {
            isSelectable
              ? (
                <HIDIconButton
                  Icon={isSelected ? () => <CheckBox sx={{ color: theme.palette.primary.main }} /> : CheckBoxOutlineBlank}
                  color="blank"
                  style={{ marginTop: theme.spacing(-1.5) }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClick(message.id, isSelected);
                  }}
                />
              )
              : (
                <HIDIconButton
                  disabled
                  Icon={Icon}
                  color="blank"
                  style={{ marginTop: theme.spacing(-1.5) }}
                  onClick={undefined}
                />
              )
          }
        </Stack>
      </Stack>
      <Typography variant="body1">
        {message.subject}
      </Typography>
      <Typography sx={getNoWrapStyle(2)}>
        {removeHtmlTags(message?.htmlBody || '')}
      </Typography>
      {Boolean(attachmentsFormatted) && (
        <Stack
          alignItems="center"
          direction="row"
          spacing={0.5}
        >
          <AttachFile
            sx={{
              color: theme.palette.grey[500],
              width: theme.spacing(2),
              height: theme.spacing(2),
              transform: 'rotate(45deg)',
            }}
          />
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {attachmentsFormatted}
          </Typography>
        </Stack>
      )}
    </HIDClickable>
  );
};

export default MessageListItem;
