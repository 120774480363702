import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyTasksSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyTasksSection from '../../../components/PropertyTasksSection';

const PropertyToDoTasksSection: FC<PropertyProgressCategorySectionProps<PropertyTasksSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['property']);

  return (
    <PropertyTasksSection
      description={t('property:property_progress_property_todo_tasks')}
      section={section}
    />
  );
};

export default PropertyToDoTasksSection;
