import React from 'react';
import {
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Comment,
  Visibility,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../../utils/url';
import { FCC } from '../../../../../types/common';

type ThreadViewsAndRepliesCountProps = {
  viewsCount?: number;
  repliesCount?: number;
  isLoading?: boolean;
};

const ThreadViewsAndRepliesCount: FCC<ThreadViewsAndRepliesCountProps> = ({
  viewsCount = 0,
  repliesCount = 0,
  isLoading = false,
  sx,
  style,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={1}
      style={style}
      sx={sx}
    >
      <Visibility sx={{ color: theme.palette.primary.main }} titleAccess={capitalize(t('forum:forum_views'))} />
      <Typography variant="h6">{isLoading ? <Skeleton sx={{ width: 10.5 }} /> : viewsCount}</Typography>
      <Comment sx={{ color: theme.palette.primary.main }} titleAccess={capitalize(t('forum:forum_replies'))} />
      <Typography variant="h6">{isLoading ? <Skeleton sx={{ width: 10.5 }} /> : repliesCount}</Typography>
    </Stack>
  );
};

export default ThreadViewsAndRepliesCount;
