import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const NeutralSmile: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.common.white;

  return (
    <HIDSvgIcon size={size}>
      <ellipse
        cx="12"
        cy="12"
        fill={color}
        rx="12"
        ry="12"
      />
      <path
        d="m7.09775,15.43158l9.80451,0"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m9.05865,9.05865l0.0098,0"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 1.5}
      />
      <path
        d="m14.94135,9.05865l0.0098,0"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 1.5}
      />
    </HIDSvgIcon>
  );
};

export default NeutralSmile;
