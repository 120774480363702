import { useTheme } from '@mui/material';
import * as React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const SadSmile: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.common.white;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m12,24c6.6274,0 12,-5.3726 12,-12c0,-6.62742 -5.3726,-12 -12,-12c-6.62742,0 -12,5.37258 -12,12c0,6.6274 5.37258,12 12,12z"
        fill={color}
      />
      <path
        d="m18,17c0,0 -2.25,-3 -6,-3c-3.75,0 -6,3 -6,3"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m9,9l0.01,0"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 1.5}
      />
      <path
        d="m15,9l0.01,0"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 1.5}
      />
    </HIDSvgIcon>
  );
};

export default SadSmile;
