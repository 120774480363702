import React, { useState } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  styled,
} from '@mui/material';

import {
  FCC,
  Icon,
} from '../../types/common';
import { resolveImageUrl } from '../../utils/env';
import DefaultImage from '../DefaultImage';
import { hidSpacing } from '../../utils/number';

const InteractiveBox = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.85,
  },
  '&:active': {
    opacity: 1,
  },
}));

type HIDImageProps = {
  url?: string;
  externalUrl?: string;
  size?: number;
  iconSize?: number;
  iconColor?: string;
  showSkeleton?: boolean;
  containerSx?: SxProps<Theme>;
  skeletonSx?: SxProps<Theme>;
  SkeletonIcon?: Icon;
  PlaceholderIcon?: Icon;
  onClick?: () => void;
};

const HIDImage: FCC<HIDImageProps> = ({
  url,
  externalUrl,
  size = hidSpacing(8),
  iconSize = hidSpacing(4),
  iconColor,
  showSkeleton = false,
  sx,
  containerSx,
  skeletonSx,
  SkeletonIcon,
  PlaceholderIcon,
  style,
  onClick,
}) => {
  const [imageLoading, setImageLoading] = useState(showSkeleton);

  const handleOnLoad = () => setImageLoading(false);
  const handleOnError = () => setImageLoading(false);

  const Container = onClick ? InteractiveBox : Box;

  return url || externalUrl
    ? (
      <Stack
        justifyContent="center"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          ...containerSx,
        }}
      >
        {imageLoading && (
          <Skeleton sx={skeletonSx} variant="rectangular">
            {SkeletonIcon ? <SkeletonIcon /> : null}
          </Skeleton>
        )}
        <Container
          component="img"
          // @ts-ignore
          loading="lazy"
          src={url ? resolveImageUrl(url) : externalUrl}
          style={style}
          sx={{
            ...sx,
            ...(
              imageLoading
                ? {
                  borderWidth: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }
                : {}
            ),
          }}
          onClick={onClick}
          onError={showSkeleton ? handleOnError : undefined}
          onLoad={showSkeleton ? handleOnLoad : undefined}
        />
      </Stack>
    )
    : (
      <DefaultImage
        CustomIcon={PlaceholderIcon}
        iconColor={iconColor}
        iconSize={iconSize}
        size={size}
        sx={sx}
      />
    );
};

export default HIDImage;
