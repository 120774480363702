import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const AddUser: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M20.5 0.5L20.5 6.5M23.5 3.5H17.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default AddUser;
