import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Offer: Icon = ({
  fillColor: fillColorProp,
  iconColor: colorProp,
  strokeWidth = 1.5,
  size,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M14.7009 2.37748C13.8409 2.13677 12.9371 2 11.9995 2C6.47617 2 2 6.47727 2 12.0005C2 17.5227 6.47617 22 11.9995 22C17.5227 22 22 17.5227 22 12.0005C22 11.1843 21.8917 10.3965 21.7068 9.63718"
        stroke="#E0E5EB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="2.40039"
        cy="9.19995"
        fill="black"
        r="1"
      />
      <path
        d="M2 12.0005C2 14.1379 2.67044 16.1184 3.81248 17.7434C5.62173 20.3178 8.61459 22 12.0005 22C17.5227 22 22 17.5238 22 12.0005C22 6.47727 17.5227 2 12.0005 2"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M7 12L8.98069 10.4596V7.99059L11.4505 8.53961L13.4312 7L14.5302 9.2251L17 9.7749L15.9002 12L17 14.2251L14.5302 14.7749L13.4312 17L11.4505 15.4596L8.98069 16.0094V13.5396L7 12Z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.575"
      />
      <path
        clipRule="evenodd"
        d="M10 10.5C10 10.2242 10.2243 10 10.4999 10C10.7757 10 11 10.2242 11 10.5C11 10.7758 10.7757 11 10.4999 11C10.2243 11 10 10.7758 10 10.5Z"
        fillRule="evenodd"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M13 13.5C13 13.2242 13.2243 13 13.4999 13C13.7757 13 14 13.2242 14 13.5C14 13.7758 13.7757 14 13.4999 14C13.2243 14 13 13.7758 13 13.5Z"
        fillRule="evenodd"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14L14 10"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </HIDSvgIcon>
  );
};

export default Offer;
