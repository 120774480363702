import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import sv from '../assets/locales/sv.json';
import en from '../assets/locales/en.json';

export const resources = {
  en,
  sv,
} as const;

export const initI18n = () => i18n
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'sv',
    debug: true,
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
