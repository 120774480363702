import {
  Middleware,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  ActionError,
  ReportErrorMessage,
} from '../../types/common';
import {
  RejectedAction,
  getActionErrorFromRtkResponse,
} from '../../utils/errors';
import { reportErrorToSentry } from '../../external-services/sentry';

type StatusErrors = {
  title: string;
  description: string;
  codes?: [{
    code: string;
    title: string;
    description: string;
  }]
};

const getCommonErrorMessages = (): Record<number, StatusErrors> => ({
  302: {
    title: i18n.t('api_errors:302_common_error_title'),
    description: i18n.t('api_errors:302_common_error_description'),
  },
  400: {
    title: i18n.t('api_errors:400_common_error_title'),
    description: i18n.t('api_errors:400_common_error_description'),
  },
  401: {
    title: i18n.t('api_errors:401_common_error_title'),
    description: i18n.t('api_errors:401_common_error_description'),
  },
  402: {
    title: i18n.t('api_errors:402_common_error_title'),
    description: i18n.t('api_errors:402_common_error_description'),
    codes: [
      {
        code: 'storage_quota_exceeded',
        title: i18n.t('api_errors:402_storage_quota_exceeded_error_title'),
        description: i18n.t('api_errors:402_storage_quota_exceeded_error_description'),
      },
    ],
  },
  403: {
    title: i18n.t('api_errors:403_common_error_title'),
    description: i18n.t('api_errors:403_common_error_description'),
  },
  404: {
    title: i18n.t('api_errors:404_common_error_title'),
    description: i18n.t('api_errors:404_common_error_description'),
  },
  422: {
    title: i18n.t('api_errors:422_common_error_title'),
    description: i18n.t('api_errors:422_common_error_description'),
  },
  429: {
    title: i18n.t('api_errors:429_common_error_title'),
    description: i18n.t('api_errors:429_common_error_description'),
  },
  500: {
    title: i18n.t('api_errors:500_common_error_title'),
    description: i18n.t('api_errors:500_common_error_description'),
  },
  503: {
    title: i18n.t('api_errors:503_common_error_title'),
    description: i18n.t('api_errors:503_common_error_description'),
  },
  504: {
    title: i18n.t('api_errors:504_common_error_title'),
    description: i18n.t('api_errors:504_common_error_description'),
  },
});

const gerErrorMessageByStatusCode = (status?: number, code?: string): ReportErrorMessage => {
  const DEFAULT_ERROR: ReportErrorMessage = {
    key: 'default',
    title: i18n.t('api_errors:default_error_title'),
    description: i18n.t('api_errors:default_error_description'),
  };

  if (!status) {
    return DEFAULT_ERROR;
  }
  const commonErrorMessages = getCommonErrorMessages();

  const currentStatus: StatusErrors = commonErrorMessages[status];
  if (currentStatus) {
    if (code && currentStatus.codes?.length) {
      const codeError = currentStatus.codes.find((c) => c.code === code);
      if (codeError) {
        return {
          key: `${status}_${code}`,
          ...codeError,
        };
      }
    }
    return {
      key: status.toString(),
      ...currentStatus,
    };
  }
  return DEFAULT_ERROR;
};

const rtkErrorLoggerMiddleware: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const actionError: ActionError = getActionErrorFromRtkResponse(action as RejectedAction);

    const errorMessage = gerErrorMessageByStatusCode(actionError?.status, actionError?.code);

    // TODO: Find better way to avoid exception
    if (actionError?.status !== 401 && !actionError?.skipGlobalHandle) {
      reportErrorToSentry(actionError, { message: errorMessage, showToaster: true });
    }
  }

  return next(action);
};

export default rtkErrorLoggerMiddleware;
