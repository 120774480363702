import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HIDLogoText: Icon = ({
  iconColor: colorProp,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.primary.main;

  return (
    <HIDSvgIcon
      height={12}
      sx={sx}
      viewBox="0 0 64 12"
      width={64}
    >
      <path
        d="M9.2454 11.6034H6.90479V6.73097H2.34061V11.6034H0V0.234375H2.34061V4.84134H6.90479V0.234375H9.2454V11.6034Z"
        fill={color}
      />
      <path
        d="M10.798 7.30099C10.798 6.46288 10.9592 5.71588 11.2817 5.05997C11.6042 4.40407 12.0671 3.89652 12.6705 3.53733C13.279 3.17815 13.9838 2.99855 14.7848 2.99855C15.9239 2.99855 16.8524 3.34733 17.5702 4.04488C18.2931 4.74243 18.6962 5.68985 18.7795 6.88714L18.7951 7.46496C18.7951 8.76116 18.4336 9.80228 17.7106 10.5883C16.9876 11.3692 16.0176 11.7596 14.8004 11.7596C13.5833 11.7596 12.6107 11.3692 11.8825 10.5883C11.1595 9.80749 10.798 8.74554 10.798 7.40249V7.30099ZM13.0528 7.46496C13.0528 8.26663 13.2036 8.88089 13.5053 9.30775C13.807 9.7294 14.2387 9.94023 14.8004 9.94023C15.3466 9.94023 15.7731 9.732 16.08 9.31556C16.3869 8.8939 16.5403 8.22238 16.5403 7.30099C16.5403 6.51494 16.3869 5.90588 16.08 5.47382C15.7731 5.04175 15.3414 4.82572 14.7848 4.82572C14.2335 4.82572 13.807 5.04175 13.5053 5.47382C13.2036 5.90068 13.0528 6.56439 13.0528 7.46496Z"
        fill={color}
      />
      <path
        d="M25.2395 10.7445C24.683 11.4212 23.9132 11.7596 22.9301 11.7596C22.0251 11.7596 21.3333 11.4993 20.8548 10.9787C20.3815 10.4582 20.1396 9.69556 20.1292 8.69088V3.15472H22.384V8.6128C22.384 9.49255 22.7845 9.93242 23.5855 9.93242C24.3501 9.93242 24.8755 9.66693 25.1615 9.13596V3.15472H27.4241V11.6034H25.302L25.2395 10.7445Z"
        fill={color}
      />
      <path
        d="M33.6969 9.26871C33.6969 8.99281 33.5591 8.77678 33.2834 8.62061C33.013 8.45923 32.576 8.31608 31.9727 8.19114C29.965 7.76949 28.9611 6.91577 28.9611 5.62999C28.9611 4.88038 29.2706 4.25571 29.8896 3.75597C30.5137 3.25102 31.3277 2.99855 32.3316 2.99855C33.4031 2.99855 34.2587 3.25102 34.8985 3.75597C35.5434 4.26091 35.8659 4.91682 35.8659 5.72369H33.6111C33.6111 5.40094 33.5071 5.13545 33.299 4.92723C33.091 4.7138 32.7659 4.60708 32.3238 4.60708C31.9441 4.60708 31.6502 4.69298 31.4422 4.86476C31.2341 5.03655 31.1301 5.25518 31.1301 5.52067C31.1301 5.77054 31.2471 5.97356 31.4812 6.12972C31.7204 6.28069 32.1209 6.41343 32.6827 6.52795C33.2444 6.63727 33.7177 6.76221 34.1026 6.90276C35.2938 7.34003 35.8893 8.09744 35.8893 9.175C35.8893 9.94543 35.559 10.5701 34.8985 11.049C34.2379 11.5227 33.3849 11.7596 32.3394 11.7596C31.632 11.7596 31.0026 11.6347 30.4513 11.3848C29.9052 11.1297 29.476 10.7835 29.164 10.3463C28.8519 9.90379 28.6958 9.42748 28.6958 8.91733H30.8336C30.8544 9.31816 31.0026 9.62529 31.2783 9.83872C31.554 10.0521 31.9233 10.1589 32.3862 10.1589C32.8179 10.1589 33.143 10.0782 33.3615 9.9168C33.5851 9.75022 33.6969 9.53419 33.6969 9.26871Z"
        fill={color}
      />
      <path
        d="M41.2337 11.7596C39.9958 11.7596 38.9867 11.3796 38.2065 10.6196C37.4315 9.85954 37.044 8.84705 37.044 7.58209V7.36345C37.044 6.51494 37.2079 5.75752 37.5355 5.09121C37.8632 4.41968 38.3261 3.90433 38.9243 3.54514C39.5277 3.18075 40.2142 2.99855 40.984 2.99855C42.1387 2.99855 43.0464 3.36294 43.7069 4.09173C44.3727 4.82051 44.7056 5.85383 44.7056 7.19167V8.11306H39.33C39.4028 8.66486 39.6213 9.10733 39.9854 9.44049C40.3547 9.77365 40.8202 9.94023 41.3819 9.94023C42.2506 9.94023 42.9293 9.62529 43.4183 8.99541L44.5261 10.2369C44.1881 10.7159 43.7303 11.0907 43.153 11.3614C42.5756 11.6268 41.9359 11.7596 41.2337 11.7596ZM40.9762 4.82572C40.5289 4.82572 40.1648 4.97668 39.8839 5.27861C39.6083 5.58053 39.4314 6.0126 39.3534 6.5748H42.4898V6.39521C42.4794 5.89547 42.3442 5.51026 42.0841 5.23957C41.824 4.96367 41.4548 4.82572 40.9762 4.82572Z"
        fill={color}
      />
      <path
        d="M46.1022 10.4946C46.1022 10.1354 46.2218 9.84392 46.4611 9.62008C46.7055 9.39624 47.0098 9.28432 47.3739 9.28432C47.7432 9.28432 48.0475 9.39624 48.2867 9.62008C48.5312 9.84392 48.6534 10.1354 48.6534 10.4946C48.6534 10.8486 48.5338 11.1375 48.2945 11.3614C48.0553 11.58 47.7484 11.6893 47.3739 11.6893C47.0046 11.6893 46.7003 11.58 46.4611 11.3614C46.2218 11.1375 46.1022 10.8486 46.1022 10.4946ZM46.1022 4.09954C46.1022 3.74035 46.2218 3.44884 46.4611 3.225C46.7055 3.00115 47.0098 2.88923 47.3739 2.88923C47.7432 2.88923 48.0475 3.00115 48.2867 3.225C48.5312 3.44884 48.6534 3.74035 48.6534 4.09954C48.6534 4.45352 48.5338 4.74243 48.2945 4.96627C48.0553 5.18491 47.7484 5.29422 47.3739 5.29422C47.0046 5.29422 46.7003 5.18491 46.4611 4.96627C46.2218 4.74243 46.1022 4.45352 46.1022 4.09954Z"
        fill={color}
      />
      <path
        d="M53.1396 11.6034H50.799V0.234375H53.1396V11.6034Z"
        fill={color}
      />
      <path
        d="M55.3163 11.6034V0.234375H58.8117C59.8103 0.234375 60.7023 0.460819 61.4877 0.913707C62.2784 1.36139 62.8947 2.00168 63.3368 2.83458C63.7789 3.66227 64 4.60448 64 5.66122V6.18438C64 7.24112 63.7815 8.18073 63.3446 9.00322C62.9129 9.8257 62.3018 10.4634 61.5112 10.9163C60.7205 11.3692 59.8285 11.5982 58.8351 11.6034H55.3163ZM57.657 2.13182V9.72159H58.7882C59.7037 9.72159 60.4033 9.42227 60.887 8.82363C61.3707 8.22498 61.6178 7.36866 61.6282 6.25466V5.65341C61.6282 4.49777 61.3889 3.62322 60.9104 3.02979C60.4319 2.43114 59.7323 2.13182 58.8117 2.13182H57.657Z"
        fill={color}
      />
    </HIDSvgIcon>
  );
};

export default HIDLogoText;
