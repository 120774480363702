import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../components/buttons/HIDButton';
import ExpertImage from '../../../assets/images/expert.png';
import { FCC } from '../../../types/common';

type ASkExpertProps = {
  onClick: () => void;
};

const AskExpert: FCC<ASkExpertProps> = ({
  sx,
  style,
  onClick,
}) => {
  const { t } = useTranslation(['forum']);
  const theme = useTheme();

  return (
    <Stack
      style={{
        width: '100%',
        aspectRatio: 16 / 9,
        backgroundImage: `url(${ExpertImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        ...style,
      }}
      sx={sx}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        spacing={1.5}
        sx={{ height: '100%' }}
      >
        <Typography color={theme.palette.common.white} variant="h4">
          {t('forum:forum_ask_the_experts')}
        </Typography>
        <HIDButton onClick={onClick}>
          {t('forum:forum_ask_the_question')}
        </HIDButton>
      </Stack>
    </Stack>
  );
};

export default AskExpert;
