import React, {
  ReactNode,
} from 'react';
import {
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../types/common';
import ContentSliderNavigation from '../../../../../../components/contentSlider/ContentSliderNavigation';

type BonusOffersBaseSlideLayoutProps = {
  title?: string;
  sx?: SxProps<Theme>;
  titleColor?: string;
  TitleComponent?: ReactNode;
  onClick: () => void;
};

const BonusOffersBaseSlideLayout: FCC<BonusOffersBaseSlideLayoutProps> = ({
  title,
  titleColor,
  TitleComponent,
  children,
  sx,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'common']);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        padding: theme.spacing(2.5),
        columnGap: theme.spacing(2.5),
        ...sx,
      }}
    >
      <Stack />
      <Stack spacing={3} sx={{ alignItems: 'center' }}>
        {TitleComponent || (
          <Typography sx={{ textAlign: 'center', color: titleColor }} variant="h4">
            {title}
          </Typography>
        )}
        <HIDButton sx={{ zIndex: 1 }} onClick={onClick}>
          {t('home:home_show_offers')}
        </HIDButton>
      </Stack>
      <ContentSliderNavigation />
      {children}
    </Card>
  );
};

export default BonusOffersBaseSlideLayout;
