import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const PenSigning: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="M15.6678 4.74347C15.9034 4.50776 16.1832 4.32078 16.4913 4.19322C16.7993 4.06566 17.1293 4 17.4626 4C17.796 4 18.126 4.06566 18.434 4.19322C18.742 4.32078 19.0219 4.50776 19.2575 4.74347C19.4932 4.97917 19.6802 5.259 19.8078 5.56696C19.9354 5.87494 20.001 6.20501 20.001 6.53835C20.001 6.87169 19.9354 7.20177 19.8078 7.50974C19.6802 7.8177 19.4932 8.09753 19.2575 8.33324L8.93692 18.6538L4.00098 20L5.34714 15.0641L15.6678 4.74347Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <rect
        fill={color}
        height={2}
        rx={2}
        width={10}
        x={11}
        y={20}
      />
    </HIDSvgIcon>
  );
};

export default PenSigning;
