import React from 'react';
import { useNavigate } from 'react-router';
import {
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  CopyAllOutlined,
  DocumentScannerOutlined,
} from '@mui/icons-material';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import {
  useGetAllReceiptCategoriesQuery,
  useDeleteReceiptsMutation,
  useGetReceiptWithCache,
  useUpdateReceiptMutation,
} from '../../api/api.receipt';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import ReceiptsQuickNavigation from '../Receipts/components/ReceiptsQuickNavigation';
import ReceiptBasicInfoSection from './components/ReceiptBasicInfoSection';
import ReceiptSummarySection from './components/ReceiptSummarySection';
import { useGetReceiptCategoryName } from '../../utils.receipt';
import {
  getCreateReceiptPath,
  getUpdateReceiptPath,
  getReceiptCategoryPath,
  getReceiptCategoriesPath,
} from '../../navigation.receipt';
import AnnotationSection from '../../../../components/sections/AnnotationSection';
import ViewEntityActions from '../../../../components/actions/ViewEntityActions';
import LinesSection from '../../../../components/sections/LinesSection';
import ContentFileViewer from '../../../../components/ContentFileViewer';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import { ContentFile } from '../../../ContentFile/types.contentFile';
import ContentConnections from '../../../../components/ContentConnections';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import ContentConnectionsTabContext from '../../../../components/ContentConnectionsTabContext';
import { OVERVIEW_TAB_ID } from '../../../../constants/constants.content';
import { getCreateDocumentPath } from '../../../Document/navigation.document';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { getMutationFixedCacheKey } from '../../../../utils/cacheKeys';
import EntityDetailsLoaderSkeleton from '../../../../../../../../components/skeletonLoaders/EntityDetailsLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../../../../hooks/useGetPropertyPermissions';

const ViewReceipt = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'receipts']);

  const { isDownMd } = useBreakPointsSizes();

  const {
    id: receiptId,
    category: categoryId,
  } = useRouteParams<{ id: string, category: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const [deleteReceipts] = useDeleteReceiptsMutation({
    fixedCacheKey: getMutationFixedCacheKey(receiptId),
  });

  const {
    receipt,
    isLoading: isLoadingReceipt,
  } = useGetReceiptWithCache({ propertyId, receiptId });

  const {
    data: categories = [],
  } = useGetAllReceiptCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const [updateReceipt, { isLoading: isUpdatingReceipt }] = useUpdateReceiptMutation();

  const categoryName = useGetReceiptCategoryName(categoryId, categories);

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId, categoryId }));

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateReceiptPath, { propertyId, id: receiptId, categoryId }));

  const handleDelete = () => {
    if (propertyId) {
      deleteReceipts({ propertyId, ids: [receiptId] })
        .then(() => navigateBackOr(getReceiptCategoryPath({ propertyId })));
    }
  };

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map((f) => f.blob);
    if (propertyId && receiptId) {
      updateReceipt({
        id: receiptId,
        propertyId,
        blobs: newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
      });
    }
  };

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (receipt) {
      openManageConnectionsDialog({
        entityType: EntityType.RECEIPT,
        entity: receipt,
      });
    }
  };

  const handleDeleteBlob = (blobId: string) => {
    const blobToDelete = receipt?.blobs?.find((blob) => blob.id === blobId);

    if (propertyId && blobToDelete) {
      updateReceipt({
        id: receiptId,
        propertyId,
        blobs: [{ ...blobToDelete, action: ModifyActionType.DELETE }],
      });
    }
  };

  const handleCopyAsDocument = () => {
    navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId }), {
      state: {
        initialData: {
          name: receipt?.name,
          blobs: receipt?.blobs,
        },
        connections: [{
          entityType: EntityType.RECEIPT,
          entityId: receipt?.id,
        }],
      },
    });
  };

  const handleScanReceipt = () => {
    if (propertyId && receipt?.blobs?.length) {
      navigate(getUpdateReceiptPath({
        propertyId,
        id: receiptId,
        categoryId,
        forceScanReceipt: true,
      }));
    }
  };

  const customActions = [
    canUpdate && {
      id: 'copy',
      Icon: CopyAllOutlined,
      label: t('receipts:receipts_view_receipt_copy_as_document'),
      onClick: handleCopyAsDocument,
    },
    receipt?.blobs?.length && canCreate && {
      id: 'scan_receipt',
      Icon: DocumentScannerOutlined,
      label: t('receipts:receipts_read_receipt'),
      onClick: handleScanReceipt,
    },
  ].filter(Boolean);

  return (
    <HomeLayout
      BodyLoaderSkeleton={EntityDetailsLoaderSkeleton}
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={customActions}
              onAdd={handleAdd}
              onDelete={handleDelete}
              onManageConnections={handleManageConnections}
              onUpdate={handleUpdate}
            />
          </Card>
          <ContentFileViewer
            showFiles
            blobs={receipt?.blobs || []}
            isLoading={isLoadingReceipt}
            isUpdating={isUpdatingReceipt}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
          <Card sx={{ padding: 2 }}>
            <ReceiptsQuickNavigation />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }),
          name: t('receipts:receipts_receipts_title'),
        },
        {
          link: getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId }),
          name: categoryName,
        },
      ]}
      isLoading={isLoadingReceipt}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={customActions}
          key={ViewEntityActions.name}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onManageConnections={handleManageConnections}
          onUpdate={handleUpdate}
        />,
        <ReceiptsQuickNavigation key={ReceiptsQuickNavigation.name} />,
        <ContentFileViewer
          showFiles
          blobs={receipt?.blobs || []}
          isLoading={isLoadingReceipt}
          isUpdating={isUpdatingReceipt}
          key={ContentFileViewer.name}
          variant="side_drawer"
          onContentFilesSelected={handleContentFilesSelected}
          onDeleteBlob={handleDeleteBlob}
        />,
      ]}
      title={receipt?.name}
      onBack={() => navigateBackOr(
        getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId }),
        { replace: true },
      )}
    >
      {receipt && (
        <ContentConnectionsTabContext
          sourceEntity={receipt}
          sourceEntityType={EntityType.RECEIPT}
          tabs={[
            {
              id: OVERVIEW_TAB_ID,
              label: t('common:overview'),
              TabComponent: (
                <Stack>
                  <ContentFileViewer
                    showImages
                    blobs={receipt?.blobs || []}
                    isLoading={isLoadingReceipt}
                    isUpdating={isUpdatingReceipt}
                    showFiles={isDownMd}
                    variant="inline"
                    onContentFilesSelected={handleContentFilesSelected}
                    onDeleteBlob={handleDeleteBlob}
                  />
                  <Typography sx={{ marginTop: 4 }} variant="h6">
                    {t('receipts:receipts_receipts_info_label')}
                  </Typography>
                  <Stack spacing={2.5} sx={{ mt: 2 }}>
                    <ReceiptBasicInfoSection receipt={receipt} />
                    <ReceiptSummarySection receipt={receipt} />
                    {receipt.lines && receipt.lines.length > 0 && (
                      <LinesSection lines={receipt.lines} />
                    )}
                    <AnnotationSection entity={receipt} />
                  </Stack>
                  <ContentConnections
                    entity={receipt}
                    entityType={EntityType.RECEIPT}
                  />
                </Stack>
              ),
            },
          ]}
        />
      )}
    </HomeLayout>
  );
};

export default ViewReceipt;
