enum PropertyExternalService {
  EMAIL_SWEDISH_MUNICIPALITY = 'email_swedish_municipality',
  ENERGY_DECLARATION = 'energy_declarations',
  UC_PROPERTY_INFORMATION_DATA = 'uc_property_information_data',
  UC_TAXATION_DATA = 'uc_taxation_data',
  JS_ENERGY = 'jsenergi',
  CHIMNEY = 'chimney',
  SVENSKFAST_SELLER = 'svenskfast-seller',
  SVENSKFAST_CURRENTLY_SELLING = 'svenskfast-currently-selling',
  SVENSKFAST_BUYER = 'svenskfast-buyer',
}

export default PropertyExternalService;
