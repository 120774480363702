import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  FC,
  ReactElement,
} from 'react';
import { ChevronRight } from '@mui/icons-material';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../types/common';
import { hidSpacing } from '../../../../../utils/number';
import { getNoWrapStyle } from '../../../../../utils/style';
import ContentImage from '../../Content/components/ContentImage';

type PropertyProgressContentItemProps = {
  noIcon?: boolean;
  Icon?: FC,
  blob?: HIDBlob,
  externalImage?: string,
  title: string,
  description?: string,
  DescriptionComponent?: ReactElement,
  height?: number;
  onNavigate?: () => void,
};

const PropertyProgressContentItem: FCC<PropertyProgressContentItemProps> = ({
  Icon,
  noIcon = false,
  blob,
  externalImage,
  title,
  description,
  DescriptionComponent,
  onNavigate,
  height = hidSpacing(10),
  sx,
}) => {
  const theme = useTheme();

  const handleOpenDocument = () => {
    if (onNavigate) {
      onNavigate();
    }
  };

  return title
    ? (
      <Card
        sx={{
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          padding={1}
          sx={{
            backgroundColor: theme.palette.grey[100],
            height,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
            '&:active': {
              backgroundColor: theme.palette.grey[300],
            },
            ...sx,
          }}
          onClick={handleOpenDocument}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            {!noIcon
              ? Icon
                ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minWidth: theme.spacing(8), marginRight: theme.spacing(1.5) }}
                  >
                    <Icon />
                  </Stack>
                )
                : (
                  <ContentImage
                    blobs={blob ? [blob] : []}
                    externalImage={externalImage}
                    imageSx={{ maxHeight: theme.spacing(8) }}
                    sx={{ marginRight: theme.spacing(1.5) }}
                  />
                )
              : null}
            <Stack justifyContent="center" spacing={0}>
              <Typography sx={getNoWrapStyle(1)} variant="subtitle1">{title}</Typography>
              {
                DescriptionComponent || <Typography sx={getNoWrapStyle(1)}>{description}</Typography>
              }
            </Stack>
          </Stack>
          <ChevronRight />
        </Stack>
      </Card>
    )
    : null;
};

export default PropertyProgressContentItem;
