import { useSelector } from 'react-redux';
import React from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import * as R from 'ramda';
import {
  Receipt,
  InferenceReceipt,
  ReceiptSummaryTotal,
  ReceiptCategory,
} from '@house-id/houseid-types/dist/content/receipt';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  CreateEntity,
  HIDEntity,
  HIDEntityId,
} from '../../../../../../../types/common';
import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import { GroupedReceiptsSummary } from '../types.receipt';
import { getUrlSearchParams } from '../../../../../../../utils/url';
import {
  TimeIntervalsGroupingType,
  DeleteContentParams,
} from '../../../types/types.content';
import { removeEmptyParams } from '../../../../../../../utils/object';
import { propertyApi } from '../../../../../api/api.property';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type ReceiptsSummaryParams = {
  propertyId: string;
  from?: string;
  to?: string;
  entityIdsMap?: Record<EntityType, Array<string>>;
  groupingType?: string;
  categoryId?: string,
  boughtByUsers?: Array<string>;
};

type ReceiptsSummaryTotalParams = {
  propertyId: string;
  from?: string;
  to?: string;
};

type GetAllReceiptsParams = PropertyId & {
  from?: string;
  to?: string;
  entityIdsMap?: Record<EntityType, Array<string>>;
  boughtByUsers?: Array<string>;
};

export const receiptApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllReceiptCategories: builder.query<Array<ReceiptCategory>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/receipts/categories`,
      transformResponse: (response: Array<{ id: string, name: string }>) =>
        R.sortBy(({ name }) => name, response),
      providesTags: [HIDApiTags.RECEIPT_CATEGORY],
    }),
    getReceiptsSummary: builder.query<GroupedReceiptsSummary, ReceiptsSummaryParams>({
      query: ({
        propertyId,
        from,
        to,
        entityIdsMap,
        groupingType = TimeIntervalsGroupingType.Yearly,
        categoryId,
        boughtByUsers,
      }) => {
        const queryParams = qs.stringify(
          removeEmptyParams({
            from,
            to,
            ...entityIdsMap,
            groupingType,
            categoryIds: categoryId,
            boughtBy: boughtByUsers,
          }),
          {
            skipNull: true,
            skipEmptyString: true,
          },
        );
        return `/properties/${propertyId}/receipts/summary?${queryParams}`;
      },
      providesTags: [HIDApiTags.RECEIPT_SUMMARY],
    }),
    getReceiptSummaryTotal: builder.query<ReceiptSummaryTotal, ReceiptsSummaryTotalParams>({
      query: ({ propertyId, from, to }) => `/properties/${propertyId}/receipts/summary/total?${getUrlSearchParams({ from, to })}`,
    }),
    getAllReceipts: builder.query<Array<Receipt>, GetAllReceiptsParams>({
      query: ({
        propertyId,
        from,
        to,
        entityIdsMap,
        boughtByUsers,
      }) => {
        const queryParams = qs.stringify(
          removeEmptyParams({
            from,
            to,
            ...entityIdsMap,
            boughtBy: boughtByUsers,
          }),
          {
            skipNull: true,
            skipEmptyString: true,
          },
        );
        return `properties/${propertyId}/receipts?${queryParams}`;
      },
      providesTags: (result) => provideArrayTags(HIDApiTags.RECEIPT, result),
    }),
    getReceipt: builder.query<Receipt, { propertyId: string, id: string }>({
      query: ({ propertyId, id }) => `/properties/${propertyId}/receipts/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.RECEIPT, id: arg.id }],
    }),
    deleteReceipts: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `/properties/${propertyId}/receipts?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        ...arg.ids.map((id) => ({ type: HIDApiTags.RECEIPT as const, id })),
        HIDApiTags.RECEIPT_SUMMARY,
        HIDApiTags.RECEIPT_CATEGORY,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PLACE,
        HIDApiTags.RECEIPT_FILTERS,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    createReceipt: builder.mutation<Receipt, CreateEntity<Receipt> & PropertyId>({
      query: (receipt) => ({
        url: `/properties/${receipt.propertyId}/receipts`,
        method: 'POST',
        body: receipt,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: () => [
        HIDApiTags.RECEIPT_CATEGORY,
        HIDApiTags.RECEIPT_SUMMARY,
        HIDApiTags.RECEIPT,
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PLACE,
        HIDApiTags.RECEIPT_FILTERS,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    updateReceipt: builder.mutation<Receipt, Partial<Receipt> & PropertyId & HIDEntityId>({
      query: (receipt) => ({
        url: `/properties/${receipt.propertyId}/receipts/${receipt.id}`,
        method: 'PATCH',
        body: receipt,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        HIDApiTags.RECEIPT_SUMMARY,
        HIDApiTags.RECEIPT_CATEGORY,
        { type: HIDApiTags.RECEIPT as const, id: arg.id },
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PLACE,
        HIDApiTags.RECEIPT_FILTERS,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    scanReceipt: builder.query<InferenceReceipt, PropertyId & { blobId: string }>({
      query: ({ propertyId, blobId }) => ({
        url: `/properties/${propertyId}/blobs/${blobId}/inference/invoice`,
        method: 'GET',
      }),
      transformErrorResponse: (response) => response.status === 422
        ? {
          ...response,

          data: { ...(response.data as object), skipGlobalHandle: true },
        }
        : response,
    }),
    rateScanReceipt: builder.mutation<void, PropertyId & { blobId: string, rating: number, inferenceId: string }>({
      query: ({
        propertyId, blobId, rating, inferenceId,
      }) => ({
        url: `/properties/${propertyId}/blobs/${blobId}/inference/invoice/${inferenceId}`,
        method: 'PATCH',
        body: { rating },
      }),
    }),
    getReceiptFilters: builder.query<Record<EntityType, Array<HIDEntity>>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/receipts/filters`,
      providesTags: () => [{ type: HIDApiTags.RECEIPT_FILTERS }],
    }),
  }),
});

export const {
  useGetAllReceiptCategoriesQuery,
  useGetReceiptsSummaryQuery,
  useGetReceiptSummaryTotalQuery,
  useGetAllReceiptsQuery,
  useDeleteReceiptsMutation,
  useCreateReceiptMutation,
  useUpdateReceiptMutation,
  useLazyScanReceiptQuery,
  useRateScanReceiptMutation,
  useGetReceiptFiltersQuery,
} = receiptApi;

export const useGetReceiptWithCache = ({
  propertyId,
  receiptId,
}: {
  propertyId?: string,
  receiptId?: string,
}) => {
  const selectReceipts = React.useMemo(
    () => receiptApi.endpoints.getAllReceipts.select(propertyId ? { propertyId } : skipToken),
    [propertyId],
  );

  const [
    _deleteReceipts,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteReceiptsMutation({
    fixedCacheKey: getMutationFixedCacheKey(receiptId),
  });
  const isDeletingOrDeleted = isDeleting || isDeleted;

  const { data: cachedReceipts } = useSelector(selectReceipts);
  const cachedReceipt = cachedReceipts?.find((receipt) => receipt.id === receiptId);

  const {
    data: fetchedReceipt,
    isLoading,
    error,
  } = receiptApi.useGetReceiptQuery(
    propertyId && receiptId && !cachedReceipt && !isDeletingOrDeleted
      ? { propertyId, id: receiptId }
      : skipToken,
  );

  return {
    receipt: fetchedReceipt || cachedReceipt,
    isLoading,
    error,
  };
};
