import qs from 'query-string';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import { Photo } from '../types.photo';
import { DeleteContentParams } from '../../../types/types.content';
import {
  HIDEntityId,
} from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/api.property';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const photoApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPhotos: builder.query<Array<Photo>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/photos`,
      providesTags: (result) => provideArrayTags(HIDApiTags.PHOTO, result),
    }),
    createPhoto: builder.mutation<Photo, { blobs: Array<HIDBlob>, name: string } & PropertyId>({
      query: ({ blobs, name, propertyId }) => ({
        url: `/properties/${propertyId}/photos`,
        method: 'POST',
        body: {
          name,
          blobs,
        },
      }),
      invalidatesTags: () => [
        HIDApiTags.PHOTO,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    createPhotos: builder.mutation<Array<Photo>, { photos: Array<{ blobs: Array<HIDBlob>, name: string }> } & PropertyId>({
      query: ({ photos, propertyId }) => ({
        url: `/properties/${propertyId}/photos/bulk`,
        method: 'POST',
        body: photos,
      }),
      invalidatesTags: () => [
        HIDApiTags.PHOTO,
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    updatePhoto: builder.mutation<Photo, Partial<Photo> & PropertyId & HIDEntityId>({
      query: (photo) => ({
        url: `/properties/${photo.propertyId}/photos/${photo.id}`,
        method: 'PATCH',
        body: photo,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.PHOTO as const, id: arg.id },
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    updatePhotos: builder.mutation<Array<Photo>, { photos: Array<Partial<Photo>> } & PropertyId>({
      query: ({ photos, propertyId }) => ({
        url: `/properties/${propertyId}/photos/bulk`,
        method: 'PATCH',
        body: photos,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        ...arg.photos.map(({ id }) => ({ type: HIDApiTags.PHOTO as const, id })),
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    deletePhotos: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `/properties/${propertyId}/photos?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        ...arg.ids.map((id) => ({ type: HIDApiTags.PHOTO as const, id })),
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
  }),
});

export const {
  useGetAllPhotosQuery,
  useCreatePhotoMutation,
  useCreatePhotosMutation,
  useUpdatePhotoMutation,
  useUpdatePhotosMutation,
  useDeletePhotosMutation,
} = photoApi;
