import { createApi } from '@reduxjs/toolkit/query/react';

import {
  HIDQuery,
} from '../../../api/HIDBaseQuery';
import { CreateUser } from '../types/types.auth';
import { HIDUser } from '@house-id/houseid-types/dist/common';

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: HIDQuery,
  endpoints: (builder) => ({
    isEmailAvailable: builder.query<boolean, { email: string }>({
      query: ({ email }) => `/users/emailaddress/availability?emailAddress=${encodeURIComponent(email)}`,
      transformResponse: (response: { available: boolean }) => response.available,
    }),
    createUser: builder.mutation<HIDUser, CreateUser>({
      query: (data: CreateUser) => ({
        url: 'users/register',
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),
    loginUserWithToken: builder.mutation<{ customToken: string }, { loginToken: string }>({
      query: ({ loginToken }) => ({
        url: 'login/token',
        method: 'POST',
        body: { token: loginToken },
      }),
      transformErrorResponse: (response) => ({
        ...response,
        data: { ...(response.data as object), skipGlobalHandle: true },
      }),
    }),
  }),
});

export const {
  useLazyIsEmailAvailableQuery,
  useCreateUserMutation,
  useLoginUserWithTokenMutation,
} = authApi;
