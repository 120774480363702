import React, { ReactElement } from 'react';
import {
  Box,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { FCC } from '../../../types/common';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import HIDLogo from '../../../components/HIDLogo';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import {
  HIDZIndex,
  LOGIN_PAGE_OUTER_SPACING,
} from '../../../constants/layout';
import { getLoginPath } from '../navigation/navigation.auth';
import usePageTracking from '../../Analytics/hooks/usePageTracking';

type LoginLayoutProps = {
  canGoBack?: boolean;
  backgroundImageUrl: string;
  message: string;
  rightTitle?: string;
  RightComponent: ReactElement;
  onArrowBackClick?: () => void;
};

const HIDIconButtonHeight = 5;

const LoginLayout: FCC<LoginLayoutProps> = ({
  canGoBack = true,
  backgroundImageUrl,
  message,
  rightTitle,
  RightComponent,
  onArrowBackClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  usePageTracking();

  const { isDownSm, isDownMd, isDownLg } = useBreakPointsSizes();

  const headerPaddingTop = LOGIN_PAGE_OUTER_SPACING;
  const headerPaddingX = LOGIN_PAGE_OUTER_SPACING;

  const messagePaddingLeft = {
    xxs: 4,
    lg: 8,
    xl: 15,
  };

  const messageVariant = isDownLg
    ? 'h2'
    : 'h1';

  const handleGoBack = () => onArrowBackClick ? onArrowBackClick() : navigate(-1);
  const handleGoLogin = () => navigate(getLoginPath());

  return (
    <Grid
      container
      style={{ height: '100dvh' }}
    >
      {!isDownSm && (
        <Grid
          item
          lg={6}
          sm={5}
          sx={{ height: '100dvh' }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            paddingTop={headerPaddingTop}
            paddingX={headerPaddingX}
            sx={{
              position: 'absolute',
              zIndex: HIDZIndex.LOGO,
            }}
          >
            {!isDownSm && (
              <HIDLogo color="secondary" onClick={handleGoLogin} />
            )}
          </Stack>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              height: '100dvh',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image={backgroundImageUrl}
                style={{ height: '100dvh' }}
              />
              <Typography
                color={theme.palette.primary.lightest}
                fontWeight="bold"
                paddingLeft={messagePaddingLeft}
                paddingRight={5}
                sx={{
                  position: 'absolute',
                  top: '40%',
                }}
                variant={messageVariant}
              >
                {message}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        lg={6}
        sm={7}
        style={{ height: '100dvh', overflowY: 'auto' }}
        xxs={12}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          paddingTop={headerPaddingTop}
          paddingX={headerPaddingX}
        >
          {
            canGoBack
              ? (
                <HIDIconButton
                  Icon={ArrowBack}
                  sx={{
                    marginLeft: isDownMd ? theme.spacing(-1) : 0,
                  }}
                  onClick={handleGoBack}
                />
              )
              : null
          }
          {isDownSm ? <HIDLogo onClick={handleGoLogin} /> : null}
        </Stack>
        {rightTitle && (
          <Typography
            sx={{
              marginTop: isDownSm ? 2 : 0,
              marginBottom: isDownMd ? 0 : 1,
              paddingLeft: headerPaddingX,
            }}
            variant={isDownMd ? 'h4' : 'h3'}
          >
            {rightTitle}
          </Typography>
        )}
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            minHeight: isDownSm
              ? `calc(100dvh - ${theme.spacing(headerPaddingTop.xxs + HIDIconButtonHeight)})`
              : isDownLg
                ? `calc(100dvh - ${theme.spacing(headerPaddingTop.sm + HIDIconButtonHeight)})`
                : `calc(100dvh - ${theme.spacing(headerPaddingTop.lg + HIDIconButtonHeight)})`,
          }}
        >
          <Grid
            item
            alignItems="center"
            justifyContent="center"
            lg={8}
            md={10}
            sm={12}
            sx={{
              width: '100%',
              padding: {
                sm: theme.spacing(3),
                xxs: theme.spacing(2.5),
              },
            }}
            xl={7}
            xxs={12}
          >
            {RightComponent}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default LoginLayout;
