import React, {
  useMemo,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';
import { isValid } from 'date-fns';

import HIDFilterDatePicker from './HIDFilterDatePicker';
import { DateTimeFormats } from '../../utils/date';
import { FILTER_DEBOUNCE_TIME } from '../../constants/layout';
import HIDTypography from '../HIDTypography';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import { FCC } from '../../types/common';

const now = new Date();

type HIDMonthRangePickerProps = {
  from?: number;
  to?: number;
  onChange: (dates: { from?: number, to?: number }) => void;
};

const HIDMonthRangePicker: FCC<HIDMonthRangePickerProps> = ({
  from,
  to,
  sx,
  onChange,
}) => {
  const { t } = useTranslation(['common']);

  const { isDownXl, isDownLg } = useBreakPointsSizes();

  const [fromDate, setFromDate] = useState<Date | undefined>(from ? new Date(now.getFullYear(), from - 1, 1) : undefined);
  const [toDate, setToDate] = useState<Date | undefined>(to ? new Date(now.getFullYear(), to - 1, 1) : undefined);

  const filtersObject = useMemo(
    () => ({
      from: fromDate,
      to: toDate,
    }),
    [
      fromDate,
      toDate,
    ],
  );

  const debouncedFilters = useDebounce(
    filtersObject,
    FILTER_DEBOUNCE_TIME,
  );

  useUpdateEffect(() => {
    if ((!debouncedFilters.from || isValid(debouncedFilters.from))
      && (!debouncedFilters.to || isValid(debouncedFilters.to))
    ) {
      onChange({
        from: debouncedFilters.from ? debouncedFilters.from.getMonth() + 1 : undefined,
        to: debouncedFilters.to ? debouncedFilters.to.getMonth() + 1 : undefined,
      });
    }
  }, [debouncedFilters]);

  return (
    <Stack
      alignItems={isDownXl ? 'stretch' : 'center'}
      direction={isDownXl ? 'column' : 'row'}
      gap={isDownXl ? 0 : 0.5}
      justifyContent="center"
      sx={sx}
    >
      <HIDFilterDatePicker
        fullWidth
        dateTimeFormat={DateTimeFormats.MONTH_ONLY}
        label={t('common:from_month')}
        maxDate={toDate}
        slotProps={{
          layout: {
            sx: isDownLg ? undefined : { height: '210px' },
          },
        }}
        sx={{ display: 'flex', flexGrow: 1 }}
        value={fromDate}
        views={['month']}
        onChange={setFromDate}
      />
      {!isDownXl && (
        <HIDTypography sx={{ mt: 2 }}>—</HIDTypography>
      )}
      <HIDFilterDatePicker
        fullWidth
        dateTimeFormat={DateTimeFormats.MONTH_ONLY}
        label={t('common:to_month')}
        minDate={fromDate}
        slotProps={{
          layout: {
            sx: isDownLg ? undefined : { height: '210px' },
          },
        }}
        sx={{ display: 'flex', flexGrow: 1 }}
        value={toDate}
        views={['month']}
        onChange={setToDate}
      />
    </Stack>
  );
};

export default HIDMonthRangePicker;
