import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Card,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import {
  FormikProvider,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  BudgetPayload,
  BudgetTemplateCategoryId,
  BudgetTemplateType,
  CreateUpdateBudgetValues,
  RemappedBudgetCategory,
} from '@house-id/houseid-types/dist/finances/budgets';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../utils/routes';
import HIDButton from '../../../../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import { getFinancesPath } from '../../../../navigation.finances';
import { getNoWrapStyle } from '../../../../../../../../utils/style';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import {
  useGetBudgetTemplatesQuery,
  useCreateBudgetMutation,
  useUpdateBudgetMutation,
  useGetBudgetQuery,
} from '../../api/api.budgets';
import BudgetCategorySection from './components/BudgetCategorySection';
import BudgetHeaderFields from './components/BudgetHeaderFields';
import BudgetSummaryCard from './components/BudgetSummaryCard';
import NoDataPlaceholder from '../../../../../../../../components/NoDataPlaceholder';
import BudgetCategoriesSidebar from './components/BudgetCategoriesSidebar';
import useGetBudgetValidationSchema from './hooks/useGetBudgetValidationSchema';
import getBudgetPayload from './utils/getBudgetPayload';
import { getBudgetsOverviewPath } from '../../navigation.budgets';
import useBudgetCategoriesDataMapper from './hooks/useBudgetCategoriesDataMapper';
import useGetFormikErrorFieldPath from './hooks/useGetFormikErrorFieldPath';
import { getCategoryDataPath } from './utils/utils.formikFieldDataPath';
import { IncompleteBudget } from '../../types.budgets';
import {
  useSetPropertySettingsMutation,
  useGetPropertySettingsQuery,
} from '../../../../../../../Auth/api/api.settings';
import { useGetActiveAuthUser } from '../../../../../../../../external-services/firebase';

const Preloader: FC = () => (
  <Stack spacing={2} sx={{ marginBottom: 3 }}>
    <Skeleton height="300px" variant="rounded" width="100%" />
    <Skeleton height="300px" variant="rounded" width="100%" />
    <Skeleton height="300px" variant="rounded" width="100%" />
    <Skeleton height="300px" variant="rounded" width="100%" />
  </Stack>
);

const CreateUpdateBudget: FC = () => {
  const { t } = useTranslation(['finances', 'common', 'forms_common']);
  const { user: currentUser } = useGetActiveAuthUser();

  const {
    routeParams: { id: budgetId },
    queryParams: { categoryId: categoryIdQueryParam },
    state: { incompleteBudget },
  } = useNavigationParamsAndState<{ id?: string; }, { categoryId?: string }, { incompleteBudget?: IncompleteBudget }>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [setPropertySettings] = useSetPropertySettingsMutation();
  const {
    data: propertySettings,
  } = useGetPropertySettingsQuery(propertyId && currentUser ? { userUId: currentUser.uid, propertyId } : skipToken);

  const { isDownSm, isDownMd, isDownLg } = useBreakPointsSizes();

  const getFirstFieldPathWithError = useGetFormikErrorFieldPath();

  const navigateBackOr = useNavigateBackOr();
  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const {
    data: budgetTemplates,
    isLoading: isTemplatesLoading,
  } = useGetBudgetTemplatesQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: budget,
    isLoading: isBudgetLoading,
  } = useGetBudgetQuery(propertyId && budgetId ? { propertyId, budgetId } : skipToken);

  const isBudgetCreated = useRef(false);

  const [createBudget, { isLoading: isCreatingBudget }] = useCreateBudgetMutation();
  const [updateBudget, { isLoading: isUpdatingBudget }] = useUpdateBudgetMutation();

  const {
    getEmptyCategoryEntity,
    getCategoryEntityWithData,
  } = useBudgetCategoriesDataMapper();

  const [openBudgetCategoriesDialog] = useDialog(DialogNames.BUDGET_MANAGE_CATEGORIES_DIALOG);

  const goToBudgetOverview = (budgetId: string) => {
    if (incompleteBudget && propertyId) {
      isBudgetCreated.current = true;
      setPropertySettings({
        propertyId,
        settings: {
          ...propertySettings,
          incompleteBudget: null,
        },
      });
    }
    navigateBackOr(propertyId ? getBudgetsOverviewPath({ propertyId, id: budgetId }) : undefined);
  };

  const handleFormSubmit = (values: CreateUpdateBudgetValues) => {
    const posts = getBudgetPayload(values);

    if (propertyId && values.name && values.year) {
      const budgetPayload: BudgetPayload = {
        name: values.name,
        year: Number(values.year),
        posts: R.flatten(posts),
      };

      if (budgetId) {
        updateBudget({
          budgetId,
          propertyId,
          budget: budgetPayload,
        })
          .then(() => goToBudgetOverview(budgetId));
      } else {
        createBudget({
          propertyId,
          budget: budgetPayload,
        })
          .unwrap()
          .then(({ id }) => goToBudgetOverview(id));
      }
    }
  };

  const initialValues: CreateUpdateBudgetValues = useMemo(() => {
    if (budgetTemplates && !isBudgetLoading) {
      if (incompleteBudget) {
        const categories = JSON.parse(incompleteBudget.categoriesMap) as Partial<Record<BudgetTemplateCategoryId, RemappedBudgetCategory>>;
        return { name: incompleteBudget.name, year: incompleteBudget.year.toString(), categories };
      }
      if (budgetId && budget && budget.categories.length) {
        const categories = budget.categories.reduce((acc, item) => {
          const template = budgetTemplates[item.categoryId];
          acc[item.categoryId] = getCategoryEntityWithData(template, item);

          return acc;
        }, {} as Partial<Record<BudgetTemplateCategoryId, RemappedBudgetCategory>>);

        return {
          name: budget.name,
          year: String(budget.year),
          categories,
        };
      }
      const categories = Object.values(budgetTemplates).reduce((acc, template) => {
        if (template.type === BudgetTemplateType.DEFAULT) {
          acc[template.id] = getEmptyCategoryEntity(template);
        }

        return acc;
      }, {} as Partial<Record<BudgetTemplateCategoryId, RemappedBudgetCategory>>);

      return { name: undefined, year: undefined, categories };
    }

    return { name: undefined, year: undefined, categories: {} };
  }, [budget, budgetId, budgetTemplates, isBudgetLoading, incompleteBudget]);

  const schema = useGetBudgetValidationSchema();

  const formik = useFormik<CreateUpdateBudgetValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      const errorFieldInfo = getFirstFieldPathWithError(errors);
      formik.setStatus(errorFieldInfo);
    }

    formik.submitForm();
  };

  const isCategoryEditMode = Boolean(categoryIdQueryParam);

  const currentCategories = useMemo(
    () =>
      Object.values(formik.values.categories)
        .filter(
          categoryIdQueryParam
            ? (category: RemappedBudgetCategory) => category.categoryId === categoryIdQueryParam
            : Boolean,
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [formik.values.categories, categoryIdQueryParam],
  );

  const activePaymentCategoryIds = useMemo(
    () => Object.keys(formik.values.categories) as BudgetTemplateCategoryId[],
    [formik.values.categories],
  );

  const handleAddCategory = (categoryId: BudgetTemplateCategoryId) => {
    const template = budgetTemplates?.[categoryId];

    if (template) {
      const newCategory = getEmptyCategoryEntity(template);
      formik.setFieldValue(getCategoryDataPath(template.id), newCategory);
    }
  };

  const manageCategoriesVisibility = (ids: Array<BudgetTemplateCategoryId>) => {
    currentCategories.forEach((category) => {
      if (!ids.includes(category.categoryId)) {
        formik.setFieldValue(getCategoryDataPath(category.categoryId), undefined);
      }
    });

    const newCategoryIds = ids.filter((id) => !currentCategories.some((category) => category.categoryId === id));

    newCategoryIds.forEach((id) => {
      const template = budgetTemplates?.[id];
      if (template) {
        formik.setFieldValue(getCategoryDataPath(id), getEmptyCategoryEntity(template));
      }
    });
  };

  const saveIncompleteBudget = () => {
    if (propertyId) {
      setPropertySettings({
        propertyId,
        settings: {
          ...propertySettings,
          incompleteBudget: {
            name: formik.values.name || '',
            year: Number(formik.values.year) || new Date().getFullYear(),
            categoriesMap: JSON.stringify(formik.values.categories),
          },
        },
      });
    }
  };

  useEffect(() =>
    () => {
      if (!isBudgetCreated.current && !budgetId) {
        saveIncompleteBudget();
      }
    }, [formik.values, isBudgetCreated]);

  const handleOpenManageBudgetCategoriesDialog = () => {
    openBudgetCategoriesDialog({
      activeCategoryIds: activePaymentCategoryIds,
      onApply: manageCategoriesVisibility,
    });
  };

  const handleCancel = () => navigateBackOr(propertyId ? getBudgetsOverviewPath({ propertyId }) : undefined);

  const totalPerYear = useMemo(
    () => R.sum(Object.values(formik.values.categories).map(({ annualAmount }) => annualAmount)),
    [formik.values.categories],
  );

  const isLoading = isTemplatesLoading || isBudgetLoading;

  const isEmptyCategoriesData = !currentCategories.length;

  return (
    <HomeLayout
      ContentComponent={
        <Card
          sx={
            isDownMd
              ? { boxShadow: 'none', marginTop: 2.5 }
              : { padding: 4, marginTop: 2.5 }
          }
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{ marginBottom: 3 }}
          >
            <Typography
              component="h1"
              sx={getNoWrapStyle()}
              variant={isDownLg ? 'h4' : 'h3'}
            >
              {t('finances:budget_payment_items')}
            </Typography>
            {!isCategoryEditMode && (
              <HIDButton
                color="secondary"
                loading={isLoading}
                size="small"
                onClick={handleOpenManageBudgetCategoriesDialog}
              >
                {t('finances:budgets_manage_payment_items')}
              </HIDButton>
            )}
          </Stack>
          {
            isLoading
              ? <Preloader />
              : (
                <NoDataPlaceholder hasData={!isEmptyCategoriesData}>
                  <FormikProvider value={formik}>
                    <Stack
                      spacing={2}
                      sx={{ marginBottom: 3 }}
                    >
                      {currentCategories.map((entity) => (
                        <BudgetCategorySection
                          data={entity}
                          key={entity.categoryId}
                          template={budgetTemplates?.[entity.categoryId]}
                        />
                      ))}
                    </Stack>
                  </FormikProvider>
                </NoDataPlaceholder>
              )
          }
          <Stack
            direction="row"
            justifyContent={isDownSm ? 'center' : 'flex-end'}
            spacing={isDownSm ? 2.5 : 2}
          >
            <HIDButton
              color="secondary"
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </HIDButton>
            <HIDButton
              disabled={isEmptyCategoriesData}
              loading={isLoading || isCreatingBudget || isUpdatingBudget}
              onClick={handleSave}
            >
              {t('common:save')}
            </HIDButton>
          </Stack>
        </Card>
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <BudgetSummaryCard totalPerYear={totalPerYear} />
          </Card>
          {!isCategoryEditMode && (
            <Card sx={{ padding: 2 }}>
              <BudgetCategoriesSidebar
                activeCategoryIds={activePaymentCategoryIds}
                onAdd={handleAddCategory}
              />
            </Card>
          )}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <BudgetSummaryCard
          key={BudgetSummaryCard.name}
          totalPerYear={totalPerYear}
        />,
        !isCategoryEditMode && (
          <BudgetCategoriesSidebar
            activeCategoryIds={activePaymentCategoryIds}
            key={BudgetCategoriesSidebar.name}
            onAdd={handleAddCategory}
          />
        ),
      ].filter(Boolean)}
      title={budgetId ? t('finances:budget_update_title') : t('finances:budget_create_title')}
      onBack={handleGoBack}
    >
      <BudgetHeaderFields formik={formik} />
    </HomeLayout>
  );
};

export default CreateUpdateBudget;
