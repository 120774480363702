import { useTranslation } from 'react-i18next';
import {
  Login,
  Logout,
} from '@mui/icons-material';

import {
  Bidding,
  Camera,
  Clean,
  PenSigning,
  Umbrella,
} from '../../../../../components/icons/Icons';
import { Icon } from '../../../../../types/common';
import { useNavigateToMove } from '../../../navigation/navigation.property';
import useGetOpenPropertyDeclarationMobileAppDialog from './useGetOpenPropertyDeclarationMobileAppDialog';

export enum SellingPropertyChecklistIds {
  DOCUMENT_YOUR_RESIDENCE = 'document_your_residence',
  VIEWING = 'viewing',
  BIDDING = 'bidding',
  CONTRACT = 'contract',
  READY_TO_MOVE = 'ready_to_move',
  ACCESS = 'access',
  YOUR_NEW_HOME = 'your_new_home',
}

export type SellingPropertyChecklistItemType = {
  id: SellingPropertyChecklistIds;
  Icon: Icon;
  description: string;
  title: string;
  text: string;
  linkText?: string;
  onLinkClick?: () => void;
};

const FMI_BIDDING = 'https://fmi.se/vad-galler-vid-formedling/budgivning/';

type UseGetSellingPropertyChecklist = () => Array<SellingPropertyChecklistItemType>;

const useGetSellingPropertyChecklist: UseGetSellingPropertyChecklist = () => {
  const { t } = useTranslation(['selling_property']);

  const navigateToMove = useNavigateToMove();

  const openPropertyDeclarationMobileAppDialog = useGetOpenPropertyDeclarationMobileAppDialog();

  const handleNavigateToFmiBidding = () => window.open(FMI_BIDDING, '_blank');

  const listItems: Array<SellingPropertyChecklistItemType> = [
    {
      id: SellingPropertyChecklistIds.DOCUMENT_YOUR_RESIDENCE,
      Icon: Clean,
      description: t('selling_property:selling_property_checklist_document_your_residence_description'),
      title: t('selling_property:selling_property_checklist_document_your_residence_title'),
      text: t('selling_property:selling_property_checklist_document_your_residence_text'),
    },
    {
      id: SellingPropertyChecklistIds.VIEWING,
      Icon: Camera,
      description: t('selling_property:selling_property_checklist_viewing_description'),
      title: t('selling_property:selling_property_checklist_viewing_title'),
      text: t('selling_property:selling_property_checklist_viewing_text'),
    },
    {
      id: SellingPropertyChecklistIds.BIDDING,
      Icon: Bidding,
      description: t('selling_property:selling_property_checklist_bidding_description'),
      title: t('selling_property:selling_property_checklist_bidding_title'),
      text: t('selling_property:selling_property_checklist_bidding_text'),
      linkText: t('selling_property:selling_property_checklist_bidding_link_text'),
      onLinkClick: handleNavigateToFmiBidding,
    },
    {
      id: SellingPropertyChecklistIds.CONTRACT,
      Icon: PenSigning,
      description: t('selling_property:selling_property_checklist_contract_description'),
      title: t('selling_property:selling_property_checklist_contract_title'),
      text: t('selling_property:selling_property_checklist_contract_text'),
    },
    {
      id: SellingPropertyChecklistIds.READY_TO_MOVE,
      Icon: Logout,
      description: t('selling_property:selling_property_checklist_ready_to_move_description'),
      title: t('selling_property:selling_property_checklist_ready_to_move_title'),
      text: t('selling_property:selling_property_checklist_ready_to_move_text'),
      linkText: t('selling_property:selling_property_checklist_ready_to_move_link_text'),
      onLinkClick: () => navigateToMove(),
    },
    {
      id: SellingPropertyChecklistIds.ACCESS,
      Icon: Login,
      description: t('selling_property:selling_property_checklist_access_description'),
      title: t('selling_property:selling_property_checklist_access_title'),
      text: t('selling_property:selling_property_checklist_access_text'),
    },
    {
      id: SellingPropertyChecklistIds.YOUR_NEW_HOME,
      Icon: Umbrella,
      description: t('selling_property:selling_property_checklist_your_new_home_description'),
      title: t('selling_property:selling_property_checklist_your_new_home_title'),
      text: t('selling_property:selling_property_checklist_your_new_home_text'),
      linkText: t('selling_property:selling_property_checklist_your_new_home_link_text'),
      onLinkClick: openPropertyDeclarationMobileAppDialog,
    },
  ];

  return listItems;
};

export default useGetSellingPropertyChecklist;
