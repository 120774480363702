import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HandOver: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon
      size={size}
      sx={sx}
    >
      <path
        d="M5 9.24952C5 9.24952 5.57143 9.24948 6.14287 9.24952C6.46577 9.24954 7.41932 8.57243 8.42861 8.23857C9.26814 7.96086 10.1122 7.87845 10.9429 8.24938C11.8094 8.63764 13.0001 9.97752 13.0001 9.97752H15.467C16.3139 9.97752 17 10.6539 17 11.4888C17 12.3236 16.3139 13 15.467 13C14.2651 13 13.0632 13 11.8613 13"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M5 14.7143H6.10324L12.8454 16.9005C13.7267 17.13 14.6628 16.9582 15.4079 16.4298L22.4029 11.5747C22.6749 11.3819 22.8697 11.0964 22.9516 10.7711L22.9564 10.7528C23.1539 9.97028 22.6605 9.18128 21.8777 9.02617C21.5679 8.96478 21.2465 9.01217 20.9666 9.16027L17.0366 11.2593"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <rect
        height="8"
        rx="1"
        stroke={color}
        strokeWidth={strokeWidth}
        width="4"
        x="1"
        y="8"
      />
    </HIDSvgIcon>
  );
};

export default HandOver;
