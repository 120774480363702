import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

import { FCC } from '../types/common';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';

type HIDTagProps = {
  label: string;
  color: string
};

const HIDTag: FCC<HIDTagProps> = ({
  label,
  color,
  style,
  sx,
}) => {
  const theme = useTheme();
  const { isDownMd } = useBreakPointsSizes();

  return (
    <Stack
      alignSelf="center"
      style={style}
      sx={{
        backgroundColor: color,
        borderRadius: '2px',
        padding: theme.spacing(0, 0.5),
        ...sx,
      }}
    >
      <Typography
        sx={{ color: theme.palette.common.white }}
        variant={isDownMd ? 'body2' : 'body1'}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export default HIDTag;
