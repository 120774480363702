import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  PropertyProgressCategorySectionProps,
  PropertyToDoPropertyCheckSectionData,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import { useGetEnspectaPropertyCheckTasksQuery } from '../../../api/api.enspecta';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import DateCard from './DateCard';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';

const PropertyToDoPropertyCheckSection: FC<PropertyProgressCategorySectionProps<PropertyToDoPropertyCheckSectionData>> = ({
  section,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'property_progress']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: partnerTaskGroupDetails } = useGetEnspectaPropertyCheckTasksQuery(propertyId ? { propertyId } : skipToken);

  const partnerTaskGroupDetailsInfo = partnerTaskGroupDetails?.info;

  const benefits = [
    t('property_progress:property_check_professional_assessment_of_your_home'),
    t('property_progress:property_check_concrete_action_proposals_and_recommendations'),
    t('property_progress:property_check_energy_saving_measures'),
  ];

  const notBooked = !partnerTaskGroupDetailsInfo;

  const NotBooked = (
    <Stack gap={2.5}>
      <HIDTypography variant="h4">
        {t('property_progress:property_check_not_booked')}
      </HIDTypography>
      <HIDTypography variant="body1">
        {t('property_progress:property_check_with_help_of_our_service_property_check')}
      </HIDTypography>
      <Stack direction="column" gap={1.5}>
        {benefits.map((benefit) => (
          <Stack direction="row" gap={1.5} key={benefit}>
            <Check />
            <HIDTypography variant="body1">
              {benefit}
            </HIDTypography>
          </Stack>
        ))}
      </Stack>
      <HIDTypography variant="body1">
        {t('property_progress:property_check_packaged_and_ready_in_the_app')}
      </HIDTypography>
    </Stack>
  );

  const dateProvided = Boolean(partnerTaskGroupDetailsInfo?.confirmedInspectionDate);

  const Booked = partnerTaskGroupDetailsInfo
    ? (
      <Stack
        flexDirection="column"
        sx={{ gap: theme.spacing(1.5) }}
      >
        {dateProvided && (
          <>
            <Stack
              alignItems="center"
              flexDirection="row"
              sx={{ gap: theme.spacing(2) }}
            >
              <DateCard date={partnerTaskGroupDetailsInfo.confirmedInspectionDate} />
              <Stack>
                <HIDTypography>
                  {partnerTaskGroupDetailsInfo.texts.dateTitle}
                </HIDTypography>
                <HIDTypography variant="subtitle1">
                  {formatDate(new Date(partnerTaskGroupDetailsInfo.confirmedInspectionDate), DateTimeFormats.DAY_AND_FULL)}
                </HIDTypography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderColor: theme.palette.grey[300],
                my: 2,
              }}
            />
          </>
        )}
        {partnerTaskGroupDetailsInfo.texts.descriptionTitle && (
          <HIDTypography sx={{ marginBottom: theme.spacing(1) }} variant="body1">
            {partnerTaskGroupDetailsInfo.texts.descriptionTitle}
          </HIDTypography>
        )}
        <ReactMarkdown skipHtml components={{ p: 'span' }}>
          {partnerTaskGroupDetailsInfo.texts.description}
        </ReactMarkdown>
      </Stack>
    )
    : null;

  const handleReadMore = () => openMobileAppDialog({
    unavailableFeature: EntityType.TASK,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent>
          <Stack direction="column" gap={2.5}>
            {notBooked ? NotBooked : Booked}
            <HIDButton sx={{ alignSelf: 'flex-end' }} onClick={handleReadMore}>
              {t('common:read_more')}
            </HIDButton>
          </Stack>
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyToDoPropertyCheckSection;
