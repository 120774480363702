import {
  createContext,
  useContext,
} from 'react';

type ContentSliderContextData = {
  totalSlidesNum: number;
  activeSlideIndex: number;
  onChangeSlide: (slideIndex: number) => void;
};

export const ContentSliderContext = createContext<ContentSliderContextData>({} as ContentSliderContextData);

const useContentSliderContext = () => useContext(ContentSliderContext);

export default useContentSliderContext;
