/* eslint-disable @typescript-eslint/ban-types */
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import DialogNames from './DialogNames';
import { HIDDialogProps } from '../../types/common';
import { createGuid } from '../../utils/uuid';
import {
  DialogComponentProps,
  DialogStateType,
  getIsDialogOpen,
  hideDialog,
  showDialog,
} from '../../store/dialogsReducer';

export type UseDialogProps<T extends DialogNames> =
  Omit<DialogComponentProps<T>, keyof HIDDialogProps>;

type OpenDialog<T> = {} extends T
  ? (T extends {}
    ? {} extends T
      ? (props?: T) => void
      : (props: T) => void
    : (props: T) => void)
  : (props: T) => void;

const useDialog = <T extends DialogNames>(name: T, onClose?: (params?: T) => void): [
  OpenDialog<UseDialogProps<T>>,
  () => void,
  boolean,
] => {
  const idRef = useRef(createGuid());
  const id = idRef.current;

  const dispatch = useDispatch();
  const isOpen: boolean = useSelector((state: { dialogs: DialogStateType }) => getIsDialogOpen(id, state));

  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    if (onClose && wasOpen && !isOpen) {
      onClose();
    }

    setWasOpen(isOpen);
  }, [isOpen]);

  return [
    // @ts-ignore
    (props?: UseDialogProps<typeof name>) => {
      dispatch(showDialog({ name, id, props }));
    },
    () => dispatch(hideDialog({ id })),
    isOpen,
  ];
};

export default useDialog;
