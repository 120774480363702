import React, {
  ReactNode,
} from 'react';
import {
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import HIDButton, { HIDButtonProps } from '../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../types/common';
import HIDTypography from '../../../../../../../components/HIDTypography';
import BonusOffersSectionCard from './BonusOffersSectionCard';

type MyTotalBonusPointsSectionProps = {
  title: string;
  isLoading?: boolean;
  numberOfSkeletonLines: number;
  buttonTitle: string;
  sx?: SxProps<Theme>;
  description: string;
  iconBgColor: string;
  IconComponent: ReactNode;
  buttonColor: HIDButtonProps['color'];
  onClick: () => void;
};

const MyTotalBonusPointsSection: FCC<MyTotalBonusPointsSectionProps> = ({
  title,
  isLoading = false,
  buttonTitle,
  description,
  IconComponent,
  iconBgColor,
  buttonColor,
  numberOfSkeletonLines,
  sx,
  onClick,
}) => {
  const theme = useTheme();

  const loadingStateStyle = isLoading
    ? {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
    : {};

  return (
    <BonusOffersSectionCard
      alignContent="center"
      alignItems="center"
      spacing={2}
      sx={sx}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(8),
          borderRadius: theme.spacing(4),
          backgroundColor: iconBgColor,
        }}
      >
        {IconComponent}
      </Stack>
      <HIDTypography
        isLoading={isLoading}
        skeletonWidth="40%"
        sx={{ textAlign: 'center', width: '100%', ...loadingStateStyle }}
        variant="h4"
      >
        {title}
      </HIDTypography>
      <HIDTypography
        isLoading={isLoading}
        numberOfSkeletonLines={numberOfSkeletonLines}
        sx={{
          textAlign: 'center',
          whiteSpace: 'pre-line',
          maxWidth: '300px',
          width: '100%',
          ...loadingStateStyle,
        }}
        variant="body1"
      >
        {description}
      </HIDTypography>
      <HIDButton
        color={buttonColor}
        loading={isLoading}
        onClick={onClick}
      >
        {buttonTitle}
      </HIDButton>
    </BonusOffersSectionCard>
  );
};

export default MyTotalBonusPointsSection;
