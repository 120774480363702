import { Stack } from '@mui/system';
import React from 'react';

import HIDButton, { HIDButtonProps } from '../../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../../components/HIDTypography';
import { FCC } from '../../../../../../../../types/common';

type BuildingEntitiesSectionProps = {
  title: string;
  description: string;
  addText: string;
  buttonSize?: HIDButtonProps['size'];
  emptyStateImage: string;
  onAdd: () => void;
};

const BuildingEntitiesSectionEmptyState: FCC<BuildingEntitiesSectionProps> = ({
  title,
  description,
  addText,
  emptyStateImage,
  onAdd,
}) => (
  <Stack gap={2.5}>
    <Stack gap={1.5} style={{ alignItems: 'center' }}>
      <img
        src={emptyStateImage}
        style={{
          width: 180,
          height: 180,
        }}
      />
      <HIDTypography style={{ textAlign: 'center' }} variant="subtitle1">
        {title}
      </HIDTypography>
      <HIDTypography style={{ textAlign: 'center' }}>
        {description}
      </HIDTypography>
    </Stack>
    <Stack direction="row" justifyContent="flex-end">
      <HIDButton onClick={onAdd}>
        {addText}
      </HIDButton>
    </Stack>
  </Stack>
);

export default BuildingEntitiesSectionEmptyState;
