import React, { FC } from 'react';

import { usePatchPropertyProgressFinanceMutation } from '../../../api/api.propertyProgress';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import PropertyProgressDynamicContentSection from '../../../components/PropertyProgressDynamicContentSection';
import {
  PropertyProgressCategorySectionProps,
  PropertyFinancesPurchaseInfoSectionData,
} from '../../../types.propertyProgress';

const PropertyFinancesPurchaseInfoSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesPurchaseInfoSectionData>> = ({
  section,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const [patchPropertyProgressFinance, { isLoading: isPropertyProgressFinancePatching }] = usePatchPropertyProgressFinanceMutation();

  const handleUpdate = (difference: Record<string, unknown>) => {
    if (propertyId) {
      patchPropertyProgressFinance({ propertyId, data: difference });
    }
  };

  return (
    <PropertyProgressDynamicContentSection
      key={section.id}
      loading={isPropertyProgressFinancePatching}
      section={section}
      onUpdate={handleUpdate}
    />
  );
};

export default PropertyFinancesPurchaseInfoSection;
