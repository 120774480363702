import React, {
  FC,
  useState,
} from 'react';
import {
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useRouteError,
} from 'react-router';
import * as Sentry from '@sentry/react';
import { useEffectOnce } from 'usehooks-ts';

import MobileErrorImage from '../assets/images/error_mobile.png';
import DesktopErrorImage from '../assets/images/error_desktop.png';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';
import HIDButton from './buttons/HIDButton';
import HIDLink from './HIDLink';

const HIDErrorPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['error_screen', 'common']);
  const theme = useTheme();

  const { isDownSm } = useBreakPointsSizes();

  const [expanded, setExpanded] = useState(false);

  const reloadPage = () => navigate(0);

  const error = useRouteError() as Error;

  useEffectOnce(() => {
    Sentry.captureException(error);
  });

  return (
    <Stack
      sx={{
        width: '100%',
        minHeight: '100dvh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isDownSm ? theme.spacing(2.5, 0, 0) : theme.spacing(2, 5, 0, 5),
      }}
    >
      <img
        src={isDownSm ? MobileErrorImage : DesktopErrorImage}
        style={isDownSm ? { width: 131.5, height: 120 } : { width: 147, height: 134 }}
      />
      <Typography sx={{ marginTop: isDownSm ? 3 : 4 }} variant="h4">
        {t('error_screen:error_screen_something_went_wrong')}
      </Typography>
      <Typography sx={{ marginTop: isDownSm ? 2.5 : 2, textAlign: 'center' }}>
        {t('error_screen:error_screen_we_are_working_on_fix')}
      </Typography>
      <HIDButton sx={{ marginTop: 3 }} onClick={reloadPage}>
        {t('error_screen:error_screen_reload')}
      </HIDButton>
      <HIDLink
        label={expanded ? t('common:read_less') : t('common:read_more')}
        sx={{ marginTop: 2 }}
        onClick={() => setExpanded(!expanded)}
      />
      <Collapse in={expanded} sx={{ marginTop: 1 }}>
        <Stack spacing={1}>
          <Typography sx={{ padding: isDownSm ? theme.spacing(0, 1, 0) : 0 }} variant="h5">
            {error?.message}
          </Typography>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              backgroundColor: theme.palette.grey[200],
              padding: 1,
            }}
          >
            {error?.stack}
          </Typography>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default HIDErrorPage;
