import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  getReceiptCategoriesPath,
  getCreateReceiptPath,
} from '../../../../Content/modules/Receipt/navigation.receipt';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';
import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';
import { SellingPropertyHandoverReceiptsSectionData } from '../../../types.sellingProperty';
import SellingPropertyHandoverReceiptsList from './SellingPropertyHandoverReceiptsList';

const SellingPropertyHandoverReceipts: FC<PropertyProgressCategorySectionProps<SellingPropertyHandoverReceiptsSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['receipts', 'property', 'selling_property']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToReceipts = () => navigate(getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }));
  const handleNavigateToAddNewReceipt = () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('receipts:receipts_add_receipt')}
          description={t('selling_property:selling_property_handover_receipts_description')}
          viewAllButtonText={t('property:property_progress_property_documentation_receipts_view_all')}
          onAddClick={handleNavigateToAddNewReceipt}
          onViewAllClick={handleNavigateToReceipts}
        >
          {Boolean(section.data.receipts.length) && (
            <SellingPropertyHandoverReceiptsList propertyReceipts={section.data.receipts} />
          )}
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default SellingPropertyHandoverReceipts;
