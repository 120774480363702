import React, { ReactNode } from 'react';
import {
  ArrowForward,
  Clear,
} from '@mui/icons-material';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import HIDButton from '../../../components/buttons/HIDButton';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import { FCC } from '../../../types/common';

type ColorfulBannerLayoutProps = {
  cardBgColor: string;
  buttonBgHoverColor: string;
  description?: string;
  DescriptionComponent?: ReactNode;
  title: string;
  buttonLabel: string;
  isLoading?: boolean;
  hideButtonIcon?: boolean;
  onClick: () => void;
  onClose?: () => void;
};

export const ColorfulBannerLayout: FCC<ColorfulBannerLayoutProps> = ({
  cardBgColor,
  buttonBgHoverColor,
  description,
  title,
  buttonLabel,
  DescriptionComponent,
  isLoading = false,
  hideButtonIcon = false,
  onClick,
  onClose,
}) => {
  const theme = useTheme();
  const textColor = theme.palette.common.white;

  return (
    <Card sx={{
      padding: 2,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '10px',
      borderColor: theme.palette.grey[300],
      backgroundColor: cardBgColor,
    }}
    >
      <Stack spacing={1.5}>
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <Typography color={textColor} variant="h6">
            {title}
          </Typography>
          {Boolean(onClose) && (
            <HIDIconButton
              Icon={Clear}
              color="alternate"
              disabled={isLoading}
              sx={{
                backgroundColor: 'transparent',
                width: '40px',
                height: '40px',
              }}
              onClick={onClose}
            />
          )}
        </Stack>
        {
          description
            ? (
              <Typography
                color={textColor}
                sx={{ whiteSpace: 'pre-line' }}
                variant="body1"
              >
                {description}
              </Typography>
            )
            : DescriptionComponent
        }
        <HIDButton
          Icon={hideButtonIcon ? undefined : ArrowForward}
          color="blank"
          loading={isLoading}
          sx={{
            '&:hover': {
              backgroundColor: buttonBgHoverColor,
            },
          }}
          onClick={onClick}
        >
          {buttonLabel}
        </HIDButton>
      </Stack>
    </Card>
  );
};
