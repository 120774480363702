import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Forum from '../../../components/icons/Forum';
import HIDButton from '../../../components/buttons/HIDButton';
import { FCC } from '../../../types/common';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';

type ReadMoreAboutExpertsProps = {
  onClick: () => void;
};

const ReadMoreAboutExperts: FCC<ReadMoreAboutExpertsProps> = ({ sx, style, onClick }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forum']);

  const handleClick = () => {
    dispatch(toggleSideDrawerOpen(false));
    onClick();
  };

  return (
    <Stack
      direction="column"
      spacing={2.5}
      style={style}
      sx={sx}
    >
      <Stack direction="row" spacing={1.5}>
        <Forum
          fillColor={theme.palette.primary.main}
          iconColor={theme.palette.primary.main}
        />
        <Typography style={{ lineHeight: '18px' }} variant="subtitle1">
          {t('forum:forum_read_more_about_what_our_experts_answer')}
        </Typography>
      </Stack>
      <HIDButton color="secondary" onClick={handleClick}>
        {t('common:read_more')}
      </HIDButton>
    </Stack>
  );
};

export default ReadMoreAboutExperts;
