import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../../../utils/string';

type BudgetSummaryCardProps = {
  totalPerYear: number;
};

const BudgetSummaryCard: FC<BudgetSummaryCardProps> = ({ totalPerYear }) => {
  const { t } = useTranslation(['finances']);

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="center"
      spacing={1.5}
    >
      <HIDTypography variant="body1">
        {t('finances:budget_total_per_year')}
      </HIDTypography>
      <HIDTypography variant="h5">
        {formatMoney(totalPerYear)}
      </HIDTypography>
      <HIDTypography variant="body1">
        {t('finances:budget_total_average_per_month', { value: formatMoney(totalPerYear / 12) })}
      </HIDTypography>
    </Stack>
  );
};

export default BudgetSummaryCard;
