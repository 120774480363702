import { createApi } from '@reduxjs/toolkit/query/react';

import { HIDApiTags } from '../../../api/HIDApiTags';
import HIDBasePartnerQuery from '../../../api/HIDBasePartnerQuery';

type ActivatePartnerTokenParams = {
  tokenId: string;
};

type PartnerTokenData = {
  customToken: string;
};

export const partnersApi = createApi({
  reducerPath: 'partners',
  baseQuery: HIDBasePartnerQuery(),
  tagTypes: [HIDApiTags.USER],
  endpoints: (builder) => ({
    activatePartnerToken: builder.mutation<PartnerTokenData, ActivatePartnerTokenParams>({
      query: (data: ActivatePartnerTokenParams) => ({
        url: 'partner/token/activate',
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useActivatePartnerTokenMutation,
} = partnersApi;
