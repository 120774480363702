import React, {
  FC,
  useCallback,
  useState,
} from 'react';
import {
  Card,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import { Note } from '../types.note';
import {
  DateTimeFormats,
  formatDate,
  isAfterOrEqual,
  isBeforeOrEqual,
} from '../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../utils/string';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../components/actions/ListEntitiesToolbarActions';
import HIDLink from '../../../../../../../components/HIDLink';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import ListEntitiesActions from '../../../components/actions/ListEntitiesActions';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  getCreateNotePath,
  getNotePath,
} from '../navigation.note';
import {
  useGetAllNotesQuery,
  useDeleteNotesMutation,
} from '../api/api.note';
import FiltersSection, { ContentFilters } from '../../../components/sections/FiltersSection';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../hooks/useSearch';

type UseGetNotesDataGridColumns = (props: {
  isSelectionMode: boolean,
  linkVisible: boolean,
  annotationVisible: boolean
}) => Array<GridColDef>;

export const useGetNotesDataGridColumns: UseGetNotesDataGridColumns = ({
  isSelectionMode,
  linkVisible,
  annotationVisible,
}) => {
  const { t } = useTranslation(['common', 'notes']);
  const { isDownSm } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.25,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params?.row as Note;
        return (
          <Typography noWrap variant="body2">
            {name || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownSm && annotationVisible && {
      field: 'annotation',
      headerName: t('notes:notes_annotation'),
      flex: 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { annotation } = params?.row as Note;
        return (
          <Typography noWrap variant="body2">
            {annotation || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownSm && linkVisible && {
      field: 'link',
      headerName: t('notes:notes_link'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { url } = params?.row as Note;
        return url
          ? <HIDLink href={url} label={url} target="_blank" />
          : (
            <Typography noWrap variant="body2">
              {EMPTY_VALUE}
            </Typography>
          );
      },
    },
    {
      field: 'date',
      headerName: t('notes:notes_last_updated_at'),
      flex: isDownSm ? 0.4 : 0.2,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Note>) => params.row.updatedAt,
      renderCell: (params: GridRenderCellParams<Note, string>) => (
        <Typography noWrap variant="body2">
          {params.value ? formatDate(new Date(params.value), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
        </Typography>
      ),
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const Notes: FC = () => {
  const { t } = useTranslation(['common', 'notes']);

  const { displaySearch } = useSearch(EntityType.NOTE);

  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedNoteIds, setSelectedNoteIds] = useState<Array<string>>([]);

  const isSelectionMode = Boolean(selectionModeType);

  const { data: notes = [], isLoading } = useGetAllNotesQuery(propertyId ? { propertyId } : skipToken);
  const [deleteNotes, { isLoading: isDeleting }] = useDeleteNotesMutation();

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    columnsVisibilityMap: {
      annotation: { label: t('notes:notes_annotation'), visible: true },
      link: { label: t('notes:notes_link'), visible: true },
    },
  });

  const filteredNotes = filters.from || filters.to
    ? notes.filter(
      (note) => (filters.from ? isAfterOrEqual(new Date(note.updatedAt), new Date(filters.from)) : true)
        && (filters.to ? isBeforeOrEqual(new Date(note.updatedAt), new Date(filters.to)) : true),
    )
    : notes;

  const annotationVisible = Boolean(filters.columnsVisibilityMap?.annotation?.visible);
  const linkVisible = Boolean(filters.columnsVisibilityMap?.link?.visible);

  const columns = useGetNotesDataGridColumns({
    isSelectionMode,
    linkVisible,
    annotationVisible,
  });

  const handleRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => setSelectedNoteIds(rowSelectionModel as Array<string>),
    [],
  );

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId }));

  const handleEnterDeleteMode = () => {
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedNoteIds([]);
  };

  const handleDelete = () => {
    if (propertyId) {
      deleteNotes({ propertyId, ids: selectedNoteIds });
    }

    setSelectionModeType(undefined);
    setSelectedNoteIds([]);
  };

  const handleNavigateToNote: GridEventListener<'rowClick'> = (params) => {
    const note = params?.row as Note;
    navigate(getPathWithPropertyIdOrInit(getNotePath, { propertyId, id: note.id }));
  };

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={filteredNotes.length}
          entity={EntityType.NOTE}
          isFetching={isDeleting}
          isLoading={isLoading}
          selectedCount={selectedNoteIds.length}
          selectionModeType={selectionModeType}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={handleDelete}
        />
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              disabled={isSelectionMode}
              onAdd={handleAdd}
              onDelete={handleEnterDeleteMode}
              onSearch={displaySearch}
            />
          </Card>
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <FiltersSection
                showColumnsVisibilityFilters
                showDateFilers
                filters={filters}
                onChange={setFilters}
              />
            </Card>
          )}
        </>
      }
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'date',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      isSelectionMode={isSelectionMode}
      rows={filteredNotes}
      sideDrawerElements={[
        <ListEntitiesActions
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={handleEnterDeleteMode}
          onSearch={displaySearch}
        />,
        !isSelectionMode && (
          <FiltersSection
            showColumnsVisibilityFilters
            showDateFilers
            filters={filters}
            key={FiltersSection.name}
            onChange={setFilters}
          />
        ),
      ].filter(Boolean)}
      title={t('notes:notes_tile')}
      onRowClick={handleNavigateToNote}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default Notes;
