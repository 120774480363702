import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';

import HIDSelectItem from './HIDSelectItem';
import { FCC } from '../types/common';

export type SelectItem = {
  id: string;
  label: string;
  Icon?: React.ElementType;
  count?: number;
  CountComponent?: ReactNode;
  isLoading?: boolean;
};

type HIDInlineSelectProps = {
  items: Array<SelectItem>;
  value?: string;
  onChange: (id: string) => void;
};

const HIDInlineSelect: FCC<HIDInlineSelectProps> = ({
  items,
  value,
  sx,
  style,
  onChange,
}) => (
  <Stack style={style} sx={sx}>
    {items
      .map(
        (
          {
            id,
            label,
            Icon,
            count,
            CountComponent,
            isLoading,
          }: SelectItem,
          index: number,
        ) => (
          <HIDSelectItem
            Icon={Icon}
            RightIconComponent={CountComponent}
            count={count}
            isLoading={isLoading}
            isSelected={id === value}
            key={id}
            label={label}
            sx={{ marginBottom: index !== items.length - 1 ? 0.5 : 0 }}
            onClick={() => onChange(id)}
          />
        ),
      )}
  </Stack>
);

export default HIDInlineSelect;
