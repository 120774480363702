import React, {
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Tab,
  useTheme,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { skipToken } from '@reduxjs/toolkit/query';
import { useUpdateEffect } from 'usehooks-ts';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { useGetEntityConnectionsQuery } from '../api/api.content';
import getEntityPossiblyConnectionTypes from '../utils/entityPossiblyConnectionTypes';
import {
  HIDEntityId,
  FCC,
} from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import ContentConnectionsTab from './ContentConnectionsTab';
import { useGetEntityTypeNames } from '../../../../../constants/entityTypeName';

type ContentConnectionsTabItem = {
  id: string;
  label: string;
  TabComponent: React.ReactNode;
};

type ContentConnectionsTabContextProps = {
  sourceEntity: Required<HIDEntityId>;
  sourceEntityType: EntityType;
  tabs: Array<ContentConnectionsTabItem>;
};

const ContentConnectionsTabContext: FCC<ContentConnectionsTabContextProps> = ({
  sourceEntity,
  sourceEntityType,
  tabs,
}) => {
  const theme = useTheme();

  const { data: propertyId } = useGetCurrentPropertyId();

  const possibleConnections = getEntityPossiblyConnectionTypes(sourceEntityType);

  const entityId = sourceEntity.id;

  const { data: connectionsMap, isLoading: connectionsLoading } = useGetEntityConnectionsQuery(
    propertyId && entityId
      ? {
        propertyId,
        entityId,
        entityType: sourceEntityType,
      }
      : skipToken,
  );
  // eslint-disable-next-line deprecation/deprecation
  const EntityNames = useGetEntityTypeNames();

  const tabList = useMemo(() => {
    const connectionTabs = possibleConnections
      .filter((connectionType) => connectionsMap && connectionsMap[connectionType]?.length)
      .map((connectionEntityType) => ({
        id: connectionEntityType,
        label: EntityNames[connectionEntityType] || connectionEntityType,
        TabComponent: (
          <ContentConnectionsTab
            connectionEntityType={connectionEntityType}
            connections={connectionsMap ? connectionsMap[connectionEntityType] || [] : []}
            isLoading={connectionsLoading}
            sourceEntity={sourceEntity}
            sourceEntityType={sourceEntityType}
          />
        ),
      }));

    return tabs.concat(connectionTabs);
  }, [tabs, possibleConnections, connectionsMap]);

  const [activeTab, setActiveTab] = useState<string>(tabList[0].id);
  const handleChange = (_event: React.SyntheticEvent, newTab: string) => setActiveTab(newTab);

  useUpdateEffect(() => {
    if (tabList.length) {
      setActiveTab(tabList[0].id);
    }
  }, [tabList.length]);

  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.grey[300] }}>
        <TabList
          allowScrollButtonsMobile
          slots={
            {
              EndScrollButtonIcon: ArrowForwardIcon,
              StartScrollButtonIcon: ArrowBackIcon,
            }
          }
          variant="scrollable"
          onChange={handleChange}
        >
          {tabList.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              value={tab.id}
            />
          ))}
        </TabList>
      </Box>
      {tabList.map((tab) => (
        <TabPanel key={tab.id} sx={{ padding: theme.spacing(3, 0, 0) }} value={tab.id}>
          {tab.TabComponent}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default ContentConnectionsTabContext;
