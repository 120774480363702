import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Receipt } from '@house-id/houseid-types/dist/content/receipt';
import { useTranslation } from 'react-i18next';

import { formatMoney } from '../../../../../../../../../utils/string';

type ReceiptSummarySectionProps = {
  receipt: Receipt;
};

const ReceiptSummarySection: FC<ReceiptSummarySectionProps> = ({
  receipt,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts']);

  return (
    <Stack
      sx={{
        padding: 2.5,
        backgroundColor: theme.palette.grey[100],
        borderRadius: '10px',
      }}
    >
      <Typography variant="subtitle1">
        {t('receipts:receipts_view_receipt_summary')}
      </Typography>
      <Typography sx={{ marginTop: 1.5 }} variant="subtitle1">
        {t('receipts:receipts_view_receipt_basic_improvement')}
      </Typography>
      <Typography sx={{ marginTop: 0.5 }} variant="body1">
        {formatMoney(receipt?.basicImprovementValue)}
      </Typography>
      <Typography sx={{ marginTop: 1.5 }} variant="subtitle1">
        {t('receipts:receipts_view_receipt_improvement_deductions')}
      </Typography>
      <Typography sx={{ marginTop: 0.5 }} variant="body1">
        {formatMoney(receipt?.repairImprovementValue)}
      </Typography>
      <Typography sx={{ marginTop: 1.5 }} variant="subtitle1">
        {t('receipts:receipts_price_label')}
      </Typography>
      <Typography sx={{ marginTop: 0.5 }} variant="body1">
        {formatMoney(receipt?.amount)}
      </Typography>
    </Stack>
  );
};

export default ReceiptSummarySection;
