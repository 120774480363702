import React, {
  useEffect,
  useState,
} from 'react';
import {
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { useFormikContext } from 'formik';
import {
  BudgetTemplateCategoryId,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import { FCC } from '../../../../../../../../../types/common';
import { ErrorFieldInfo } from '../hooks/useGetFormikErrorFieldPath';
import { BUDGET_COLLAPSIBLE_SECTION_ANIMATION_TIMEOUT } from '../constants/animationTimeout';

type BudgetCategoryCollapsibleSectionProps = {
  sum: string;
  title: string;
  categoryId: BudgetTemplateCategoryId;
};

const BudgetCategoryCollapsibleSection: FCC<BudgetCategoryCollapsibleSectionProps> = ({
  sum,
  title,
  children,
  categoryId,
  sx,
}) => {
  const theme = useTheme();
  const [isExpanded, setExpanded] = useState(true);

  const formikContext = useFormikContext<CreateUpdateBudgetValues>();
  const errorFieldInfo = formikContext.status as ErrorFieldInfo;

  useEffect(() => {
    if (errorFieldInfo && errorFieldInfo.categoryId === categoryId) {
      setExpanded(true);
    }
  }, [errorFieldInfo, categoryId]);

  const handleToggleExpandedState = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Stack
      spacing={1.5}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '8px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        width: '100%',
        ...sx,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          padding: 2,
          borderTopRightRadius: 'inherit',
          borderTopLeftRadius: 'inherit',
          borderBottomRightRadius: isExpanded ? 'unset' : 'inherit',
          borderBottomLeftRadius: isExpanded ? 'unset' : 'inherit',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.grey[50],
          },
        }}
        onClick={handleToggleExpandedState}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={0.5}
          sx={{
            flex: 1,
            flexWrap: 'wrap',
          }}
        >
          <Typography sx={{ flex: 1 }} variant="subtitle1">
            {title}
          </Typography>
          <Typography>{sum}</Typography>
        </Stack>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </Stack>
      <Collapse
        in={isExpanded}
        style={{ marginTop: 0 }}
        sx={{ padding: theme.spacing(0, 2, isExpanded ? 2 : 0, 2) }}
        timeout={BUDGET_COLLAPSIBLE_SECTION_ANIMATION_TIMEOUT}
      >
        {children}
      </Collapse>
    </Stack>
  );
};

export default BudgetCategoryCollapsibleSection;
