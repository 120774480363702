import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const PieChart: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="M22 12C22 10.6868 21.7413 9.38638 21.2388 8.17317C20.7362 6.95991 19.9996 5.85752 19.071 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M21.2104 15.8899C20.5743 17.3944 19.5792 18.7201 18.3123 19.7512C17.0454 20.7823 15.5452 21.4873 13.9429 21.8047C12.3406 22.1221 10.6849 22.0421 9.12055 21.5717C7.55627 21.1014 6.13103 20.255 4.96943 19.1066C3.80782 17.9582 2.94522 16.5427 2.45704 14.9839C1.96886 13.4251 1.86997 11.7704 2.16901 10.1646C2.46804 8.55874 3.1559 7.05059 4.17245 5.77199C5.189 4.49339 6.50329 3.48328 8.00041 2.82996M22.0005 12C22.0005 10.6868 21.7418 9.38638 21.2393 8.17317C20.7367 6.95991 20.0001 5.85752 19.0715 4.92893C18.143 4.00035 17.0406 3.26375 15.8273 2.7612C14.6141 2.25866 13.3137 2 12.0005 2V12H22.0005Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default PieChart;
