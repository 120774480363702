import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';
import { ALL_RECEIPTS_CATEGORY } from './constants.receipt';

export const getReceiptCategoriesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.RECEIPTS_CATEGORIES,
  { propertyId },
);

type GetReceiptCategoryPath = PropertyIdNavigation & { categoryId?: string };
export const getReceiptCategoryPath = ({ propertyId, categoryId }: GetReceiptCategoryPath) => generateRoutePath(
  RouteNames.RECEIPTS,
  {
    propertyId,
    category: encodeURIComponent(categoryId || ALL_RECEIPTS_CATEGORY),
  },
);

type GetReceiptPath = PropertyIdNavigation & {
  categoryId?: string,
  id: string
};

export const getReceiptPath = (
  { propertyId, categoryId, id }: GetReceiptPath,
) => generateRoutePath(
  RouteNames.RECEIPT,
  {
    propertyId,
    category: encodeURIComponent(categoryId || ALL_RECEIPTS_CATEGORY),
    id,
  },
);

type GetCreateReceiptPath = PropertyIdNavigation & {
  categoryId?: string,
  contentFileIds?: Array<string>
};

export const getCreateReceiptPath = ({ propertyId, categoryId, contentFileIds }: GetCreateReceiptPath) => generateRoutePath(
  RouteNames.RECEIPT_CREATE,
  {
    propertyId,
    category: categoryId || ALL_RECEIPTS_CATEGORY,
  },
  contentFileIds ? { contentFileIds } : undefined,
);

type GetUpdateReceiptPath = PropertyIdNavigation & {
  id: string,
  categoryId?: string,
  suggestionKey?: string,
  forceScanReceipt?: boolean,
};

export const getUpdateReceiptPath = ({
  propertyId,
  id,
  categoryId,
  suggestionKey,
  forceScanReceipt,
}: GetUpdateReceiptPath) => generateRoutePath(
  RouteNames.RECEIPT_UPDATE,
  {
    propertyId,
    id,
    category: categoryId || ALL_RECEIPTS_CATEGORY,
  },
  {
    suggestionKey,
    forceScanReceipt,
  },
);
