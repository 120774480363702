import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Bank: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M5 9V17M9.5 9V17M14.5 9V17M19 9V17M3 18.6L3 19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.04 21 17.7599 20.891 17.546C20.7951 17.3579 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3579 3.10899 17.546C3 17.7599 3 18.04 3 18.6ZM11.6529 3.07714L4.25291 4.72158C3.80585 4.82093 3.58232 4.8706 3.41546 4.99081C3.26829 5.09684 3.15273 5.2409 3.08115 5.40757C3 5.59653 3 5.82551 3 6.28348L3 7.4C3 7.96006 3 8.24008 3.10899 8.45399C3.20487 8.64216 3.35785 8.79514 3.54601 8.89101C3.75992 9 4.03995 9 4.6 9H19.4C19.9601 9 20.2401 9 20.454 8.89101C20.6422 8.79514 20.7951 8.64216 20.891 8.454C21 8.24008 21 7.96006 21 7.4V6.28348C21 5.82551 21 5.59653 20.9188 5.40757C20.8473 5.2409 20.7317 5.09684 20.5845 4.99081C20.4177 4.8706 20.1942 4.82093 19.7471 4.72158L12.3471 3.07714C12.2176 3.04835 12.1528 3.03396 12.0874 3.02822C12.0292 3.02312 11.9708 3.02312 11.9126 3.02822C11.8472 3.03396 11.7824 3.04835 11.6529 3.07714Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Bank;
