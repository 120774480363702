import React, { FC } from 'react';
import {
  Tooltip,
  TooltipProps,
  useTheme,
} from '@mui/material';

type HIDTooltipProps = {
  title?: string;
  titlePlacement?: TooltipProps['placement'];
  children: TooltipProps['children'];
};

const HIDTooltip: FC<HIDTooltipProps> = ({
  title,
  titlePlacement,
  children,
}) => {
  const theme = useTheme();

  return title
    ? (
      <Tooltip
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -4],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.grey[900],
            },
          },
          arrow: {
            sx: {
              color: theme.palette.grey[900],
            },
          },
        }}
        enterDelay={500}
        placement={titlePlacement}
        title={title}
      >
        {children}
      </Tooltip>
    )
    : children;
};

export default HIDTooltip;
