import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategorySection,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressFinanceQuery } from '../../api/api.propertyProgress';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import {
  PropertyProgressCategorySectionProps,
  PropertyFinancesSectionData,
  PropertyFinanceSectionSubSection,
} from '../../types.propertyProgress';
import PropertyFinancesEasementSection from './components/PropertyFinancesEasementSection';
import PropertyFinancesFixedCostSection from './components/PropertyFinancesFixedCostSection';
import PropertyFinancesLoanInterestRateSection from './components/PropertyFinancesLoanInterestRateSection';
import PropertyFinancesLoansSection from './components/PropertyFinancesLoansSection';
import PropertyFinancesMortgageSection from './components/PropertyFinancesMortgageSection';
import PropertyFinancesOperatingCostSection from './components/PropertyFinancesOperatingCostSection';
import PropertyFinancesPurchaseInfoSection from './components/PropertyFinancesPurchaseInfoSection';
import PropertyFinancesTaxationSection from './components/PropertyFinancesTaxationSection';
import PropertyFinancesValuationSection from './components/PropertyFinancesValuationSection';
import PropertyExternalService from '../../../../constants/constants.externalServices';
import { BoughtSfPropertyYearAgoBanner } from '../../components/sf/BoughtSfPropertyYearAgoBanner';
import PropertyExternalServicesSection from '../../../../components/PropertyExternalServicesSection';
import useGetPropertyProgressCategoryContentText from '../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import PropertyFinancesPropertySoldSection from './components/PropertyFinancesPropertySoldSection';

const PropertyFinancesSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyFinanceSectionSubSection.FINANCE_LOANS]: PropertyFinancesLoansSection,
  [PropertyFinanceSectionSubSection.FINANCE_LOANS_INTEREST_RATE]: PropertyFinancesLoanInterestRateSection,
  [PropertyFinanceSectionSubSection.FINANCE_RECURRING_EXPENSES]: PropertyFinancesFixedCostSection,
  [PropertyFinanceSectionSubSection.FINANCE_BUDGET]: PropertyFinancesOperatingCostSection,
  [PropertyFinanceSectionSubSection.FINANCE_VALUATION]: PropertyFinancesValuationSection,
  [PropertyFinanceSectionSubSection.PURCHASE_INFO]: PropertyFinancesPurchaseInfoSection,
  [PropertyFinanceSectionSubSection.TAXATION]: PropertyFinancesTaxationSection,
  [PropertyFinanceSectionSubSection.EASEMENT]: PropertyFinancesEasementSection,
  [PropertyFinanceSectionSubSection.MORTGAGE]: PropertyFinancesMortgageSection,
  [PropertyFinanceSectionSubSection.PROPERTY_SOLD]: PropertyFinancesPropertySoldSection,
};

const PropertyFinance: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyFinance,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressFinanceQuery(propertyId ? { propertyId } : skipToken);

  const sections = propertyFinance?.sections || [];

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyFinancesSectionData>) => {
    const Content = PropertyFinancesSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(propertyFinance?.progress),
    categoryId: PropertyProgressCategorySection.FINANCE,
  });

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategorySection.FINANCE}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
          <BoughtSfPropertyYearAgoBanner key={BoughtSfPropertyYearAgoBanner.name} />
          <PropertyExternalServicesSection
            externalServicesIds={[PropertyExternalService.UC_PROPERTY_INFORMATION_DATA, PropertyExternalService.UC_TAXATION_DATA]}
            key={PropertyExternalServicesSection.name}
          />
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategorySection.FINANCE}
          key={PropertyProgressQuickNavigation.name}
        />,
        <BoughtSfPropertyYearAgoBanner key={BoughtSfPropertyYearAgoBanner.name} />,
        <PropertyExternalServicesSection
          externalServicesIds={[PropertyExternalService.UC_PROPERTY_INFORMATION_DATA, PropertyExternalService.UC_TAXATION_DATA]}
          key={PropertyExternalServicesSection.name}
        />,
      ]}
      title={t('property:property_progress_property_finances_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
    >
      <PropertyProgressPageContent
        description={description}
        loading={isPropertySpecificationLoading}
        progress={propertyFinance?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}
      />
    </HomeLayout>
  );
};

export default PropertyFinance;
