import React from 'react';
import {
  useTheme,
  Stack,
  Fade,
} from '@mui/material';
import HIDLogoIcon from './icons/HIDLogoIcon';
import { HIDZIndex } from '../constants/layout';

const HIDFullScreenLoader = () => {
  const theme = useTheme();

  return (
    <Fade in timeout={300}>
      <Stack
        alignItems="center"
        height="100vh"
        justifyContent="center"
        sx={{
          zIndex: HIDZIndex.LOADER,
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: theme.palette.common.white,
        }}
        width="100vw"
      >
        <HIDLogoIcon
          className="pulse"
          iconColor={theme.palette.primary.main}
          size={64}
        />
      </Stack>
    </Fade>
  );
};

export default HIDFullScreenLoader;
