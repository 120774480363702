import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { useNavigateBackOr } from '../../../../../../../utils/routes';
import { getSuggestionCategoriesPath } from '../navigation.suggestion';
import {
  useGetSuggestionsSettingsQuery,
  useUpdateSuggestionsSettingsMutation,
} from '../api/api.suggestion';
import {
  EntityTypeSuggestions,
  SuggestionType,
  SuggestionsSettingsConfiguration,
} from '../types.suggestion';
import useGetSuggestionMetadata from '../hooks/useGetSuggestionMetadata';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { useGetEntityTypeNames } from '../../../../../../../constants/entityTypeName';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const getCategoryCheckedState = (suggestions: Record<string, { muted?: boolean }>) =>
  Object.values(suggestions).some(({ muted }) => muted === false);

const getCategoriesCheckedMap = (configurations: SuggestionsSettingsConfiguration) => {
  const entries = Object.entries(configurations);

  return Object.fromEntries(
    entries.map(([key, suggestions]) => [key, getCategoryCheckedState(suggestions)]),
  );
};

const Divider = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: '100%',
        borderBottomStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
      }}
    />
  );
};

const SuggestionsSettings: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'suggestion']);
  const { isDownXl } = useBreakPointsSizes();

  // eslint-disable-next-line deprecation/deprecation
  const EntityTypeNames = useGetEntityTypeNames();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: suggestionSettings, isLoading } = useGetSuggestionsSettingsQuery(propertyId ? { propertyId } : skipToken);

  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);
  const [configuration, setConfiguration] = useState<SuggestionsSettingsConfiguration>();
  const [categoriesCheckedMap, setCategoriesCheckedMap] = useState<Record<string, boolean | undefined>>();

  const { findSuggestionMetadata } = useGetSuggestionMetadata();
  const [updateSuggestionSettings, { isLoading: isSaving }] = useUpdateSuggestionsSettingsMutation();

  useEffect(() => {
    if (suggestionSettings?.configuration) {
      setConfiguration(suggestionSettings.configuration);
      setSuggestionsEnabled(suggestionSettings.suggestionsEnabled);
      setCategoriesCheckedMap(getCategoriesCheckedMap(suggestionSettings.configuration));
    }
  }, [suggestionSettings?.configuration]);

  const handleChangeCategory = (entityType: EntityType) => {
    const checked = !categoriesCheckedMap?.[entityType];

    setCategoriesCheckedMap({
      ...categoriesCheckedMap,
      [entityType]: checked,
    });

    if (configuration && configuration[entityType]) {
      setConfiguration({
        ...configuration,
        [entityType]: Object.fromEntries(
          Object.keys(configuration[entityType])
            .map((key) => [key, { muted: !checked }]),
        ),
      });
    }
  };

  const handleChange = (entityType: EntityType, suggestionType: SuggestionType) => {
    const newConfiguration = {
      ...configuration,
      [entityType]: {
        ...configuration?.[entityType],
        [suggestionType]: {
          muted: !configuration?.[entityType]?.[suggestionType]?.muted,
        },
      },
    } as SuggestionsSettingsConfiguration;

    setConfiguration(newConfiguration);
    const categoryState = getCategoryCheckedState(newConfiguration[entityType]);

    if (categoryState !== categoriesCheckedMap?.[entityType]) {
      setCategoriesCheckedMap({
        ...categoriesCheckedMap,
        [entityType]: categoryState,
      });
    }
  };

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }));

  const handleSave = () => {
    if (propertyId && configuration) {
      updateSuggestionSettings({ propertyId, settings: { suggestionsEnabled, configuration } })
        .then(handleGoBack);
    }
  };

  const getSuggestionTypes = (entityType: EntityType | string, suggestions: EntityTypeSuggestions) =>
    Object.entries(suggestions)
      .map(([suggestionType, { muted }]) => {
        const metadata = findSuggestionMetadata(entityType, suggestionType);
        return metadata ? { ...metadata, muted } : undefined;
      })
      .filter(Boolean);

  return (
    <HomeLayout
      SideColumn={isDownXl ? undefined : <div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }),
          name: t('suggestion:smart_suggestions'),
        },
      ]}
      title={t('suggestion:smart_suggestions_settings')}
      onBack={handleGoBack}
    >
      <Typography sx={{ marginBottom: 3 }}>
        {t('suggestion:smart_suggestions_settings_description')}
      </Typography>
      {!isLoading && (
        <Stack sx={{ marginBottom: 2 }}>
          <FormControlLabel
            control={<Switch checked={suggestionsEnabled} />}
            label={
              <Typography variant="subtitle1">
                {t('suggestion:smart_suggestions_enable')}
              </Typography>
            }
            labelPlacement="start"
            sx={{ justifyContent: 'space-between', marginLeft: 0 }}
            onChange={(_event, checked) => setSuggestionsEnabled(checked)}
          />
          <Divider />
        </Stack>
      )}
      {suggestionsEnabled && configuration && !R.isEmpty(configuration) && (
        <Stack spacing={2}>
          {Object.entries(configuration)
            .map(([entityType, suggestions]) => (
              <Stack key={entityType} spacing={0.5}>
                <Stack>
                  <FormControlLabel
                    control={<Switch checked={categoriesCheckedMap?.[entityType] !== false} />}
                    disabled={!suggestionsEnabled}
                    label={
                      <Typography variant="subtitle1">
                        {EntityTypeNames[entityType as EntityType]}
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                    onChange={() => handleChangeCategory(entityType as EntityType)}
                  />
                  {getSuggestionTypes(entityType, suggestions)
                    .map(({ type, id, muted }) => (
                      <FormControlLabel
                        control={<Switch checked={!muted} />}
                        disabled={!suggestionsEnabled}
                        key={id}
                        label={type}
                        labelPlacement="start"
                        sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                        onChange={() => handleChange(entityType as EntityType, id as SuggestionType)}
                      />
                    ))}
                </Stack>
                <Divider />
              </Stack>
            ))}
        </Stack>
      )}
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ marginTop: 3 }}
      >
        <HIDButton
          color="secondary"
          disabled={isLoading || isSaving}
          onClick={handleGoBack}
        >
          {t('common:cancel')}
        </HIDButton>
        <HIDButton
          disabled={isLoading}
          loading={isSaving}
          onClick={handleSave}
        >
          {t('common:save')}
        </HIDButton>
      </Stack>
    </HomeLayout>
  );
};

export default SuggestionsSettings;
