import {
  useSignOut,
} from 'react-firebase-hooks/auth';
import { Auth } from 'firebase/auth';
import { useState } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router';

import { useGetClearCache } from '../../../store/store';
import { clearExternalServicesUserData } from '../../../external-services';
import { getLoginPath } from '../navigation/navigation.auth';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import { getPartnersLoginPath } from '../../Partners/navigation/navigation.partners';
import RouteNames from '../../../routes/RouteNames';

type UseGetSignOut = (auth: Auth) => [
  () => Promise<void>,
  boolean,
  Error | undefined,
];

export const useGetSignOut: UseGetSignOut = (auth: Auth) => {
  const navigate = useNavigate();
  const [signOut] = useSignOut(auth);
  const clearCache = useGetClearCache();

  const location = useLocation();
  const isPartnersRoute = location.pathname.includes(RouteNames.PARTNERS);

  const { isPartnerAuth } = useGetActiveAuthUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const signOutWithClearCache = () => {
    setLoading(true);
    setError(undefined);
    return signOut()
      .then(() => clearCache())
      .then(() => clearExternalServicesUserData())
      .catch((e: Error) => setError(e))
      .finally(() => {
        setLoading(false);
        navigate(
          isPartnerAuth || isPartnersRoute ? getPartnersLoginPath() : getLoginPath(),
          { state: { skipOrigin: true } },
        );
      });
  };

  return [signOutWithClearCache, loading, error];
};
