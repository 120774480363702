import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import { BuildingEntity } from '../types.building';
import { propertyApi } from '../../../../../api/api.property';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type GetBuildsParams = PropertyId & { buildingType: EntityType };

export const buildingApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getBuildings: builder.query<Array<BuildingEntity>, GetBuildsParams>({
      query: ({ propertyId, buildingType }) => `/properties/${propertyId}/buildings/${buildingType}`,
      providesTags: (result) => provideArrayTags(HIDApiTags.BUILDING, result),
      transformResponse: (result: Record<string, Array<BuildingEntity>>, _, arg) => result[arg.buildingType] || [],
    }),
  }),
});

export const {
  useGetBuildingsQuery,
  useLazyGetBuildingsQuery,
} = buildingApi;
