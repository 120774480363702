import React, { FC } from 'react';
import { useTheme } from '@mui/material';

const MessageReadDot: FC<{ isRead: boolean }> = ({
  isRead,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: isRead
          ? theme.palette.common.transparent
          : theme.palette.primary.main,
      }}
    />
  );
};

export default MessageReadDot;
