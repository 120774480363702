import React, { FC } from 'react';
import { useTheme } from '@mui/material';

import HIDAvatar from '../../../components/HIDAvatar';
import {
  ForumAuthor,
  ForumAuthorType,
  ForumUserAuthor,
} from '../types.forum';
import { Anonymous } from '../../../components/icons/Icons';
import { useGetForumAuthorName } from '../utils/forumAuthor';
import { hidSpacing } from '../../../utils/number';

type ForumAuthorAvatarProps = {
  author?: ForumAuthor;
  showLabel?: boolean;
  isLoading?: boolean;
};

const ForumAuthorAvatar: FC<ForumAuthorAvatarProps> = ({
  author,
  showLabel = false,
  isLoading,
}) => {
  const theme = useTheme();
  const getForumAuthorName = useGetForumAuthorName();
  const { givenName, surname } = getForumAuthorName(author);

  return author?.type === ForumAuthorType.ANONYMOUS
    ? (
      <HIDAvatar
        Icon={() => <Anonymous iconColor={theme.palette.primary.main} size={hidSpacing(4)} />}
        avatarSx={{ backgroundColor: theme.palette.primary.lighter }}
        givenName={givenName}
        isLoading={isLoading}
        showLabel={showLabel}
        size="big"
        surname={surname}
      />
    )
    : (
      <HIDAvatar
        avatarSx={{
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: author?.type === ForumAuthorType.EXPERT
            ? theme.palette.warning.main
            : theme.palette.common.transparent,
        }}
        externalImage={(author as ForumUserAuthor)?.avatar}
        givenName={givenName}
        isLoading={isLoading}
        showLabel={showLabel}
        size="big"
        surname={surname}
      />
    );
};

export default ForumAuthorAvatar;
