import {
  Stack,
  styled,
} from '@mui/material';

const StyledChartWrapper = styled(Stack)(({ theme, sx }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2.5),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: '5px',
    ...sx,
  },
}));

export default StyledChartWrapper;
