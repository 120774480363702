import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import HIDClickable from '../../../../components/HIDClickable';
import HIDTag from '../../../../components/HIDTag';
import { SettingsItem } from '../../types/types.settings';

type UserSettingsItemProps = {
  item: SettingsItem;
  last?: boolean,
};
const UserSettingsItem: FC<UserSettingsItemProps> = ({
  item,
  last = false,
}) => {
  const theme = useTheme();

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={1.5}
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
        padding: theme.spacing(0, 0, 0, 2),
      }}
      // eslint-disable-next-line react/jsx-handler-names
      onClick={item.onClick}
    >
      <item.Icon />
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1.5}
        sx={{
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: last ? 0 : 1,
          borderColor: theme.palette.grey[300],
          padding: theme.spacing(3, 2, 3, 0),
          width: '100%',
        }}
      >
        <Typography>
          {item.label}
        </Typography>
        {item.highlight && (
          <HIDTag
            color={item.highlightColor || theme.palette.warning.main}
            label={item.highlight}
            style={{ marginLeft: 'auto' }}
          />
        )}
        <ChevronRight />
      </Stack>
    </HIDClickable>
  );
};
export default UserSettingsItem;
