import React, { FC } from 'react';
import {
  Card,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  differenceInDays,
  isBefore,
} from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import useScrollWithShadow from '../../../../../hooks/useScrollWithShadow';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import { getTypographyHeight } from '../../../../../utils/style';
import CheckMarkRect from '../../../../../components/icons/CheckMarkRect';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import HIDLink from '../../../../../components/HIDLink';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { hidSpacing } from '../../../../../utils/number';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetTasksQuery } from '../../../modules/Content/modules/GoodToDoTask/api/api.task';
import { Task } from '../../../modules/Content/modules/GoodToDoTask/types.task';

export enum TaskExpirationTypes {
  DATE = 'date',
  TIME = 'time',
}

const ExpirationTypeMap: Record<TaskExpirationTypes, DateTimeFormats> = {
  [TaskExpirationTypes.DATE]: DateTimeFormats.DATE_ONLY_TEXT,
  [TaskExpirationTypes.TIME]: DateTimeFormats.DATE_AND_TIME,
};

const getExpirationString = (expiration: { type: TaskExpirationTypes, date: string } | null) => {
  if (!expiration) {
    return undefined;
  }

  const format = ExpirationTypeMap[expiration.type];
  return formatDate(new Date(expiration.date), format);
};

const SHOW_TASKS_COUNT = 4;

const TasksLoadingState = () => {
  const theme = useTheme();

  const lines = 3;
  const itemSpacing = 0.25;
  const sizeReduce = hidSpacing(((lines - 1) * itemSpacing) / lines);

  return (
    <Stack>
      {R.range(0, SHOW_TASKS_COUNT).map((item) => (
        <Stack key={item} spacing={0.25} sx={{ padding: theme.spacing(0.5, 1) }}>
          <Skeleton height={getTypographyHeight(theme.typography.subtitle1) - sizeReduce} variant="rounded" width="100%" />
          <Skeleton height={getTypographyHeight(theme.typography.body2) - sizeReduce} variant="rounded" width="100%" />
          <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        </Stack>
      ))}
    </Stack>
  );
};

const TasksEmptyState = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'common']);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleAdd = () => openMobileAppDialog({
    unavailableFeature: EntityType.TASK,
  });

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2.5}
      sx={{
        padding: 3,
        height: '100%',
      }}
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.primary.lightest,
          padding: 3,
          borderRadius: 10,
        }}
      >
        <CheckMarkRect iconColor={theme.palette.primary.main} size={36} />
      </Stack>
      <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
        {t('home:home_no_tasks')}
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant="body1">
        {t('home:home_no_tasks_description')}
      </Typography>
      <HIDLink
        label={t('common:add_label')}
        style={{ marginRight: theme.spacing(1) }}
        onClick={() => handleAdd()}
      />
    </Stack>
  );
};

const TaskItem: FC<{ task: Task }> = ({
  task,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home']);

  const expired = task.expiration?.date && isBefore(new Date(task.expiration.date), new Date());
  const diffInDays = task.expiration?.date ? differenceInDays(new Date(), new Date(task.expiration.date)) : 0;

  return (
    <Stack sx={{ padding: theme.spacing(0.5) }}>
      <Typography variant="subtitle1">
        {task.name}
      </Typography>
      <Typography
        sx={{ color: theme.palette.grey[500] }}
        variant="body2"
      >
        {getExpirationString(task.expiration)}
      </Typography>
      {expired && (
        <Typography sx={{ color: theme.palette.error.main }}>
          {
            diffInDays === 1
              ? `${t('home:home_the_date_has_passed_by')} 1 ${t('common:day')}`
              : `${t('home:home_the_date_has_passed_by')} ${diffInDays} ${t('common:days')}`
          }
        </Typography>
      )}
    </Stack>
  );
};

const TasksWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { isDownSm } = useBreakPointsSizes();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.REMINDERS);

  const titleHeight = hidSpacing(3);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: tasks = [], isLoading } = useGetTasksQuery(propertyId ? { propertyId } : skipToken);

  const getTaskExpirationTime = (task: Task) => task.expiration?.date
    ? new Date(task.expiration.date).getTime()
    : new Date().getTime();

  const notCompleted = R.filter((task) => !task.completedAt, tasks);
  const sorted = R.sortBy(getTaskExpirationTime, notCompleted);
  const firsTasks = R.reverse(R.slice(0, SHOW_TASKS_COUNT, sorted));

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow({ showBottom: false });

  const hasNoItems = !isLoading && !tasks.length;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: isDownSm ? theme.spacing(2) : theme.spacing(2, 1, 0, 2),
        height: widgetHeight || '100%',
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Typography
          sx={{ height: titleHeight }}
          variant="h5"
        >
          {t('home:home_tasks')}
        </Typography>
        {
          isLoading
            ? <TasksLoadingState />
            : hasNoItems
              ? <TasksEmptyState />
              : (
                <Stack
                  ref={scrollContainerRef}
                  sx={{
                    height: widgetHeight - titleHeight - hidSpacing(1 + 2),
                    overflowY: 'auto',
                    boxShadow,
                  }}
                  onScroll={onScrollHandler}
                >
                  {firsTasks.map((task) => (
                    <TaskItem key={task.id} task={task} />
                  ))}
                </Stack>
              )
        }
      </Stack>
    </Card>
  );
};

export default TasksWidget;
