import React, {
  useRef,
  useState,
} from 'react';
import {
  Grid,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClearRounded } from '@mui/icons-material';

import {
  FCC,
} from '../../../types/common';
import HIDImage from '../../../components/image/HIDImage';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import HIDImagePreview from '../../../components/filePicker/HIDImagePreview';
import { getIsTypeOf } from '../../../utils/object';
import { getGreyBordersStyle } from '../../../utils/style';
import HIDImageViewer, { HIDImageViewerRef } from '../../../components/image/HIDImageViewer/HIDImageViewer';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

const getIsFile = getIsTypeOf<File>('arrayBuffer');

type ForumImageAttachmentsProps<T = HIDBlob | File> = {
  attachments: Array<T>;
  isEditable?: boolean;
  onRemove?: (blob: T, index: number) => void;
};

const ForumImageAttachments: FCC<ForumImageAttachmentsProps> = ({
  attachments,
  isEditable = false,
  style,
  sx,
  onRemove,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const [isGalleryMode, setIsGalleryMode] = useState(false);

  const imageGalleryRef = useRef<HIDImageViewerRef | null>(null);

  const handleOpenGallery = (index: number) => {
    imageGalleryRef.current?.fullScreen(index);
  };

  const galleryDisplay = isGalleryMode ? 'unset' : 'none';

  return (
    <>
      <Grid
        container
        flexWrap="wrap"
        spacing={2}
        style={style}
        sx={sx}
      >
        {attachments.map((attachment, index) => (
          <Grid
            item
            key={index}
            sm={2.4}
            xxs={3}
          >
            <Stack
              sx={{ position: 'relative' }}
            >
              <Stack
                sx={{
                  ...getGreyBordersStyle(theme, 5),
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => handleOpenGallery(index)}
              >
                {
                  getIsFile(attachment)
                    ? (
                      <HIDImagePreview
                        file={attachment}
                        sx={{ overflow: 'hidden' }}
                      />
                    )
                    : (
                      <HIDImage
                        sx={{
                          width: '100%',
                          aspectRatio: 1,
                          overflow: 'hidden',
                          objectFit: 'cover',
                        }}
                        url={attachment.thumbnailUrl}
                      />
                    )
                }
              </Stack>
              {isEditable && onRemove !== undefined && (
                <HIDIconButton
                  Icon={ClearRounded}
                  color="red"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(-1),
                    right: theme.spacing(-1),
                  }}
                  title={t('common:delete_label')}
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemove(attachment, index);
                  }}
                />
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
      <HIDImageViewer
        isFullScreen
        filePickerSx={{ display: galleryDisplay }}
        images={attachments}
        ref={imageGalleryRef}
        sx={{ display: galleryDisplay }}
        onScreenChange={setIsGalleryMode}
      />
    </>
  );
};

export default ForumImageAttachments;
