import { Language } from '@house-id/houseid-types/dist/common';

const HOUSE_ID_MOVE_EVENT = 'INBOUND_HOUSE_ID_MOVE_EVENT';

enum HouseIdMoveEventType {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
}

export const changeLanguage = (lang: Language) => {
  const event = new Event(HOUSE_ID_MOVE_EVENT);
  // @ts-ignore
  event.detail = {
    type: HouseIdMoveEventType.CHANGE_LANGUAGE,
    data: {
      lang,
    },
  };

  window.dispatchEvent(event);
};
