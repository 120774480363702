import React, {
  FC,
} from 'react';
import {
  CircularProgress,
  Stack,
  useTheme,
} from '@mui/material';

import { IconProps } from '../../types/common';
import HomeIcon from './HomeIcon';

type IconWithProgress = IconProps & {
  progressColorBackground?: string;
  progressColor?: string;
  progress: number;
};

const HomeWithProgress: FC<IconWithProgress> = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  progressColorBackground: progressColorBackgroundProp,
  progressColor: progressColorProp,
  progress,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;
  const progressColorBackground = progressColorBackgroundProp || theme.palette.grey[200];
  const progressColor = progressColorProp || theme.palette.primary.main;

  return (
    <Stack position="relative" sx={{ width: 40, height: 40 }}>
      <CircularProgress
        sx={{
          color: progressColorBackground,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        sx={{
          color: progressColor,
          strokeLinecap: 'round',
        }}
        value={progress}
        variant="determinate"
      />
      <HomeIcon
        fillColor={fillColor}
        iconColor={color}
        size={size}
        strokeWidth={strokeWidth}
        sx={{
          position: 'absolute',
          top: theme.spacing(1),
          right: theme.spacing(1),
        }}
      />
    </Stack>
  );
};

export default HomeWithProgress;
