import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const BuildingPart: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M3 9.5L3 20C3 20.5305 3.21071 21.0392 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7892 21.0392 21 20.5305 21 20V9.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M2 10L12 2L22 10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M10 16V12H14V16"
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default BuildingPart;
