import React, {
  FC,
} from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { BudgetTemplateCategoryId } from '@house-id/houseid-types/dist/finances/budgets';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDSelectItem from '../../../../../../../../../components/HIDSelectItem';
import useScrollWithShadow from '../../../../../../../../../hooks/useScrollWithShadow';
import HIDFiltersContainer from '../../../../../../../../../components/HIDFiltersContainer';
import ListStructureLoaderSkeleton from '../../../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import useGetBudgetCategoriesList from '../hooks/useGetBudgetCategoriesList';

type PaymentCategoriesSidebarProps = {
  activeCategoryIds: Array<string>;
  onAdd: (categoryId: BudgetTemplateCategoryId) => void;
};

const BudgetCategoriesSidebar: FC<PaymentCategoriesSidebarProps> = ({
  activeCategoryIds,
  onAdd,
}) => {
  const { t } = useTranslation(['finances']);

  const {
    data: categories,
    isLoading,
  } = useGetBudgetCategoriesList();

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow();

  return (
    <ListStructureLoaderSkeleton
      isLoading={isLoading}
      itemsCount={15}
    >
      <Stack>
        <HIDTypography variant="h6">
          {t('finances:budgets_add_payment_item')}
        </HIDTypography>
        <HIDFiltersContainer
          ref={scrollContainerRef}
          sx={{ boxShadow, mt: 1, pr: 1 }}
          onScroll={onScrollHandler}
        >
          {categories.map(({ id, name }) =>
            activeCategoryIds.includes(id)
              ? null
              : (
                <HIDSelectItem
                  Icon={Add}
                  key={id}
                  label={name}
                  onClick={() => onAdd(id as BudgetTemplateCategoryId)}
                />
              ))}
        </HIDFiltersContainer>
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default BudgetCategoriesSidebar;
