import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Camera: Icon = ({
  iconColor: colorProp,
  size,
  fillColor: fillColorProp,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.dark;

  return (
    <HIDSvgIcon
      fill="none"
      size={size}
      viewBox="0 0 28 28"
    >
      <path
        d="M26.8335 22.1667C26.8335 22.7855 26.5877 23.379 26.1501 23.8166C25.7125 24.2541 25.119 24.5 24.5002 24.5H3.5002C2.88137 24.5 2.28787 24.2541 1.85029 23.8166C1.41271 23.379 1.16687 22.7855 1.16687 22.1667V9.33333C1.16687 8.7145 1.41271 8.121 1.85029 7.68342C2.28787 7.24584 2.88137 7 3.5002 7H8.16687L10.5002 3.5H17.5002L19.8335 7H24.5002C25.119 7 25.7125 7.24584 26.1501 7.68342C26.5877 8.121 26.8335 8.7145 26.8335 9.33333V22.1667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M14.0002 19.8334C16.5775 19.8334 18.6669 17.744 18.6669 15.1667C18.6669 12.5893 16.5775 10.5 14.0002 10.5C11.4229 10.5 9.33354 12.5893 9.33354 15.1667C9.33354 17.744 11.4229 19.8334 14.0002 19.8334Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M21.5836 14C22.5501 14 23.3336 13.2165 23.3336 12.25C23.3336 11.2835 22.5501 10.5 21.5836 10.5C20.6171 10.5 19.8336 11.2835 19.8336 12.25C19.8336 13.2165 20.6171 14 21.5836 14Z"
        fill={fillColor}
      />
    </HIDSvgIcon>
  );
};

export default Camera;
