import {
  createApi,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import { addDays } from 'date-fns';
import { Property } from '@house-id/houseid-types/dist/property';

import {
  ExternalService,
  HIDEntityId,
  PropertyInvite,
  PropertyUser,
  ResponseData,
} from '../../../types/common';
import { HIDAuthQuery } from '../../../api/HIDBaseQuery';
import { HIDApiTags } from '../../../api/HIDApiTags';
import { HIDApiCacheTimeouts } from '../../../api/HIDApiCacheTimeouts';
import {
  PropertyBonusOffers,
  PropertyId,
  PropertyInboundEmail,
  PropertyStatus,
  PropertySpecification,
  UserRemovalRequest,
  UserSyncDataActionType,
} from '../types/types.property';
import { getPropertyDisplayName } from '../utils/utils.property';
import PropertyExternalService from '../constants/constants.externalServices';
import { getActiveAuth } from '../../../external-services/firebase';

type PublishPropertyAction = PropertyId & {
  type: UserSyncDataActionType;
};

const propertyTagTypes = [
  HIDApiTags.ARTICLE,
  HIDApiTags.BONUS_OFFERS,
  HIDApiTags.BOUGHT_BY,
  HIDApiTags.BUDGET_COMPARISON,
  HIDApiTags.BUDGET_FILTRATION,
  HIDApiTags.BUDGET,
  HIDApiTags.BUILDING_ENTITIES_LIST,
  HIDApiTags.BUILDING_ENTITY,
  HIDApiTags.BUILDING_RECOMMENDED_TEMPLATE,
  HIDApiTags.BUILDING,
  HIDApiTags.CONTENT_CONNECTIONS,
  HIDApiTags.CONTENT_FILE,
  HIDApiTags.DOCUMENT_CATEGORY,
  HIDApiTags.DOCUMENT,
  HIDApiTags.FINANCE_INFORMATION,
  HIDApiTags.FORUM_REPLY,
  HIDApiTags.FORUM_THREAD,
  HIDApiTags.INSURANCE_COMPANY,
  HIDApiTags.INSURANCES,
  HIDApiTags.LOAN,
  HIDApiTags.MESSAGE,
  HIDApiTags.NOTE,
  HIDApiTags.PHOTO,
  HIDApiTags.PLACE,
  HIDApiTags.PRODUCT_CATEGORY,
  HIDApiTags.PRODUCT_SUMMARY,
  HIDApiTags.PRODUCT_TYPE_GUIDE,
  HIDApiTags.PRODUCT,
  HIDApiTags.PROPERTY_BLUEPRINTS,
  HIDApiTags.PROPERTY_DOCUMENTATION,
  HIDApiTags.PROPERTY_FINANCE,
  HIDApiTags.PROPERTY_INBOUND_EMAIL,
  HIDApiTags.PROPERTY_INSURANCE,
  HIDApiTags.PROPERTY_INVITE_CODE,
  HIDApiTags.PROPERTY_MAIN_BUILDING,
  HIDApiTags.PROPERTY_PLANNING,
  HIDApiTags.PROPERTY_PRODUCTS,
  HIDApiTags.PROPERTY_PROGRESS_SPECIFICATION,
  HIDApiTags.PROPERTY_PROGRESS,
  HIDApiTags.PROPERTY_PURCHASE,
  HIDApiTags.PROPERTY_STATUS,
  HIDApiTags.PROPERTY_TODO,
  HIDApiTags.PROPERTY_USER_REMOVAL_REQUEST,
  HIDApiTags.PROPERTY,
  HIDApiTags.RECEIPT_CATEGORY,
  HIDApiTags.RECEIPT_SUMMARY,
  HIDApiTags.RECEIPT,
  HIDApiTags.RECEIPT_FILTERS,
  HIDApiTags.SELLING_PROPERTY_TRANSFER,
  HIDApiTags.SELLING_PROPERTY_ARCHIVE,
  HIDApiTags.SUGGESTION,
  HIDApiTags.SUGGESTIONS_SETTING,
  HIDApiTags.SUGGESTIONS_SUMMARY,
  HIDApiTags.TASK,
  HIDApiTags.TIMELINE_EVENT,
  HIDApiTags.TIMELINE_FILTER,
  HIDApiTags.TIMELINE_GET_STARTED_WIZARD,
  HIDApiTags.TIMELINE,
  HIDApiTags.USER_SETTINGS,
  HIDApiTags.VALUATION,
  HIDApiTags.RECURRING_EXPENSE,
  HIDApiTags.RECURRING_EXPENSE_FILTRATION,
  HIDApiTags.RECURRING_EXPENSE_COMPARISON,
  HIDApiTags.RECURRING_EXPENSE_CANDIDATE,
  HIDApiTags.RECURRING_EXPENSE_BANK_ACCOUNT,
  HIDApiTags.RECURRING_EXPENSE_CONSENTS,
  HIDApiTags.RECURRING_EXPENSE_SHARED_CONSENTS,
];

export const propertyApi = createApi({
  reducerPath: 'property',
  baseQuery: HIDAuthQuery,
  tagTypes: propertyTagTypes,
  endpoints: (builder) => ({
    getProperties: builder.query<Array<Property>, void>({
      query: () => 'properties',
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_120,
      transformResponse: (response: ResponseData<Array<Property>>) => {
        const properties = response?.data || [];
        const unverifiedProperties = properties.filter((p) => !p.isVerified);

        return properties.map((property) => ({
          ...property,
          displayName: getPropertyDisplayName(
            property,
            unverifiedProperties.length > 0,
            unverifiedProperties.indexOf(property),
          ),
        }));
      },
      providesTags: (properties = []) => properties.map((property) => ({ type: HIDApiTags.PROPERTY as const, id: property.id })),
      serializeQueryArgs: ({ endpointName }) => getActiveAuth()?.currentUser?.uid || endpointName,
    }),
    getPropertyStatus: builder.query<PropertyStatus, PropertyId>({
      query: ({ propertyId }) => `properties/${propertyId}/status`,
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_120,
      providesTags: [HIDApiTags.PROPERTY_STATUS],
    }),
    getPropertyUsers: builder.query<Array<PropertyUser>, PropertyId>({
      query: ({ propertyId }) => `properties/${propertyId}/users`,
      providesTags: (users) => users?.map((user) => ({ type: HIDApiTags.PROPERTY_USER, id: user.id })) || [],
      transformResponse: (response: { users: Array<PropertyUser> }) => response.users,
    }),
    getPropertyInboundEmail: builder.query<PropertyInboundEmail, PropertyId>({
      query: ({ propertyId }) => `/sendgrid/token/${propertyId}`,
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_600,
      providesTags: () => [HIDApiTags.PROPERTY_INBOUND_EMAIL],
    }),
    getPropertyUserRemovalRequests: builder.query<Array<UserRemovalRequest>, PropertyId>({
      query: ({ propertyId }) => `properties/${propertyId}/user-removal-request`,
      providesTags: () => [HIDApiTags.PROPERTY_USER_REMOVAL_REQUEST],
    }),
    createPropertyUserRemovalRequests: builder.mutation<void, PropertyId & { userId: string }>({
      query: ({ propertyId, userId }) => ({
        url: `properties/${propertyId}/user-removal-request`,
        method: 'POST',
        body: { userId },
      }),
      invalidatesTags: () => [HIDApiTags.PROPERTY_USER_REMOVAL_REQUEST],
    }),
    acceptUserRemovalRequestRequest: builder.mutation<void, PropertyId & { userRemovalRequestId: string }>({
      query: ({ propertyId, userRemovalRequestId }) => ({
        url: `/properties/${propertyId}/user-removal-request/${userRemovalRequestId}/accept`,
        method: 'POST',
      }),
    }),
    declineUserRemovalRequestRequest: builder.mutation<void, PropertyId & { userRemovalRequestId: string }>({
      query: ({ propertyId, userRemovalRequestId }) => ({
        url: `/properties/${propertyId}/user-removal-request/${userRemovalRequestId}/decline`,
        method: 'POST',
      }),
    }),
    getPropertyBonusOffersSummary: builder.query<PropertyBonusOffers, PropertyId>({
      query: ({ propertyId }) => {
        const dateFrom = addDays(new Date(), 30).toISOString();

        return `properties/${propertyId}/bonus/summary?${qs.stringify({ from: dateFrom })}`;
      },
    }),
    getPropertyExternalServices: builder.query<Array<ExternalService>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/external-services`,
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_600,
      providesTags: () => [HIDApiTags.PROPERTY_EXTERNAL_SERVICE],
    }),
    updateProperty: builder.mutation<Property, Partial<Property> & HIDEntityId>({
      query: (property) => ({
        url: `/properties/${property.id}`,
        method: 'PATCH',
        body: property,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.PROPERTY as const, id: arg.id },
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.PROPERTY_FINANCE,
      ],
    }),
    syncPropertyExternalServices: builder.mutation<Property, PropertyId & { serviceIds: Array<string> }>({
      query: ({ propertyId, serviceIds }) => ({
        url: `/properties/${propertyId}/external-services/sync`,
        method: 'POST',
        body: { service_ids: serviceIds },
      }),
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_EXTERNAL_SERVICE,
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.PROPERTY_PROGRESS_SPECIFICATION,
      ],
    }),
    deleteProperty: builder.mutation<Property, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.PROPERTY as const, id: arg.propertyId },
      ],
    }),
    leaveProperty: builder.mutation<Property, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}/users/me`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.PROPERTY as const, id: arg.propertyId },
      ],
    }),
    patchPropertyInboundEmail: builder.mutation<Property, PropertyId & { token: string }>({
      query: ({ propertyId, token }) => ({
        url: `/sendgrid/token/${propertyId}`,
        method: 'PUT',
        body: { token },
      }),
      invalidatesTags: () => [HIDApiTags.PROPERTY_INBOUND_EMAIL],
    }),
    createPropertyInvite: builder.mutation<PropertyInvite, PropertyId>({
      query: ({ propertyId }) => ({
        url: `/properties/${propertyId}/invites`,
        method: 'PUT',
      }),
    }),
    publishPropertyAction: builder.mutation<Property, PublishPropertyAction>({
      query: ({ propertyId, type }) => ({
        url: `/properties/${propertyId}/users/actions/_publish`,
        method: 'POST',
        body: { type },
      }),
    }),
    getPropertySpecificationValues: builder.query<PropertySpecification, PropertyId>({
      query: ({ propertyId }) => `v2/properties/${propertyId}/specification?valuesOnly=true`,
      providesTags: [HIDApiTags.PROPERTY_SPECIFICATION],
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetPropertyUsersQuery,
  useGetPropertyStatusQuery,
  useGetPropertyInboundEmailQuery,
  useLazyGetPropertyInboundEmailQuery,
  useGetPropertyBonusOffersSummaryQuery,
  useGetPropertyUserRemovalRequestsQuery,
  usePatchPropertyInboundEmailMutation,
  useGetPropertyExternalServicesQuery,
  useSyncPropertyExternalServicesMutation,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
  useLeavePropertyMutation,
  useCreatePropertyUserRemovalRequestsMutation,
  useCreatePropertyInviteMutation,
  useDeclineUserRemovalRequestRequestMutation,
  useAcceptUserRemovalRequestRequestMutation,
  useGetPropertySpecificationValuesQuery,
} = propertyApi;

export const useGetPropertyExternalService = (
  {
    propertyId,
    externalServiceId,
  }: PropertyId & { externalServiceId: PropertyExternalService },
) =>
  useGetPropertyExternalServicesQuery(
    propertyId ? { propertyId } : skipToken,
    {
      selectFromResult: ({ currentData: externalServices, isLoading, isSuccess }) => ({
        data: externalServices?.find((externalService: ExternalService) =>
          externalService.serviceId === externalServiceId),
        isLoading,
        isSuccess,
      }),
    },
  );

export const useGetProperty = ({ propertyId }: { propertyId?: string }) =>
  useGetPropertiesQuery(
    undefined,
    {
      selectFromResult: ({ data: properties, isLoading, isSuccess }) => ({
        data: properties?.find((property: Property) => property.id === propertyId),
        isLoading,
        isSuccess,
      }),
    },
  );
