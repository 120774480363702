import React, { FC } from 'react';
import {
  Card,
  useTheme,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import RecurringExpensesOverview from '../../../modules/Finances/pages/financeOverview/components/RecurringExpensesOverview';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import HIDButton from '../../../../../components/buttons/HIDButton';
import { getRecurringExpensesCategoriesPath } from '../../../modules/Finances/modules/RecurringExpenses/navigation.recurringExpenses';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';

const RecurringExpensesWidget: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.RECURRING_EXPENSE);

  const handleNavigateToExpenseCategories = () => navigate(getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId }));

  const headerInfo = (
    <HIDButton
      Icon={ArrowForward}
      size="small"
      sx={{ alignSelf: 'flex-start' }}
      onClick={handleNavigateToExpenseCategories}
    >
      {t('home:home_detailed_history')}
    </HIDButton>
  );

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        height: widgetHeight || 'unset',
      }}
    >
      <RecurringExpensesOverview
        headerInfo={headerInfo}
        minHeight={280}
        sx={{ borderWidth: 0 }}
      />
    </Card>
  );
};

export default RecurringExpensesWidget;
