import { useMemo } from 'react';

import { QueryHook } from '../../../types/common';
import useGetCurrentProperty from './useCurrentProperty';
import PropertyPermission from '../constants/constants.propertyPermissions';

export type PropertyPermissions = {
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  permissions: Array<PropertyPermission>;
};

type UseGetPropertyPermissions = QueryHook<PropertyPermissions | undefined>;

const useGetPropertyPermissions: UseGetPropertyPermissions = (args, options) => {
  const {
    data: property,
    ...rest
  } = useGetCurrentProperty(args, options);

  const permissionsList = useMemo(
    () =>
      property?.readOnly
        ? [PropertyPermission.CAN_DELETE]
        : [
          PropertyPermission.CAN_CREATE,
          PropertyPermission.CAN_UPDATE,
          PropertyPermission.CAN_DELETE,
        ],
    [property],
  );

  return {
    data: property
      ? {
        canCreate: permissionsList.includes(PropertyPermission.CAN_CREATE),
        canUpdate: permissionsList.includes(PropertyPermission.CAN_UPDATE),
        canDelete: permissionsList.includes(PropertyPermission.CAN_DELETE),
        permissions: permissionsList,
      }
      : undefined,
    ...rest,
  };
};

export default useGetPropertyPermissions;
