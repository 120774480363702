import React from 'react';
import { DialogContent } from '@mui/material';

import { FCC } from '../../types/common';

const HIDDialogContent: FCC = ({
  children,
  sx,
  style,
}) => (
  <DialogContent
    style={style}
    sx={{ padding: 2.5, ...sx }}
  >
    {children}
  </DialogContent>
);

export default HIDDialogContent;
