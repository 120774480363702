import React, { forwardRef } from 'react';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material';
import {
  CalendarToday,
  Clear,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS } from 'date-fns/locale/en-US';
import { sv } from 'date-fns/locale/sv';
import { Language } from '@house-id/houseid-types/dist/common';

import {
  FCC,
  FCCProps,
} from '../../types/common';
import {
  DateTimeFormats,
  getDateFormat,
} from '../../utils/date';
import HIDIconButton from '../buttons/HIDIconButton';
import { getCurrentLanguage } from '../../utils/locale';

const localesMap: Record<Language, typeof enUS> = {
  [Language.SV]: sv,
  [Language.EN]: enUS,
};

export type HIDDatePickerProps = {
  fullWidth?: boolean;
  label: DatePickerProps<Date>['label'];
  value?: Date;
  disabled?: DatePickerProps<Date>['disabled'];
  dateTimeFormat?: DateTimeFormats;
  maxDate?: Date;
  minDate?: Date;
  views?: DatePickerProps<Date>['views'];
  slots?: DatePickerProps<Date>['slots'];
  slotProps?: DatePickerProps<Date>['slotProps'];
  onChange: (date?: Date) => void;
};

type HIDDatePickerPropsWithRef = FCCProps<HIDDatePickerProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDDatePicker: FCC<HIDDatePickerProps> = forwardRef<HTMLInputElement, HIDDatePickerPropsWithRef>((
  {
    fullWidth = false,
    label,
    value,
    disabled,
    dateTimeFormat = DateTimeFormats.DATE_ONLY,
    maxDate,
    minDate,
    views,
    slots,
    slotProps,
    sx,
    onChange,
  },
  ref,
) => {
  const { t } = useTranslation(['common']);
  const handleClear = () => onChange(undefined);

  const isDesktop = useMediaQuery('@media (pointer: fine)');

  const locale = localesMap[getCurrentLanguage()];

  return (
    <LocalizationProvider
      adapterLocale={locale}
      dateAdapter={AdapterDateFns}
    >
      <DatePicker
        disabled={disabled}
        format={getDateFormat(dateTimeFormat)}
        inputRef={ref}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        slotProps={slotProps}
        slots={{
          calendarHeader: views?.length === 1 && views[0] === 'month' ? () => <div /> : undefined,
          openPickerIcon: CalendarToday,
          openPickerButton: (props) => (
            <HIDIconButton
              Icon={CalendarToday}
              color="blank"
              size="small"
              onClick={props.onClick}
            />
          ),
          inputAdornment: (props) => (
            <>
              {value && (
                <InputAdornment position="end" sx={props.sx}>
                  <HIDIconButton
                    Icon={Clear}
                    color="blank"
                    disabled={disabled}
                    size="small"
                    title={t('common:clear')}
                    onClick={handleClear}
                  />
                </InputAdornment>
              )}
              <InputAdornment {...props} />
            </>
          ),
          textField: isDesktop
            ? undefined
            : (props) => (
              <TextField
                {...props}
                InputProps={{
                  endAdornment: (
                    <>
                      {value && (
                        <InputAdornment position="end">
                          <HIDIconButton
                            Icon={Clear}
                            color="blank"
                            size="small"
                            title={t('common:clear')}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClear();
                            }}
                          />
                        </InputAdornment>
                      )}
                      <InputAdornment position="end">
                        <HIDIconButton
                          Icon={CalendarToday}
                          color="blank"
                          size="small"
                          onClick={() => { }}
                        />
                      </InputAdornment>
                    </>
                  ),
                  // @ts-ignore
                  ...(slotProps?.textField?.InputProps as unknown as TextFieldProps['InputProps']),
                }}
                fullWidth={fullWidth}
              />
            ),
          ...slots,
        }}
        sx={sx}
        value={value === undefined ? null : value}
        views={views}
        onChange={(date) => onChange(date || undefined)}
      />
    </LocalizationProvider>
  );
});

HIDDatePicker.displayName = 'HIDDatePicker';

export default HIDDatePicker;
