import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  useTheme,
} from '@mui/material';
import { isBefore } from 'date-fns';

import {
  formatDate,
  DateTimeFormats,
} from '../../../../../utils/date';
import { formatMoney } from '../../../../../utils/string';
import PropertyProgressContentItem from './PropertyProgressContentItem';
import TaskIcon from '../../../../../components/icons/Task';
import { Task } from '../../Content/modules/GoodToDoTask/types.task';
import { getExpirationString } from '../../Content/modules/GoodToDoTask/utils.task';

type PropertyTaskProps = {
  task: Task;
  onClick: (taskId: string) => void;
};

const PropertyTask: FC<PropertyTaskProps> = ({ task, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation(['tasks']);
  const isCompleted = Boolean(task.completedAt);

  const expired = task?.expiration?.date && isBefore(new Date(task?.expiration?.date), new Date());

  const taskDateColor = isCompleted
    ? 'primary'
    : expired ? 'error' : 'grey';

  const date = isCompleted && task?.completedAt
    ? `${t('tasks:tasks_carried_out')}, ${formatDate(new Date(task.completedAt), DateTimeFormats.DATE_ONLY)}`
    : getExpirationString(task.expiration);

  const cost = task.cost?.actual ? formatMoney(task.cost?.actual) : formatMoney(task.cost.estimated);

  const description = [date, cost].filter(Boolean).join(', ');

  return (
    <PropertyProgressContentItem
      DescriptionComponent={
        <Typography color={taskDateColor}>
          {description}
        </Typography>
      }
      Icon={TaskIcon}
      key={task.id}
      sx={{
        backgroundColor: isCompleted ? theme.palette.primary.lightest : theme.palette.common.white,
      }}
      title={task.name}
      onNavigate={() => onClick(task.id)}
    />
  );
};

export default PropertyTask;
