import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Shield: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M9 12L11 14L15 10M20 11V6L12 2L4 6V11C4 11 4.04762 16.1957 5.7146 18.0711C7.3816 19.9464 12 22 12 22C12 22 16.5658 20.0057 18.2854 18.0711C19.9524 16.1957 20 13.6522 20 11Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Shield;
