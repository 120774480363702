import React, {
  FC,
  useState,
} from 'react';
import {
  Card,
  Typography,
} from '@mui/material';
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../utils/date';
import { useNavigateBackOr } from '../../../../../../utils/routes';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeListLayout from '../../../../pages/Home/components/HomeListLayout';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import {
  BonusOffersQuickNavigationCategories,
  BonusOffersLogEntity,
} from '../../types.bonusOffers';
import BonusOffersQuickNavigation from '../../components/BonusOffersQuickNavigation';
import { getBonusOffersPath } from '../../navigation.bonusOffers';
import { useGetBonusOffersLogsQuery } from '../../api/api.bonusOffers';
import HIDTypography from '../../../../../../components/HIDTypography';

const BonusOffersLogs: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['bonus_offers']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { isDownSm } = useBreakPointsSizes();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const {
    data: bonusOffersLogsData,
    isLoading: isLoadingBonusOffersLogs,
    isFetching: isFetchingBonusOffersLogs,
  } = useGetBonusOffersLogsQuery(propertyId
    ? {
      propertyId,
      pageSize: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
    }
    : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const history = bonusOffersLogsData?.history || [];
  const totalCount = bonusOffersLogsData?.totalCount || 0;

  const columns: Array<GridColDef> = [
    {
      field: 'timestamp',
      headerName: t('bonus_offers:bonus_offers_log_time'),
      flex: 0.3,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        const { timestamp } = params?.row as BonusOffersLogEntity;

        return (
          <Typography noWrap variant="subtitle1">
            {formatDate(new Date(timestamp), DateTimeFormats.FULL)}
          </Typography>
        );
      },
    },
    !isDownSm && {
      field: 'text',
      headerName: t('bonus_offers:bonus_offers_log_event'),
      flex: 0.3,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        const { text } = params?.row as BonusOffersLogEntity;

        return (
          <Typography variant="body1">
            {text}
          </Typography>
        );
      },
    },
    {
      field: 'points',
      headerName: t('bonus_offers:bonus_offers_log_points'),
      flex: 0.2,
      type: 'number',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const { points } = params?.row as BonusOffersLogEntity;

        return (
          <Typography variant="body1">
            {points}
          </Typography>
        );
      },
    },
    {
      field: 'totalPoints',
      headerName: t('bonus_offers:bonus_offers_log_total_points'),
      flex: 0.2,
      type: 'number',
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const { totalPoints } = params?.row as BonusOffersLogEntity;

        return (
          <Typography variant="body1">
            {totalPoints || ''}
          </Typography>
        );
      },
    },
  ].filter(Boolean);

  return (
    <HomeListLayout
      ListHeaderComponent={
        <HIDTypography
          isLoading={isLoadingBonusOffersLogs}
          skeletonWidth="200px"
          variant="body1"
        >
          {
            totalCount === 1
              ? t('bonus_offers:bonus_offers_logs_counter', { count: totalCount })
              : t('bonus_offers:bonus_offers_logs_counter_plural', { count: totalCount })
          }
        </HIDTypography>
      }
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <BonusOffersQuickNavigation
            currentCategory={BonusOffersQuickNavigationCategories.BONUS_OFFERS_LOGS}
            key={BonusOffersQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getBonusOffersPath, { propertyId }),
          name: t('bonus_offers:bonus_offers_bonus_offers'),
        },
      ]}
      columns={columns}
      getRowId={(row: BonusOffersLogEntity) => row.id}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'timestamp',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isFetchingBonusOffersLogs}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={history || []}
      sideDrawerElements={[
        <BonusOffersQuickNavigation
          currentCategory={BonusOffersQuickNavigationCategories.BONUS_OFFERS_LOGS}
          key={BonusOffersQuickNavigation.name}
        />,
      ]}
      title={t('bonus_offers:bonus_offers_logs')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onPaginationModelChange={setPaginationModel}
    />
  );
};

export default BonusOffersLogs;
