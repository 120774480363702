import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import {
  PropertyProductType,
} from '../../../types.propertyProgress';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useGetAddEntityConnectionAction from '../../../../Content/hooks/useGetAddEntityConnectionAction';
import { getProductPath } from '../../../../Content/modules/Product/navigation.product';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import PropertyProductsList from './PropertyProductsList';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../../../hooks/useGetPropertyPermissions';

const PropertyProductTypeGuideSection: FC<{ productType: PropertyProductType }> = ({
  productType,
}) => {
  const { t } = useTranslation(['products']);
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  const addProduct = useGetAddEntityConnectionAction(EntityType.PRODUCT);

  const handleCreateProduct = (productType: string) => addProduct([], {
    propertyId,
    type: productType,
  });

  const handleNavigateToViewProduct = (productId: string) =>
    navigate(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id: productId }));

  const [openProductTypeDialog] = useDialog(DialogNames.PRODUCT_TYPE_GUIDE_DIALOG);
  const handleOpenProductTypeGuide = (productType: string) => openProductTypeDialog({ productType });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('products:product_add_product_from_guide', { typeName: productType.name?.toLocaleLowerCase() })}
          showButtons={canCreate}
          viewAllButtonText={t('products:product_product_type_guide_title', { typeName: productType.name?.toLocaleLowerCase() })}
          onAddClick={() => handleCreateProduct(productType.type)}
          onViewAllClick={() => handleOpenProductTypeGuide(productType.type)}
        >
          <PropertyProductsList
            productType={productType}
            onClick={handleNavigateToViewProduct}
          />
        </PropertyProgressCategorySectionContent>
      }
      key={productType.type}
      section={{
        id: productType.type,
        type: productType.type,
        iconUrl: productType.icon.url,
        name: productType.name,
        progress: {
          total: 1,
          completed: productType.completed ? 1 : 0,
        },
        data: {},
      }}
    />

  );
};
export default PropertyProductTypeGuideSection;
