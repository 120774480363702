import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const CheckMarkRect: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m8.58884,11.09432l3.01316,3.01316l10.04383,-10.04389m-1.00436,8.03509l0,7.03068c0,0.53273 -0.21167,1.04368 -0.58839,1.4204c-0.37672,0.3768 -0.88768,0.58839 -1.4204,0.58839l-14.06139,0c-0.53276,0 -1.0437,-0.2116 -1.42042,-0.58839c-0.37672,-0.37672 -0.58836,-0.88768 -0.58836,-1.4204l0,-14.06137c0,-0.53276 0.21164,-1.0437 0.58836,-1.42042c0.37672,-0.37672 0.88766,-0.58836 1.42042,-0.58836l11.04823,0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default CheckMarkRect;
