import React, {
  FC,
  ReactNode,
} from 'react';
import {
  DialogTitle,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import {
  InfoOutlined,
} from '@mui/icons-material';

import CloseDialogIconButton from '../buttons/CloseDialogIconButton';
import { FCC } from '../../types/common';

type HIDDialogIconType = 'error';

const HIDDialogErrorIcon: FC = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignSelf: 'flex-start',
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.error.lighter,
        borderRadius: theme.spacing(3),
      }}
    >
      <InfoOutlined sx={{ color: theme.palette.error.main }} />
    </Stack>
  );
};

export type HIDDialogTitleProps = {
  title?: string;
  TitleComponent?: ReactNode;
  iconType?: HIDDialogIconType;
  showBorder?: boolean;
  titleContainerSx?: SxProps<Theme>;
  onClose: () => void;
};

const HIDDialogIconTypeMap: Record<HIDDialogIconType, React.ReactElement> = {
  error: <HIDDialogErrorIcon />,
};

const HIDDialogTitle: FCC<HIDDialogTitleProps> = ({
  title,
  TitleComponent,
  iconType,
  showBorder = true,
  sx,
  titleContainerSx,
  style,
  children,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Stack style={style} sx={sx}>
      <CloseDialogIconButton onClick={onClose} />
      <Stack
        sx={{
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: showBorder ? 1 : 0,
          borderColor: theme.palette.grey[300],
        }}
      >
        <DialogTitle
          component="div"
          sx={{
            padding: 2.5,
            paddingRight: 5.5,
            ...titleContainerSx,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {iconType && HIDDialogIconTypeMap[iconType]}
            {TitleComponent || (
              <Typography variant="h4">
                {title || <>&nbsp;</>}
              </Typography>
            )}
          </Stack>
        </DialogTitle>
        {children}
      </Stack>
    </Stack>
  );
};

export default HIDDialogTitle;
