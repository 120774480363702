// import _ from 'lodash';
// import React, { FC } from 'react';
// import {
//   StyleProp,
//   ViewStyle,
// } from 'react-native';
// import SelectModalInput from '../../../../../components/pickers/selectModalInput/SelectModalInput';
// import { spacing } from '../../../../../constants/Layout';
// import {
//   DynamicInputCommonFieldProps,
//   DynamicMultipleSelectField,
// } from '../../../types';
// import { getDynamicContentComponentInfo } from '../services';
// import DynamicContentSource from './DynamicContentSource';

import {
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import DynamicContentSource from './DynamicContentSource';
import {
  DynamicInputCommonFieldProps,
  DynamicMultipleSelectField,
} from '../../../types/types.dynamicContent';
import HIDFormMultiSelect from '../../../../../../../../../components/HIDFormMultiSelect';

type DynamicContentMultiSelectProps = DynamicInputCommonFieldProps<Array<string>> & DynamicMultipleSelectField;

const DynamicContentMultiSelect: FC<DynamicContentMultiSelectProps> = ({
  title,
  mandatory,
  // description,
  options,
  placeholder,
  value,
  readonly,
  source,
  errorMessage,
  onValueChange,
  onBlur,
}) => {
  const items = options.map((option) => ({
    id: option.value,
    name: option.label,
  }));

  const handleValueChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (onValueChange) {
      onValueChange(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const helperText = (
    <Stack>
      {errorMessage && (
        <Typography variant="inherit">
          {errorMessage}
        </Typography>
      )}
      <DynamicContentSource source={source} />
    </Stack>
  );

  return (
    <HIDFormMultiSelect
      showHelperText
      disabled={readonly}
      error={Boolean(errorMessage)}
      helperText={helperText}
      items={items}
      label={title}
      placeholder={placeholder}
      required={mandatory}
      value={value}
      onBlur={onBlur}
      onChange={handleValueChange}
    />
  );
};

export default DynamicContentMultiSelect;
