import React, { FC } from 'react';
import { Stack } from '@mui/material';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  ExpenseTransaction,
  RecurringExpense,
} from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDTypography from '../../../../../../../components/HIDTypography';
import BasicInfoSection from '../../../../Content/components/sections/BasicInfoSection';
import { formatMoney } from '../../../../../../../utils/string';

type ExpenseInsightsSectionProps = {
  expense: RecurringExpense;
};

const ExpenseInsightsSection: FC<ExpenseInsightsSectionProps> = ({
  expense,
}) => {
  const { t } = useTranslation(['forms_common', 'finances']);

  const transactionsValue = R.map((transaction: ExpenseTransaction) => transaction.amount.value, expense.transactions || []);

  const maxTransaction = R.reduce(R.max, Number.NEGATIVE_INFINITY, transactionsValue) as number;
  const minTransaction = R.reduce(R.min, Number.POSITIVE_INFINITY, transactionsValue) as number;

  const fields = [
    {
      label: t('finances:largest_amount'),
      value: formatMoney(maxTransaction, true),
    },
    {
      label: t('finances:lowest_amount'),
      value: formatMoney(minTransaction, true),
    },
    {
      label: t('finances:average_amount'),
      value: formatMoney(expense.averageAmount, true),
    },
    {
      label: t('finances:number_of_costs'),
      value: expense.transactionsCount.toString(),
    },
  ];

  return (
    <Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <HIDTypography variant="h6">
          {t('finances:insights')}
        </HIDTypography>
      </Stack>
      <BasicInfoSection items={fields} />
    </Stack>
  );
};

export default ExpenseInsightsSection;
