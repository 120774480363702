import { CSSProperties } from 'react';
import { Theme } from '@mui/material';

import { remToPixels } from './number';

export const getNoWrapStyle = (numberOfLines = 2) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines.toString(),
  WebkitBoxOrient: 'vertical',
  wordBreak: numberOfLines === 1 ? 'break-all' : 'break-word',
});

export const getTypographyHeight = (typography: CSSProperties) => {
  const fontSizeRem = Number.parseFloat((typography.fontSize as string).replace('rem', ''));
  return (typography.lineHeight as number) * remToPixels(fontSizeRem);
};

export const getGreyBordersStyle = (theme: Theme, borderRadius?: number) => ({
  borderStyle: 'solid',
  borderWidth: '1',
  borderColor: theme.palette.grey[300],
  borderRadius: borderRadius ? `${borderRadius}px` : undefined,
});

export const getColorGradient = (fromColor: string, toColor: string) => `linear-gradient(180deg, ${fromColor} 0%, ${toColor} 100%)`;
