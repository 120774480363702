import React from 'react';
import {
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import mimeTypes from 'mime-types';
import * as R from 'ramda';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../types/common';
import {
  FileMimeType,
  ImageMimeTypes,
} from '../../../../../constants/mimeTypes';
import HIDImage from '../../../../../components/image/HIDImage';
import { hidSpacing } from '../../../../../utils/number';

type ContentImageProps = {
  blobs?: Array<HIDBlob>;
  externalImage?: string;
  size?: number;
  imageSx?: SxProps;
};

const ContentImage: FCC<ContentImageProps> = ({
  blobs,
  externalImage,
  size = hidSpacing(7),
  imageSx,
  sx,
}) => {
  const theme = useTheme();

  const blob = blobs && blobs.length
    ? blobs.find((blob) => ImageMimeTypes.includes(blob.mime) || blob.mime as FileMimeType === FileMimeType.PDF) || R.head(blobs)
    : undefined;

  const hasThumbnail = Boolean(blob?.thumbnailUrl || externalImage);

  const extension = blob?.mime
    ? mimeTypes.extension(blob.mime) || undefined
    : undefined;

  return (
    <Stack sx={{ ...sx, minWidth: size }}>
      {
        hasThumbnail || !extension
          ? (
            <HIDImage
              showSkeleton
              externalUrl={externalImage}
              size={size}
              sx={{
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.palette.grey[300],
                borderRadius: theme.spacing(0.5),
                margin: '0 auto',
                width: hasThumbnail ? 'auto' : size,
                minWidth: theme.spacing(3),
                maxWidth: size,
                maxHeight: size,
                objectFit: 'cover',
                ...imageSx,
              }}
              url={blob?.thumbnailUrl}
            />
          )
          : (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                width: size,
                minWidth: size,
                height: size,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.palette.grey[300],
                borderRadius: theme.spacing(0.5),
                ...imageSx,
              }}
            >
              <Typography color="primary" variant="h6">{extension.toUpperCase()}</Typography>
            </Stack>
          )
      }
    </Stack>
  );
};

export default React.memo(ContentImage);
