import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getPhotosPath = ({ propertyId, photoId }: PropertyIdNavigation & { photoId?: string }) => generateRoutePath(
  RouteNames.PHOTOS,
  { propertyId },
  { photoId },
);

type GetCreatePhotosPath = PropertyIdNavigation & { contentFileIds?: Array<string> };
export const getCreatePhotosPath = ({ propertyId, contentFileIds }: GetCreatePhotosPath) => generateRoutePath(
  RouteNames.PHOTOS_CREATE,
  { propertyId },
  { contentFileIds },
);

type GetUpdatePhotosPath = PropertyIdNavigation & { ids: Array<string> };
export const getUpdatePhotosPath = ({ propertyId, ids }: GetUpdatePhotosPath) =>
  generateRoutePath(RouteNames.PHOTOS_CREATE, { propertyId }, { ids });
