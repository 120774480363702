import React, { useMemo } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import HIDInlineSelect from '../../../../../../../../../components/HIDInlineSelect';
import ListStructureLoaderSkeleton from '../../../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import useGetBudgetsList from '../../../hooks/useGetBudgetsList';
import { useRouteQueryParams } from '../../../../../../../../../utils/routes';
import HIDFiltersContainer from '../../../../../../../../../components/HIDFiltersContainer';
import useScrollWithShadow from '../../../../../../../../../hooks/useScrollWithShadow';

const BudgetsQuickNavigation = () => {
  const { t } = useTranslation(['finances']);

  const [_, setSearchParams] = useSearchParams();
  const { budgetId } = useRouteQueryParams<{ budgetId?: string; }>();

  const {
    data: budgets = [],
    isLoading: isBudgetsLoading,
  } = useGetBudgetsList();

  const mappedBudgets = useMemo(() => budgets.map(({ id, name }) => ({
    id,
    label: name,
  })), [budgets]);

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow();

  return (
    <ListStructureLoaderSkeleton isLoading={isBudgetsLoading}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('finances:budgets')}
        </Typography>
        <HIDFiltersContainer
          ref={scrollContainerRef}
          sx={{
            boxShadow,
            mt: 1,
            pr: 1,
            minHeight: 'unset',
          }}
          onScroll={onScrollHandler}
        >
          <HIDInlineSelect
            items={mappedBudgets}
            value={budgetId}
            onChange={(id) => setSearchParams({ budgetId: id })}
          />
        </HIDFiltersContainer>
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default BudgetsQuickNavigation;
