import React from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { ExpensesBankProvider } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import { FCC } from '../../../../../../../../../types/common';
import BankAccount from './BankAccount';

type BankListItemProps = {
  bank: ExpensesBankProvider;
  onDelete: () => void;
};

const BankListItem: FCC<BankListItemProps> = ({
  bank,
  onDelete,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        ...sx,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        justifyContent="space-between"
      >
        <BankAccount
          logoURL={bank.logoURL}
          name={bank.name.toUpperCase()}
        />
        <HIDIconButton
          Icon={DeleteOutline}
          onClick={onDelete}
        />
      </Stack>
    </Card>
  );
};

export default BankListItem;
