import {
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { partnersAuth } from '../external-services/firebase';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  // [HOUSE_ID_APP_VERSION]: appVersion,
  'x-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
};

type HIDBasePartnerQueryParams = typeof fetchBaseQuery;
const HIDBasePartnerQuery: HIDBasePartnerQueryParams = (props) => fetchBaseQuery({
  ...props,
  baseUrl: `${import.meta.env.VITE_APP_BACKEND_URL}`,
  headers: DEFAULT_HEADERS,
  credentials: 'same-origin',
  prepareHeaders: async (headers) => {
    try {
      const token = await partnersAuth.currentUser?.getIdToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Access-Control-Expose-Headers: ', '*');
      }
    } catch (e) {
      return headers;
    }
    return headers;
  },

});

export default HIDBasePartnerQuery;
