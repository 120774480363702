import {
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';
import { FCC } from '../../types/common';

type MembersLoaderSkeletonProps = {
  itemSize?: number;
  itemsCount?: number;
  isLoading: boolean | undefined;
};

const MembersLoaderSkeleton: FCC<MembersLoaderSkeletonProps> = ({
  itemSize = 24,
  itemsCount = 5,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={2} sx={{ padding: theme.spacing(0.5, 1) }}>
        {R.range(0, itemsCount).map((i) => (
          <Stack direction="row" key={i} spacing={1}>
            <Skeleton
              height={itemSize}
              variant="circular"
              width={itemSize}
            />
            <Skeleton
              height={itemSize}
              variant="rounded"
              width={`calc(100% - (${theme.spacing(1)} + ${itemSize}px))`}
            />
          </Stack>
        ))}
      </Stack>
    );
  }

  return children;
};

export default MembersLoaderSkeleton;
