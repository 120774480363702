import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

import { QueryHook } from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { BuildingTemplate } from '../types.buildings';
import { useGetAllBuildingTemplatesQuery } from '../api/api.buildings';
import { arrToMap } from '../../../../../utils/array';
import { BuildingEntityType } from '../../../../../constants/entityType';

type UseGetBuildingTemplatesMap = QueryHook<Record<string, BuildingTemplate> | undefined, { buildingEntityType: BuildingEntityType }>;

const useGetBuildingTemplatesMap: UseGetBuildingTemplatesMap = (args, options) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: templates = [], ...rest } = useGetAllBuildingTemplatesQuery(
    propertyId && args?.buildingEntityType ? { propertyId, buildingEntityType: args?.buildingEntityType } : skipToken,
    options,
  );

  const templatesMap = useMemo(() => arrToMap((template) => [template.id, template], templates), [templates]);

  return {
    data: templatesMap,
    ...rest,
  };
};

export default useGetBuildingTemplatesMap;
