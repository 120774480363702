import {
  Stack,
  useTheme,
  Skeleton,
  Divider,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';
import { FCC } from '../../../../../../../../types/common';
import { getTypographyHeight } from '../../../../../../../../utils/style';

type TimelineEventLoaderSkeletonProps = {
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const TimelineEventLoaderSkeleton: FCC<TimelineEventLoaderSkeletonProps> = ({
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    const height = getTypographyHeight(theme.typography.body2);

    return (
      <Stack direction="column" sx={{ padding: theme.spacing(1) }}>
        <Stack direction="row" spacing={2} sx={{ padding: theme.spacing(2), maxWidth: '70%' }}>
          {R.range(0, 3).map((i) => (
            <Skeleton
              height={height}
              key={i}
              variant="rounded"
              width="33.3%"
            />
          ))}
        </Stack>
        <Divider />
        {[40, 20, 50].map((width, i) => (
          <Stack key={i} spacing={1.5} sx={{ marginTop: theme.spacing(2.5) }}>
            <Skeleton
              height={height}
              variant="rounded"
              width={`${width}%`}
            />
            <Stack
              spacing={2}
              sx={{
                padding: 2,
                borderRadius: theme.spacing(1.25),
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="50%" />
            </Stack>
          </Stack>
        ))}
      </Stack>
    );
  }

  return children;
};

export default TimelineEventLoaderSkeleton;
