import React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const FlagSV: Icon = ({
  size,
}) => (
  <HIDSvgIcon size={size}>
    <circle
      cx="12"
      cy="12"
      fill="#ffda44"
      r="11.99373"
    />
    <path
      d="m9.38645,10.42935l14.49949,0c-0.76662,-5.88475 -5.7985,-10.42935 -11.89221,-10.42935a12.03599,12.03599 0 0 0 -2.60733,0.28509l0,10.14426l0.00005,0zm-3.12887,-0.00005l0,-8.97099c-3.29331,1.7969 -5.65088,5.09289 -6.15607,8.97103l6.15607,0l0,-0.00005zm-0.00005,3.12882l-6.15602,0c0.50519,3.87815 2.86275,7.17413 6.15607,8.97099l-0.00005,-8.97099zm3.12887,0.00005l0,10.14422a12.03599,12.03599 0 0 0 2.60733,0.28509c6.09371,0 11.12559,-4.54459 11.89221,-10.42935l-14.49953,0l0,0.00005z"
      fill="#0052b4"
    />
  </HIDSvgIcon>
);

export default FlagSV;
