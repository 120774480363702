import { useMemo } from 'react';

import { QueryHook } from '../../../types/common';
import useGetCurrentProperty from './useCurrentProperty';
import { useRouteParams } from '../../../utils/routes';
import { useGetTokenDataQuery } from '../../Auth/api/api.user';

const useGetCurrentPropertyId: QueryHook<string | undefined> = (args, options) => {
  const { propertyId: routePropertyId } = useRouteParams<{ propertyId?: string }>();

  const { data: tokenData } = useGetTokenDataQuery({}, options);

  const {
    data: currentProperty,
    ...rest
  } = useGetCurrentProperty(args, options);

  const currentPropertyId = useMemo(() => {
    if (currentProperty?.id) {
      return currentProperty.id;
    }
    if (tokenData?.properties?.length) {
      return tokenData.properties.some((propertyId) => propertyId === routePropertyId)
        ? routePropertyId
        : tokenData.properties[0];
    }
    return undefined;
  }, [currentProperty, tokenData, routePropertyId]);

  return {
    data: currentPropertyId,
    ...rest,
  };
};

export default useGetCurrentPropertyId;
