import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import HIDHeader from '../../../components/layouts/HIDHeader';
import HIDButton from '../../../components/buttons/HIDButton';
import { useGetSignOut } from '../../Auth/hooks/useGetSignOut';
import { getActiveAuth } from '../../../external-services/firebase';
import { getPartnersLoginPath } from '../navigation/navigation.partners';
import {
  FCC,
  HeaderProps,
} from '../../../types/common';

const PartnersHeader: FCC<HeaderProps> = ({
  isMenuOpened,
  sx,
  onToggleMenuOpen,
}) => {
  const { t } = useTranslation(['settings']);
  const navigate = useNavigate();

  const [signOut] = useGetSignOut(getActiveAuth());

  const handleLogout = () => {
    signOut()
      .then(() => navigate(getPartnersLoginPath()));
  };

  return (
    <HIDHeader
      RightComponent={
        <HIDButton
          size="small"
          onClick={handleLogout}
        >
          {t('settings:settings_logout')}
        </HIDButton>
      }
      isMenuOpened={isMenuOpened}
      sx={sx}
      onToggleMenuOpen={onToggleMenuOpen}
    />
  );
};

export default PartnersHeader;
