import React from 'react';
import {
  Collapse,
  Fade,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  COLLAPSED_MENU_WIDTH,
  HEADER_HEIGHT,
  MENU_WIDTH,
} from '../../../constants/layout';
import HIDLogo from '../../../components/HIDLogo';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import {
  FCC,
  MenuProps,
} from '../../../types/common';
import MenuItem from '../../Property/pages/Home/components/MenuItem';
import HIDButton from '../../../components/buttons/HIDButton';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import { getPartnersHomePath } from '../navigation/navigation.partners';
import Forum from '../../../components/icons/Forum';
import { getPartnersForumPath } from '../../Forum/navigation/navigation.forum';

type HomeMenuItem = {
  Icon: React.ElementType;
  label: string;
  path?: string;
  action?: () => void;
  count?: number;
};

type UseGetMenuItems = () => Array<HomeMenuItem>;

const useGetMenuItems: UseGetMenuItems = () => {
  const { t } = useTranslation(['home']);

  return [
    {
      Icon: Forum,
      label: t('home:home_menu_forum_label'),
      path: getPartnersForumPath(),
    },
  ];
};

const PartnersMenu: FCC<MenuProps> = ({
  open,
  sx,
  onToggleOpen,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const animationDuration = 600;

  const { isDownMd } = useBreakPointsSizes();

  const navigateToHome = () => navigate(getPartnersHomePath());

  const menuItems = useGetMenuItems();

  return (
    <Collapse
      collapsedSize={isDownMd ? 0 : COLLAPSED_MENU_WIDTH}
      in={open}
      key={isDownMd.toString()}
      orientation="horizontal"
      sx={{
        ...sx,
        flexShrink: 0,
        height: '100%',
        backgroundColor: theme.palette.common.white,
        top: isDownMd ? HEADER_HEIGHT : 0,
        position: isDownMd ? 'absolute' : 'unset',
        borderRightColor: theme.palette.grey[200],
        borderRightStyle: 'solid',
        borderRightWidth: isDownMd ? 0 : 1,
      }}
    >
      <Stack
        sx={{
          width: MENU_WIDTH,
          height: '100%',
        }}
      >
        <Fade unmountOnExit in={open} timeout={animationDuration}>
          <Stack
            flex={1}
            justifyContent="space-between"
            sx={{ height: '100%' }}
          >
            <Stack
              spacing={0.5}
              sx={{
                padding: theme.spacing(2),
                minHeight: 0,
                overflow: 'auto',
              }}
            >
              <HIDLogo
                style={{
                  marginLeft: theme.spacing(0.5),
                  marginBottom: theme.spacing(2.5),
                }}
                onClick={navigateToHome}
              />
              {
                menuItems
                  .map(({
                    Icon,
                    label,
                    path,
                    action,
                    count,
                  }) => (
                    <MenuItem
                      Icon={Icon}
                      count={count}
                      key={label}
                      label={label}
                      onClick={() => {
                        if (action) {
                          action();
                        } else if (path) {
                          navigate(path);
                        }

                        onToggleOpen(!open);
                      }}
                    />
                  ))
              }
            </Stack>
            <Stack
              sx={{
                alignItems: 'flex-start',
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                borderTopColor: theme.palette.grey[200],
                borderTopStyle: 'solid',
                borderTopWidth: 1,
              }}
            >
              <HIDButton
                fullWidth
                Icon={ChevronLeft}
                color="secondary"
                sx={{
                  alignSelf: 'stretch',
                  width: `calc(${MENU_WIDTH}px - ${theme.spacing(4)})`,
                  height: '40px',
                }}
                onClick={() => onToggleOpen(!open)}
              >
                {t('common:close')}
              </HIDButton>
            </Stack>
          </Stack>
        </Fade>
        <Fade
          unmountOnExit
          in={!open}
          timeout={animationDuration}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              display: isDownMd ? 'none' : 'flex',
              height: '100%',
            }}
          >
            <Stack
              spacing={0.5}
              sx={{
                alignItems: 'flex-start',
                minHeight: 0,
                padding: theme.spacing(2),
                overflow: 'auto',
              }}
            >
              <HIDLogo
                size="small"
                style={{
                  marginLeft: theme.spacing(0.5),
                  marginBottom: theme.spacing(2.5),
                }}
                onClick={navigateToHome}
              />
              {
                menuItems
                  .map(({
                    Icon,
                    count,
                    label,
                    action,
                    path,
                  }) => (
                    <HIDIconButton
                      Icon={Icon}
                      badgeColor="warning"
                      badgeCount={count}
                      key={label}
                      title={label}
                      titlePlacement="right"
                      onClick={() => {
                        if (action) {
                          action();
                        } else if (path) {
                          navigate(path);
                        }
                      }}
                    />
                  ))
              }
            </Stack>
            <Stack
              sx={{
                alignItems: 'flex-start',
                width: open ? MENU_WIDTH : COLLAPSED_MENU_WIDTH,
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                position: 'relative',
                borderColor: theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: '1px 1px 0 0',
              }}
            >
              <HIDIconButton
                Icon={ChevronRight}
                color="secondary"
                title={t('common:close')}
                titlePlacement="right"
                onClick={() => onToggleOpen(!open)}
              />
            </Stack>
          </Stack>
        </Fade>
      </Stack>
    </Collapse>
  );
};

export default PartnersMenu;
