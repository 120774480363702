import React, {
  useEffect,
} from 'react';
import {
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import RouteNames from '../../../../routes/RouteNames';
import {
  useGetTokenDataQuery,
} from '../../../Auth/api/api.user';
import { getActiveAuth } from '../../../../external-services/firebase';

const Home = () => {
  const navigate = useNavigate();
  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({});

  useEffect(() => {
    if (!tokenData?.properties?.length || !currentUser) {
      navigate(RouteNames.INIT);
    }
  }, [tokenData, currentUser]);

  return <Outlet />;
};

export default Home;
