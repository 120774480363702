import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
  Menu,
} from '@mui/material';
import { Check } from '@mui/icons-material';

import StyledMenuItem from '../StyledMenuItem';
import { HIDListItemProps } from '../../types/common';

export type ContextMenuListItem = HIDListItemProps;

type ContextMenuListItemProps = {
  Button: FC<{ open: boolean, onClick: (event: React.MouseEvent<HTMLElement>) => void }>;
  listItems: Array<ContextMenuListItem>;
};

const ContextMenu: FC<ContextMenuListItemProps> = ({
  Button,
  listItems,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleToggleMenuVisible = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (id: string, onClick?: (id: string) => void) => {
    handleClose();
    onClick?.(id);
  };

  return (
    <>
      <Button open={open} onClick={handleToggleMenuVisible} />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        sx={{
          marginTop: theme.spacing(1),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {
          listItems
            .map((menuItem: ContextMenuListItem, index: number) => (
              <StyledMenuItem
                key={menuItem.id}
                sx={index === listItems.length - 1 ? { border: 'none' } : undefined}
                onClick={() => handleClick(menuItem.id, menuItem.onClick)}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  flexGrow={1}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Typography variant="body1">{menuItem.label}</Typography>
                  {menuItem.Icon && (
                    <menuItem.Icon />
                  )}
                  {menuItem.selected && (
                    <Check color="primary" />
                  )}
                </Stack>
              </StyledMenuItem>
            ))
        }
      </Menu>
    </>
  );
};

export default ContextMenu;
