import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import {
  ExternalMediaType,
  Product,
} from '@house-id/houseid-types/dist/content/product';

const getMediaTypeCount = (product: Product | undefined, mediaType: ExternalMediaType) => product
  ?.externalMedia
  ?.filter((media) => media.type === mediaType)?.length || 0;

const ExternalMediaText: FC<{ product?: Product, updatedProduct: Product }> = ({ product, updatedProduct }) => {
  const theme = useTheme();
  const { t } = useTranslation(['products', 'entities']);

  const items = [
    {
      show: !(product?.descriptions?.length) && Boolean(updatedProduct.descriptions?.length),
      label: t('products:product_description'),
    },
    {
      show: !(product?.specification?.length) && Boolean(updatedProduct.specification?.length),
      label: t('products:specification'),
    },
    {
      count: getMediaTypeCount(updatedProduct, ExternalMediaType.IMAGE) - getMediaTypeCount(product, ExternalMediaType.IMAGE),
      label: t('entities:photo'),
    },
    {
      count: getMediaTypeCount(updatedProduct, ExternalMediaType.DOCUMENT) - getMediaTypeCount(product, ExternalMediaType.DOCUMENT),
      label: t('entities:document'),
    },
  ]
    .filter((item) => item.show || (item.count && item.count > 0));

  if (!items.length) {
    return null;
  }

  return (
    <>
      <span>
        {t('products:we_matched')}:
      </span>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingLeft: theme.spacing(3) }}>
        {
          items.map((item, index) => (
            <li key={index}>
              {`${item.label}${item.count ? ` (${item.count})` : ''}`}
            </li>
          ))
        }
      </ul>
    </>
  );
};

export default ExternalMediaText;
