import { useFormik } from 'formik';
import React, {
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { useUpdateEffect } from 'usehooks-ts';
import { Grid } from '@mui/material';
import * as R from 'ramda';

import { DynamicInputFormFieldTypes } from '../../constants/dynamicInputFormFieldTypes';
import {
  DynamicInputCommonField,
  DynamicInputField,
  ValidationDataType,
} from '../../types/types.dynamicContent';
import { renderElementByType } from './services/inputFormElementsRenderer';
import {
  useGetValidationDataTypeSchemas,
  getDynamicInputFieldsData,
} from '../../constants/dynamicInputsValidationTypes';
import { FCCProps } from '../../../../../../../../types/common';

const ExcludeInitValuesComponentTypes = [
  DynamicInputFormFieldTypes.EXTERNAL_SERVICE_INPUT,
  DynamicInputFormFieldTypes.LIST,
  DynamicInputFormFieldTypes.TEXT,
  DynamicInputFormFieldTypes.FACTS_CONTAINER,
  DynamicInputFormFieldTypes.FACTS_GROUP,
  DynamicInputFormFieldTypes.FACT,
  DynamicInputFormFieldTypes.ADDRESS,
  DynamicInputFormFieldTypes.GEO_POINT,
];

const getFields = (fields: Array<DynamicInputCommonField> = []): Array<DynamicInputField> => fields
  .flatMap(
    (field: DynamicInputField) => field.components?.length
      ? getFields(field.components)
      : [field],
  );

const useGetValidationSchema = (fields: Array<DynamicInputField>) => {
  const validationDataTypeSchemas = useGetValidationDataTypeSchemas();

  if (!fields?.length || !validationDataTypeSchemas) {
    return Yup.object();
  }

  const fieldsValidationArray = getFields(fields)
    .map(({ id, validationType, mandatory }: DynamicInputCommonField) => {
      const currentValidationType: ValidationDataType = validationType || ValidationDataType.STRING;

      const provider = validationDataTypeSchemas[currentValidationType] || validationDataTypeSchemas[ValidationDataType.STRING];

      return [
        [id],
        provider({ mandatory: Boolean(mandatory) }),
      ];
    });

  return Yup.object().shape(Object.fromEntries(fieldsValidationArray));
};

export const getDynamicFormInitialValues = (fields: Array<DynamicInputCommonField>) =>
  getFields(fields)
    .filter((field: DynamicInputField) => !ExcludeInitValuesComponentTypes.includes(field.type as DynamicInputFormFieldTypes))
    .reduce(
      (acc, { id, value }: { id: string; value?: unknown }) => {
        acc[id] = value !== null ? value : undefined;
        return acc;
      },
      {} as Record<string, any>,
    );

export const getDynamicComponentFormEditableValues = (fields: Array<DynamicInputField>, values: Record<string, any>) =>
  getFields(fields)
    .filter((field: DynamicInputField) =>
      !ExcludeInitValuesComponentTypes.includes(field.type as DynamicInputFormFieldTypes) && !field.readonly)
    .reduce(
      (acc: Record<string, unknown>, { id }) => {
        acc[id] = R.isNil(values[id]) ? undefined : values[id];
        return acc;
      },
      {} as Record<string, unknown>,
    );

const getInitValues = (
  newFieldsValuesKeys: string[],
  newFieldsValues: Record<string, unknown>,
  formikValues: Record<string, unknown>,
) => R.pipe(
  R.map((key: string) => [
    key,
    formikValues[key] || newFieldsValues[key],
  ] as [string, string]),
  R.fromPairs,
)(newFieldsValuesKeys);

type DynamicContentInputFormProps<TDynamicInputField extends DynamicInputField> = FCCProps<{
  fields: Array<TDynamicInputField>,
  enableReinitializeValues?: boolean;
  validateOnMount?: boolean;
  onValuesChanged?: (values: object) => void;
  onIsValidChanged?: (valid: boolean) => void;
  onReload?: () => void;
}>;

const DynamicContentInputForm = <TDynamicInputField extends DynamicInputField>({
  fields,
  validateOnMount = false,
  enableReinitializeValues = true,
  sx,
  onValuesChanged,
  onIsValidChanged,
  onReload,
}: React.PropsWithChildren<DynamicContentInputFormProps<TDynamicInputField>>) => {
  const validationSchema = useGetValidationSchema(fields);
  const [initValues, setInitValues] = useState(getDynamicFormInitialValues(fields));
  const [forceReloadValues, setForceReloadValues] = useState(false);

  const handleReload = () => {
    if (onReload) {
      onReload();
      setForceReloadValues(true);
    }
  };

  const formik = useFormik<Record<string, unknown>>({
    initialValues: initValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount,
    validationSchema,
    onSubmit: () => { },
  });

  useUpdateEffect(() => {
    if (enableReinitializeValues || forceReloadValues) {
      setInitValues(getDynamicFormInitialValues(fields));
      setForceReloadValues(false);
    } else {
      const formikValues = formik.values;
      const newFieldsValues: Record<string, unknown> = getDynamicFormInitialValues(fields);

      const newFieldsValuesKeys = Object.keys(newFieldsValues);
      if (!R.equals(Object.keys(formikValues).sort(), newFieldsValuesKeys.sort())) {
        setInitValues(getInitValues(newFieldsValuesKeys, newFieldsValues, formikValues));
      }
    }
  }, [fields, forceReloadValues]);

  useEffect(() => {
    if (onValuesChanged) {
      const editableFieldsValues = getDynamicComponentFormEditableValues(fields, formik.values);

      const valuesChanges = getDynamicInputFieldsData(getFields(fields), editableFieldsValues);

      onValuesChanged(valuesChanges);
    }
  }, [formik.values, onValuesChanged]);

  useEffect(() => {
    if (onIsValidChanged) {
      onIsValidChanged(formik.isValid);
    }
  }, [formik.isValid, onIsValidChanged]);

  return (
    <Grid
      container
      rowSpacing={2}
      sx={sx}
      xxs={12}
    >
      {fields.map((field, index) => (
        <Grid item key={`${field.id} + ${index}`} xxs={12}>
          {
            renderElementByType(field, {
              index,
              formik,
              isLastElement: fields.length === index + 1,
              onReload: handleReload,
            })
          }
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicContentInputForm;
