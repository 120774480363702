import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

export type ServerValidationMessage = {
  [key: string]: string;
  fallback: string;
};

export type ServerErrorWithMessageCode = {
  code: string;
  param: string;
};

export type CreateUpdateUserServerError = {
  error: Array<ServerErrorWithMessageCode>;
};

type UseGetAuthErrorMessage = (error?: FetchBaseQueryError | SerializedError | CreateUpdateUserServerError) => Record<string, string>;
// TODO: fix error type as for now it is a Array<ServerErrorWithMessageCode>
const useGetBackendErrorMessage: UseGetAuthErrorMessage = (error) => {
  const { t } = useTranslation(['forms_common', 'auth_error_messages']);

  const backendErrors: Record<string, ServerValidationMessage> = {
    phoneNumber: {
      fallback: t('auth_error_messages:auth/invalid-phone-number'),
      phonenumber_already_exists: t('auth_error_messages:auth/phone-number-already-exists'),
    },
    email: {
      email_already_exists: t('auth_error_messages:auth/email-already-in-use'),
      fallback: t('auth_error_messages:auth/email-already-in-use'),
    },
    givenName: { fallback: t('forms_common:field_mandatory') },
    surname: { fallback: t('forms_common:field_mandatory') },
  };

  // @ts-ignore
  // eslint-disable-next-line
  const backendFieldsErrors: Array<ServerErrorWithMessageCode> = error?.errors || [];

  return backendFieldsErrors.reduce((prev, curr) => {
    const field = backendErrors[curr.param];
    if (curr.code && field) {
      // eslint-disable-next-line no-param-reassign
      prev[curr.param] = curr.code in field ? field[curr.code] : field.fallback;
    }
    return prev;
  }, {} as Record<string, string>);
};

export default useGetBackendErrorMessage;
