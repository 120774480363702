import React, {
  FC,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ArrowForward } from '@mui/icons-material';

import { HomeIcon } from '../../../../../components/icons/Icons';
import { hidSpacing } from '../../../../../utils/number';
import { getPropertyProgressPath } from '../../PropertyProgress/navigation.propertyProgress';
import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../../../pages/PropertyHome/utils/useGetWidgetHeight';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';

const SellingPropertyOverviewWidget: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['selling_property', 'property']);
  const theme = useTheme();

  const { data: propertyId } = useGetCurrentPropertyId();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.PROPERTY_PROGRESS);

  const handleClick = () => propertyId ? navigate(getPropertyProgressPath({ propertyId })) : undefined;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1.5, 2.5, 2.5, 2.5),
        height: widgetHeight || '100%',
        minHeight: 215,
      }}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          height: '100%',
        }}
      >
        <Typography variant="h5">
          {t('property:property_progress_overview_title')}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2.5}
        >
          <Stack
            alignSelf="flex-start"
            style={{
              padding: hidSpacing(3),
              borderRadius: hidSpacing(5),
              backgroundColor: theme.palette.primary.lighter,
            }}
          >
            <HomeIcon
              iconColor={theme.palette.primary.dark}
              size={hidSpacing(4)}
            />
          </Stack>
          <Typography variant="body1">
            {t('selling_property:selling_property_my_property_description')}
          </Typography>
        </Stack>
        <HIDButton
          Icon={ArrowForward}
          onClick={handleClick}
        >
          {t('selling_property:selling_property_progress_overview_button_label')}
        </HIDButton>
      </Stack>
    </Card>
  );
};

export default SellingPropertyOverviewWidget;
