import React, { FC } from 'react';

import PropertyExternalDocumentList from './PropertyExternalDocumentList';
import {
  PropertyProgressCategorySectionProps,
  PropertyProspectLinksSectionData,
} from '../types.propertyProgress';
import PropertyProgressCategorySectionContainer from './PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from './PropertyProgressCategorySectionContent';

const PropertyProspectLinksSection: FC<PropertyProgressCategorySectionProps<PropertyProspectLinksSectionData>> = ({
  section,
}) => (
  <PropertyProgressCategorySectionContainer
    Content={
      <PropertyProgressCategorySectionContent>
        <PropertyExternalDocumentList
          externalDocuments={section.data.links}
        />
      </PropertyProgressCategorySectionContent>
    }
    section={section}
  />
);
export default PropertyProspectLinksSection;
