import { useCallback } from 'react';

import useGetPropertyPermissions from './useGetPropertyPermissions';
import PropertyPermission from '../constants/constants.propertyPermissions';

type UsePropertyPermissions = (options?: { skip?: boolean }) => (permissions: Array<PropertyPermission> | undefined) => boolean;

const usePropertyPermissions: UsePropertyPermissions = (options) => {
  const { data: { permissions } = {} } = useGetPropertyPermissions(undefined, options);

  const hasPermissions = useCallback((certainPermissions: Array<PropertyPermission> | undefined) => {
    if (!certainPermissions) {
      return true;
    }

    return certainPermissions.every((p) => (permissions || []).includes(p));
  }, [permissions]);

  return hasPermissions;
};

export default usePropertyPermissions;
