import React from 'react';
import { FCC } from '../../types/common';

const HorizontalImagePlaceholder: FCC = ({ style }) => (
  <svg
    fill="currentColor"
    style={{
      width: 'inherit',
      visibility: 'visible',
      ...style,
    }}
    viewBox="0 0 205 150"
  >
    <path d="M 5.336 0 L 199.656 0 C 201.058 0.056 202.531 0.683 203.449 1.475 C 204.294 2.331 204.939 3.679 205 4.997 L 205 18.26 C 205.001 18.773 205 19.294 205 19.818 L 205 144.996 C 204.943 146.302 204.312 147.681 203.437 148.535 C 202.527 149.353 201.064 149.946 199.666 150 L 5.344 150 C 3.943 149.946 2.469 149.317 1.549 148.523 C 0.707 147.669 0.062 146.321 0 145.004 L 0 5.005 C 0.06 3.691 0.732 2.31 1.577 1.451 C 2.489 0.661 3.929 0.058 5.336 0 M 44.992 72.39 C 49.002 68.477 56.009 68.477 60.018 72.39 L 89.312 95.252 L 125.308 45.214 C 129.458 41.169 136.183 41.169 140.333 45.214 L 197.915 104.718 L 197.915 21.315 C 197.915 14.058 192.786 8.154 186.481 8.154 L 18.522 8.154 C 12.217 8.154 7.088 14.058 7.088 21.315 L 7.088 105.623 L 44.992 72.39 M 136.329 58.045 C 134.938 56.697 132.711 56.697 131.32 58.045 L 93.83 110.524 C 92.438 111.875 90.21 111.878 88.815 110.531 L 55.009 85.222 C 53.598 83.924 51.411 83.924 50.002 85.222 L 7.088 123.343 L 7.088 128.678 C 7.088 135.942 12.217 141.846 18.522 141.846 L 186.474 141.846 C 192.786 141.846 197.915 135.942 197.915 128.686 L 197.915 119.457 L 136.329 58.045 M 76.092 51.363 C 68.278 51.363 61.923 45.162 61.923 37.536 C 61.923 29.911 68.278 23.71 76.092 23.71 C 83.907 23.71 90.261 29.911 90.261 37.536 C 90.261 45.162 83.907 51.363 76.092 51.363 M 76.092 30.623 C 72.189 30.623 69.008 33.728 69.008 37.536 C 69.008 41.346 72.189 44.45 76.092 44.45 C 79.996 44.45 83.178 41.346 83.178 37.536 C 83.178 33.728 79.996 30.623 76.092 30.623" />
  </svg>
);

export default HorizontalImagePlaceholder;
