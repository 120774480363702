import { HIDBlob } from '@house-id/houseid-types/dist/common';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

export enum SuggestionType {
  MISSING_PRICE = 'missing_price',
  MISSING_FILE = 'missing_file',
  MISSING_TYPE = 'missing_type',
  MISSING_PLACE = 'missing_place',
  MISSING_PURCHASE_DATE = 'missing_purchase_date',
  MISSING_LIFETIME = 'missing_lifetime',
  MISSING_WARRANTY = 'missing_warranty',
  MISSING_BRAND = 'missing_brand',
  MISSING_AMOUNT = 'missing_amount',
  MISSING_INVOICE_NUMBER = 'missing_invoice_number',
  MISSING_DATE = 'missing_date',
  MISSING_CLASSIFICATION_TYPE = 'missing_classification_type',
  NOT_SAVED_ATTACHMENTS = 'not_saved_attachments',
  ADD_SUGGESTED_EXPENSE = 'add_suggested_expense',
  CONNECT_SERVICE = 'connect_service',
}

export type EntityTypeSuggestions = Partial<Record<SuggestionType, { muted?: boolean }>>;

export type SuggestionsSettingsConfiguration = Record<EntityType, EntityTypeSuggestions>;

export type SuggestionsSettings = {
  suggestionsEnabled: boolean;
  configuration: SuggestionsSettingsConfiguration;
};

export type Suggestion = {
  entityType: EntityType;
  entityId: string;
  suggestions: Array<string>;
  entity: {
    id: string;
    name: string;
    blobs: Array<HIDBlob>;
    externalMainImage?: { url: string, thumbnailUrl: string }
  },
};

export type SuggestionsFilters = Record<EntityType, {
  suggestionTypes: Record<string, object>;
}>;

export type SuggestionsSummary = Record<EntityType, {
  count: number;
  entityCount: number;
  muted: boolean;
  suggestions: Record<SuggestionType, number>
}>;
