import React, {
  FC,
  KeyboardEvent,
  useState,
  FormEvent,
  FocusEvent,
  useCallback,
  useEffect,
} from 'react';
import {
  Input,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { VERIFICATION_CODE_LENGTH } from '../constants.auth';

const VerificationInput = styled(Input)(({ theme }) => ({
  width: '2rem',
  fontSize: '1.4rem',
  fontWeight: '600',
  color: theme.palette.primary.main,
  input: { textAlign: 'center' },
  // hide arrows
  appearance: 'textfield',
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
}));

type InputOrNull = HTMLInputElement | null;

type ConfirmCodeInputProps = {
  isValid?: boolean;
  isDisabled?: boolean;
  length?: number;
  value?: string;
  onChange: (value?: string) => void;
};

const ConfirmCodeInput: FC<ConfirmCodeInputProps> = ({
  isValid,
  isDisabled,
  length = VERIFICATION_CODE_LENGTH,
  value,
  onChange,
}) => {
  const theme = useTheme();

  const [code, setCode] = useState<string[]>(value && value.length === length ? value.split('') : Array(length).fill(''));

  const handleUpdate = useCallback(
    (index: number, val: string) => setCode(
      (prevState) => {
        const slice = prevState.slice();
        slice[index] = val;
        return slice;
      },
    ),
    [],
  );

  useEffect(() => {
    if (code) {
      onChange(code.join(''));
    }
  }, [code]);

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    const index = Number.parseInt(evt.currentTarget.dataset.index as string, 10);

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput: InputOrNull = document.querySelector(`.input-${prevIndex}`);
    const nextInput: InputOrNull = document.querySelector(`.input-${nextIndex}`);

    if (evt.key === 'Backspace') {
      if (code[index]) {
        handleUpdate(index, '');
      } else if (prevInput) {
        prevInput.select();
      }
    } else if (evt.key === 'ArrowRight') {
      evt.preventDefault();
      if (nextInput) {
        nextInput.focus();
      }
    } else if (evt.key === 'ArrowLeft') {
      evt.preventDefault();
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleChange = (evt: FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    const index = Number.parseInt(evt.currentTarget.dataset.index as string, 10);

    let nextIndex = index + 1;
    let nextInput: InputOrNull = document.querySelector(`.input-${nextIndex}`);

    handleUpdate(index, value[0] || '');

    if (value.length === 1) {
      nextInput?.focus();
    } else if (index < length - 1) {
      const split = value.slice(index + 1, length).split('');
      split.forEach((val) => {
        handleUpdate(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = document.querySelector(`.input-${nextIndex}`);
      });
    }
  };

  const handleFocus = (evt: FocusEvent<HTMLInputElement>) => evt.currentTarget.select();

  return (
    <Stack
      border="none"
      component="fieldset"
      direction="row"
      justifyContent="center"
      spacing={1.2}
    >
      {code.map((value, index) => (
        <VerificationInput
          disabled={isDisabled}
          error={isValid === false}
          inputProps={{
            type: 'number',
            className: `input-${index}`,
            'aria-label': `Number ${index + 1}`,
            'data-index': index,
            pattern: '[0-9]*',
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            onFocus: handleFocus,
          }}
          key={index}
          sx={{ backgroundColor: value ? theme.palette.grey[100] : theme.palette.primary.lighter }}
          value={value}
        />
      ))}
    </Stack>
  );
};

export default ConfirmCodeInput;
