import {
  PaymentMonth,
} from '@house-id/houseid-types/dist/finances/budgets';

import { range } from '../../../../../../../../../utils/array';

const getPaymentMonthsForQuarter = (amount: number | undefined, firstPaymentMonth: number): Array<PaymentMonth<number | undefined>> =>
  range(firstPaymentMonth, 12, 3).map((month) => ({ month, amount }));

export default getPaymentMonthsForQuarter;
