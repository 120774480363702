import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Receipt: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="M19.9961 1L16.8 2L13.6 1L10.4015 2L7.19999 1L4 2V23L7.19999 22L10.4 23L13.6 22L16.8 23L20 22L19.9961 1Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8 8H16"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75 * strokeWidth}
      />
      <path
        d="M8 12H16"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75 * strokeWidth}
      />
      <path
        d="M8 16H14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75 * strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Receipt;
