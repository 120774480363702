import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyStageType } from '@house-id/houseid-types/dist/property';

import {
  MenuGroupId,
  MenuId,
} from '../../../types/types.menu';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyStatusQuery } from '../../../api/api.property';
import { useNavigateToMove } from '../../../navigation/navigation.property';
import Folder from '../../../../../components/icons/Folder';
import Layers from '../../../../../components/icons/Layers';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import { getContentFilesPath } from '../../../modules/Content/modules/ContentFile/navigation.contentFile';
import { getDocumentCategoriesPath } from '../../../modules/Content/modules/Document/navigation.document';
import { getReceiptCategoriesPath } from '../../../modules/Content/modules/Receipt/navigation.receipt';
import Receipt from '../../../../../components/icons/Receipt';
import Product from '../../../../../components/icons/Product';
import { getProductsPath } from '../../../modules/Content/modules/Product/navigation.product';
import { getPhotosPath } from '../../../modules/Content/modules/Photo/navigation.photo';
import Note from '../../../../../components/icons/Note';
import { getNotesPath } from '../../../modules/Content/modules/Note/navigation.note';
import { getTimelinePath } from '../../../modules/Content/modules/Timeline/navigation.timeline';
import Timeline from '../../../../../components/icons/Timeline';
import Suggestion from '../../../../../components/icons/Suggestion';
import { getSuggestionCategoriesPath } from '../../../modules/Content/modules/Suggestion/navigation.suggestion';
import {
  Bonus,
  Document,
  Forum,
  Image,
  PieChart,
  Truck,
} from '../../../../../components/icons/Icons';
import { getForumPath } from '../../../../Forum/navigation/navigation.forum';
import { getBonusOffersPath } from '../../../modules/BonusOffers/navigation.bonusOffers';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import { getFinancesPath } from '../../../modules/Finances/navigation.finances';
import HandHome from '../../../../../components/icons/HandHome';

type MenuIListItem = {
  id: MenuId;
  Icon: React.ElementType;
  label: string;
  count?: number;
  path?: string;
  onClick?: () => void;
};

type MenuGroup = {
  id: MenuGroupId;
  Icon: React.ElementType;
  label: string;
  items: Array<MenuIListItem>
};

const useGetMenu = () => {
  const { t } = useTranslation(['menu', 'home']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { stage } = {} } = useGetCurrentProperty();
  const { data: propertyStatus } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);
  const navigateToMove = useNavigateToMove();

  const { data: { canUpdate, canCreate } = {} } = useGetPropertyPermissions();

  const menuGroups: Array<MenuGroup> = [
    // {
    //   id: MenuGroupId.PROPERTY_STRUCTURE,
    //   Icon: Plans,
    //   label: t('menu:housing_structure_and_facts'),
    //   items:
    //     [
    //       {
    //         id: MenuId.PROPERTY_STRUCTURE,
    //         label: t('menu:overview_structure_and_facts'),
    //         Icon: HomeIcon,
    //       },
    //       {
    //         id: MenuId.BUILDINGS,
    //         label: t('menu:buildings'),
    //         Icon: getEntityInfo(EntityType.BUILDING)?.Icon,
    //       },
    //       {
    //         id: MenuId.ROOMS,
    //         label: t('menu:rooms'),
    //         Icon: getEntityInfo(EntityType.ROOM)?.Icon,
    //       },
    //       {
    //         id: MenuId.BUILDING_PARTS,
    //         label: t('menu:building_parts'),
    //         Icon: getEntityInfo(EntityType.BUILDING_PART)?.Icon,
    //       },
    //       {
    //         id: MenuId.OUTDOORS,
    //         label: t('menu:land_shares'),
    //         Icon: getEntityInfo(EntityType.OUTDOOR)?.Icon,
    //       },
    //     ].filter(Boolean),
    // },
    {
      id: MenuGroupId.CONTENT,
      Icon: Folder,
      label: t('menu:housing_archive'),
      items:
        [
          {
            id: MenuId.UNSORTED_FILES,
            label: t('menu:unsorted_files'),
            Icon: Layers,
            count: propertyStatus?.files,
            path: getPathWithPropertyIdOrInit(getContentFilesPath, { propertyId }),
          },
          {
            id: MenuId.DOCUMENTS,
            label: t('menu:document'),
            Icon: Document,
            path: getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }),
          },
          {
            id: MenuId.RECEIPTS,
            label: t('menu:receipts'),
            Icon: Receipt,
            path: getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }),
          },
          {
            id: MenuId.PRODUCTS,
            label: t('menu:products_and_materials'),
            Icon: Product,
            path: getPathWithPropertyIdOrInit(getProductsPath, { propertyId }),
          },
          {
            id: MenuId.PICTURES,
            label: t('menu:image_gallery'),
            Icon: Image,
            path: getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }),
          },
          {
            id: MenuId.NOTES,
            label: t('menu:note'),
            Icon: Note,
            path: getPathWithPropertyIdOrInit(getNotesPath, { propertyId }),
          },
          {
            id: MenuId.TIMELINE,
            label: t('menu:timeline'),
            Icon: Timeline,
            path: getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }),
          },
        ].filter(Boolean),
    },
    {
      id: MenuGroupId.HOUSE_SUPPORT,
      Icon: HandHome,
      label: t('menu:housing_support'),
      items:
        [
          canUpdate && {
            id: MenuId.SUGGESTIONS,
            label: t('menu:smart_suggestions'),
            Icon: Suggestion,
            count: propertyStatus?.suggestions,
            path: getPathWithPropertyIdOrInit(getSuggestionCategoriesPath, { propertyId }),
          },
          // {
          //   id: MenuId.TASKS,
          //   label: t('menu:to_do_and_maintenance'),
          //   Icon: Task,
          // },
          {
            id: MenuId.FINANCE,
            label: t('menu:financial_overview'),
            Icon: PieChart,
            path: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          },
          // {
          //   id: MenuId.INSURANCE,
          //   label: t('menu:insurance_overview'),
          //   Icon: Insurance,
          // },
          canUpdate && canCreate && {
            id: MenuId.FORUM,
            label: t('menu:forum'),
            Icon: Forum,
            path: getForumPath(),
          },
          // {
          //   id: MenuId.CONTACTS,
          //   label: t('menu:editorial_content'),
          //   Icon: ContactsIcon,
          // },
          // {
          //   id: MenuId.EXPERTS,
          //   label: t('menu:services_and_expert_help'),
          //   Icon: Star,
          // },
          stage !== PropertyStageType.PREVIOUS_OWNED && {
            id: MenuId.MOVE,
            label: t('home:home_menu_move'),
            Icon: Truck,
            onClick: () => navigateToMove(),
          },
          {
            id: MenuId.BONUSES,
            label: t('menu:offer_and_bonus_points'),
            Icon: Bonus,
            path: getPathWithPropertyIdOrInit(getBonusOffersPath, { propertyId }),
          },
        ].filter(Boolean),
    },
    // {
    //   id: MenuGroupId.SETTINGS,
    //   Icon: Settings,
    //   label: t('menu:settings_and_support'),
    //   items:
    //     [
    //       {
    //         id: MenuId.PROPERTY_SETTINGS,
    //         label: t('menu:the_homes_settings'),
    //         Icon: HomeSettings,
    //       },
    //       {
    //         id: MenuId.PERSONAL_SETTINGS,
    //         label: t('menu:personal_settings'),
    //         Icon: PersonOutline,
    //       },
    //       {
    //         id: MenuId.SUPPORT,
    //         label: t('menu:help'),
    //         Icon: Support,
    //       },
    //     ].filter(Boolean),
    // },
  ];

  return menuGroups;
};

export default useGetMenu;
