import React from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import InviteCode from '../pages/InviteCode';
import UserSettings from '../pages/userSettings/UserSettings';
import Feedback from '../pages/Feedback';
import ResetPassword from '../pages/ResetPassword';
import UserDetails from '../pages/UserDetails';
import StoreLinks from '../pages/storeLinks/StoreLinks';

const OnboardingRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.INVITE_CODE,
    element: (
      <ProtectedRoute
        path={RouteNames.INVITE_CODE}
        propertyChangePath={RouteNames.INVITE_CODE}
        propertyRequired={false}
      >
        <InviteCode />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.USER_SETTINGS_ONBOARDING,
    element: (
      <ProtectedRoute
        path={RouteNames.USER_SETTINGS_ONBOARDING}
        propertyRequired={false}
      >
        <UserSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FEEDBACK_ONBOARDING,
    element: (
      <ProtectedRoute
        path={RouteNames.FEEDBACK_ONBOARDING}
        propertyRequired={false}
      >
        <Feedback />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.RESET_PASSWORD_ONBOARDING,
    element: (
      <ProtectedRoute
        path={RouteNames.RESET_PASSWORD_ONBOARDING}
        propertyRequired={false}
      >
        <ResetPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.USER_DETAILS_ONBOARDING,
    element: (
      <ProtectedRoute
        path={RouteNames.USER_DETAILS_ONBOARDING}
        propertyRequired={false}
      >
        <UserDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.ONBOARDING,
    element: (
      <ProtectedRoute
        path={RouteNames.ONBOARDING}
        propertyRequired={false}
      >
        <StoreLinks />
      </ProtectedRoute>
    ),
  },
];

export default OnboardingRouteConfig;
