import React, { FC } from 'react';
import {
  Card,
  useTheme,
} from '@mui/material';

import { Suggestion } from '../types.suggestion';
import SuggestionsList from './SuggestionsList';

type EntitySuggestionSectionProps = {
  suggestion: Suggestion;
  loading: boolean;
  onFixSuggestion?: (suggestionKey: string) => void;
};

const EntitySuggestionSection: FC<EntitySuggestionSectionProps> = ({
  suggestion,
  onFixSuggestion,
}) => {
  const theme = useTheme();

  if (!suggestion.suggestions.length) {
    return null;
  }

  return (
    <Card
      style={{
        borderRadius: 8,
        background: theme.palette.warning.lightest,
        padding: theme.spacing(2.5),
      }}
    >
      <SuggestionsList
        entityId={suggestion.entityId}
        entityType={suggestion.entityType}
        mode="small"
        suggestions={suggestion.suggestions}
        onFixSuggestion={onFixSuggestion}
      />
    </Card>
  );
};

export default EntitySuggestionSection;
