import * as R from 'ramda';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const getElementByIndex = R.curry((index, array) => R.nth(index, array));

type ArrToMap = <T, K>(mapper: (item: T) => [key: string, value: K], arr: Array<T>) => Record<string, K>;
export const arrToMap: ArrToMap = (mapper, arr) => R.compose(R.fromPairs, R.map(mapper))(arr);

export const range = (start: number, end: number, step = 1) => {
  const validStep = step === 0 ? 1 : step;
  const length = Math.floor(Math.abs((end - start) / validStep) + 1);
  const stepSign = start <= end ? 1 : -1;
  return new Array(length)
    .fill(0)
    .map((_, index) => start + index * Math.abs(validStep) * stepSign);
};

export const removeByIndex = <T>(index: number, array: Array<T>) => R.remove(index, 1, array);

export const getElementBeforeLast = <T>(array: Array<T>) => R.nth(-2, array);
