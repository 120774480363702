import { BudgetTemplateCategoryId } from '@house-id/houseid-types/dist/finances/budgets';

export const getCategoryDataPath = (categoryId: BudgetTemplateCategoryId) => `categories.${categoryId}`;

export const getSubcategoriesDataPath = (categoryId: BudgetTemplateCategoryId) => `${getCategoryDataPath(categoryId)}.subcategories`;

export const getSubcategoryDataPath = (categoryId: BudgetTemplateCategoryId, index: number) =>
  `${getSubcategoriesDataPath(categoryId)}.${index}`;

export const getPaymentMonthsDataPath = (categoryId: BudgetTemplateCategoryId, index: number) =>
  `${getSubcategoriesDataPath(categoryId)}.${index}.paymentMonths`;

export const getMortgagesDataPath = (categoryId: BudgetTemplateCategoryId, index: number) =>
  `${getSubcategoriesDataPath(categoryId)}.${index}.mortgages`;
