import { FileMimeType } from '../../constants/mimeTypes';

export const getAcceptMimeTypeMap = (mimeTypes: Array<FileMimeType>) =>
  mimeTypes
    .reduce(
      (acc, mimeType) => {
        acc[mimeType] = [];
        return acc;
      },
      {} as Record<FileMimeType, Array<string>>,
    );
