import React, {
  FC,
  useMemo,
} from 'react';
import * as R from 'ramda';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Remove,
  PersonOff,
} from '@mui/icons-material';

import HIDAvatar from '../../../components/HIDAvatar';
import HIDSelectItem from '../../../components/HIDSelectItem';
import {
  useCreatePropertyUserRemovalRequestsMutation,
  useGetPropertyUserRemovalRequestsQuery,
} from '../api/api.property';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import { useGetCurrentUserQuery } from '../../Auth/api/api.user';
import useGetIsCurrentUserPropertyOwner from '../hooks/useGetIsCurrentUserPropertyOwner';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import MembersLoaderSkeleton from '../../../components/skeletonLoaders/MembersLoaderSkeleton';
import { PropertyUser } from '../../../types/common';

type PropertyUsersSectionProps = {
  propertyId?: string;
  loading?: boolean;
  users: Array<PropertyUser>;
};

const PropertyUsersSection: FC<PropertyUsersSectionProps> = ({
  loading,
  propertyId,
  users,
}) => {
  const { t } = useTranslation(['property', 'common']);

  const { data: userRemovalRequests = [] } = useGetPropertyUserRemovalRequestsQuery(propertyId ? { propertyId } : skipToken);

  const { data: currentUser } = useGetCurrentUserQuery();

  const isCurrentUserOwner = useGetIsCurrentUserPropertyOwner({ propertyId });

  const [createUserRemovalRequest, { isLoading: isUserRemovalRequestCreating }] = useCreatePropertyUserRemovalRequestsMutation();

  const pendingRemovalRequestIds = userRemovalRequests?.filter((r) => r.state === 'pending').map((r) => r.userId);

  const [openRemoveUserDialog] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const handleRemoveUser = (userId: string, userName: string) => {
    openRemoveUserDialog({
      title: t('property:property_remove_user_title', { userName }),
      description: t('property:property_remove_user_description', { userName }),
      loading: isUserRemovalRequestCreating,
      yesTitle: t('common:delete_label'),
      onYes: () => {
        if (propertyId) {
          createUserRemovalRequest({ propertyId, userId });
        }
      },
      onNo: () => { },
    });
  };

  const mappedUsers = useMemo(() => users.map((user) => ({
    id: user.id,
    Icon: () => (
      <HIDAvatar
        blob={R.head(user.blobs)}
        givenName={user.name}
        size="medium"
        surname={user.surname}
      />
    ),
    disabled: true,
    RightIconComponent: isCurrentUserOwner && currentUser?.id !== user.id
      ? pendingRemovalRequestIds?.includes(user.id)
        ? (
          <HIDIconButton
            Icon={PersonOff}
            title={t('property:user_removal_request_pending')}
            onClick={() => { }}
          />
        )
        : (
          <HIDIconButton
            Icon={Remove}
            title={t('property:create_user_removal_request')}
            onClick={() => handleRemoveUser(user.id, user.name)}
          />
        )
      : null,
    label: currentUser?.id === user.id ? `${user.name} (${t('common:you')})` : user.name,
  })), [users, userRemovalRequests]);

  return (
    <Stack>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        {t('property:property_users')}
      </Typography>
      <MembersLoaderSkeleton isLoading={loading}>
        <Stack>
          {mappedUsers.map((user, index) => (
            <HIDSelectItem
              Icon={user.Icon}
              RightIconComponent={user.RightIconComponent}
              key={user.id}
              label={user.label}
              sx={{ marginBottom: index !== mappedUsers.length - 1 ? 0.5 : 0 }}
            />
          ))}
        </Stack>
      </MembersLoaderSkeleton>
    </Stack>
  );
};

export default PropertyUsersSection;
