import qs from 'query-string';

import { HIDApiCacheTimeouts } from '../../../api/HIDApiCacheTimeouts';
import { HIDApiTags } from '../../../api/HIDApiTags';
import {
  SubscriptionSharingActionItem,
  CurrentSubscriptionPlan,
  SubscriptionSharingMember,
  SubscriptionHistoryItem,
  SubscriptionInfo,
  SubscriptionPlan,
  SubscriptionPlanOffer,
  SubscriptionPlanProviderId,
  SubscriptionOrder,
} from '../types.subscriptionPlans';
import {
  userCommonApi,
} from '../../Auth/api/api.user.common';
import { authApi } from '../../Auth/api/api.auth';

type QuestionnaireOptions = Array<{ id: string; text: string; }>;

type BuySubscriptionRequest = {
  subscription: string;
  offer?: string;
  successRedirectUrl: string;
  failRedirectUrl: string;
};

type BuySubscriptionResponse = {
  status: 'ORDER_CREATED' | 'CHANGED';
  mandateUrl?: string;
  order: SubscriptionOrder
};

export const subscriptionPlansApi = userCommonApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubscriptionPlan: builder.query<CurrentSubscriptionPlan, void>({
      query: () => ({
        url: 'users/me/subscriptions/plan',
        method: 'GET',
      }),
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_120,
      providesTags: [HIDApiTags.SUBSCRIPTION_PLAN_STATUS],
    }),
    getSubscription: builder.query<SubscriptionInfo, void>({
      query: () => ({
        url: '/users/me/subscriptions',
        method: 'GET',
      }),
      providesTags: [HIDApiTags.SUBSCRIPTION],
    }),
    getSubscriptionPlanOffers: builder.query<Array<SubscriptionPlanOffer>, void>({
      query: () => ({
        url: `users/me/subscriptions/plans?${qs.stringify({
          // test-1-month-trial,test-3-month-trial,test-6-month-trial,test-half-price, test-12-month-trial-half-price-for-life
          // offerId: 'test-12-month-trial-half-price-for-life',
          provider: SubscriptionPlanProviderId.MDD,
        })}`,
        method: 'GET',
      }),
      transformResponse: (result: { plans: Array<SubscriptionPlanOffer> }) => result.plans,
      providesTags: [HIDApiTags.SUBSCRIPTION_PLAN_OFFER],
    }),
    getSubscriptionOrder: builder.query<SubscriptionOrder, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `${SubscriptionPlanProviderId.MDD}/subscription/order/${orderId}`,
        method: 'GET',
      }),
    }),
    buySubscription: builder.mutation<BuySubscriptionResponse, BuySubscriptionRequest>({
      query: ({
        successRedirectUrl,
        failRedirectUrl,
        subscription,
        offer,
      }) => ({
        url: `${SubscriptionPlanProviderId.MDD}/subscription?${qs.stringify({ successRedirectUrl, failRedirectUrl })}`,
        method: 'PUT',
        body: { subscription, offer },
      }),
      invalidatesTags: (result) => result?.mandateUrl
        ? []
        : [
          HIDApiTags.SUBSCRIPTION_PLAN_OFFER,
          HIDApiTags.SUBSCRIPTION_PLAN_STATUS,
          HIDApiTags.SUBSCRIPTION,
          HIDApiTags.SUBSCRIPTION_MEMBERS,
        ],
    }),
    applyPromoCode: builder.mutation<void, { code: string }>({
      query: ({ code }) => ({
        url: '/users/me/subscriptions/voucher/_redeem',
        method: 'POST',
        body: { code },
      }),
      invalidatesTags: () => [
        HIDApiTags.SUBSCRIPTION_PLAN_OFFER,
      ],
    }),
    getSharingSubscriptionMembers: builder.query<Array<SubscriptionSharingMember>, void>({
      query: () => ({
        url: '/users/me/subscriptions/sharing',
        method: 'GET',
      }),
      providesTags: [HIDApiTags.SUBSCRIPTION_MEMBERS],
    }),
    updateSharingSubscriptionMembers: builder.mutation<void, Array<SubscriptionSharingActionItem>>({
      query: (actions) => ({
        url: '/users/me/subscriptions/sharing',
        method: 'PATCH',
        body: { actions },
      }),
      invalidatesTags: () => [HIDApiTags.SUBSCRIPTION_MEMBERS],
    }),
    getSubscriptionPlanPaymentsHistory: builder.query<Array<SubscriptionHistoryItem>, void>({
      query: () => '/users/me/subscriptions/receipts',
      transformResponse: (response: { receipts: Array<SubscriptionHistoryItem> }) => response.receipts,
    }),
    getQuestionnaireOptions: builder.query<QuestionnaireOptions, { lang: string }>({
      query: (params) => ({
        url: `/subscriptions/cancellation-reasons?${qs.stringify(params)}`,
        method: 'GET',
      }),
      transformResponse: (result: { reasons: QuestionnaireOptions }) => result.reasons,
    }), // /users/me/subscriptions/mdd
    cancelCurrentMddSubscription: builder.mutation<void, { reasonId: string }>({
      query: (params) => ({
        url: `/users/me/subscriptions/mdd?${qs.stringify(params)}`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: () => [
        HIDApiTags.SUBSCRIPTION_PLAN_OFFER,
        HIDApiTags.SUBSCRIPTION_PLAN_STATUS,
        HIDApiTags.SUBSCRIPTION,
        HIDApiTags.SUBSCRIPTION_MEMBERS,
      ],
    }),
  }),
});

export const subscriptionPlansNoAuthApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPlans: builder.query<Array<SubscriptionPlan>, void>({
      query: () => ({
        url: 'subscriptions/plans',
        method: 'GET',
      }),
      transformResponse: (result: { plans: Array<SubscriptionPlan> }) => result.plans,
    }),
  }),
});

export const {
  useGetCurrentSubscriptionPlanQuery,
  useLazyGetCurrentSubscriptionPlanQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionPlanOffersQuery,
  useLazyGetSubscriptionPlanOffersQuery,
  useGetSubscriptionOrderQuery,
  useBuySubscriptionMutation,
  useGetSharingSubscriptionMembersQuery,
  useUpdateSharingSubscriptionMembersMutation,
  useGetSubscriptionPlanPaymentsHistoryQuery,
  useGetQuestionnaireOptionsQuery,
  useCancelCurrentMddSubscriptionMutation,
  useApplyPromoCodeMutation,
} = subscriptionPlansApi;

export const {
  useGetSubscriptionPlansQuery,
} = subscriptionPlansNoAuthApi;
