import React from 'react';
import {
  ImageList,
} from '@mui/material';
import * as R from 'ramda';

import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { hidSpacing } from '../../../../../../../utils/number';
import { FCC } from '../../../../../../../types/common';

const DEFAULT_IMAGES_PER_ROW = 5;

const PhotosListLayoutWrapper: FCC<{ children: React.ReactNode[] }> = ({
  children,
}) => {
  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const columnsCount = R.cond([
    [() => isDownXs, R.always(1)],
    [() => isDownSm, R.always(2)],
    [() => isDownMd, R.always(3)],
    [() => isDownLg, R.always(3)],
    [() => isDownXl, R.always(4)],
  ])();

  return (
    <ImageList
      cols={columnsCount || DEFAULT_IMAGES_PER_ROW}
      gap={hidSpacing(2)}
      variant="standard"
    >
      {children}
    </ImageList>
  );
};

export default PhotosListLayoutWrapper;
