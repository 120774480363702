import {
  FormikErrors,
} from 'formik';
import * as R from 'ramda';
import {
  BudgetTemplateCategoryId,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import useGetEnum from '../../../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../../../types/common';

const isObject = (obj: unknown) => Boolean(obj) && typeof obj === 'object' && !Array.isArray(obj);

type NestedObject<T> = T extends object ? { [K in keyof T]: NestedObject<T[K]> | Array<T[K] | undefined> | T[K] } : T;

const getErrorFieldPath = <TValue extends object>(obj: NestedObject<TValue>, initialPath?: string): string | undefined => {
  if (R.isEmpty(obj)) {
    return initialPath;
  }

  const fieldNames = Object.keys(obj);
  const fieldName = fieldNames[0];
  const fieldValue = obj[fieldName as keyof typeof obj];

  if (fieldValue && Array.isArray(fieldValue) && !R.isEmpty(fieldValue)) {
    const index = fieldValue.findIndex((item) => Boolean(item));
    const arrayItem = fieldValue[index];
    const arrayItemPath = initialPath ? `${initialPath}.${fieldName}.${index}` : `${fieldName}.${index}`;

    return isObject(arrayItem) ? getErrorFieldPath(arrayItem as NestedObject<TValue>, arrayItemPath) : arrayItemPath;
  }

  const fieldPath = initialPath ? `${initialPath}.${fieldName}` : fieldName;
  return isObject(fieldValue) ? getErrorFieldPath(fieldValue as NestedObject<TValue>, fieldPath) : fieldPath;
};

export type ErrorFieldInfo = {
  categoryId?: BudgetTemplateCategoryId;
  errorFieldPath: string;
};

type UseGetFormikErrorFieldPath = () => (errors: FormikErrors<CreateUpdateBudgetValues>) => ErrorFieldInfo | null;

const useGetFormikErrorFieldPath: UseGetFormikErrorFieldPath = () => {
  const { data: ExpensePaymentCategoryEnum } = useGetEnum(EnumType.ExpensePaymentCategory);

  const getFirstFieldPathWithError = (errors: FormikErrors<CreateUpdateBudgetValues>) => {
    const categoryNames = ExpensePaymentCategoryEnum || {};

    const firstCategoryId = R.head(
      Object.keys(errors.categories || {})
        .filter(Boolean)
        .sort((a, b) => categoryNames[a].localeCompare(categoryNames[b])),
    ) as BudgetTemplateCategoryId | undefined;

    const filteredErrors = {
      ...errors,
      categories: errors.categories && firstCategoryId
        ? { [firstCategoryId]: errors.categories[firstCategoryId] }
        : undefined,
    };

    const errorFieldPath = getErrorFieldPath(filteredErrors);

    return errorFieldPath
      ? {
        categoryId: firstCategoryId,
        errorFieldPath,
      }
      : null;
  };

  return getFirstFieldPathWithError;
};

export default useGetFormikErrorFieldPath;
