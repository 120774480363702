import {
  Stack,
  Skeleton,
  Box,
  Theme,
} from '@mui/material';
import React, { FC } from 'react';

import { getTypographyHeight } from '../../../../../../../../../utils/style';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';

type TimelineSkeletonSectionProps = {
  isLast?: boolean;
  theme: Theme;
};

const TimelineSkeletonSection: FC<TimelineSkeletonSectionProps> = ({
  isLast = false,
  theme,
}) => {
  const { isDownSm, isDownXs } = useBreakPointsSizes();
  const circularSkeletonSize = 35;
  const dateSkeletonHeight = getTypographyHeight(theme.typography.body2);

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      gap={isDownSm ? 2 : 3}
      justifyContent="center"
      sx={{
        height: isLast ? 'auto' : { xxs: '100px', xs: '140px', md: '240px' },
        width: '100%',
      }}
    >
      {!isDownSm && (
        <Skeleton
          height={dateSkeletonHeight}
          sx={{ marginTop: `${(circularSkeletonSize - dateSkeletonHeight) / 2}px` }}
          variant="rounded"
          width="25%"
        />
      )}
      <Stack
        alignItems="center"
        direction="column"
        height="inherit"
        spacing={isDownXs ? 1 : 2}
      >
        <Skeleton
          height={circularSkeletonSize}
          variant="circular"
          width={circularSkeletonSize}
        />
        {!isLast && (
          <Skeleton
            height={`calc(100% - (${theme.spacing(isDownXs ? 1 : 2)} + ${circularSkeletonSize * 1.4}px))`}
            variant="rounded"
            width={15}
          />
        )}
      </Stack>
      <Box
        sx={{
          padding: isDownXs ? 0.5 : 1.5,
          borderRadius: theme.spacing(1.25),
          border: `1px solid ${theme.palette.grey[300]}`,
          width: '100%',
        }}
      >
        <Stack direction="column" spacing={isDownSm ? 0.4 : 1} width="100%">
          <Skeleton variant="text" width="35%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="50%" />
        </Stack>
      </Box>
    </Stack>
  );
};

export default TimelineSkeletonSection;
