import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Upload: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M16.0054 16L12.0054 12M12.0054 12L8.00537 16M12.0054 12V21M20.3953 18.39C21.3707 17.8583 22.1412 17.0169 22.5852 15.9986C23.0292 14.9804 23.1215 13.8432 22.8475 12.7667C22.5735 11.6901 21.9488 10.7355 21.072 10.0534C20.1952 9.37137 19.1162 9.00072 18.0053 8.99999H16.7453C16.4426 7.82923 15.8785 6.74232 15.0953 5.82098C14.3121 4.89964 13.3302 4.16784 12.2234 3.6806C11.1167 3.19335 9.91388 2.96335 8.70545 3.00787C7.49701 3.0524 6.3144 3.37029 5.24651 3.93765C4.17862 4.50502 3.25324 5.30709 2.53995 6.28357C1.82666 7.26005 1.34402 8.38553 1.12831 9.57538C0.912602 10.7653 0.969437 11.9885 1.29454 13.1533C1.61965 14.318 2.20457 15.3939 3.00533 16.3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Upload;
