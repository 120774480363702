import React from 'react';
import {
  Skeleton,
  useTheme,
  Stack,
  Box,
} from '@mui/material';

import * as R from 'ramda';

import { FCC } from '../../../../../../../types/common';

type MessagesLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
};

const MessagesLoaderSkeleton: FCC<MessagesLoaderSkeletonProps> = ({
  itemsCount = 5,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={1}>
        {R.range(0, itemsCount).map((i) => (
          <Box
            height={100}
            key={i}
            sx={{
              backgroundColor: theme.palette.primary.lightest,
              padding: theme.spacing(1.5, 1.5, 1.5, 3),
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="90%" />
          </Box>
        ))}
      </Stack>
    );
  }

  return children;
};

export default MessagesLoaderSkeleton;
