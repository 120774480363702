import React from 'react';
import { Stack } from '@mui/material';

import {
  FCC,
  Icon,
} from '../types/common';
import Picture from './icons/Picture';
import { hidSpacing } from '../utils/number';

type DefaultImageProps = {
  size?: number;
  iconSize?: number;
  iconColor?: string;
  CustomIcon?: Icon
  onClick?: () => void;
};

const DefaultImage: FCC<DefaultImageProps> = ({
  size = hidSpacing(8),
  iconSize = hidSpacing(4),
  iconColor,
  CustomIcon,
  sx,
  onClick,
}) => {
  const IconPlaceholder = CustomIcon || Picture;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        ...sx,
      }}
      onClick={onClick}
    >
      <IconPlaceholder iconColor={iconColor} size={iconSize} />
    </Stack>
  );
};

export default DefaultImage;
