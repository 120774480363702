import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Blueprints: Icon = ({
  iconColor: colorProp,
  size,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>

      <path
        d="M5 5H23V22C23 22.5523 22.5523 23 22 23H5"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
      <path
        d="M14 20H8V8H20V20H18.8182"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 8L12 14"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 18L12 20"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 13H14"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M18 13H20"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M1 19V4.81818C1 2.70946 2.79086 1 5 1V7"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
      <path d="M5 23C2.79086 23 1 21.2763 1 19.15C1 17.3528 2.2794 15.8432 4.0094 15.419C4.54579 15.2874 5 14.8523 5 14.3V5" stroke={color} strokeWidth="1.8" />
    </HIDSvgIcon>
  );
};

export default Blueprints;
