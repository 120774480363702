import React, { FC } from 'react';

import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { DynamicContentAddButtonProps } from '../../../types/types.dynamicContent';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import useNavigateFromLink from '../../../../../../../hooks/useNavigateFromLink';

const DynamicContentAddButton: FC<DynamicContentAddButtonProps> = ({
  title,
  link,
}) => {
  const handleAddLinkPress = useNavigateFromLink(link);

  return (
    <Stack direction="row" justifyContent="flex-end">
      <HIDButton
        Icon={Add}
        onClick={handleAddLinkPress}
      >
        {title}
      </HIDButton>
    </Stack>
  );
};
export default DynamicContentAddButton;
