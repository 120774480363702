import { Reducer } from '@reduxjs/toolkit';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

const LayoutReducerActionTypes = {
  TOGGLE_MENU_OPEN: 'LAYOUT/TOGGLE_MENU_OPEN',
  TOGGLE_SIDE_DRAWER_OPEN: 'LAYOUT/TOGGLE_SIDE_DRAWER_OPEN',
  SEARCH_VISIBILITY: 'LAYOUT/SEARCH_VISIBILITY',
  RESET_STATE: 'LAYOUT/RESET_STATE',
} as const;

export const toggleMenuOpen = (isMenuOpened: boolean) => ({
  type: LayoutReducerActionTypes.TOGGLE_MENU_OPEN,
  isMenuOpened,
});

export const toggleSideDrawerOpen = (isOpenSideDrawer: boolean) => ({
  type: LayoutReducerActionTypes.TOGGLE_SIDE_DRAWER_OPEN,
  isOpenSideDrawer,
});

export const toggleSearchVisibility = (payload: {
  isFocusedSearch?: boolean;
  isVisibleSearch?: boolean;
  entityType?: EntityType;
}) => ({
  type: LayoutReducerActionTypes.SEARCH_VISIBILITY,
  payload,
});

export const resetLayoutState = () => ({ type: LayoutReducerActionTypes.RESET_STATE });

type Actions =
  | ReturnType<typeof toggleMenuOpen>
  | ReturnType<typeof toggleSideDrawerOpen>
  | ReturnType<typeof toggleSearchVisibility>
  | ReturnType<typeof resetLayoutState>;

type LayoutReducerState = {
  isMenuOpened: boolean;
  isOpenSideDrawer: boolean;
  isVisibleSearch: boolean;
  isFocusedSearch: boolean;
  entityType?: EntityType;
};

const initialState: LayoutReducerState = {
  isMenuOpened: false,
  isOpenSideDrawer: false,
  isVisibleSearch: false,
  isFocusedSearch: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const layoutReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case LayoutReducerActionTypes.TOGGLE_MENU_OPEN:
      return {
        ...state,
        isMenuOpened: action.isMenuOpened,
      };

    case LayoutReducerActionTypes.TOGGLE_SIDE_DRAWER_OPEN:
      return {
        ...state,
        isOpenSideDrawer: action.isOpenSideDrawer,
      };

    case LayoutReducerActionTypes.SEARCH_VISIBILITY:
      return {
        ...state,
        ...action.payload,
      };

    case LayoutReducerActionTypes.RESET_STATE:
      return { ...initialState };

    default: return state;
  }
};

export default layoutReducer as Reducer<LayoutReducerState>;
