import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import {
  Property,
  PropertyStageType,
} from '@house-id/houseid-types/dist/property';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';

type UseGetSellingPropertyStageBasedText = (params: {
  property: Property | undefined,
  includeDate?: boolean
}) => {
  label: string;
  title: string;
  description: string;
};

const useGetSellingPropertyStageBasedText: UseGetSellingPropertyStageBasedText = (params) => {
  const { t } = useTranslation(['forms_common', 'selling_property']);

  const { property } = params;
  const propertyStage = property?.stage;
  const transitionDate = property?.saleInformation?.transitionDate;

  return match(propertyStage)
    .with(PropertyStageType.SELLING, () => ({
      label: t('selling_property:selling_property_progress_property_for_sale_modal_title'),
      title: t('selling_property:selling_property_progress_property_for_sale_modal_title'),
      description: t('selling_property:selling_property_progress_property_for_sale_modal_description'),
    }))
    .with(PropertyStageType.SOLD, () => ({
      label: params?.includeDate && transitionDate !== undefined
        ? t(
          'selling_property:selling_property_progress_property_sold_with_date_modal_title',
          { date: formatDate(new Date(transitionDate), DateTimeFormats.DATE_ONLY) },
        )
        : t('selling_property:selling_property_progress_property_sold_modal_title'),
      title: t('selling_property:selling_property_progress_property_sold_modal_title'),
      description: t('selling_property:selling_property_progress_property_sold_modal_description'),
    }))
    .with(PropertyStageType.PREVIOUS_OWNED, () => ({
      label: t('selling_property:selling_property_progress_previously_owned_property_label'),
      title: t('selling_property:selling_property_progress_previously_owned_property_modal_title'),
      description: t('selling_property:selling_property_progress_previously_owned_property_modal_description'),
    }))
    .otherwise(() => ({
      label: '',
      title: '',
      description: '',
    }));
};

export default useGetSellingPropertyStageBasedText;
