import React, { FC } from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import {
  CheckMarkRect,
  HomeIcon,
  Lock,
  Shield,
  Star,
} from '../../../components/icons/Icons';
import LoginLayout from './LoginLayout';
import signUpImageUrl from '../../../assets/images/sign_up.jpg';
import { hidSpacing } from '../../../utils/number';
import BankID from '../../../components/icons/BankID';
import HandOver from '../../../components/icons/HandOver';
import HIDLink from '../../../components/HIDLink';
import HIDLogoIcon from '../../../components/icons/HIDLogoIcon';
import { HOUSE_ID_EMAIL } from '../../../constants/links';

const Section = styled(Stack)(({ theme }) => ({
  borderRadius: 5,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.grey[300],
  padding: theme.spacing(4),
}));

const IconContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.primary.lighter,
}));

const About: FC = () => {
  const theme = useTheme();

  const { t } = useTranslation(['auth']);

  const infoList = [
    {
      icon: (
        <IconContainer>
          <HomeIcon fillColor={theme.palette.common.transparent} iconColor={theme.palette.primary.main} size={hidSpacing(4)} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_this_is_house_id'),
      description: t('auth:about_house_id_facts_about_home_description'),
    },
    {
      icon: <BankID size={hidSpacing(8)} />,
      title: t('auth:about_house_id_bank_id_title'),
      description: t('auth:about_house_id_bank_id_description'),
    },
    {
      icon: (
        <IconContainer>
          <HandOver iconColor={theme.palette.primary.main} size={hidSpacing(4)} strokeWidth={2} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_promise_title'),
      description: t('auth:about_house_id_promise_description_part1'),
      extraContent: (
        <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'center' }} variant="body1">
          <ReactMarkdown components={{ p: 'span' }}>
            {t('auth:about_house_id_promise_description_part2')}
          </ReactMarkdown>&nbsp;
          <HIDLink
            href={`mailto:${HOUSE_ID_EMAIL}`}
            label={HOUSE_ID_EMAIL}
            underline="none"
          />
        </Typography>
      ),
    },
    {
      icon: (
        <IconContainer>
          <Star iconColor={theme.palette.primary.main} size={hidSpacing(4)} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_purpose_title'),
      description: t('auth:about_house_id_purpose_description'),
    },
    {
      icon: (
        <IconContainer>
          <Lock iconColor={theme.palette.primary.main} size={hidSpacing(4)} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_data_security_title'),
      description: t('auth:about_house_id_data_security_description'),
    },
    {
      icon: (
        <IconContainer>
          <Shield iconColor={theme.palette.primary.main} size={hidSpacing(4)} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_data_and_privacy_title'),
      description: t('auth:about_house_id_data_and_privacy_description'),
    },
    {
      icon: (
        <IconContainer>
          <CheckMarkRect iconColor={theme.palette.primary.main} size={hidSpacing(4)} />
        </IconContainer>
      ),
      title: t('auth:about_house_id_maintenance_title'),
      description: t('auth:about_house_id_maintenance_description'),
    },
  ];

  const RightContent = (
    <Stack spacing={2.5}>
      {infoList.map(
        (
          {
            icon,
            title,
            description,
            extraContent,
          },
          index,
        ) => (
          <Section alignItems="center" key={index} spacing={2}>
            {icon}
            <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
              {title}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'center' }} variant="body1">
              <ReactMarkdown components={{ p: 'div' }}>
                {description}
              </ReactMarkdown>
            </Typography>
            {extraContent}
          </Section>
        ),
      )}
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: 4,
        }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 4,
            width: theme.spacing(6),
            height: theme.spacing(6),
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.spacing(1.25),
          }}
        >
          <HIDLogoIcon iconColor={theme.palette.common.white} />
        </Stack>
        <HIDLink
          href={`mailto:${HOUSE_ID_EMAIL}`}
          label={HOUSE_ID_EMAIL}
          sx={{ marginTop: 2.5 }}
          underline="none"
        />
      </Stack>
    </Stack>
  );

  return (
    <LoginLayout
      RightComponent={RightContent}
      backgroundImageUrl={signUpImageUrl}
      canGoBack={false}
      message={t('auth:sign_up_message')}
      rightTitle={t('auth:about_house_id_title')}
    />
  );
};

export default About;
