import {
  HEADER_BANNER_HEIGHT_SM,
  HEADER_BANNER_HEIGHT_MD,
  COLLAPSED_MENU_WIDTH,
  MENU_WIDTH,
  SIDE_BAR_WIDTH_XL,
  SIDE_BAR_WIDTH_LG,
  SIDE_BAR_WIDTH_MD,
} from '../../../constants/layout';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';

export const useGetMenuWidth = (isMenuOpened: boolean) => {
  const { isDownMd } = useBreakPointsSizes();

  return isDownMd
    ? 0
    : isMenuOpened
      ? MENU_WIDTH
      : COLLAPSED_MENU_WIDTH;
};

export const useGetSideBarWidth = (isMenuOpened: boolean, hasSideBar = true) => {
  const { isDownMd, isDownLg, isDownXl } = useBreakPointsSizes();

  return isDownMd || !hasSideBar
    ? 0
    : isDownLg
      ? isMenuOpened ? 0 : SIDE_BAR_WIDTH_MD
      : isDownXl
        ? isMenuOpened ? SIDE_BAR_WIDTH_MD : SIDE_BAR_WIDTH_LG
        : isMenuOpened ? SIDE_BAR_WIDTH_LG : SIDE_BAR_WIDTH_XL;
};

export const useGetHeaderBannerHeight = () => {
  const { isDownSm } = useBreakPointsSizes();

  return isDownSm ? HEADER_BANNER_HEIGHT_SM : HEADER_BANNER_HEIGHT_MD;
};
