import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
  Menu,
} from '@mui/material';
import { Check } from '@mui/icons-material';

import StyledMenuItem from '../StyledMenuItem';
import HIDIconButton, { HIDIconButtonProps } from '../buttons/HIDIconButton';
import { FCC } from '../../types/common';
import { ContextMenuListItem } from './ContextMenu';
import HIDBadge from '../buttons/HIDBadge';

type ContextMenuListItemProps = Omit<HIDIconButtonProps, 'onClick'> & {
  listItems: Array<ContextMenuListItem>;
};

const HIDIconButtonContextMenu: FCC<ContextMenuListItemProps> = ({
  Icon,
  badgeCount,
  color = 'primary',
  disabled = false,
  iconButtonColor,
  size = 'medium',
  sx,
  style,
  title,
  titlePlacement,
  listItems,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleToggleMenuVisible = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (id: string, onClick: (id: string) => void) => {
    handleClose();
    onClick(id);
  };

  return (
    <>
      <HIDIconButton
        Icon={Icon}
        badgeCount={badgeCount}
        color={color}
        disabled={disabled}
        iconButtonColor={iconButtonColor}
        size={size}
        style={style}
        sx={sx}
        title={title}
        titlePlacement={titlePlacement}
        onClick={handleToggleMenuVisible}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        sx={{
          marginTop: theme.spacing(1),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {
          listItems
            .map((menuItem: ContextMenuListItem, index: number) => (
              <StyledMenuItem
                key={menuItem.id}
                sx={index === listItems.length - 1 ? { border: 'none' } : undefined}
                onClick={() => handleClick(menuItem.id, menuItem.onClick)}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  flexGrow={1}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2.5}
                  >
                    <Typography variant="body1">{menuItem.label}</Typography>
                    {menuItem.count !== undefined && menuItem.count > 0 && (
                      <HIDBadge
                        badgeContent={menuItem.count}
                        color="error"
                      />
                    )}
                  </Stack>
                  {menuItem.Icon && (
                    <menuItem.Icon />
                  )}
                  {menuItem.selected && (
                    <Check color="primary" />
                  )}
                </Stack>
              </StyledMenuItem>
            ))
        }
      </Menu>
    </>
  );
};

export default HIDIconButtonContextMenu;
