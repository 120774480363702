import {
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDialog from '../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../hooks/useDialog/DialogNames';
import useIsSFProperty from '../../../../hooks/useIsSfProperty';
import { ColorfulBannerLayout } from '../../../../components/ColorfulBannerLayout';
import { UnavailableFeature } from '../../../../components/dialogs/OpenMobileAppDialog';

export const BoughtSfPropertyYearAgoBanner = () => {
  const { t } = useTranslation(['property']);
  const theme = useTheme();
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const isSfProperty = useIsSFProperty();

  return !isSfProperty
    ? (
      <ColorfulBannerLayout
        hideButtonIcon
        buttonBgHoverColor={theme.palette.secondary.light}
        buttonLabel={t('property:property_bought_sf_property_year_ago_banner_action')}
        cardBgColor={theme.palette.common.premium}
        description={t('property:property_bought_sf_property_year_ago_banner_description')}
        title={t('property:property_bought_sf_property_year_ago_banner_title')}
        onClick={() => openMobileAppDialog({ unavailableFeature: UnavailableFeature.SYNC_PROPERTY })}
      />
    )
    : null;
};
