import React from 'react';
import {
  useTheme,
  Box,
  Grid,
  Stack,
  Skeleton,
} from '@mui/material';
import * as R from 'ramda';

import { FCC } from '../../../../../../../types/common';
import VerticalImagePlaceholder from '../../../../../../../components/svg/VerticalImagePlaceholder';

type SearchResultsLoaderSkeletonProps = {
  rowsCount?: number;
  itemsCount?: number;
  isLoading: boolean;
};

const SearchResultsLoaderSkeleton: FCC<SearchResultsLoaderSkeletonProps> = ({
  rowsCount = 2,
  itemsCount = 4,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={3}>
        <Skeleton sx={{ width: 'max(15%, 70px)' }} variant="rounded" />
        {R.range(0, rowsCount).map((i) => (
          <Stack key={i} spacing={1.5}>
            <Skeleton sx={{ width: 'max(35%, 150px)' }} variant="rounded" />
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                columns={{
                  xxs: 1, xs: 4, sm: 9, md: 12, lg: 12,
                }}
                spacing={{ xxs: 1.5, xs: 2, md: 2.5 }}
              >
                {R.range(0, itemsCount).map((j) => (
                  <Grid
                    item
                    key={j}
                    lg={3}
                    md={4}
                    sm={3}
                    xs={2}
                    xxs={1}
                  >
                    <Stack
                      sx={{
                        padding: 1.5,
                        borderRadius: theme.spacing(1.25),
                        border: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Skeleton
                        height="auto"
                        sx={{
                          display: 'flex',
                          color: theme.palette.shadow[100],
                          backgroundColor: 'unset',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        variant="rounded"
                        width="100%"
                      >
                        <VerticalImagePlaceholder
                          style={{
                            maxWidth: '60%',
                          }}
                        />
                      </Skeleton>
                      <Stack alignItems="center" justifyContent="center">
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="50%" />
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        ))}
      </Stack>
    );
  }

  return children;
};

export default SearchResultsLoaderSkeleton;
