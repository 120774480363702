import {
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicContentFieldSource } from '../../../types/types.dynamicContent';
import { FCC } from '../../../../../../../../../types/common';

type DynamicContentSourceProps = {
  source?: DynamicContentFieldSource;
  variant?: TypographyProps['variant']
};

const DynamicContentSource: FCC<DynamicContentSourceProps> = ({
  source,
  variant = 'body2',
}) => {
  const { t } = useTranslation(['dynamic_content']);
  const theme = useTheme();

  return source?.name
    ? (
      <Typography color={theme.palette.grey[500]} variant={variant}>
        {t('dynamic_content:dynamic_content_information_taken_from_source', { source: source?.name })}
      </Typography>
    )
    : null;
};
export default DynamicContentSource;
