import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import useContentSliderContext from './useContentSliderContext';

const ContentSliderNavigation: FC = () => {
  const theme = useTheme();

  const {
    totalSlidesNum,
    activeSlideIndex,
    onChangeSlide,
  } = useContentSliderContext();

  return totalSlidesNum > 1
    ? (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={0}
      >
        {R.range(0, totalSlidesNum).map((slideIndex) => (
          <div
            key={slideIndex}
            style={{
              width: theme.spacing(1),
              height: theme.spacing(1),
              borderRadius: theme.spacing(1),
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: theme.palette.common.transparent,
              backgroundClip: 'content-box',
              backgroundColor: slideIndex === activeSlideIndex ? theme.palette.primary.main : theme.palette.grey[300],
              boxSizing: 'content-box',
              cursor: 'pointer',
            }}
            onClick={() => onChangeSlide(slideIndex)}
          />
        ))}
      </Stack>
    )
    : null;
};

export default ContentSliderNavigation;
