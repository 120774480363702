import { UtmParameters } from './types/types.auth';

export const HOUSE_ID_UTM_SOURCE = 'houseid.app';

export const DEFAULT_UTM_PARAMS: UtmParameters = {
  utm_source: HOUSE_ID_UTM_SOURCE,
  utm_medium: 'default',
};

export const ENROLL_SECOND_FACTOR_URL_PARAM = 'enrollSecondFactor';

export const VERIFICATION_CODE_LENGTH = 6;
