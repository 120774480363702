import React from 'react';
import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../types/common';

type ManageExpertThreadsProps = {
  showNotAnsweredOnly: boolean;
  onShowNotAnsweredOnlyChange: (value: boolean) => void;
};

const ManageExpertThreads: FCC<ManageExpertThreadsProps> = ({
  showNotAnsweredOnly,
  sx,
  onShowNotAnsweredOnlyChange,
}) => {
  const { t } = useTranslation(['common', 'forum']);

  return (
    <Stack spacing={1} sx={sx}>
      <Typography variant="h6">
        {t('common:manage')}
      </Typography>
      <FormControlLabel
        control={<Switch checked={showNotAnsweredOnly} sx={{ marginRight: 2 }} />}
        label={t('forum:forum_not_answered_only')}
        labelPlacement="start"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
        onChange={(_event, checked) => onShowNotAnsweredOnlyChange(checked)}
      />
    </Stack>
  );
};

export default ManageExpertThreads;
