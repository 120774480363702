import { SubscriptionPlanId } from '../../SubscriptionPlans/types.subscriptionPlans';

export type UserStatus = {
  unreadNotifications: Array<{
    propertyId: string;
    unreadCount: number;
  }>;
  notificationCount: number;
  unreadMessages: {
    user: number;
    properties: Array<{ propertyId: string, unreadCount: number }>
  };
  banking: {
    hasBankingData: boolean;
    earliestExpireAt: string;
    userShouldSync: boolean;
    userMustSyncBeforeToNotLooseData: string;
  }
};

export type UtmParameters = {
  utm_campaign?: string;
  utm_source: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_id?: string;
};

export type CreateUser = {
  email: string;
  password: string;
  confirmPassword?: string;
  givenName?: string;
  surname: string;
  phoneNumber?: string;
  utm?: UtmParameters
};

export type CreateUserFormValues = Omit<CreateUser, 'phoneNumber'> & {
  phoneNumber?: {
    value: string;
    numberValue: string;
    nationalNumber: string;
  };
};

export type ConnectUserFormValues = {
  givenName?: string;
  email: string;
  surname: string;
  utm?: UtmParameters
};

export type InitAppRouteState = {
  propertyId?: string;
  withInvitation: boolean;
  from?: {
    pathname: string;
    search: string;
  }
};

export enum UserOrigin {
  HOUSE_ID = 'houseid',
  SVENSKFAST = 'svenskfast',
  IF = 'if',
}

export type UserTokenData = {
  email?: string;
  name?: string;
  isAdmin: boolean;
  admin?: HouseIdUserToken['urn:houseid:admin'];
  properties: Array<string>;
  hasBankIdClaim: boolean;
  bankIdClaim?: {
    personalNumber: string;
  }
  memberships: Array<{
    isOwner?: boolean;
    membershipType?: string;
    expirationTime?: number;
  }>;
  signInProvider: string;
  project: string;
  userId: string;
  partnerId?: string;
  subscriptionPlanId: SubscriptionPlanId;
  firebaseUID: string;
  iat: HouseIdUserToken['iat'];
  firebase: HouseIdUserToken['firebase'];
  auth_time: HouseIdUserToken['auth_time'];
};

export type UserDetails = {
  email: string;
  givenName?: string;
  surname: string;
  phoneNumber?: string;
};

export type UserDetailsForm = Omit<UserDetails, 'phoneNumber'> & {
  phoneNumber?: {
    value?: string;
    numberValue?: string;
    nationalNumber?: string;
  };
};

export type HouseIdUserToken = {
  name: string;
  'urn:houseid:user': {
    id: string;
  };
  houseid_sub: string;
  'urn:houseid:admin'?: {
    personalNumber: string;
    phoneNumber: string;
  };
  'urn:houseid:properties': string[];
  'urn:houseid:bankid'?: {
    personalNumber: string;
  };
  'urn:houseid:properties-memberships': {
    propertyId: string;
    isOwner?: boolean;
    membershipType?: string;
    expirationTime?: number;
  }[];
  consent?: any;
  memberships: Array<{
    type: string;
    startTime: number;
    expirationTime: number;
  }>;
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  partnerId?: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: boolean;
  phone_number: string;
  firebase: {
    identities: {
      'google.com'?: string[];
      email?: string[];
      phone?: string[];
    };
    sign_in_provider: string;
    sign_in_second_factor: string;
  };
};
