import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  PropertyProgressCategorySection,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { FCC } from '../../../../../../../types/common';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { ContentFile } from '../../../../Content/modules/ContentFile/types.contentFile';
import {
  getCreatePhotosPath,
  getPhotosPath,
} from '../../../../Content/modules/Photo/navigation.photo';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import {
  PropertyPhotosSectionData,
} from '../../../types.propertyProgress';
import PropertyBlueprintsImagesSection from './PropertyBlueprintsImagesSection';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type PropertyBlueprintsPhotosSectionProps = {
  section: PropertyProgressSection<PropertyPhotosSectionData>;
};

const PropertyBlueprintsPhotosSection: FCC<PropertyBlueprintsPhotosSectionProps> = ({
  section,
}) => {
  const { t } = useTranslation(['property']);
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { photos = [] } = section.data;
  const blobs = photos.flatMap((photo) => photo.blobs);

  const handleAddImage = () => navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId }));
  const handleViewImages = () => navigate(getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }));

  const handleContentFilesSelected = (files: Array<ContentFile>) =>
    navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId, contentFileIds: files.map((file) => file.id) }));

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: blobs.length > 1 ? 100 : 0,
    categoryId: PropertyProgressCategorySection.BLUEPRINTS,
    sectionId: section.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyBlueprintsImagesSection
          blobs={blobs}
          buttonText={t('property:property_progress_property_blueprints_additional_images_add')}
          description={description}
          onAddImage={handleAddImage}
          onContentFilesSelected={handleContentFilesSelected}
          onViewImages={handleViewImages}
        />
      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyBlueprintsPhotosSection;
