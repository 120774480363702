import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetBonusOfferCategoriesQuery } from '../api/api.bonusOffers';
import { BonusOffersCategoryId } from '../types.bonusOffers';
import { QueryHook } from '../../../../../types/common';

type BonusOffersCategoryAvailability = {
  hasMoveInBonusOffers: boolean;
};

const useGetBonusOffersCategoryAvailability: QueryHook<BonusOffersCategoryAvailability> = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data,
    ...rest
  } = useGetBonusOfferCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const availableBonusOfferCategories = useMemo(() => {
    if (data?.length) {
      const ids = data.map((item) => item.id);

      return {
        hasMoveInBonusOffers: ids.includes(BonusOffersCategoryId.MOVE_IN_SERVICES),
      };
    }

    return {
      hasMoveInBonusOffers: false,
    };
  }, [data]);

  return {
    data: availableBonusOfferCategories,
    ...rest,
  };
};

export default useGetBonusOffersCategoryAvailability;
