import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HIDHome: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M3 10.4554C3 9.52867 3.42833 8.65394 4.1604 8.08565L10.1604 3.42802C11.2429 2.58774 12.7571 2.58774 13.8396 3.42802L19.8396 8.08565C20.5717 8.65394 21 9.52867 21 10.4554V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V10.4554Z"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="12"
        cy="15"
        r="3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="16"
        cy="10"
        fill={color}
        r="1.5"
        stroke="white"
      />
    </HIDSvgIcon>
  );
};

export default HIDHome;
