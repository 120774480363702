import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HomeProfile: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M9.5 22H5.5C4.96957 22 4.46086 21.7893 4.08579 21.4142C3.71071 21.0392 3.5 20.5305 3.5 20V9L12.5 2L21.5 9M9.5 16V11H13"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M23 22.5003V21.3892C23 20.7998 22.763 20.2346 22.341 19.8179C21.919 19.4011 21.3468 19.167 20.75 19.167H16.25C15.6533 19.167 15.081 19.4011 14.659 19.8179C14.2371 20.2346 14 20.7998 14 21.3892V22.5003"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M18.5 16.4444C19.7426 16.4444 20.75 15.4495 20.75 14.2222C20.75 12.9949 19.7426 12 18.5 12C17.2574 12 16.25 12.9949 16.25 14.2222C16.25 15.4495 17.2574 16.4444 18.5 16.4444Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default HomeProfile;
