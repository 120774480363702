import { Product } from '@house-id/houseid-types/dist/content/product';
import { Receipt } from '@house-id/houseid-types/dist/content/receipt';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { HIDEntityId } from '../../../../../types/common';

type InitialDataItem = {
  key: string;
  value?: string;
};

type UseGetAddEntityConnectionInitialData<TSource extends HIDEntityId = HIDEntityId> = (entityType: EntityType) => (
  targetEntityType: EntityType, source: TSource
) => Array<InitialDataItem>;

const useGetAddEntityConnectionInitialData: UseGetAddEntityConnectionInitialData = (entityType) => {
  const entityConnectionsInitialDataMappers: Record<string, Record<string, (source: any) => InitialDataItem[]>> = {
    [EntityType.RECEIPT]: {
      [EntityType.PRODUCT]: (receipt: Receipt) => ([
        {
          key: 'name',
          value: receipt.name,
        },
        {
          key: 'annotation',
          value: receipt.annotation,
        },
        {
          key: 'purchaseDate',
          value: receipt.date?.toString(),
        },
        {
          key: 'place',
          value: receipt.place,
        },
        {
          key: 'price',
          value: receipt.amount?.toString(),
        },
      ]),
    },
    [EntityType.PRODUCT]: {
      [EntityType.RECEIPT]: (product: Product) => ([
        {
          key: 'name',
          value: product.name,
        },
        {
          key: 'annotation',
          value: product.annotation,
        },
        {
          key: 'date',
          value: product.purchaseDate,
        },
        {
          key: 'place',
          value: product.place,
        },
        {
          key: 'amount',
          value: product.price?.toString(),
        },
      ]),
    },
  };

  return (targetEntityType, source) => {
    const sourceEntityTypeMappers = entityConnectionsInitialDataMappers[entityType];
    if (sourceEntityTypeMappers && source) {
      const mapper = sourceEntityTypeMappers[targetEntityType];
      return mapper ? mapper(source) : [];
    }
    return [];
  };
};

export default useGetAddEntityConnectionInitialData;
