import qs from 'query-string';

import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';
import {
  BonusOfferCategory,
  BonusOfferEntity,
  BonusOffersCategoryId,
  BonusOffersLogs,
} from '../types.bonusOffers';
import { PaginationParams } from '../../../../../types/common';
import { HIDApiTags } from '../../../../../api/HIDApiTags';

export type BonusOfferId = { bonusOfferId: string; };

export const bonusOfferApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableBonusOffers: builder.query<Array<BonusOfferEntity>, PropertyId & { category?: BonusOffersCategoryId; }>({
      query: ({ propertyId, category }) => `/properties/${propertyId}/bonus/offers?${qs.stringify({ category })}`,
      providesTags: [HIDApiTags.BONUS_OFFERS],
    }),
    getBonusOfferCategories: builder.query<Array<BonusOfferCategory>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/bonus/offers/categories`,
    }),
    getCheapestBonusOffers: builder.query<Array<BonusOfferEntity>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/bonus/offers?${qs.stringify({ pageSize: 1, orderBy: 'points_asc' })}`,
    }),
    getBonusOffer: builder.query<BonusOfferEntity, PropertyId & BonusOfferId>({
      query: ({ propertyId, bonusOfferId }) => `/properties/${propertyId}/bonus/offers/${bonusOfferId}`,
    }),
    getBonusOffersLogs: builder.query<BonusOffersLogs, PropertyId & Partial<PaginationParams>>({
      query: ({ offset, pageSize, propertyId }) => `/properties/${propertyId}/bonus/history?${qs.stringify({ pageSize, offset })}`,
    }),
    activateBonusOffer: builder.mutation<void, PropertyId & BonusOfferId>({
      query: ({ propertyId, bonusOfferId }) => ({
        url: `/properties/${propertyId}/bonus/offers/${bonusOfferId}/activate`,
        method: 'POST',
      }),
      invalidatesTags: [HIDApiTags.PROPERTY_STATUS, HIDApiTags.BONUS_OFFERS],
    }),
  }),
});

export const {
  useGetAvailableBonusOffersQuery,
  useGetBonusOfferCategoriesQuery,
  useGetCheapestBonusOffersQuery,
  useGetBonusOfferQuery,
  useGetBonusOffersLogsQuery,
  useLazyGetBonusOffersLogsQuery,
  useActivateBonusOfferMutation,
} = bonusOfferApi;
