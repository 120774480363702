import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { Icon } from '../../../../../types/common';
import { hidSpacing } from '../../../../../utils/number';

type FinanceIconProps = {
  Icon: Icon;
  iconColor: string;
  iconBackgroundColor: string;
};

const FinanceIcon: FC<FinanceIconProps> = ({
  Icon,
  iconColor,
  iconBackgroundColor,
}) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      backgroundColor: iconBackgroundColor,
      borderRadius: '50%',
      padding: 2,
    }}
  >
    <Icon iconColor={iconColor} size={hidSpacing(4)} />
  </Stack>
);

export default FinanceIcon;
