import { PartnerTaskGroupDetails } from '@house-id/houseid-types/dist/partners';

import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';

export const enspectaApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getEnspectaPropertyCheckTasks: builder.query<PartnerTaskGroupDetails, PropertyId>({
      query: ({ propertyId }) => `/partner/enspecta/property-check/active?propertyId=${propertyId}`,
      providesTags: [HIDApiTags.PROPERTY_PROGRESS],
    }),
  }),
});

export const {
  useGetEnspectaPropertyCheckTasksQuery,
} = enspectaApi;
