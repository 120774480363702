import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import {
  PropertyProgressCategorySection,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import { getPropertyProgressPath } from '../../../PropertyProgress/navigation.propertyProgress';
import {
  SellingPropertyHandoverReceiptsSectionData,
  SellingPropertyHandoverSectionData,
  SellingPropertyHandoverSectionId,
} from '../../types.sellingProperty';
import {
  PropertyDocumentationData,
  PropertyTimelineSectionData,
  PropertyProgressCategorySectionProps,
  PropertyTasksSectionData,
} from '../../../PropertyProgress/types.propertyProgress';
import PropertyProgressPageContent from '../../../PropertyProgress/components/PropertyProgressPageContent';
import { useGetSellingPropertyTransferQuery } from '../../api/api.sellingProperty';
import SellingPropertyHandoverDocuments from './components/SellingPropertyHandoverDocuments';
import SellingPropertyHandoverTimeline from './components/SellingPropertyHandoverTimeline';
import SellingPropertyHandoverTasks from './components/SellingPropertyHandoverTasks';
import SellingPropertyHandoverReceipts from './components/SellingPropertyHandoverReceipts';
import HIDTypography from '../../../../../../components/HIDTypography';
import SellingPropertyHandoverBanner from './components/SellingPropertyHandoverBanner';
import SellingPropertyHandoverLoaderSkeleton from './components/SellingPropertyHandoverLoaderSkeleton';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import PropertyProgressQuickNavigation from '../../../PropertyProgress/components/PropertyProgressQuickNavigation';

type SellingPropertyHandoverSectionsContentType = {
  [SellingPropertyHandoverSectionId.TASKS]: FC<PropertyProgressCategorySectionProps<PropertyTasksSectionData>>;
  [SellingPropertyHandoverSectionId.DOCUMENT_TYPES]: FC<PropertyProgressCategorySectionProps<PropertyDocumentationData>>;
  [SellingPropertyHandoverSectionId.RECEIPTS]: FC<PropertyProgressCategorySectionProps<SellingPropertyHandoverReceiptsSectionData>>;
  [SellingPropertyHandoverSectionId.TIMELINE_ENTRIES]: FC<PropertyProgressCategorySectionProps<PropertyTimelineSectionData>>;
};

const SellingPropertyHandoverSectionsContent: SellingPropertyHandoverSectionsContentType = {
  [SellingPropertyHandoverSectionId.TASKS]: SellingPropertyHandoverTasks,
  [SellingPropertyHandoverSectionId.DOCUMENT_TYPES]: SellingPropertyHandoverDocuments,
  [SellingPropertyHandoverSectionId.RECEIPTS]: SellingPropertyHandoverReceipts,
  [SellingPropertyHandoverSectionId.TIMELINE_ENTRIES]: SellingPropertyHandoverTimeline,
};

const SellingPropertyHandover: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['property', 'selling_property']);
  const navigateBackOr = useNavigateBackOr();
  const { isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: PropertyHandoverData,
    isLoading: isLoadingPropertyHandoverData,
  } = useGetSellingPropertyTransferQuery(propertyId ? { propertyId } : skipToken);

  const handleRenderSectionContent = (section: PropertyProgressSection<SellingPropertyHandoverSectionData>) => {
    const Content = SellingPropertyHandoverSectionsContent[section.id as keyof typeof SellingPropertyHandoverSectionsContent];

    // @ts-ignore
    return Content ? <Content section={section} /> : null;
  };

  const sectionsData = PropertyHandoverData?.sections || [];
  const isLoading = !sectionsData.length && isLoadingPropertyHandoverData;

  return (
    <HomeLayout
      BodyLoaderSkeleton={SellingPropertyHandoverLoaderSkeleton}
      SideColumn={
        <Stack spacing={2}>
          <SellingPropertyHandoverBanner />
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategorySection.HANDOVER}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPropertyProgressPath({ propertyId: propertyId || '' }),
          name: t('property:property_progress_title'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategorySection.HANDOVER}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('selling_property:selling_property_handover_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <HIDTypography
        variant="body1"
      >
        {t('selling_property:selling_property_handover_screen_description')}
      </HIDTypography>
      <PropertyProgressPageContent<SellingPropertyHandoverSectionData>
        hideProgressBar
        loading={isLoadingPropertyHandoverData}
        renderSectionContent={handleRenderSectionContent}
        sections={sectionsData}
      />
      {isDownMd && (
        <Stack sx={{ marginTop: theme.spacing(2) }}>
          <SellingPropertyHandoverBanner />
        </Stack>
      )}
    </HomeLayout>
  );
};

export default SellingPropertyHandover;
