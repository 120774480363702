import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const DocMoney: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M19 9V4C19 2.89543 18.1046 2 17 2H5C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22H9.5M13.5 14.5V21.5C13.5 22.33 15.5 23 18 23C20.5 23 22.5 22.33 22.5 21.5V14.5M13.5 14.5C13.5 13.6716 15.5147 13 18 13C20.4853 13 22.5 13.6716 22.5 14.5M13.5 14.5C13.5 15.3284 15.5147 16 18 16C20.4853 16 22.5 15.3284 22.5 14.5M22.5 18C22.5 18.83 20.5 19.5 18 19.5C15.5 19.5 13.5 18.83 13.5 18M7 7H15M7 11H12M7 15H9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default DocMoney;
