import qs from 'query-string';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import {
  Suggestion,
  SuggestionsSummary,
  SuggestionsSettings,
  SuggestionsFilters,
  EntityTypeSuggestions,
} from '../types.suggestion';
import { PropertyId } from '../../../../../types/types.property';
import { PaginationParams } from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/api.property';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type IgnoreSuggestionParams = PropertyId & {
  entityId: string,
  entityType: string,
  entitySuggestions: EntityTypeSuggestions,
};

type GetSuggestionsParams = PropertyId & Partial<PaginationParams> & { entityType?: string };

export const suggestionApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestionsFilters: builder.query<SuggestionsFilters, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/suggestions/filters`,
      providesTags: () => [{ type: HIDApiTags.SUGGESTIONS_FILTERS }],
    }),
    getSuggestions: builder.query<{ suggestions: Array<Suggestion>, totalCount: number }, GetSuggestionsParams>({
      query: ({
        propertyId,
        entityType,
        pageSize,
        offset,
      }) => {
        const routeParams = entityType ? `/${entityType}` : '';

        const queryParams = pageSize !== undefined && offset !== undefined
          ? `?${qs.stringify({ pageSize, offset })}`
          : '';

        return `/properties/${propertyId}/suggestions${routeParams}${queryParams}`;
      },
      providesTags: (result) => provideArrayTags(HIDApiTags.SUGGESTION, result?.suggestions?.map(({ entityId }) => ({ id: entityId }))),
    }),
    getEntitySuggestions: builder.query<Suggestion, PropertyId & { entityType: string, entityId: string }>({
      query: ({
        propertyId,
        entityType,
        entityId,
      }) => `/properties/${propertyId}/suggestions/${entityType}/${entityId}`,
      providesTags: (result) => result ? [{ type: HIDApiTags.SUGGESTION as const, id: result.entityId }] : [],
    }),
    getSuggestionsSummary: builder.query<SuggestionsSummary, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/suggestions/summary`,
      providesTags: () => [{ type: HIDApiTags.SUGGESTIONS_SUMMARY }],
    }),
    getSuggestionsSettings: builder.query<SuggestionsSettings, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/suggestions/configuration`,
      providesTags: () => [{ type: HIDApiTags.SUGGESTIONS_SETTING }],
    }),
    updateSuggestionsSettings: builder.mutation<SuggestionsSettings, { settings: SuggestionsSettings } & PropertyId>({
      query: ({ propertyId, settings }) => ({
        url: `/properties/${propertyId}/suggestions/configuration`,
        method: 'PATCH',
        body: settings,
      }),
      invalidatesTags: () => [
        { type: HIDApiTags.SUGGESTIONS_SETTING },
        { type: HIDApiTags.SUGGESTIONS_SUMMARY },
        { type: HIDApiTags.SUGGESTIONS },
      ],
    }),
    ignoreSuggestion: builder.mutation<SuggestionsSettings, IgnoreSuggestionParams>({
      query: ({
        propertyId,
        entityId,
        entityType,
        entitySuggestions,
      }) => ({
        url: `/properties/${propertyId}/suggestions/configuration/${entityType}/${entityId}`,
        method: 'PATCH',
        body: entitySuggestions,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.SUGGESTION, id: arg.entityId },
        { type: HIDApiTags.SUGGESTIONS_SUMMARY },
      ],
    }),
  }),
});

export const {
  useGetSuggestionsFiltersQuery,
  useGetSuggestionsQuery,
  useGetEntitySuggestionsQuery,
  useLazyGetSuggestionsQuery,
  useGetSuggestionsSummaryQuery,
  useGetSuggestionsSettingsQuery,
  useUpdateSuggestionsSettingsMutation,
  useIgnoreSuggestionMutation,
} = suggestionApi;
