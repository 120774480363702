import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../hooks/useDialog';
import { getCreateBudgetPath } from '../navigation.budgets';
import useGetBudgetsList from './useGetBudgetsList';
import { useGetRecurringExpensesBankAccountsQuery } from '../../RecurringExpenses/api/api.recurringExpenses';
import { getYearsListFromBankAccounts } from '../../RecurringExpenses/utils.recurringExpenses';
import {
  useLazyGetPropertySettingsQuery,
  useSetPropertySettingsMutation,
} from '../../../../../../Auth/api/api.settings';
import { IncompleteBudget } from '../types.budgets';
import { useGetActiveAuthUser } from '../../../../../../../external-services/firebase';

type UseGetHandleCreateBudget = () => [() => void, boolean];

const useGetHandleCreateBudget: UseGetHandleCreateBudget = () => {
  const { t } = useTranslation(['common', 'forms_common']);
  const navigate = useNavigate();
  const { user: currentUser } = useGetActiveAuthUser();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: budgetsList = [], isLoading: isBudgetsLoading } = useGetBudgetsList();

  const [
    openCreateFromExistingBudgetDialog,
  ] = useDialog(DialogNames.CREATE_BUDGET_FROM_EXISTING_DIALOG);

  const [openContinueOrResetBudgetCreationDialog] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const { data: bankAccounts, isLoading: isBankAccountsLoading } = useGetRecurringExpensesBankAccountsQuery();

  const [getPropertySettings] = useLazyGetPropertySettingsQuery();
  const [setPropertySettings] = useSetPropertySettingsMutation();

  const expensesList = useMemo(() => {
    if (bankAccounts?.length) {
      return getYearsListFromBankAccounts(bankAccounts)
        .map((year) => {
          const stringYear = String(year);

          return {
            id: stringYear,
            name: stringYear,
          };
        });
    }

    return [];
  }, [bankAccounts]);

  const handleNavigateToCreateNewBudget = (incompleteBudget?: IncompleteBudget | null) =>
    propertyId ? navigate(getCreateBudgetPath({ propertyId }), incompleteBudget ? { state: { incompleteBudget } } : {}) : undefined;

  const handleCreateNewBudget = () => {
    if (budgetsList.length || expensesList.length) {
      openCreateFromExistingBudgetDialog({
        budgetsList,
        expensesList,
        onDecline: handleNavigateToCreateNewBudget,
      });
    } else {
      handleNavigateToCreateNewBudget();
    }
  };

  const handleCreateBudget = () => {
    if (propertyId && currentUser) {
      getPropertySettings({ userUId: currentUser.uid, propertyId })
        .unwrap()
        .then((data) => {
          if (data?.incompleteBudget) {
            openContinueOrResetBudgetCreationDialog({
              title: t('forms_common:continue_where_you_left'),
              description: t('forms_common:continue_or_delete_data'),
              yesTitle: t('forms_common:continue'),
              noTitle: t('common:clear_remove'),
              onYes: () => handleNavigateToCreateNewBudget(data?.incompleteBudget),
              onNo: () => {
                setPropertySettings({
                  propertyId,
                  settings: {
                    ...data,
                    incompleteBudget: null,
                  },
                });
                handleCreateNewBudget();
              },
            });
          } else {
            handleCreateNewBudget();
          }
        });
    } else {
      handleCreateNewBudget();
    }
  };

  const isLoading = isBankAccountsLoading || isBudgetsLoading;

  return [handleCreateBudget, isLoading];
};

export default useGetHandleCreateBudget;
