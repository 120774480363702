import React, { FC } from 'react';
import {
  ChevronRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getBadgeCount } from '../../../../../utils/string';
import { getNoWrapStyle } from '../../../../../utils/style';

const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 8,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledMenuBadge = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0, 1.25),
  color: theme.palette.common.white,
}));

type MenuItemProps = {
  Icon: React.ElementType;
  label: string;
  count?: number;
  progress?: number;
  isExpendedCategory?: boolean;
  isCategory?: boolean;
  showChevron?: boolean;
  onClick: () => void;
};

const MenuItem: FC<MenuItemProps> = ({
  Icon,
  label,
  count,
  progress,
  isExpendedCategory = false,
  isCategory = false,
  showChevron = true,
  onClick,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  return (
    <StyledStack
      alignItems="center"
      direction="row"
      onClick={onClick}
    >
      <Icon />
      <Typography
        style={{ lineHeight: theme.spacing(3) }}
        sx={{ marginLeft: theme.spacing(2), ...getNoWrapStyle(1) }}
        variant={isCategory ? 'subtitle1' : 'body1'}
      >
        {label}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexGrow={1}
        justifyContent="flex-end"
        spacing={1.5}
      >
        {count !== undefined && count > 0 && (
          <StyledMenuBadge sx={{ lineHeight: theme.spacing(3) }} variant="body2">
            {getBadgeCount(count)}
          </StyledMenuBadge>
        )}
        {progress !== undefined && (
          <Typography
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '2px',
              color: theme.palette.common.white,
              padding: theme.spacing(0, 0.5),
              whiteSpace: 'nowrap',
              lineHeight: theme.spacing(3),
            }}
            variant="body2"
          >
            {`${progress}% ${t('common:done')}`}
          </Typography>
        )}
        {showChevron && (
          isCategory
            ? isExpendedCategory
              ? <KeyboardArrowUp />
              : <KeyboardArrowDown />
            : <ChevronRight />
        )}
      </Stack>
    </StyledStack>
  );
};

export default MenuItem;
