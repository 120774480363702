import React from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from '@mui/material';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  useFormikContext,
} from 'formik';
import {
  PaymentType,
  RemappedBudgetSubcategory,
  BudgetTemplateCategoryId,
  CreateUpdateBudgetValues,
} from '@house-id/houseid-types/dist/finances/budgets';

import BudgetAverageTypeForm from './BudgetAverageTypeForm';
import BudgetMortgageTypeForm from './BudgetMortgageTypeForm';
import IndividualMonthlyAllocatedBudget from './IndividualMonthlyAllocatedBudget';
import BudgetElectricityConsumptionTypeForm from './BudgetElectricityConsumptionTypeForm';
import BudgetSubcategoryHeader from './BudgetSubcategoryHeader';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import DebouncedTextField from './DebouncedTextField';
import getPaymentMonthsForQuarter from '../utils/getPaymentMonthsForQuarter';
import { FCC } from '../../../../../../../../../types/common';
import { getSubcategoryDataPath } from '../utils/utils.formikFieldDataPath';

type BudgetSubcategorySectionProps = {
  data: RemappedBudgetSubcategory;
  subcategoryName?: string;
  subcategoryIndex: number;
  categoryId: BudgetTemplateCategoryId;
  hasMultiSubcategories?: boolean;
  onDeleteSubcategory: (index: number) => void;
};

const BudgetSubcategorySection: FCC<BudgetSubcategorySectionProps> = ({
  data,
  subcategoryName,
  subcategoryIndex,
  categoryId,
  hasMultiSubcategories,
  onDeleteSubcategory,
}) => {
  const {
    name,
    amount,
    infoText,
    paymentType,
    paymentTypes,
    isNoteSupported,
    firstPaymentMonth,
  } = data;

  const subcategoryPath = getSubcategoryDataPath(categoryId, subcategoryIndex);

  const theme = useTheme();

  const { setFieldValue } = useFormikContext<CreateUpdateBudgetValues>();

  const { t } = useTranslation(['finances']);

  const subcategoryWrapperStyles = {
    padding: 1.5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '5px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white,
    width: '100%',
  };

  const paymentTypeOptions = [
    { id: PaymentType.AVERAGE, name: t('finances:budgets_payment_average_cost_option') },
    { id: PaymentType.INDIVIDUAL, name: t('finances:budgets_payment_individual_months_option') },
    { id: PaymentType.MORTGAGE, name: t('finances:budgets_payment_based_on_mortgage_option') },
    { id: PaymentType.ELECTRICAL_TRADE, name: t('finances:budgets_payment_based_on_consumption_option') },
  ];

  const handleChangePaymentType = (value: PaymentType) => {
    setFieldValue(`${subcategoryPath}.paymentType`, value);

    const paymentMonthsFieldPath = `${subcategoryPath}.paymentMonths`;

    if (value !== PaymentType.AVERAGE) {
      setFieldValue(paymentMonthsFieldPath, R.times((n) => ({ month: n + 1, amount }), 12));
    } else if (firstPaymentMonth && value === PaymentType.AVERAGE) {
      setFieldValue(paymentMonthsFieldPath, getPaymentMonthsForQuarter(amount, firstPaymentMonth));
    }
  };

  const PaymentTypeBasedComponent = match(paymentType)
    .with(PaymentType.AVERAGE, () => BudgetAverageTypeForm)
    .with(PaymentType.INDIVIDUAL, () => IndividualMonthlyAllocatedBudget)
    .with(PaymentType.MORTGAGE, () => BudgetMortgageTypeForm)
    .with(PaymentType.ELECTRICAL_TRADE, () => BudgetElectricityConsumptionTypeForm)
    .exhaustive();

  return (
    <Stack
      spacing={1.5}
      sx={hasMultiSubcategories ? subcategoryWrapperStyles : undefined}
    >
      {hasMultiSubcategories && (
        <BudgetSubcategoryHeader
          name={subcategoryName || name}
          onDelete={() => onDeleteSubcategory(subcategoryIndex)}
        />
      )}
      <RadioGroup
        sx={{ alignItems: 'flex-start' }}
        value={paymentType}
        onChange={(_, value) => handleChangePaymentType(value as PaymentType)}
      >
        {
          paymentTypeOptions.map((option) => paymentTypes.includes(option.id)
            ? (
              <FormControlLabel
                control={<Radio />}
                key={option.id}
                label={
                  <HIDInfo
                    description={infoText?.[option.id]}
                    label={option.name}
                    labelVariant="body1"
                  />
                }
                value={option.id}
              />
            )
            : null)
        }
      </RadioGroup>
      {isNoteSupported && paymentType !== PaymentType.MORTGAGE && (
        <DebouncedTextField
          fieldPath={`${subcategoryPath}.note`}
          label={t('finances:budgets_payment_own_note_label')}
          type="text"
        />
      )}
      <PaymentTypeBasedComponent
        categoryId={categoryId}
        data={data}
        subcategoryIndex={subcategoryIndex}
      />
    </Stack>
  );
};

export default BudgetSubcategorySection;
