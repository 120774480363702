import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

import { FCC } from '../../types/common';
import { ICON_SIZE } from '../../constants/layout';

type HIDSvgIconProps = {
  size?: number;
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
  title?: string;
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
  className?: string;
};

const HIDSvgIcon: FCC<HIDSvgIconProps> = ({
  size = ICON_SIZE,
  width,
  height,
  fill = 'none',
  viewBox = `0 0 ${ICON_SIZE} ${ICON_SIZE}`,
  title,
  sx,
  className,
  children,
}) => (
  <SvgIcon
    className={className}
    sx={{
      fill,
      width: width || size,
      height: height || size,
      ...sx,
    }}
    titleAccess={title}
    viewBox={viewBox}
  >
    {children}
  </SvgIcon>
);

export default HIDSvgIcon;
