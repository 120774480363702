import React from 'react';
import {
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import HIDLink from '../../../../../components/HIDLink';
import { ForumRepliesOrder } from '../../../types.forum';
import { FCC } from '../../../../../types/common';

type ForumRepliesOrderSelectProps = {
  value?: ForumRepliesOrder;
  onChange: (repliesOrder: ForumRepliesOrder) => void;
};

const ForumRepliesOrderSelect: FCC<ForumRepliesOrderSelectProps> = ({
  value = ForumRepliesOrder.DATE_DESC,
  sx,
  style,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleToggleMenuVisible = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const handleChange = (order: ForumRepliesOrder) => {
    onChange(order);
    handleClose();
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        style={style}
        sx={sx}
        onClick={handleToggleMenuVisible}
      >
        <HIDLink
          label={t(`forum:forum_replies_order_${value}`)}
          sx={{ color: theme.palette.common.black }}
          underline="none"
          onClick={(event) => {
            event.preventDefault();
          }}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        sx={{
          marginTop: theme.spacing(1),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {
          Object.keys(ForumRepliesOrder)
            .map((order) => order.toLowerCase())
            .map((order) => (
              <MenuItem
                key={order}
                onClick={() => handleChange(order as ForumRepliesOrder)}
              >
                {/* @ts-ignore */}
                {t(`forum:forum_replies_order_${order}`)}
              </MenuItem>
            ))
        }
      </Menu>
    </>
  );
};

export default ForumRepliesOrderSelect;
