import React, { FC } from 'react';
import {
  Card,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import { hidSpacing } from '../../../../../utils/number';
import useScrollWithShadow from '../../../../../hooks/useScrollWithShadow';
import { getTypographyHeight } from '../../../../../utils/style';
import MessagesImage from '../../../../../assets/images/messages_empty.png';
import HIDLink from '../../../../../components/HIDLink';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetMessagesQuery } from '../../../modules/Content/modules/Message/api/api.message';
import MessageListItemCompact from '../../../modules/Content/modules/Message/components/MessageListItemCompact';
import {
  getMessagePath,
  getMessagesPath,
} from '../../../modules/Content/modules/Message/navigation.message';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

const SHOW_MESSAGES_COUNT = 4;

const MessagesLoadingState = () => {
  const theme = useTheme();

  const lines = 3;
  const itemSpacing = 0.25;
  const sizeReduce = hidSpacing(((lines - 1) * itemSpacing) / lines);

  return (
    <Stack>
      {R.range(0, SHOW_MESSAGES_COUNT).map((item) => (
        <Stack key={item} spacing={0.25} sx={{ padding: theme.spacing(0.5, 1) }}>
          <Skeleton height={getTypographyHeight(theme.typography.subtitle1) - sizeReduce} variant="rounded" width="100%" />
          <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
          <Skeleton height={getTypographyHeight(theme.typography.body2) - sizeReduce} variant="rounded" width="100%" />
        </Stack>
      ))}
    </Stack>
  );
};

const MessagesEmptyState = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const handleGoToInbox = () => navigate(getPathWithPropertyIdOrInit(getMessagesPath, { propertyId }));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2.5}
      sx={{
        padding: 3,
        height: '100%',
      }}
    >
      <img src={MessagesImage} style={{ width: 100, height: 96 }} />
      <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
        {t('home:home_no_messages')}
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant="body1">
        {t('home:home_no_messages_description')}
      </Typography>
      <HIDLink label={t('home:home_go_to_inbox')} onClick={handleGoToInbox} />
    </Stack>
  );
};

const MessagesWidget: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['home', 'forms_common']);

  const { isDownSm } = useBreakPointsSizes();

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.MESSAGES);

  const titleHeight = hidSpacing(3);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { messages = [] } = {}, isLoading } = useGetMessagesQuery(
    propertyId ? { propertyId, pageSize: SHOW_MESSAGES_COUNT } : skipToken,
  );

  const { boxShadow, scrollContainerRef, onScrollHandler } = useScrollWithShadow({ showBottom: false });

  const hasNoItems = !isLoading && !messages.length;

  const handleGoToInbox = () => navigate(getPathWithPropertyIdOrInit(getMessagesPath, { propertyId }));

  const handleClick = (id: string) => navigate(getPathWithPropertyIdOrInit(getMessagePath, { propertyId, id }));

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: isDownSm ? theme.spacing(2) : theme.spacing(2, 1, 0, 2),
        height: widgetHeight || '100%',
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography
            noWrap
            sx={{
              height: titleHeight,
            }}
            variant="h5"
          >
            {t('home:home_my_messages')}
          </Typography>
          <HIDLink
            label={t('forms_common:view_all')}
            style={{ marginRight: theme.spacing(1) }}
            onClick={handleGoToInbox}
          />
        </Stack>
        {
          isLoading
            ? <MessagesLoadingState />
            : hasNoItems
              ? <MessagesEmptyState />
              : (
                <Stack
                  ref={scrollContainerRef}
                  sx={{
                    height: widgetHeight - titleHeight - hidSpacing(1 + 2),
                    overflowY: 'auto',
                    boxShadow,
                  }}
                  onScroll={onScrollHandler}
                >
                  {messages.map((message) => (
                    <MessageListItemCompact
                      key={message.id}
                      message={message}
                      onClick={() => handleClick(message.id)}
                    />
                  ))}
                </Stack>
              )
        }
      </Stack>
    </Card>
  );
};

export default MessagesWidget;
