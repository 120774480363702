import React, {
  FC,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import HIDInlineSelect, { SelectItem } from '../../../../../components/HIDInlineSelect';
import PiggyBank from '../../../../../components/icons/PiggyBank';
import Wallet from '../../../../../components/icons/Wallet';
import { toggleSideDrawerOpen } from '../../../../../store/layoutReducer';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { PropertyIdNavigation } from '../../../types/types.property';
import { getBudgetsOverviewPath } from '../modules/Budgets/navigation.budgets';
import { getRecurringExpensesCategoriesPath } from '../modules/RecurringExpenses/navigation.recurringExpenses';
import { FinancePageNames } from '../types.finances';

const FinancesQuickNavigationMap: Record<FinancePageNames, (params: PropertyIdNavigation) => string> = {
  [FinancePageNames.RECURRING_EXPENSES]: getRecurringExpensesCategoriesPath,
  [FinancePageNames.BUDGET_CATEGORIES]: getBudgetsOverviewPath,
};

type FinancesQuickNavigationProps = {
  currentPage?: FinancePageNames;
};

const FinancesQuickNavigation: FC<FinancesQuickNavigationProps> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const selectItems: Array<SelectItem> = [
    {
      id: FinancePageNames.RECURRING_EXPENSES,
      label: t('finances:fixed_expenses'),
      Icon: Wallet,
    },
    {
      id: FinancePageNames.BUDGET_CATEGORIES,
      label: t('finances:budgets'),
      Icon: PiggyBank,
    },
  ].filter(Boolean);

  const handleNavigate = (page: FinancePageNames) => {
    if (propertyId) {
      const getPath = FinancesQuickNavigationMap[page];
      if (typeof getPath === 'function' && propertyId) {
        dispatch(toggleSideDrawerOpen(false));
        navigate(getPath({ propertyId }));
      }
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {t('finances:finances')}
      </Typography>
      <HIDInlineSelect
        items={selectItems}
        value={currentPage}
        onChange={(page) => handleNavigate(page as FinancePageNames)}
      />
    </Stack>
  );
};

export default FinancesQuickNavigation;
