import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import ReactMarkdown from 'react-markdown';

import PropertyInsuranceCondominiumBackground from '../../../../../../../../assets/images/if_insurance_background.jpeg';
import IfLogoShort from '../../../../../../../../assets/images/if_short.png';
import HIDImage from '../../../../../../../../components/image/HIDImage';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { CustomInsuranceType } from '../../types.insurance';

type IfPropertyInsuranceCardProps = {
  insuranceType: CustomInsuranceType,
};

const IfPropertyInsuranceCard: FC<IfPropertyInsuranceCardProps> = ({
  insuranceType,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances']);
  const imageUrl = PropertyInsuranceCondominiumBackground;

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const headerHeight = R.cond([
    [() => isDownXs, R.always(160)],
    [() => isDownSm, R.always(160)],
    [() => isDownMd, R.always(320)],
    [() => isDownLg, R.always(400)],
    [() => isDownXl, R.always(400)],
    [R.T, R.always(320)],
  ])();

  const logoHeight = R.cond([
    [() => isDownXs, R.always(48)],
    [() => isDownSm, R.always(48)],
    [() => isDownMd, R.always(80)],
    [() => isDownLg, R.always(80)],
    [() => isDownXl, R.always(80)],
    [R.T, R.always(80)],
  ])();

  return (
    <Stack spacing={2}>
      <Box sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        height: headerHeight,
      }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          padding={2}
          spacing={2}
          sx={{ height: headerHeight }}
        >
          <HIDImage
            style={{
              height: logoHeight,
            }}
            url={IfLogoShort}
          />
          <Typography color={theme.palette.common.white} textAlign="center" typography={{ sm: 'h3', xxs: 'h5' }}>
            {t(`insurances:insurance_if_congratulations_${insuranceType}`)}
          </Typography>
        </Stack>
      </Box>
      <Typography>
        <ReactMarkdown components={{ p: 'span' }}>
          {t(`insurances:insurance_if_description_${insuranceType}`)}
        </ReactMarkdown>
      </Typography>
      <Typography>
        {t('insurances:insurance_if_included_features')}
        <ReactMarkdown components={{ p: 'span' }}>
          {t(`insurances:insurance_if_features_${insuranceType}`)}
        </ReactMarkdown>
      </Typography>
    </Stack>
  );
};

export default IfPropertyInsuranceCard;
