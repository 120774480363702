import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import HIDButton from '../../../../../components/buttons/HIDButton';
import { getColorGradient } from '../../../../../utils/style';
import {
  FCC,
  Icon,
} from '../../../../../types/common';
import { useGetFinancesChartHeight } from './FinancesChart';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import FinanceIcon from './FinanceIcon';

type FinancesEmptyStateCardProps = {
  title: string;
  subTitle: string;
  Icon: Icon;
  addTitle: string;
  isLoading?: boolean;
  onAdd: () => void;
};

const FinancesEmptyStateCard: FCC<FinancesEmptyStateCardProps> = ({
  title,
  subTitle,
  Icon,
  addTitle,
  isLoading,
  onAdd,
  children,
}) => {
  const theme = useTheme();
  const chartMinHeight = useGetFinancesChartHeight();
  const height = chartMinHeight + 50;

  const { isDownLg, isDownMd } = useBreakPointsSizes();

  return (
    <Stack
      alignItems="center"
      gap={isDownMd ? 2 : 3}
      sx={{
        padding: isDownMd ? 2 : isDownLg ? 3 : 4,
        background: getColorGradient(theme.palette.info.lightest, theme.palette.common.white),
        borderRadius: '5px',
        border: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        minHeight: height,
        position: 'relative',
      }}
    >
      <FinanceIcon
        Icon={Icon}
        iconBackgroundColor={theme.palette.info.lighter}
        iconColor={theme.palette.info.dark}
      />
      <Stack alignItems="center">
        <Typography textAlign="center" variant="h6">
          {title}
        </Typography>
        <Typography textAlign="center">
          {subTitle}
        </Typography>
      </Stack>
      <HIDButton loading={isLoading} onClick={onAdd}>
        {addTitle}
      </HIDButton>
      {children}
    </Stack>
  );
};

export default FinancesEmptyStateCard;
