import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Card } from '@mui/material';
import {
  PropertyProgressCategorySection,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import { getPropertyProgressPath } from '../../../PropertyProgress/navigation.propertyProgress';
import {
  SellingPropertyInformationSectionData,
  SellingPropertyInformationDocuments,
  SellingPropertyInformationSectionId,
  SellingPropertyInformationTextData,
  SellingPropertyDocumentationData,
} from '../../types.sellingProperty';
import {
  PropertyPhotosSectionData,
  PropertyProgressCategorySectionProps,
  PropertyFinancesOperatingCostSectionData,
  PropertyProgressDynamicContentSectionData,
} from '../../../PropertyProgress/types.propertyProgress';
import PropertyProgressPageContent from '../../../PropertyProgress/components/PropertyProgressPageContent';
import { useGetPropertyDataFromRealEstateAgencyQuery } from '../../api/api.sellingProperty';
import SellingPropertyInfoBlueprintsDocumentsSection from './components/SellingPropertyInfoBlueprintsDocumentsSection';
import SellingPropertyInfoHomeAndAccommodation from './components/SellingPropertyInfoHomeAndAccommodation';
import SellingPropertyInfoHousingFacts from './components/SellingPropertyInfoHousingFacts';
import SellingPropertyInfoOperatingCosts from './components/SellingPropertyInfoOperatingCosts';
import SellingPropertyInfoPhotosSection from './components/SellingPropertyInfoPhotosSection';
import SellingPropertyInfoPurchaseInfoSection from './components/SellingPropertyInfoPurchaseInfoSection';
import SellingPropertyInfoQuestionnaireAnswers from './components/SellingPropertyInfoQuestionnaireAnswers';
import SellingPropertyInfoSalesDocument from './components/SellingPropertyInfoSalesDocument';
import SellingPropertyInformationLoaderSkeleton from './components/SellingPropertySectionLoaderSkeleton';
import PropertyProgressQuickNavigation from '../../../PropertyProgress/components/PropertyProgressQuickNavigation';

type SellingPropertyInformationSectionsContentType = {
  [SellingPropertyInformationSectionId.PHOTOS]: FC<PropertyProgressCategorySectionProps<PropertyPhotosSectionData>>;
  [SellingPropertyInformationSectionId.BLUEPRINTS]: FC<PropertyProgressCategorySectionProps<SellingPropertyDocumentationData>>;
  [SellingPropertyInformationSectionId.OPERATING_COSTS]: FC<PropertyProgressCategorySectionProps<PropertyFinancesOperatingCostSectionData>>;
  [SellingPropertyInformationSectionId.HOUSING_FACTS]: FC<PropertyProgressCategorySectionProps>;
  [SellingPropertyInformationSectionId.DOCUMENTS]: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationDocuments>>;
  // eslint-disable-next-line max-len
  [SellingPropertyInformationSectionId.PURCHASE_INFORMATION]: FC<PropertyProgressCategorySectionProps<PropertyProgressDynamicContentSectionData>>;
  // eslint-disable-next-line max-len
  [SellingPropertyInformationSectionId.HOME_AND_ACCOMMODATION]: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationTextData>>;
  // eslint-disable-next-line max-len
  [SellingPropertyInformationSectionId.ANSWERS_TO_QUESTIONNAIRE]: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationDocuments>>;
};

const SellingPropertyInformationSectionsContent: SellingPropertyInformationSectionsContentType = {
  [SellingPropertyInformationSectionId.PHOTOS]: SellingPropertyInfoPhotosSection,
  [SellingPropertyInformationSectionId.BLUEPRINTS]: SellingPropertyInfoBlueprintsDocumentsSection,
  [SellingPropertyInformationSectionId.OPERATING_COSTS]: SellingPropertyInfoOperatingCosts,
  [SellingPropertyInformationSectionId.HOUSING_FACTS]: SellingPropertyInfoHousingFacts,
  [SellingPropertyInformationSectionId.DOCUMENTS]: SellingPropertyInfoSalesDocument,
  [SellingPropertyInformationSectionId.PURCHASE_INFORMATION]: SellingPropertyInfoPurchaseInfoSection,
  [SellingPropertyInformationSectionId.HOME_AND_ACCOMMODATION]: SellingPropertyInfoHomeAndAccommodation,
  [SellingPropertyInformationSectionId.ANSWERS_TO_QUESTIONNAIRE]: SellingPropertyInfoQuestionnaireAnswers,
};

const SellingPropertyInformation: FC = () => {
  const { t } = useTranslation(['property', 'selling_property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: propertyInfoData,
    isLoading: isLoadingPropertyInfoData,
  } = useGetPropertyDataFromRealEstateAgencyQuery(propertyId ? { propertyId } : skipToken);

  const handleRenderSectionContent = (section: PropertyProgressSection<SellingPropertyInformationSectionData>) => {
    const Content = SellingPropertyInformationSectionsContent[section.id as keyof typeof SellingPropertyInformationSectionsContent];

    // @ts-ignore
    return Content ? <Content section={section} /> : null;
  };

  const sectionsData = propertyInfoData?.sections || [];
  const isLoading = !sectionsData.length && isLoadingPropertyInfoData;

  return (
    <HomeLayout
      BodyLoaderSkeleton={SellingPropertyInformationLoaderSkeleton}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <PropertyProgressQuickNavigation
            currentPropertyProgressCategoryId={PropertyProgressCategorySection.REALTOR_PROPERTY_INFORMATION}
            key={PropertyProgressQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPropertyProgressPath({ propertyId: propertyId || '' }),
          name: t('property:property_progress_title'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategorySection.REALTOR_PROPERTY_INFORMATION}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('selling_property:selling_property_information_screen_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <PropertyProgressPageContent<SellingPropertyInformationSectionData>
        hideProgressBar
        loading={isLoadingPropertyInfoData}
        renderSectionContent={handleRenderSectionContent}
        sections={sectionsData}
      />
    </HomeLayout>
  );
};

export default SellingPropertyInformation;
