import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getActiveAuth } from '../external-services/firebase';
import { getCurrentLanguage } from '../utils/locale';
import { HIDEntityId } from '../types/common';
import { HIDApiTags } from './HIDApiTags';

const HOUSE_ID_APP_VERSION = 'X-HouseId-WebAppVersion';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'x-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  'Accept-Language': getCurrentLanguage(),
  [HOUSE_ID_APP_VERSION]: import.meta.env.VITE_APP_VERSION,
};

type HIDBaseQueryParams = typeof fetchBaseQuery;
export const HIDQuery: ReturnType<HIDBaseQueryParams> = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_APP_BACKEND_URL}`,
  headers: DEFAULT_HEADERS,
  credentials: 'same-origin',
});

const HIDAuthBaseQuery: ReturnType<HIDBaseQueryParams> = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_APP_BACKEND_URL}`,
  headers: DEFAULT_HEADERS,
  credentials: 'same-origin',
  prepareHeaders: async (headers) => {
    try {
      const auth = getActiveAuth();
      if (auth) {
        const token = await auth?.currentUser?.getIdToken();
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
          headers.set('Access-Control-Expose-Headers: ', '*');
        }
      }
    } catch (e) {
      return headers;
    }
    return headers;
  },
});

export const HIDAuthQuery: ReturnType<HIDBaseQueryParams> = async (args, api, extraOptions) => {
  const auth = getActiveAuth();
  if (auth?.currentUser) {
    const result = await HIDAuthBaseQuery(args, api, extraOptions);
    return result;
  }
  return { error: { status: 401, data: undefined } };
};

export const provideArrayTags = (tag: HIDApiTags, array: Array<HIDEntityId> | undefined) => array?.length
  ? array.map(({ id }) => ({ type: tag, id }))
  : [tag];
