import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import {
  DynamicFactsGroup,
  DynamicContainerFieldProps,
  DynamicInputField,
  DynamicFact,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import HIDLink from '../../../../../../../../../components/HIDLink';
import HIDArrowButton from '../../../../../../../../../components/buttons/HIDArrowButton';
import useNavigateFromLink from '../../../../../../../hooks/useNavigateFromLink';
import useGetPropertyPermissions from '../../../../../../../hooks/useGetPropertyPermissions';

type DynamicContentFactsGroupProps = DynamicFactsGroup & DynamicContainerFieldProps;

const DynamicContentFactsGroup: FC<DynamicContentFactsGroupProps> = ({
  title,
  description,
  components,
  source,
  link,
  button,
  renderItem,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forms_common']);

  const navigateToScreenFromLink = useNavigateFromLink(link);
  const navigateToScreenFromButtonLink = useNavigateFromLink(button?.link);
  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const changeText = components?.some((c: DynamicInputField) => !R.isNil((c as DynamicFact).value))
    ? t('forms_common:change')
    : t('forms_common:enter');

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[200],
        width: '100%',
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <HIDInfo
            description={description}
            label={title}
            labelVariant="subtitle2"
          />
          {link && canUpdate && (
            <HIDLink
              label={changeText}
              variant="subtitle2"
              onClick={navigateToScreenFromLink}
            />
          )}
        </Stack>
        {components?.map((inputField: DynamicInputField, index: number) => renderItem(inputField, index))}
        {button && (
          <Stack direction="row">
            <HIDArrowButton
              label={title}
              onClick={navigateToScreenFromButtonLink}
            />
          </Stack>
        )}
        <DynamicContentSource source={source} />
      </Stack>
    </Card>
  );
};
export default DynamicContentFactsGroup;
