import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { getPropertyDeclarationDeepLink } from '../../../../../utils/links';

const useGetOpenPropertyDeclarationMobileAppDialog = () => {
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleOpen = () => {
    openMobileAppDialog({
      linkExtractor: getPropertyDeclarationDeepLink,
      unavailableFeature: EntityType.DECLARATION,
    });
  };

  return handleOpen;
};

export default useGetOpenPropertyDeclarationMobileAppDialog;
