import React, { FC } from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PropertyProgressCategorySection,
  PropertyProgressSection,
} from '@house-id/houseid-types/dist/propertyProgress';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { useGetPropertyProgressToDoQuery } from '../../api/api.propertyProgress';
import PropertyProgressPageContent from '../../components/PropertyProgressPageContent';
import PropertyProgressQuickNavigation from '../../components/PropertyProgressQuickNavigation';
import { getPropertyProgressPath } from '../../navigation.propertyProgress';
import {
  PropertyProgressCategorySectionProps,
  PropertyToDoSectionSubSection,
  PropertyToDoSectionData,
} from '../../types.propertyProgress';
import useGetPropertyProgressCategoryContentText from '../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import PropertyToDoTasksSection from './components/PropertyToDoTasksSection';
import PropertyToDoPropertyCheckSection from './components/PropertyToDoPropertyCheckSection';

const PropertyProductsSectionsContent: Record<string, FC<PropertyProgressCategorySectionProps>> = {
  [PropertyToDoSectionSubSection.PROPERTY_TODO_TASKS]: PropertyToDoTasksSection,
  [PropertyToDoSectionSubSection.PROPERTY_TODO_PROPERTY_CHECK]: PropertyToDoPropertyCheckSection,
};

const PropertyToDo: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyToDo,
    isLoading: isPropertySpecificationLoading,
  } = useGetPropertyProgressToDoQuery(propertyId ? { propertyId } : skipToken);

  const sections = propertyToDo?.sections || [];

  const handleRenderSectionContent = (section: PropertyProgressSection<PropertyToDoSectionData>) => {
    const Content = PropertyProductsSectionsContent[section.id];

    return Content ? <Content section={section} /> : null;
  };

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(propertyToDo?.progress),
    categoryId: PropertyProgressCategorySection.TODO,
  });

  return (
    <HomeLayout
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertyProgressQuickNavigation
              currentPropertyProgressCategoryId={PropertyProgressCategorySection.TODO}
              key={PropertyProgressQuickNavigation.name}
            />
          </Card>
        </Stack>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }),
          name: t('property:property_progress_title'),
        },
      ]}
      sideDrawerElements={[
        <PropertyProgressQuickNavigation
          currentPropertyProgressCategoryId={PropertyProgressCategorySection.TODO}
          key={PropertyProgressQuickNavigation.name}
        />,
      ]}
      title={t('property:property_progress_property_todo_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }))}
    >
      <PropertyProgressPageContent
        description={description}
        loading={isPropertySpecificationLoading}
        progress={propertyToDo?.progress}
        renderSectionContent={handleRenderSectionContent}
        sections={sections}
      />
    </HomeLayout>
  );
};

export default PropertyToDo;
