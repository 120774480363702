import {
  addDays,
} from 'date-fns';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { QueryHook } from '../../../types/common';
import {
  SubscriptionFeature,
} from '../types.subscriptionPlans';
import { useGetCurrentSubscriptionPlanQuery } from '../api/api.subscriptionPlans';
import { getRemainingTime } from '../../../utils/date';

type RemainingTime = {
  unit: 'days' | 'hours' | 'minutes',
  value: number;
};

type SubscriptionPlanTrialPeriodInfo = {
  isEnabled: boolean;
  isExpired?: boolean;
  durationDays?: number;
  remainingTime?: RemainingTime
};

const useGetSubscriptionPlanTrialPeriodInfo: QueryHook<SubscriptionPlanTrialPeriodInfo | undefined> = (_args, options) => {
  const {
    data: subscriptionPlan,
    ...rest
  } = useGetCurrentSubscriptionPlanQuery(options?.skip ? skipToken : undefined);

  const data = useMemo(() => {
    if (subscriptionPlan) {
      const feature = subscriptionPlan.plan.features[SubscriptionFeature.MULTI_PLATFORMS];

      if (feature && !feature.enabled && feature.trial && feature.trial.enabled && feature.trial.value) {
        const { trialPeriodDays } = feature.trial.value as { trialPeriodDays: number };
        const startDate = new Date(subscriptionPlan.startDateUsingWebApp);
        const expiredDate = addDays(startDate, trialPeriodDays);
        const remainingTime = getRemainingTime(expiredDate);

        return {
          isEnabled: true,
          isExpired: !remainingTime,
          durationDays: trialPeriodDays,
          remainingTime: remainingTime as RemainingTime | undefined,
        };
      }

      return {
        isEnabled: false,
      };
    }

    return undefined;
  }, [subscriptionPlan]);

  return {
    data,
    ...rest,
  };
};

export default useGetSubscriptionPlanTrialPeriodInfo;
