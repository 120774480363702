import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ExpertImage from '../../../../../assets/images/expert.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import useTryOpenAskForumQuestionDialog from '../../../../Forum/hooks/useTryOpenAskForumQuestionDialog';

const ForumExpertWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();

  const handleAskExpert = () => tryOpenAskForumQuestionDialog({ isAskExpert: true });
  const handleAskForum = () => tryOpenAskForumQuestionDialog({ isAskExpert: false });

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.FORUM_EXPERT);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        height: widgetHeight || 'unset',
      }}
    >
      <img
        src={ExpertImage}
        style={{
          width: '100%',
          aspectRatio: 16 / 9,
          overflow: 'hidden',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }}
      />
      <Stack spacing={2} sx={{ padding: theme.spacing(1.5, 2.5, 2.5, 2.5), flex: 1 }}>
        <Stack justifyContent="center" sx={{ flex: 1 }}>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {t('forum:forum_experts_widget_info')}
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <HIDButton color="secondary" loading={isTryOpenAskForumQuestionDialogLoading} onClick={handleAskForum}>
            {t('forum:forum_ask_the_forum')}
          </HIDButton>
          <HIDButton loading={isTryOpenAskForumQuestionDialogLoading} onClick={handleAskExpert}>
            {t('forum:forum_ask_the_expert')}
          </HIDButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ForumExpertWidget;
