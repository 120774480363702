import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';

import { ICON_SIZE } from './layout';

export const CHEVRON_FIELD_ID = 'CHEVRON_FIELD_ID';

export const LIST_CHEVRON_COLUMN_CONFIG: GridColDef = {
  field: CHEVRON_FIELD_ID,
  headerName: '',
  type: 'string',
  maxWidth: ICON_SIZE + 2 * 10,
  minWidth: ICON_SIZE + 2 * 10,
  sortable: false,
  renderCell: () => <ChevronRight />,
};
