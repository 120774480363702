import {
  useTheme,
  ImageListItem,
  Skeleton,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';
import { FCC } from '../../../../../../../types/common';
import PhotosListLayoutWrapper from './PhotosListLayoutWrapper';
import VerticalImagePlaceholder from '../../../../../../../components/svg/VerticalImagePlaceholder';

type ImageGalleryLoaderSkeletonProps = {
  isLoading: boolean;
  itemsCount?: number;
};

const ImageGalleryLoaderSkeleton: FCC<ImageGalleryLoaderSkeletonProps> = ({
  itemsCount = 10,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <PhotosListLayoutWrapper>
        {R.range(0, itemsCount).map((i) => (
          <ImageListItem key={i} sx={{ overflow: 'hidden', position: 'relative' }}>
            <Skeleton
              height="auto"
              sx={{ color: theme.palette.shadow[100], backgroundColor: 'unset' }}
              variant="rounded"
              width="100%"
            >
              <VerticalImagePlaceholder />
            </Skeleton>
            <Skeleton
              height="18%"
              sx={{
                position: 'absolute',
                bottom: '6px',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
              }}
              variant="rounded"
              width="100%"
            />
          </ImageListItem>
        ))}
      </PhotosListLayoutWrapper>
    );
  }

  return children;
};

export default ImageGalleryLoaderSkeleton;
