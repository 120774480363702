import React, {
  FC,
  useRef,
  useState,
} from 'react';
import {
  Card,
  ImageList,
  ImageListItem,
} from '@mui/material';
import * as R from 'ramda';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import HIDImageViewer, { HIDImageViewerRef } from '../../../../../../../components/image/HIDImageViewer/HIDImageViewer';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import ContentImage from '../../../../Content/components/ContentImage';

export type PropertyPhotosListProps = {
  blobs: Array<HIDBlob>;
};

const PropertyPhotosList: FC<PropertyPhotosListProps> = ({
  blobs,
}) => {
  const imageGalleryRef = useRef<HIDImageViewerRef | null>(null);

  const [isGalleryMode, setIsGalleryMode] = useState(false);

  const handleOpen = (index: number) => imageGalleryRef.current?.fullScreen(index);

  const galleryDisplay = isGalleryMode ? 'unset' : 'none';

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const columnsCount = R.cond([
    [() => isDownXs, R.always(3)],
    [() => isDownSm, R.always(4)],
    [() => isDownMd, R.always(6)],
    [() => isDownLg, R.always(6)],
    [() => isDownXl, R.always(6)],
    [R.T, R.always(8)],
  ])();

  return blobs.length
    ? (
      <>
        <HIDImageViewer
          isFullScreen
          filePickerSx={{ display: galleryDisplay }}
          images={blobs}
          key={blobs.length}
          ref={imageGalleryRef}
          sx={{ display: galleryDisplay }}
          onScreenChange={setIsGalleryMode}
        />
        <ImageList
          cols={columnsCount}
          gap={6}
        >
          {blobs.map((blob, index) => (
            <ImageListItem key={blob.id} sx={{ overflow: 'hidden' }}>
              <Card
                onClick={() => handleOpen(index)}
              >
                <ContentImage
                  blobs={[blob]}
                  imageSx={{
                    width: '100%',
                    height: 'unset',
                    objectFit: 'cover',
                    aspectRatio: 3 / 4,
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                  }}
                  key={blob.id}
                />
              </Card>
            </ImageListItem>
          ))}
        </ImageList>
      </>
    )
    : null;
};
export default PropertyPhotosList;
