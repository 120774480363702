import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import HIDClickable from '../../../components/HIDClickable';
import { getRandomBetween } from '../../../utils/random';
import HIDTypography from '../../../components/HIDTypography';
import HIDMuiIcon from '../../../components/icons/HIDMuiIcon';

type FeaturedTopicListItemProps = {
  name: string;
  threadsCount: number;
  isLoading?: boolean;
  onClick: () => void;
};

const FeaturedTopicListItem: FC<FeaturedTopicListItemProps> = ({
  name,
  threadsCount,
  isLoading,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  return (
    <HIDClickable
      isDisabled={isLoading}
      sx={{
        direction: 'row',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
      }}
      onClick={onClick}
    >
      <HIDTypography isLoading={isLoading} skeletonWidth={getRandomBetween(50, 100)}>
        {name}
      </HIDTypography>
      <Stack direction="row">
        <HIDTypography isLoading={isLoading} skeletonWidth={60}>
          {`${threadsCount} ${threadsCount === 1 ? t('forum:forum_question').toLowerCase() : t('forum:forum_questions').toLowerCase()}`}
        </HIDTypography>
        <HIDMuiIcon Icon={ChevronRight} isDisabled={isLoading} />
      </Stack>
    </HIDClickable>
  );
};

export default FeaturedTopicListItem;
