import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  PropertyFinancesFixedCostSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import RecurringExpensesOverview from '../../../../Finances/pages/financeOverview/components/RecurringExpensesOverview';
import { getReceiptCategoriesPath } from '../../../../Content/modules/Receipt/navigation.receipt';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';

const PropertyFinancesFixedCostSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesFixedCostSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleSeeMore = () => {
    if (propertyId) {
      navigate(getReceiptCategoriesPath({ propertyId }));
    }
  };

  const handleAddOperatingCost = () => {
    if (propertyId) {
      navigate(getReceiptCategoriesPath({ propertyId }));
    }
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_operating_cost_section_add_expenses')}
          viewAllButtonText={t('property:property_progress_property_operating_cost_section_view_expenses')}
          onAddClick={handleAddOperatingCost}
          onViewAllClick={handleSeeMore}
        >
          <RecurringExpensesOverview />
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesFixedCostSection;
