import React, { FC } from 'react';
import {
  Stack,
} from '@mui/material';
import {
  DynamicContainerFieldProps,
  DynamicInputField,
  DynamicSection,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DynamicContentSectionProps = DynamicSection & DynamicContainerFieldProps;

const DynamicContentSection: FC<DynamicContentSectionProps> = ({
  title,
  description,
  direction,
  components,
  source,
  renderItem,
}) => (
  <Stack direction={direction} justifyContent={direction ? 'flex-end' : undefined} spacing={1}>
    <HIDInfo description={description} label={title} labelVariant="subtitle1" />
    {components?.map((inputField: DynamicInputField, index: number) => renderItem(inputField, index))}
    <DynamicContentSource source={source} />
  </Stack>
);

export default DynamicContentSection;
