import React from 'react';

import SellingPropertySectionLoaderSkeleton from '../../../components/SellingPropertySectionLoaderSkeleton';
import { FCC } from '../../../../../../../types/common';

type SellingPropertyHandoverLoaderSkeletonProps = {
  isLoading: boolean;
};

const SellingPropertyHandoverLoaderSkeleton: FCC<SellingPropertyHandoverLoaderSkeletonProps> = ({
  isLoading,
  children,
}) => (
  <SellingPropertySectionLoaderSkeleton hideGalleryLoader isLoading={isLoading}>
    {children}
  </SellingPropertySectionLoaderSkeleton>
);

export default SellingPropertyHandoverLoaderSkeleton;
