import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import AdminRouteNames from './AdminRouteNames';
import AdminProtectedRoute from './AdminProtectedRoute';

const AdminSurveys = lazy(() => import('../modules/Survey/pages/AdminSurveys/AdminSurveys'));
const AdminSurveyGroups = lazy(() => import('../modules/Survey/pages/AdminSurveyGroups/AdminSurveyGroups'));
const AdminSurveyGroupCreateUpdate = lazy(() => import(
  '../modules/Survey/pages/AdminSurveyGroupCreateUpdate/AdminSurveyGroupCreateUpdate'
));

const LabelingQueue = lazy(() => import('../modules/Labeling/pages/LabelingQueue'));
const LabelingQueueTasks = lazy(() => import('../modules/Labeling/pages/LabelingQueueTasks'));
const ViewLabelingQueueTask = lazy(() => import('../modules/Labeling/pages/ViewLabelingQueueTask/ViewLabelingQueueTask'));
const CreateUpdateLabelingCashierReceiptData = lazy(() => import(
  '../modules/Labeling/pages/CreateUpdateLabelingCashierReceiptData/CreateUpdateLabelingCashierReceiptData'
));
const CreateUpdateLabelingInvoiceData = lazy(() => import(
  '../modules/Labeling/pages/CreateUpdateLabelingInvoiceData/CreateUpdateLabelingInvoiceData'
));
const Emails = lazy(() => import('../modules/Emails/pages/Emails'));
const UploadEmail = lazy(() => import('../modules/Emails/pages/UploadEmail'));
const ImitateAccount = lazy(() => import('../pages/ImitateAccount/ImitateAccount'));
const CreateProperty = lazy(() => import('../pages/CreateProperty/CreateProperty'));

const AdminRouteConfig: Array<RouteObject> = [
  {
    path: AdminRouteNames.ADMIN_HOME,
    element: (
      <AdminProtectedRoute>
        <ImitateAccount />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.LABELING_QUEUES,
    element: (
      <AdminProtectedRoute>
        <LabelingQueue />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.LABELING_QUEUE_TASKS,
    element: (
      <AdminProtectedRoute>
        <LabelingQueueTasks />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.VIEW_LABELING_TEMPLATES_TASK,
    element: (
      <AdminProtectedRoute>
        <ViewLabelingQueueTask />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.ADD_LABELING_QUEUE_TASK_INVOICE_DATA,
    element: (
      <AdminProtectedRoute>
        <CreateUpdateLabelingInvoiceData />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.ADD_LABELING_QUEUE_TASK_CASHIER_RECEIPT_DATA,
    element: (
      <AdminProtectedRoute>
        <CreateUpdateLabelingCashierReceiptData />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.EMAILS,
    element: (
      <AdminProtectedRoute>
        <Emails />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.UPLOAD_EMAIL,
    element: (
      <AdminProtectedRoute>
        <UploadEmail />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.IMITATE_ACCOUNT,
    element: (
      <AdminProtectedRoute>
        <ImitateAccount />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.SURVEY_GROUP_CRETE,
    element: (
      <AdminProtectedRoute>
        <AdminSurveyGroupCreateUpdate />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.SURVEY_GROUP_UPDATE,
    element: (
      <AdminProtectedRoute>
        <AdminSurveyGroupCreateUpdate />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.SURVEY_GROUPS,
    element: (
      <AdminProtectedRoute>
        <AdminSurveyGroups />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.SURVEYS,
    element: (
      <AdminProtectedRoute>
        <AdminSurveys />
      </AdminProtectedRoute>
    ),
  },
  {
    path: AdminRouteNames.CREATE_PROPERTY,
    element: (
      <AdminProtectedRoute>
        <CreateProperty />
      </AdminProtectedRoute>
    ),
  },
];

export default AdminRouteConfig;
