import {
  PropertyStageType,
  PropertyType,
} from '@house-id/houseid-types/dist/property';

import DialogNames from '../../../../../hooks/useDialog/DialogNames';

import {
  ShowDialogItem,
  ShowDialogItemTargetType,
} from '../../../../../types/dialogs';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';

enum SellingPropertyModalIds {
  SELLING_PROPERTY_MODAL = 'sellingPropertyModal',
  SOLD_PROPERTY_MODAL = 'soldPropertyModal',
  PREVIOUSLY_OWNED_PROPERTY_MODAL = 'previouslyOwnedPropertyModal',
}

const useGetHomeDialogs: () => ShowDialogItem[] = () => {
  const { data: { type, stage } = {} } = useGetCurrentProperty();

  const dialogs = type !== PropertyType.UNKNOWN
    ? [
      stage === PropertyStageType.SELLING && {
        uniqueId: SellingPropertyModalIds.SELLING_PROPERTY_MODAL,
        name: DialogNames.SELLING_PROPERTY_PROGRESS_DIALOG,
        priority: 1,
        target: ShowDialogItemTargetType.PROPERTY,
        maxCountToShow: 1,
      },
      stage === PropertyStageType.SOLD && {
        uniqueId: SellingPropertyModalIds.SOLD_PROPERTY_MODAL,
        name: DialogNames.SELLING_PROPERTY_PROGRESS_DIALOG,
        priority: 1,
        target: ShowDialogItemTargetType.PROPERTY,
        maxCountToShow: 1,
      },
      stage === PropertyStageType.PREVIOUS_OWNED && {
        uniqueId: SellingPropertyModalIds.PREVIOUSLY_OWNED_PROPERTY_MODAL,
        name: DialogNames.SELLING_PROPERTY_PROGRESS_DIALOG,
        priority: 1,
        target: ShowDialogItemTargetType.PROPERTY,
        maxCountToShow: 1,
      },
    ] : [];

  return dialogs.filter(Boolean);
};

export default useGetHomeDialogs;
