import React from 'react';

import SellingPropertySectionLoaderSkeleton from '../../../components/SellingPropertySectionLoaderSkeleton';
import { FCC } from '../../../../../../../types/common';

type SellingPropertyInformationLoaderSkeletonProps = {
  isLoading: boolean;
};

const SellingPropertyInformationLoaderSkeleton: FCC<SellingPropertyInformationLoaderSkeletonProps> = ({
  isLoading,
  children,
}) => (
  <SellingPropertySectionLoaderSkeleton hideDescriptionLoader isLoading={isLoading}>
    {children}
  </SellingPropertySectionLoaderSkeleton>
);

export default SellingPropertyInformationLoaderSkeleton;
