import { propertyApi } from './api.property';

type DynamicLink = {
  link: string;
};

export const dynamicLinksApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicLinkShort: builder.query<DynamicLink, DynamicLink>({
      query: ({ link }) => `${import.meta.env.VITE_APP_BACKEND_URL}/tools/dynamic-link?format=short&link=${link}`,
    }),
  }),
});

export const {
  useLazyGetDynamicLinkShortQuery,
  useGetDynamicLinkShortQuery,
} = dynamicLinksApi;
