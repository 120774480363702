/* eslint-disable max-len */
import qs from 'query-string';

import { isLocal } from './env';
import { getInviteCodePath } from '../modules/Auth/navigation/navigation.auth';
import { ENROLL_SECOND_FACTOR_URL_PARAM } from '../modules/Auth/constants.auth';

export const getAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1`;

export const getAppStoreAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1&ofl=https://apps.apple.com/se/app/house-id-acessa-ditt-hus-id/id1546758051&utm_source=houseid.se&utm_medium=web_no_utm`;

export const getGooglePlayAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1&ofl=https://play.google.com/store/apps/details?id=se.houseid.app&utm_source=houseid.se&utm_medium=web_no_utm`;

export const MobileAppPrefixUrl = 'houseid://app';

export const getVerifyEmailReturnBackUrl = (propertyId: string) =>
  `${document.location.origin}/properties/${propertyId}/user-details?${ENROLL_SECOND_FACTOR_URL_PARAM}=true&action=${MobileAppPrefixUrl}/editAccount/true`;

export const generateDynamicLink = (location: string, appDeepLink: string) => {
  const locationResolved = isLocal
    ? location
      .replace('http://localhost:5173', 'https://test.houseid.services')
      .replace('http://127.0.0.1:5173', 'https://test.houseid.services')
    : location;

  const parsedUrl = qs.parseUrl(locationResolved);
  return encodeURI(qs.stringifyUrl({
    url: parsedUrl.url,
    query: {
      ...parsedUrl.query,
      action: encodeURI(appDeepLink),
    },
  }));
};

export const getPropertyInviteDeepLink = (code: string) => generateDynamicLink(
  `${window.location.origin}/${getInviteCodePath(code)}`,
  `${MobileAppPrefixUrl}/inviteCode/${code}`,
);

export const getPropertyHomeDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/home`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );

export const getPropertyDeclarationDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/declaration`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );

export const getPropertySpecificationDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/viewPropertySpecifications`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );
