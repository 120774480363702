import { PropertyProgressSection } from '@house-id/houseid-types/dist/propertyProgress';
import { Stack } from '@mui/system';
import { skipToken } from '@reduxjs/toolkit/query';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Grid } from '@mui/material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HIDTypography from '../../../../../../../../components/HIDTypography';
import { BuildingEntityType } from '../../../../../../../../constants/entityType';
import {
  FCC,
} from '../../../../../../../../types/common';
import useGetCurrentProperty from '../../../../../../hooks/useCurrentProperty';
import useGetEntityInfo from '../../../../../Content/hooks/useGetEntityInfo';
import PropertyProgressCategorySectionContainer from '../../../../components/PropertyProgressCategorySectionContainer';
import BuildingEntitiesSectionEmptyState from './BuildingEntitiesSectionEmptyState';
import PropertyStructureBuildingsSectionItem from './PropertyStructureBuildingsSectionItem';
import { useGetBuildingEntitiesQuery } from '../../../../../Buildings/api/api.buildings';
import useGetMainOrFirstBuilding from '../../../../../Buildings/hooks/useGetMainOrFirstBuilding';
import { useGetEntityConnectionsQuery } from '../../../../../Content/api/api.content';
import { BuildingShortEntity } from '../../../../../Buildings/types.buildings';
import PropertyProgressCategorySectionContent from '../../../../components/PropertyProgressCategorySectionContent';
import HIDFormSelect from '../../../../../../../../components/HIDFormSelect';

const getConnectedEntities = (parentId?: string, connectedEntitiesIds?: Array<string>) =>
  R.pipe(
    // @ts-ignore
    parentId
      ? R.filter(({ id }: { id: string }) => Boolean(connectedEntitiesIds?.includes(id)))
      : R.identity,
    R.sortBy(R.prop('order')),
  ) as (entities: Array<BuildingShortEntity>) => Array<BuildingShortEntity>;

type BuildingEntitiesSectionProps = {
  parentRequired?: boolean
  section: PropertyProgressSection;
  buildingEntityType: BuildingEntityType;
  description: string;
  emptyStateTitle: string;
  emptyStateDescription: string;
  emptyStateAddText: string;
  emptyStateImage: string;
  showAllText: string;
  onShowAll: () => void;
  onConfigure: () => void;
};

const BuildingEntitiesSection: FCC<BuildingEntitiesSectionProps> = ({
  parentRequired = true,
  section,
  buildingEntityType,
  description,
  showAllText,
  emptyStateTitle,
  emptyStateDescription,
  emptyStateAddText,
  emptyStateImage,
  onShowAll,
  onConfigure,
  style,
}) => {
  const { t } = useTranslation(['forms_common', 'common', 'buildings']);

  const getEntityInfo = useGetEntityInfo();
  const buildingTypeInfo = getEntityInfo(buildingEntityType);

  const { data: property } = useGetCurrentProperty();
  const propertyId = property?.id;

  const [currentBuildingId, setCurrentBuildingId] = useState<string | undefined>();

  const { data: firstOrMainBuilding } = useGetMainOrFirstBuilding();

  const {
    data: buildings = [],
  } = useGetBuildingEntitiesQuery(
    propertyId ? { propertyId, buildingEntityType: EntityType.BUILDING } : skipToken,
  );

  const {
    data: entities = [],
    isSuccess: isEntitiesSuccess,
  } = useGetBuildingEntitiesQuery(propertyId ? { propertyId, buildingEntityType } : skipToken);

  const { data: connectedEntitiesIds } = useGetEntityConnectionsQuery(
    propertyId && currentBuildingId
      ? {
        propertyId,
        entityId: currentBuildingId,
        entityType: EntityType.BUILDING,
      }
      : skipToken,
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data?.[buildingEntityType]?.map(({ entity }) => entity.id) || [],
        rest,
      }),
    },
  );

  const entitiesList: Array<BuildingShortEntity> = useMemo(
    () =>
      parentRequired
        ? currentBuildingId && connectedEntitiesIds
          ? getConnectedEntities(currentBuildingId, connectedEntitiesIds)(entities)
          : []
        : entities,
    [currentBuildingId, connectedEntitiesIds, entities],
  );

  const showBuildingsPicker = parentRequired && currentBuildingId && buildings?.length > 1 && entities.length > 0;

  useEffect(() => {
    if (!currentBuildingId && firstOrMainBuilding) {
      setCurrentBuildingId(firstOrMainBuilding?.id);
    }
  }, [firstOrMainBuilding, currentBuildingId]);

  const isEmptyState = isEntitiesSuccess && !entities.length;

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('common:add_label')}
          showButtons={!isEmptyState}
          viewAllButtonText={showAllText}
          onAddClick={onConfigure}
          onViewAllClick={onShowAll}
        >
          {isEmptyState
            ? (
              <BuildingEntitiesSectionEmptyState
                addText={emptyStateAddText}
                description={emptyStateDescription}
                emptyStateImage={emptyStateImage}
                title={emptyStateTitle}
                onAdd={onConfigure}
              />
            ) : (
              <Stack style={{ gap: 2.5 }}>
                {showBuildingsPicker && (
                  <HIDFormSelect
                    items={buildings}
                    label={t('buildings:add_buildings_screen_select_building')}
                    value={currentBuildingId}
                    onChange={(event) => setCurrentBuildingId(event.target.value)}
                  />
                )}
                {
                  entitiesList.length
                    ? (
                      <Stack style={{ gap: 2.5 }}>
                        <Grid
                          container
                          spacing={1.5}
                          xxs={12}
                        >
                          {entitiesList
                            ?.map((entity) => (
                              <Grid
                                item
                                key={entity.id}
                                sm={entitiesList.length > 1 ? 6 : 12}
                                xxs={12}
                              >
                                <PropertyStructureBuildingsSectionItem
                                  buildingEntity={entity}
                                  buildingEntityType={buildingEntityType}
                                  style={{
                                    width: 100,
                                    height: 100,
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Stack>
                    )
                    : (
                      <Stack style={{
                        flex: 1,
                        justifyContent: 'center',
                        gap: 2.5,
                      }}
                      >
                        <Stack gap={1}>
                          <img
                            src={emptyStateImage}
                            style={{
                              width: 180,
                              height: 180,
                              alignSelf: 'center',
                            }}
                          />
                          <HIDTypography style={{ textAlign: 'center' }} variant="subtitle1">
                            {t(
                              'buildings:no_content_building_entities_title',
                              { buildingEntityName: buildingTypeInfo?.namePlural?.toLowerCase() },
                            )}
                          </HIDTypography>
                          <HIDTypography style={{ textAlign: 'center' }}>
                            {t(
                              'buildings:no_content_building_entities_message',
                              { buildingEntityName: buildingTypeInfo?.namePlural?.toLowerCase() },
                            )}
                          </HIDTypography>
                        </Stack>
                      </Stack>
                    )
                }
              </Stack>
            )}
        </PropertyProgressCategorySectionContent>
      }
      section={{ ...section, description: !isEmptyState ? description : undefined }}
      style={style}
    />
  );
};

export default BuildingEntitiesSection;
