import * as Sentry from '@sentry/react';

export const sentryConfig: Sentry.BrowserOptions = {
  enabled: import.meta.env.VITE_APP_ERROR_REPORTING_ENABLED === 'true',
  environment: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  dsn: 'https://52f3b0628f0a4d858c4216ab8ba9fdd1@o4505130692575232.ingest.sentry.io/4505159798161408',
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 0.1,
  release: `${import.meta.env.VITE_APP_REPOSITORY_NAME}-${import.meta.env.VITE_APP_GITHUB_BUILD_NUMBER}`,
  beforeSend: (event) => {
    if (import.meta.env.VITE_APP_ERROR_REPORTING_ENABLED === 'true' && event.exception) {
      // TODO: Show toaster with error info
    }
    return event;
  },
  debug: false,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],
};
