export type SupportTicketCategory = {
  id: string;
  name: string;
  subjectTemplate: string;
  bodyTemplate: string;
};

export type SupportTicket = {
  body: string;
  subject: string;
  contextInformation?: object;
  categoryId: string;
};

export enum SupportTicketCategoryId {
  BONUS_POINTS = 'bonuspoints',
  MISSING_EXPERT = 'missing-expert',
  MISSING_PRODUCT_MATERIAL = 'missing-product-material',
  ONLINE_SESSION = 'online-session',
  OTHER = 'other',
  PROPERTY_TRANSFER = 'property-transfer',
  REPORT_BUG = 'report-bug',
  SUGGESTIONS_IMPROVEMENT = 'suggestions-improvement',
  PROPERTY_VERIFICATION = 'property-verification',
  SUBSCRIPTIONS = 'subscriptions',
}
