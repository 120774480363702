import React, {
  FC,
} from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  CreateUpdateBudgetValues,
  PaymentType,
  PaymentTypeBasedComponentProps,
} from '@house-id/houseid-types/dist/finances/budgets';

import { CustomAdornment } from '../../../../../../../../../components/HIDTextField';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { useCalculateElectricityPricesMutation } from '../../../api/api.budgets';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import {
  getPaymentMonthsDataPath,
  getSubcategoryDataPath,
} from '../utils/utils.formikFieldDataPath';
import MonthlyAllocatedBudget from './MonthlyAllocatedBudget';
import DebouncedTextField from './DebouncedTextField';

const BudgetElectricityConsumptionTypeForm: FC<PaymentTypeBasedComponentProps> = ({
  data,
  categoryId,
  subcategoryIndex,
}) => {
  const {
    paymentMonths,
    annualConsumption,
    averagePrice,
  } = data;

  const { t } = useTranslation(['common', 'finances', 'forms_common']);
  const { isDownSm } = useBreakPointsSizes();

  const { setFieldValue, values } = useFormikContext<CreateUpdateBudgetValues>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [
    calculateElectricityPrices,
    { isLoading },
  ] = useCalculateElectricityPricesMutation();

  const subcategoryPath = getSubcategoryDataPath(categoryId, subcategoryIndex);
  const paymentMonthsFieldPath = getPaymentMonthsDataPath(categoryId, subcategoryIndex);

  const handleClearPaymentMonths = () => {
    setFieldValue(paymentMonthsFieldPath, (paymentMonths || []).map(({ month }) => ({ month, amount: undefined })));
  };

  const isCalculationDisabled = !(annualConsumption && averagePrice && propertyId);

  const handleCalculatePaymentMonths = () => {
    if (!isCalculationDisabled) {
      calculateElectricityPrices({
        propertyId,
        payload: {
          year: values.year,
          yearlyAveragePricePerKwh: averagePrice,
          expectedYearlyUsageKwh: annualConsumption,
        },
      })
        .unwrap()
        .then(({ description, perMonth }) => {
          const paymentMonthsResult = (Object.values(perMonth) || []).map(({ month, totalIncludingTax }) => ({
            month,
            amount: totalIncludingTax,
          }));

          setFieldValue(`${subcategoryPath}.infoText.${PaymentType.ELECTRICAL_TRADE}`, description);
          setFieldValue(paymentMonthsFieldPath, paymentMonthsResult);
        });
    }
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        spacing={isDownSm ? 1 : 2}
      >
        <DebouncedTextField
          isRequired
          endAdornment={<CustomAdornment label={t('forms_common:per_year', { value: 'kWh' })} />}
          fieldPath={`${subcategoryPath}.annualConsumption`}
          inputProps={{
            min: 0,
            step: 0.1,
          }}
          label={t('finances:budgets_payment_annual_consumption_label')}
          type="number"
        />
        <DebouncedTextField
          isRequired
          endAdornment={<CustomAdornment label="kr/kWh" />}
          fieldPath={`${subcategoryPath}.averagePrice`}
          inputProps={{
            min: 0,
            step: 0.1,
          }}
          label={t('finances:budgets_payment_annual_average_price_label')}
          type="number"
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent={isDownSm ? 'center' : 'flex-start'}
        spacing={isDownSm ? 2.5 : 2}
      >
        <HIDButton
          disabled={isCalculationDisabled}
          loading={isLoading}
          onClick={handleCalculatePaymentMonths}
        >
          {t('finances:budgets_payment_calculate_label')}
        </HIDButton>
        <HIDButton
          color="secondary"
          disabled={isLoading}
          onClick={handleClearPaymentMonths}
        >
          {t('common:clear_remove')}
        </HIDButton>
      </Stack>
      <MonthlyAllocatedBudget
        categoryId={categoryId}
        paymentMonths={paymentMonths}
        subcategoryIndex={subcategoryIndex}
      />
    </Stack>
  );
};

export default BudgetElectricityConsumptionTypeForm;
