import React from 'react';
import {
  Skeleton,
  useTheme,
  Stack,
  Card,
  ImageList,
  ImageListItem,
} from '@mui/material';
import * as R from 'ramda';

import { FCC } from '../../../../../types/common';
import { ICON_SIZE } from '../../../../../constants/layout';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import VerticalImagePlaceholder from '../../../../../components/svg/VerticalImagePlaceholder';

type SellingPropertySectionLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
  hideGalleryLoader?: boolean;
  hideDescriptionLoader?: boolean;
};

const SellingPropertySectionLoaderSkeleton: FCC<SellingPropertySectionLoaderSkeletonProps> = ({
  itemsCount = 5,
  isLoading,
  children,
  hideGalleryLoader = false,
  hideDescriptionLoader = false,
}) => {
  const theme = useTheme();
  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const columnsCount = R.cond([
    [() => isDownXs, R.always(3)],
    [() => isDownSm, R.always(4)],
    [() => isDownMd, R.always(6)],
    [() => isDownLg, R.always(6)],
    [() => isDownXl, R.always(6)],
    [R.T, R.always(8)],
  ])();

  return isLoading
    ? (
      <Stack spacing={2}>
        {!hideDescriptionLoader && (
          <Stack>
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="90%" />
          </Stack>
        )}
        {!hideGalleryLoader && (
          <Card
            sx={{
              marginBottom: theme.spacing(2),
              width: '100%',
              padding: theme.spacing(2),
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} sx={{ marginBottom: theme.spacing(2) }}>
                <Skeleton height={ICON_SIZE} variant="rounded" width={ICON_SIZE} />
                <Skeleton variant="text" width="200px" />
              </Stack>
              <ImageList
                cols={columnsCount}
                gap={6}
              >
                {R.range(0, 6).map((index) => (
                  <ImageListItem key={index} sx={{ overflow: 'hidden' }}>
                    <Skeleton
                      height="auto"
                      sx={{
                        display: 'flex',
                        color: theme.palette.shadow[100],
                        backgroundColor: 'unset',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      variant="rounded"
                      width="100%"
                    >
                      <VerticalImagePlaceholder />
                    </Skeleton>
                  </ImageListItem>
                ))}
              </ImageList>
            </Stack>
            <Stack direction="row-reverse" sx={{ marginTop: theme.spacing(2) }}>
              <Skeleton
                height={40}
                sx={{ borderRadius: 20 }}
                variant="rounded"
                width="120px"
              />
            </Stack>
          </Card>
        )}
        {R.range(0, itemsCount).map((i) => (
          <Card
            key={i}
            sx={{
              marginBottom: theme.spacing(2),
              width: '100%',
              padding: theme.spacing(2),
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Stack direction="row" spacing={1} sx={{ marginBottom: theme.spacing(2) }}>
              <Skeleton height={ICON_SIZE} variant="rounded" width={ICON_SIZE} />
              <Skeleton variant="text" width="200px" />
            </Stack>
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
            <Stack spacing={2} sx={{ marginTop: theme.spacing(2) }}>
              <Stack direction={isDownSm ? 'column' : 'row'} spacing={2}>
                <Skeleton
                  height={80}
                  sx={{ width: isDownSm ? '100%' : '50%' }}
                  variant="rounded"
                />
                <Skeleton
                  height={80}
                  sx={{ width: isDownSm ? '100%' : '50%' }}
                  variant="rounded"
                />
              </Stack>
              <Skeleton
                height={40}
                sx={{ borderRadius: 20 }}
                variant="rounded"
                width="200px"
              />
            </Stack>
            <Stack direction="row-reverse" sx={{ marginTop: theme.spacing(2) }}>
              <Skeleton
                height={40}
                sx={{ borderRadius: 20 }}
                variant="rounded"
                width="120px"
              />
            </Stack>
          </Card>
        ))}
      </Stack>
    )
    : children;
};

export default SellingPropertySectionLoaderSkeleton;
