import React from 'react';
import {
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { ArrowForward } from '@mui/icons-material';
import {
  FCC,
} from '../../types/common';
import { HIDButtonProps } from './HIDButton';

type HIDArrowButtonProps = & {
  label: string;
  disabled?: HIDButtonProps['disabled'];
  onClick: HIDButtonProps['onClick'];
};

const HIDArrowButton: FCC<HIDArrowButtonProps> = ({
  label,
  disabled,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Button
      disabled={disabled}
      sx={{
        paddingX: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.transparent,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[500],
        },
      }}
      onClick={onClick}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Typography color={theme.palette.common.black} variant="body1">{label}</Typography>
        <ArrowForward
          fontSize="small"
          sx={{
            color: theme.palette.common.black,
          }}
        />
      </Stack>
    </Button>
  );
};

export default HIDArrowButton;
