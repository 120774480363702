import React, {
  FC,
  useState,
} from 'react';
import {
  Collapse,
  Icon as MuiIcon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../../../../../types/common';
import { TimelineEvent } from '../../../types.timeline';
import useGetEntityInfo from '../../../../../hooks/useGetEntityInfo';
import HIDAvatar from '../../../../../../../../../components/HIDAvatar';
import ContentImage from '../../../../../components/ContentImage';
import { EntityContentConnection } from '../../../../../types/types.content';
import { hidSpacing } from '../../../../../../../../../utils/number';
import HIDLink from '../../../../../../../../../components/HIDLink';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { getNoWrapStyle } from '../../../../../../../../../utils/style';
import { RootState } from '../../../../../../../../../store/store';
import { htmlDecode } from '../../../../../../../../../utils/string';

const EntityTypeIcon: FC<{ Icon: React.ElementType, size?: number }> = ({ Icon, size }) => {
  const theme = useTheme();

  const iconSize = size || theme.spacing(12);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: iconSize,
        height: iconSize,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
      }}
    >
      <Icon />
    </Stack>
  );
};

type CollapsibleSectionProps = {
  label: string;
};

const CollapsibleSection: FCC<CollapsibleSectionProps> = ({ label, children }) => {
  const { t } = useTranslation(['entities', 'common']);
  const { isDownXs } = useBreakPointsSizes();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  return (
    <Stack
      spacing={2}
      sx={{
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 1,
        borderColor: theme.palette.grey[300],
        paddingTop: theme.spacing(2),
      }}
    >
      <Collapse
        collapsedSize={hidSpacing(4)}
        in={expanded}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: theme.spacing(1),
          }}
        >
          <Typography sx={getNoWrapStyle(1)} variant="subtitle2">
            {label}
          </Typography>
          <HIDLink
            Icon={expanded ? ExpandLess : ExpandMore}
            label={
              isDownXs
                ? ''
                : expanded ? t('common:show_less') : t('common:show_more')
            }
            underline="none"
            onClick={(event) => {
              event.stopPropagation();
              setExpanded(!expanded);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{
            margin: theme.spacing(0, -1),
            width: `calc(100% + ${theme.spacing(2)}`,
          }}
        >
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const TimelineEventContent: FC<{ event: TimelineEvent }> = ({ event }) => {
  const { t } = useTranslation(['entities', 'common']);
  const theme = useTheme();

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const getEntityTypeInfo = useGetEntityInfo();

  const imageSize = isDownLg ? hidSpacing(10) : hidSpacing(12);

  const dateLabel = event.dateHeader
    || (isDownSm ? formatDate(new Date(event.start), DateTimeFormats.DATE_ONLY_TEXT_SHORT) : '');

  const { isMenuOpened } = useSelector((state: RootState) => state.layout);

  const itemsCount = R.cond([
    [() => isDownXs, R.always(2)],
    [() => isDownSm, R.always(3)],
    [() => isDownMd, R.always(4)],
    [() => isDownLg, R.always(3)],
    [() => isDownXl, R.always(isMenuOpened ? 3 : 4)],
    [R.T, R.always(5)],
  ])();

  const imageWidth = (100 / itemsCount) - 0.01;

  const blobsCount = event.blobs?.length || 0;
  const connectionsCount = event.connections?.length || 0;
  const hasBlobsOrConnections = blobsCount > 0 || connectionsCount > 0;

  const filesLabel = blobsCount > 0
    ? `${blobsCount} ${blobsCount > 1 ? t('common:files') : t('common:file')}`
    : '';

  const connectionsLabel = connectionsCount > 0
    ? `${connectionsCount} ${connectionsCount > 1 ? t('common:connections') : t('common:connection')}`
    : '';

  const countLabel = hasBlobsOrConnections
    ? [filesLabel, connectionsLabel].filter(Boolean).join(' & ')
    : '';

  return (
    <Stack spacing={2} sx={{ flex: 1 }}>
      {Boolean(dateLabel) && (
        <Typography
          sx={{
            color: theme.palette.grey[600],
            marginRight: theme.spacing(2.5),
          }}
        >
          {dateLabel}
        </Typography>
      )}
      <Typography
        sx={{
          marginRight: !dateLabel ? theme.spacing(2.5) : 0,
        }}
        variant="subtitle1"
      >
        {htmlDecode(event.title)}
      </Typography>
      {Boolean(event.annotation) && (
        <Typography whiteSpace="pre-line">
          {event.annotation}
        </Typography>
      )}
      {hasBlobsOrConnections && (
        <CollapsibleSection label={countLabel}>
          {
            event.blobs
              ?.map((blob) => (
                <Stack
                  alignItems="center"
                  key={blob.id}
                  spacing={1}
                  sx={{
                    marginBottom: theme.spacing(2),
                    width: `${imageWidth}%`,
                  }}
                >
                  <ContentImage
                    blobs={[blob]}
                    size={imageSize}
                    sx={{
                      height: imageSize,
                    }}
                  />
                  <Typography variant="body2">
                    {t('common:file')}
                  </Typography>
                </Stack>
              ))
          }
          {
            event.connections
              ?.map((connection: EntityContentConnection) => ({ connection, info: getEntityTypeInfo(connection.type as EntityType) }))
              ?.map(({ connection, info }) => (
                <Stack
                  alignItems="center"
                  key={connection.entity.id}
                  spacing={1}
                  sx={{
                    marginBottom: theme.spacing(2),
                    width: `${imageWidth}%`,
                  }}
                >
                  {
                    !R.isNil(connection.entity.image)
                      ? (
                        <ContentImage
                          blobs={
                            connection.entity.image.thumbnailUrl
                              ? [{
                                thumbnailUrl: connection.entity.image.thumbnailUrl,
                                mime: connection.entity.image.mime,
                              } as HIDBlob]
                              : undefined
                          }
                          size={imageSize}
                          sx={{
                            justifyContent: 'center',
                            height: imageSize,
                          }}
                        />
                      )
                      : info?.Icon
                        ? <EntityTypeIcon Icon={info.Icon} size={imageSize} />
                        : <MuiIcon />
                  }
                  <Typography variant="body2">
                    {info?.name || ''}
                  </Typography>
                </Stack>
              ))
          }
        </CollapsibleSection>
      )}
      {event.author?.name !== undefined && (
        <HIDAvatar
          showLabel
          givenName={event.author.name}
          size="small"
        />
      )}
    </Stack>
  );
};

export default React.memo(TimelineEventContent, (prevProps, nextProps) => prevProps.event.key === nextProps.event.key);
