import React from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import PartnersLogin from '../pages/PartnersLogin';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import HIDRootLayout from '../../../components/layouts/HIDRootLayout';
import ForumExpertThreads from '../../Forum/pages/forumExpertThreads/ForumExpertThreads';
import ForumTopic from '../../Forum/pages/ForumTopic';
import ForumThread from '../../Forum/pages/forumThread/ForumThread';
import ForumExperts from '../../Forum/pages/ForumExperts';

const PartnersRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.PARTNERS_LOGIN,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.PARTNERS_LOGIN}
        propertyRequired={false}
      >
        <PartnersLogin />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.PARTNERS,
    element: <HIDRootLayout />,
    children: [
      {
        path: RouteNames.PARTNERS_HOME,
        element: (
          <ProtectedRoute
            authRequired
            path={RouteNames.PARTNERS_HOME}
            propertyRequired={false}
          >
            <ForumExpertThreads />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.PARTNERS_FORUM,
        element: (
          <ProtectedRoute
            authRequired
            path={RouteNames.PARTNERS_FORUM}
            propertyRequired={false}
          >
            <ForumExpertThreads />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.PARTNERS_FORUM_TOPIC,
        element: (
          <ProtectedRoute
            authRequired
            path={RouteNames.PARTNERS_FORUM_TOPIC}
            propertyRequired={false}
          >
            <ForumTopic />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.PARTNERS_FORUM_THREAD,
        element: (
          <ProtectedRoute
            authRequired
            path={RouteNames.PARTNERS_FORUM_THREAD}
            propertyRequired={false}
          >
            <ForumThread />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.PARTNERS_FORUM_EXPERTS,
        element: (
          <ProtectedRoute
            authRequired
            path={RouteNames.PARTNERS_FORUM_EXPERTS}
            propertyRequired={false}
          >
            <ForumExperts />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default PartnersRouteConfig;
