import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getSuggestionCategoriesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SUGGESTION_CATEGORIES,
  { propertyId },
);

type GetSuggestionsPath = PropertyIdNavigation & { entityType: string };
export const getSuggestionsPath = ({ propertyId, entityType }: GetSuggestionsPath) => generateRoutePath(
  RouteNames.SUGGESTIONS,
  { propertyId, entityType },
);

export const getSuggestionsSettingsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.SUGGESTION_SETTINGS,
  { propertyId },
);
