import {
  Stack,
  useTheme,
} from '@mui/material';
import React from 'react';

import { FCC } from '../../../../../../../../../types/common';
import TimelineSkeletonSection from './TimelineSkeletonSection';

const TimelineLoaderSkeleton: FCC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  const theme = useTheme();

  return isLoading
    ? (
      <Stack sx={{ padding: theme.spacing(1) }}>
        <TimelineSkeletonSection theme={theme} />
        <TimelineSkeletonSection theme={theme} />
        <TimelineSkeletonSection theme={theme} />
        <TimelineSkeletonSection isLast theme={theme} />
      </Stack>
    )
    : children;
};

export default TimelineLoaderSkeleton;
