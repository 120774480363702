import React, {
  useState,
} from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useUpdateEffect } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetSuggestionsSummaryQuery } from '../api/api.suggestion';
import { useRouteParams } from '../../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDInlineSelect from '../../../../../../../components/HIDInlineSelect';
import { getSuggestionsPath } from '../navigation.suggestion';
import { ALL_SUGGESTIONS_CATEGORY } from '../contacts.suggestion';
import { toggleSideDrawerOpen } from '../../../../../../../store/layoutReducer';
import useGetSuggestionCategories from '../hooks/useGetSuggestionCategories';
import ListStructureLoaderSkeleton from '../../../../../../../components/skeletonLoaders/ListStructureLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const SuggestionsQuickNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['receipts']);

  const { entityType } = useRouteParams<{ entityType: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { currentData: suggestionsSummary, isLoading } = useGetSuggestionsSummaryQuery(propertyId ? { propertyId } : skipToken);

  const selectItems = useGetSuggestionCategories(suggestionsSummary);

  const [selectedEntityType, setSelectedEntityType] = useState<string | undefined>(entityType);

  useUpdateEffect(() => {
    navigate(
      getPathWithPropertyIdOrInit(getSuggestionsPath, { propertyId, entityType: selectedEntityType || ALL_SUGGESTIONS_CATEGORY }),
      { replace: true },
    );
    dispatch(toggleSideDrawerOpen(false));
  }, [selectedEntityType]);

  return (
    <ListStructureLoaderSkeleton isLoading={isLoading}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('receipts:receipts_receipts_title')}
        </Typography>
        <HIDInlineSelect
          items={selectItems}
          value={selectedEntityType || ALL_SUGGESTIONS_CATEGORY}
          onChange={setSelectedEntityType}
        />
      </Stack>
    </ListStructureLoaderSkeleton>
  );
};

export default SuggestionsQuickNavigation;
