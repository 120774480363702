import React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const FlagGB: Icon = ({
  size,
}) => (
  <HIDSvgIcon size={size}>
    <circle
      cx="12"
      cy="12"
      fill="#f0f0f0"
      r="11.98333"
    />
    <path d="m2.49385,4.70431c-0.9413,1.22469 -1.65108,2.63624 -2.06436,4.16968l6.23405,0l-4.16968,-4.16968zm21.07666,4.16968c-0.41328,-1.5334 -1.12311,-2.94495 -2.06436,-4.16964l-4.16959,4.16964l6.23395,0zm-23.14102,6.25216c0.41333,1.5334 1.12311,2.94495 2.06436,4.16959l4.16954,-4.16959l-6.23391,0zm18.86621,-12.63225c-1.22469,-0.9413 -2.63619,-1.65108 -4.16964,-2.06441l0,6.234l4.16964,-4.16959zm-14.59139,19.01221c1.22469,0.9413 2.63624,1.65108 4.16964,2.06441l0,-6.23395l-4.16964,4.16954zm4.16959,-21.07661c-1.5334,0.41333 -2.94495,1.12311 -4.16959,2.06436l4.16959,4.16959l0,-6.23395zm6.25221,23.14102c1.5334,-0.41333 2.94495,-1.12311 4.16959,-2.06436l-4.16959,-4.16959l0,6.23395zm2.21046,-8.44436l4.16959,4.16964c0.94125,-1.22464 1.65108,-2.63624 2.06436,-4.16964l-6.23395,0z" fill="#0052b4" />
    <g>
      <path d="m23.88189,10.43697l-10.31882,0l0,-10.31886a12.10297,12.10297 0 0 0 -1.56308,-0.10144c-0.52984,0 -1.0514,0.03483 -1.56303,0.10144l0,10.31882l-10.31886,0a12.10297,12.10297 0 0 0 -0.10144,1.56308c0,0.52984 0.03483,1.0514 0.10144,1.56303l10.31882,0l0,10.31886a12.09333,12.09333 0 0 0 3.1261,0l0,-10.31882l10.31886,0a12.1019,12.1019 0 0 0 0.10144,-1.56308c0,-0.52975 -0.03483,-1.0514 -0.10144,-1.56303z" fill="#d80027" />
      <path d="m15.1261,15.12615l5.34737,5.34737a12.0131,12.0131 0 0 0 0.70439,-0.76932l-4.5781,-4.5781l-1.47367,0l0,0.00005zm-6.25221,0l-0.00009,0l-5.34733,5.34733a12.0131,12.0131 0 0 0 0.76932,0.70439l4.5781,-4.57819l0,-1.47353zm0,-6.25216l0,-0.00009l-5.34737,-5.34742a12.0131,12.0131 0 0 0 -0.70439,0.76932l4.57815,4.57815l1.47362,0l0,0.00005zm6.25221,0l5.34742,-5.34747a11.99868,11.99868 0 0 0 -0.76932,-0.70435l-4.5781,4.57815l0,1.47367z" fill="#d80027" />
    </g>
  </HIDSvgIcon>
);

export default FlagGB;
