import { BuildingEntityType } from '../../../../../constants/entityType';
import { QueryHook } from '../../../../../types/common';
import { BuildingTemplate } from '../types.buildings';
import useGetBuildingTemplatesMap from './useGetBuildingTemplatesMap';

type UseGetBuildingTemplate = QueryHook<BuildingTemplate | undefined, { buildingEntityType: BuildingEntityType, templateId: string }>;

const useGetBuildingTemplate: UseGetBuildingTemplate = (args, options) => {
  const { data: templatesMap, ...rest } = useGetBuildingTemplatesMap(
    args?.buildingEntityType ? { buildingEntityType: args?.buildingEntityType } : undefined,
    { skip: Boolean(!args?.buildingEntityType) || options?.skip },
  );

  const template = templatesMap && args?.templateId ? templatesMap[args.templateId] : undefined;

  return {
    data: template,
    ...rest,
  };
};

export default useGetBuildingTemplate;
