import React, { FC } from 'react';

import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';
import HIDTypography from '../../../../../../../components/HIDTypography';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import { SellingPropertyInformationTextData } from '../../../types.sellingProperty';

const SellingPropertyInfoHomeAndAccommodation: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationTextData>> = ({
  section,
}) => (
  <PropertyProgressCategorySectionContainer
    Content={
      <HIDTypography variant="body1">
        {section.data.description}
      </HIDTypography>
    }
    key={section.id}
    section={section}
  />
);

export default SellingPropertyInfoHomeAndAccommodation;
