import React, { FC } from 'react';
import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import HIDButton from '../../../../../components/buttons/HIDButton';
import { getNoWrapStyle } from '../../../../../utils/style';
import { PropertyDocumentType } from '../types.propertyProgress';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';

type PropertyDocumentTemplatesListProps = {
  propertyDocumentTemplates?: Array<PropertyDocumentType>;
  title?: string;
  onClick?: (templateType: string) => void;
};

const PropertyDocumentTemplatesList: FC<PropertyDocumentTemplatesListProps> = ({
  propertyDocumentTemplates,
  title,
  onClick,
}) => {
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  return canCreate && propertyDocumentTemplates?.length
    ? (
      <Stack spacing={1}>
        {Boolean(title) && (
          <Typography variant="subtitle1">
            {title}
          </Typography>
        )}
        <Grid container spacing={1.5} xxs={12}>
          {
            propertyDocumentTemplates.map((template) => (
              <Grid
                item
                key={template.type}
                md={6}
                xxs={12}
              >
                <HIDButton
                  fullWidth
                  Icon={Add}
                  childrenContainerSx={getNoWrapStyle(1)}
                  color="secondary"
                  onClick={onClick ? () => onClick(template.type) : undefined}
                >
                  {template.name}
                </HIDButton>
              </Grid>
            ))
          }
        </Grid>
      </Stack>
    )
    : null;
};

export default PropertyDocumentTemplatesList;
