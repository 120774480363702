import React from 'react';
import {
  Button,
  ButtonProps,
} from '@mui/material';
import { FCC } from '../../types/common';
import AppStoreLabel from './AppStoreLabel';

type AppStoreButtonProps = {
  onClick: ButtonProps['onClick']
};

const AppStoreButton: FCC<AppStoreButtonProps> = ({
  onClick,
  sx,
}) => (
  <Button
    style={{ padding: 0 }}
    sx={sx}
    onClick={onClick}
  >
    <AppStoreLabel iconColor="white" />
  </Button>
);

export default AppStoreButton;
