import React, { FC } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import HIDInlineSelect from '../../../../../components/HIDInlineSelect';
import { toggleSideDrawerOpen } from '../../../../../store/layoutReducer';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertiesQuery } from '../../../api/api.property';
import { useGetUserStorageUsageQuery } from '../../../../Auth/api/api.user';
import { arrToMap } from '../../../../../utils/array';
import { useSetUserSettingsMutation } from '../../../../Auth/api/api.settings';
import {
  getPropertyStoragePath,
  getUserStoragePath,
} from '../../../navigation/navigation.property';
import { Property } from '@house-id/houseid-types/dist/property';

export const ALL_CATEGORY = 'all';

const PropertyStorageQuickNavigation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation(['settings', 'property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [setUserSettings] = useSetUserSettingsMutation();

  const { data: properties = [] } = useGetPropertiesQuery();
  const { data: storageUsage } = useGetUserStorageUsageQuery();

  const propertiesIdNameMap = arrToMap(
    (property: Property) => ([property.id, property.displayName]),
    properties,
  );

  const handleCategoryChange = (propertyIdOrAll: string) => {
    if (propertyIdOrAll === ALL_CATEGORY) {
      navigate(getUserStoragePath());
    } else {
      setUserSettings({ currentPropertyId: propertyIdOrAll });
      navigate(getPropertyStoragePath({ propertyId: propertyIdOrAll }));
    }

    dispatch(toggleSideDrawerOpen(false));
  };

  const propertyItems = storageUsage?.properties?.map((property) => ({
    id: property.propertyId,
    label: propertiesIdNameMap[property.propertyId] || t('property:unverified_property'),
  })) || [];

  const items = [
    {
      id: ALL_CATEGORY,
      label: t('settings:settings_all_storage'),
    },
    ...propertyItems,
  ];

  return (
    <Stack>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        {t('property:property_settings_properties_action')}
      </Typography>
      <HIDInlineSelect
        items={items}
        value={propertyId}
        onChange={handleCategoryChange}
      />
    </Stack>
  );
};

export default PropertyStorageQuickNavigation;
