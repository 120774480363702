import React, {
  FC,
} from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import {
  Suggestion,
  SuggestionType,
} from '../types.suggestion';
import SuggestionRow, { SuggestionRowProps } from './SuggestionRow';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useGetSuggestionMetadata, { SuggestionMetadata } from '../hooks/useGetSuggestionMetadata';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import { useIgnoreSuggestionMutation } from '../api/api.suggestion';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type SuggestionsListProps = {
  suggestions: Suggestion['suggestions'];
  entityId: Suggestion['entityId'];
  entityType: Suggestion['entityType'];
  mode?: SuggestionRowProps['mode'];
  onFixSuggestion?: (suggestionKey: string) => void;
  onIgnoreSuggestion?: (suggestionKey: string) => void;
};

const SuggestionsList: FC<SuggestionsListProps> = ({
  suggestions,
  entityId,
  entityType,
  mode,
  onFixSuggestion,
  onIgnoreSuggestion,
}) => {
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [ignoreSuggestion] = useIgnoreSuggestionMutation();

  const getEntityInfo = useGetEntityInfo();
  const { findSuggestionMetadata } = useGetSuggestionMetadata();

  const entityInfo = getEntityInfo(entityType);

  const mappedSuggestions: Array<SuggestionMetadata> = suggestions
    .map((suggestionType) => findSuggestionMetadata(entityType, suggestionType))
    .filter(Boolean);

  if (!mappedSuggestions.length) {
    return null;
  }

  const handleFixSuggestion = (suggestionKey: string) => {
    if (entityInfo?.getUpdateLink) {
      const link = getPathWithPropertyIdOrInit(entityInfo.getUpdateLink, { id: entityId, propertyId, suggestionKey });
      navigate(link);
    }
  };

  const handleIgnoreSuggestion = (suggestionKey: string) => {
    if (propertyId) {
      ignoreSuggestion({
        propertyId,
        entityId,
        entityType,
        entitySuggestions: { [suggestionKey as SuggestionType]: { muted: true } },
      });
    }
  };

  return (
    <Stack spacing={2}>
      {
        mappedSuggestions.map(({ id: suggestionKey, name }) => (
          <SuggestionRow
            key={suggestionKey}
            mode={mode}
            name={name}
            suggestionKey={suggestionKey}
            onFixSuggestion={onFixSuggestion || handleFixSuggestion}
            onIgnoreSuggestion={onIgnoreSuggestion || handleIgnoreSuggestion}
          />
        ))
      }
    </Stack>
  );
};

export default SuggestionsList;
