import { Stack } from '@mui/material';
import React from 'react';

import HIDTypography from '../../../../../components/HIDTypography';
import { FCC } from '../../../../../types/common';
import { formatPercentage } from '../../../../../utils/number';

type RateComparisonCardProps = {
  Icon?: React.ReactNode;
  rate?: number;
  subtitle: string;
  subtitleDescription?: string;
  wholeNumber?: boolean;
  backgroundColor?: string,
};

const RateComparisonCard: FCC<RateComparisonCardProps> = ({
  Icon,
  rate = 0,
  subtitle,
  subtitleDescription,
  wholeNumber = false,
  backgroundColor,
  style,
}) => (
  <Stack
    spacing={1}
    style={style}
    sx={{
      padding: 2,
      alignItems: 'center',
      backgroundColor,
      borderRadius: 2,
    }}
  >
    {Icon}
    <HIDTypography variant="h6">
      {formatPercentage(rate, { wholeNumber })}
    </HIDTypography>
    <HIDTypography variant="body1">
      {subtitle}
    </HIDTypography>
    {subtitleDescription && (
      <HIDTypography variant="body1">
        {subtitleDescription}
      </HIDTypography>
    )}
  </Stack>
);

export default RateComparisonCard;
