import React, {
  FC,
  useMemo,
} from 'react';
import {
  ExpandMore,
} from '@mui/icons-material';
import {
  ListItemIcon,
  MenuItem,
  Typography,
  Select,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import useGetEntitiesInfo from '../../../hooks/useGetEntitiesInfo';
import { ALL_SEARCH_RESULTS_CATEGORY } from '../constants.search';

type EntityTypeSelectProps = {
  value?: EntityType;
  onSelect: (event: SelectChangeEvent) => void;
};

const EntityTypeSelect: FC<EntityTypeSelectProps> = ({
  value,
  onSelect,
}) => {
  const { t, i18n: { language } } = useTranslation(['common']);

  const { isDownMd } = useBreakPointsSizes();

  const theme = useTheme();

  const entitiesInfo = useGetEntitiesInfo();

  const entitiesList = useMemo(() => {
    const availableEntities = Object.values(entitiesInfo)
      .filter((item) => item.isSearchable)
      .map((item) => ({
        value: item.type,
        label: item.name,
        Icon: item.Icon,
      }));

    return [{
      value: ALL_SEARCH_RESULTS_CATEGORY,
      label: t('common:all_label'),
      Icon: null,
    },
    ...availableEntities]
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [language]);

  const renderEntityTypeSelectValue = (val: EntityType | string) => {
    const option = entitiesList.find((item) => item.value === val);

    if (!option) {
      return t('common:all_label');
    }

    return isDownMd && option.Icon
      ? <option.Icon iconColor="currentColor" size={17} />
      : option.label;
  };

  return (
    <Select
      autoWidth
      IconComponent={ExpandMore}
      inputProps={{ 'aria-label': 'Entity type select' }}
      renderValue={renderEntityTypeSelectValue}
      size="small"
      sx={{
        maxWidth: isDownMd ? 100 : 'unset',
        borderTopLeftRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        '& .MuiSvgIcon-root': {
          color: 'currentColor',
        },
        '& .MuiSelect-select > svg': {
          marginBottom: '-2px',
          fontSize: 17,
        },
      }}
      value={value}
      onChange={onSelect}
    >
      {
        entitiesList.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              {item.Icon && <item.Icon />}
            </ListItemIcon>
            <Typography variant="inherit">
              {item.label}
            </Typography>
          </MenuItem>
        ))
      }
    </Select>
  );
};

export default EntityTypeSelect;
