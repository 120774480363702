import React, {
  useEffect,
  useState,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
  useTheme,
  Stack,
  Fade,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
  Persistence,
  AuthError,
} from 'firebase/auth';
import * as R from 'ramda';
import {
  useAuthState,
  useSignInWithEmailAndPassword,
} from 'react-firebase-hooks/auth';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { partnersAuth } from '../../../external-services/firebase';
import HIDButton from '../../../components/buttons/HIDButton';
import RouteNames from '../../../routes/RouteNames';
import loginImageUrl from '../../../assets/images/partners_login.jpg';
import HIDIconButton from '../../../components/buttons/HIDIconButton';
import HIDLogoIcon from '../../../components/icons/HIDLogoIcon';
import { HIDZIndex } from '../../../constants/layout';
import HIDTextField from '../../../components/HIDTextField';
import ErrorBox from '../../Auth/components/ErrorBox';
import useGetAuthErrorMessage from '../../Auth/hooks/useGetAuthErrorMessage';
import LoginLayout from '../../Auth/pages/LoginLayout';
import { getLoginPath } from '../../Auth/navigation/navigation.auth';
import HIDLink from '../../../components/HIDLink';

const PartnersLogin = () => {
  const { t } = useTranslation(['auth', 'forms_common', 'partners']);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [currentUser, userLoading] = useAuthState(partnersAuth);

  const [userNotExistError, setUserNotExistError] = useState<AuthError | undefined>();

  const [showLoading, setShowLoading] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [
    signInWithEmailAndPassword,
    _userSignedWithEmailAndPassword,
    signingInWithEmail,
    signInWithEmailError,
  ] = useSignInWithEmailAndPassword(partnersAuth);

  const hasAnyHistoryEntry = location.key === 'default';

  const type: Persistence = rememberMe
    ? browserLocalPersistence
    : browserSessionPersistence;

  const logInWithEmailAndPassword = (userEmail: string, userPassword: string) => {
    setPersistence(partnersAuth, type)
      .then(() => signInWithEmailAndPassword(userEmail, userPassword))
      .catch(() => {
        // TODO: Add error reporting
      });
  };

  const handleLogin = () => {
    setUserNotExistError(undefined);
    logInWithEmailAndPassword(email, password);
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked);

  const authError: AuthError | undefined = signInWithEmailError || userNotExistError;
  const authErrorMessage = useGetAuthErrorMessage(authError);

  const handleNavigateToLogin = () => navigate(getLoginPath());

  useEffect(() => {
    if (userLoading === false) {
      setShowLoading(false);
    }
  }, [userLoading]);

  useEffect(() => {
    if (R.isNotNil(currentUser)) {
      navigate(RouteNames.INIT);
    }
  }, [currentUser, location, signInWithEmailAndPassword]);

  return (
    <>
      <Fade in={showLoading} timeout={300}>
        <Stack
          alignItems="center"
          height="100vh"
          justifyContent="center"
          sx={{
            zIndex: HIDZIndex.LOADER,
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: theme.palette.common.white,
          }}
          width="100vw"
        >
          <HIDLogoIcon
            className="pulse"
            iconColor={theme.palette.primary.main}
            size={64}
          />
        </Stack>
      </Fade>
      {!showLoading && (
        <LoginLayout
          RightComponent={
            <Stack spacing={2}>
              <Typography
                gutterBottom
                fontWeight="bold"
                style={{ marginBottom: theme.spacing(2) }}
                variant="h3"
              >
                {t('partners:partners_welcome_to_log_in_as_a_partner')}
              </Typography>
              {authErrorMessage && (
                <ErrorBox
                  message={authErrorMessage}
                />
              )}
              <HIDTextField
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  sx: { background: theme.palette.common.transparent },
                }}
                id="email"
                label={t('forms_common:email_title')}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <HIDTextField
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HIDIconButton
                        Icon={passwordVisible ? VisibilityOff : Visibility}
                        color="primary"
                        sx={{ marginBottom: 2 }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      />
                    </InputAdornment>
                  ),
                  sx: { background: theme.palette.common.transparent },
                }}
                id="password"
                label={t('forms_common:password_title')}
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={rememberMe}
                      onChange={handleRememberMe}
                    />
                  }
                  label={t('auth:login_remember_me')}
                />
              </Stack>
              <HIDButton
                fullWidth
                disabled={!email || !password}
                loading={signingInWithEmail}
                size="large"
                onClick={handleLogin}
              >
                {t('auth:login_login_button')}
              </HIDButton>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Typography>
                  {t('partners:partners_are_you_a_regular_user')}
                </Typography>
                <HIDLink label={t('auth:login_here')} onClick={handleNavigateToLogin} />
              </Stack>
            </Stack>
          }
          backgroundImageUrl={loginImageUrl}
          canGoBack={hasAnyHistoryEntry}
          message={t('partners:partners_together_we_help_swedens_home_owners')}
        />
      )}
    </>
  );
};

export default PartnersLogin;
