import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Picture: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1045 21 21 20.1046 21 19V5C21 3.89543 20.1045 3 19 3Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.99998 11C10.1045 11 11 10.1047 11 9.00002C11 7.89544 10.1045 7 8.99998 7C7.89542 7 7 7.89544 7 9.00002C7 10.1047 7.89542 11 8.99998 11Z"
        fill={fillColor}
      />
      <path
        d="M21 15L16 9.99997L5 21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Picture;
