import React, {
  FC,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import Bonus from '../../../../../../components/icons/Bonus';
import { hidSpacing } from '../../../../../../utils/number';
import { useGetPropertyBonusOffersSummaryQuery } from '../../../../api/api.property';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import BonusOffersBaseSlideLayout from './BonusOffersBaseSlideLayout';
import { getMyTotalBonusPointsPath } from '../../../../modules/BonusOffers/navigation.bonusOffers';

const MyTotalBonusPointsSlide: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation(['home', 'common']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: bonuses } = useGetPropertyBonusOffersSummaryQuery(propertyId ? { propertyId } : skipToken);

  const {
    availablePoints = 0,
    earnedBonusPoints,
  } = bonuses || {};

  const handleNavigateToMyTotalBonusPoints = () => propertyId ? navigate(getMyTotalBonusPointsPath({ propertyId })) : undefined;

  return (
    <BonusOffersBaseSlideLayout
      TitleComponent={
        <>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: theme.spacing(8),
              height: theme.spacing(8),
              borderRadius: theme.spacing(4),
              backgroundColor: theme.palette.primary.lighter,
            }}
          >
            <Bonus
              fillColor={theme.palette.primary.main}
              iconColor={theme.palette.primary.main}
              size={hidSpacing(4)}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home:home_total_bonus_points')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('common:points', { points: availablePoints })}
            </Typography>
            {Boolean(earnedBonusPoints?.points) && (
              <Typography sx={{ textAlign: 'center' }}>
                {/* @ts-ignore */}
                {t('home:home_last_month_count', { count: t('common:points', { points: earnedBonusPoints?.points }) })}
              </Typography>
            )}
          </Stack>
        </>
      }
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
      onClick={handleNavigateToMyTotalBonusPoints}
    />
  );
};

export default MyTotalBonusPointsSlide;
