import {
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Progress } from '@house-id/houseid-types/dist/propertyProgress';

import { FCC } from '../../../../../types/common';

type PropertyProgressCategoryBadgeProps = {
  progress?: Progress;
};

const PropertyProgressCategoryBadge: FCC<PropertyProgressCategoryBadgeProps> = ({ progress, sx }) => {
  const theme = useTheme();
  const isCompleted = progress?.total === progress?.completed;

  const count = progress ? `${progress?.completed}/${progress?.total}` : '';

  return progress?.total
    ? (
      <Typography
        sx={{
          backgroundColor: isCompleted ? theme.palette.primary.main : theme.palette.error.main,
          color: theme.palette.common.white,
          borderRadius: theme.spacing(3),
          padding: theme.spacing(0, 0.75),
          fontWeight: 'bold',
          ...sx,
        }}
        variant="caption"
      >
        {count}
      </Typography>
    )
    : null;
};

export default PropertyProgressCategoryBadge;
