import RouteNames from '../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../utils/routes';
import { PropertyIdNavigation } from '../../types/types.property';

export const getPropertyProgressPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_PROGRESS,
  { propertyId },
);

export const getPropertySpecificationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_PROGRESS_SPECIFICATION,
  { propertyId },
);

export const getPropertyBlueprintsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_BLUEPRINTS,
  { propertyId },
);

export const getPropertyPurchasePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_PURCHASE,
  { propertyId },
);

export const getPropertyToDoPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_TODO,
  { propertyId },
);

export const getPropertyPlanningPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_PLANNING,
  { propertyId },
);

export const getPropertyDocumentationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_DOCUMENTATION,
  { propertyId },
);

export const getPropertyProductsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_PRODUCTS,
  { propertyId },
);

export const getPropertyInsuranceAndSafetyPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_INSURANCE_AND_SAFETY,
  { propertyId },
);

export const getPropertyFinancePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_FINANCE,
  { propertyId },
);
