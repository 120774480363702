import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HomeSuccess: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M 20 8 L 11 1 L 2 8 L 2 19 C 2 19.53 2.21 20.039 2.586 20.414 C 2.961 20.789 3.469 21 4 21 L 7.5 21 M 18 15.5 L 15.25 18.25 L 14 17 M 7 13 L 7 10 L 9 10 M 21.999 17 C 21.999 18.591 21.367 20.117 20.241 21.242 C 19.116 22.368 17.59 23 15.999 23 C 14.407 23 12.881 22.368 11.756 21.242 C 10.631 20.117 9.999 18.591 9.999 17 C 9.999 15.408 10.631 13.882 11.756 12.757 C 12.881 11.632 14.407 11 15.999 11 C 17.59 11 19.116 11.632 20.241 12.757 C 21.367 13.882 21.999 15.408 21.999 17 Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default HomeSuccess;
