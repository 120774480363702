import React, { useMemo } from 'react';
import { Stack } from '@mui/material';

import { FCC } from '../../types/common';

type HIDImagePreviewProps = {
  file: File,
};

const HIDImagePreview: FCC<HIDImagePreviewProps> = ({
  file,
  sx,
  style,
}) => {
  const preview = useMemo(() => URL.createObjectURL(file), [file]);

  return (
    <Stack
      style={style}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <img
        src={preview}
        style={{
          objectFit: 'cover',
          width: '100%',
          aspectRatio: 1,
        }}
        // NOTE: Revoke data uri after image is loaded
        onLoad={() => URL.revokeObjectURL(preview)}
      />
    </Stack>
  );
};

export default HIDImagePreview;
