import React from 'react';
import {
  Grid,
  Box,
  Typography,
} from '@mui/material';
import {
  FieldArray,
} from 'formik';
import {
  BudgetTemplateCategoryId,
  PaymentMonth,
} from '@house-id/houseid-types/dist/finances/budgets';

import { CurrencyAdornment } from '../../../../../../../../../components/HIDTextField';
import DebouncedTextField from './DebouncedTextField';
import getMonthsList from '../utils/getMonthsList';
import {
  FCC,
} from '../../../../../../../../../types/common';
import { getPaymentMonthsDataPath } from '../utils/utils.formikFieldDataPath';

type MonthlyAllocatedPaymentsProps = {
  paymentMonths?: Array<PaymentMonth<number | undefined>>;
  label?: string;
  subcategoryIndex: number;
  categoryId: BudgetTemplateCategoryId;
  inputsPerRow?: 3 | 4;
};

const MonthlyAllocatedBudget: FCC<MonthlyAllocatedPaymentsProps> = ({
  label,
  inputsPerRow = 3,
  paymentMonths,
  categoryId,
  subcategoryIndex,
}) => {
  const months = getMonthsList();

  const paymentMonthsFieldPath = getPaymentMonthsDataPath(categoryId, subcategoryIndex);

  return paymentMonths?.length
    ? (
      <Box>
        {Boolean(label) && (
          <Typography
            fontWeight="bold"
            variant="body1"
          >
            {label}
          </Typography>
        )}
        <FieldArray
          name={paymentMonthsFieldPath}
          render={() => (
            <Grid
              container
              spacing={2}
              sx={{ marginTop: 1 }}
            >
              {paymentMonths.map(({ month }, index) => (
                <Grid
                  item
                  key={month}
                  md={12 / inputsPerRow}
                  sm={inputsPerRow === 4 ? 6 : 4}
                  xxs={6}
                >
                  <DebouncedTextField
                    endAdornment={<CurrencyAdornment />}
                    fieldPath={`${paymentMonthsFieldPath}.${index}.amount`}
                    inputProps={{
                      min: 0,
                      step: 0.1,
                    }}
                    key={month}
                    label={months[month - 1]?.name}
                    type="number"
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      </Box>
    )
    : null;
};

export default MonthlyAllocatedBudget;
