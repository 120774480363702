import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Bar,
} from 'recharts';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../../../types/common';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import { formatMoney } from '../../../../../../../../../utils/string';
import { TimeIntervalsGroupingType } from '../../../../../types/types.content';
import SummaryChartTooltip from '../../../../../../../../../components/charts/SummaryChartTooltip';
import StyledChartWrapper from '../../../../../../../../../components/charts/StyledChartWrapper';
import BaseChartLayout from '../../../../../../../../../components/charts/BaseChartLayout';
import HIDTypography from '../../../../../../../../../components/HIDTypography';

type ProductsSummaryChartProps = {
  minHeight?: number;
  isLoading: boolean;
  data: Array<{ name: string, amount?: number, lastAmount?: number }>;
  groupingType?: TimeIntervalsGroupingType;
};

const ProductsSummaryChart: FCC<ProductsSummaryChartProps> = ({
  minHeight = 220,
  data,
  isLoading,
  groupingType,
  style,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'products', 'home']);

  const lastDatePointLabel = groupingType === TimeIntervalsGroupingType.Monthly
    ? `${t('home:home_last_month')} ${t('products:product_price').toLowerCase()}`
    : `${t('home:home_last_year')} ${t('products:product_price').toLowerCase()}`;

  const getTooltipCountLabel = (payload: object) => (
    <Stack
      alignItems="center"
      direction="row"
      key="quantity"
      spacing={1}
    >
      <Typography style={{ color: theme.palette.common.white }} sx={{ marginLeft: 2 }} variant="body2">
        {`${t('common:quantity')}:`}
      </Typography>
      <Typography style={{ color: theme.palette.common.white }} variant="body2">
        {(payload as { count?: number }).count || 0}
      </Typography>
    </Stack>
  );

  const totalAmount = R.sum(
    R.map(({ amount, lastAmount }) => amount || lastAmount || 0, data),
  );

  return (
    <StyledChartWrapper style={style}>
      <HIDTypography isLoading={isLoading} skeletonWidth="250px" variant="subtitle1">
        {t('products:product_price')}
      </HIDTypography>
      <HIDInfo
        description={t('products:product_total_amount_description')}
        isLoading={isLoading}
        label={formatMoney(totalAmount)}
        labelVariant="h3"
        skeletonWidth="200px"
        sx={{ margin: theme.spacing(1, 0, 1.5, 0) }}
      />
      <BaseChartLayout
        ChartTooltipComponent={<SummaryChartTooltip getCustomPayloadItem={getTooltipCountLabel} />}
        data={data}
        isLoading={isLoading}
        maxBarSize={80}
        minHeight={minHeight}
      >
        <Bar
          dataKey="amount"
          fill={theme.palette.primary.main}
          name={`${t('home:home_earlier')} ${t('products:product_price').toLowerCase()}`}
          stackId="stack"
        />
        <Bar
          dataKey="lastAmount"
          fill={theme.palette.warning.main}
          name={lastDatePointLabel}
          stackId="stack"
        />
      </BaseChartLayout>
    </StyledChartWrapper>
  );
};

export default ProductsSummaryChart;
