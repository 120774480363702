import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  Add,
  DeleteOutline,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { useGetEntityTypeNames } from '../../../../../constants/entityTypeName';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import {
  HIDEntityId,
  FCC,
} from '../../../../../types/common';
import useGetAddEntityConnectionAction from '../hooks/useGetAddEntityConnectionAction';
import useGetAddEntityConnectionInitialData from '../hooks/useGetAddEntityConnectionInitialData';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import ManageConnections from '../../../../../components/icons/ManageConnections';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';

type ConnectionsListToolbarProps = {
  showTitle?: boolean;
  sourceEntity: Required<HIDEntityId>;
  sourceEntityType: EntityType;
  connectionEntityType: EntityType;
  onDelete: () => void;
};

const ConnectionsListToolbar: FCC<ConnectionsListToolbarProps> = ({
  showTitle = true,
  sourceEntityType,
  sourceEntity,
  connectionEntityType,
  onDelete,
}) => {
  const { t } = useTranslation(['common']);

  const addNewConnectionAction = useGetAddEntityConnectionAction(connectionEntityType);
  // eslint-disable-next-line deprecation/deprecation
  const EntityNames = useGetEntityTypeNames();

  const { data: { canCreate, canUpdate, canDelete } = {} } = useGetPropertyPermissions();

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);

  const handleAddExisting = () => {
    openManageConnectionsDialog({
      entityType: sourceEntityType,
      entity: sourceEntity,
      activeEntityType: connectionEntityType,
    });
  };

  const getEntityMapper = useGetAddEntityConnectionInitialData(sourceEntityType);
  const handleAddNew = () => {
    const entityInitialData = getEntityMapper(connectionEntityType, sourceEntity);
    addNewConnectionAction(
      [{
        entityType: sourceEntityType,
        entityId: sourceEntity.id,
      }],
      R.fromPairs(entityInitialData.map((item) => [item.key, item.value])),
    );
  };

  const actions = [
    canCreate && {
      id: 'new',
      label: t('common:add_new'),
      Icon: Add,
      onClick: handleAddNew,
    },
    canCreate && canUpdate && {
      id: 'existing',
      label: t('common:add_existing_item'),
      Icon: ManageConnections,
      onClick: handleAddExisting,
    },
    onDelete && canDelete && {
      id: 'remove',
      label: t('common:delete_label'),
      Icon: DeleteOutline,
      onClick: onDelete,
    },
  ].filter(Boolean);

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      spacing={1.5}
    >
      {showTitle && (
        <Typography variant="subtitle1">
          {EntityNames[connectionEntityType] || connectionEntityType}
        </Typography>
      )}
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        spacing={1.5}
      >
        {actions.map((action) => (
          <HIDIconButton
            Icon={action.Icon}
            key={action.id}
            title={action.label}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={action.onClick}
          />
        ))}
      </Stack>
    </Stack>

  );
};

export default ConnectionsListToolbar;
