import {
  Stack,
  styled,
} from '@mui/material';

import { HEADER_HEIGHT } from '../constants/layout';
import { hidSpacing } from '../utils/number';

const HIDFiltersContainer = styled(Stack)(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'clip',
  paddingRight: theme.spacing(2),
  // TODO: consider better solution if possible
  maxHeight: `calc(100vh - ${HEADER_HEIGHT + hidSpacing(2.5) + 400}px)`,
  minHeight: 114,
}));

export default HIDFiltersContainer;
