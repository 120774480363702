import React, { FC } from 'react';

import {
  Add,
  DeleteOutline,
  ArrowForward,
  EditOutlined,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import HIDActionsSection from '../../../../../../components/HIDActionsSection';
import { HIDListItemProps } from '../../../../../../types/common';
import useGetPropertyPermissions from '../../../../hooks/useGetPropertyPermissions';

type ListEntitiesActionsProps = {
  title?: string;
  disabled?: boolean;
  customActions?: Array<HIDListItemProps>;
  onAdd?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  onMove?: () => void;
  onSearch?: () => void;
};

const ListEntitiesActions: FC<ListEntitiesActionsProps> = ({
  title,
  disabled,
  customActions,
  onAdd,
  onUpdate,
  onDelete,
  onMove,
  onSearch,
}) => {
  const { t } = useTranslation(['common']);

  const { data: { canCreate, canUpdate, canDelete } = {} } = useGetPropertyPermissions();

  const actions = [
    onSearch && {
      id: 'search',
      Icon: Search,
      label: t('common:search'),
      disabled,
      onClick: onSearch,
    },
    onAdd && canCreate && {
      id: 'add',
      Icon: Add,
      label: t('common:add_label'),
      disabled,
      onClick: onAdd,
    },
    onMove && canUpdate && {
      id: 'move',
      Icon: ArrowForward,
      label: t('common:move_label'),
      disabled,
      onClick: onMove,
    },
    onUpdate && canUpdate && {
      id: 'edit',
      Icon: EditOutlined,
      label: t('common:update_label'),
      disabled,
      onClick: onUpdate,
    },
    onDelete && canDelete && {
      id: 'delete',
      Icon: DeleteOutline,
      label: t('common:delete_label'),
      disabled,
      onClick: onDelete,
    },
    ...(customActions || []),
  ].filter(Boolean);

  return actions.length
    ? (
      <HIDActionsSection
        items={actions}
        title={title || t('common:manage')}
      />
    )
    : null;
};

export default ListEntitiesActions;
