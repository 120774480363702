import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Umbrella: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M6.25 21.9593L12 12M17 3.33975C12.6868 0.849531 7.28964 1.93762 4.246 5.68294C3.94893 6.0485 3.80039 6.23127 3.75718 6.49316C3.7228 6.70152 3.77373 6.97765 3.88018 7.16003C4.01398 7.38927 4.25111 7.52618 4.72539 7.8L19.2746 16.2C19.7489 16.4738 19.986 16.6107 20.2514 16.612C20.4626 16.613 20.7272 16.519 20.8905 16.3851C21.0957 16.2167 21.1797 15.9967 21.3477 15.5566C23.0695 11.0481 21.3132 5.82996 17 3.33975ZM17 3.33975C15.0868 2.23518 11.2973 5.21707 8.5359 10M17 3.33975C18.9132 4.44432 18.2255 9.21707 15.4641 14M22 22H2"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Umbrella;
