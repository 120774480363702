import React from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts';

import { formatMoney } from '../../utils/string';

type SummaryChartTooltipProps = TooltipProps<ValueType, NameType> & {
  getCustomPayloadItem?: (payload: object) => React.JSX.Element;
};

const SummaryChartTooltip = ({
  active,
  payload,
  label,
  getCustomPayloadItem,
}: SummaryChartTooltipProps) => {
  const theme = useTheme();

  if (active && payload && payload?.length) {
    return (
      <Card
        sx={{
          backgroundColor: theme.palette.common.black,
          padding: 1.5,
        }}
      >
        <Typography color={theme.palette.common.white} variant="subtitle2">
          {label}
        </Typography>
        <Stack spacing={0.5}>
          {payload.map((item, index) => (
            <React.Fragment key={item.dataKey}>
              <Stack
                alignItems="center"
                direction="row"
                key={`${item.dataKey || ''}-${index}`}
                spacing={1}
              >
                <div
                  style={{
                    width: theme.spacing(1),
                    height: theme.spacing(1),
                    borderRadius: theme.spacing(0.5),
                    background: item.color,
                  }}
                />
                <Typography style={{ color: theme.palette.common.white }} variant="body2">
                  {`${item.name || ''}:`}
                </Typography>
                <Typography style={{ color: theme.palette.common.white }} variant="body2">
                  {formatMoney(item.value as number) || ''}
                </Typography>
              </Stack>
              {
                getCustomPayloadItem
                  ? (
                    <React.Fragment key={`${item.dataKey || ''}-${index}-custom`}>
                      {getCustomPayloadItem(item.payload as object)}
                    </React.Fragment>
                  )
                  : null
              }
            </React.Fragment>
          ))}
        </Stack>
      </Card>
    );
  }

  return null;
};

export default SummaryChartTooltip;
