import {
  Theme,
  useMediaQuery,
} from '@mui/material';

const useBreakPointsSizes = () => {
  const isDownXl = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const isDownLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isDownXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isDownXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xxs'));

  return {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
    isDownXxs,
  };
};

export default useBreakPointsSizes;
