import React from 'react';
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  FormatUnderlined,
  Link,
} from '@mui/icons-material';
import {
  Separator,
  Toolbar,
  Editor,
  EditorProvider,
  createButton,
} from 'react-simple-wysiwyg';
import { Stack } from '@mui/material';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { FCC } from '../../../../../types/common';

const linkPlaceholder = 'HID_LINK_PLACEHOLDER';

type ForumTextEditorProps = {
  value: string;
  onChange: (newValue: string) => void;
};

const ForumTextEditor: FCC<ForumTextEditorProps> = ({
  style,
  sx,
  value,
  onChange,
}) => {
  const BoldButton = createButton('Bold', <FormatBold />, 'bold');
  const ItalicButton = createButton('Italic', <FormatItalic />, 'italic');
  const UnderlinedButton = createButton('Underlined', <FormatUnderlined />, 'underline');
  const StrikeThroughButton = createButton('Strike through', <FormatStrikethrough />, 'strikeThrough');

  const NumberedListButton = createButton('Numbered list', <FormatListNumbered />, 'insertOrderedList');
  const BulletListButton = createButton('Bullet list', <FormatListBulleted />, 'insertUnorderedList');

  const [openPromptLinkDialog] = useDialog(DialogNames.PROMPT_LINK_DIALOG);

  const LinkButton = createButton('Link', <Link />, (editorState) => {
    if (editorState.$selection?.nodeName === 'A') {
      // eslint-disable-next-line deprecation/deprecation
      document.execCommand('unlink');
    } else {
      const selectedText = window.getSelection()?.toString();

      if (selectedText) {
        // eslint-disable-next-line deprecation/deprecation
        document.execCommand('createLink', false, linkPlaceholder);
        const linkElement = document.querySelector(`a[href="${linkPlaceholder}"`) as HTMLElement | undefined;

        openPromptLinkDialog({
          onCancel: () => {
            editorState.$el?.focus();
            // eslint-disable-next-line deprecation/deprecation
            document.execCommand('undo');
          },
          onChange: (url: string) => {
            if (linkElement) {
              linkElement.setAttribute('target', '_blank');
              linkElement.setAttribute('href', url);
            }
          },
        });
      } else {
        // eslint-disable-next-line deprecation/deprecation
        document.execCommand('createLink', false, linkPlaceholder);

        const linkElement = document.querySelector(`a[href="${linkPlaceholder}"`) as HTMLElement | undefined;
        if (linkElement) {
          linkElement.style.setProperty('visibility', 'hidden');
        }

        openPromptLinkDialog({
          onCancel: () => {
            linkElement?.remove();
          },
          onChange: (url: string) => {
            if (linkElement) {
              linkElement.setAttribute('target', '_blank');
              linkElement.setAttribute('href', url);
              linkElement.innerHTML = url;
              linkElement.style.setProperty('visibility', 'visible');
            }
          },
        });
      }
    }
  });

  return (
    <Stack style={style} sx={sx}>
      <EditorProvider>
        <Editor
          style={{ minHeight: 88 }}
          value={value}
          onChange={({ target }) => onChange(target.value)}
        >
          <Toolbar>
            <BoldButton />
            <ItalicButton />
            <UnderlinedButton />
            <StrikeThroughButton />
            <Separator />
            <NumberedListButton />
            <BulletListButton />
            <Separator />
            <LinkButton />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </Stack>
  );
};

export default ForumTextEditor;
