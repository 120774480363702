import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import HIDButton from '../../../components/buttons/HIDButton';
import { FCC } from '../../../types/common';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';
import Paper from '../../../components/icons/Paper';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';

const ForumRules: FCC = ({ sx, style }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forum']);

  const [openWelcomeToForumDialog] = useDialog(DialogNames.WELCOME_TO_FORUM_DIALOG);

  const handleClick = () => {
    dispatch(toggleSideDrawerOpen(false));
    openWelcomeToForumDialog();
  };

  return (
    <Stack
      direction="column"
      spacing={2.5}
      style={style}
      sx={sx}
    >
      <Stack direction="row" spacing={1.5}>
        <Paper iconColor={theme.palette.primary.main} />
        <Typography style={{ lineHeight: '18px' }} variant="subtitle1">
          {t('forum:forum_rules')}
        </Typography>
      </Stack>
      <HIDButton color="secondary" onClick={handleClick}>
        {t('common:read_more')}
      </HIDButton>
    </Stack>
  );
};

export default ForumRules;
