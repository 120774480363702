export enum DynamicInputFormFieldTypes {
  ADDRESS = 'ADDRESS',
  ADD_BUTTON = 'ADD_BUTTON',
  ARROW_BUTTON = 'ARROW_BUTTON',
  BUTTON = 'BUTTON',
  BUTTON_GROUP = 'BUTTON_GROUP',
  DATE_PICKER = 'DATE_PICKER',
  DROP_DOWN = 'DROP_DOWN',
  DOCUMENT = 'DOCUMENT',
  CHECKBOX = 'CHECKBOX',
  COUNTER = 'COUNTER',
  EXTERNAL_SERVICE_INPUT = 'EXTERNAL_SERVICE_INPUT',
  FACT = 'FACT',
  FACTS_CONTAINER = 'FACTS_CONTAINER',
  FACTS_GROUP = 'FACTS_GROUP',
  FILE_PICKER = 'FILE_PICKER',
  GEO_POINT = 'GEO_POINT',
  HEADER = 'HEADER',
  IMAGE = 'IMAGE',
  INPUT = 'INPUT',
  INPUT_AREA = 'INPUT_AREA',
  INPUT_CURRENCY = 'INPUT_CURRENCY',
  MARKDOWN_TEXT = 'MARKDOWN_TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  LINKING_BUTTON = 'LINKING_BUTTON',
  LIST = 'LIST',
  NAVIGATION_BUTTON = 'NAVIGATION_BUTTON',
  RADIO_BUTTON_LIST = 'RADIONBUTTON_LIST',
  RECTANGLE_BUTTON = 'RECTANGLE_BUTTON',
  SECTION = 'SECTION',
  SLIDER = 'SLIDER',
  TEXT_LINK = 'TEXT_LINK',
  TEXT = 'TEXT',
}
