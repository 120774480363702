import { HIDBlob } from '@house-id/houseid-types/dist/common';

export enum ThumbnailSize {
  MAX_500 = 'max_500',
  MAX_2600 = 'max_2600',
  JPEG = 'jpeg',
}

export const getImageThumbnailUrl = (thumbnailUrlTemplate: string, thumbnailSize: ThumbnailSize) =>
  thumbnailUrlTemplate?.replace('{format}', thumbnailSize);

export const getFirstLargeImageBlob = (blob: HIDBlob) => blob.exif && blob.exif.width >= 1000;
