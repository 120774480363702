import React, { FC } from 'react';
import {
  Grid,
  Stack,
} from '@mui/material';
import {
  FormikErrors,
  FormikProps,
  FormikTouched,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { DeleteOutline } from '@mui/icons-material';
import * as Yup from 'yup';

import {
  Photo,
  PhotosFormFields,
} from '../types.photo';
import PhotoImage from './PhotoImage';
import HIDTextField from '../../../../../../../components/HIDTextField';
import { getHandleSetField } from '../../../../../../../utils/form';
import HIDFormDatePicker from '../../../../../../../components/datePicker/HIDFormDatePicker';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';

export const getPhotoSchema = (requiredFieldMessage: string) =>
  Yup.object<Photo>({
    id: Yup.string().optional(),
    name: Yup.string().required(requiredFieldMessage),
    date: Yup.string().optional().nullable(),
    place: Yup.string().optional().nullable(),
  }).required();

type PhotoFormProps = {
  index: number;
  photo: Photo;
  formik: FormikProps<PhotosFormFields>,
  touched?: boolean;
  onDelete?: () => void;
};

const PhotoForm: FC<PhotoFormProps> = ({
  index,
  photo,
  formik,
  touched,
  onDelete,
}) => {
  const { t } = useTranslation(['forms_common', 'photos']);

  const { isDownSm } = useBreakPointsSizes();

  const handleSetField = getHandleSetField<PhotosFormFields>(formik);

  const formikPhoto = formik.values.photos?.[index];
  const formikErrors = formik.errors.photos as unknown as FormikErrors<Array<Photo>>;
  const formikError = formikErrors?.[index];
  const formikTouchedItems = formik.touched.photos as unknown as FormikTouched<Array<Photo>>;
  const formikTouched = formikTouchedItems?.[index];

  return (
    <Grid
      container
      alignItems="center"
      spacing={isDownSm ? 2 : 3}
      sx={{
        position: 'relative',
        width: 'unset',
      }}
    >
      <Grid
        item
        xs={4}
        xxs={12}
      >
        <PhotoImage photo={photo} />
      </Grid>
      <Grid
        item
        alignSelf="flex-start"
        xs={8}
        xxs={12}
      >
        <Stack spacing={1}>
          {Boolean(onDelete) && (
            <Stack
              direction="row"
              justifyContent="flex-end"
            >
              <HIDIconButton
                Icon={DeleteOutline}
                onClick={onDelete}
              >
                <DeleteOutline />
              </HIDIconButton>
            </Stack>
          )}
          <HIDTextField
            required
            error={Boolean((formikTouched?.name || touched) && formikError?.name)}
            helperText={(formikTouched?.name || touched) ? formikError?.name : undefined}
            id="name"
            label={t('forms_common:enter_name')}
            value={formikPhoto?.name}
            onBlur={formik.handleBlur(`photos.${index}.name`)}
            onChange={handleSetField(`photos.${index}.name`)}
          />
          <HIDTextField
            id="place"
            label={t('photos:photo_location')}
            value={formikPhoto?.place || ''}
            onBlur={formik.handleBlur(`photos.${index}.place`)}
            onChange={handleSetField(`photos.${index}.place`)}
          />
          <HIDFormDatePicker
            label={t('photos:enter_date')}
            value={formikPhoto?.date ? new Date(formikPhoto?.date) : undefined}
            onBlur={formik.handleBlur(`photos.${index}.date`)}
            onChange={(date) => formik.setFieldValue(`photos.${index}.date`, date?.toISOString())}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PhotoForm;
