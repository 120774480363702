import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Paper: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M3 2C3.53043 2 4.03914 2.21072 4.41421 2.58579C4.78929 2.96086 5 3.46957 5 4L5 20C5 20.5304 5.21072 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22M3 2C2.46957 2 1.96086 2.21072 1.58579 2.58579C1.21071 2.96086 1 3.46957 1 4C1 5.1 1.9 6 3 6L5 6M3 2L17 2C17.5304 2 18.0391 2.21071 18.4142 2.58579C18.7893 2.96086 19 3.46957 19 4L19 18.5M7 22C7.53043 22 8.03914 21.7893 8.41421 21.4142C8.78929 21.0391 9 20.5304 9 20V18.5H23V20C23 20.5304 22.7893 21.0391 22.4142 21.4142C22.0391 21.7893 21.5304 22 21 22H7ZM16 7H8M16 10H8M14 13H8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Paper;
