import * as R from 'ramda';
import {
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  ReceiptCategory,
  ReceiptsSummaryValue,
} from '@house-id/houseid-types/dist/content/receipt';

import { ReceiptsSummaryChartDataPoint } from './pages/Receipts/components/ReceiptsSummaryChart';
import { ALL_RECEIPTS_CATEGORY } from './constants.receipt';
import { TimeIntervalsGroupingType } from '../../types/types.content';
import {
  getDateChartGroupingKey,
  getDateChartGroupingKeyByIndex,
} from '../../utils/chartData';

const mapReceiptSummaryValue = (
  chartGroupingType: TimeIntervalsGroupingType,
  { date, totalAmount, totalDeductible }: ReceiptsSummaryValue,
): ReceiptsSummaryChartDataPoint => ({
  name: getDateChartGroupingKey(chartGroupingType, new Date(date)),
  amount: totalAmount,
  deductibleAmount: totalDeductible,
});

export const getReceiptsSummaryChartData = (
  summaryValues: Array<ReceiptsSummaryValue>,
  chartGroupingType = TimeIntervalsGroupingType.Yearly,
) => {
  if (summaryValues.length <= 1) {
    return summaryValues.map((value) => mapReceiptSummaryValue(chartGroupingType, value));
  }

  const summaryDatesMap = R.indexBy(({ date }) => getDateChartGroupingKey(chartGroupingType, new Date(date)), summaryValues);

  const fromDate = new Date((R.head(summaryValues) as ReceiptsSummaryValue).date);
  const toDate = new Date((R.last(summaryValues) as ReceiptsSummaryValue).date);

  const dataPointsCount = chartGroupingType === TimeIntervalsGroupingType.Yearly
    ? differenceInYears(toDate, fromDate)
    : differenceInMonths(toDate, fromDate);

  const mappedSummaryValues = R.range(0, dataPointsCount + 1)
    .map((index: number) => {
      const key = getDateChartGroupingKeyByIndex(chartGroupingType, fromDate, index);

      return summaryDatesMap[key]
        ? mapReceiptSummaryValue(chartGroupingType, summaryDatesMap[key])
        : {
          name: key,
          amount: 0,
          deductibleAmount: 0,
        };
    });

  return mappedSummaryValues;
};

export const useGetReceiptCategoryName = (categoryId: string | undefined, categories: Array<ReceiptCategory>): string => {
  const { t } = useTranslation(['receipts', 'entities']);

  return categoryId === ALL_RECEIPTS_CATEGORY
    ? t('receipts:receipts_receipts_categories_all_receipts')
    : categories
      ?.find((category) => category.id === categoryId)
      ?.name || t('entities:receipt_plural');
};
