import React from 'react';
import { Add } from '@mui/icons-material';

import HIDLink from './HIDLink';
import { FCC } from '../types/common';

type HIDAddLinkButtonProps = {
  label: string;
  onClick: () => void;
};

const HIDAddLinkButton: FCC<HIDAddLinkButtonProps> = ({
  label,
  sx,
  onClick,
}) => (
  <HIDLink
    Icon={Add}
    label={label}
    sx={sx}
    underline="none"
    onClick={onClick}
  />
);

export default HIDAddLinkButton;
