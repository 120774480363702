import React, { FC } from 'react';
import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import HIDImage from '../../../../../components/image/HIDImage';
import { ICON_SIZE } from '../../../../../constants/layout';
import { openUrlNewTab } from '../../../../../utils/file';
import { PropertyLink } from '../types.propertyProgress';
import PropertyProgressContentItem from './PropertyProgressContentItem';

type PropertyDocumentsListProps = {
  externalDocuments?: Array<PropertyLink>;
  title?: string,
};

const PropertyExternalDocumentList: FC<PropertyDocumentsListProps> = ({
  externalDocuments,
  title = '',
}) =>
  externalDocuments?.length
    ? (
      <Stack spacing={1}>
        <Typography
          variant="subtitle1"
        >
          {title}
        </Typography>
        <Grid
          container
          spacing={1.5}
          xxs={12}
        >
          {
            externalDocuments.map((link) => (
              <Grid
                item
                key={link.name}
                md={6}
                xxs={12}
              >
                <PropertyProgressContentItem
                  Icon={() => (
                    <Stack>
                      {link.icon
                        ? <HIDImage externalUrl={link.icon.url} style={{ width: ICON_SIZE, height: ICON_SIZE }} />
                        : <AttachFileIcon />}
                    </Stack>
                  )}
                  externalImage={link.url}
                  key={link.url}
                  title={link.name}
                  onNavigate={() => openUrlNewTab(link.url)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Stack>
    )
    : null;

export default PropertyExternalDocumentList;
