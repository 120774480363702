import React, {
  FC,
  useMemo,
  useRef,
} from 'react';
import {
  GridEventListener,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid';
import {
  Box,
  Skeleton,
  styled,
} from '@mui/material';

import { getRandomBetween } from '../../../../../utils/random';
import { CHEVRON_FIELD_ID } from '../../../../../constants/columns';

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

type ListLayoutLoadingSkeletonProps = {
  minRows: number;
  minRowHeight: number;
};

const DataGridLoadingSkeleton: FC<ListLayoutLoadingSkeletonProps> = ({ minRows, minRowHeight }) => {
  const apiRef = useGridApiContext();
  const loadingSkeletonRef = useRef<HTMLDivElement>(null);
  const dimensions = apiRef.current.getRootDimensions();
  const columns = apiRef.current.getVisibleColumns();

  const minViewportHeight = minRows * minRowHeight;
  let viewportHeight = dimensions?.viewportInnerSize.height;
  let skeletonRowsCount = minRows;

  if (!viewportHeight || (viewportHeight !== undefined && viewportHeight <= minViewportHeight)) {
    viewportHeight = minViewportHeight;
  } else {
    skeletonRowsCount = Math.ceil(viewportHeight / minRowHeight);
  }

  const skeletonRows = useMemo(() => {
    const array: React.ReactNode[] = [];

    for (let i = 0; i < skeletonRowsCount; i++) {
      for (const column of columns) {
        const width = getRandomBetween(25, 75);
        array.push(
          <SkeletonCell
            key={`column-${i}-${column.field}`}
            sx={{ justifyContent: column.align, padding: '0 5px' }}
          >
            <Skeleton variant="rounded" width={`${width}%`} />
          </SkeletonCell>,
        );
      }
    }

    return array;
  }, [skeletonRowsCount]);

  const handleScrollChange: GridEventListener<'scrollPositionChange'> = (
    params,
  ) => {
    if (loadingSkeletonRef.current) {
      loadingSkeletonRef.current.scrollLeft = params.left;
    }
  };

  useGridApiEventHandler(apiRef, 'scrollPositionChange', handleScrollChange);

  return (
    <div
      ref={loadingSkeletonRef}
      style={{
        display: 'grid',
        gridTemplateColumns: `${columns
          .map(({ computedWidth, field }) => `${field !== CHEVRON_FIELD_ID ? computedWidth : 0}px`)
          .join(' ')}`,
        gridAutoRows: `${minRowHeight}px`,
        overflowX: 'hidden',
      }}
    >
      {skeletonRows}
    </div>
  );
};

export default DataGridLoadingSkeleton;
