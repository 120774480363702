import React, { FC } from 'react';
import {
  Badge,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronRight,
} from '@mui/icons-material';

import HIDClickable from '../../../../../components/HIDClickable';
import useGetPropertyProgressCategoryMap from '../../../modules/PropertyProgress/hooks/useGetPropertyProgressCategoryMap';
import HIDImage from '../../../../../components/image/HIDImage';
import { PropertyProgressCategory } from '@house-id/houseid-types/dist/propertyProgress';

type SellingPropertyTasksListItemProps = {
  category: PropertyProgressCategory;
  count: number;
  onClick: () => void;
};

const SellingPropertyTasksListItem: FC<SellingPropertyTasksListItemProps> = ({
  category,
  count,
  onClick,
}) => {
  const theme = useTheme();

  const propertyProgressCategoryMap = useGetPropertyProgressCategoryMap();
  const CategoryIcon = propertyProgressCategoryMap[category.id]?.Icon;

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={2.5}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        padding: theme.spacing(2, 2, 1.5),
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        height: '100%',
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.info.lightest,
          borderRadius: '5px',
          padding: 1.45,
        }}
      >
        <Badge
          badgeContent={count}
          color="error"
          componentsProps={{
            badge: {
              style: {
                borderRadius: '2px',
              },
            },
          }}
          sx={{
            left: '2px',
            top: '2px',
            position: 'absolute',
          }}
        />
        {
          CategoryIcon
            ? <CategoryIcon size={32} />
            : category.iconUrl
              ? <HIDImage externalUrl={category.iconUrl} />
              : null
        }
      </Stack>
      <Typography sx={{ flex: 1 }} variant="body1">
        {category.name}
      </Typography>
      <ChevronRight />
    </HIDClickable>
  );
};

export default SellingPropertyTasksListItem;
