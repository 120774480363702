import React from 'react';
import {
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../types/common';
import BonusOffersSectionCard from './BonusOffersSectionCard';
import HIDButton from '../../../../../../../components/buttons/HIDButton';

type PurchaseCheapestOfferProps = {
  sx?: SxProps<Theme>;
  onClick: () => void;
};

const PurchaseCheapestOffer: FCC<PurchaseCheapestOfferProps> = ({
  sx,
  onClick,
}) => {
  const { t } = useTranslation(['bonus_offers']);

  return (
    <BonusOffersSectionCard
      alignContent="center"
      alignItems="center"
      spacing={2}
      sx={sx}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant="body1"
      >
        <ReactMarkdown components={{ p: 'span' }}>
          {t('bonus_offers:bonus_offers_view_offers_text')}
        </ReactMarkdown>
      </Typography>
      <HIDButton
        color="primary"
        onClick={onClick}
      >
        {t('bonus_offers:bonus_offers_view_offers_button')}
      </HIDButton>
    </BonusOffersSectionCard>
  );
};

export default PurchaseCheapestOffer;
