import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';
import {
  HIDBlob,
  HIDBlobModify,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { ALL_DOCUMENTS_CATEGORY } from '../../constants.document';
import {
  useCreateDocumentMutation,
  useGetAllDocumentCategoriesQuery,
  useGetDocumentWithCache,
  useUpdateDocumentMutation,
} from '../../api/api.document';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import HIDTextField from '../../../../../../../../components/HIDTextField';
import { getHandleSetField } from '../../../../../../../../utils/form';
import {
  getDocumentCategoriesPath,
  getDocumentCategoryPath,
  getDocumentPath,
} from '../../navigation.document';
import {
  HIDDocument,
  DocumentCategory,
  DocumentType,
} from '../../types.document';
import {
  CreateEntity,
  HIDEntityId,
} from '../../../../../../../../types/common';
import {
  getDocumentCategory,
  useGetDocumentCategoryName,
} from '../../utils.document';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import HIDFormSelect from '../../../../../../../../components/HIDFormSelect';
import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../utils/routes';
import ContentFileViewer from '../../../../components/ContentFileViewer';
import {
  useGetContentFileBlobsByIdsQuery,
} from '../../../ContentFile/api/api.contentFile';
import { ContentFile } from '../../../ContentFile/types.contentFile';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../../components/CreateContentPageBottomToolbar';
import { EntityConnectionsCommonRouteState } from '../../../../types/types.content';
import { IdPropRoute } from '../../../../../../../../types/route';
import useEntitySuggestionSection from '../../../Suggestion/hooks/useEntitySuggestionSection';
import { HIDImageViewerRef } from '../../../../../../../../components/image/HIDImageViewer/HIDImageViewer';
import { handleSelectSuggestion } from '../../../Suggestion/utils.suggestion';
import HIDFormDatePicker from '../../../../../../../../components/datePicker/HIDFormDatePicker';
import useManageConnectionAfterCreateOrUpdate from '../../../../hooks/useManageConnectionAfterCreateOrUpdate';
import HIDInfo from '../../../../../../../../components/HIDInfo';
import useGetLogAnalyticsEvent from '../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { AnalyticsEvent } from '../../../../../../../Analytics/types.analytics';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';

type CreateUpdateDocumentRouteParams = IdPropRoute & { category?: string };
type CreateUpdateDocumentRouteQueryParams = {
  typeId?: string;
  contentFileIds?: string;
  suggestionKey?: string;
};
type CreateUpdateDocumentRouteState = EntityConnectionsCommonRouteState<DocumentInitialData>;

type DocumentInitialData = {
  name: HIDDocument['name'];
  blobs?: HIDDocument['blobs']
};

const CreateUpdateDocument: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();

  const {
    routeParams: {
      id: documentId,
      category: categoryId,
    },
    queryParams: {
      typeId: typeIdSearchParam,
      contentFileIds: suggestionKeyParams,
      suggestionKey,
    },
    state,
  } = useNavigationParamsAndState<CreateUpdateDocumentRouteParams, CreateUpdateDocumentRouteQueryParams, CreateUpdateDocumentRouteState>();

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const typeId = typeIdSearchParam ? decodeURIComponent(typeIdSearchParam) : undefined;
  const contentFileIds = suggestionKeyParams?.split(',');

  const connections = state?.connections || [];
  const documentInitialData = state?.initialData;
  const hasConnections = !R.isEmpty(connections);

  const { t } = useTranslation(['common', 'forms_common', 'documents', 'products']);

  const { isDownMd } = useBreakPointsSizes();

  const isUpdate = Boolean(documentId);

  const {
    data: propertyId,
    isLoading: propertyIsLoading,
  } = useGetCurrentPropertyId();

  const [createDocument, { isLoading: isCreatingDocument }] = useCreateDocumentMutation();
  const [updateDocument, { isLoading: isUpdatingDocument }] = useUpdateDocumentMutation();

  const {
    document,
    isLoading: isDocumentLoading,
  } = useGetDocumentWithCache({ propertyId, documentId });

  const [blobs, setBlobs] = useState<Array<HIDBlob | HIDBlobModify>>([]);

  useEffect(() => {
    if (!blobs.length) {
      setBlobs(document?.blobs || []);
    }
  }, [document]);

  const handleGoBack = (entity?: HIDEntityId) => documentId
    ? navigateBackOr(getPathWithPropertyIdOrInit(getDocumentPath, { propertyId, categoryId, id: documentId }))
    : entity?.id
      ? navigate(getPathWithPropertyIdOrInit(getDocumentPath, { propertyId, id: entity.id }), { replace: true })
      : navigateBackOr(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId }));

  const { data: categories = [] } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const categoryName = useGetDocumentCategoryName(categoryId, categories);

  const imageViewerRef = useRef<HIDImageViewerRef | null>(null);
  const classificationTypeRef = useRef<HTMLInputElement>();

  const customFieldSuggestionAction: Record<string, () => void> = {
    file: () => setTimeout(() => imageViewerRef.current?.openFilePicker(), 300),
    classification_type: () => handleSelectSuggestion(classificationTypeRef),
  };

  const {
    SuggestionSection,
    refetchSuggestions,
  } = useEntitySuggestionSection({
    entityId: documentId,
    entityType: EntityType.DOCUMENT,
    customFieldSuggestionAction,
    initialSuggestion: suggestionKey,
  });

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.DOCUMENT,
    connections,
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (valuesWithSaveMode: WithSaveMode<CreateEntity<HIDDocument>>) => {
    const { saveMode } = valuesWithSaveMode;
    const values = R.omit(['saveMode'], valuesWithSaveMode);

    if (documentId) {
      updateDocument({
        id: documentId,
        ...values,
        blobs: [
          ...R.difference(document?.blobs || [], blobs)
            .map((blob) => ({ ...blob, action: ModifyActionType.DELETE })),
          ...R.difference(blobs, document?.blobs || [])
            .map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
        ],
      })
        .unwrap()
        .then((updatedDocument) => {
          refetchSuggestions();
          afterUpdate(updatedDocument, saveMode);
        });
    } else {
      createDocument({
        ...values,
        blobs: blobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
      })
        .unwrap()
        .then((createdDocument) => {
          logAnalyticsEvent({
            event: AnalyticsEvent.CREATE_DOCUMENT,
            hidCategory: EntityType.DOCUMENT,
          });
          afterCreate(createdDocument, saveMode);
        });
    }
  };

  const schema = Yup.object({
    name: Yup.string().required(t('forms_common:field_mandatory')),
    categoryId: Yup.string().required(t('forms_common:field_mandatory')),
    annotation: Yup.string().optional().nullable(),
    date: Yup.string().required(t('forms_common:field_mandatory')),
    classificationType: Yup.string().optional().nullable(),
    saveMode: Yup.string().optional().nullable(),
    movable: Yup.boolean().optional().nullable(),
  });

  const formik = useFormik<CreateEntity<HIDDocument>>({
    initialValues: {
      name: document?.name || documentInitialData?.name || '',
      categoryId: document?.categoryId || (categoryId === ALL_DOCUMENTS_CATEGORY ? '' : categoryId || ''),
      annotation: document?.annotation,
      date: document?.date,
      propertyId: propertyId || '',
      classificationType: document?.classificationType || typeId || '',
      movable: document?.movable,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleFormSubmit,
  });

  const handleSetField = getHandleSetField<CreateEntity<HIDDocument>>(formik);

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
  };

  const handleDeleteBlob = (id: string) => setBlobs(blobs.filter((blob) => blob.id !== id));

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map(({ blob }) => blob);
    setBlobs([...blobs, ...newBlobs]);

    if (isUpdate && propertyId && documentId) {
      const removedBlobs = R.difference(document?.blobs || [], blobs)
        .map((blob) => ({ ...blob, action: ModifyActionType.DELETE }));

      updateDocument({
        id: documentId,
        propertyId,
        blobs: removedBlobs.concat(newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE }))),
      });
    }
  };

  const { data: contentFileBlobs = [] } = useGetContentFileBlobsByIdsQuery({ propertyId, contentFileIds });

  useEffect(() => {
    if (contentFileBlobs.length || documentInitialData?.blobs) {
      const newBlobs = [...(contentFileBlobs || []), ...(documentInitialData?.blobs || [])];
      const uniqueBlobs = R.uniqBy((blob) => blob.id, [...blobs, ...newBlobs]);
      setBlobs(uniqueBlobs);
      if (contentFileBlobs.length && !isUpdate) {
        formik.setFieldValue('name', uniqueBlobs[0].name);
      }
    }
  }, [contentFileBlobs.length, documentInitialData]);

  const documentCategories = useMemo(() => categories.map(
    (category: DocumentCategory) => ({ id: category.id, name: category.name }),
  ), [categories]);

  const documentTypes = useMemo(
    () => {
      const currentCategory: DocumentCategory | undefined = getDocumentCategory(categories, formik.values.categoryId);
      return !currentCategory || currentCategory.types?.length === 0
        ? []
        : R.map((type: DocumentType) => ({ id: type.typeId, name: type.name }), currentCategory.types);
    },
    [categories, formik.values.categoryId],
  );

  useEffect(() => {
    if (typeId && !formik.values.categoryId && categories.length) {
      const typeCategory = categories.find((category) => category.types.some((type) => type.typeId === typeId));
      if (typeCategory) {
        formik.setFieldValue('categoryId', typeCategory.id);
      }
    }
  }, [typeId, documentTypes]);

  return (
    <HomeLayout
      SideColumn={
        <>
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            imageViewerRef={imageViewerRef}
            isLoading={propertyIsLoading || isDocumentLoading}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
          {SuggestionSection}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }),
          name: t('documents:documents_documents_title'),
        },
        categoryId && {
          link: getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId }),
          name: categoryName,
        },
      ].filter(Boolean)}
      title={isUpdate ? t('documents:documents_edit_document') : t('documents:documents_add_document')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        rowSpacing={2}
      >
        {isDownMd && (
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            imageViewerRef={imageViewerRef}
            isLoading={propertyIsLoading || isDocumentLoading}
            variant="inline"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
        )}
        <Grid item sm={6} xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : undefined}
            id="name"
            label={t('forms_common:name')}
            valid={Boolean(formik.touched.name && !formik.errors.name)}
            value={formik.values.name}
            onBlur={formik.handleBlur('name')}
            onChange={handleSetField('name')}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xxs={12}
        >
          <FormControlLabel
            control={<Checkbox checked={formik.values.movable} />}
            label={
              <HIDInfo
                description={t('documents:documents_private_document_description')}
                label={t('documents:documents_private_document')}
              />
            }
            onChange={(_event, checked) => formik.setFieldValue('movable', checked)}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDFormSelect
            required
            error={Boolean(formik.touched.categoryId && formik.errors.categoryId)}
            helperText={formik.touched.categoryId ? formik.errors.categoryId : undefined}
            items={documentCategories}
            label={t('common:category')}
            value={formik.values.categoryId}
            onBlur={formik.handleBlur('categoryId')}
            onChange={handleSetField('categoryId')}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDFormSelect
            disabled={!formik.values.categoryId}
            items={documentTypes}
            label={t('common:type')}
            ref={classificationTypeRef}
            value={formik.values.classificationType}
            onChange={handleSetField('classificationType')}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xxs={12}
        >
          <HIDFormDatePicker
            required
            error={Boolean(formik.touched.date && formik.errors.date)}
            helperText={formik.touched.date ? formik.errors.date : undefined}
            label={t('forms_common:enter_date')}
            value={formik.values.date ? new Date(formik.values.date) : undefined}
            onBlur={formik.handleBlur('date')}
            onChange={(date) => formik.setFieldValue('date', date?.toISOString())}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            multiline
            id="annotation"
            label={t('common:annotation')}
            value={formik.values.annotation}
            variant="outlined"
            onBlur={formik.handleBlur('annotation')}
            onChange={handleSetField('annotation')}
          />
        </Grid>
      </Grid>
      <CreateContentPageBottomToolbar
        loading={isCreatingDocument || isUpdatingDocument}
        showAddConnections={!isUpdate && !hasConnections}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateDocument;
