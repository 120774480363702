import * as R from 'ramda';
import { setMonth } from 'date-fns';

import { HIDSelectItem } from '../../../../../../../../../components/HIDFormSelect';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';

const now = new Date();

const getMonthsList = (
  monthFormat?: DateTimeFormats.MONTH_ONLY | DateTimeFormats.MONTH_FULL,
): Array<HIDSelectItem> => {
  const months = R.times((month) => {
    const monthName = formatDate(setMonth(now, month), monthFormat || DateTimeFormats.MONTH_FULL);

    return {
      id: String(month + 1),
      name: monthName ? monthName.charAt(0).toUpperCase() + monthName.slice(1) : '',
    };
  }, 12);

  return months;
};

export default getMonthsList;
