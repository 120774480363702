import { HIDBlobModify } from '@house-id/houseid-types/dist/common';

export type ForumExpert = {
  id: string;
  name: string;
  avatar?: string;
  description: string;
};

export type ForumTopic = {
  id: string;
  name: string;
  threadCount: number;
  createdAt: string;
  experts: Array<ForumExpert>;
};

export enum ForumAuthorType {
  ANONYMOUS = 'anonymous',
  USER = 'user',
  EXPERT = 'expert',
}

export type ForumUserAuthor = {
  id: string;
  givenName: string;
  surname?: string;
  type: ForumAuthorType;
  avatar?: string;
  me?: boolean;
};

export type ForumExpertAuthor = {
  id: string;
  type: ForumAuthorType;
  avatar?: string;
  me?: boolean;
  expert: {
    id: string;
    name: string;
  }
};

export type AnonymousAuthor = {
  type: ForumAuthorType;
  me?: boolean;
};

export type ForumAuthor = ForumUserAuthor | ForumExpertAuthor | AnonymousAuthor;

export enum ForumThreadTarget {
  COMMUNITY = 'community',
  EXPERT = 'expert',
}

export type ForumThread = {
  id: string;
  topicId: string;
  author: ForumAuthor;
  subject: string;
  body: string;
  replyCount: number;
  acceptedAnswerId?: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  lastExpertReply?: {
    author: ForumAuthor;
    timestamp: string;
  };
  usersReadCount: number;
  target: ForumThreadTarget;
  me?: {
    followed: boolean;
  };
  blobs?: Array<HIDBlobModify>;
};

export type ForumReply = {
  id: string;
  threadId: string;
  author: ForumAuthor;
  body: string;
  blobs?: Array<HIDBlobModify>;
  anonymous: boolean;
  score?: number;
  me?: {
    read?: boolean;
    vote?: ForumVote;
  }
  createdAt: string;
  deletedAt?: string;
};

export type ForumQuestion = {
  topicId: string;
  subject: string;
  body: string;
  anonymous: boolean;
  target: ForumThreadTarget;
  blobs: Array<{ id: string }>;
};

export enum ForumRepliesOrder {
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
  SCORE_DESC = 'score_desc',
}

export enum ForumThreadsSortOrder {
  LAST_UPDATE_DESC = 'last_updated_desc',
  USERS_READ_DESC = 'users_read_desc',
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
}

export enum ForumVote {
  UP_VOTE = 'up',
  DOWN_VOTE = 'down',
}
