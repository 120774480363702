import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyStatusQuery } from '../../../api/api.property';
import BonusOfferCardBadge from './BonusOfferCardBadge';

const MyBonusPoints: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['bonus_offers', 'common']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: propertyStatus,
  } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);
  const availablePoints = propertyStatus?.bonusPoints?.available || 0;

  return availablePoints
    ? (
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <Typography color={theme.palette.primary.light} variant="body1">
          {t('bonus_offers:bonus_offers_my_bonus_points')}
        </Typography>
        <BonusOfferCardBadge variant="green">
          {t('common:points', { points: availablePoints })}
        </BonusOfferCardBadge>
      </Stack>
    )
    : null;
};

export default MyBonusPoints;
