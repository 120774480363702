import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
// TODO: useGetEntityTypeNames is deprecated use useGetEntityInfo instead
/**
 * @deprecated The component should not be used.
 * Use useGetEntityInfo instead.
 */
export const useGetEntityTypeNames = (): Record<EntityType, string> => {
  const { t } = useTranslation(['entities']);

  // @ts-ignore
  return {
    [EntityType.BUILDING]: t('entities:building'),
    [EntityType.BUILDING_PART]: t('entities:building-part'),
    [EntityType.CONTACT]: t('entities:contact'),
    [EntityType.BUDGET]: t('entities:budget'),
    [EntityType.DOCUMENT]: t('entities:document'),
    [EntityType.NOTE]: t('entities:note'),
    [EntityType.OUTDOOR]: t('entities:outdoor'),
    [EntityType.PHOTO]: t('entities:photo'),
    [EntityType.PRODUCT]: t('entities:product'),
    [EntityType.PROPERTY]: t('entities:property'),
    [EntityType.RECEIPT]: t('entities:receipt'),
    [EntityType.ROOM]: t('entities:room'),
    [EntityType.TASK]: t('entities:task'),
    [EntityType.CONTENT_FILE]: t('entities:content-file'),
    [EntityType.RECURRING_EXPENSE]: t('entities:fixed_expense'),
    [EntityType.MESSAGE]: t('entities:message'),
    [EntityType.TIMELINE_ENTRY]: t('entities:timeline-entry'),
    [EntityType.VALUATION]: t('entities:valuation'),
    [EntityType.ARTICLE]: t('entities:article'),
    [EntityType.EXTERNAL_SERVICE]: t('entities:external_service'),
    [EntityType.BONUS_OFFER]: t('entities:bonus_offer'),
  };
};
