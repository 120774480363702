import { HIDApiTags } from '../../../../../../../api/HIDApiTags';

import { propertyApi } from '../../../../../api/api.property';
import { HIDApiCacheTimeouts } from '../../../../../../../api/HIDApiCacheTimeouts';
import { InsuranceCompany } from '../types.insurance';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const insuranceApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getInsuranceCompanies: builder.query<Array<InsuranceCompany>, void>({
      query: () => '/insurely/insurances/companies',
      keepUnusedDataFor: HIDApiCacheTimeouts.seconds_600,
      providesTags: (result) => provideArrayTags(HIDApiTags.INSURANCE_COMPANY, result),
    }),
  }),
});

export const {
  useGetInsuranceCompaniesQuery,
} = insuranceApi;
