import React, {
  FC,
  useEffect,
} from 'react';
import {
  Stack,
  Backdrop,
  Grid,
  useTheme,
} from '@mui/material';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Outlet } from 'react-router';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import {
  HEADER_HEIGHT,
  HIDZIndex,
  MAIN_CONTENT_MAX_SIZE,
} from '../../constants/layout';
import {
  FCC,
  HeaderProps,
  MenuProps,
} from '../../types/common';
import { RootState } from '../../store/store';
import {
  useGetHeaderBannerHeight,
  useGetMenuWidth,
} from '../../modules/Property/utils/utils.home';
import {
  toggleMenuOpen,
  toggleSearchVisibility,
} from '../../store/layoutReducer';

type HIDLayoutProps = {
  Menu?: FCC<MenuProps>;
  Header?: FCC<HeaderProps>;
  HeaderBanner?: FC;
};

const HIDLayout: FC<HIDLayoutProps> = ({
  Menu,
  Header,
  HeaderBanner,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isDownMd, isDownLg } = useBreakPointsSizes();

  const { isMenuOpened, isVisibleSearch } = useSelector((state: RootState) => state.layout);

  useEffect(() => {
    if (!isMenuOpened && !isDownLg) {
      dispatch(toggleMenuOpen(true));
    } else if (isMenuOpened && isDownLg) {
      dispatch(toggleMenuOpen(false));
    }
  }, [isDownLg]);

  const headerBannerHeight = useGetHeaderBannerHeight();
  const bannerHeight = HeaderBanner ? headerBannerHeight : 0;

  const menuWidthBasedOnScreenSize = useGetMenuWidth(isMenuOpened);
  const menuWidth = Menu ? menuWidthBasedOnScreenSize : 0;

  return (
    <>
      {
        HeaderBanner
          ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: headerBannerHeight,
                zIndex: HIDZIndex.HEADER,
              }}
            >
              <HeaderBanner />
            </Stack>
          )
          : null
      }
      <Grid
        container
        direction="row"
        flex={1}
        flexWrap="nowrap"
        sx={{
          minHeight: `calc(100vh - ${bannerHeight}px)`,
          backgroundColor: isDownMd ? theme.palette.common.white : theme.palette.grey[50],
        }}
      >
        {Menu !== undefined && (
          <Grid
            item
            style={{
              zIndex: HIDZIndex.MENU,
              position: 'fixed',
              top: bannerHeight,
              maxHeight: `calc(100% - ${bannerHeight}px)`,
              height: `calc(100dvh - ${bannerHeight}px)`,
            }}
          >
            <Menu
              open={isMenuOpened}
              sx={{ backgroundColor: theme.palette.common.white }}
              onToggleOpen={(open) => dispatch(toggleMenuOpen(open))}
            />
          </Grid>
        )}
        <Grid
          container
          item
          alignItems="flex-start"
          flex={1}
          flexDirection="column"
          maxWidth={`calc(100vw - ${menuWidth}px)`}
          style={{ marginLeft: menuWidth }}
        >
          {Header !== undefined && (
            <>
              <Grid
                item
                alignSelf="stretch"
                sx={{
                  position: 'fixed',
                  top: bannerHeight,
                  zIndex: HIDZIndex.HEADER,
                  width: `calc(100vw - ${menuWidth}px)`,
                  alignItems: 'center',
                  // NOTE: we only need it for windows
                  overflowY: 'scroll',
                }}
              >
                <Header
                  isMenuOpened={isMenuOpened}
                  isVisibleSearch={isVisibleSearch}
                  onToggleMenuOpen={(open) => dispatch(toggleMenuOpen(open))}
                  onToggleOpenSearch={(isVisible) => dispatch(toggleSearchVisibility({ isVisibleSearch: isVisible }))}
                />
              </Grid>
              <Backdrop
                open={isMenuOpened && isDownMd}
                sx={{
                  zIndex: HIDZIndex.BACKDROP,
                  backgroundColor: theme.palette.shadow[200],
                }}
                onClick={() => dispatch(toggleMenuOpen(false))}
              />
            </>
          )}
          <Grid
            container
            item
            direction="row"
            style={{
              alignSelf: 'center',
              maxWidth: MAIN_CONTENT_MAX_SIZE,
              marginTop: Header ? HEADER_HEIGHT + bannerHeight : bannerHeight,
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HIDLayout;
