import React, { FC } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import HIDInlineSelect, { SelectItem } from '../../../components/HIDInlineSelect';
import { useRouteParams } from '../../../utils/routes';
import { useGetForumTopicsQuery } from '../api/api.forum';
import {
  getForumTopicPath as getNotPartnersForumTopicPath,
  getPartnersForumTopicPath,
} from '../navigation/navigation.forum';
import { toggleSideDrawerOpen } from '../../../store/layoutReducer';
import { useGetActiveAuthUser } from '../../../external-services/firebase';

const TopicsQuickNavigation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['forum']);

  const { topicId } = useRouteParams<{ topicId: string }>();

  const { isPartnerAuth } = useGetActiveAuthUser();

  const getForumTopicPath = isPartnerAuth ? getPartnersForumTopicPath : getNotPartnersForumTopicPath;

  const {
    data: topics = [],
    isLoading: isLoadingTopics,
  } = useGetForumTopicsQuery();

  const selectItems: Array<SelectItem> = topics
    .map(
      (topic) => ({
        id: topic.id,
        label: topic.name,
      }),
    );

  const selectItemsList = isLoadingTopics
    ? R.times((n) => ({ id: n.toString(), label: n.toString(), isLoading: true }), 8)
    : selectItems;

  const handleChangeTopic = (topicId: string) => {
    dispatch(toggleSideDrawerOpen(false));
    navigate(getForumTopicPath({ topicId }));
  };

  return (
    <Stack>
      <Typography sx={{ marginBottom: 1 }} variant="h6">
        {t('forum:forum_topics')}
      </Typography>
      <HIDInlineSelect
        items={selectItemsList}
        value={topicId}
        onChange={handleChangeTopic}
      />
    </Stack>
  );
};

export default TopicsQuickNavigation;
