import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as R from 'ramda';

import {
  SubscriptionPlan,
  SubscriptionPlanId,
} from '../types.subscriptionPlans';
import { useGetSubscriptionPlansQuery } from '../api/api.subscriptionPlans';
import { QueryHook } from '../../../types/common';
import {
  SubscriptionPlanSettings,
  useGetSubscriptionPlansStaticSettings,
} from './useGetSubscriptionPlansStaticSettings';

export type SubscriptionPlanInfo = SubscriptionPlan & SubscriptionPlanSettings;

type UseGetSubscriptionPlansSettings = QueryHook<Record<SubscriptionPlanId, SubscriptionPlanInfo> | undefined>;

export const useGetSubscriptionPlansSettings: UseGetSubscriptionPlansSettings = (args, options) => {
  const { i18n: { language } } = useTranslation();

  const {
    data: subscriptionPlans,
    ...rest
  } = useGetSubscriptionPlansQuery(args, options);

  const subscriptionPlanSettings = useGetSubscriptionPlansStaticSettings();

  const mappedSubscriptionPlans: Record<string, SubscriptionPlanInfo> | undefined = useMemo(
    () => subscriptionPlans
      ? R.fromPairs(
        subscriptionPlans.map((plan) => (
          [plan.id, { ...plan, ...subscriptionPlanSettings[plan.id] } as SubscriptionPlanInfo])),
      )
      : undefined,
    [subscriptionPlans, language],
  );

  return {
    data: mappedSubscriptionPlans,
    ...rest,
  };
};
