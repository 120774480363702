import {
  initializeApp,
} from 'firebase/app';
import {
  Auth,
  User as FBUser,
  getAuth,
} from 'firebase/auth';
import {
  Firestore,
  getFirestore,
} from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  firebaseConfig,
  firebasePartnersConfig,
} from './configs/firebase-config';

export const firebaseApp = initializeApp(firebaseConfig);
const partnersApp = initializeApp(firebasePartnersConfig, 'partners');

export const auth = getAuth(firebaseApp);
export const partnersAuth = getAuth(partnersApp);

export const firestore: Firestore = getFirestore(firebaseApp);

export const getActiveAuth = (): Auth => {
  if (partnersAuth.currentUser) {
    return partnersAuth;
  }
  return auth;
};

type UseGetActiveAuthUser = () => ({
  user?: FBUser,
  isLoading: boolean,
  error?: Error,
  isPartnerAuth?: boolean;
});

export const useGetActiveAuthUser: UseGetActiveAuthUser = () => {
  const [currentUser, currentUserLoading, currentUserError] = useAuthState(auth);
  const [currentPartnersUser, currentPartnersUserLoading, currentPartnersUserError] = useAuthState(partnersAuth);

  return {
    user: currentUser || currentPartnersUser || undefined,
    isLoading: currentUserLoading || currentPartnersUserLoading,
    error: currentUserError || currentPartnersUserError,
    isPartnerAuth: currentPartnersUser || currentUser
      ? !currentUser && Boolean(currentPartnersUser)
      : undefined,
  };
};

auth.onIdTokenChanged((user) => {
  if (user) {
    user.getIdToken()
      .then((token) => {
        document.cookie = `token=${token}; Max-Age=604800; Domain=.${document.location.hostname}; Path=/; SameSite=None; Secure`;
      })
      .catch(() => {
        // TODO: log error
      });
  } else {
    document.cookie = `token=; Max-Age=0; Domain=.${document.location.hostname}; Path=/; SameSite=None; Secure`;
  }
});

partnersAuth.onIdTokenChanged(
  (user) => {
    if (user) {
      user.getIdToken()
        .then((token) => {
          document.cookie = `token=${token}; Max-Age=604800; Domain=.${document.location.hostname}; Path=/; SameSite=None; Secure`;
        })
        .catch(() => {
          // TODO: log error
        });
    } else {
      document.cookie = `token=; Max-Age=0; Domain=.${document.location.hostname}; Path=/; SameSite=None; Secure`;
    }
  },
);
