import { skipToken } from '@reduxjs/toolkit/query';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import {
  useGetCurrentSubscriptionPlanQuery,
  useLazyGetCurrentSubscriptionPlanQuery,
} from '../api/api.subscriptionPlans';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '../types.subscriptionPlans';

type IsSubscriptionPlanFeatureEnabled = {
  isEnabled: boolean;
  featureStatus: FeatureStatus;
};

const useGetIsSubscriptionPlanFeatureEnabled = (subscriptionFeature: SubscriptionFeature) => {
  const { isPartnerAuth } = useGetActiveAuthUser();
  const { data: currentPropertyId } = useGetCurrentPropertyId();

  const {
    data: currentSubscriptionPlan,
    isLoading: subscriptionPlanIsLoading,
  } = useGetCurrentSubscriptionPlanQuery(isPartnerAuth ? skipToken : undefined);

  const [
    getCurrentSubscriptionPlan,
    { isLoading: subscriptionPlanIsLoadingLazy },
  ] = useLazyGetCurrentSubscriptionPlanQuery();

  const isLoading = subscriptionPlanIsLoading || subscriptionPlanIsLoadingLazy;

  const currentPropertyStorageSpace = currentSubscriptionPlan?.properties
    ?.find(({ id }) => id === currentPropertyId)?.storageSpace;

  const canNotUseCurrentPropertyStorage = subscriptionFeature === SubscriptionFeature.STORAGE_SPACE
    && currentPropertyStorageSpace?.status === FeatureStatus.DISABLED;

  const currentFeatureStatus = (
    subscriptionFeature === SubscriptionFeature.STORAGE_SPACE
      ? currentPropertyStorageSpace?.status
      : currentSubscriptionPlan?.me?.[subscriptionFeature]?.status
  ) || FeatureStatus.DISABLED;

  const getIsSubscriptionPlanFeatureEnabled = () => new Promise<IsSubscriptionPlanFeatureEnabled>(
    (resolve, reject) => {
      if (isPartnerAuth || (currentFeatureStatus === FeatureStatus.ENABLED && !canNotUseCurrentPropertyStorage)) {
        resolve({
          isEnabled: true,
          featureStatus: currentFeatureStatus,
        });
      } else if ([FeatureStatus.TRIAL, FeatureStatus.TRIAL_AVAILABLE].includes(currentFeatureStatus) && !canNotUseCurrentPropertyStorage) {
        getCurrentSubscriptionPlan()
          .unwrap()
          .then((newCurrentSubscriptionPlan) => {
            const newCurrentFeatureStatus = newCurrentSubscriptionPlan?.me?.[subscriptionFeature]?.status || FeatureStatus.DISABLED;
            resolve({
              isEnabled: newCurrentFeatureStatus === FeatureStatus.ENABLED || newCurrentFeatureStatus === FeatureStatus.TRIAL,
              featureStatus: newCurrentFeatureStatus,
            });
          })
          .catch(reject);
      } else {
        resolve({
          isEnabled: false,
          featureStatus: currentFeatureStatus,
        });
      }
    },
  );

  return {
    getIsSubscriptionPlanFeatureEnabled,
    isLoading,
  };
};

export default useGetIsSubscriptionPlanFeatureEnabled;
