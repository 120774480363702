import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import * as R from 'ramda';

import { useTranslation } from 'react-i18next';

import HIDInfo from '../../../../../components/HIDInfo';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import {
  getCreateDocumentPath,
  getDocumentCategoryPath,
} from '../../Content/modules/Document/navigation.document';
import {
  PropertyProgressCategorySectionProps,
  PropertyDocumentationData,
  PropertyDocumentType,
  PropertyPurchaseDocumentRequisite,
} from '../types.propertyProgress';
import PropertyDocumentTemplatesList from './PropertyDocumentTemplatesList';
import PropertyDocumentsList from './PropertyDocumentsList';
import PropertyProgressCategorySectionContainer from './PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from './PropertyProgressCategorySectionContent';
import PropertyServicesList from './PropertyServicesList';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

type PropertyDocumentsSectionProps = PropertyProgressCategorySectionProps<PropertyDocumentationData> & {
  description?: string;
  infoTitle?: string;
  infoDescription?: string;
  documentsListTitle: string;
  requiredTemplatesTitle?: string;
  recommendedTemplatesTitle?: string;
  viewAllDocumentsTitle: string;
  showAddButton?: boolean;
  hideRequiredTemplatesList?: boolean;
  hideRecommendedTemplatesList?: boolean;
  onRefetch?: () => void;
  onNavigateToDocuments?: () => void;
};

export const partitionTemplatesByRequisite = (templates: Array<PropertyDocumentType>) =>
  R.partition(
    ({ requisite }: PropertyDocumentType) => requisite === PropertyPurchaseDocumentRequisite.required,
    R.filter((template: PropertyDocumentType) => !template.documents.length, templates),
  );

export const getDocumentFromDocumentTypes = (documentTypes?: Array<PropertyDocumentType>) => documentTypes
  ?.flatMap((documentType) => documentType.documents
    ?.map((document) => ({
      classificationType: {
        name: documentType.name,
        type: documentType.type,
      },
      ...document,
    }))) || [];

const PropertyDocumentsSection: FC<PropertyDocumentsSectionProps> = ({
  description,
  infoTitle,
  infoDescription,
  documentsListTitle,
  requiredTemplatesTitle,
  recommendedTemplatesTitle,
  viewAllDocumentsTitle,
  showAddButton = false,
  hideRequiredTemplatesList = false,
  hideRecommendedTemplatesList = false,
  section,
  onRefetch,
  onNavigateToDocuments,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['documents']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const documentTypes = section.data?.documentTypes || [];
  const propertyServices = section.data?.externalServices || [];

  const documents = getDocumentFromDocumentTypes(documentTypes);

  const [
    requiredTemplates,
    recommendedTemplates,
  ] = partitionTemplatesByRequisite(documentTypes);

  const handleNavigateToDoCreateDocument = (classificationType?: string) =>
    navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, typeId: classificationType }));

  const handleNavigateToDocuments = () => onNavigateToDocuments
    ? onNavigateToDocuments()
    : navigate(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('documents:documents_add_document')}
          description={description}
          viewAllButtonText={viewAllDocumentsTitle}
          onAddClick={showAddButton ? handleNavigateToDoCreateDocument : undefined}
          onViewAllClick={handleNavigateToDocuments}
        >
          {Boolean(infoTitle && infoDescription) && (
            <HIDInfo
              isMarkdown
              bodyTextSx={{ whiteSpace: 'pre-line' }}
              description={infoDescription as string}
              label={infoTitle as string}
            />
          )}
          {Boolean(documents?.length) && (
            <PropertyDocumentsList
              propertyDocuments={documents}
              title={documentsListTitle}
            />
          )}
          {Boolean(propertyServices.length) && onRefetch && (
            <PropertyServicesList
              propertyServices={propertyServices}
              onServiceSynced={onRefetch}
            />
          )}
          {Boolean(requiredTemplates?.length && !hideRequiredTemplatesList) && (
            <PropertyDocumentTemplatesList
              propertyDocumentTemplates={requiredTemplates}
              title={requiredTemplatesTitle}
              onClick={handleNavigateToDoCreateDocument}
            />
          )}
          {Boolean(recommendedTemplates?.length && !hideRecommendedTemplatesList) && (
            <PropertyDocumentTemplatesList
              propertyDocumentTemplates={recommendedTemplates as unknown as Array<PropertyDocumentType>}
              title={recommendedTemplatesTitle}
              onClick={handleNavigateToDoCreateDocument}
            />
          )}
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyDocumentsSection;
