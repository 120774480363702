import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Lock: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m18.86207,11.0197l-13.72413,0c-1.08277,0 -1.96059,0.87782 -1.96059,1.96059l0,6.86203c0,1.08284 0.87782,1.96059 1.96059,1.96059l13.72413,0c1.08277,0 1.96059,-0.87775 1.96059,-1.96059l0,-6.86203c0,-1.08277 -0.87782,-1.96059 -1.96059,-1.96059z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m7.09866,11.0197l0,-3.92118c0,-1.29998 0.51638,-2.54666 1.43558,-3.46586c0.9192,-0.9192 2.16594,-1.43558 3.46586,-1.43558c1.29998,0 2.54666,0.51638 3.46586,1.43558c0.9192,0.9192 1.43558,2.16588 1.43558,3.46586l0,3.92118"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Lock;
