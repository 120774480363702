import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { PropertyId } from '../../../../../types/types.property';
import { propertyApi } from '../../../../../api/api.property';
import {
  SearchAutocompleteResultItem,
  SearchResultFilterTypes,
  SearchResultItem,
} from '../types.search';

type GetSearchResultsParams = PropertyId & {
  types?: SearchResultFilterTypes;
  query?: string;
  lang: string;
  size?: number;
};

export type GetAutocompleteSearchResultsParams = PropertyId & {
  query: string;
  lang: string;
  entityType?: EntityType;
  size?: number;
};

export const searchApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutocompleteSearchResults: builder.query<Array<SearchAutocompleteResultItem>, GetAutocompleteSearchResultsParams>({
      query: ({
        entityType,
        propertyId,
        query,
        lang,
        size = 10,
      }) => ({
        url: '/v2/search/autocomplete',
        method: 'POST',
        body: {
          lang,
          query,
          propertyId,
          types: entityType ? { [entityType]: {} } : {},
          size,
        },
      }),
      transformResponse: (response: { results: Array<SearchAutocompleteResultItem> }) => response.results,
    }),
    getSearchResults: builder.query<Array<SearchResultItem>, GetSearchResultsParams>({
      query: ({
        propertyId,
        types,
        query,
        lang,
        size = 10,
      }) => ({
        url: '/v2/search/multisearch',
        method: 'POST',
        body: {
          propertyId,
          query,
          lang,
          size,
          types: types || {},
          filters: {},
        },
      }),
    }),
  }),
});

export const {
  useGetSearchResultsQuery,
  useGetAutocompleteSearchResultsQuery,
} = searchApi;
