import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import IfPropertyInsuranceCard from './IfPropertyInsuranceCard';
import PropertyDocumentsList from '../../../../../PropertyProgress/components/PropertyDocumentsList';

import { CustomInsuranceType } from '../../types.insurance';
import useGetExpirationDateString from '../../hooks/useGetExpirationDateString';
import PropertyExternalDocumentList from '../../../../../PropertyProgress/components/PropertyExternalDocumentList';
import { PropertyLink } from '../../../../../PropertyProgress/types.propertyProgress';
import { HIDDocumentWithType } from '../../../DynamicContent/types/types.dynamicContent';

type IfPropertyInsuranceProps = {
  externalDocuments?: Array<PropertyLink>;
  documents?: Array<HIDDocumentWithType>;
  insuranceType: CustomInsuranceType;
  expirationDate?: string;
  startDate?: string;
};

const IfPropertyInsurance: FC<IfPropertyInsuranceProps> = ({
  externalDocuments,
  documents,
  insuranceType,
  expirationDate,
  startDate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances', 'entities', 'common']);

  const expirationDateString = useGetExpirationDateString(startDate, expirationDate);

  return (
    <Stack spacing={2}>
      <IfPropertyInsuranceCard insuranceType={insuranceType} />
      {Boolean(expirationDateString) && (
        <>
          <Typography variant="subtitle1">
            {t('insurances:insurance_free')}
          </Typography>
          <Stack flexDirection="row">
            <Typography color="grey" variant="body1">
              {expirationDateString}
            </Typography>
          </Stack>
        </>
      )}
      <PropertyExternalDocumentList
        externalDocuments={externalDocuments}
        title={t('common:links')}
      />
      <PropertyDocumentsList
        propertyDocuments={documents}
        title={t('entities:document_plural')}
      />
      <Typography color={theme.palette.grey[500]} variant="body2">
        {t('insurances:insurance_in_collaboration_with_svensk_and_if')}
      </Typography>
    </Stack>
  );
};

export default IfPropertyInsurance;
