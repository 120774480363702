import React, {
  useEffect,
  useState,
} from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
  TextFieldProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDTextField, { CurrencyAdornment } from '../../../../../../../../../components/HIDTextField';
import { formatMoney } from '../../../../../../../../../utils/string';
import { FCC } from '../../../../../../../../../types/common';

enum AmountType {
  SUM = 'SUM',
  CUSTOM = 'CUSTOM',
}

type ReceiptLinesTotalAmountProps = {
  customAmount?: number;
  sumAmount?: number;
  amountRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  onBlur?: TextFieldProps['onBlur'],
  onChange: (value?: string) => void;
};

const ReceiptLinesTotalAmount: FCC<ReceiptLinesTotalAmountProps> = ({
  customAmount = 0,
  sumAmount = 0,
  amountRef,
  sx,
  onBlur,
  onChange,
}) => {
  const theme = useTheme();

  const { t } = useTranslation(['receipts']);

  const [amountType, setAmountType] = useState(customAmount && customAmount !== sumAmount ? AmountType.CUSTOM : AmountType.SUM);

  useEffect(() => {
    setAmountType(customAmount && customAmount !== sumAmount ? AmountType.CUSTOM : AmountType.SUM);
  }, [customAmount, sumAmount]);

  const handleChangeAmountType = (amountType: AmountType) => {
    setAmountType(amountType);
    if (amountType === AmountType.SUM) {
      onChange(sumAmount?.toString());
    }
  };

  return (
    <Stack sx={sx}>
      <Typography sx={{ marginTop: 2 }} variant="subtitle1">
        {t('receipts:receipts_receipt_amount')}
      </Typography>
      <RadioGroup
        sx={{ alignItems: 'flex-start' }}
        value={amountType}
        onChange={(_event, value) => handleChangeAmountType(value as AmountType)}
      >
        <Stack alignItems="center" direction="row">
          <FormControlLabel
            control={<Radio />}
            label={t('receipts:receipts_improvement_expenses_sum')}
            sx={{ marginRight: 1 }}
            value={AmountType.SUM}
          />
          <Typography color={theme.palette.grey[500]} variant="body2">
            {`(${formatMoney(sumAmount)})`}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          <FormControlLabel
            control={<Radio />}
            label={t('receipts:receipts_improvement_expenses_custom')}
            sx={{ marginRight: 1 }}
            value={AmountType.CUSTOM}
          />
          {amountType === AmountType.CUSTOM && (
            <HIDTextField
              endAdornment={<CurrencyAdornment />}
              ref={amountRef}
              showHelperText={false}
              sx={{ maxWidth: 104 }}
              type="number"
              value={customAmount?.toString() || ''}
              onBlur={onBlur}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

export default ReceiptLinesTotalAmount;
