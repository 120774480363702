import React, { FC } from 'react';
import {
  Card,
  Grid,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  BonusOffersCategoryId,
  BonusOffersQuickNavigationCategories,
} from '../../types.bonusOffers';
import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import BonusOffersQuickNavigation from '../../components/BonusOffersQuickNavigation';
import { useGetAvailableBonusOffersQuery } from '../../api/api.bonusOffers';
import BonusOfferCard from '../../components/BonusOfferCard';
import { getBonusOfferDetailsPath } from '../../navigation.bonusOffers';
import BonusOffersGridLoaderSkeleton from '../../components/BonusOffersGridLoaderSkeleton';
import { FCC } from '../../../../../../types/common';
import useGetLogAnalyticsEvent from '../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { AnalyticsEvent } from '../../../../../Analytics/types.analytics';

const MoveInBonusOffersLoaderSkeleton: FCC<{ isLoading: boolean; }> = ({ isLoading, children }) => (
  <BonusOffersGridLoaderSkeleton showDescription isLoading={isLoading}>
    {children}
  </BonusOffersGridLoaderSkeleton>
);

const MoveInBonusOffers: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['bonus_offers', 'common']);

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const category = BonusOffersCategoryId.MOVE_IN_SERVICES;

  const {
    data: bonusOffers,
    isLoading: isLoadingAvailableBonusOffers,
  } = useGetAvailableBonusOffersQuery(
    propertyId
      ? { propertyId, category }
      : skipToken,
  );

  const isLoading = isLoadingAvailableBonusOffers;

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const handleNavigateToBonusOfferDetails = (bonusOfferId: string, source?: string) => {
    if (propertyId) {
      navigate(getBonusOfferDetailsPath({ propertyId, bonusOfferId, categoryId: category }));
      logAnalyticsEvent({
        event: AnalyticsEvent.OPEN_OFFER,
        hidCategory: EntityType.BONUS_OFFER,
        source,
        params: { bonusOfferId, categoryId: category },
      });
    }
  };

  return (
    <HomeLayout
      BodyLoaderSkeleton={MoveInBonusOffersLoaderSkeleton}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <BonusOffersQuickNavigation
            currentCategory={BonusOffersQuickNavigationCategories.MOVE_IN_BONUS_OFFERS}
            key={BonusOffersQuickNavigation.name}
          />
        </Card>
      }
      isLoading={isLoading}
      sideDrawerElements={[
        <BonusOffersQuickNavigation
          currentCategory={BonusOffersQuickNavigationCategories.MOVE_IN_BONUS_OFFERS}
          key={BonusOffersQuickNavigation.name}
        />,
      ]}
      title={t('bonus_offers:bonus_offers_offers_from_move_in')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      {bonusOffers && Boolean(bonusOffers.length) && (
        <Grid
          container
          spacing={1.5}
          sx={{ marginTop: theme.spacing(1) }}
        >
          {bonusOffers.map((entity) => (
            <Grid
              item
              key={entity.id}
              lg={bonusOffers.length === 1 ? 12 : 6}
              xxs={12}
            >
              <BonusOfferCard
                showDescription
                entity={entity}
                isPrimary={bonusOffers.length === 1}
                onClick={() => handleNavigateToBonusOfferDetails(String(entity.id), entity.action?.source)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </HomeLayout>
  );
};

export default MoveInBonusOffers;
