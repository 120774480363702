export type InsuranceParametersCategoryItem = {
  name: string;
  description: string;
  value: string | boolean;
};

export type InsuranceParametersCategory = {
  name: string;
  parameters: InsuranceParametersCategoryItem[];
};

export enum InsurancePaymentMethod {
  UNDEFINED = 'UNDEFINED',
  AUTOGIRO = 'AUTOGIRO',
  PAPER_INVOICE = 'PAPER_INVOICE',
  ELECTRONIC_INVOICE = 'ELECTRONIC_INVOICE',
  GENERIC_INVOICE = 'GENERIC_INVOICE',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  EMPLOYER_PAID = 'EMPLOYER_PAID',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum InsurancePaymentFrequency {
  UNKNOWN = 'UNKNOWN',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BIANNUALLY = 'BIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export type Insurance = {
  insuranceNumber: string;
  insuranceCompany: string;
  insuranceSubType: string;
  insuranceHolderStreetAddress: string;
  insuranceObjectStreetAddress: string;
  insuranceHolderName: string;
  insuranceName: string;
  startDate: string;
  renewalDate: string;
  premiumAmountYear: number;
  premiumFrequency: InsurancePaymentFrequency;
  paymentMethod: InsurancePaymentMethod;
  parameterCategories: InsuranceParametersCategory[];
  calculated: {
    status: string;
    comparisonPremiumAmountYear: number;
  };
};

export type InsuranceWithParams = Insurance;

export type InsuranceCompany = {
  id: string;
  name: string;
  logoUrl: string;
  contact?: {
    phone?: string;
    contact_email?: string;
  };
  claim?: {
    phone?: string;
    email?: string;
    url?: string;
  };
  termsUrl?: string;
};

export enum InsuranceType {
  HOUSE = 'villaInsurance',
  CONDOMINIUM = 'condoInsurance',
}

export enum InsuranceSyncState {
  RUNNING = 'RUNNING',
  LOGIN = 'LOGIN',
  COLLECTING = 'COLLECTING',
  COMPLETED = 'COMPLETED',
  COMPLETED_PARTIAL = 'COMPLETED PARTIAL',
  COMPLETED_EMPTY = 'COMPLETED EMPTY',
  FAILED = 'FAILED',
  WAITING_FOR_AUTHENTICATION = 'WAITING FOR AUTHENTICATION',
}

export const InsuranceRetrievalStatus = {
  missingInsurance: 'missing-insurance',
  hasInsurance: 'has-insurance',
};

export enum CustomInsuranceType {
  HOUSE = 'house',
  CONDOMINIUM = 'condominium',
  VACATION_HOUSE = 'vacation-house',
}

export enum CustomInsurance {
  SVENSKFAST_VARUDEKLARERAT = 'svenskfast-varudeklarerat',
  SVENSKFAST_IF = 'svenskfast-if',
}
