import React from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import ForumOverview from '../pages/ForumOverview';
import ForumTopic from '../pages/ForumTopic';
import ForumThread from '../pages/forumThread/ForumThread';
import ForumExperts from '../pages/ForumExperts';
import MyForumQuestions from '../pages/MyForumQuestions';
import MyForumBookmarks from '../pages/MyForumBookmarks';

const ForumRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.FORUM,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM}
        propertyRequired={false}
      >
        <ForumOverview />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FORUM_TOPIC,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM_TOPIC}
        propertyRequired={false}
      >
        <ForumTopic />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FORUM_THREAD,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM_THREAD}
        propertyRequired={false}
      >
        <ForumThread />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FORUM_EXPERTS,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM_EXPERTS}
        propertyRequired={false}
      >
        <ForumExperts />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FORUM_MY_QUESTIONS,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM_MY_QUESTIONS}
        propertyRequired={false}
      >
        <MyForumQuestions />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.FORUM_MY_BOOKMARKS,
    element: (
      <ProtectedRoute
        authRequired
        path={RouteNames.FORUM_MY_BOOKMARKS}
        propertyRequired={false}
      >
        <MyForumBookmarks />
      </ProtectedRoute>
    ),
  },
];

export default ForumRouteConfig;
