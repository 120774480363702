import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  ArrowForward,
  DeleteOutline,
  FileDownloadOutlined,
  MoreHoriz,
  OpenInBrowser,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../types/common';
import HIDIconButton from '../../../../../../components/buttons/HIDIconButton';
import FileIcon, { FileIconPropsMap } from './FileIcon';
import { getNoWrapStyle } from '../../../../../../utils/style';
import HIDIconButtonContextMenu from '../../../../../../components/contextMenu/HIDIconButtonContextMenu';

type FileListItemProps = {
  name: string;
  mime: string;
  iconSize?: number;
  loading?: boolean;
  condense?: boolean;
  onDelete?: () => void;
  onOpen?: () => void;
  onDownload?: () => void;
  onMove?: () => void;
};

const FileListItem: FCC<FileListItemProps> = ({
  name,
  mime,
  iconSize,
  loading,
  condense,
  sx,
  onDelete,
  onOpen,
  onDownload,
  onMove,
}) => {
  const { t } = useTranslation(['common']);
  const { type, extension } = FileIconPropsMap[mime as keyof typeof FileIconPropsMap] || {};

  const contextMenuActions = [
    onDelete && {
      id: 'delete',
      label: t('common:delete_label'),
      Icon: DeleteOutline,
      onClick: onDelete,
    },
    onDownload && {
      id: 'download',
      label: t('common:download_label'),
      Icon: FileDownloadOutlined,
      onClick: onDownload,
    },
  ].filter(Boolean);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={1}
      sx={sx}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ minWidth: 0 }}
      >
        <FileIcon
          extension={extension}
          size={iconSize}
          type={type}
        />
        <Stack sx={{ minWidth: 0 }}>
          <Typography sx={getNoWrapStyle(1)} variant="body1">
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={1}
      >
        {!condense && onDelete && (
          <HIDIconButton
            Icon={DeleteOutline}
            disabled={loading}
            size="small"
            title={t('common:delete_label')}
            onClick={onDelete}
          />
        )}
        {onOpen && (
          <HIDIconButton
            Icon={OpenInBrowser}
            disabled={loading}
            size="small"
            title={t('common:open_label')}
            onClick={onOpen}
          />
        )}
        {!condense && onDownload && (
          <HIDIconButton
            Icon={FileDownloadOutlined}
            disabled={loading}
            size="small"
            title={t('common:download_label')}
            onClick={onDownload}
          />
        )}
        {onMove && (
          <HIDIconButton
            Icon={ArrowForward}
            disabled={loading}
            size="small"
            title={t('common:move_label')}
            onClick={onMove}
          />
        )}
        {condense && contextMenuActions.length > 0 && (
          <HIDIconButtonContextMenu
            Icon={MoreHoriz}
            color="primary"
            disabled={loading}
            listItems={contextMenuActions}
            sx={{ padding: 0 }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default FileListItem;
