import React, { FC } from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';

import HIDInfo from '../../../../../components/HIDInfo';
import { ExternalService } from '../../../../../types/common';

type PropertyServicesListProps = {
  propertyServices?: Array<ExternalService>,
  onServiceSynced: () => void;
};

const PropertyServicesList: FC<PropertyServicesListProps> = ({
  propertyServices,
  onServiceSynced,
}) => {
  const theme = useTheme();
  const handleExternalServiceSynced = () => onServiceSynced();

  return (
    <Stack spacing={1}>
      {
        propertyServices?.map((service) => (
          <Card
            key={service.serviceId}
            sx={{
              padding: 2,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: '10px',
              borderColor: theme.palette.grey[200],
              width: '100%',
              backgroundColor: theme.palette.warning.lighter,
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              onClick={handleExternalServiceSynced}
            >
              <HIDInfo
                description={service.additionalInformation}
                label={service.name}
              />
              <ChevronRight />
            </Stack>
          </Card>
        ))
      }
    </Stack>
  );
};

export default PropertyServicesList;
