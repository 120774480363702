import React from 'react';
import {
  ButtonGroup,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDTypography from '../../../../../../components/HIDTypography';
import { TimeIntervalsGroupingType } from '../../types/types.content';
import { FCC } from '../../../../../../types/common';
import HIDButton from '../../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';

type TimeIntervalsSectionProps = {
  timeIntervalGroupingType?: TimeIntervalsGroupingType;
  showQuarterly?: boolean;
  onChange: (interval: TimeIntervalsGroupingType) => void;
};

const TimeIntervalsSection: FCC<TimeIntervalsSectionProps> = ({
  timeIntervalGroupingType,
  showQuarterly = false,
  sx,
  onChange,
}) => {
  const { t } = useTranslation(['common']);

  const { isDownXl } = useBreakPointsSizes();

  return (
    <Stack
      alignItems="center"
      direction="column"
      flexWrap="wrap"
      spacing={1}
      sx={sx}
    >
      <HIDTypography
        sx={{ alignSelf: 'flex-start' }}
        variant="subtitle1"
      >
        {`${t('common:interval')}: `}
      </HIDTypography>
      <ButtonGroup size={isDownXl ? 'small' : 'medium'} variant="outlined">
        <HIDButton
          color={timeIntervalGroupingType === TimeIntervalsGroupingType.Yearly ? 'primary' : 'secondary'}
          sx={isDownXl ? undefined : { paddingX: 2.5 }}
          onClick={() => onChange(TimeIntervalsGroupingType.Yearly)}
        >
          {t('common:yearly')}
        </HIDButton>
        {showQuarterly && (
          <HIDButton
            color={timeIntervalGroupingType === TimeIntervalsGroupingType.Quarterly ? 'primary' : 'secondary'}
            sx={isDownXl ? undefined : { paddingX: 2.5 }}
            onClick={() => onChange(TimeIntervalsGroupingType.Quarterly)}
          >
            {t('common:quarterly')}
          </HIDButton>
        )}
        <HIDButton
          color={timeIntervalGroupingType === TimeIntervalsGroupingType.Monthly ? 'primary' : 'secondary'}
          sx={isDownXl ? undefined : { paddingX: 2.5 }}
          onClick={() => onChange(TimeIntervalsGroupingType.Monthly)}
        >
          {t('common:monthly')}
        </HIDButton>
      </ButtonGroup>
    </Stack>
  );
};

export default TimeIntervalsSection;
