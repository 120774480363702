export const handleSelectSuggestion = (ref: React.MutableRefObject<HTMLInputElement | undefined> | null) => {
  ref?.current?.focus();

  // @ts-ignore
  const element = (ref?.current?.node) as (HTMLInputElement | undefined);

  if (element?.scrollIntoView) {
    element?.scrollIntoView(false);
  }

  setTimeout(
    () => element?.previousSibling?.dispatchEvent(new MouseEvent('mousedown', { bubbles: true })),
    300,
  );
};
