import React, { FC } from 'react';
import { t } from 'i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import {
  PropertyIfInsuranceSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import { openUrlNewTab } from '../../../../../../../utils/file';
import IfPropertyInsurance from '../../../../Content/modules/Insurance/components/ifPropertyInsurance/IfPropertyInsurance';
import {
  IF_READ_MORE_HOUSE_INSURANCE_URL,
  IF_READ_MORE_CONDOMINIUM_INSURANCE_URL,
  IF_READ_MORE_VACATION_HOUSE_INSURANCE_URL,
  IF_REPORT_URL,
} from '../../../../Content/modules/Insurance/constants.insurance';
import { CustomInsuranceType } from '../../../../Content/modules/Insurance/types.insurance';
import useGetLogAnalyticsEvent from '../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import {
  AnalyticsEvent,
  PartnersSource,
  EventReportSource,
} from '../../../../../../Analytics/types.analytics';

const ReadMoreUrlMap = {
  [CustomInsuranceType.HOUSE]: IF_READ_MORE_HOUSE_INSURANCE_URL,
  [CustomInsuranceType.CONDOMINIUM]: IF_READ_MORE_CONDOMINIUM_INSURANCE_URL,
  [CustomInsuranceType.VACATION_HOUSE]: IF_READ_MORE_VACATION_HOUSE_INSURANCE_URL,
};

const PropertyIfInsuranceSection: FC<PropertyProgressCategorySectionProps<PropertyIfInsuranceSectionData>> = ({
  section,
}) => {
  const {
    type,
    documents,
    expirationDate,
    externalDocuments,
    startDate,
  } = section.data;

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const handleAddInsurance = () => {
    openUrlNewTab(IF_REPORT_URL);
    logAnalyticsEvent(
      {
        event: AnalyticsEvent.REPORT_DAMAGE_INSURANCE,
        source: PartnersSource.IF,
        hidCategory: EntityType.INSURANCE,
        eventReportSource: EventReportSource.ALL,
      },
    );
  };

  const handleReadMore = () => {
    openUrlNewTab(ReadMoreUrlMap[type]);
    logAnalyticsEvent(
      {
        event: AnalyticsEvent.READ_MORE_VARUDEKLARERAT,
        source: PartnersSource.IF,
        hidCategory: EntityType.INSURANCE,
        eventReportSource: EventReportSource.ALL,
      },
    );
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('insurances:insurance_report_error_or_damage')}
          viewAllButtonText={t('insurances:insurance_extend_insurance')}
          onAddClick={handleAddInsurance}
          onViewAllClick={handleReadMore}
        >
          <IfPropertyInsurance
            documents={documents}
            expirationDate={expirationDate}
            externalDocuments={externalDocuments}
            insuranceType={type}
            startDate={startDate}
          />
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyIfInsuranceSection;
