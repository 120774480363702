import React from 'react';
import { useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import { FCC } from '../../types/common';
import HIDIconButton from './HIDIconButton';
import { HIDZIndex } from '../../constants/layout';

type CloseDialogIconButtonProps = {
  onClick: () => void;
};

const CloseDialogIconButton: FCC<CloseDialogIconButtonProps> = ({
  sx,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <HIDIconButton
      Icon={Close}
      color="blank"
      sx={{
        position: 'absolute',
        top: theme.spacing(1.25),
        right: theme.spacing(1.25),
        zIndex: HIDZIndex.CLOSE,
        backgroundColor: `${theme.palette.common.white}DD`,
        ...sx,
      }}
      onClick={onClick}
    />
  );
};

export default CloseDialogIconButton;
