import React, {
  FC,
  useMemo,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useNavigate } from 'react-router';

import PurpleRocketImage from '../../../../../assets/images/purple_rocket.png';
import PurpleCloudImage from '../../../../../assets/images/purple_cloud.png';
import RocketImage from '../../../../../assets/images/rocket.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import useGetCurrentUserSubscriptionPlanId from '../../../../SubscriptionPlans/hooks/useGetCurrentUserSubscriptionPlanId';
import useGetSubscriptionPlanOffers from '../../../../SubscriptionPlans/hooks/useGetSubscriptionPlanOffers';
import { getSubscriptionPlansPath } from '../../../../SubscriptionPlans/navigation/navigation.subscriptionPlans';
import {
  SubscriptionPlanId,
} from '../../../../SubscriptionPlans/types.subscriptionPlans';
import { PARTNERS } from '../../../../../types/common';
import { useGetFormatMonth } from '../../../../../utils/translation';
import {
  getSubscriptionPlanMonths,
  hasSubscriptionPlanPeriodOptionOfferTrial,
} from '../../../../SubscriptionPlans/utils.subscriptionPlans';
import { HIDZIndex } from '../../../../../constants/layout';
import useGetSubscriptionPlanOfferPeriodTexts from '../../../../SubscriptionPlans/hooks/useGetSubscriptionPlanOfferPeriodTexts';
import { useGetSubscriptionPlansStaticSettings } from '../../../../SubscriptionPlans/hooks/useGetSubscriptionPlansStaticSettings';
import { getColorGradient } from '../../../../../utils/style';
import useGetLogAnalyticsEvent from '../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import {
  AnalyticsEvent,
  HIDEventCustomCategory,
  PartnersSource,
} from '../../../../Analytics/types.analytics';
import useGetLowestPriceSubscriptionPlanOffer from '../../../../SubscriptionPlans/hooks/useGetLowestPriceSubscriptionPlanOffer';

type SubscriptionPlanBannerProps = {
  isSidebarBanner?: boolean;
};

const SubscriptionPlanBanner: FC<SubscriptionPlanBannerProps> = ({
  isSidebarBanner = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['subscriptions', 'home']);
  const { data: { subscriptionPlanId } = {} } = useGetCurrentUserSubscriptionPlanId();
  const formatMonth = useGetFormatMonth();
  const logEvent = useGetLogAnalyticsEvent();

  const subscriptionPlanSettings = useGetSubscriptionPlansStaticSettings();
  const basePlanSettings = subscriptionPlanSettings[SubscriptionPlanId.BASE];
  const plusPlanSettings = subscriptionPlanSettings[SubscriptionPlanId.PLUS];

  const { data: subscriptionPlanOffers } = useGetSubscriptionPlanOffers();

  const { data: lowestPriceSubscriptionPlanOffer } = useGetLowestPriceSubscriptionPlanOffer();

  const basePlanName = basePlanSettings?.name;
  const plusPlanName = plusPlanSettings?.name;

  const defaultBannerTexts: Record<string, { title: string, description: string, buttonText: string }> = {
    [SubscriptionPlanId.FREE]: {
      title: t('home:banner_title_for_free_subscription', { basePlanName, plusPlanName }),
      description: t('home:banner_description_for_free_subscription'),
      buttonText: t('subscriptions:select_subscription_plan'),
    },
    [SubscriptionPlanId.BASE]: {
      title: t('home:banner_title_for_base_subscription', { plusPlanName }),
      description: t('home:banner_description_for_free_subscription'),
      buttonText: t('subscriptions:select_subscription_plan'),
    },
  };

  const hasCustomSfTrialOfferBannerText = lowestPriceSubscriptionPlanOffer?.offer?.metadata?.type === 'introductory'
    && lowestPriceSubscriptionPlanOffer?.offer?.metadata.partner === PARTNERS.SVENSKFAST
    && hasSubscriptionPlanPeriodOptionOfferTrial(lowestPriceSubscriptionPlanOffer?.offer?.config?.type);

  const { data: subscriptionPlanOfferPeriodTexts } = useGetSubscriptionPlanOfferPeriodTexts(
    lowestPriceSubscriptionPlanOffer?.planId
      ? { subscriptionPlanId: lowestPriceSubscriptionPlanOffer?.planId }
      : undefined,
  );

  const bannerText = useMemo(
    () => subscriptionPlanId
      ? hasCustomSfTrialOfferBannerText
        ? {
          title: `🎁 ${t('home:banner_sf_trial_text', {
            periodLabel: formatMonth(getSubscriptionPlanMonths(lowestPriceSubscriptionPlanOffer?.offer?.config.duration)),
            planName: lowestPriceSubscriptionPlanOffer?.planId === SubscriptionPlanId.PLUS ? plusPlanName : basePlanName || '',
          })}`,
          description: t('home:banner_sf_trial_description', {
            periodLabel: formatMonth(getSubscriptionPlanMonths(lowestPriceSubscriptionPlanOffer?.offer?.config.duration)),
            planName: lowestPriceSubscriptionPlanOffer?.planId === SubscriptionPlanId.PLUS ? plusPlanName : basePlanName || '',
          }),
          buttonText: t('home:home_go_to_the_next_step'),
        }
        : lowestPriceSubscriptionPlanOffer?.offer && subscriptionPlanOfferPeriodTexts
          ? {
            title: `🎁 ${subscriptionPlanOfferPeriodTexts.bannerText}`,
            description: defaultBannerTexts[subscriptionPlanId].title,
            buttonText: t('subscriptions:select_subscription_plan'),
          }
          : defaultBannerTexts[subscriptionPlanId]
      : undefined,
    [
      subscriptionPlanId,
      hasCustomSfTrialOfferBannerText,
      lowestPriceSubscriptionPlanOffer,
      subscriptionPlanOfferPeriodTexts,
      defaultBannerTexts,
    ],
  );

  const handleNavigate = () => {
    logEvent({
      event: AnalyticsEvent.OPEN_SUBSCRIPTION_OFFER,
      source: PartnersSource.HOUSE_ID,
      hidCategory: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
      params: {
        subscriptionPlanId: lowestPriceSubscriptionPlanOffer?.planId,
        subscriptionPlanOfferId: lowestPriceSubscriptionPlanOffer?.offer?.id,
      },
    });
    navigate(getSubscriptionPlansPath());
  };

  const {
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const imageHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownMd, R.always(160)],
    [() => isDownLg, R.always(200)],
    [R.T, R.always(183)],
  ])();

  const isSmallBanner = isDownMd || isSidebarBanner;

  return bannerText && subscriptionPlanSettings && subscriptionPlanOffers
    ? (
      <Card
        sx={{
          background: getColorGradient(theme.palette.common.premiumDark, theme.palette.common.premium),
          borderRadius: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            background: getColorGradient(theme.palette.common.premiumDark, theme.palette.common.premium),
            position: 'relative',
            flex: 1,
          }}
        >
          <Stack
            sx={{
              padding: theme.spacing(3),
              zIndex: HIDZIndex.HEADER,
              flex: 1,
            }}
          >
            <Stack
              spacing={2}
              sx={{
                alignItems: isSmallBanner ? 'flex-start' : 'center',
                justifyContent: 'center',
                paddingHorizontal: theme.spacing(5),
              }}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{ color: theme.palette.common.white }}
                  textAlign={isSmallBanner ? undefined : 'center'}
                  variant="h3"
                >
                  {bannerText.title}
                </Typography>
                <Typography
                  sx={{ color: theme.palette.common.white }}
                  textAlign={isSmallBanner ? undefined : 'center'}
                  variant="body1"
                >
                  {bannerText.description}
                </Typography>
              </Stack>
              <HIDButton
                color="yellow"
                sx={{ alignSelf: isSmallBanner ? 'flex-start' : 'center' }}
                onClick={handleNavigate}
              >
                {bannerText.buttonText}
              </HIDButton>
            </Stack>
          </Stack>
          {
            isDownXs || isSidebarBanner
              ? (
                <img
                  src={RocketImage}
                  style={{
                    zIndex: HIDZIndex.LOGO,
                    height: 25,
                    width: 70,
                    position: 'absolute',
                    right: theme.spacing(1.5),
                    bottom: theme.spacing(3.125),
                  }}
                />
              )
              : isDownSm
                ? (
                  <img
                    src={RocketImage}
                    style={{
                      height: 37.5,
                      width: 105,
                      position: 'absolute',
                      right: theme.spacing(2),
                      bottom: theme.spacing(2.5),
                    }}
                  />
                )
                : (
                  <>
                    <img
                      src={PurpleCloudImage}
                      style={{
                        zIndex: HIDZIndex.LOGO,
                        position: 'absolute',
                        left: theme.spacing(0),
                        bottom: theme.spacing(0),
                        height: imageHeight,
                      }}
                    />
                    <img
                      src={PurpleRocketImage}
                      style={{
                        zIndex: HIDZIndex.LOGO,
                        position: 'absolute',
                        right: theme.spacing(0),
                        bottom: theme.spacing(0),
                        height: imageHeight,
                      }}
                    />
                  </>
                )
          }
        </Stack>
      </Card>
    )
    : null;
};

export default SubscriptionPlanBanner;
