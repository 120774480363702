import React, { FC } from 'react';
import {
  Card,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { GridEventListener } from '@mui/x-data-grid';

import ListEntitiesActions from '../../Property/modules/Content/components/actions/ListEntitiesActions';
import ReadMoreAboutExperts from '../components/ReadMoreAboutExperts';
import {
  getExpertPath,
  getForumPath,
} from '../navigation/navigation.forum';
import {
  useGetForumThreadsQuery,
} from '../api/api.forum';
import { createFakeForumThread } from '../utils/fakeForumEntities';
import {
  ForumThread,
  ForumThreadsSortOrder,
} from '../types.forum';
import useGetForumThreadColumns from '../hooks/useGetForumThreadColumns';
import HomeListLayout from '../../Property/pages/Home/components/HomeListLayout';
import ListEntitiesToolbarActions from '../../Property/modules/Content/components/actions/ListEntitiesToolbarActions';
import useTryOpenAskForumQuestionDialog from '../hooks/useTryOpenAskForumQuestionDialog';
import useTryNavigateToThread from '../hooks/useTryNavigateToThread';

const MyForumQuestions: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();

  const handleAskExpert = () => tryOpenAskForumQuestionDialog({ isAskExpert: true });
  const handleAskForum = () => tryOpenAskForumQuestionDialog({ isAskExpert: false });

  const handleNavigateToExperts = () => navigate(getExpertPath());

  const {
    data: { threads = [] } = {},
    isLoading: threadIsLoading,
    isFetching: threadIsFetching,
  } = useGetForumThreadsQuery({ sort: ForumThreadsSortOrder.LAST_UPDATE_DESC, createdByMe: true });

  const myThreads = threadIsLoading
    ? R.times((n) => createFakeForumThread(n.toString()), 5)
    : threads;

  const customActions = [
    {
      id: 'ask_forum',
      Icon: Add,
      label: t('forum:forum_ask_the_forum'),
      onClick: handleAskForum,
    },
    {
      id: 'ask_expert',
      Icon: Add,
      label: t('forum:forum_ask_the_expert'),
      disabled: isTryOpenAskForumQuestionDialogLoading,
      onClick: handleAskExpert,
    },
  ];

  const columns = useGetForumThreadColumns();

  const handleTryNavigateToThread = useTryNavigateToThread();

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const thread = params?.row as ForumThread;
    handleTryNavigateToThread(thread);
  };

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={threads.length}
          countLabel={`${threads.length} ${t('forum:forum_questions')}`}
          isFetching={threadIsFetching}
          isLoading={threadIsLoading}
          selectedCount={0}
          onCancel={() => {}}
        />
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions customActions={customActions} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ReadMoreAboutExperts onClick={handleNavigateToExperts} />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getForumPath(),
          name: t('forum:forum_title'),
        },
      ]}
      columns={columns}
      rows={myThreads}
      sideDrawerElements={[
        <ListEntitiesActions customActions={customActions} key={ListEntitiesActions.name} />,
        <ReadMoreAboutExperts key={ReadMoreAboutExperts.name} style={{ marginTop: theme.spacing(1) }} onClick={handleNavigateToExperts} />,
      ]}
      title={t('forum:forum_my_questions')}
      onRowClick={handleRowClick}
    />
  );
};

export default MyForumQuestions;
