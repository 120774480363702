import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
} from '../../Auth/api/api.settings';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import useDialog from '../../../hooks/useDialog';
import { useLazyGetSubscriptionPlanOffersQuery } from '../api/api.subscriptionPlans';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { useGetPropertyProgressQuery } from '../../Property/modules/PropertyProgress/api/api.propertyProgress';
import { getPropertyProgressPercent } from '../../Property/modules/PropertyProgress/utils/utils.propertyProgress';
import useGetCurrentUserSubscriptionPlanId from './useGetCurrentUserSubscriptionPlanId';
import useGetIsCurrentUserPropertyOwner from '../../Property/hooks/useGetIsCurrentUserPropertyOwner';
import { REWARD_WHEN_PROGRESS_NUMBER } from '../constants.subscriptionPlans';

const useShowRewardOfferDialog = () => {
  const { user: currentUser } = useGetActiveAuthUser();

  const {
    data: { subscriptionPlanId, isFree } = {},
    isLoading: isCurrentUserSubscriptionPlanIdLoading,
  } = useGetCurrentUserSubscriptionPlanId();

  const [refetchSubscriptionOffers] = useLazyGetSubscriptionPlanOffersQuery();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: propertyProgress } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const propertyProgressPercent = getPropertyProgressPercent(propertyProgress?.progress);

  const isOwner = useGetIsCurrentUserPropertyOwner({ propertyId });

  const {
    data: { excludedDialogsToShow = [] } = {},
    isLoading: isUserSettingsLoading,
  } = useGetUserSettingsQuery(
    currentUser && currentUser.uid
      ? { userUId: currentUser.uid }
      : skipToken,
  );

  const [setUserSettings] = useSetUserSettingsMutation();

  const handleClose = () => {
    setUserSettings({
      excludedDialogsToShow: [...excludedDialogsToShow, DialogNames.REWARD_OFFER_DIALOG],
    });
  };

  const [openRewardOfferDialog] = useDialog(DialogNames.REWARD_OFFER_DIALOG, handleClose);

  useEffect(() => {
    const showRewardOfferDialog = !isUserSettingsLoading && !excludedDialogsToShow.includes(DialogNames.REWARD_OFFER_DIALOG);

    if (showRewardOfferDialog
      && subscriptionPlanId
      && isFree
      && isOwner
      && propertyProgressPercent >= REWARD_WHEN_PROGRESS_NUMBER
    ) {
      refetchSubscriptionOffers()
        .then(() => openRewardOfferDialog());
    }
  }, [
    excludedDialogsToShow,
    isUserSettingsLoading,
    isCurrentUserSubscriptionPlanIdLoading,
    propertyProgressPercent,
  ]);
};

export default useShowRewardOfferDialog;
