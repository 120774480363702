import React from 'react';
import { Stack } from '@mui/material';

import HIDInfo from '../HIDInfo';
import { FCC } from '../../types/common';
import HIDTypography from '../HIDTypography';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import { getNoWrapStyle } from '../../utils/style';

type SummaryChartInfoProps = {
  value: string;
  label: string;
  isLoading?: boolean;
  infoDescription?: string;
  infoLabel?: string;
  infoSubLabel?: string;
  InfoLabelComponent?: React.ReactElement;
  Icon?: React.ReactNode;
};

const SummaryChartInfo: FCC<SummaryChartInfoProps> = ({
  sx,
  label,
  value,
  isLoading,
  infoLabel,
  infoSubLabel,
  infoDescription,
  InfoLabelComponent,
  Icon,
}) => {
  const {
    isDownXl,
    isDownLg,
    isDownSm,
  } = useBreakPointsSizes();

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={2}
      sx={sx}
    >
      {!isDownXl && Icon}
      <Stack
        direction="column"
        gap={0.5}
      >
        <Stack
          direction="column"
          gap={isDownLg ? 0 : isDownXl ? 0.5 : 1}
        >
          <HIDTypography
            isLoading={isLoading}
            skeletonWidth={isDownSm ? 100 : isDownLg ? 150 : isDownXl ? 175 : 250}
            variant="h5"
          >
            {label}
          </HIDTypography>
          <HIDTypography
            isLoading={isLoading}
            skeletonWidth={isDownSm ? 75 : isDownLg ? 100 : isDownXl ? 120 : 200}
            variant={isDownLg ? 'h5' : 'h3'}
          >
            {value}
          </HIDTypography>
        </Stack>
        <Stack
          alignItems={isDownLg ? 'flex-start' : 'center'}
          direction={isDownLg ? 'column' : 'row'}
          gap={isDownLg ? 0 : 1}
        >
          {infoSubLabel && (
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={isDownXl ? 75 : 100}
              sx={getNoWrapStyle(1)}
            >
              {infoSubLabel}
            </HIDTypography>
          )}
          <HIDInfo
            LabelComponent={InfoLabelComponent}
            description={infoDescription}
            iconSize="small"
            isLoading={isLoading}
            label={infoLabel}
            labelSx={getNoWrapStyle(1)}
            labelVariant="subtitle1"
            skeletonWidth={isDownXl ? 75 : 100}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SummaryChartInfo;
