import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Menu,
  Theme,
  SxProps,
  SvgIconProps,
} from '@mui/material';
import {
  Check,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { Property } from '@house-id/houseid-types/dist/property';

import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  FCC,
} from '../../../../../types/common';
import StyledMenuItem from '../../../../../components/StyledMenuItem';
import { useGetPropertiesQuery } from '../../../api/api.property';
import HIDImage from '../../../../../components/image/HIDImage';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import { HIDZIndex } from '../../../../../constants/layout';
import { hidSpacing } from '../../../../../utils/number';
import { useSetUserSettingsMutation } from '../../../../Auth/api/api.settings';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import PropertyRowAddress from '../../../components/PropertyRowAddress';
import { SubscriptionFeature } from '../../../../SubscriptionPlans/types.subscriptionPlans';
import useTryUseFeatureDialog from '../../../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { UnavailableFeature } from '../../../components/dialogs/OpenMobileAppDialog';

type PropertyImageProps = {
  property: Property;
  size: number;
  iconSize: number,
  iconFontSize?: SvgIconProps['fontSize']
  warningIconFontSize?: SvgIconProps['fontSize']
};

const PropertyImage: FCC<PropertyImageProps> = ({
  property,
  iconSize,
  size,
  iconFontSize = 'medium',
  warningIconFontSize = 'small',
}) => {
  const theme = useTheme();
  const attention = property.readOnly || !property.isVerified;

  return (
    <Stack position="relative">
      <HIDImage
        PlaceholderIcon={() => <PlaceIcon fontSize={iconFontSize} />}
        iconSize={iconSize}
        size={size}
        sx={{
          backgroundColor: theme.palette.grey[200],
          width: size,
          height: size,
          borderRadius: size / 2,
        }}
        url={property.blobs?.[0]?.thumbnailUrl}
      />
      {attention && (
        <Box
          position="absolute"
          sx={{
            left: -5,
            top: -5,
          }}
          zIndex={HIDZIndex.LOGO}
        >
          <InfoIcon
            color="warning"
            fontSize={warningIconFontSize}
          />
        </Box>
      )}

    </Stack>
  );
};

type PropertyItemProps = {
  selectedPropertyId?: string;
  property: Property,
  onChangeSelectedPropertyId: (property: Property) => void
};

const PropertyItem: FCC<PropertyItemProps> = ({
  selectedPropertyId,
  property,
  sx,
  onChangeSelectedPropertyId,
}) => {
  const theme = useTheme();

  return (
    <StyledMenuItem
      key={property.id}
      sx={sx}
      onClick={() => onChangeSelectedPropertyId(property)}
    >
      <Stack
        alignItems="center"
        direction="row"
        flexGrow={1}
        spacing={1}
      >
        <PropertyImage
          iconFontSize="medium"
          iconSize={hidSpacing(3)}
          property={property}
          size={hidSpacing(5)}
        />
        <PropertyRowAddress propertyId={property.id} showAddress={false} />
        {property.id === selectedPropertyId && (
          <Box
            alignItems="center"
            display="flex"
            flexGrow={1}
            justifyContent="flex-end"
            paddingLeft={theme.spacing(2)}
          >
            <Check color="primary" />
          </Box>
        )}
      </Stack>
    </StyledMenuItem>
  );
};

type PropertySelectProps = {
  outline?: boolean;
  showImage?: boolean;
  showAddNew?: boolean;
  sx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
};

const PropertySelect: FC<PropertySelectProps> = ({
  outline = true,
  showImage = true,
  showAddNew = true,
  sx,
  buttonSx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home', 'property']);

  const { data: properties = [] } = useGetPropertiesQuery();
  const { data: property } = useGetCurrentProperty();

  const [setUserSettings] = useSetUserSettingsMutation();

  const [selectedProperty, setSelectedProperty] = useState<Property | undefined>(property);

  useEffect(() => {
    setSelectedProperty(property);
  }, [property]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleToggleMenuVisible = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleSelectPropertyId = (property: Property) => {
    setUserSettings({ currentPropertyId: property.id });

    setSelectedProperty(property);
    handleClose();
  };

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.MULTIPLE_PROPERTIES });

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleAddNewProperty = () => {
    handleClose();
    proceedToFeatureOrOpenSubscriptionDialog({
      onAction: () => openMobileAppDialog({ unavailableFeature: UnavailableFeature.ADD_PROPERTY }),
    });
  };

  const PropertySelectContent = (
    <Stack
      alignItems="center"
      direction="row"
      flex={1}
      justifyContent="flex-start"
      spacing={1}
      sx={sx}
    >
      {showImage && selectedProperty && (
        <PropertyImage
          iconFontSize="inherit"
          iconSize={hidSpacing(2)}
          property={selectedProperty}
          size={hidSpacing(3)}
          warningIconFontSize="inherit"
        />
      )}
      <Typography noWrap variant="body1">
        {selectedProperty?.displayName}
      </Typography>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
      >
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
    </Stack>
  );

  return (
    <>
      {
        outline
          ? (
            <HIDButton
              color="secondary"
              sx={{
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
                color: theme.palette.common.black,
                ...buttonSx,
              }}
              onClick={handleToggleMenuVisible}
            >
              {PropertySelectContent}
            </HIDButton>
          )
          : (
            <Box
              sx={{
                display: 'flex',
                flexShrink: 1,
                cursor: 'pointer',
                minWidth: 0,
              }}
              onClick={handleToggleMenuVisible}
            >
              {PropertySelectContent}
            </Box>
          )
      }
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        sx={{
          marginTop: theme.spacing(1),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {
          properties.map((property: Property, index: number) => (
            <PropertyItem
              key={property.id}
              property={property}
              selectedPropertyId={selectedProperty?.id}
              sx={index === properties.length - 1 && !showAddNew ? { border: 'none' } : undefined}
              onChangeSelectedPropertyId={handleSelectPropertyId}
            />
          ))
        }
        {showAddNew && (
          <HIDButton
            loading={subscriptionFeaturesIsLoading}
            size="medium"
            sx={{
              width: `calc(100% - ${theme.spacing(2)})`,
              margin: theme.spacing(1, 1, 0, 1),
            }}
            onClick={handleAddNewProperty}
          >
            {t('property:add_property')}
          </HIDButton>
        )}
      </Menu>
    </>
  );
};

export default PropertySelect;
