import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getProductsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PRODUCTS,
  { propertyId },
);

type GetProductPath = { propertyId: string, id: string };
export const getProductPath = ({ propertyId, id }: GetProductPath) => generateRoutePath(
  RouteNames.PRODUCT,
  { propertyId, id },
);

export const getCreateProductPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(RouteNames.PRODUCT_CREATE, { propertyId });

type GetUpdateProductPath = PropertyIdNavigation & {
  id: string;
  suggestionKey?: string;
};

export const getUpdateProductPath = ({
  propertyId,
  id,
  suggestionKey,
}: GetUpdateProductPath) => generateRoutePath(
  RouteNames.PRODUCT_UPDATE,
  { propertyId, id },
  { suggestionKey },
);
