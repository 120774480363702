import React from 'react';
import {
  Avatar,
  Skeleton,
  Stack,
  SxProps,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
  Icon,
} from '../types/common';
import HIDImage from './image/HIDImage';
import { resolveImageUrl } from '../utils/env';
import {
  getImageThumbnailUrl,
  ThumbnailSize,
} from '../utils/image';
import { hidSpacing } from '../utils/number';
import {
  getUserName,
  getUserNameInitials,
} from '../utils/string';
import HIDTypography, { HIDTypographyProps } from './HIDTypography';

type HIDAvatarSize = 'extraLarge' | 'large' | 'big' | 'medium' | 'small';

const Variants: Record<HIDAvatarSize, {
  size: number;
  typographyVariant: TypographyProps['variant'];
  fontSize?: number;
}> = {
  extraLarge: {
    size: hidSpacing(15),
    typographyVariant: 'h4',
  },
  large: {
    size: hidSpacing(8),
    typographyVariant: 'h4',
  },
  big: {
    size: hidSpacing(7),
    typographyVariant: 'h5',
  },
  medium: {
    size: hidSpacing(4),
    typographyVariant: 'subtitle1',
  },
  small: {
    size: hidSpacing(3),
    typographyVariant: 'subtitle2',
    fontSize: 12,
  },
};

type HIDAvatarImageProps = {
  blob?: HIDBlob;
  Icon?: Icon;
  externalImage?: string;
  size: number;
};

const HIDAvatarImage: FCC<HIDAvatarImageProps> = ({
  blob,
  Icon,
  externalImage,
  size,
  sx,
}) => (
  Icon
    ? <Icon />
    : blob || externalImage
      ? (
        <HIDImage
          sx={{
            width: size,
            height: size,
            objectFit: 'contain',
            ...sx,
          }}
          url={
            blob
              ? resolveImageUrl(getImageThumbnailUrl(blob.thumbnailUrlTemplate, ThumbnailSize.MAX_500))
              : externalImage
          }
        />
      )
      : null
);

export type HIDAvatarProps = {
  givenName?: string;
  surname?: string;
  blob?: HIDBlob;
  Icon?: Icon;
  externalImage?: string;
  showLabel?: boolean;
  labelVariant?: HIDTypographyProps['variant'];
  size?: HIDAvatarSize;
  isLoading?: boolean;
  avatarSx?: SxProps;
  imageSx?: SxProps;
};

const HIDAvatar: FCC<HIDAvatarProps> = ({
  givenName = '',
  surname = '',
  showLabel = false,
  labelVariant = 'subtitle2',
  blob,
  Icon,
  externalImage,
  size: currentSize = 'medium',
  isLoading = false,
  avatarSx,
  imageSx,
  sx,
}) => {
  const theme = useTheme();

  const variantSize = Variants[currentSize];

  const { size, typographyVariant, fontSize } = variantSize;

  const abbreviation = getUserNameInitials({ givenName, surname });
  const label = getUserName({ givenName, surname });

  const hasAnyImage = blob || externalImage || Icon;

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      sx={sx}
    >
      {
        isLoading
          ? <Skeleton height={size} variant="circular" width={size} />
          : (
            <Avatar
              sx={{
                width: size,
                height: size,
                backgroundColor: theme.palette.primary.main,
                ...avatarSx,
              }}
            >
              {
                hasAnyImage
                  ? (
                    <HIDAvatarImage
                      Icon={Icon}
                      blob={blob}
                      externalImage={externalImage}
                      size={size}
                      sx={{
                        alignSelf: 'center',
                        ...imageSx,
                      }}
                    />
                  )
                  : (
                    <HIDTypography sx={{ fontSize }} variant={typographyVariant}>
                      {abbreviation}
                    </HIDTypography>
                  )
              }
            </Avatar>
          )
      }
      {showLabel && (
        <HIDTypography variant={labelVariant}>
          {isLoading ? <Skeleton sx={{ width: 60 }} /> : label}
        </HIDTypography>
      )}
    </Stack>
  );
};

export default HIDAvatar;
