import React, { FC } from 'react';

import {
  DynamicGeoPointInput,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import DynamicContentTextInput from './DynamicContentTextInput';
import { GeoPoint } from '../../../../../../../../../types/common';

type DynamicContentGeoPointInputProps = DynamicGeoPointInput & DynamicInputCommonFieldProps<GeoPoint>;

const DynamicContentGeoPointInput: FC<DynamicContentGeoPointInputProps> = ({
  id,
  title,
  description,
  mandatory,
  placeholder,
  errorMessage,
  value,
  source,
  onBlur,
}) => {
  const formattedValue = value && value.coordinates?.length > 1 ? `${value.coordinates[1]} ${value.coordinates[0]}` : '';

  return (
    <DynamicContentTextInput
      readonly
      description={description}
      errorMessage={errorMessage}
      id={id}
      mandatory={mandatory}
      placeholder={placeholder}
      source={source}
      title={title}
      value={formattedValue}
      onBlur={onBlur}
    />
  );
};

export default DynamicContentGeoPointInput;
