import React, { forwardRef } from 'react';
import {
  Autocomplete,
  FormControl,
} from '@mui/material';

import HIDTextField from './HIDTextField';
import {
  FCC,
  FCCProps,
  HelperTextFormProps,
} from '../types/common';

export type HIDSelectItem = {
  id: string;
  name: string;
};

type HIDFormFreeSelectProps = HelperTextFormProps & {
  freeSolo?: boolean;
  label?: string;
  value?: string;
  items: Array<string>;
  fullWidth?: boolean;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange: (event: React.SyntheticEvent<Element, Event>, value?: string) => void;
};

type HIDFormFreeSelectPropsWithRef = FCCProps<HIDFormFreeSelectProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDFormFreeSelect: FCC<HIDFormFreeSelectProps> = forwardRef<HTMLInputElement, HIDFormFreeSelectPropsWithRef>((
  {
    freeSolo = true,
    label,
    value,
    items,
    fullWidth = true,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    sx,
    onBlur,
    onChange,
  },
  ref,
) => (
  <FormControl
    fullWidth={fullWidth}
    sx={sx}
    variant="standard"
  >
    <Autocomplete
      disableClearable
      forcePopupIcon
      freeSolo={freeSolo}
      options={items}
      renderInput={(params) => (
        <HIDTextField
          {...params}
          helperText={helperText}
          helperTextWrap={helperTextWrap}
          label={label}
          ref={ref}
          showHelperText={showHelperText}
          value={value}
        />
      )}
      value={value}
      onBlur={onBlur}
      onInputChange={(event, value) => onChange(event, value || undefined)}
    />
  </FormControl>
));

HIDFormFreeSelect.displayName = 'HIDFormFreeSelect';

export default HIDFormFreeSelect;
