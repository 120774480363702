import React, { FC } from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from 'recharts';

import { BudgetCostColors } from '../../../modules/Finances/constants.finance';

type StoragePieChartProps = {
  data: Array<{ name: string; value: number, color?: string }>;
  size: number;
};

const StoragePieChart: FC<StoragePieChartProps> = ({ data, size }) => {
  const innerRadius = size / 5;
  const outerRadius = size / 2.5;
  const pieChartSize = 80 / 2.5;

  return (
    <ResponsiveContainer height={size} width={size}>
      <PieChart height={pieChartSize} width={pieChartSize}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
        >
          {
            data.map((entry, index) => (
              <Cell fill={entry.color || BudgetCostColors[index % BudgetCostColors.length]} key={`cell-${index}`} />
            ))
          }
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StoragePieChart;
