import qs from 'query-string';

import { propertyApi } from '../../../../../api/api.property';
import { HIDApiTags } from '../../../../../../../api/HIDApiTags';

type Article = {
  id: string;
  title: string;
  ingress: string;
  data: {
    link: string;
  };
};

export const articleApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<Array<Article>, { pageSize?: number, classifications?: Array<string> }>({
      query: ({ pageSize, classifications }) => `/articles?${qs.stringify({ pageSize, classifications })}`,
      providesTags: [HIDApiTags.ARTICLE],
    }),
  }),
});

export const {
  useGetArticlesQuery,
} = articleApi;
