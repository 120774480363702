import React, {
  useMemo,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';
import { isValid } from 'date-fns';
import { DatePickerProps } from '@mui/x-date-pickers';

import HIDFilterDatePicker from './HIDFilterDatePicker';
import { DateTimeFormats } from '../../utils/date';
import { FILTER_DEBOUNCE_TIME } from '../../constants/layout';
import { FCC } from '../../types/common';

type HIDDateRangePickerProps = {
  from?: Date;
  to?: Date;
  dateTimeFormat?: DateTimeFormats;
  datePickerViews?: DatePickerProps<Date>['views'];
  onChange: (dates: { from?: Date, to?: Date }) => void;
};

const HIDDateRangePicker: FCC<HIDDateRangePickerProps> = ({
  from,
  to,
  dateTimeFormat,
  datePickerViews,
  sx,
  onChange,
}) => {
  const { t } = useTranslation(['common']);

  const [fromDate, setFromDate] = useState<Date | undefined>(from);
  const [toDate, setToDate] = useState<Date | undefined>(to);

  const filtersObject = useMemo(
    () => ({
      from: fromDate,
      to: toDate,
    }),
    [
      fromDate,
      toDate,
    ],
  );

  const debouncedFilters = useDebounce(
    filtersObject,
    FILTER_DEBOUNCE_TIME,
  );

  useUpdateEffect(() => {
    if ((!debouncedFilters.from || isValid(debouncedFilters.from))
      && (!debouncedFilters.to || isValid(debouncedFilters.to))
    ) {
      onChange({
        from: debouncedFilters.from,
        to: debouncedFilters.to,
      });
    }
  }, [debouncedFilters]);

  return (
    <Stack direction="column" sx={sx}>
      <HIDFilterDatePicker
        dateTimeFormat={dateTimeFormat}
        label={t('common:from')}
        maxDate={toDate}
        value={fromDate}
        views={datePickerViews}
        onChange={setFromDate}
      />
      <HIDFilterDatePicker
        dateTimeFormat={dateTimeFormat}
        label={t('common:to')}
        minDate={fromDate}
        value={toDate}
        views={datePickerViews}
        onChange={setToDate}
      />
    </Stack>
  );
};

export default HIDDateRangePicker;
