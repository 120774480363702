import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EditOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import ReactMarkdown from 'react-markdown';
import HIDImage from '../../../../../components/image/HIDImage';
import HIDCircularProgress from '../../../../../components/progress/HIDCircularProgress';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import { HIDZIndex } from '../../../../../constants/layout';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { ImageMimeTypes } from '../../../../../constants/mimeTypes';
import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import { ContentFile } from '../../../modules/Content/modules/ContentFile/types.contentFile';
import { useUpdatePropertyMutation } from '../../../api/api.property';
import { useDeleteContentFilesMutation } from '../../../modules/Content/modules/ContentFile/api/api.contentFile';
import {
  ThumbnailSize,
  getFirstLargeImageBlob,
  getImageThumbnailUrl,
} from '../../../../../utils/image';
import { PROPERTY_PHOTO_COVER_CLASSIFICATION } from '../../../constants/constants.property';
import propertyImage from '../../../../../assets/images/property.jpg';
import HorizontalOvalImageCut from '../../../../../components/svg/HorizontalOvalImageCut';
import useGetPropertyAccessDate from '../../../hooks/useGetPropertyAccessDateText';
import HIDInfo from '../../../../../components/HIDInfo';
import useGetPropertyProgressCategoryText from '../../../modules/PropertyProgress/hooks/useGetPropertyProgressCategoryText';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import useGetIsSfSellerSellingProperty from '../../../hooks/useGetIsSfSellerSellingProperty';
import { SellingPropertyStageLabel } from '../../../modules/SellingProperty/components/SellingPropertyStageLabel';

type PropertyHeaderProps = {
  progressPercent: number;
  ManageButton?: React.ReactElement;
};

const PropertyHeader: FC<PropertyHeaderProps> = ({
  progressPercent,
  ManageButton,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'property', 'home']);

  const { data: currentProperty, isLoading: isPropertyLoading } = useGetCurrentProperty();
  const [updateProperty] = useUpdatePropertyMutation();
  const [deleteContentFiles] = useDeleteContentFilesMutation();

  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const { data: isSellingProperty } = useGetIsSfSellerSellingProperty();

  const propertyAccessDate = useGetPropertyAccessDate({ propertyId: currentProperty?.id || '' });

  const firstBlob = currentProperty?.blobs
    ? R.head(currentProperty?.blobs?.filter(getFirstLargeImageBlob)) || R.head(currentProperty?.blobs)
    : undefined;

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();

  const imageHeight = R.cond([
    [() => isDownSm, R.always(200)],
    [() => isDownMd, R.always(250)],
    [() => isDownLg, R.always(300)],
    [() => isDownXl, R.always(350)],
    [R.T, R.always(400)],
  ])();

  const descriptionMaxWidth = R.cond([
    [() => isDownSm, R.always(380)],
    [() => isDownMd, R.always(750)],
    [() => isDownLg, R.always(750)],
    [() => isDownXl, R.always(800)],
    [R.T, R.always(800)],
  ])();

  const onContentFilesSelected = (files: Array<ContentFile>) => {
    const firstFile = R.head(files);
    if (currentProperty && firstFile) {
      updateProperty({
        id: currentProperty.id,
        blobs: [{ ...firstFile.blob, classifications: [PROPERTY_PHOTO_COVER_CLASSIFICATION] }],
      })
        .then(() => deleteContentFiles({ propertyId: currentProperty.id, ids: [firstFile.id] }));
    }
  };

  const [openFilePickerDialog] = useDialog(DialogNames.CONTENT_FILE_PICKER_DIALOG);

  const handleOpenFilePicker = () => {
    openFilePickerDialog({
      mimeTypes: ImageMimeTypes,
      isMultiple: false,
      onSelect: onContentFilesSelected,
    });
  };

  const getPropertyProgressPercentText = useGetPropertyProgressCategoryText();
  const description = getPropertyProgressPercentText({
    categoryId: 'all',
    percent: progressPercent,
  });

  return (
    <Stack alignItems="center">
      <Stack sx={{ position: 'relative', width: '100%' }}>
        <HIDImage
          showSkeleton
          skeletonSx={{
            width: '100%',
            height: imageHeight,
          }}
          sx={{
            width: '100%',
            height: imageHeight,
            objectFit: 'cover',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
          url={
            !isPropertyLoading && !firstBlob?.thumbnailUrlTemplate
              ? propertyImage
              : firstBlob?.thumbnailUrlTemplate
                ? getImageThumbnailUrl(firstBlob.thumbnailUrlTemplate, ThumbnailSize.MAX_2600)
                : undefined
          }
        />
        <HorizontalOvalImageCut color={theme.palette.common.white} />
        {canUpdate && (
          <HIDIconButton
            Icon={EditOutlined}
            color="alternate"
            sx={{
              position: 'absolute',
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
            onClick={handleOpenFilePicker}
          />
        )}
      </Stack>
      {!isSellingProperty && (
        <Stack
          sx={{
            padding: 1.5,
            backgroundColor: theme.palette.common.white,
            borderRadius: '100%',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.lighter,
            boxShadow: `0px 3px 12px 0px ${theme.palette.grey[200]}`,
            marginTop: -7.5,
            zIndex: HIDZIndex.PROPERTY_PROGRESS,
          }}
        >
          <HIDCircularProgress
            showLabel
            label={t('common:clear')}
            progressColorBackground={theme.palette.primary.lighter}
            size="large"
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: '100%',
            }}
            value={progressPercent}
          />
        </Stack>
      )}
      <Stack
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: theme.spacing(2.5),
        }}
      >
        <Typography
          component="h1"
          textAlign="center"
          variant={isDownLg ? 'h4' : 'h3'}
        >
          {t('property:property_progress_overview_title')}
        </Typography>
        <SellingPropertyStageLabel includeDate />
        {propertyAccessDate !== undefined && (
          <HIDInfo
            isMarkdown
            color="orange"
            description={t('property:property_access_date_description', { date: propertyAccessDate })}
            iconSize="small"
            label={t('property:property_access_date_title', { date: propertyAccessDate })}
            labelVariant="body2"
          />
        )}
        {!isSellingProperty && (
          <Typography
            display="block"
            paddingX={{
              xxs: 0.5,
              xs: 1,
              md: 3,
            }}
            sx={{
              maxWidth: descriptionMaxWidth,
            }}
            textAlign="center"
            variant="body1"
          >
            <ReactMarkdown components={{ p: 'div' }}>
              {description}
            </ReactMarkdown>
          </Typography>
        )}
      </Stack>
      {ManageButton && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            width: '100%',
            padding: {
              md: theme.spacing(2, 3, 0, 3),
              xxs: theme.spacing(2, 0, 0, 0),
            },
          }}
        >
          {ManageButton}
        </Stack>
      )}
    </Stack>
  );
};

export default PropertyHeader;
