import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Suggestion: Icon = ({
  iconColor: colorProp,
  fillColor: follColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = follColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <circle
        cx="12"
        cy="8"
        fill={fillColor}
        r="2"
      />
      <path
        d="M9 18H15M10 22H14M15.09 14C15.27 13.02 15.74 12.26 16.5 11.5C16.9829 11.0555 17.3662 10.5138 17.6247 9.91058C17.8832 9.30734 18.0111 8.65621 18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 9 6.23 10.23 7.5 11.5C8.22405 12.1621 8.718 13.0379 8.91 14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Suggestion;
