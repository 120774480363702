import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  PropertyProgressCategorySectionProps,
} from '../../../../PropertyProgress/types.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { getDocumentCategoryPath } from '../../../../Content/modules/Document/navigation.document';
import { DRAWINGS_CLASSIFICATION } from '../../../../Content/modules/Document/constants.document';
import { SellingPropertyDocumentationData } from '../../../types.sellingProperty';
import PropertyDocumentsList from '../../../../PropertyProgress/components/PropertyDocumentsList';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import HIDInfo from '../../../../../../../components/HIDInfo';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';

const SellingPropertyInfoBlueprintsDocumentsSection: FC<PropertyProgressCategorySectionProps<SellingPropertyDocumentationData>> = ({
  section,
}) => {
  const { t } = useTranslation(['property', 'selling_property']);

  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToDocuments = () =>
    navigate(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId: DRAWINGS_CLASSIFICATION }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          description={t('selling_property:selling_property_information_blueprints_description')}
          viewAllButtonText={t('property:property_progress_property_blueprints_view_all_drawings')}
          onViewAllClick={handleNavigateToDocuments}
        >
          <HIDInfo
            isMarkdown
            bodyTextSx={{ whiteSpace: 'pre-line' }}
            description={t('property:property_progress_property_blueprints_blueprints_info')}
            label={t('property:property_progress_property_blueprints_blueprints_read_more_about_blueprints')}
          />
          <PropertyDocumentsList
            propertyDocuments={section.data.documents}
          />
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default SellingPropertyInfoBlueprintsDocumentsSection;
