import React from 'react';
import {
  useTheme,
  Grid,
  Stack,
} from '@mui/material';
import * as R from 'ramda';

import { FCC } from '../../../../../types/common';
import BonusOfferCardLoaderSkeleton from './BonusOfferCardLoaderSkeleton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';

type BonusOffersGridLoaderSkeletonProps = {
  showPrimaryCard?: boolean;
  showDescription?: boolean;
  isLoading: boolean;
};

const BonusOffersGridLoaderSkeleton: FCC<BonusOffersGridLoaderSkeletonProps> = ({
  showPrimaryCard = false,
  showDescription = false,
  isLoading,
  children,
}) => {
  const theme = useTheme();
  const { isDownLg } = useBreakPointsSizes();

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {showPrimaryCard && (<BonusOfferCardLoaderSkeleton isPrimary={!isDownLg} showDescription={showDescription} />)}
        <Grid
          container
          spacing={1.5}
          sx={{ marginTop: theme.spacing(1) }}
        >
          {R.range(0, 4).map((i) => (
            <Grid
              item
              key={i}
              lg={6}
              xxs={12}
            >
              <BonusOfferCardLoaderSkeleton showDescription={showDescription} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    );
  }

  return children;
};

export default BonusOffersGridLoaderSkeleton;
