export enum MenuGroupId {
  PROPERTY_STRUCTURE = 'property_structure',
  CONTENT = 'content',
  HOUSE_SUPPORT = 'house_support',
  SETTINGS = 'settings',
}

export enum MenuId {
  BONUSES = 'bonuses',
  BUILDING_PARTS = 'building_parts',
  BUILDINGS = 'buildings',
  CONTACTS = 'contacts',
  DOCUMENTS = 'documents',
  EXPERTS = 'experts',
  FINANCE = 'finance',
  FORUM = 'forum',
  INSURANCE = 'insurance',
  MOVE = 'move',
  NOTES = 'notes',
  OUTDOORS = 'outdoors',
  PERSONAL_SETTINGS = 'personal_settings',
  PICTURES = 'pictures',
  PRODUCTS = 'products',
  PROPERTY_PROGRESS = 'propertyProgress',
  PROPERTY_SETTINGS = 'property_settings',
  PROPERTY_STRUCTURE = 'propertyStructure',
  RECEIPTS = 'receipts',
  ROOMS = 'rooms',
  SELLING_PROPERTY = 'sellingProperty',
  SUGGESTIONS = 'suggestions',
  SUPPORT = 'support',
  TASKS = 'tasks',
  TIMELINE = 'timeline',
  UNSORTED_FILES = 'unsorted_files',
}
