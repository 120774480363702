import React, {
  FC,
  useState,
} from 'react';
import {
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import HIDDataGrid from '../../../pages/Home/components/HIDDataGrid';
import {
  EntityContentConnection,
  ModificationType,
} from '../types/types.content';
import ConnectionsListToolbar from './ConnectionsListToolbar';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../constants/columns';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../utils/string';
import ContentImage from './ContentImage';
import { FileMimeType } from '../../../../../constants/mimeTypes';
import {
  HIDEntityId,
} from '../../../../../types/common';
import ListEntitiesToolbarActions, { SelectionModeType } from './actions/ListEntitiesToolbarActions';
import { usePatchEntityConnectionsMutation } from '../api/api.content';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import Document from '../../../../../components/icons/Document';
import useGetEntityInfo from '../hooks/useGetEntityInfo';

type UseGetContentConnectionsDataGridColumns = (props: {
  isSelectionMode: boolean,
}) => Array<GridColDef>;

export const useGetContentConnectionsDataGridColumns: UseGetContentConnectionsDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common', 'documents']);
  const theme = useTheme();

  const getEntityInfo = useGetEntityInfo();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 0.7,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const connection = params?.row as EntityContentConnection;
        const thumbnailUrl = connection.entity.image?.thumbnailUrl;
        const Icon = getEntityInfo(connection.type as EntityType)?.Icon || Document;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            {thumbnailUrl
              ? (
                <ContentImage
                  blobs={thumbnailUrl ? [{ thumbnailUrl, mime: FileMimeType.JPEG } as HIDBlob] : undefined}
                  imageSx={{ maxHeight: theme.spacing(8) }}
                  sx={{ marginRight: theme.spacing(1.5) }}
                />
              ) : (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{ minWidth: theme.spacing(7), marginRight: theme.spacing(1.5) }}
                >
                  <Icon />
                </Stack>
              )}
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {connection.entity.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('documents:documents_documents_updated_at_column_name'),
      flex: 0.3,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridRenderCellParams) => (params?.row as EntityContentConnection)?.details?.updatedAt,
      renderCell: (params: GridRenderCellParams) => {
        const { details } = params?.row as EntityContentConnection;
        const { updatedAt } = details || {};
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {updatedAt ? formatDate(new Date(updatedAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

type ContentConnectionsTabProps = {
  sourceEntity: Required<HIDEntityId>
  sourceEntityType: EntityType;
  connectionEntityType: EntityType;
  connections: Array<EntityContentConnection>;
  isLoading?: boolean;
};

const ContentConnectionsTab: FC<ContentConnectionsTabProps> = ({
  sourceEntity,
  sourceEntityType,
  connections,
  connectionEntityType,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Array<string>>([]);

  const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) =>
    setSelectedDocumentIds(rowSelectionModel as Array<string>);

  const getEntityInfo = useGetEntityInfo();
  const viewEntityConnection = getEntityInfo(connectionEntityType);

  const columns = useGetContentConnectionsDataGridColumns({
    isSelectionMode: false,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (!selectionModeType) {
      const entityConnection = params?.row as EntityContentConnection;
      if (viewEntityConnection?.getViewLink && propertyId) {
        navigate(viewEntityConnection?.getViewLink({ propertyId, id: entityConnection.entity.id }));
      }
    }
  };

  const [patchEntityConnections] = usePatchEntityConnectionsMutation();
  const handleDelete = () => {
    const deleteActions = R.map(
      (id) => ({
        type: connectionEntityType,
        entityId: id,
        action: ModificationType.DELETE,
      }),
      selectedDocumentIds,
    );

    if (propertyId) {
      const data = {
        propertyId,
        entityId: sourceEntity.id,
        entityType: sourceEntityType,
        actions: deleteActions,
      };

      return patchEntityConnections(data)
        .then(() => {
          setSelectionModeType(undefined);
          setSelectedDocumentIds([]);
        });
    }
  };

  return (
    <Stack>
      <ListEntitiesToolbarActions
        SortingComponent={(
          <ConnectionsListToolbar
            connectionEntityType={connectionEntityType}
            showTitle={false}
            sourceEntity={sourceEntity}
            sourceEntityType={sourceEntityType}
            onDelete={() => setSelectionModeType(SelectionModeType.DELETE)}
          />
        )}
        count={connections.length}
        entity={connectionEntityType}
        isFetching={false}
        isLoading={isLoading}
        selectedCount={selectedDocumentIds.length}
        selectionModeType={selectionModeType}
        onCancel={() => setSelectionModeType(undefined)}
        onDelete={handleDelete}
      />
      <HIDDataGrid
        columns={columns}
        getRowId={(row: EntityContentConnection) => row.entity.id}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'updatedAt',
                sort: 'desc',
              },
            ],
          },
        }}
        isLoading={isLoading}
        isSelectionMode={Boolean(selectionModeType)}
        rows={connections}
        onRowClick={handleRowClick}
        onRowSelectionModelChange={handleRowSelectionModelChange}
      />
    </Stack>
  );
};
export default ContentConnectionsTab;
