import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyPermission from '../constants/constants.propertyPermissions';
import useGetPropertyPermissions, { PropertyPermissions } from './useGetPropertyPermissions';

type UseGetPropertyEntityPermissions = () => {
  getPropertyEntityPermissions: (entityType: EntityType) => PropertyPermissions | undefined;
  getPropertyEntityModifyPermission: (entityType: EntityType) => boolean;
  getPropertyEntitiesModifyPermission: (entityTypes: Array<EntityType>) => boolean;
};

const useGetPropertyEntityPermissions: UseGetPropertyEntityPermissions = () => {
  const { data: defaultPermissions } = useGetPropertyPermissions();

  const EntityTypeAccessRight: Record<string, () => PropertyPermissions> = {
    [EntityType.RECEIPT]: () => {
      const permissionsList = [PropertyPermission.CAN_CREATE, PropertyPermission.CAN_UPDATE, PropertyPermission.CAN_DELETE];
      return {
        canCreate: permissionsList.includes(PropertyPermission.CAN_CREATE),
        canUpdate: permissionsList.includes(PropertyPermission.CAN_UPDATE),
        canDelete: permissionsList.includes(PropertyPermission.CAN_DELETE),
        permissions: permissionsList,
      };
    },
  };

  const getPropertyEntityAccessRight = (entityType: EntityType) => {
    const customEntityTypeAccessRights = EntityTypeAccessRight[entityType];
    if (customEntityTypeAccessRights) {
      return customEntityTypeAccessRights();
    }
    return defaultPermissions;
  };

  const getPropertyEntityAccessRightModify = (entityType: EntityType) => {
    const access = getPropertyEntityAccessRight(entityType);
    return Boolean(access?.canCreate || access?.canUpdate);
  };

  const getPropertyEntitiesAccessRightModify = (entityTypes: Array<EntityType>) => entityTypes.some(getPropertyEntityAccessRightModify);

  return {
    getPropertyEntityPermissions: getPropertyEntityAccessRight,
    getPropertyEntityModifyPermission: getPropertyEntityAccessRightModify,
    getPropertyEntitiesModifyPermission: getPropertyEntitiesAccessRightModify,
  };
};

export default useGetPropertyEntityPermissions;
