export enum FileMimeType {
  CSV = 'text/csv',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  HEIC = 'image/heic',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  TIFF = 'image/tiff',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export const ImageMimeTypes = [
  FileMimeType.JPEG,
  FileMimeType.PNG,
  FileMimeType.HEIC,
  FileMimeType.SVG,
  FileMimeType.TIFF,
];

export const AllSupportedFileMimeTypes = [
  FileMimeType.CSV,
  FileMimeType.DOC,
  FileMimeType.DOCX,
  FileMimeType.HEIC,
  FileMimeType.JPEG,
  FileMimeType.PDF,
  FileMimeType.PNG,
  FileMimeType.SVG,
  FileMimeType.TIFF,
  FileMimeType.XLS,
  FileMimeType.XLSX,
  FileMimeType.PPT,
  FileMimeType.PPTX,
];
