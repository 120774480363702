import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { getFinancesPath } from '../../../navigation.finances';
import { getRecurringExpensesCategoriesPath } from '../navigation.recurringExpenses';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../utils/routes';
import HIDTextField, { CurrencyAdornment } from '../../../../../../../components/HIDTextField';
import HIDFormSelect from '../../../../../../../components/HIDFormSelect';
import { RecurringExpenseCreateUpdateForm } from '../types.recurringExpenses';
import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';
import { getHandleSetField } from '../../../../../../../utils/form';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDSection from '../../../../../../../components/HIDSection';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDFormDatePicker from '../../../../../../../components/datePicker/HIDFormDatePicker';
import CreateContentPageBottomToolbar from '../../../../Content/components/CreateContentPageBottomToolbar';
import {
  useGetRecurringExpensesFiltrationQuery,
  useUpdateRecurringExpenseMutation,
} from '../api/api.recurringExpenses';

const CreateUpdateExpense: FC = () => {
  const { id } = useRouteParams<{ id: string }>();

  const { t } = useTranslation(['finances', 'forms_common', 'common']);
  const { data: propertyId } = useGetCurrentPropertyId();
  const navigateBackOr = useNavigateBackOr();

  const { isDownMd } = useBreakPointsSizes();

  const {
    data: filtration,
    isLoading: isFiltrationLoading,
    isFetching: isFiltrationFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    expenses: id,
    includeIncompleteMonths: true,
  });

  const isLoading = isFiltrationLoading || isFiltrationFetching;

  const expense = filtration?.expenses?.[0];

  const { data: categoriesEnum = {} } = useGetEnum(EnumType.ExpensePaymentCategory);
  const { data: intervalsEnum = {} } = useGetEnum(EnumType.ExpensePaymentInterval);
  const categoriesList = Object.entries(categoriesEnum).map(([id, name]) => ({ id, name }));
  const intervalsList = Object.entries(intervalsEnum).map(([id, name]) => ({ id, name }));

  const [updateRecurringExpense, { isLoading: isUpdating }] = useUpdateRecurringExpenseMutation();

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }));

  const defaultFormValues = {
    label: expense?.label || '',
    averageAmount: expense?.averageAmount || 0,
    category: expense?.category || (categoriesList)?.[0]?.id || '',
    interval: expense?.interval || (intervalsList)?.[0]?.id || '',
    contractPeriodEndDate: expense?.contract?.endDate,
    contractPeriodReminder: expense?.contract?.reminder || Boolean(expense?.contract?.endDate),
  };

  const schema = Yup.object({
    label: Yup.string().required('forms_common:field_mandatory'),
    averageAmount: Yup.number()
      .required('forms_common:field_mandatory'),
    category: Yup.string().required('forms_common:field_mandatory'),
    interval: Yup.string().required('forms_common:field_mandatory'),
    contactPeriodDate: Yup.string(),
    contactPeriodReminder: Yup.boolean(),
  });

  const handleFormSubmit = (values: RecurringExpenseCreateUpdateForm) => {
    updateRecurringExpense({
      id,
      label: values.label,
      averageAmount: values.averageAmount,
      category: values.category,
      interval: values.interval,
      contract: values.contractPeriodReminder && values.contractPeriodEndDate
        ? {
          endDate: values.contractPeriodEndDate,
          reminder: values.contractPeriodReminder,
        }
        : undefined,
      action: ModifyActionType.UPDATE,
    })
      .unwrap()
      .then(() => handleGoBack());
  };

  const formik = useFormik<RecurringExpenseCreateUpdateForm>({
    initialValues: defaultFormValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleFormSubmit,
  });

  const handleSave = () => formik.submitForm();

  const handleSetField = getHandleSetField<RecurringExpenseCreateUpdateForm>(formik);

  return (
    <HomeLayout
      SideColumn={<div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId }),
          name: t('finances:fixed_expenses'),
        },
      ]}
      title={t('finances:edit_expense')}
      onBack={handleGoBack}
    >
      <Stack
        direction="column"
        gap={2.5}
      >
        <HIDTextField
          disabled
          required
          endAdornment={<CurrencyAdornment />}
          label={t('finances:the_cost_amount')}
          value={formik.values.averageAmount.toString()}
        />
        <Stack
          direction={isDownMd ? 'column' : 'row'}
          gap={2.5}
        >
          <HIDTextField
            required
            error={Boolean(formik.touched.label && formik.errors.label)}
            helperText={formik.touched.label ? formik.errors.label : undefined}
            label={(t('finances:expense_name'))}
            value={formik.values.label}
            onBlur={formik.handleBlur('label')}
            onChange={handleSetField('label')}
          />
          <HIDFormSelect
            required
            error={Boolean(formik.touched.category && formik.errors.category)}
            helperText={formik.touched.category ? formik.errors.category : undefined}
            items={categoriesList}
            label={t('forms_common:select_category')}
            value={formik.values.category}
            onBlur={formik.handleBlur('category')}
            onChange={handleSetField('category')}
          />
        </Stack>
        <HIDSection title={t('finances:how_often_this_cost_is_repeated')}>
          <HIDFormSelect
            required
            error={Boolean(formik.touched.interval && formik.errors.interval)}
            helperText={formik.touched.interval ? formik.errors.interval : undefined}
            items={intervalsList}
            label={t('common:interval')}
            value={formik.values.interval}
            onBlur={formik.handleBlur('interval')}
            onChange={handleSetField('interval')}
          />
        </HIDSection>
        <HIDSection title={t('finances:time_of_contract')}>
          <Stack direction="column" gap={2.5}>
            <HIDTypography>
              {t('finances:if_expense_has_fixed_contract_set_the_contract_term_here')}
            </HIDTypography>
            <HIDFormDatePicker
              label={t('finances:contract_period')}
              value={formik.values.contractPeriodEndDate ? new Date(formik.values.contractPeriodEndDate) : undefined}
              onBlur={formik.handleBlur('contractPeriodEndDate')}
              onChange={(date) => formik.setFieldValue('contractPeriodEndDate', date?.toISOString())}
            />
            <Stack direction="column" gap={1}>
              <FormControlLabel
                control={<Switch checked={formik.values.contractPeriodReminder} />}
                label={<HIDTypography variant="subtitle1">{t('finances:contract_period')}</HIDTypography>}
                labelPlacement="start"
                sx={{ justifyContent: 'space-between', ml: 0 }}
                onChange={() => formik.setFieldValue('contractPeriodReminder', !formik.values.contractPeriodReminder)}
              />
              <HIDTypography>
                {t('finances:we_will_send_you_a_reminder_notice_in_30_days')}
              </HIDTypography>
            </Stack>
          </Stack>
        </HIDSection>
        <CreateContentPageBottomToolbar
          disabled={isLoading}
          loading={isUpdating}
          sx={{ mt: 2 }}
          onCancel={handleGoBack}
          onSave={handleSave}
        />
      </Stack>
    </HomeLayout>
  );
};

export default CreateUpdateExpense;
