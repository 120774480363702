import {
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';

import { getTypographyHeight } from '../../utils/style';
import { FCC } from '../../types/common';

type ListStructureLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
};

const ListStructureLoaderSkeleton: FCC<ListStructureLoaderSkeletonProps> = ({
  itemsCount = 7,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={1.5} sx={{ padding: theme.spacing(0.5, 1) }}>
        <Skeleton height={getTypographyHeight(theme.typography.body2)} variant="rounded" width="50%" />
        {R.range(0, itemsCount).map((i) => (
          <Skeleton
            height={getTypographyHeight(theme.typography.body2)}
            key={i}
            variant="rounded"
            width="100%"
          />
        ))}
      </Stack>
    );
  }

  return children;
};

export default ListStructureLoaderSkeleton;
