import React, { FC } from 'react';
import { PersonOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RecurringExpenseCollectionType } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDActionsSection from '../../../../../../../components/HIDActionsSection';
import Bank from '../../../../../../../components/icons/Bank';
import CreditCardSearch from '../../../../../../../components/icons/CreditCardSearch';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getRecurringExpensesBankAccountsPath } from '../navigation.recurringExpenses';
import CreditCardRefresh from '../../../../../../../components/icons/CreditCardRefresh';
import useStartRecurringExpenseCollection from '../hooks/useStartRecurringExpenseCollection';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';

enum RecurringExpensesAction {
  DOWNLOAD_NEW_COSTS = 'download_new_costs',
  UPDATE_YOUR_COSTS = 'update_your_costs',
  MANAGE_BANK_ACCOUNTS = 'manage_bank_accounts',
  MANAGE_COST_SHARING = 'manage_cost_sharing',
}

const RECURRING_EXPENSES_ACTIONS_KEY = 'recurringExpenses';
const RECURRING_EXPENSES_ACTIONS_SIDEBAR_KEY = 'recurringExpensesSidebar';

type RecurringExpensesActionsProps = {
  isSideDrawer?: boolean;
};

const RecurringExpensesActions: FC<RecurringExpensesActionsProps> = ({
  isSideDrawer,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'finances']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const collectionKey = isSideDrawer ? RECURRING_EXPENSES_ACTIONS_SIDEBAR_KEY : RECURRING_EXPENSES_ACTIONS_KEY;

  const openAddRecurringExpensesCollectionDialog = useStartRecurringExpenseCollection({
    recurringExpenseCollectionType: RecurringExpenseCollectionType.ADD,
    collectionKey,
  });

  const openUpdateRecurringExpensesCollectionDialog = useStartRecurringExpenseCollection({
    recurringExpenseCollectionType: RecurringExpenseCollectionType.UPDATE,
    collectionKey,
  });

  const handleManageBankExpenses = () => propertyId ? navigate(getRecurringExpensesBankAccountsPath({ propertyId })) : undefined;

  const [openManageCostSharingDialog] = useDialog(DialogNames.MANAGE_RECURRING_EXPENSE_BANK_ACCOUNT_CONSENTS_DIALOG);

  const actions = [
    {
      id: RecurringExpensesAction.DOWNLOAD_NEW_COSTS,
      Icon: CreditCardSearch,
      label: t('finances:download_new_costs'),
      onClick: openAddRecurringExpensesCollectionDialog,
    },
    {
      id: RecurringExpensesAction.UPDATE_YOUR_COSTS,
      Icon: CreditCardRefresh,
      label: t('finances:update_your_costs'),
      onClick: openUpdateRecurringExpensesCollectionDialog,
    },
    {
      id: RecurringExpensesAction.MANAGE_BANK_ACCOUNTS,
      Icon: Bank,
      label: t('finances:manage_bank_and_account'),
      onClick: handleManageBankExpenses,
    },
    {
      id: RecurringExpensesAction.MANAGE_COST_SHARING,
      Icon: PersonOutline,
      label: t('finances:manage_cost_sharing'),
      onClick: () => openManageCostSharingDialog(),
    },
  ].filter(Boolean);

  return (
    <HIDActionsSection
      items={actions}
      title={t('common:manage')}
    />
  );
};

export default RecurringExpensesActions;
