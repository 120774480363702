import React, {
  CSSProperties,
  FC,
} from 'react';
import {
  Stack,
  StackProps,
  styled,
  useTheme,
} from '@mui/material';

const StyledStack = styled(Stack)(({ theme, sx }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  backgroundColor: (sx as CSSProperties)?.backgroundColor || theme.palette.grey[50],
}));

type HIDClickableProps = StackProps & {
  isDisabled?: boolean;
};

const HIDClickable: FC<HIDClickableProps> = ({
  children,
  isDisabled = false,
  onClick,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <StyledStack
      sx={{
        cursor: isDisabled ? 'unset' : 'pointer',
        '&:hover': isDisabled ? {} : { backgroundColor: theme.palette.grey[100] },
        '&:active': isDisabled ? {} : { backgroundColor: theme.palette.grey[200] },
        ...sx,
      }}
      onClick={isDisabled ? undefined : onClick}
      {...rest}
    >
      {children}
    </StyledStack>
  );
};

export default HIDClickable;
