import { propertyApi } from './api.property';
import {
  SupportTicket,
  SupportTicketCategory,
} from '../types/types.support';

export const supportApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupportCategories: builder.query<Array<SupportTicketCategory>, void>({
      query: () => '/support/categories',
    }),
    createSupportTicket: builder.mutation<SupportTicket, SupportTicket>({
      query: (ticket) => ({
        url: '/support/ticket',
        method: 'POST',
        body: ticket,
      }),
    }),
  }),
});

export const {
  useGetSupportCategoriesQuery,
  useCreateSupportTicketMutation,
} = supportApi;
