import React, {
  memo,
  useMemo,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useUpdateEffect } from 'usehooks-ts';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  TreeItem,
  TreeView,
} from '@mui/x-tree-view';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllDocumentCategoriesQuery } from '../api/api.document';
import { ALL_DOCUMENTS_CATEGORY } from '../constants.document';
import { getDocumentCategoryPath } from '../navigation.document';
import HIDSelectItem from '../../../../../../../components/HIDSelectItem';
import {
  DocumentCategory,
  DocumentType,
} from '../types.document';
import { useNavigationParams } from '../../../../../../../utils/routes';
import { toggleSideDrawerOpen } from '../../../../../../../store/layoutReducer';
import TreeStructureLoaderSkeleton from '../../../../../../../components/skeletonLoaders/TreeStructureLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

type TreeDocumentCategory = {
  id: string;
  label: string;
  count?: number;
  types?: TreeDocumentCategory[];
};

const DocumentsQuickNavigation = () => {
  const { t } = useTranslation(['documents']);
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();

  const {
    routeParams: {
      category: categoryId,
    },
    queryParams: {
      typeId: typeIdSearchParam,
    },
  } = useNavigationParams<{ category: string }, { typeId?: string }>();

  const typeId = typeIdSearchParam ? decodeURIComponent(typeIdSearchParam) : undefined;

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: categories = [], isLoading } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const [selectedCategory, setSelectedCategory] = useState<{ categoryId: string, typeId?: string }>({ categoryId, typeId });

  const selectItems: Array<TreeDocumentCategory> = useMemo(() => [
    {
      id: ALL_DOCUMENTS_CATEGORY,
      label: t('documents:documents_documents_categories_all_documents'),
      count: R.sum(R.map((category) => category.count, categories)) || 0,
      types: [],
    } as TreeDocumentCategory,
  ]
    .concat(
      categories.map(
        (category: DocumentCategory) => ({
          id: category.id,
          label: category.name,
          count: category.count || 0,
          types: category.types
            .filter((type: DocumentType) => type.count > 0)
            .map((type: DocumentType) => ({ id: type.typeId, label: type.name, count: type.count || 0 } as TreeDocumentCategory)),
        }),
      ),
    ), [categories]);

  useUpdateEffect(() => {
    const { categoryId, typeId } = selectedCategory;
    navigate(
      getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId, typeId }),
      { replace: true },
    );
    dispatch(toggleSideDrawerOpen(false));
  }, [selectedCategory]);

  const handleTreeNodeSelect = (category: TreeDocumentCategory, parent?: TreeDocumentCategory) => parent
    ? setSelectedCategory({ categoryId: parent.id, typeId: category.id })
    : setSelectedCategory({ categoryId: category.id });

  const renderTree = (category: TreeDocumentCategory, parent?: TreeDocumentCategory) => (
    <TreeItem
      key={category.id}
      label={
        <HIDSelectItem
          count={category.count}
          key={category.id}
          label={category.label}
          style={{ backgroundColor: theme.palette.common.transparent }}
          onClick={() => handleTreeNodeSelect(category, parent)}
        />
      }
      nodeId={category.id}
    >
      {
        Array.isArray(category.types)
          ? category.types.map((type) => renderTree(type, category))
          : null
      }
    </TreeItem>
  );

  return (
    <TreeStructureLoaderSkeleton isLoading={isLoading}>
      <Stack>
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('documents:documents_documents_title')}
        </Typography>
        <TreeView
          defaultCollapseIcon={<GridExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={[selectedCategory.categoryId]}
          defaultSelected={selectedCategory.typeId ? selectedCategory.typeId : selectedCategory.categoryId}
        >
          {selectItems.map((item) => renderTree(item))}
        </TreeView>
      </Stack>
    </TreeStructureLoaderSkeleton>
  );
};

export default memo(DocumentsQuickNavigation);
