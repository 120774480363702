import useGetCurrentProperty from '../../../hooks/useCurrentProperty';
import useGetSellingPropertyStageBasedText from './useGetSellingPropertyStageBasedText';

type UseGetSellingCurrentPropertyStageBasedText = (params?: { includeDate?: boolean }) => {
  label: string;
  title: string;
  description: string;
};

const useGetSellingCurrentPropertyStageBasedText: UseGetSellingCurrentPropertyStageBasedText = (params) => {
  const { data: property } = useGetCurrentProperty();

  const text = useGetSellingPropertyStageBasedText({
    property,
    includeDate: params?.includeDate,
  });

  return text;
};

export default useGetSellingCurrentPropertyStageBasedText;
