import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import {
  PropertyFinancesLoanInterestRateSectionData,

  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';
import Discount from '../../../../../../../components/icons/Discount';
import Smile from '../../../../../../../components/icons/Smile';
import SadSmile from '../../../../../../../components/icons/SadSmile';
import RateComparisonCard from '../../../components/RateComparisonCard';
import { SWEDISH_REGION_ID } from '../../../../Finances/constants.finance';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDTypography from '../../../../../../../components/HIDTypography';

const PropertyFinancesLoanInterestRateSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesLoanInterestRateSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'home', 'property']);

  const theme = useTheme();
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const { isDownXl } = useBreakPointsSizes();

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),

    categoryId: PropertyProgressCategorySection.FINANCE,
    sectionId: section.id,
  });

  const handleAddInsurance = () => openMobileAppDialog({ unavailableFeature: EntityType.LOAN });
  const handleReadMore = () => openMobileAppDialog({ unavailableFeature: EntityType.LOAN });

  const {
    weightedAverageComparisonInterestRates,
    weightedAverageInterestRate,
    numberOfLoans,
    ordna,
    synchronizeTimestamp,
  } = section.data;

  const averageInterestRateByCountryData = weightedAverageComparisonInterestRates
    ?.find((rate) => rate.regionId === SWEDISH_REGION_ID);

  const averageInterestRateByCountry = averageInterestRateByCountryData?.interestRate;

  const interestRateIsAcceptable = weightedAverageInterestRate !== undefined
    && averageInterestRateByCountry !== undefined
    && weightedAverageInterestRate < averageInterestRateByCountry;

  const hasLoans = Boolean(numberOfLoans);

  const ordnaLastMonthAverage = new Date();

  const interestRateIcon = !hasLoans
    ? <Discount iconColor={theme.palette.primary.light} />
    : interestRateIsAcceptable
      ? <Smile iconColor={theme.palette.primary.dark} />
      : <SadSmile iconColor={theme.palette.warning.main} />;

  const interestRateBackgroundColor = (!hasLoans || interestRateIsAcceptable)
    ? theme.palette.primary.lighter
    : theme.palette.warning.lighter;

  const interestRateDescription = hasLoans
    ? t('home:home_my_average_interest_rate_from_loans', { count: numberOfLoans || 0 })
    : t('home:home_my_average_interest_rate');

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_financing_loan_interest_rate_add_connect_interest')}
          description={hasLoans ? undefined : description}
          viewAllButtonText={t('property:property_progress_property_financing_loan_interest_rate_go_to_interest_check')}
          onAddClick={handleAddInsurance}
          onViewAllClick={handleReadMore}
        >
          <Stack spacing={2}>
            {typeof synchronizeTimestamp === 'string' && (
              <HIDTypography color={theme.palette.grey[500]}>
                {t('forms_common:last_updated')}
                {' '}
                {formatDate(new Date(synchronizeTimestamp), DateTimeFormats.DATE_ONLY)}
              </HIDTypography>
            )}
            {hasLoans && (
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={2}
                justifyContent="stretch"
              >
                <Stack flex={1}>
                  <RateComparisonCard
                    Icon={interestRateIcon}
                    backgroundColor={interestRateBackgroundColor}
                    rate={weightedAverageInterestRate}
                    style={{
                      minWidth: isDownXl ? 180 : 240,
                      flex: 2,
                    }}
                    subtitle={interestRateDescription}
                  />
                </Stack>
                <Stack
                  direction="row"
                  flex={1}
                  justifyContent="stretch"
                  spacing={2}
                >
                  {Boolean(ordna?.averageInterestRates?.lastTwelveMonthAverage) && (
                    <RateComparisonCard
                      backgroundColor={theme.palette.grey[100]}
                      rate={ordna.averageInterestRates.lastTwelveMonthAverage}
                      style={{ minWidth: isDownXl ? 110 : 140, flex: 1 }}
                      subtitle={t('home:home_order_average')}
                      subtitleDescription={t('home:home_12_months')}
                    />
                  )}
                  {Boolean(ordna?.averageInterestRates?.lastMonthAverage) && (
                    <RateComparisonCard
                      backgroundColor={theme.palette.grey[100]}
                      rate={ordna.averageInterestRates.lastMonthAverage}
                      style={{ minWidth: isDownXl ? 110 : 140, flex: 1 }}
                      subtitle={t('home:home_order_average')}
                      subtitleDescription={formatDate(ordnaLastMonthAverage, DateTimeFormats.MONTH_AND_YEAR)}
                    />
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyFinancesLoanInterestRateSection;
