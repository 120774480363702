import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Stack,
  Skeleton,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { useGetSuggestionsSummaryQuery } from '../api/api.suggestion';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { useNavigateBackOr } from '../../../../../../../utils/routes';
import { getHomePath } from '../../../../../navigation/navigation.property';
import {
  getSuggestionsPath,
  getSuggestionsSettingsPath,
} from '../navigation.suggestion';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import SuggestionSettings from '../../../../../../../components/icons/SuggestionSettings';
import useGetSuggestionCategories from '../hooks/useGetSuggestionCategories';
import HIDClickable from '../../../../../../../components/HIDClickable';
import { getTypographyHeight } from '../../../../../../../utils/style';
import { FCC } from '../../../../../../../types/common';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import AllDoneImage from '../../../../../../../assets/images/all_done.svg';

const defaultItemSizes = [21, 30, 15, 19, 26];

type SuggestionCategoriesLoaderSkeletonProps = {
  itemSizes?: ReadonlyArray<number>;
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const SuggestionCategoriesLoaderSkeleton: FCC<SuggestionCategoriesLoaderSkeletonProps> = ({
  itemSizes = defaultItemSizes,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={2} sx={{ marginTop: 3 }}>
        {itemSizes.map((size, i) => (
          <Box
            key={size + i}
            sx={{
              padding: 1.5,
              borderRadius: theme.spacing(1.25),
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Stack alignItems="center" direction="row" spacing={2}>
              <Skeleton
                height={44}
                variant="rounded"
                width={44}
              />
              <Skeleton
                height={getTypographyHeight(theme.typography.body2)}
                variant="rounded"
                width={`${size}%`}
              />
            </Stack>
          </Box>
        ))}
      </Stack>
    );
  }

  return children;
};

type SuggestionsCategoryListItemProps = {
  Icon?: React.ElementType;
  type?: EntityType;
  label: string;
  count: number;
  onClick: (type?: EntityType) => void;
};

const SuggestionsCategoryListItem: FC<SuggestionsCategoryListItemProps> = ({
  Icon,
  type,
  label,
  count,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={2.5}
      sx={{
        padding: 1.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
      }}
      onClick={() => onClick(type)}
    >
      {Icon && (
        <Stack
          sx={{
            backgroundColor: theme.palette.info.lighter,
            borderRadius: '5px',
            padding: 1.25,
          }}
        >
          <Icon />
        </Stack>
      )}
      <Typography sx={{ flex: 1 }} variant="subtitle1">
        {label}
      </Typography>
      <Badge
        badgeContent={count}
        color="error"
      />
      <ChevronRight />
    </HIDClickable>
  );
};

const SuggestionCategories: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'suggestion']);
  const { isDownXl } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { currentData: suggestionsSummary, isLoading } = useGetSuggestionsSummaryQuery(propertyId ? { propertyId } : skipToken);

  const listItems = useGetSuggestionCategories(suggestionsSummary);

  const handleNavigateToCategory = (type: string) => navigate(
    getPathWithPropertyIdOrInit(getSuggestionsPath, { propertyId, entityType: type }),
  );

  const EmptyComponent = (
    <Stack alignItems="center" spacing={2}>
      <img
        src={AllDoneImage}
        style={{
          height: 200,
        }}
      />
      <Typography variant="h4">
        {t('suggestion:suggestion_everything_cool')}
      </Typography>
      <Typography variant="body1">
        {t('suggestion:suggestion_no_smart_suggestions_to_complete')}
      </Typography>
    </Stack>
  );

  return (
    <HomeLayout
      BodyLoaderSkeleton={SuggestionCategoriesLoaderSkeleton}
      SideColumn={isDownXl ? undefined : <div />}
      TitleRightComponent={
        <HIDIconButton
          Icon={SuggestionSettings}
          onClick={() => navigate(getPathWithPropertyIdOrInit(getSuggestionsSettingsPath, { propertyId }))}
        />
      }
      isLoading={isLoading}
      title={t('suggestion:smart_suggestions')}
      titleInfo={{ description: t('suggestion:smart_suggestions_description') }}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack spacing={2} sx={{ marginTop: 3 }}>
        {!listItems.length
          ? EmptyComponent
          : (
            listItems
              .map(({
                Icon,
                id,
                label,
                count,
              }) => (
                <SuggestionsCategoryListItem
                  Icon={Icon}
                  count={count || 0}
                  key={id}
                  label={label}
                  type={id as EntityType}
                  onClick={() => handleNavigateToCategory(id)}
                />
              ))
          )}
      </Stack>
    </HomeLayout>
  );
};

export default SuggestionCategories;
