import {
  AttachFile,
  ChevronRight,
} from '@mui/icons-material';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { Message } from '../types.message';
import { APP_NAME } from '../../../../../../../constants/links';
import MessageReadDot from './MessageReadDot';
import HIDClickable from '../../../../../../../components/HIDClickable';

type MessageListItemCompactProps = {
  message: Message;
  onClick: () => void;
};

const MessageListItemCompact: FC<MessageListItemCompactProps> = ({
  message,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const attachmentsCount = message.blobs?.length || 0;

  return (
    <HIDClickable
      sx={{
        padding: theme.spacing(0.25, 1, 0.25, 3),
        margin: theme.spacing(0.25, 0),
        borderRadius: '2px',
        backgroundColor: message.read
          ? theme.palette.grey[50]
          : theme.palette.primary.lightest,
      }}
      onClick={onClick}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            style={{ marginLeft: theme.spacing(-2) }}
          >
            <MessageReadDot isRead={message.read} />
            <Typography variant="subtitle1">
              {message.sender.name || APP_NAME}
            </Typography>
          </Stack>
          <Typography variant="body1">
            {message.subject}
          </Typography>
          {attachmentsCount > 0 && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
            >
              <AttachFile
                sx={{
                  color: theme.palette.grey[500],
                  width: theme.spacing(2),
                  height: theme.spacing(2),
                  transform: 'rotate(45deg)',
                }}
              />
              <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
                {`${attachmentsCount} ${attachmentsCount === 1 ? t('common:attachment') : t('common:attachments')}`}
              </Typography>
            </Stack>
          )}
          <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
            {formatDate(new Date(message.createdAt), DateTimeFormats.DATE_ONLY_TEXT)}
          </Typography>
        </Stack>
        <ChevronRight />
      </Stack>
    </HIDClickable>
  );
};

export default MessageListItemCompact;
