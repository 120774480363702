import React, {
  FocusEventHandler,
  forwardRef,
} from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  FCC,
  FCCProps,
  HelperTextFormProps,
} from '../types/common';
import { HIDZIndex } from '../constants/layout';
import { hidSpacing } from '../utils/number';
import HIDTypography from './HIDTypography';

export type HIDSelectItem = {
  id: string;
  name: string;
  isCategory?: boolean;
};

type HIDFormSelectProps = HelperTextFormProps & {
  required?: boolean;
  multilevel?: boolean;
  label: string;
  value?: string;
  items: Array<HIDSelectItem>;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: TextFieldProps['error'];
  placeholder?: string;
  variant?: FormControlProps['variant'];
  size?: FormControlProps['size'];
  showClear?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (event: SelectChangeEvent) => void;
};

type HIDFormSelectPropsWithRef = FCCProps<HIDFormSelectProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDFormSelect: FCC<HIDFormSelectProps> = forwardRef<HTMLInputElement, HIDFormSelectPropsWithRef>((
  {
    required,
    multilevel = false,
    label,
    value,
    items,
    fullWidth = true,
    variant = 'standard',
    disabled,
    error,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    placeholder,
    size = 'medium',
    showClear = false,
    onBlur,
    onChange,
    sx,
  },
  ref,
) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      size={size}
      sx={sx}
      variant={variant}
    >
      <InputLabel
        sx={{
          '& +.MuiOutlinedInput-root': {
            paddingTop: '0px',
          },
          pointerEvents: 'none',
          zIndex: HIDZIndex.LABEL,
        }}
        variant={variant}
      >
        {label}
      </InputLabel>
      <Select
        inputRef={ref}
        label={label}
        placeholder={placeholder}
        sx={{
          ...(!value && { backgroundColor: theme.palette.primary.lightest }),
          '& .MuiInput-input:focus': {
            backgroundColor: theme.palette.common.transparent,
          },
          '& .MuiOutlinedInput-input': size === 'medium'
            ? {
              paddingLeft: 2,
              paddingTop: 1.5,
              paddingBottom: 1.5,
              lineHeight: `${hidSpacing(3)}px`,
            }
            : {},
        }}
        value={value}
        variant={variant}
        onBlur={onBlur}
        onChange={onChange}
      >
        {showClear && (
          <MenuItem value="">
            <em>{t('common:clear_remove')}</em>
          </MenuItem>
        )}
        {
          items.map((item) => item.isCategory
            ? (
              <ListSubheader key={item.id} sx={{ color: theme.palette.common.black }}>
                <HIDTypography sx={{ paddingY: 1 }} variant="subtitle1">
                  {item.name}
                </HIDTypography>
              </ListSubheader>
            )
            : (
              <MenuItem key={item.id} sx={{ marginLeft: multilevel ? 2 : 0 }} value={item.id}>
                {item.name}
              </MenuItem>
            ))
        }
      </Select>
      {showHelperText && (
        <FormHelperText sx={{ textWrap: helperTextWrap ? 'wrap' : 'nowrap' }}>
          {helperText || ' '}
        </FormHelperText>
      )}
    </FormControl>
  );
});

HIDFormSelect.displayName = 'HIDFormSelect';

export default HIDFormSelect;
