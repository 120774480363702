import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import {
  RemainingTime,
  getRemainingTime,
} from './date';

export const useGetFormatMonth = () => {
  const { t } = useTranslation(['forms_common']);

  return (number?: number) => {
    if (!number || number <= 0) {
      return '';
    }

    const monthLabel = number === 1
      ? t('forms_common:month_singular')
      : t('forms_common:month_plural');

    return `${number} ${monthLabel}`;
  };
};

export const useGetFormatYear = () => {
  const { t } = useTranslation(['forms_common']);

  return (number?: number) => {
    if (!number || number <= 0) {
      return '';
    }

    const yearLabel = number === 1
      ? t('forms_common:year_singular')
      : t('forms_common:year_plural');

    return `${number} ${yearLabel}`;
  };
};

export const useGetPeriodLabelFromMonth = () => {
  const formatMonth = useGetFormatMonth();
  const formatYear = useGetFormatYear();

  return (number?: number) => {
    if (!number || number <= 0) {
      return '';
    }

    const years = Math.floor(number / 12);
    const month = number - years * 12;

    return `${formatYear(years)} ${formatMonth(month)}`.trim();
  };
};

export const useGetRemainingTimeLabel = () => {
  const { t } = useTranslation(['common', 'forms_common']);

  return (remainingTime?: RemainingTime) => {
    if (remainingTime) {
      return match(remainingTime)
        .with(
          { unit: 'years' },
          ({ value }) => `${value} ${value === 1 ? t('forms_common:year_singular') : t('forms_common:year_plural')}`,
        )
        .with(
          { unit: 'months' },
          ({ value }) => `${value} ${value === 1 ? t('forms_common:month_singular') : t('forms_common:month_plural')}`,
        )
        .with({ unit: 'days' }, ({ value }) => `${value} ${value === 1 ? t('common:day') : t('common:days')}`)
        .with({ unit: 'hours' }, ({ value }) => `${value} ${value === 1 ? t('common:hour') : t('common:hours')}`)
        .with({ unit: 'minutes' }, ({ value }) => `${value} ${value === 1 ? t('common:minute') : t('common:minutes')}`)
        .otherwise(() => '');
    }
    return '';
  };
};

export const useGetRemainingTimeFromDateLabel = () => {
  const getRemainingTimeLabel = useGetRemainingTimeLabel();

  return (date?: Date) => {
    if (date) {
      const remainingTime = getRemainingTime(date);

      return remainingTime ? getRemainingTimeLabel(remainingTime) : '';
    }
    return '';
  };
};
