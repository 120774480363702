import React, { FC } from 'react';

import {
  PropertyProgressCategorySectionProps,
  PropertyFinancesPurchaseInfoSectionData,
} from '../../../../PropertyProgress/types.propertyProgress';
import PropertyProgressDynamicContentSection from '../../../../PropertyProgress/components/PropertyProgressDynamicContentSection';

const SellingPropertyInfoPurchaseInfoSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesPurchaseInfoSectionData>> = ({
  section,
}) => (
  <PropertyProgressDynamicContentSection
    key={section.id}
    section={section}
  />
);

export default SellingPropertyInfoPurchaseInfoSection;
