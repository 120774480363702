import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import useTryUseFeatureDialog from '../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { SubscriptionFeature } from '../../SubscriptionPlans/types.subscriptionPlans';

type UseTryOpenAskForumQuestionDialog = () => [
  (params: { isAskExpert: boolean; topicId?: string }) => void,
  boolean,
];

const useTryOpenAskForumQuestionDialog: UseTryOpenAskForumQuestionDialog = () => {
  const [openAskForumQuestionDialog] = useDialog(DialogNames.ASK_FORUM_QUESTION_DIALOG);

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.EXPERTS_PANEL_AND_FORUM });

  const tryOpen = (params: { isAskExpert: boolean, topicId?: string }) => params.isAskExpert
    ? proceedToFeatureOrOpenSubscriptionDialog({ onAction: () => openAskForumQuestionDialog(params) })
    : openAskForumQuestionDialog(params);

  return [tryOpen, subscriptionFeaturesIsLoading];
};

export default useTryOpenAskForumQuestionDialog;
