import React from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import Timeline from '../../../../../../../../../components/icons/Timeline';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../../../types/common';
import { useGetGetStartedWizardDataQuery } from '../../../api/api.timeline';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { GET_STARTED_WIZARD_MAX_STEP } from '../../../constants.timeline';
import useGetPropertyPermissions from '../../../../../../../hooks/useGetPropertyPermissions';

type GetStartedBannerProps = {
  onClick: () => void;
};

const GetStartedBanner: FCC<GetStartedBannerProps> = ({
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['timeline']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: getStartedWizardData } = useGetGetStartedWizardDataQuery(propertyId ? { propertyId } : skipToken);
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  if (getStartedWizardData?.status === 'finished' || !canCreate) {
    return null;
  }

  const isStarted = getStartedWizardData?.status === 'started';
  const stepsLeft = getStartedWizardData?.activeStep !== undefined
    ? GET_STARTED_WIZARD_MAX_STEP - getStartedWizardData.activeStep + 1
    : GET_STARTED_WIZARD_MAX_STEP;

  return (
    <Card
      style={style}
      sx={{
        padding: 2.5,
        backgroundColor: theme.palette.primary.lighter,
        ...sx,
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Stack
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: 2,
            borderRadius: theme.spacing(6),
          }}
        >
          <Timeline fillColor={theme.palette.common.white} />
        </Stack>
        <Typography sx={{ textAlign: 'center' }} variant="h6">
          {isStarted ? t('timeline:wizard_finish_get_started_wizard') : t('timeline:wizard_get_started')}
        </Typography>
        <Typography sx={{ textAlign: 'center' }} variant="body1">
          {
            isStarted
              ? t('timeline:wizard_fill_in_steps_to_complete', { steps: stepsLeft })
              : t('timeline:wizard_get_started_description')
          }
        </Typography>
        <HIDButton onClick={onClick}>
          {isStarted ? t('timeline:wizard_complete') : t('timeline:wizard_get_started_button')}
        </HIDButton>
      </Stack>
    </Card>
  );
};

export default GetStartedBanner;
