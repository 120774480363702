import React, {
  FC,
  useCallback,
  useState,
} from 'react';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GridEventListener,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';

import ListEntitiesActions from '../../Property/modules/Content/components/actions/ListEntitiesActions';
import { getForumPath } from '../navigation/navigation.forum';
import {
  useGetForumThreadsQuery,
  useRemoveFromBookmarksMutation,
} from '../api/api.forum';
import {
  ForumThread,
  ForumThreadsSortOrder,
} from '../types.forum';
import ListEntitiesToolbarActions, {
  SelectionModeType,
} from '../../Property/modules/Content/components/actions/ListEntitiesToolbarActions';
import HomeListLayout from '../../Property/pages/Home/components/HomeListLayout';
import useGetForumThreadColumns from '../hooks/useGetForumThreadColumns';
import useTryOpenAskForumQuestionDialog from '../hooks/useTryOpenAskForumQuestionDialog';
import useTryNavigateToThread from '../hooks/useTryNavigateToThread';

const MyForumBookmarks: FC = () => {
  const { t } = useTranslation(['common', 'forms_common', 'forum']);

  const [tryOpenAskForumQuestionDialog, isTryOpenAskForumQuestionDialogLoading] = useTryOpenAskForumQuestionDialog();

  const handleAskExpert = () => tryOpenAskForumQuestionDialog({ isAskExpert: true });
  const handleAskForum = () => tryOpenAskForumQuestionDialog({ isAskExpert: false });

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

  const [removeFromBookmarks] = useRemoveFromBookmarksMutation();

  const {
    data: { threads = [] } = {},
    isLoading: threadIsLoading,
    isFetching: threadIsFetching,
    refetch: refetchBookmarkedThreads,
  } = useGetForumThreadsQuery({ sort: ForumThreadsSortOrder.LAST_UPDATE_DESC, followed: true });

  const handleEnterSelectionMode = (selectionModeType: SelectionModeType) => {
    setSelectionModeType(selectionModeType);
    setSelectedIds([]);
  };

  const handleRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => setSelectedIds(rowSelectionModel as Array<string>),
    [],
  );

  const handleDelete = async (threadIds: Array<string>) => {
    await Promise.all(threadIds.map((threadId) => removeFromBookmarks({ threadId })));
    await refetchBookmarkedThreads();

    setSelectionModeType(undefined);
    setSelectedIds([]);
  };

  const customActions = [
    {
      id: 'ask_forum',
      Icon: Add,
      label: t('forum:forum_ask_the_forum'),
      onClick: handleAskForum,
    },
    {
      id: 'ask_expert',
      Icon: Add,
      label: t('forum:forum_ask_the_expert'),
      disabled: isTryOpenAskForumQuestionDialogLoading,
      onClick: handleAskExpert,
    },
  ];

  const columns = useGetForumThreadColumns();

  const handleTryNavigateToThread = useTryNavigateToThread();

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const thread = params?.row as ForumThread;
    handleTryNavigateToThread(thread);
  };

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={threads.length}
          countLabel={`${threads.length} ${t('forum:forum_followings')}`}
          isFetching={threadIsFetching}
          isLoading={threadIsLoading}
          selectedCount={selectedIds.length}
          selectionModeType={selectionModeType}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={() => handleDelete(selectedIds)}
        />
      }
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ListEntitiesActions
            customActions={customActions}
            onDelete={threads.length ? () => handleEnterSelectionMode(SelectionModeType.DELETE) : undefined}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getForumPath(),
          name: t('forum:forum_title'),
        },
      ]}
      columns={columns}
      isSelectionMode={Boolean(selectionModeType)}
      rows={threads}
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={customActions}
          key={ListEntitiesActions.name}
          onDelete={threads.length ? () => handleEnterSelectionMode(SelectionModeType.DELETE) : undefined}
        />,
      ]}
      title={t('forum:forum_my_bookmarks')}
      onRowClick={handleRowClick}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default MyForumBookmarks;
