import React, { FC } from 'react';
import { Stack } from '@mui/material';

import {
  DynamicContentImageInput,
  DynamicContentImageInputHeightType,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';

const imageHeightSizes: Record<DynamicContentImageInputHeightType, number> = {
  [DynamicContentImageInputHeightType.tiny]: 80,
  [DynamicContentImageInputHeightType.small]: 160,
  [DynamicContentImageInputHeightType.medium]: 240,
  [DynamicContentImageInputHeightType.large]: 300,
};

type DynamicContentImageInputProps = DynamicContentImageInput;

const DynamicContentImage: FC<DynamicContentImageInputProps> = ({
  value,
  source,
}) => {
  const heightType = value?.heightType || DynamicContentImageInputHeightType.small;
  const imageHeight = imageHeightSizes[heightType];

  return value.url
    ? (
      <Stack spacing={1}>
        <img
          src={value.url}
          style={{ height: imageHeight }}
        />
        <DynamicContentSource source={source} />
      </Stack>
    )
    : null;
};
export default DynamicContentImage;
