import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronRight,
} from '@mui/icons-material';

import HIDClickable from '../../../../../../../components/HIDClickable';
import {
  SellingPropertyChecklistIds,
  SellingPropertyChecklistItemType,
} from '../../../hooks/useGetSellingPropertyChecklist';

type SellingPropertyChecklistItemProps = {
  item: SellingPropertyChecklistItemType;
  onClick: (id: SellingPropertyChecklistIds) => void;
};

const SellingPropertyChecklistItem: FC<SellingPropertyChecklistItemProps> = ({
  item,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={2.5}
      sx={{
        padding: 1.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        height: '100%',
      }}
      onClick={() => onClick(item.id)}
    >
      {item.Icon && (
        <Stack
          alignSelf="center"
          sx={{
            padding: 2,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: theme.spacing(6),
          }}
        >
          <item.Icon iconColor={theme.palette.primary.main} />
        </Stack>
      )}
      <Stack sx={{ flex: 1 }}>
        <Typography variant="subtitle1">
          {item.title}
        </Typography>
        <Typography variant="body1">
          {item.description}
        </Typography>
      </Stack>
      <ChevronRight />
    </HIDClickable>
  );
};

export default SellingPropertyChecklistItem;
