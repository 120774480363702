import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getMessagesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(RouteNames.MESSAGES, { propertyId });

type GetMessagePath = { propertyId: string, id: string };
export const getMessagePath = ({ propertyId, id }: GetMessagePath) => generateRoutePath(
  RouteNames.MESSAGE,
  { propertyId, id },
);
