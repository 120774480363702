import * as R from 'ramda';

import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';

export enum HomeWidgetType {
  ACCOMMODATION_COST = 'ACCOMMODATION_COST',
  ADD_BY_OTHERS_PRODUCTS = 'ADD_BY_OTHERS_PRODUCTS',
  BONUS_OFFERS = 'BONUS_OFFERS',
  RECURRING_EXPENSE = 'RECURRING_EXPENSE',
  INTEREST_RATE = 'INTEREST_RATE',
  LOANS_RATE = 'LOANS_RATE',
  MARKET_VALUE = 'MARKET_VALUE',
  MESSAGES = 'MESSAGES',
  PROPERTY_PROGRESS = 'PROPERTY_PROGRESS',
  RECEIPTS_SUMMARY = 'RECEIPTS_SUMMARY',
  RECENTLY_ADDED_PRODUCTS = 'RECENTLY_ADDED_PRODUCTS',
  REMINDERS = 'REMINDERS',
  TASKS = 'TASKS',
  TOTAL_RECEIPTS = 'TOTAL_RECEIPTS',
  VALUATION = 'VALUATION',
  WELCOME = 'WELCOME',
  FORUM_EXPERT = 'FORUM_EXPERT',
  FORUM_LATEST_THREADS = 'FORUM_LATEST_THREADS',
}

export const useGetWidgetHeight = (homeWidgetType: HomeWidgetType): number => {
  const {
    isDownSm,
    isDownMd,
    isDownLg,
    isDownXl,
  } = useBreakPointsSizes();

  const tasksMessagesRemindersWidgetHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownMd, R.always(430)],
    [() => isDownLg, R.always(472)],
    [() => isDownXl, R.always(472)],
    [R.T, R.always(416)],
  ])();

  const recurringExpensesAndValuationWidgetHeight = isDownXl ? 0 : 426;

  const propertyProgressAndWelcomeWidgetHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [R.T, R.always(215)],
  ])();

  const productsWidgetHeight = 330;
  const accommodationAndLoansHeight = 116;

  const bonusOffersWidgetHeight = 308;

  const forumWidgetHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownXl, R.always(467)],
    [R.T, R.always(546)],
  ])();

  const widgetHeightMap: Record<HomeWidgetType, number> = {
    [HomeWidgetType.TASKS]: tasksMessagesRemindersWidgetHeight,
    [HomeWidgetType.MESSAGES]: tasksMessagesRemindersWidgetHeight,
    [HomeWidgetType.REMINDERS]: tasksMessagesRemindersWidgetHeight,
    [HomeWidgetType.RECURRING_EXPENSE]: recurringExpensesAndValuationWidgetHeight,
    [HomeWidgetType.VALUATION]: recurringExpensesAndValuationWidgetHeight,
    [HomeWidgetType.PROPERTY_PROGRESS]: propertyProgressAndWelcomeWidgetHeight,
    [HomeWidgetType.WELCOME]: propertyProgressAndWelcomeWidgetHeight,
    [HomeWidgetType.ACCOMMODATION_COST]: accommodationAndLoansHeight,
    [HomeWidgetType.INTEREST_RATE]: 0,
    [HomeWidgetType.LOANS_RATE]: accommodationAndLoansHeight,
    [HomeWidgetType.TOTAL_RECEIPTS]: 120,
    [HomeWidgetType.RECEIPTS_SUMMARY]: 470,
    [HomeWidgetType.MARKET_VALUE]: 330,
    [HomeWidgetType.RECENTLY_ADDED_PRODUCTS]: productsWidgetHeight,
    [HomeWidgetType.ADD_BY_OTHERS_PRODUCTS]: productsWidgetHeight,
    [HomeWidgetType.BONUS_OFFERS]: bonusOffersWidgetHeight,
    [HomeWidgetType.FORUM_EXPERT]: forumWidgetHeight,
    [HomeWidgetType.FORUM_LATEST_THREADS]: forumWidgetHeight,
  };

  return widgetHeightMap[homeWidgetType];
};
