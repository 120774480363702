import React, { memo } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../../../types/common';
import {
  Message,
  UserRemovalRequestMessageData,
} from '../../../types.message';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import {
  useAcceptUserRemovalRequestRequestMutation,
  useDeclineUserRemovalRequestRequestMutation,
} from '../../../../../../../api/api.property';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useReloadTokenDataMutation } from '../../../../../../../../Auth/api/api.user';
import { getInitAppPath } from '../../../../../../../../Auth/navigation/navigation.auth';

type UserRemovalRequestMessageTypeCardProps = {
  message: Message;
};

const UserRemovalRequestMessageTypeCard: FCC<UserRemovalRequestMessageTypeCardProps> = ({ message }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messageData = message.data as UserRemovalRequestMessageData;
  const request = messageData.userRemovalRequest;
  const { isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [declineRequest, { isLoading: isRequestDeclining }] = useDeclineUserRemovalRequestRequestMutation();
  const [acceptRequest, { isLoading: isRequestAccepting }] = useAcceptUserRemovalRequestRequestMutation();

  const [reloadTokenData, { isLoading: isTokenDataLoading }] = useReloadTokenDataMutation();

  const handleAccept = () => {
    if (request && propertyId) {
      acceptRequest({
        propertyId: request.propertyId,
        userRemovalRequestId: request.id,
      })
        .then(() => reloadTokenData())
        .then(() => navigate(getInitAppPath()));
    }
  };

  const handleDecline = () => {
    if (request) {
      declineRequest({
        propertyId: request.propertyId,
        userRemovalRequestId: request.id,
      })
        .then(() => navigate(getInitAppPath()));
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <HIDButton
        color="secondary"
        fullWidth={isDownSm}
        loading={isRequestDeclining}
        onClick={handleDecline}
      >
        {t('common:decline')}
      </HIDButton>
      <HIDButton
        fullWidth={isDownSm}
        loading={isRequestAccepting || isTokenDataLoading}
        onClick={handleAccept}
      >
        {t('common:accept')}
      </HIDButton>
    </Stack>
  );
};

export default memo(UserRemovalRequestMessageTypeCard, (prev, next) => prev.message.id === next.message.id);
