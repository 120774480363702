export type ShowDialogItemSource = { name: string, shownCount: number, disabled: boolean };

export enum ShowDialogItemTargetType {
  USER = 'user',
  PROPERTY = 'property',
}

export type ShowDialogItem = {
  uniqueId?: string;
  name: string;
  priority?: number,
  target: ShowDialogItemTargetType;
  maxCountToShow?: number;
  props?: object;
};
