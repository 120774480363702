import { addMonths } from 'date-fns';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import { TimeIntervalsGroupingType } from '../types/types.content';

export const getDateChartGroupingKey = (chartGroupingType: TimeIntervalsGroupingType, date: Date) =>
  chartGroupingType === TimeIntervalsGroupingType.Yearly
    ? date.getFullYear().toString()
    : formatDate(date, DateTimeFormats.MONTH_AND_YEAR) || date.getFullYear().toString();

export const getDateChartGroupingKeyByIndex = (chartGroupingType: TimeIntervalsGroupingType, date: Date, index: number) =>
  chartGroupingType === TimeIntervalsGroupingType.Yearly
    ? (date.getFullYear() + index).toString()
    : getDateChartGroupingKey(chartGroupingType, addMonths(date, index));
