export enum BonusOffersCategoryId {
  MOVE_IN_SERVICES = 'move-in-services',
  HOUSE_ID = 'house-id',
}

export enum BonusOffersQuickNavigationCategories {
  HOUSE_ID_BONUS_OFFERS = 'houseIdBonusOffers',
  MY_BONUS_POINTS = 'myBonusPoints',
  BONUS_OFFERS_LOGS = 'bonusOffersLogs',
  MOVE_IN_BONUS_OFFERS = 'moveInBonusOffers',
}

export type BonusOfferCategory = {
  id: string;
  name: string;
  iconUrl: string | null;
};

type BonusOfferTag = {
  key: string;
  label: string;
};

type BonusOfferLink = {
  label: string;
  url: string;
};

export type BonusOfferAction = {
  // TODO: type field is legacy data, delete when no longer needed
  type?: 'link' | 'activate';
  title?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
  source?: string;
  affiliate?: string;
  analyticsEvent?: string;
  url?: string;
  form?: {
    dynamicContentId: string;
  };
};

export type BonusOfferEntityPercentDiscount = { percent: number };
export type BonusOfferEntityAmountDiscount = { amount: number };

export type BonusOfferEntity = {
  id: number;
  name: string;
  description: string;
  iconUrl?: string;
  imageUrl?: string;
  points: number | null;
  discount?: BonusOfferEntityPercentDiscount | BonusOfferEntityAmountDiscount;
  categories: Array<BonusOfferCategory>;
  action?: BonusOfferAction;
  shareAction?: {
    buttonText: string;
    url: string;
    source: string;
  };
  tags: Array<BonusOfferTag>;
  links: Array<BonusOfferLink>;
};

export type BonusOffersLogEntity = {
  id: string;
  text: string;
  iconUrl: string;
  points: number | null;
  totalPoints: number | null;
  timestamp: string;
  scrollId: string;
};

export type BonusOffersLogs = {
  history: Array<BonusOffersLogEntity>;
  pageSize: number;
  totalCount: number;
  scrollId: string | null;
};
