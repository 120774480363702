import { useTranslation } from 'react-i18next';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';

type UseGetExpirationDateString = (from?: string, to?: string) => string | undefined;

const useGetExpirationDateString: UseGetExpirationDateString = (from, to) => {
  const { t } = useTranslation(['forms_common']);

  const formattedFromDate = from ? formatDate(new Date(from), DateTimeFormats.DATE_ONLY) : undefined;
  const formattedToDate = to ? formatDate(new Date(to), DateTimeFormats.DATE_ONLY) : undefined;

  if (from && to) {
    return t('forms_common:forms_common_active_from_to', { from: formattedFromDate, to: formattedToDate });
  }

  if (from) {
    return t('forms_common:forms_common_active_from', { from: formattedFromDate });
  }

  if (to) {
    return t('forms_common:forms_common_active_to', { to: formattedToDate });
  }

  return undefined;
};

export default useGetExpirationDateString;
