import * as R from 'ramda';
import {
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

import {
  isObject,
  isTypeOf,
} from './object';
import { ActionError } from '../types/common';

const DefaultErrorMessage = 'ERROR';
const DefaultErrorCode = 'UNHANDLED_ERROR';

const RequestUrl = 'request-url';
const ResponseStatus = 'response-status';
const Errors = 'errors';

export const RtkEndpoint = 'rtk-endpoint';
export const EnvoyDecoratorOperation = 'x-envoy-decorator-operation';

const getErrorWithStack = (actionId: string, status?: number, code?: string, message?: string) =>
  new Error(`${actionId ? `${actionId}.` : ''}${status ? `${status}_` : ''}${code || DefaultErrorCode}${message ? `(${message})` : ''}`);

interface RejectedActionPayload {
  status: number;
  data: ({
    errors: {
      code: string;
      message: string;
      param?: string
    }[];
  } | {
    error: string
  }) & {
    skipGlobalHandle?: boolean;
  };
}

type HIDBaseQueryMeta = { arg: { endpointName: string }; requestId: string; baseQueryMeta?: FetchBaseQueryMeta };

export type RejectedAction = PayloadAction<RejectedActionPayload, string, HIDBaseQueryMeta, SerializedError>;

const getErrorToReportAttributes = (
  statusCode: number,
  errors: Array<any>,
  meta: HIDBaseQueryMeta,
): Record<string, string> => {
  const headers = meta?.baseQueryMeta?.response?.headers as { map: Record<string, any> } | Headers;
  if (headers) {
    try {
      const attributes: Record<string, string> = {};
      if (statusCode) {
        attributes[ResponseStatus] = statusCode?.toString();
      }
      if (errors?.length) {
        attributes[Errors] = JSON.stringify(errors);
      }

      if (meta.baseQueryMeta?.request?.url) {
        attributes[RequestUrl] = meta.baseQueryMeta?.request?.url;
      }
      if (meta.arg) {
        attributes[RtkEndpoint] = meta.arg.endpointName;
      }

      const mappedHeaders = isTypeOf(headers, 'map') && !R.isEmpty(headers.map)
        ? headers.map
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        : R.fromPairs(headers.entries() as unknown as [PropertyKey, string][]);
      return { ...attributes, ...mappedHeaders };
    } catch {
      return {};
    }
  }
  return {};
};

export const getActionErrorFromRtkResponse = (
  action: RejectedAction,
): ActionError => {
  const errors = isObject(action.payload.data)
    ? action.payload.data && 'errors' in action.payload.data
      ? action?.payload?.data?.errors || []
      : [{ message: action?.payload?.data?.error, code: DefaultErrorCode }]
    : [{ message: action?.payload?.data as unknown as string, code: DefaultErrorCode }];

  const currentError = R.head(errors);

  const statusCode = action.payload.status || action.meta.baseQueryMeta?.response?.status || 500;
  const message = currentError?.message || action.meta.baseQueryMeta?.response?.statusText || DefaultErrorMessage;

  const attributes = getErrorToReportAttributes(statusCode, errors, action?.meta);

  const code = currentError?.code;

  const error = getErrorWithStack(attributes[EnvoyDecoratorOperation], statusCode, code, message);

  return {
    code,
    message,
    data: currentError ? R.omit(['code', 'message'], currentError) : undefined,
    error,
    status: Number(statusCode),
    attributes,
    errors,
    skipGlobalHandle: action.payload.data?.skipGlobalHandle,
  };
};
