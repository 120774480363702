import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const ManageConnections: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M12 8V12M12 12H6C5.44772 12 5 12.4477 5 13V16M12 12H18C18.5523 12 19 12.4477 19 13V16M11 2H13C14.1046 2 15 2.89543 15 4V6C15 7.10457 14.1046 8 13 8H11C9.89543 8 9 7.10457 9 6V4C9 2.89543 9.89543 2 11 2ZM18 16H20C21.1046 16 22 16.8954 22 18V20C22 21.1046 21.1046 22 20 22H18C16.8954 22 16 21.1046 16 20V18C16 16.8954 16.8954 16 18 16ZM4 16H6C7.10457 16 8 16.8954 8 18V20C8 21.1046 7.10457 22 6 22H4C2.89543 22 2 21.1046 2 20V18C2 16.8954 2.89543 16 4 16Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default ManageConnections;
