import React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const BankID: Icon = ({
  size,
  fillColor = '#E3ECF7',
}) => (
  <HIDSvgIcon size={size}>
    <rect
      fill={fillColor}
      height="24"
      rx="32"
      width="24"
      x="0"
      y="0"
    />
    <path d="m9.79084,12.27329l0.4934,-3.33874c-0.20184,0 -0.53827,0 -0.53827,0c-0.24668,0 -0.57192,-0.14256 -0.67282,-0.41585c-0.03365,-0.09506 -0.10094,-0.41585 0.31397,-0.72477c0.14581,-0.10693 0.24672,-0.22576 0.25795,-0.32079c0.02242,-0.09506 0,-0.17823 -0.06729,-0.24952c-0.07848,-0.08316 -0.25791,-0.1426 -0.48221,-0.1426c-0.38127,0 -0.6504,0.23766 -0.67282,0.40399c-0.02246,0.13069 0.07848,0.22576 0.15697,0.28516c0.23549,0.19009 0.29159,0.46338 0.14578,0.72477c-0.15697,0.26139 -0.48217,0.43962 -0.82983,0.43962c0,0 -0.34762,0 -0.54946,0c-0.04487,0.32082 -0.78499,5.31106 -0.84106,5.70314l2.9605,0c0.01119,-0.16632 0.14578,-1.11685 0.3252,-2.3644z" fill="#459BBE" />
    <path d="m13.74933,6.01171l-3.00534,0l-0.40372,2.69713l0.51585,0c0.28036,0 0.5495,-0.13073 0.66163,-0.33269c0.03365,-0.05943 0.05607,-0.11883 0.05607,-0.16636c0,-0.10693 -0.06729,-0.20199 -0.14578,-0.24952c-0.20184,-0.15446 -0.23549,-0.32079 -0.23549,-0.43958c0,-0.02377 0,-0.04753 0,-0.05943c0.04484,-0.28516 0.40369,-0.59408 0.88589,-0.59408c0.29159,0 0.50463,0.0713 0.63921,0.20199c0.11213,0.11883 0.1682,0.28516 0.13455,0.45152c-0.04484,0.20196 -0.23549,0.36832 -0.34762,0.45148c-0.29156,0.21386 -0.25791,0.40399 -0.23549,0.46338c0.05607,0.16632 0.29156,0.27329 0.47098,0.27329l0.78499,0c1.06532,0.01186 1.63724,0.52278 1.44662,1.7347c-0.16823,1.11685 -0.97564,1.604 -1.94006,1.61587l-0.40369,2.5783l0.56069,0c2.38858,0 4.32864,-1.62777 4.7211,-4.1823c0.48221,-3.17237 -1.42417,-4.44369 -4.16041,-4.44369z" fill="#235971" />
    <path d="m6.33691,15.79022l1.21113,0c0.51582,0 0.63918,0.27325 0.60553,0.53468c-0.03361,0.20196 -0.15697,0.35642 -0.39246,0.45148c0.29156,0.11883 0.40369,0.29706 0.35885,0.58221c-0.0561,0.35642 -0.34766,0.61784 -0.72893,0.61784l-1.37933,0l0.3252,-2.18621zm0.79622,0.91486c0.23549,0 0.34762,-0.13069 0.37004,-0.28516c0.02242,-0.16632 -0.04484,-0.28516 -0.28033,-0.28516l-0.21307,0l-0.08971,0.57031l0.21307,0zm-0.13459,0.95053c0.24672,0 0.38127,-0.10693 0.41491,-0.32079c0.02242,-0.17823 -0.06729,-0.29706 -0.30278,-0.29706l-0.23549,0l-0.08971,0.61784l0.21307,0z" fill="#235971" />
    <path d="m9.80207,18.00016c-0.31401,0.02377 -0.47102,-0.01186 -0.53827,-0.15446c-0.16823,0.10697 -0.34766,0.16636 -0.5495,0.16636c-0.35885,0 -0.48221,-0.19009 -0.44856,-0.41585c0.01123,-0.10693 0.06729,-0.20199 0.1682,-0.28516c0.19065,-0.17823 0.68405,-0.20199 0.8747,-0.34459c0.01123,-0.15446 -0.04484,-0.21386 -0.22426,-0.21386c-0.21307,0 -0.3813,0.0713 -0.68409,0.28516l0.06729,-0.49901c0.25795,-0.20199 0.50463,-0.28516 0.79622,-0.28516c0.37004,0 0.69524,0.15446 0.62798,0.58217l-0.06729,0.48715c-0.02242,0.16636 -0.02242,0.22576 0.15701,0.22576l-0.17942,0.45148zm-0.53827,-0.74854c-0.16823,0.10697 -0.48221,0.09506 -0.51585,0.32082c-0.01123,0.10693 0.04484,0.19009 0.14578,0.19009c0.10094,0 0.2243,-0.04753 0.31401,-0.11879c-0.01123,-0.03567 0,-0.0832 0.01119,-0.15446l0.04487,-0.23766z" fill="#235971" />
    <path d="m10.39637,16.28923l0.62798,0l-0.03365,0.22576c0.20184,-0.17823 0.34762,-0.24952 0.54946,-0.24952c0.34766,0 0.51585,0.22576 0.45979,0.60598l-0.1682,1.11685l-0.62798,0l0.13455,-0.92676c0.02242,-0.16632 -0.02242,-0.24952 -0.14578,-0.24952c-0.10094,0 -0.19065,0.05943 -0.28036,0.17823l-0.14578,0.9862l-0.62798,0l0.25795,-1.6872z" fill="#235971" />
    <path d="m12.4934,15.79022l0.62798,0l-0.15701,1.08122l0.60557,-0.58221l0.77376,0l-0.77376,0.72477l0.61679,0.9743l-0.79622,0l-0.48221,-0.78417l-0.01119,0l-0.11217,0.78417l-0.62798,0l0.33643,-2.19808z" fill="#235971" />
    <path d="m6.33691,15.79022l1.21113,0c0.51582,0 0.63918,0.27325 0.60553,0.53468c-0.03361,0.20196 -0.15697,0.35642 -0.39246,0.45148c0.29156,0.11883 0.40369,0.29706 0.35885,0.58221c-0.0561,0.35642 -0.34766,0.61784 -0.72893,0.61784l-1.37933,0l0.3252,-2.18621zm0.79622,0.91486c0.23549,0 0.34762,-0.13069 0.37004,-0.28516c0.02242,-0.16632 -0.04484,-0.28516 -0.28033,-0.28516l-0.21307,0l-0.08971,0.57031l0.21307,0zm-0.13459,0.95053c0.24672,0 0.38127,-0.10693 0.41491,-0.32079c0.02242,-0.17823 -0.06729,-0.29706 -0.30278,-0.29706l-0.23549,0l-0.08971,0.61784l0.21307,0z" fill="#235971" />
    <path d="m9.80207,18.00016c-0.31401,0.02377 -0.47102,-0.01186 -0.53827,-0.15446c-0.16823,0.10697 -0.34766,0.16636 -0.5495,0.16636c-0.35885,0 -0.48221,-0.19009 -0.44856,-0.41585c0.01123,-0.10693 0.06729,-0.20199 0.1682,-0.28516c0.19065,-0.17823 0.68405,-0.20199 0.8747,-0.34459c0.01123,-0.15446 -0.04484,-0.21386 -0.22426,-0.21386c-0.21307,0 -0.3813,0.0713 -0.68409,0.28516l0.06729,-0.49901c0.25795,-0.20199 0.50463,-0.28516 0.79622,-0.28516c0.37004,0 0.69524,0.15446 0.62798,0.58217l-0.06729,0.48715c-0.02242,0.16636 -0.02242,0.22576 0.15701,0.22576l-0.17942,0.45148zm-0.53827,-0.74854c-0.16823,0.10697 -0.48221,0.09506 -0.51585,0.32082c-0.01123,0.10693 0.04484,0.19009 0.14578,0.19009c0.10094,0 0.2243,-0.04753 0.31401,-0.11879c-0.01123,-0.03567 0,-0.0832 0.01119,-0.15446l0.04487,-0.23766z" fill="#235971" />
    <path d="m10.39637,16.28923l0.62798,0l-0.03365,0.22576c0.20184,-0.17823 0.34762,-0.24952 0.54946,-0.24952c0.34766,0 0.51585,0.22576 0.45979,0.60598l-0.1682,1.11685l-0.62798,0l0.13455,-0.92676c0.02242,-0.16632 -0.02242,-0.24952 -0.14578,-0.24952c-0.10094,0 -0.19065,0.05943 -0.28036,0.17823l-0.14578,0.9862l-0.62798,0l0.25795,-1.6872z" fill="#235971" />
    <path d="m12.4934,15.79022l0.62798,0l-0.15701,1.08122l0.60557,-0.58221l0.77376,0l-0.77376,0.72477l0.61679,0.9743l-0.79622,0l-0.48221,-0.78417l-0.01119,0l-0.11217,0.78417l-0.62798,0l0.33643,-2.19808z" fill="#235971" />
    <path d="m14.71382,15.79022l0.72893,0l-0.32524,2.18621l-0.72889,0l0.3252,-2.18621z" fill="#459BBE" />
    <path d="m15.79033,15.79022l1.03167,0c0.79622,0 1.03171,0.61784 0.95322,1.12876c-0.06729,0.49901 -0.44856,1.06932 -1.14384,1.06932l-1.15507,0l0.31401,-2.19808zm0.67282,1.6753c0.34766,0 0.5495,-0.17823 0.60557,-0.57031c0.04487,-0.28516 -0.04484,-0.57031 -0.42614,-0.57031l-0.20184,0l-0.1682,1.15248l0.19061,-0.01186z" fill="#459BBE" />
  </HIDSvgIcon>
);

export default BankID;
