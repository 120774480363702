/* eslint-disable max-len */
import {
  lazy,
} from 'react';

import DialogNames from './DialogNames';

const AddContentDialog = lazy(() => import('../../modules/Property/modules/Content/components/dialogs/AddContentDialog'));
const AddPasswordAuthToAccountDialog = lazy(() => import('../../modules/Auth/components/AddPasswordAuthToAccountDialog'));
const ApplyPromoCodeDialog = lazy(() => import('../../modules/SubscriptionPlans/components/ApplyPromoCodeDialog'));
const AskForumQuestionDialog = lazy(() => import('../../modules/Forum/components/AskForumQuestionDialog'));
const BonusOfferActivatedDialog = lazy(() => import('../../modules/Property/modules/BonusOffers/pages/BonusOfferDetails/components/BonusOfferActivatedDialog'));
const CancelSubscriptionDialog = lazy(() => import('../../modules/SubscriptionPlans/components/CancelSubscriptionDialog'));
const CancelSubscriptionQuestionnaireDialog = lazy(() => import('../../modules/SubscriptionPlans/components/CancelSubscriptionQuestionnaireDialog'));
const ChangeInboundEmailDialog = lazy(() => import('../../modules/Property/components/dialogs/ChangeInboundEmailDialog'));
const ChangePropertyDisplayNameDialog = lazy(() => import('../../modules/Property/components/dialogs/ChangePropertyDisplayNameDialog'));
const ConnectNewDeviceDialog = lazy(() => import('../../modules/Property/components/dialogs/ConnectNewDeviceDialog'));
const ContentFilePickerDialog = lazy(() => import('../../modules/Property/modules/Content/modules/ContentFile/component/ContentFilePickerDialog'));
const CreateBudgetFromExistingDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/Budgets/components/dialogs/CreateBudgetFromExistingDialog'));
const CreateSurveyDialog = lazy(() => import('../../modules/Admin/modules/Survey/pages/AdminSurveys/components/CreateSurveyDialog'));
const DeleteAccountDialog = lazy(() => import('../../modules/Auth/components/DeleteAccountDialog'));
const DeletePropertyDialog = lazy(() => import('../../modules/Property/components/dialogs/DeletePropertyDialog'));
const DeleteRecurringExpensesBankAccountDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/DeleteRecurringExpensesBankAccountDialog'));
const DeleteRecurringExpensesBankDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/DeleteRecurringExpensesBankDialog'));
const EndTrialPeriodDialog = lazy(() => import('../../modules/Property/pages/Home/components/EndTrialPeriodDialog'));
const EnrollSecondFactorDialog = lazy(() => import('../../modules/Auth/components/EnrollSecondFactorDialog'));
const EnterInviteCodeDialog = lazy(() => import('../../modules/Property/components/dialogs/EnterInviteCodeDialog'));
const ExpertsCommunicationFormDialog = lazy(() => import('../../modules/Property/modules/Content/modules/Message/components/ExpertsCommunicationFormDialog'));
const ExternalServiceIntroDialog = lazy(() => import('../../modules/Property/components/dialogs/ExternalServiceIntroDialog'));
const InfoDialog = lazy(() => import('../../modules/Property/components/dialogs/InfoDialog'));
const InviteUserDialog = lazy(() => import('../../modules/Property/components/dialogs/InviteUserDialog'));
const LeavePropertyDialog = lazy(() => import('../../modules/Property/components/dialogs/LeavePropertyDialog'));
const ManageContentConnectionsDialog = lazy(() => import('../../modules/Property/modules/Content/components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog'));
const ManagePropertySellingStageConditionsDialog = lazy(() => import('../../modules/Property/components/dialogs/ManagePropertySellingStageConditionsDialog'));
const ManagePropertySoldStageConditionsDialog = lazy(() => import('../../modules/Property/components/dialogs/ManagePropertySoldStageConditionsDialog'));
const ManageRecurringExpenseBankAccountConsentsDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/manageRecurringExpenseBankAccountConsentsDialog/ManageRecurringExpenseBankAccountConsentsDialog'));
const ManageRecurringExpensesCandidatesDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/ManageRecurringExpensesCandidatesDialog'));
const MddPaymentsIntroDialog = lazy(() => import('../../modules/SubscriptionPlans/components/MddPaymentsIntroDialog'));
const MyTotalBonusPointsDialog = lazy(() => import('../../modules/Property/modules/BonusOffers/pages/MyTotalBonusPoints/components/MyTotalBonusPointsDialog'));
const NoInvitedMembersDialog = lazy(() => import('../../modules/SubscriptionPlans/components/NoInvitedMembersDialog'));
const OpenBankIdDialog = lazy(() => import('../../modules/Auth/components/OpenBankIdDialog/OpenBankIdDialog'));
const OpenMobileAppDialog = lazy(() => import('../../modules/Property/components/dialogs/OpenMobileAppDialog'));
const BudgetManageCategoriesDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/Budgets/pages/CreateUpdateBudget/components/dialogs/BudgetManageCategoriesDialog'));
const ProductExternalMediaInfoDialog = lazy(() => import('../../modules/Property/modules/Content/modules/Product/components/modals/ProductExternalMediaInfoDialog'));
const ProductTypeGuideDialog = lazy(() => import('../../modules/Property/modules/Content/modules/Product/components/modals/ProductTypeGuideDialog'));
const PromptDialog = lazy(() => import('../../components/dialogs/PromptDialog'));
const PromptLinkDialog = lazy(() => import('../../modules/Forum/pages/forumThread/components/PromptLinkDialog'));
const RateActionDialog = lazy(() => import('../../components/dialogs/RateActionDialog'));
const RecurringExpensesCollectionCompletedDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/RecurringExpensesCollectionCompletedDialog'));
const RewardOfferDialog = lazy(() => import('../../modules/SubscriptionPlans/components/RewardOfferDialog'));
const ScanReceiptDialog = lazy(() => import('../../modules/Property/modules/Content/modules/ContentFile/component/ScanReceiptDialog'));
const SearchProductsDialog = lazy(() => import('../../modules/Property/modules/Content/modules/Product/pages/SearchProducts/SearchProductDialog'));
const SelectRecurringExpensesBankAccountsDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/SelectRecurringExpensesBankAccountsDialog'));
const SelectRecurringExpensesDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/selectRecurringExpensesDialog/SelectRecurringExpensesDialog'));
const SellingPropertyChecklistInfoDialog = lazy(() => import('../../modules/Property/modules/SellingProperty/pages/SellingPropertyChecklist/components/SellingPropertyChecklistInfoDialog'));
const SellingPropertyProgressDialog = lazy(() => import('../../modules/Property/modules/SellingProperty/components/SellingPropertyProgressDialog'));
const SetPropertyStageDialog = lazy(() => import('../../modules/Property/components/dialogs/SetPropertyStageDialog'));
const StartRecurringExpensesCollectionDialog = lazy(() => import('../../modules/Property/modules/Finances/modules/RecurringExpenses/components/dialogs/StartRecurringExpensesCollectionDialog'));
const StartTrialPeriodDialog = lazy(() => import('../../modules/Property/pages/PropertyHome/components/StartTrialPeriodDialog'));
const SubscriptionsSharingDialog = lazy(() => import('../../modules/SubscriptionPlans/components/SubscriptionsSharingDialog'));
const TryFeatureDialog = lazy(() => import('../../modules/SubscriptionPlans/components/TryUseFeatureDialog'));
const TutorialDialog = lazy(() => import('../../modules/Property/components/dialogs/tutorialDialog/TutorialDialog'));
const WaitBankIdCompletedDialog = lazy(() => import('../../modules/Auth/components/OpenBankIdDialog/WaitBankIdCompletedDialog'));
const WelcomeDialog = lazy(() => import('../../modules/Property/pages/PropertyHome/components/WelcomeDialog'));
const WelcomeToForumDialog = lazy(() => import('../../modules/Forum/components/WelcomeToForumDialog'));
const YesNoCancelDialog = lazy(() => import('../../components/dialogs/YesNoCancelDialog'));

const DialogComponents = {
  [DialogNames.ADD_CONTENT_DIALOG]: AddContentDialog,
  [DialogNames.ADD_PASSWORD_AUTH_TO_ACCOUNT_DIALOG]: AddPasswordAuthToAccountDialog,
  [DialogNames.APPLY_PROMO_CODE_DIALOG]: ApplyPromoCodeDialog,
  [DialogNames.ASK_FORUM_QUESTION_DIALOG]: AskForumQuestionDialog,
  [DialogNames.BONUS_OFFER_ACTIVATED_DIALOG]: BonusOfferActivatedDialog,
  [DialogNames.CANCEL_SUBSCRIPTION_DIALOG]: CancelSubscriptionDialog,
  [DialogNames.CANCEL_SUBSCRIPTION_QUESTIONNAIRE_DIALOG]: CancelSubscriptionQuestionnaireDialog,
  [DialogNames.CHANGE_INBOUND_EMAIL_NAME_DIALOG]: ChangeInboundEmailDialog,
  [DialogNames.CHANGE_PROPERTY_DISPLAY_NAME]: ChangePropertyDisplayNameDialog,
  [DialogNames.CONNECT_NEW_DEVICE_DIALOG]: ConnectNewDeviceDialog,
  [DialogNames.CONTENT_FILE_PICKER_DIALOG]: ContentFilePickerDialog,
  [DialogNames.CREATE_BUDGET_FROM_EXISTING_DIALOG]: CreateBudgetFromExistingDialog,
  [DialogNames.CREATE_SURVEY_DIALOG]: CreateSurveyDialog,
  [DialogNames.DELETE_ACCOUNT_DIALOG]: DeleteAccountDialog,
  [DialogNames.DELETE_PROPERTY_DIALOG]: DeletePropertyDialog,
  [DialogNames.DELETE_RECURRING_EXPENSES_BANK_DIALOG]: DeleteRecurringExpensesBankDialog,
  [DialogNames.DELETE_RECURRING_EXPENSES_BANK_ACCOUNT_DIALOG]: DeleteRecurringExpensesBankAccountDialog,
  [DialogNames.END_TRIAL_PERIOD_DIALOG]: EndTrialPeriodDialog,
  [DialogNames.ENROLL_SECOND_FACTOR_DIALOG]: EnrollSecondFactorDialog,
  [DialogNames.ENTER_INVITE_CODE_DIALOG]: EnterInviteCodeDialog,
  [DialogNames.EXTERNAL_SERVICE_INTRO_DIALOG]: ExternalServiceIntroDialog,
  [DialogNames.EXPERTS_COMMUNICATION_FORM_DIALOG]: ExpertsCommunicationFormDialog,
  [DialogNames.INFO_DIALOG]: InfoDialog,
  [DialogNames.INVITE_USER_DIALOG]: InviteUserDialog,
  [DialogNames.LEAVE_PROPERTY_DIALOg]: LeavePropertyDialog,
  [DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG]: ManageContentConnectionsDialog,
  [DialogNames.MANAGE_RECURRING_EXPENSE_BANK_ACCOUNT_CONSENTS_DIALOG]: ManageRecurringExpenseBankAccountConsentsDialog,
  [DialogNames.MANAGE_PROPERTY_SELLING_STAGE_CONDITIONS_DIALOG]: ManagePropertySellingStageConditionsDialog,
  [DialogNames.MANAGE_RECURRING_EXPENSES_CANDIDATES_DIALOG]: ManageRecurringExpensesCandidatesDialog,
  [DialogNames.MANAGE_PROPERTY_SOLD_STAGE_CONDITIONS_DIALOG]: ManagePropertySoldStageConditionsDialog,
  [DialogNames.MDD_PAYMENTS_INTRO_DIALOG]: MddPaymentsIntroDialog,
  [DialogNames.MY_TOTAL_BONUS_POINTS_DIALOG]: MyTotalBonusPointsDialog,
  [DialogNames.NO_INVITED_MEMBERS_DIALOG]: NoInvitedMembersDialog,
  [DialogNames.OPEN_BANK_ID_DIALOG]: OpenBankIdDialog,
  [DialogNames.OPEN_MOBILE_APP_DIALOG]: OpenMobileAppDialog,
  [DialogNames.PRODUCT_EXTERNAL_MEDIA_INFO_DIALOG]: ProductExternalMediaInfoDialog,
  [DialogNames.PRODUCT_TYPE_GUIDE_DIALOG]: ProductTypeGuideDialog,
  [DialogNames.PROMPT_DIALOG]: PromptDialog,
  [DialogNames.BUDGET_MANAGE_CATEGORIES_DIALOG]: BudgetManageCategoriesDialog,
  [DialogNames.PROMPT_LINK_DIALOG]: PromptLinkDialog,
  [DialogNames.RATE_ACTION_DIALOG]: RateActionDialog,
  [DialogNames.REWARD_OFFER_DIALOG]: RewardOfferDialog,
  [DialogNames.SELLING_PROPERTY_PROGRESS_DIALOG]: SellingPropertyProgressDialog,
  [DialogNames.SELLING_PROPERTY_CHECKLIST_INFO_DIALOG]: SellingPropertyChecklistInfoDialog,
  [DialogNames.SET_PROPERTY_STAGE_DIALOG]: SetPropertyStageDialog,
  [DialogNames.SCAN_RECEIPT_DIALOG]: ScanReceiptDialog,
  [DialogNames.SEARCH_PRODUCTS_DIALOG]: SearchProductsDialog,
  [DialogNames.START_TRIAL_PERIOD_DIALOG]: StartTrialPeriodDialog,
  [DialogNames.START_RECURRING_EXPENSES_COLLECTION_DIALOG]: StartRecurringExpensesCollectionDialog,
  [DialogNames.SELECT_RECURRING_EXPENSES_BANK_ACCOUNTS_DIALOG]: SelectRecurringExpensesBankAccountsDialog,
  [DialogNames.SELECT_RECURRING_EXPENSES_DIALOG]: SelectRecurringExpensesDialog,
  [DialogNames.RECURRING_EXPENSES_COLLECTION_COMPLETED_DIALOG]: RecurringExpensesCollectionCompletedDialog,
  [DialogNames.SUBSCRIPTIONS_SHARING_DIALOG]: SubscriptionsSharingDialog,
  [DialogNames.TRY_FEATURE_DIALOG]: TryFeatureDialog,
  [DialogNames.TUTORIAL_DIALOG]: TutorialDialog,
  [DialogNames.WAIT_BANK_ID_COMPLETED_DIALOG]: WaitBankIdCompletedDialog,
  [DialogNames.WELCOME_DIALOG]: WelcomeDialog,
  [DialogNames.WELCOME_TO_FORUM_DIALOG]: WelcomeToForumDialog,
  [DialogNames.YES_NO_CANCEL_DIALOG]: YesNoCancelDialog,
};

export default DialogComponents;

export type DialogComponentsType = typeof DialogComponents;
