import * as React from 'react';

import { Icon } from '../../types/common';
import { ICON_SIZE } from '../../constants/layout';

const Prisjakt: Icon = ({ size = ICON_SIZE }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.99 0C18.619-.005 23.996 5.363 24 11.99c.005 6.628-5.363 12.005-11.99 12.01C5.38 24.005.004 18.637 0 12.01V12C-.003 5.375 5.366.003 11.99 0z"
      fill="#FF3366"
    />
    <path d="M11.987 5.658a6.341 6.341 0 11-6.338 6.346V12a6.34 6.34 0 016.338-6.342z" fill="#00ADDB" />
    <path
      d="M21.01 12a8.989 8.989 0 00-1.669-5.232l1.185-1.186a10.732 10.732 0 00-2.128-2.13L17.21 4.638a9.018 9.018 0 00-10.434-.005L5.59 3.446a10.726 10.726 0 00-2.13 2.129L4.644 6.76a9.036 9.036 0 00-.01 10.464l-1.18 1.181a10.75 10.75 0 002.128 2.13l1.18-1.18a9.017 9.017 0 0010.463-.005l1.18 1.18a10.73 10.73 0 002.126-2.133l-1.18-1.181A8.992 8.992 0 0021.01 12zM5.654 12a6.337 6.337 0 1112.674.01A6.337 6.337 0 015.653 12"
      fill="#FFD065"
    />
    <path d="M11.987 8.99a3.01 3.01 0 11-3.007 3.014V12a3.008 3.008 0 013.007-3.01z" fill="#FFD065" />
  </svg>
);

export default Prisjakt;
