import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';

import {
  PropertyBlueprintsSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { DRAWINGS_CLASSIFICATION } from '../../../../Content/modules/Document/constants.document';
import { getDocumentCategoryPath } from '../../../../Content/modules/Document/navigation.document';
import PropertyDocumentsSection from '../../../components/PropertyDocumentsSection';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const PropertyBlueprintsDocumentsSection: FC<PropertyProgressCategorySectionProps<PropertyBlueprintsSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { t } = useTranslation(['property']);

  const handleNavigateToDocuments = () =>
    navigate(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId: DRAWINGS_CLASSIFICATION }));

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section.progress),
    categoryId: PropertyProgressCategorySection.BLUEPRINTS,
    sectionId: section.id,
  });

  return (
    <PropertyDocumentsSection
      description={description}
      documentsListTitle={t('property:property_progress_property_blueprints_blueprints')}
      infoDescription={t('property:property_progress_property_blueprints_blueprints_info')}
      infoTitle={t('property:property_progress_property_blueprints_blueprints_read_more_about_blueprints')}
      recommendedTemplatesTitle={t('property:property_progress_property_documentation_we_recommend_adding')}
      requiredTemplatesTitle={t('property:property_progress_property_blueprints_drawings_to_add')}
      section={section}
      viewAllDocumentsTitle={t('property:property_progress_property_blueprints_view_all_drawings')}
      onNavigateToDocuments={handleNavigateToDocuments}
    />
  );
};

export default PropertyBlueprintsDocumentsSection;
