import { differenceInDays } from 'date-fns';
import i18n from 'i18next';

import { PREVIOUSLY_OWNED_PROPERTY_AVAILABLE_DAYS } from '../constants/constants.property';
import { Property } from '@house-id/houseid-types/dist/property';

export const getPropertyDisplayName = (
  property: Property,
  hasMultipleUnverifiedProperties: boolean,
  unverifiedPropertyIndex?: number,
) => {
  if (property.alias) {
    return property.alias;
  }
  return property.isVerified || property.address?.streetAddress
    ? property.address?.streetAddress
    : hasMultipleUnverifiedProperties && unverifiedPropertyIndex
      ? `${i18n.t('property:unverified_property')} - ${unverifiedPropertyIndex}`
      : i18n.t('property:unverified_property');
};

export const getNumberOfDaysLeftWithAccess = (property: Property) => {
  const now = new Date();
  const deactivatedAt = property.deactivatedAt ? new Date(property.deactivatedAt) : undefined;
  const diffInDays = deactivatedAt && now > deactivatedAt
    ? PREVIOUSLY_OWNED_PROPERTY_AVAILABLE_DAYS - differenceInDays(now, deactivatedAt)
    : 0;

  return diffInDays;
};

export const hasAccessToProperty = (property: Property, isBaseOrPlusSubscription: boolean | undefined) =>
  !property?.readOnly || isBaseOrPlusSubscription || getNumberOfDaysLeftWithAccess(property) > 0;
