import React from 'react';
import { useTheme } from '@mui/material';
import * as R from 'ramda';

import HIDImage from '../../../../../../../components/image/HIDImage';
import { FCC } from '../../../../../../../types/common';
import { Photo } from '../types.photo';
import VerticalImagePlaceholder from '../../../../../../../components/svg/VerticalImagePlaceholder';

type PhotoImageProps = {
  photo: Photo;
  onClick?: () => void;
};

const PhotoImage: FCC<PhotoImageProps> = ({
  photo,
  onClick,
  sx,
}) => {
  const theme = useTheme();

  return (
    <HIDImage
      SkeletonIcon={VerticalImagePlaceholder}
      showSkeleton={Boolean(photo.createdAt)}
      skeletonSx={{
        width: '100%',
        height: 'unset',
        aspectRatio: 3 / 4,
        color: theme.palette.shadow[100],
        borderRadius: 'inherit',
      }}
      sx={{
        width: '100%',
        height: 'unset',
        objectFit: 'cover',
        aspectRatio: 3 / 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
        borderRadius: theme.spacing(0.5),
        ...sx,
      }}
      url={R.head(photo.blobs)?.thumbnailUrl}
      onClick={onClick}
    />
  );
};

export default PhotoImage;
