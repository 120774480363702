import { userApi } from '../../Auth/api/api.user';

type ReportEventValues = {
  type: string;
  source: string;
  hidCategory: string;
  propertyId?: string;
  data?: object;
} & object;

export const analyticsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    reportEvent: builder.mutation<void, ReportEventValues>({
      query: (data: ReportEventValues) => ({
        url: '/statistics/event',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useReportEventMutation,
} = analyticsApi;
