import React, {
  forwardRef,
} from 'react';
import {
  MuiTelInput,
  MuiTelInputInfo,
  matchIsValidTel,
} from 'mui-tel-input';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  FCC,
  FCCProps,
} from '../types/common';
import {
  HIDTextFieldProps,
  useGetTextFieldProps,
} from './HIDTextField';
import {
  isLocal,
  isTest,
} from '../utils/env';

export type PhoneInputInfo = {
  value?: string;
  numberValue?: string;
  nationalNumber?: string;
};

type HIDPhoneFiledProps = Omit<HIDTextFieldProps, 'value' | 'onChange'> & {
  value?: PhoneInputInfo;
  onChange: (info: PhoneInputInfo) => void;
};

type HIDPhoneFieldPropsWithRef = FCCProps<HIDPhoneFiledProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDPhoneField: FCC<HIDPhoneFiledProps> = forwardRef<HTMLInputElement, HIDPhoneFieldPropsWithRef>((
  {
    id,
    label,
    placeholder,
    variant = 'standard',
    inputProps,
    value,
    fullWidth = true,
    required,
    error,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    InputProps,
    InputLabelProps,
    valid,
    disabled,
    showValidationIcon = false,
    sx,
    endAdornment,
    onChange,
    onBlur,
    onKeyDown,
  },
  ref,
) => {
  const textFieldProps = useGetTextFieldProps({
    endAdornment,
    error,
    helperTextWrap,
    InputLabelProps,
    InputProps,
    showValidationIcon,
    valid,
    value: value?.value,
  });

  return (
    <MuiTelInput
      {...textFieldProps}
      forceCallingCode
      defaultCountry="SE"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText || (showHelperText ? ' ' : undefined)}
      id={id}
      inputProps={inputProps}
      inputRef={ref}
      label={label}
      onlyCountries={isLocal || isTest ? ['SE', 'GB', 'UA'] : ['SE', 'GB']}
      placeholder={placeholder}
      required={required}
      sx={sx}
      value={value?.value}
      variant={variant}
      onBlur={onBlur}
      onChange={(value: string, info: MuiTelInputInfo) => {
        onChange({
          value,
          numberValue: info.numberValue || undefined,
          nationalNumber: info.nationalNumber || undefined,
        });
      }}
      onKeyDown={onKeyDown}
    />
  );
});

HIDPhoneField.displayName = 'HIDPhoneField';

export const useGetPhoneFieldValidationSchema = () => {
  const { t } = useTranslation(['auth_error_messages']);

  const validationSchema = Yup.object<PhoneInputInfo>({
    value: Yup.string()
      .test(
        'phone',
        t('auth_error_messages:auth/invalid-phone-number'),
        (value, context) => !value || !(context.parent as PhoneInputInfo).nationalNumber || matchIsValidTel(value),
      )
      .optional()
      .nullable(),
    numberValue: Yup.string().optional(),
    nationalNumber: Yup.string().optional(),
  });

  return validationSchema;
};

export default HIDPhoneField;
