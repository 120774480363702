import React, { FC } from 'react';
import {
  IconProps,
  useTheme,
  Icon,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

type HIDMuiIconProps = IconProps & {
  Icon: SvgIconComponent;
  isDisabled?: boolean;
};

const HIDMuiIcon: FC<HIDMuiIconProps> = ({
  isDisabled,
  color,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Icon sx={{ color: isDisabled ? theme.palette.grey[500] : color, ...sx }} {...rest} />
  );
};

export default HIDMuiIcon;
