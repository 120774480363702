import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  SnackbarProvider,
  SnackbarContent,
  CustomContentProps,
  VariantType,
  closeSnackbar,
} from 'notistack';
import {
  CancelRounded,
  CheckCircleRounded,
  Close,
  ErrorRounded,
  InfoRounded,
} from '@mui/icons-material';

import { FCC } from '../../types/common';
import { HIDZIndex } from '../../constants/layout';
import HIDIconButton from '../buttons/HIDIconButton';

export const AUTO_HIDE_DURATION = 5000;

const useGetSnackColorAndIcon = (variant: VariantType) => {
  const theme = useTheme();

  const SnackColors: Record<VariantType, { background: string, Icon: React.ReactElement }> = {
    error: {
      background: theme.palette.error.lightest,
      Icon: <CancelRounded color="error" />,
    },
    success: {
      background: theme.palette.primary.lightest,
      Icon: <CheckCircleRounded color="primary" />,
    },
    warning: {
      background: theme.palette.warning.lightest,
      Icon: <ErrorRounded color="warning" />,
    },
    info: {
      background: theme.palette.info.lightest,
      Icon: <InfoRounded color="info" />,
    },
  };

  return SnackColors[variant];
};

type HIDErrorAlert = CustomContentProps & {
  description?: string | React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDAlert = React.forwardRef<HTMLDivElement, HIDErrorAlert>((props, ref) => {
  const theme = useTheme();

  const {
    id,
    description,
    message,
    variant,
    style,
    ...rest
  } = props;

  const {
    background,
    Icon,
  } = useGetSnackColorAndIcon(variant);

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{
        ...style,
        padding: theme.spacing(2),
        backgroundColor: background,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
        zIndex: HIDZIndex.ALERT,
        maxWidth: 300,
        width: 300,
      }}
      {...rest}
    >
      <Stack spacing={0.5}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
          >
            {Icon}
            <Typography
              sx={{ lineHeight: '16px' }}
              variant="subtitle1"
            >
              {message}
            </Typography>
          </Stack>
          <HIDIconButton
            Icon={Close}
            color="blank"
            style={{
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(-1),
            }}
            onClick={() => closeSnackbar(id)}
          />
        </Stack>
        {Boolean(description) && (
          <Typography variant="body1">
            {description}
          </Typography>
        )}
      </Stack>
    </SnackbarContent>
  );
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
HIDAlert.displayName = 'HIDAlert';

const HIDSnackBarProvider: FCC = ({
  children,
}) => (
  <SnackbarProvider
    Components={{
      error: HIDAlert as React.JSXElementConstructor<any>,
      success: HIDAlert as React.JSXElementConstructor<any>,
      warning: HIDAlert as React.JSXElementConstructor<any>,
      info: HIDAlert as React.JSXElementConstructor<any>,
    }}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    maxSnack={5}
  >
    {children}
  </SnackbarProvider>
);

export default HIDSnackBarProvider;
