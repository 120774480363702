import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import VarudeklareradPropertyInsuranceCard from './VarudeklareradPropertyInsuranceCard';
import PropertyDocumentsList from '../../../../../PropertyProgress/components/PropertyDocumentsList';

import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../../../utils/date';
import { HIDDocumentWithType } from '../../../DynamicContent/types/types.dynamicContent';
import { CustomInsuranceType } from '../../types.insurance';
import PropertyExternalDocumentList from '../../../../../PropertyProgress/components/PropertyExternalDocumentList';
import { PropertyLink } from '../../../../../PropertyProgress/types.propertyProgress';

type VarudeklareradPropertyInsuranceProps = {
  externalDocuments?: Array<PropertyLink>;
  documents?: Array<HIDDocumentWithType>;
  insuranceType: CustomInsuranceType.CONDOMINIUM | CustomInsuranceType.HOUSE;
  expirationDate?: string;
};

const VarudeklareradPropertyInsurance: FC<VarudeklareradPropertyInsuranceProps> = ({
  externalDocuments,
  documents,
  insuranceType,
  expirationDate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances', 'entities', 'common']);

  const expiration = expirationDate
    ? t('insurances:insurance_active_till', { date: formatDate(new Date(expirationDate), DateTimeFormats.DATE_ONLY) })
    : undefined;

  return (
    <Stack spacing={2}>
      <VarudeklareradPropertyInsuranceCard insuranceType={insuranceType} />
      {Boolean(expiration) && (
        <>
          <Typography variant="subtitle1">
            {t('insurances:insurance_free')}
          </Typography>
          <Stack flexDirection="row">
            <Typography color="grey" variant="body1">
              {expiration}
            </Typography>
          </Stack>
        </>
      )}
      <PropertyExternalDocumentList
        externalDocuments={externalDocuments}
        title={t('common:links')}
      />
      <PropertyDocumentsList
        propertyDocuments={documents}
        title={t('entities:document_plural')}
      />
      <Typography color={theme.palette.grey[500]} variant="body2">
        {t('insurances:insurance_in_collaboration_with_svensk_and_anticimex')}
      </Typography>
    </Stack>
  );
};

export default VarudeklareradPropertyInsurance;
