import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { ComparisonListItem } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';

const useGetComparisonColumns = (props?: { showDate: boolean }) => {
  const showDate = props?.showDate !== false;

  const theme = useTheme();
  const { t } = useTranslation(['common', 'finances']);
  const { isDownSm } = useBreakPointsSizes();

  const comparisonColumns: Array<GridColDef> = [
    {
      field: 'id',
      headerName: t('common:name'),
      flex: showDate ? 0.25 : 0.33,
      type: 'string',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { label, isTitleRow } = params.row as ComparisonListItem;
        return (
          <HIDTypography noWrap color={isTitleRow ? theme.palette.primary.light : undefined} variant="subtitle2">
            {label}
          </HIDTypography>
        );
      },
    },
    !isDownSm && showDate && ({
      field: 'lastTransactionDate',
      headerName: t('finances:last_paid'),
      flex: 0.25,
      type: 'string',
      sortable: false,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const { lastDate } = params?.row as ComparisonListItem;
        return lastDate;
      },
    }),
    !isDownSm && ({
      field: 'lastTransactionAmount',
      headerName: t('finances:last_amount'),
      flex: showDate ? 0.25 : 0.33,
      type: 'string',
      sortable: false,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const { lastValue } = params?.row as ComparisonListItem;
        return lastValue ? formatMoney(lastValue, true) : '';
      },
    }),
    {
      field: 'totalAmount',
      headerName: t('finances:total_amount'),
      flex: showDate ? 0.25 : 0.33,
      type: 'string',
      sortable: false,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const { value, isTitleRow } = params?.row as ComparisonListItem;
        return (
          <HIDTypography color={isTitleRow ? theme.palette.primary.light : undefined} variant="subtitle1">
            {(formatMoney(value, false))}
          </HIDTypography>
        );
      },
    },
  ].filter(Boolean);

  return comparisonColumns;
};

export default useGetComparisonColumns;
