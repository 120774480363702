import React, {
  FC,
} from 'react';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import {
  DynamicButtonGroup,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentButtonGroupProps = DynamicInputCommonFieldProps<string> & DynamicButtonGroup;

const DynamicContentButtonGroup: FC<DynamicContentButtonGroupProps> = ({
  title,
  description,
  options,
  value,
  source,
  onValueChange,
}) => {
  const handleSelect = (value: string) => {
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <Stack overflow="auto" spacing={1}>
      <HIDInfo
        description={description}
        label={title}
        labelVariant="subtitle1"
      />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={value}
        onChange={(_, value: string) => handleSelect(value)}
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <DynamicContentSource source={source} />
    </Stack>
  );
};

export default DynamicContentButtonGroup;
