import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Timeline as MUITimeline,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Card,
  styled,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'usehooks-ts';
import { useNavigate } from 'react-router';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { endOfDay } from 'date-fns';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  useDeleteEventsMutation,
  useGetEventTypesQuery,
  useLazyGetEventsQuery,
} from '../../api/api.timeline';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import {
  TimelineEvent,
  TimelineFilter,
} from '../../types.timeline';
import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import InfiniteScroll from '../../../../../../../../components/InfiniteScroll';
import ListCategoriesActions from '../../../../components/actions/ListCategoriesActions';
import TimelineFiltersSection, { TimelineFilters } from './components/TimelineFiltersSection';
import TimelineItem, {
  DateSeparator,
  TimelineLoadingItem,
  isDateSeparator,
} from './components/TimelineItem';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../../components/actions/ListEntitiesToolbarActions';
import { getCreateTimelineEventPath } from '../../navigation.timeline';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import useGetEntityInfo from '../../../../hooks/useGetEntityInfo';
import useLock from '../../../../../../../../hooks/useLock';
import GetStartedWizard from './components/GetStartedWizard';
import GetStartedBanner from './components/GetStartedBanner';
import {
  isAfterOrEqual,
  isBeforeOrEqual,
} from '../../../../../../../../utils/date';
import {
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
} from '../../../../../../../Auth/api/api.settings';
import { getActiveAuth } from '../../../../../../../../external-services/firebase';
import { createGuid } from '../../../../../../../../utils/uuid';
import TimelineLoaderSkeleton from './components/TimelineLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../../hooks/useSearch';

export const StyledMUITimeline = styled(MUITimeline)(() => ({
  [`& .${timelineOppositeContentClasses.root}`]: {
    flex: 0,
    textWrap: 'nowrap',
  },
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  padding: 0,
  margin: 0,
}));

const PAGE_SIZE = 20;

const isEqualYears = (date1: string, date2: string) => new Date(date1).getFullYear() === new Date(date2).getFullYear();
const getYearName = (date: string) => new Date(date).getFullYear().toString();

const isDifferentYearsInFold = (left: Array<TimelineEvent | DateSeparator>, right: Array<TimelineEvent>) => {
  const lastItem = R.last(left);
  const firstItem = R.head(right);

  return lastItem
    && !isDateSeparator(lastItem)
    && firstItem
    && !isEqualYears(lastItem.start, firstItem.start);
};

const isTodayBetweenDates = (date1: string, date2: string) => isAfterOrEqual(new Date(date1), new Date())
  && isBeforeOrEqual(new Date(date2), new Date());

const isTodayBetweenDatesInFold = (left: Array<TimelineEvent | DateSeparator>, right: Array<TimelineEvent>) => {
  const lastItem = R.last(left);
  const firstItem = R.head(right);

  return (lastItem
    && !isDateSeparator(lastItem)
    && firstItem
    && isTodayBetweenDates(lastItem.start, firstItem.start)
  ) || (!lastItem && firstItem && isBeforeOrEqual(new Date(firstItem.start), new Date()));
};

const getSelectedEventTypes = (filterTypesMap: Record<string, boolean>, eventTypes: Array<TimelineFilter>) => {
  const selectedTypes = eventTypes
    .filter((eventType) => filterTypesMap[eventType.id] !== false)
    .map((eventType) => eventType.id);

  return selectedTypes.length ? selectedTypes : undefined;
};

const Timeline: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'timeline']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const auth = getActiveAuth();

  const fillInDateSeparators = (items: Array<TimelineEvent>) => {
    const mappedItems = items
      .flatMap(
        (item, index) => {
          const todaySeparator = index > 0 && isTodayBetweenDates(items[index - 1].start, item.start)
            ? { label: t('common:today') }
            : undefined;

          const yearSeparator = index > 0 && !isEqualYears(items[index - 1].start, item.start)
            ? { label: getYearName(items[index - 1].start) }
            : undefined;

          return [yearSeparator, todaySeparator, item].filter(Boolean);
        },
      );

    return mappedItems;
  };

  const getSeparatorsInFold = (left: Array<TimelineEvent | DateSeparator>, right: Array<TimelineEvent>) => {
    const yearSeparator = isDifferentYearsInFold(left, right)
      ? { label: getYearName((R.last(left) as TimelineEvent).start) }
      : undefined;

    const todaySeparator = isTodayBetweenDatesInFold(left, right)
      ? { label: t('common:today') }
      : undefined;

    return [yearSeparator, todaySeparator].filter(Boolean);
  };

  const getEntityTypeInfo = useGetEntityInfo();

  const {
    currentData: userSettings,
    isSuccess: userSettingsLoaded,
    isLoading: isLoadingUserSettings,
  } = useGetUserSettingsQuery(
    auth.currentUser?.uid
      ? { userUId: auth.currentUser.uid }
      : skipToken,
  );

  const [setUserSettings] = useSetUserSettingsMutation();

  const [wizardOpen, setWizardOpen] = useState(false);

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [selectedItemsMap, setSelectedItemsMap] = useState<Record<string, boolean>>({});

  const defaultTimelineState = {
    totalCount: 0,
    fetchedCount: 0,
    events: undefined,
  };

  const [timelineState, setTimelineState] = useState<{
    totalCount: number;
    fetchedCount: number;
    events?: Array<TimelineEvent | DateSeparator>;
  }>(defaultTimelineState);

  const { locked: fetchLocked, lock: lockFetch } = useLock(timelineState.fetchedCount);

  const { totalCount, events } = timelineState;

  const [getEvents, { isLoading, isFetching }] = useLazyGetEventsQuery();
  const { data: eventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery(propertyId ? { propertyId } : skipToken);

  const [filters, setFilters] = useState<TimelineFilters>({
    from: undefined,
    to: endOfDay(new Date()).toISOString(),
    eventTypesMap: userSettings?.eventTypesMap,
  });

  const appliedFiltersCount = useMemo(
    () => {
      const filterValues = Object.values(filters.eventTypesMap || {});
      const hasSomeSelectedFilters = Boolean(filters.eventTypesMap)
        && filterValues.some((value) => !value)
        && filterValues.some((value) => value);

      return (hasSomeSelectedFilters ? 1 : 0) + (filters.from ? 1 : 0) + (filters.to ? 1 : 0);
    },
    [filters],
  );

  const [deleteEvents] = useDeleteEventsMutation();

  const handleFetch = ({ resetState = false, offset: offsetParam = 0 }) => {
    const offset = resetState ? 0 : offsetParam;

    if (!propertyId || isLoading || isFetching || (fetchLocked && !resetState)) {
      return;
    }

    lockFetch();

    getEvents({
      propertyId,
      from: filters.from,
      to: filters.to,
      types: getSelectedEventTypes(filters.eventTypesMap || {}, eventTypes || []),
      pageSize: PAGE_SIZE,
      offset,
      connections: true,
      key: resetState ? createGuid() : undefined,
    }, !resetState)
      .unwrap()
      .then((response) => {
        setTimelineState((prevState) => {
          const events = resetState ? [] : prevState.events || [];
          const totalCount = resetState ? 0 : prevState.totalCount;
          const fetchedCount = resetState ? 0 : prevState.fetchedCount;

          const mergedItems = [
            ...events,
            ...getSeparatorsInFold(events, response.events),
            ...fillInDateSeparators(response.events),
          ];

          return ({
            totalCount: totalCount || response.totalCount,
            fetchedCount: fetchedCount + response.events.length,
            events: mergedItems,
          });
        });
      });
  };

  useEffect(() => {
    if ((filters.eventTypesMap || userSettingsLoaded) && eventTypes) {
      setTimelineState(defaultTimelineState);
      handleFetch({ offset: 0, resetState: true });
    }
  }, [filters, userSettingsLoaded, eventTypes]);

  useEffect(() => {
    if (userSettings?.eventTypesMap) {
      setFilters((filters) => ({ ...filters, eventTypesMap: userSettings.eventTypesMap }));
    }
  }, [userSettings?.eventTypesMap]);

  useUpdateEffect(() => {
    if (filters.eventTypesMap) {
      setUserSettings({ eventTypesMap: filters.eventTypesMap });
    }
  }, [filters.eventTypesMap]);

  const { displaySearch } = useSearch(EntityType.TIMELINE_ENTRY);

  const hasMore = (!timelineState.events && totalCount > 0) || timelineState.fetchedCount < totalCount;

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateTimelineEventPath, { propertyId }));

  const handleEnterDeleteMode = () => {
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedItemsMap({});
    setSelectedItemsCount(0);
  };

  const getHandleClick = (event: TimelineEvent) => {
    if (selectionModeType === SelectionModeType.DELETE) {
      return () => {
        const selected = !selectedItemsMap[event.key];

        setSelectedItemsMap((itemsMap) => ({
          ...itemsMap,
          [event.key]: selected,
        }));

        setSelectedItemsCount((count) => selected ? count + 1 : count - 1);
      };
    }

    const entityInfo = getEntityTypeInfo(event.entity.type);

    const viewEntityPath = entityInfo
      && entityInfo.getViewLink
      && entityInfo.getViewLink({ propertyId: event.entity.propertyId, id: event.entity.id });

    return viewEntityPath ? () => navigate(viewEntityPath) : undefined;
  };

  const handleDelete = () => {
    const selectedEvents = Object.entries(selectedItemsMap)
      .filter(([_id, selected]) => selected)
      .map(([eventId]) => eventId);

    setSelectionModeType(undefined);
    setSelectedItemsMap({});
    setSelectedItemsCount(0);

    if (propertyId) {
      deleteEvents({ propertyId, keys: selectedEvents })
        .unwrap()
        .then(() => {
          setTimelineState(defaultTimelineState);
          handleFetch({ resetState: true });
        });
    }
  };

  const customActions = [
    {
      id: 'delete',
      label: t('common:delete_label'),
      Icon: DeleteOutline,
      onClick: handleEnterDeleteMode,
    },
  ];

  const handleShowWizard = () => setWizardOpen(true);

  const handleCloseWizard = () => {
    setWizardOpen(false);

    setTimelineState(defaultTimelineState);
    handleFetch({ resetState: true });
  };

  const showGetStartedBanner = eventTypes !== undefined && !hasMore;

  const isLoadingData = isLoadingEventTypes || isLoadingUserSettings || isLoading;

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListCategoriesActions
              customActions={customActions}
              onAdd={handleAdd}
              onSearch={displaySearch}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <TimelineFiltersSection
              filters={filters}
              onChange={setFilters}
            />
          </Card>
        </>
      }
      appliedFiltersCount={appliedFiltersCount}
      isLoading={isLoadingData}
      sideDrawerElements={[
        <ListCategoriesActions
          customActions={customActions}
          key={ListCategoriesActions.name}
          onAdd={handleAdd}
          onSearch={displaySearch}
        />,

        <TimelineFiltersSection
          filters={filters}
          key={TimelineFiltersSection.name}
          onChange={setFilters}
        />,
      ]}
      title={t('timeline:timeline')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <ListEntitiesToolbarActions
        count={totalCount}
        entity={EntityType.TIMELINE_ENTRY}
        isFetching={isFetching}
        isLoading={isLoading}
        selectedCount={selectedItemsCount}
        selectionModeType={selectionModeType}
        sx={{ marginBottom: 1 }}
        onCancel={() => setSelectionModeType(undefined)}
        onDelete={handleDelete}
      />
      <StyledMUITimeline>
        <InfiniteScroll
          hasMore={hasMore}
          isFetching={isFetching}
          onLoadMore={() => handleFetch({ offset: timelineState.fetchedCount })}
        >
          <TimelineLoaderSkeleton isLoading={isLoadingData}>
            {
              events?.map((event, index) =>
                isDateSeparator(event)
                  ? (
                    <TimelineItem
                      isLast={index === events.length - 1 && !isFetching}
                      item={event}
                      key={index}
                    />
                  )
                  : (
                    <TimelineItem
                      isLast={index === events.length - 1 && !isFetching}
                      isSelected={Boolean(selectedItemsMap[event.key])}
                      isSelectionMode={selectionModeType === SelectionModeType.DELETE}
                      item={event}
                      key={index}
                      onClick={getHandleClick(event)}
                    />
                  ))
            }
          </TimelineLoaderSkeleton>
        </InfiniteScroll>
        {isFetching ? <TimelineLoadingItem /> : null}
      </StyledMUITimeline>
      {showGetStartedBanner && (
        <GetStartedBanner
          sx={{ marginTop: 3 }}
          onClick={handleShowWizard}
        />
      )}
      <GetStartedWizard open={wizardOpen} onClose={handleCloseWizard} />
    </HomeLayout>
  );
};

export default Timeline;
