import React, {
  FC,
} from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import {
  DynamicInputCommonFieldProps,
  DynamicRadioButtonListField,
} from '../../../types/types.dynamicContent';

import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DynamicContentRadioButtonListProps = DynamicRadioButtonListField & DynamicInputCommonFieldProps<string>;

const DynamicContentRadioButtonList: FC<DynamicContentRadioButtonListProps> = ({
  title,
  description,
  options,
  value,
  source,
  onValueChange,
}) => {
  const handleSelect = (value: string) => {
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <Stack>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <HIDInfo
            description={description}
            label={title}
          />
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
        >
          {options.map((option) => (
            <FormControlLabel
              control={<Radio checked={option.value === value} onChange={() => handleSelect(option.value)} />}
              key={option.value}
              label={
                option.iconUrl
                  ? <img src={option.iconUrl} />
                  : option.label
              }
              value={option.value}
            />
          ))}

        </RadioGroup>
      </FormControl>
      <DynamicContentSource source={source} />
    </Stack>
  );
};

export default DynamicContentRadioButtonList;
