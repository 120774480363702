import React from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import {
  FCC,
  HIDListItemProps,
} from '../types/common';

const StyledListItem = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));

const ActionListItem = styled(StyledListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const ActionListItemDisabled = StyledListItem;

const HIDActionListItem: FCC<HIDListItemProps> = ({
  label,
  Icon,
  RightIcon,
  count,
  disabled,
  sx,
  onClick,
}) => {
  const theme = useTheme();

  const ListItem = disabled ? ActionListItemDisabled : ActionListItem;
  const color = disabled ? theme.palette.grey[500] : theme.palette.common.black;

  const hasRightComponent = Boolean(count !== undefined || RightIcon);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <ListItem
      direction="row"
      justifyContent={hasRightComponent ? 'space-between' : 'flex-start'}
      sx={sx}
      onClick={handleClick}
    >
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        spacing={1.5}
      >
        {Icon && <Icon sx={{ color }} />}
        <Typography
          sx={{
            color,
          }}
          variant="body1"
        >
          {label}
        </Typography>
      </Stack>
      {
        hasRightComponent
          ? count !== undefined
            ? (
              <Typography
                color={theme.palette.grey[500]}
                variant="subtitle2"
              >
                {count}
              </Typography>
            )
            : RightIcon && <RightIcon sx={{ color }} />
          : undefined
      }
    </ListItem>
  );
};

export default HIDActionListItem;
