import {
  BudgetAveragePaymentTypeValue,
  BudgetIndividualPaymentTypeValue,
  BudgetElectricityConsumptionPaymentTypeValue,
  BudgetMortgagesPaymentTypeValue,
  CreateUpdateBudgetValues,
  PaymentInterval,
  PaymentMonth,
  PaymentType,
  RemappedBudgetSubcategory,
} from '@house-id/houseid-types/dist/finances/budgets';

const getRemappedPaymentMonths = (paymentMonths: Array<PaymentMonth<number | undefined>>): Array<PaymentMonth<number>> =>
  paymentMonths.map(({ month, amount }) => ({ month, amount: amount || 0 }));

const getAverageData = (subcategory: RemappedBudgetSubcategory): BudgetAveragePaymentTypeValue => ({
  paymentInterval: subcategory.paymentInterval || PaymentInterval.MONTHLY,
  firstPaymentMonth: subcategory.firstPaymentMonth || 1,
  amount: subcategory.amount || 0,
  note: subcategory.note,
  currency: subcategory.currency,
  paymentType: PaymentType.AVERAGE,
  paymentMonths: subcategory.paymentInterval === PaymentInterval.QUARTERLY
    ? getRemappedPaymentMonths(subcategory.paymentMonths || [])
    : undefined,
});

const getIndividualData = (subcategory: RemappedBudgetSubcategory): BudgetIndividualPaymentTypeValue => ({
  currency: subcategory.currency,
  note: subcategory.note,
  paymentType: PaymentType.INDIVIDUAL,
  paymentMonths: getRemappedPaymentMonths(subcategory.paymentMonths || []),
});

const getMortgageData = (subcategory: RemappedBudgetSubcategory): BudgetMortgagesPaymentTypeValue => ({
  currency: subcategory.currency,
  paymentType: PaymentType.MORTGAGE,
  mortgages: (subcategory.mortgages || []).map(({ averageInterestRate, debt, amortization }) => ({
    averageInterestRate: averageInterestRate || 0,
    debt: debt || 0,
    amortization: amortization || 0,
  })),
  paymentMonths: getRemappedPaymentMonths(subcategory.paymentMonths || []),
});

const getElectricityConsumptionData = (subcategory: RemappedBudgetSubcategory): BudgetElectricityConsumptionPaymentTypeValue => ({
  currency: subcategory.currency,
  paymentType: PaymentType.ELECTRICAL_TRADE,
  averagePrice: subcategory.averagePrice || 0,
  annualConsumption: subcategory.annualConsumption || 0,
  paymentMonths: getRemappedPaymentMonths(subcategory.paymentMonths || []),
});

const BudgetPayloadDataGetterMap = {
  [PaymentType.AVERAGE]: getAverageData,
  [PaymentType.INDIVIDUAL]: getIndividualData,
  [PaymentType.MORTGAGE]: getMortgageData,
  [PaymentType.ELECTRICAL_TRADE]: getElectricityConsumptionData,
};

const getBudgetPayload = (values: CreateUpdateBudgetValues) => {
  const payload = Object.values(values.categories).filter(Boolean).map((category) => {
    const subcategories = (category.subcategories || []).map((item) => {
      const payloadData = BudgetPayloadDataGetterMap[item.paymentType](item);

      return {
        categoryId: category.categoryId,
        subCategoryId: item.subcategoryId,
        data: payloadData,
      };
    });

    return subcategories;
  });

  return payload;
};

export default getBudgetPayload;
