import React, {
  Suspense,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  DialogStateListEntry,
  hideDialog,
} from '../../../store/dialogsReducer';
import DialogComponents from '../../../hooks/useDialog/DialogComponents';

const DialogWrapper = ({
  id,
  name,
  props,
}: DialogStateListEntry) => {
  const dispatch = useDispatch();
  const Component = DialogComponents[name];

  const onClose = useCallback(
    () => {
      dispatch(hideDialog({ id, props }));
      props?.onAfterClose?.();
    },
    [id, props],
  );

  return (
    <Suspense>
      {/* @ts-ignore */}
      <Component {...{ ...props, open: Boolean(props?.open) }} onClose={onClose} />
    </Suspense>
  );
};

export default DialogWrapper;
