enum AdminRouteNames {
  ADMIN_HOME = '/admin',
  LABELING_QUEUES = '/admin/labeling-queues',
  LABELING_QUEUE_TASKS = '/admin/labeling-queues/:queueId/labeling-queue-tasks',
  VIEW_LABELING_TEMPLATES_TASK = '/admin/labeling-queues/:queueId/labeling-queue-tasks/:id',
  ADD_LABELING_QUEUE_TASK_INVOICE_DATA = '/admin/labeling-queues/:queueId/labeling-queue-tasks/update/:id/invoice',
  ADD_LABELING_QUEUE_TASK_CASHIER_RECEIPT_DATA = '/admin/labeling-queues/:queueId/labeling-queue-tasks/update/:id/cashier-receipt',
  EMAILS = '/admin/emails',
  UPLOAD_EMAIL = '/admin/emails/:id',
  IMITATE_ACCOUNT = '/admin/use',
  SURVEYS = '/admin/surveys',
  SURVEY_GROUPS = '/admin/surveys/:id/groups',
  SURVEY_GROUP_CRETE = '/admin/surveys/:id/groups/create',
  SURVEY_GROUP_UPDATE = '/admin/surveys/:id/groups/update/:groupId',
  CREATE_PROPERTY = '/admin/properties/create',
}

export default AdminRouteNames;
