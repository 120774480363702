import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Image: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        clipRule="evenodd"
        d="M11 10C12.1045 10 13 9.10466 13 8.00002C13 6.89544 12.1045 6 11 6C9.89542 6 9 6.89544 9 8.00002C9 9.10466 9.89542 10 11 10Z"
        fill={fillColor}
        fillRule="evenodd"
      />
      <path
        d="M17 22H6C3.79086 22 2 20.2093 2 18.0001V9M22 13.0909L17.9375 9L9 18M20.1111 3H7.88889C6.84568 3 6 3.74619 6 4.66667V16.3333C6 17.2538 6.84568 18 7.88889 18H20.1111C21.1543 18 22 17.2538 22 16.3333V4.66667C22 3.74619 21.1543 3 20.1111 3Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Image;
