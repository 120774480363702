import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useNavigate } from 'react-router';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import PropertyProgressContentItem from '../../../../PropertyProgress/components/PropertyProgressContentItem';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';
import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';
import { SellingPropertyArchiveNotesSectionData } from '../../../types.sellingProperty';
import {
  getCreateNotePath,
  getNotePath,
  getNotesPath,
} from '../../../../Content/modules/Note/navigation.note';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';

const SellingPropertyArchiveNotes: FC<PropertyProgressCategorySectionProps<SellingPropertyArchiveNotesSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['selling_property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToAllNotes = () =>
    navigate(getPathWithPropertyIdOrInit(getNotesPath, { propertyId }));

  const handleNavigateToNote = (noteId: string) =>
    navigate(getPathWithPropertyIdOrInit(getNotePath, { id: noteId, propertyId }));

  const handleNavigateToAddNote = () =>
    navigate(getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId }));

  const notes = section.data.notes || [];

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('selling_property:selling_property_archive_section_add_note')}
          description={t('selling_property:selling_property_archive_section_notes_description')}
          viewAllButtonText={t('selling_property:selling_property_archive_section_show_all_notes')}
          onAddClick={handleNavigateToAddNote}
          onViewAllClick={handleNavigateToAllNotes}
        >
          <Grid
            container
            spacing={1.5}
            xxs={12}
          >
            {R.take(4, notes).map((note) => (
              <Grid
                item
                key={note.id}
                md={6}
                xxs={12}
              >
                <PropertyProgressContentItem
                  DescriptionComponent={
                    <Typography>
                      {formatDate(new Date(note.createdAt), DateTimeFormats.DATE_ONLY)}
                    </Typography>
                  }
                  key={note.id}
                  title={note.name}
                  onNavigate={() => handleNavigateToNote(note.id)}
                />
              </Grid>
            ))}
          </Grid>
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default SellingPropertyArchiveNotes;
