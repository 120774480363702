import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Card,
  Stack,
  useTheme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useDebounce } from 'usehooks-ts';
import * as R from 'ramda';
import { useSearchParams } from 'react-router-dom';
import {
  BudgetAveragePaymentTypeValue,
  BudgetIndividualPaymentTypeValue,
  BudgetShortModel,
  BudgetSubcategory,
  BudgetTemplateCategoryId,
} from '@house-id/houseid-types/dist/finances/budgets';

import {
  useNavigateBackOr,
  useNavigationParams,
} from '../../../../../../../../utils/routes';
import {
  formatMoney,
  EMPTY_VALUE,
} from '../../../../../../../../utils/string';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import { TimeIntervalsGroupingType } from '../../../../../Content/types/types.content';
import FinancesQuickNavigation from '../../../../components/FinancesQuickNavigation';
import { getFinancesPath } from '../../../../navigation.finances';
import {
  FinancePageNames,
  FinancesFilterSectionMode,
} from '../../../../types.finances';
import {
  useGetBudgetComparisonQuery,
  useGetBudgetFiltrationQuery,
  useGetBudgetsQuery,
} from '../../api/api.budgets';
import BudgetActions from '../../components/BudgetActions';
import BudgetComparisonChart from '../../components/BudgetComparisonChart';
import {
  getBudgetCategoryPath,
  getBudgetsOverviewPath,
} from '../../navigation.budgets';
import BudgetsFilterSection, { BudgetsFilters } from '../../components/BudgetsFilterSection';
import { useGetRecurringExpensesBankAccountsQuery } from '../../../RecurringExpenses/api/api.recurringExpenses';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../../../constants/layout';
import { getBudgetName } from '../../utils/utils.budget';
import BudgetCategoriesQuickNavigation from './components/BudgetCategoriesQuickNavigation';
import { ALL_BUDGET_CATEGORY } from '../../constants.budgets';
import useGetComparisonColumns from '../../../RecurringExpenses/hooks/useGetComparisonColumns';
import { getComparisonList } from '../../../RecurringExpenses/utils.recurringExpenses';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';

const getBudgetById = (budgetId?: string, budgets?: Array<BudgetShortModel>) => budgets?.find((budget) => budget.id === budgetId);

const now = new Date();
const currentYear = now.getFullYear();
const yearBefore = currentYear - 1;

const useGetBudgetCategoryColumns = (columnParams: {
  budgetTotal: number;
  timeIntervalGroupingType: TimeIntervalsGroupingType
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances', 'common']);

  const isYearly = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Yearly;
  const isQuarterly = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Quarterly;

  const valueColumnLabel = columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Yearly
    ? t('finances:budget_yearly_label')
    : columnParams.timeIntervalGroupingType === TimeIntervalsGroupingType.Monthly
      ? t('finances:budget_monthly_label')
      : t('finances:budget_quarterly_label');

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:categories'),
      flex: 0.75,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => params?.row as BudgetSubcategory,
      sortComparator: (a: BudgetSubcategory, b: BudgetSubcategory) => a.label.localeCompare(b.label),
      renderCell: (params: GridRenderCellParams) => {
        const { label, value } = params?.row as BudgetSubcategory;
        const { note } = value as BudgetAveragePaymentTypeValue | BudgetIndividualPaymentTypeValue;

        return (
          <Stack>
            <Typography variant="body2">{label}</Typography>
            {Boolean(note) && (
              <Typography
                sx={{ color: theme.palette.grey[500] }}
                variant="body2"
              >
                {note}
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'value',
      headerName: valueColumnLabel,
      flex: 0.25,
      type: 'number',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<BudgetSubcategory>) => params.row.averagePerMonth,
      renderCell: (params: GridRenderCellParams<BudgetSubcategory, number | undefined>) => (
        <Typography noWrap variant="subtitle2">
          {
            params.value !== undefined
              ? formatMoney(isYearly
                ? params.value * 12
                : isQuarterly
                  ? params.value * 3
                  : params.value || 0)
              : EMPTY_VALUE
          }
        </Typography>
      ),
    },
  ].filter(Boolean);
  return columns;
};

const BudgetCategoryPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'common', 'finances']);

  const [_, setSearchParams] = useSearchParams();
  const {
    routeParams: { id: budgetId },
    queryParams: { categoryId = ALL_BUDGET_CATEGORY } = {},
  } = useNavigationParams<{ id: string }, { categoryId?: string; }>();

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [filters, setFilters] = useState<BudgetsFilters>({
    mode: FinancesFilterSectionMode.Filter,
    primaryBudgetId: budgetId,
    timeIntervalGroupingType: TimeIntervalsGroupingType.Monthly,
    categoryId: categoryId !== ALL_BUDGET_CATEGORY ? categoryId as BudgetTemplateCategoryId : undefined,
  });

  const isFiltrationMode = filters.mode === FinancesFilterSectionMode.Filter;

  const {
    data: budgets,
    isLoading: isBudgetsLoading,
    isFetching: isBudgetsFetching,
  } = useGetBudgetsQuery(propertyId ? { propertyId } : skipToken);

  const debouncedFilters = useDebounce(
    filters,
    FILTER_DEBOUNCE_TIME,
  );

  const {
    data: filtration,
    isLoading: isFiltrationLoading,
    isFetching: isFiltrationFetching,
  } = useGetBudgetFiltrationQuery(
    propertyId && debouncedFilters.primaryBudgetId && debouncedFilters.mode === FinancesFilterSectionMode.Filter
      ? {
        propertyId,
        budgetId: debouncedFilters.primaryBudgetId,
        fromMonth: debouncedFilters.fromMonth,
        toMonth: debouncedFilters.toMonth,
        grouping: debouncedFilters.timeIntervalGroupingType,
        categories: debouncedFilters.categoryId ? [debouncedFilters.categoryId] : undefined,
      }
      : skipToken,
  );

  const {
    data: comparison,
    isLoading: isComparisonLoading,
    isFetching: isComparisonFetching,
  } = useGetBudgetComparisonQuery(
    propertyId && debouncedFilters.primaryBudgetId && debouncedFilters.mode === FinancesFilterSectionMode.Compare
      ? {
        propertyId,
        budgetId: debouncedFilters.primaryBudgetId,
        fromMonth: debouncedFilters.fromMonth,
        toMonth: debouncedFilters.toMonth,
        grouping: debouncedFilters.timeIntervalGroupingType,
        compareToBudgetId: debouncedFilters.secondaryBudgetId,
        expenseYear: debouncedFilters.compareToYear,
        accounts: debouncedFilters.bankAccountIds,
        categories: debouncedFilters.categoryId ? [debouncedFilters.categoryId] : undefined,
      }
      : skipToken,
  );

  const isLoading = isFiltrationLoading || isComparisonLoading || isBudgetsLoading;
  const isFetching = isFiltrationFetching || isComparisonFetching || isBudgetsFetching;

  const comparisonChartData = comparison?.chart?.map(
    debouncedFilters.compareToYear
      ? (chartDataItem: ChartDataItem) => ({ ...chartDataItem, secondary: chartDataItem.line })
      : R.identity,
  );

  const chartData = debouncedFilters.mode === FinancesFilterSectionMode.Filter
    ? filtration?.chart
    : comparisonChartData;

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();

  const primaryBudget = getBudgetById(budgetId, budgets);
  const secondaryBudget = filters.secondaryBudgetId ? getBudgetById(filters.secondaryBudgetId, budgets) : undefined;

  const yearsList = [currentYear, yearBefore];

  const currentCategory = useMemo(
    () => categoryId === ALL_BUDGET_CATEGORY
      ? undefined
      : filtration?.budget?.categories?.find((category) => category.categoryId === categoryId),
    [filtration?.budget?.categories, categoryId],
  );

  const title = currentCategory
    ? currentCategory.label
    : t('common:all_label');

  const budgetTotal = useMemo(
    () => currentCategory
      ? R.sum(currentCategory.subcategories.map(({ totalAmount }) => totalAmount) || [])
      : R.sum(filtration?.budget.categories.flatMap(({ subcategories }) => subcategories)?.map(({ totalAmount }) => totalAmount) || []),
    [filtration?.budget?.categories, currentCategory],
  );

  const filtrationRows = useMemo(() => {
    if (filtration?.budget?.categories) {
      if (!currentCategory) {
        const allSubcategories = filtration.budget.categories.flatMap(({ subcategories }) => subcategories);
        return allSubcategories.length
          ? allSubcategories
          : [];
      }
      return currentCategory.subcategories.length
        ? currentCategory.subcategories
        : [];
    }
    return [];
  }, [filtration?.budget?.categories, currentCategory]);

  const comparisonRows = isFiltrationMode
    ? []
    : getComparisonList(
      comparisonChartData || [],
      debouncedFilters.compareToYear || secondaryBudget?.year,
      debouncedFilters.timeIntervalGroupingType,
    );

  const rows = isFiltrationMode ? filtrationRows : comparisonRows;

  const filtrationColumns = useGetBudgetCategoryColumns({
    budgetTotal,
    timeIntervalGroupingType: debouncedFilters.timeIntervalGroupingType,
  });
  const comparisonColumns = useGetComparisonColumns({ showDate: false });
  const columns = isFiltrationMode ? filtrationColumns : comparisonColumns;

  const handleBudgetIdChange = useCallback((budgetId: string) => {
    if (propertyId) {
      navigate(getBudgetCategoryPath({ propertyId, id: budgetId, categoryId }), { replace: true });
    }
  }, [propertyId, categoryId]);

  useEffect(() => {
    if (filters.primaryBudgetId && filters.primaryBudgetId !== budgetId) {
      handleBudgetIdChange(filters.primaryBudgetId);
    }
  }, [filters.primaryBudgetId]);

  useEffect(() => {
    if (categoryId && categoryId !== filters.categoryId) {
      setFilters({ ...filters, categoryId: categoryId !== ALL_BUDGET_CATEGORY ? categoryId as BudgetTemplateCategoryId : undefined });
    }
  }, [categoryId]);

  const getBudgetListNameById = (budgetId: string | undefined, budgetsList: Array<BudgetShortModel> | undefined) =>
    getBudgetName(budgetsList?.find((budgetListItem) => budgetListItem.id === budgetId));

  return (
    <HomeListLayout
      ListHeaderComponent={
        <Stack spacing={3}>
          <BudgetComparisonChart
            data={chartData || []}
            groupingType={debouncedFilters.timeIntervalGroupingType}
            isLoading={isLoading || isFetching}
            mode={filters.mode}
            primaryLabel={
              filters.primaryBudgetId
                ? `${t('forms_common:total')} ${getBudgetListNameById(primaryBudget?.id, budgets)}`
                : undefined
            }
            secondaryLabel={
              filters.secondaryBudgetId
                ? `${t('forms_common:total')} ${getBudgetListNameById(secondaryBudget?.id, budgets)}`
                : filters.compareToYear
                  ? `${t('forms_common:total')} ${filters.compareToYear}`
                  : undefined
            }
          />
        </Stack>
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <BudgetActions selectedBudget={primaryBudget} selectedBudgetCategory={currentCategory} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <FinancesQuickNavigation currentPage={FinancePageNames.BUDGET_CATEGORIES} />
          </Card>
          <Card sx={{ padding: 2 }}>
            <BudgetsFilterSection
              bankAccounts={bankAccounts}
              budgets={budgets}
              filters={filters}
              years={yearsList}
              onChange={setFilters}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <BudgetCategoriesQuickNavigation
              budgetId={budgetId}
              categoryId={categoryId}
              onCategoryIdChanged={(id) => setSearchParams({ categoryId: id })}
            />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getBudgetsOverviewPath, { propertyId, id: primaryBudget?.id }),
          name: primaryBudget?.name || '',
        },
      ]}
      columns={columns}
      getRowId={(category: BudgetSubcategory) => category.id}
      initialState={{
        pagination: {},
        sorting: isFiltrationMode
          ? {
            sortModel: [
              {
                field: 'value',
                sort: 'desc',
              },
              {
                field: 'label',
                sort: 'asc',
              },
            ],
          }
          : {},
      }}
      isLoading={isLoading || isFetching}
      rows={rows}
      sideDrawerElements={[
        <BudgetActions
          key={BudgetActions.name}
          selectedBudget={primaryBudget}
          selectedBudgetCategory={currentCategory}
        />,
        <FinancesQuickNavigation
          currentPage={FinancePageNames.BUDGET_CATEGORIES}
          key={FinancesQuickNavigation.name}
        />,
        <BudgetsFilterSection
          bankAccounts={bankAccounts}
          budgets={budgets}
          filters={filters}
          key={BudgetsFilterSection.name}
          years={yearsList}
          onChange={setFilters}
        />,
        <BudgetCategoriesQuickNavigation
          budgetId={budgetId}
          categoryId={categoryId}
          key={BudgetCategoriesQuickNavigation.name}
          onCategoryIdChanged={(id) => setSearchParams({ categoryId: id })}
        />,

      ].filter(Boolean)}
      title={title}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getBudgetsOverviewPath, { propertyId, id: budgetId }))}
    />
  );
};

export default BudgetCategoryPage;
