import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../../../types/common';

type BonusOffersSectionCardProps = {
  spacing?: string | number;
  alignContent?: 'center';
  alignItems?: 'center';
  justifyContent?: 'center';
  direction?: 'row' | 'column';
};

const BonusOffersSectionCard: FCC<BonusOffersSectionCardProps> = ({
  sx,
  spacing,
  alignItems,
  alignContent,
  justifyContent,
  direction,
  children,
}) => {
  const theme = useTheme();

  return (
    <Stack
      alignContent={alignContent}
      alignItems={alignItems}
      direction={direction}
      justifyContent={justifyContent}
      spacing={spacing}
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        border: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        padding: theme.spacing(2.5),
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default BonusOffersSectionCard;
