import { getDate } from 'date-fns';
import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../../../types/common';
import HIDTypography from '../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';

type DateCardProps = {
  date: string,
};

const DateCard: FCC<DateCardProps> = ({
  date: dateString,
  sx,
}) => {
  const theme = useTheme();
  const date = new Date(dateString);

  return (
    <Stack flexDirection="column" sx={sx}>
      <Stack
        sx={{
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          px: 1,
          py: 0.25,
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <HIDTypography
          sx={{
            color: theme.palette.common.white,
            textTransform: 'uppercase',
          }}
          variant="subtitle2"
        >
          {formatDate(date, DateTimeFormats.MONTH_ONLY)}
        </HIDTypography>
      </Stack>
      <Stack
        sx={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          py: 0.75,
          backgroundColor: theme.palette.primary.lighter,
          alignItems: 'center',
        }}
      >
        <HIDTypography sx={{ color: theme.palette.primary.dark }} variant="h5">
          {getDate(date)}
        </HIDTypography>
      </Stack>
    </Stack>
  );
};

export default DateCard;
