import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import {
  FCC,
  HIDListItemProps,
} from '../types/common';
import { toggleSideDrawerOpen } from '../store/layoutReducer';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';
import HIDActionListItem from './HIDActionListItem';

export type HIDActionsSectionProps = {
  title?: string;
  items: Array<HIDListItemProps>;
};

const HIDActionsSection: FCC<HIDActionsSectionProps> = ({
  title,
  items,
  sx,
  children,
}) => {
  const dispatch = useDispatch();
  const { isDownMd } = useBreakPointsSizes();

  const handleClick = (onClick: () => void) => {
    if (isDownMd) {
      dispatch(toggleSideDrawerOpen(false));
    }

    onClick();
  };

  return (
    <Stack sx={sx}>
      {Boolean(title) && (
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {title}
        </Typography>
      )}
      {items.map((
        {
          id,
          label,
          Icon,
          RightIcon,
          count,
          disabled,
          onClick,
        },
        index,
      ) => (
        <HIDActionListItem
          Icon={Icon}
          RightIcon={RightIcon}
          count={count}
          disabled={disabled}
          id={id}
          key={label}
          label={label}
          sx={{ marginBottom: index !== items.length - 1 ? 0.5 : 0 }}
          onClick={() => handleClick(onClick)}
        />
      ))}
      {children}
    </Stack>
  );
};

export default HIDActionsSection;
