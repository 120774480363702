import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getDocumentCategoryPath } from '../../../../Content/modules/Document/navigation.document';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import PropertyDocumentsSection from '../../../../PropertyProgress/components/PropertyDocumentsSection';
import {
  PropertyProgressCategorySectionProps,
  PropertyDocumentationData,
} from '../../../../PropertyProgress/types.propertyProgress';

const SellingPropertyArchiveDocuments: FC<PropertyProgressCategorySectionProps<PropertyDocumentationData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { t } = useTranslation(['property', 'selling_property']);

  const handleNavigateToDocuments = () => navigate(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId }));

  return (
    <PropertyDocumentsSection
      hideRecommendedTemplatesList
      showAddButton
      description={t('selling_property:selling_property_archive_section_documents_description')}
      documentsListTitle={t('property:property_progress_property_documentation_documents_and_actions')}
      section={section}
      viewAllDocumentsTitle={t('property:property_progress_property_documentation_view_all_documents')}
      onNavigateToDocuments={handleNavigateToDocuments}
    />
  );
};

export default SellingPropertyArchiveDocuments;
