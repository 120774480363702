import React, { MouseEvent } from 'react';

import {
  Button,
  Paper,
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FCC } from '../../../../../../../types/common';
import AutocompletePaperLoaderSkeleton from './AutocompletePaperLoaderSkeleton';

type AutocompletePaperProps = {
  className?: string;
  isLoading: boolean;
  hasLastSearches: boolean;
  onClear: (event: MouseEvent<HTMLButtonElement>) => void;
};

const AutocompletePaper: FCC<AutocompletePaperProps> = ({
  children,
  className,
  isLoading,
  hasLastSearches,
  onClear,
}) => {
  const { t } = useTranslation(['common', 'search']);
  const theme = useTheme();

  return (
    <Paper
      className={className}
      sx={{
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: 'inherit',
      }}
    >
      {!isLoading && hasLastSearches && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ padding: theme.spacing(0, 2, 1, 2) }}
          onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
            // Prevent input blur when interacting with content
            event.preventDefault();
          }}
        >
          <Typography color={theme.palette.common.black} variant="body2">
            {t('search:previous_searches')}
          </Typography>
          <Button
            size="small"
            sx={{
              borderRadius: theme.spacing(5),
              color: theme.palette.common.black,
            }}
            variant="text"
            onClick={onClear}
          >
            {t('common:clear')}
          </Button>
        </Stack>
      )}
      <AutocompletePaperLoaderSkeleton isLoading={isLoading}>
        {children}
      </AutocompletePaperLoaderSkeleton>
    </Paper>
  );
};

export default AutocompletePaper;
