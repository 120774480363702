import React, { FC } from 'react';

import PropertyDocumentsList from '../../../../PropertyProgress/components/PropertyDocumentsList';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import { SellingPropertyInformationDocuments } from '../../../types.sellingProperty';
import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';

const SellingPropertyInfoQuestionnaireAnswers: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationDocuments>> = ({
  section,
}) => (
  <PropertyProgressCategorySectionContainer
    Content={
      <PropertyDocumentsList
        propertyDocuments={section.data.documents}
      />
    }
    key={section.id}
    section={section}
  />
);

export default SellingPropertyInfoQuestionnaireAnswers;
