import { useTranslation } from 'react-i18next';

import * as R from 'ramda';
import { ALL_DOCUMENTS_CATEGORY } from './constants.document';
import {
  DocumentCategory,
  DocumentType,
} from './types.document';

export const useGetDocumentCategoryName = (categoryId: string | undefined, categories: Array<DocumentCategory>): string => {
  const { t } = useTranslation(['documents']);

  return categoryId === ALL_DOCUMENTS_CATEGORY
    ? t('documents:documents_documents_categories_all_documents')
    : categories
      ?.find((category) => category.id === categoryId)
      ?.name || t('documents:documents_documents_categories_title');
};

export const getDocumentCategory = (categories: Array<DocumentCategory>, categoryId?: string): DocumentCategory | undefined =>
  R.find((category: DocumentCategory) => category?.id === categoryId)(categories);

export const getDocumentType = (categories: Array<DocumentCategory>, categoryId: string, typeId?: string): DocumentType | undefined => {
  const currentCategory: DocumentCategory | undefined = getDocumentCategory(categories, categoryId);
  return R.find((type: DocumentType) => type.typeId === typeId, currentCategory?.types || []);
};
