import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Forum: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M8 16.3333C8 16.7459 8.16395 17.1416 8.45562 17.4333C8.74736 17.725 9.14302 17.8889 9.55555 17.8889H17.8889L21 21V9.55556C21 9.143 20.8361 8.74734 20.5444 8.45562C20.2527 8.16389 19.857 8 19.4444 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M17 12.3333C17 12.7459 16.836 13.1416 16.5444 13.4333C16.2526 13.725 15.857 13.8889 15.4444 13.8889H10.7778H6.1111L3 17V4.55556C3 4.143 3.16389 3.74734 3.45561 3.45562C3.74733 3.16389 4.143 3 4.55555 3H15.4444C15.857 3 16.2526 3.16389 16.5444 3.45562C16.836 3.74734 17 4.143 17 4.55556V12.3333Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="7"
        cy="8.5"
        fill={fillColor}
        r="1"
      />
      <circle
        cx="10"
        cy="8.5"
        fill={fillColor}
        r="1"
      />
      <circle
        cx="13"
        cy="8.5"
        fill={fillColor}
        r="1"
      />
    </HIDSvgIcon>
  );
};

export default Forum;
