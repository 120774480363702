import React from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  CheckRounded,
  ChevronRight,
  CircleOutlined,
} from '@mui/icons-material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../types/common';
import ContentImage from './ContentImage';
import { FileMimeType } from '../../../../../constants/mimeTypes';
import { hidSpacing } from '../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import useGetEntityInfo from '../hooks/useGetEntityInfo';

const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

type ConnectionListItemProps = {
  name: string;
  date?: string,
  thumbnailUrl?: string;
  entityType: EntityType;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onClick?: () => void
};

const ConnectionListItem: FCC<ConnectionListItemProps> = ({
  name,
  date,
  thumbnailUrl,
  entityType,
  isSelected,
  isSelectionMode,
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();

  const formattedDate = date ? formatDate(new Date(date), DateTimeFormats.DATE_AND_TIME) : undefined;
  const Icon = isSelected ? CheckRounded : CircleOutlined;

  const getEntityInfo = useGetEntityInfo();
  const entityInfo = getEntityInfo(entityType);

  return (
    <StyledStack
      alignItems="center"
      direction="row"
      style={style}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: '5px',
        padding: 1.5,
        ...sx,
      }}
      onClick={onClick}
    >
      {
        thumbnailUrl || !entityInfo?.Icon
          ? (
            <ContentImage
              blobs={thumbnailUrl ? [{ thumbnailUrl, mime: FileMimeType.JPEG } as HIDBlob] : undefined}
              size={hidSpacing(6)}
              sx={{
                marginRight: 1.5,
                minHeight: hidSpacing(6),
                justifyContent: 'center',
              }}
            />
          )
          : (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                aspectRatio: 1,
                height: hidSpacing(6),
                marginRight: 1.5,
              }}
            >
              <entityInfo.Icon />
            </Stack>
          )
      }
      <Stack justifyContent="center" sx={{ minWidth: 0 }}>
        <Typography noWrap variant="subtitle1">
          {name}
        </Typography>
        {formattedDate && (
          <Typography noWrap color={theme.palette.grey[500]} variant="body2">
            {formattedDate}
          </Typography>
        )}
      </Stack>
      {isSelectionMode
        ? (
          <Icon
            sx={{
              top: theme.spacing(1),
              right: theme.spacing(1),
              borderRadius: theme.spacing(1.5),
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: isSelected ? theme.palette.primary.main : theme.palette.grey[400],
              color: theme.palette.common.white,
              backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.common.white,
              pointerEvents: 'none',
              marginLeft: 'auto',
            }}
          />
        )
        : (
          <ChevronRight sx={{ marginLeft: 'auto' }} />
        )}

    </StyledStack>
  );
};

export default ConnectionListItem;
