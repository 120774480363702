import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  BuildingTemplate,
  Floor,
} from './types.buildings';
import { BuildingEntityType } from '../../../../constants/entityType';
import { EMPTY_VALUE } from '../../../../utils/string';
import {
  HousingType,
  FormOfOwnership,
} from '../../types/types.property';
import {
  DynamicInputTextField,
  DynamicGeoPointInput,
  DynamicInputField,
  DynamicDropDownField,
} from '../Content/modules/DynamicContent/types/types.dynamicContent';
import {
  PropertyProgressCategoryDetails,
  PropertySpecificationData,
  PropertySpecificationSectionSubSection,
} from '../PropertyProgress/types.propertyProgress';

const OTHER_SECTION_ID = 'miscellaneous';
const FLOOR_FIELD = 'floorId';
const BUILDINGS_FACT_SECTION = 'building-facts';
const FLOOR_NUMBERS_FIELD = 'stories';

export const getFloor = (floorId?: string, roomTemplate?: BuildingTemplate): Floor | undefined =>
  floorId && roomTemplate
    ? (roomTemplate
      ?.components
      ?.find(({ id }) => id === OTHER_SECTION_ID)
      ?.components
      ?.find(({ id }) => id === FLOOR_FIELD) as DynamicDropDownField)
      ?.options
      ?.find(({ value }) => value === floorId) as Floor
    : undefined;

export const getBuildingNumberOfFloorsOptions = (buildingTemplate?: BuildingTemplate): Array<Floor> | undefined =>
  buildingTemplate
    ? (buildingTemplate
      ?.components
      ?.find(({ id }) => id === BUILDINGS_FACT_SECTION)
      // @ts-ignore
      ?.components
      ?.find(({ id }) => id === FLOOR_NUMBERS_FIELD) as DynamicDropDownField)
      ?.options as Array<Floor>
    : undefined;

export const getBuildingNumberOfFloors = (storyId?: string, buildingTemplate?: BuildingTemplate): string | undefined =>
  storyId && buildingTemplate
    ? getBuildingNumberOfFloorsOptions(buildingTemplate)
      // @ts-ignore
      ?.find(({ value }) => value === storyId)?.label
    : undefined;

export const getPropertyFormOfOwnership = (storyId?: string, buildingTemplate?: BuildingTemplate): string | undefined =>
  storyId && buildingTemplate
    ? (buildingTemplate
      ?.components
      ?.find(({ id }) => id === BUILDINGS_FACT_SECTION)
      // @ts-ignore
      ?.components
      ?.find(({ id }) => id === FLOOR_NUMBERS_FIELD) as DynamicDropDownField)
      ?.options
      ?.find(({ value }) => value === storyId)?.label
    : undefined;

export const findHousingType = (
  housingType: HousingType,
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => (propertySpecificationsWithComponents?.sections?.find((section) => section.id === PropertySpecificationSectionSubSection.BASIC_INFO)
  ?.data?.components?.find(({ id }) => id === 'housingType') as DynamicDropDownField)
  ?.options
  ?.find(({ value }) => value === housingType) as { value: string; label: string; iconUrl: string; };

export const findPropertyDesignation = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => {
  const propertyDesignation = propertySpecificationsWithComponents?.sections?.find(
    (section) => section.id === PropertySpecificationSectionSubSection.PROPERTY_INFO,
  )
    ?.data?.components?.find(({ id }) => id === 'propertyDesignation') as DynamicInputTextField;

  if (propertyDesignation) {
    return { label: propertyDesignation.title, value: propertyDesignation.value || EMPTY_VALUE };
  }
  return undefined;
};

export const findCentralPoint = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => {
  const centralPoint = propertySpecificationsWithComponents?.sections?.find(
    (section) => section.id === PropertySpecificationSectionSubSection.PROPERTY_INFO,
  )
    ?.data?.components?.find(({ id }) => id === 'centralPoint') as DynamicGeoPointInput;

  if (centralPoint) {
    return { label: centralPoint.title, value: centralPoint.value?.coordinates.join(', ') || EMPTY_VALUE };
  }
  return undefined;
};

export const findAreaLandComponent = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => propertySpecificationsWithComponents?.sections?.find(
  (section) => section.id === PropertySpecificationSectionSubSection.PROPERTY_INFO,
)
  ?.data?.components?.find(({ id }) => id === 'areaLand') as DynamicGeoPointInput;

export const findFormOfOwnershipLabel = (
  formOfOwnership: FormOfOwnership,
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => (propertySpecificationsWithComponents?.sections?.find((section) => section.id === PropertySpecificationSectionSubSection.BASIC_INFO)
  ?.data?.components?.find(({ id }) => id === 'formOfOwnership') as DynamicDropDownField)
  ?.options
  ?.find(({ value }) => value === formOfOwnership)?.label;

export const hasFloor = (buildingEntityType: BuildingEntityType) => buildingEntityType === EntityType.ROOM;

export const getRoomAreaString = (area: number | undefined) => `${area || 0} m²`;

export const findHousingTypeOptions = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => (propertySpecificationsWithComponents?.sections?.find((section) => section.id === 'basic-info')
  ?.data?.components?.find(({ id }) => id === 'housingType') as DynamicDropDownField)?.options;

export const findHeatingSystemTemplate = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => propertySpecificationsWithComponents?.sections?.find((section) => section.id === 'heating')
  ?.data?.components?.find(({ id }) => id === 'heatingSystem') as DynamicDropDownField;

export const findFormOfOwnershipOptions = (
  propertySpecificationsWithComponents: PropertyProgressCategoryDetails<PropertySpecificationData> | undefined,
) => (propertySpecificationsWithComponents?.sections?.find((section) => section.id === 'basic-info')
  ?.data?.components?.find(({ id }) => id === 'formOfOwnership') as DynamicDropDownField)
  ?.options
  ?.map(({ label, value }) => ({ label, id: value }));

const getBuildingTemplateConstructionPartsComponent = (buildingTemplate?: BuildingTemplate): DynamicInputField[] => buildingTemplate
  ?.components
  ?.find(({ id }) => id === 'construction-parts')
  ?.components || [];

export const getBuildingHousingFoundationTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'houseFoundation') as DynamicDropDownField
    : undefined;

export const getBuildingHousingBodyTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'houseBody') as DynamicDropDownField
    : undefined;

export const getBuildingFacadeTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'facade') as DynamicDropDownField
    : undefined;

export const getBuildingRoofingTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'roofing') as DynamicDropDownField
    : undefined;

export const getBuildingIntermediateJoistsTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'intermediateJoists') as DynamicDropDownField
    : undefined;

export const getBuildingAtticBeamsTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'atticBeams') as DynamicDropDownField
    : undefined;

export const getBuildingChimneyTemplate = (buildingTemplate?: BuildingTemplate): DynamicDropDownField | undefined =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'chimney') as DynamicDropDownField
    : undefined;

export const getBuildingVentilationTemplate = (buildingTemplate?: BuildingTemplate) =>
  buildingTemplate
    ? getBuildingTemplateConstructionPartsComponent(buildingTemplate)
      ?.find(({ id }) => id === 'ventilation') as DynamicDropDownField
    : undefined;

export const useGetBuildingEntityShortInfo = () => {
  const { t } = useTranslation(['common']);

  return (
    template: BuildingTemplate | undefined,
    floorId: string | undefined,
    area: number | undefined,
    year?: number,
  ) => {
    const floor = getFloor(floorId, template);

    return [
      area ? t('common:m2_value', { value: area }) : undefined,
      floor?.shortLabel,
      year,
    ]
      .filter(Boolean)
      .join(', ');
  };
};
