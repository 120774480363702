import React, {
  FC,
} from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
  ArrowForward,
  Close,
} from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import Wallet from '../../../../../../../components/icons/Wallet';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { useGetRecurringExpensesSummaryQuery } from '../api/api.recurringExpenses';
import useGetEntityInfo from '../../../../Content/hooks/useGetEntityInfo';
import {
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
} from '../../../../../../Auth/api/api.settings';
import { getActiveAuth } from '../../../../../../../external-services/firebase';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';

type RecurringExpenseSuggestionsStatusBoxProps = {
  isSidebarBanner?: boolean;
};

const RecurringExpenseSuggestionsStatusBox: FC<RecurringExpenseSuggestionsStatusBoxProps> = ({ isSidebarBanner }) => {
  const theme = useTheme();
  const auth = getActiveAuth();
  const { t } = useTranslation(['finances', 'common']);

  const {
    data: summary,
  } = useGetRecurringExpensesSummaryQuery();
  const getEntityInfo = useGetEntityInfo();

  const [setUserSettings, { isLoading: isDeleting }] = useSetUserSettingsMutation();

  const {
    data: { expensesSuggestionsStatusBoxDisabled } = {},
    isLoading: isUserSettingsLoading,
  } = useGetUserSettingsQuery(
    auth.currentUser?.uid
      ? { userUId: auth.currentUser.uid }
      : skipToken,
  );

  const fixedExpense = getEntityInfo(EntityType.RECURRING_EXPENSE);

  const description = summary?.numberOfSuggestions
    ? t('finances:recurring_expenses_suggestions_status_box_expenses_description', { count: summary?.numberOfSuggestions || 0 })
    : summary && summary?.numberOfOtherGroups > 1
      ? t('finances:recurring_expenses_suggestions_status_box_transactions_description', {
        count: summary?.numberOfOtherGroups || 0,
        entityName: fixedExpense?.namePlural?.toLocaleLowerCase(),
      })
      : t('finances:recurring_expenses_suggestions_status_box_transactions_description', {
        count: summary?.numberOfOtherGroups || 0,
        entityName: fixedExpense?.name?.toLocaleLowerCase(),
      });

  const [openManageRecurringExpensesCandidatesDialog] = useDialog(
    DialogNames.MANAGE_RECURRING_EXPENSES_CANDIDATES_DIALOG,
  );
  const handleNavigateToManageExpensesCandidates = () => {
    openManageRecurringExpensesCandidatesDialog();
  };

  const handleHideBox = () => setUserSettings({ expensesSuggestionsStatusBoxDisabled: true });

  const [openConfirmDelete] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const handleDelete = () => openConfirmDelete({
    title: t('common:are_you_sure_you_want_to_delete'),
    description: t('common:are_you_sure_you_want_to_delete'),
    loading: isDeleting,
    onYes: handleHideBox,
    onNo: () => { },
  });

  const shouldShowBanner = summary && (summary?.numberOfSuggestions > 0 || summary?.numberOfOtherGroups > 0);

  const Content = (
    <Stack
      gap={2}
      sx={{
        padding: 2,
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <Stack flexDirection="row" gap={1}>
        <Stack flexDirection="row" gap={1}>
          <Wallet iconColor={theme.palette.primary.dark} />
          <HIDTypography>
            <ReactMarkdown components={{ p: 'span' }}>
              {description}
            </ReactMarkdown>
          </HIDTypography>
          <HIDIconButton
            Icon={Close}
            color="blank"
            onClick={handleDelete}
          />
        </Stack>

      </Stack>
      <HIDButton
        Icon={ArrowForward}
        onClick={handleNavigateToManageExpensesCandidates}
      >
        {t('finances:recurring_expenses_suggestions_status_box_transactions_action')}
      </HIDButton>
    </Stack>
  );

  return !isUserSettingsLoading && !expensesSuggestionsStatusBoxDisabled && shouldShowBanner
    ? isSidebarBanner
      ? (
        <Card>
          {Content}
        </Card>
      )
      : Content
    : null;
};
export default RecurringExpenseSuggestionsStatusBox;
