import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Bonus: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <circle
        cx={2.4}
        cy={9.2}
        fill={color}
        r={strokeWidth / 2}
      />
      <path
        d="M2 12c0 2.138.67 4.118 1.812 5.743A9.989 9.989 0 0012.001 22C17.523 22 22 17.524 22 12c0-5.523-4.477-10-10-10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M7 12l1.98-1.54V7.99l2.47.55L13.431 7l1.1 2.225 2.469.55L15.9 12l1.1 2.225-2.47.55L13.431 17l-1.98-1.54-2.47.55v-2.47L7 12z"
        fill={fillColor}
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth * 0.75}
      />
      <path
        clipRule="evenodd"
        d="M10 10.5a.5.5 0 111.001.001A.5.5 0 0110 10.5zM13 13.5a.5.5 0 111.001.001A.5.5 0 0113 13.5z"
        stroke={theme.palette.common.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth / 2}
      />
      <path
        d="M10 14l4-4"
        stroke={theme.palette.common.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth / 2}
      />
    </HIDSvgIcon>
  );
};

export default Bonus;
