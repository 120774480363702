import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Note: Icon = ({
  iconColor: colorProp,
  fillColor: follColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = follColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <circle
        cx="12"
        cy="8"
        fill={fillColor}
        r="2"
      />
      <path
        clipRule="evenodd"
        d="M19.4121 1.61558C19.8062 1.22142 20.3408 1 20.8982 1C21.4556 1 21.9902 1.22142 22.3844 1.61558C22.7785 2.00972 22.9999 2.5443 22.9999 3.10171C22.9999 3.65912 22.7785 4.19369 22.3844 4.58784L13.3088 13.7554L10.3081 10.7417L19.4121 1.61558Z"
        fill="#FEB700"
        fillRule="evenodd"
        id="Path"
      />
      <path
        d="M12 3.12134H5C4.46957 3.12134 3.96086 3.33205 3.58579 3.70713C3.21071 4.0822 3 4.59091 3 5.12134V19.1213C3 19.6518 3.21071 20.1605 3.58579 20.5355C3.96086 20.9106 4.46957 21.1213 5 21.1213H19C19.5304 21.1213 20.0392 20.9106 20.4142 20.5355C20.7893 20.1605 21 19.6518 21 19.1213V12.1213M19.4098 1.61598C19.8042 1.22157 20.3392 1 20.8969 1C21.4547 1 21.9897 1.22157 22.384 1.61598C22.7784 2.01039 23 2.54532 23 3.1031C23 3.66088 22.7784 4.1958 22.384 4.59021L12.9656 14.0086L9 15L9.99141 11.0344L19.4098 1.61598Z"
        id="Vector"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Note;
