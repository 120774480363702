import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';

import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import {
  PropertyProductTypesSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProductTypeGuideSection from './PropertyProductTypeGuideSection';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getProductsPath } from '../../../../Content/modules/Product/navigation.product';
import useGetPropertyProgressCategoryContentText from '../../../hooks/useGetPropertyProgressCategoryContentText';
import { getPropertyProgressPercent } from '../../../utils/utils.propertyProgress';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const PropertyProductTypesSection: FC<PropertyProgressCategorySectionProps<PropertyProductTypesSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { t } = useTranslation(['property']);

  const handleNavigateToAllProducts = () => navigate(getPathWithPropertyIdOrInit(getProductsPath, { propertyId }));

  const getPropertyProgressCategoryContentDescription = useGetPropertyProgressCategoryContentText();
  const description = getPropertyProgressCategoryContentDescription({
    percent: getPropertyProgressPercent(section?.progress),
    categoryId: PropertyProgressCategorySection.PRODUCTS,
    sectionId: section?.id,
  });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          viewAllButtonText={t('property:property_progress_property_products_show_all_products')}
          onViewAllClick={handleNavigateToAllProducts}
        >
          {section.data.productTypes?.map((productType) => (
            <PropertyProductTypeGuideSection
              key={productType.type}
              productType={productType}
            />
          ))}
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={{ ...section, description }}
    />
  );
};
export default PropertyProductTypesSection;
