import React from 'react';
import { useTheme } from '@mui/material';

import HIDDatePicker, { HIDDatePickerProps } from './HIDDatePicker';
import { DateTimeFormats } from '../../utils/date';
import { FCC } from '../../types/common';

type HIDFilterDatePickerProps = {
  fullWidth?: boolean;
  label: string;
  value: Date | undefined;
  maxDate?: Date;
  minDate?: Date;
  views?: HIDDatePickerProps['views'];
  slotProps?: HIDDatePickerProps['slotProps'];
  dateTimeFormat?: DateTimeFormats;
  onChange: (date?: Date | undefined) => void;
};

const HIDFilterDatePicker: FCC<HIDFilterDatePickerProps> = ({
  fullWidth,
  label,
  value,
  maxDate,
  minDate,
  views,
  dateTimeFormat,
  slotProps,
  sx,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <HIDDatePicker
      dateTimeFormat={dateTimeFormat}
      fullWidth={fullWidth}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      slotProps={{
        ...slotProps,
        textField: {
          size: 'small',
          InputLabelProps: {
            sx: {
              '& +.MuiInputBase-root': {
                paddingTop: '0px',
              },
            },
          },
          sx: {
            marginTop: theme.spacing(2),
          },
        },
        inputAdornment: {
          sx: {
            marginRight: -1,
          },
        },
      }}
      sx={sx}
      value={value}
      views={views}
      onChange={onChange}
    />
  );
};
export default HIDFilterDatePicker;
