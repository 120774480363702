import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { match } from 'ts-pattern';

import { FCC } from '../types/common';
import HIDLogoIcon from './icons/HIDLogoIcon';
import HIDLogoText from './icons/HIDLogoText';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';

type HIDLogoProps = {
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium';
  onClick: () => void
};

const HIDLogo: FCC<HIDLogoProps> = ({
  color = 'primary',
  size: sizeProp = 'medium',
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();

  const { isDownSm } = useBreakPointsSizes();
  const size = sizeProp || (isDownSm ? 'small' : 'medium');

  const iconColor = match(color)
    .with('primary', () => theme.palette.primary.main)
    .with('secondary', () => theme.palette.common.white)
    .exhaustive();

  return (
    <Stack
      alignItems="center"
      direction="row"
      style={style}
      sx={{
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      <HIDLogoIcon iconColor={iconColor} />
      {(size === 'medium') && (
        <HIDLogoText
          iconColor={iconColor}
          sx={{ marginLeft: theme.spacing(1) }}
        />
      )}
    </Stack>
  );
};

export default HIDLogo;
