import React from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  differenceInDays,
} from 'date-fns';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  DeleteOutline,
} from '@mui/icons-material';
import { ExpenseBankAccount } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import { FCC } from '../../../../../../../../../types/common';
import BankAccount from './BankAccount';

type BankAccountListItemProps = {
  bankAccount: ExpenseBankAccount;
  updateAutomatically: boolean;
  onUpdateAutomaticallyChange: (checked: boolean) => void;
  onDelete: () => void;
};

const BankAccountListItem: FCC<BankAccountListItemProps> = ({
  bankAccount,
  updateAutomatically,
  onUpdateAutomaticallyChange,
  onDelete,
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);
  const expiredInDaysNumber = differenceInDays(new Date(bankAccount.consent.expireAt), new Date());

  return (
    <Card
      sx={{
        padding: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        ...sx,
      }}
    >
      <Stack>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-between"
        >
          <BankAccount
            bban={bankAccount.bban}
            lastSyncedAt={bankAccount.lastSyncedAt}
            logoURL={bankAccount.provider.logoURL}
            name={bankAccount.name}
            product={bankAccount.product}
          />
          <Stack style={{ marginLeft: theme.spacing(1.5) }}>
            <HIDIconButton
              Icon={DeleteOutline}
              onClick={onDelete}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{}}
          >
            <HIDInfo
              description={t('finances:expenses_update_automatically_description', { daysNumber: expiredInDaysNumber })}
              label={t('finances:expenses_update_automatically')}
            />
            <HIDIconButton
              Icon={() =>
                updateAutomatically
                  ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                  : <CheckBoxOutlineBlank />}
              color="blank"
              onClick={(event) => {
                event.stopPropagation();
                onUpdateAutomaticallyChange(!updateAutomatically);
              }}
            />
          </Stack>
          {Boolean(updateAutomatically && bankAccount?.consent?.expireAt) && (
            <Typography variant="body2">
              {t(
                'finances:expenses_manage_bank_accounts_account_consent_expired_at',
                { date: formatDate(new Date(bankAccount?.consent?.expireAt), DateTimeFormats.DATE_ONLY) },
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default BankAccountListItem;
