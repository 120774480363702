import React from 'react';
import {
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../types/common';
import HIDImage from '../../../../../components/image/HIDImage';
import HorizontalImagePlaceholder from '../../../../../components/svg/HorizontalImagePlaceholder';

type BonusOfferCardImageProps = {
  maxHeight: string | number;
  imageUrl?: string;
};

const BonusOfferCardImage: FCC<BonusOfferCardImageProps> = ({
  sx,
  imageUrl,
  maxHeight,
}) => {
  const theme = useTheme();

  const commonStyles = {
    width: '100%',
    overflow: 'hidden',
    aspectRatio: 21 / 9,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    maxHeight,
  };

  return (
    <HIDImage
      showSkeleton
      SkeletonIcon={HorizontalImagePlaceholder}
      containerSx={{
        width: '100%',
        ...sx,
      }}
      skeletonSx={{
        color: theme.palette.shadow[100],
        maxWidth: '100%',
        ...commonStyles,
      }}
      sx={{
        objectFit: 'cover',
        height: 'auto',
        ...commonStyles,
      }}
      url={imageUrl || ''}
    />
  );
};

export default BonusOfferCardImage;
