import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  getProductPath,
  getProductsPath,
} from '../../../../Content/modules/Product/navigation.product';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';
import {
  PropertyProgressCategorySectionProps,
  PropertyProductTypesSectionData,
} from '../../../../PropertyProgress/types.propertyProgress';
import useGetAddEntityConnectionAction from '../../../../Content/hooks/useGetAddEntityConnectionAction';
import PropertyProductsList from '../../../../PropertyProgress/pages/PropertyProducts/components/PropertyProductsList';

const SellingPropertyArchiveProducts: FC<PropertyProgressCategorySectionProps<PropertyProductTypesSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { t } = useTranslation(['home', 'property', 'selling_property']);

  const handleNavigateToAllProducts = () => navigate(getPathWithPropertyIdOrInit(getProductsPath, { propertyId }));

  const handleNavigateToViewProduct = (productId: string) =>
    navigate(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id: productId }));

  const addProduct = useGetAddEntityConnectionAction(EntityType.PRODUCT);

  const handleCreateProduct = () => addProduct([], { propertyId });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('home:home_add_products')}
          viewAllButtonText={t('property:property_progress_property_products_show_all_products')}
          onAddClick={handleCreateProduct}
          onViewAllClick={handleNavigateToAllProducts}
        >
          {section.data.productTypes?.map((productType, index) => (
            <PropertyProductsList
              hideNoContentPlaceholder
              key={index}
              productType={productType}
              onClick={handleNavigateToViewProduct}
            />
          ))}
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={{
        ...section,
        description: t('selling_property:selling_property_archive_section_products_description'),
      }}
    />
  );
};
export default SellingPropertyArchiveProducts;
