import React, { FC } from 'react';
import {
  useTheme,
  Stack,
  Skeleton,
} from '@mui/material';

import HorizontalImagePlaceholder from '../../../../../components/svg/HorizontalImagePlaceholder';

type BonusOfferCardLoaderSkeletonProps = {
  isPrimary?: boolean;
  showDescription?: boolean;
};

const BonusOfferCardLoaderSkeleton: FC<BonusOfferCardLoaderSkeletonProps> = ({
  isPrimary = false,
  showDescription = false,
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Skeleton
        sx={{
          display: 'flex',
          color: theme.palette.shadow[100],
          backgroundColor: 'unset',
          justifyContent: 'center',
          alignItems: 'start',
          overflow: 'hidden',
          maxHeight: theme.spacing(isPrimary ? 50 : 35),
        }}
        variant="rounded"
        width="100%"
      >
        <HorizontalImagePlaceholder />
      </Skeleton>
      <Stack
        flex={1}
        justifyContent="space-between"
        spacing={2}
        sx={{
          padding: theme.spacing(2, 0.5),
        }}
      >
        <Skeleton variant="rounded" width="75%" />
        {showDescription && (
          <Stack>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="55%" />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BonusOfferCardLoaderSkeleton;
