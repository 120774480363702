import React from 'react';
import {
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import {
  FCC,
} from '../../../../../../types/common';

import { getTypographyHeight } from '../../../../../../utils/style';

type FilesSectionProps = {
  isLoading: boolean;
  showTitle: boolean;
  showContent: boolean;
  itemsCount?: number;
  title: string;
};

const FilesSection: FCC<FilesSectionProps> = ({
  isLoading,
  showTitle,
  showContent,
  itemsCount = 4,
  title,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={1.5} sx={{ padding: theme.spacing(0.5, 1) }}>
        {R.range(0, itemsCount).map((i) => (
          <Skeleton
            height={getTypographyHeight(theme.typography.body2)}
            key={i}
            variant="rounded"
            width={!i ? '50%' : '100%'}
          />
        ))}
      </Stack>
    );
  }

  if (showContent) {
    return (
      <>
        {showTitle && (
          <Typography variant="h6">
            {title}
          </Typography>
        )}
        <Stack spacing={2}>
          {children}
        </Stack>
      </>
    );
  }

  return null;
};

export default FilesSection;
