import React, { ReactNode } from 'react';
import {
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import {
  Menu,
  MenuOpen,
} from '@mui/icons-material';

import { HEADER_HEIGHT } from '../../constants/layout';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';
import { FCC } from '../../types/common';
import HIDIconButton from '../buttons/HIDIconButton';

const HeaderContainer = styled(Stack)(({ theme }) => ({
  alignSelf: 'stretch',
  backgroundColor: theme.palette.common.white,
  height: HEADER_HEIGHT,
  maxHeight: HEADER_HEIGHT,
  border: `solid 0px ${theme.palette.grey[200]}`,
  borderBottomWidth: 1,
}));

type HIDHeaderProps = {
  showMenu?: boolean;
  isMenuOpened?: boolean;
  LeftComponent?: ReactNode;
  MiddleComponent?: ReactNode;
  RightComponent?: ReactNode;
  onToggleMenuOpen?: (isMenuOpened: boolean) => void;
};

const HIDHeader: FCC<HIDHeaderProps> = ({
  showMenu = true,
  isMenuOpened,
  LeftComponent,
  MiddleComponent,
  RightComponent,
  sx,
  onToggleMenuOpen,
}) => {
  const theme = useTheme();

  const { isDownMd } = useBreakPointsSizes();

  return (
    <HeaderContainer
      alignItems="center"
      alignSelf="stretch"
      direction="row"
      flex={1}
      justifyContent="space-between"
      sx={{
        padding: {
          sm: theme.spacing(0, 2),
          xxs: theme.spacing(0, 2, 0, 1),
        },
        ...sx,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        marginRight={1}
        minWidth={0}
      >
        {isDownMd && showMenu && (
          <HIDIconButton
            Icon={isMenuOpened ? MenuOpen : Menu}
            sx={{ marginRight: theme.spacing(1) }}
            onClick={onToggleMenuOpen ? () => onToggleMenuOpen(!isMenuOpened) : undefined}
          />
        )}
        {LeftComponent}
      </Stack>
      {MiddleComponent}
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ minWidth: 0 }}
      >
        {RightComponent}
      </Stack>
    </HeaderContainer>
  );
};

export default HIDHeader;
