import React from 'react';
import { useSelector } from 'react-redux';
import {
  skipToken,
} from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import { Note } from '../types.note';
import { DeleteContentParams } from '../../../types/types.content';
import {
  CreateEntity,
  HIDEntityId,
} from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/api.property';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const noteApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotes: builder.query<Array<Note>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/notes`,
      providesTags: (result) => provideArrayTags(HIDApiTags.NOTE, result),
    }),
    getNote: builder.query<Note, { propertyId: string, id: string }>({
      query: ({ propertyId, id }) => `/properties/${propertyId}/notes/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.NOTE, id: arg.id }],
    }),
    deleteNotes: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `/properties/${propertyId}/notes?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...arg.ids.map((id) => ({ type: HIDApiTags.NOTE as const, id })),
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    createNote: builder.mutation<Note, CreateEntity<Note> & PropertyId>({
      query: (note) => ({
        url: `/properties/${note.propertyId}/notes`,
        method: 'POST',
        body: note,
      }),
      invalidatesTags: () => [
        HIDApiTags.NOTE,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    updateNote: builder.mutation<Note, Partial<Note> & PropertyId & HIDEntityId>({
      query: (note) => ({
        url: `/properties/${note.propertyId}/notes/${note.id}`,
        method: 'PATCH',
        body: note,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: HIDApiTags.NOTE as const, id: arg.id },
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
  }),
});

export const {
  useGetAllNotesQuery,
  useDeleteNotesMutation,
  useCreateNoteMutation,
  useUpdateNoteMutation,
} = noteApi;

export const useGetNoteWithCache = ({
  propertyId,
  noteId,
}: {
  propertyId?: string,
  noteId?: string,
}) => {
  const selectNotes = React.useMemo(
    () => noteApi.endpoints.getAllNotes.select(propertyId ? { propertyId } : skipToken),
    [propertyId],
  );

  const [
    _deleteNotes,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteNotesMutation({
    fixedCacheKey: getMutationFixedCacheKey(noteId),
  });
  const isDeletingOrDeleted = isDeleting || isDeleted;

  const { data: cachedNotes } = useSelector(selectNotes);
  const cachedNote = cachedNotes?.find((product) => product.id === noteId);

  const {
    data: fetchedNote,
    isLoading,
    error,
  } = noteApi.useGetNoteQuery(
    propertyId && noteId && !cachedNote && !isDeletingOrDeleted
      ? { propertyId, id: noteId }
      : skipToken,
  );

  return {
    note: fetchedNote || cachedNote,
    isLoading,
    error,
  };
};
