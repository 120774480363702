import React from 'react';
import {
  Stack,
  Skeleton,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../../../types/common';

const defaultItemSizes = [75, 50, 85, 65, 45];

type AutocompletePaperLoaderSkeletonProps = {
  itemSizes?: ReadonlyArray<number>;
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const AutocompletePaperLoaderSkeleton: FCC<AutocompletePaperLoaderSkeletonProps> = ({
  itemSizes = defaultItemSizes,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={0.5} sx={{ marginLeft: theme.spacing(1.5) }}>
        {itemSizes.map((width, i) => (
          <Stack
            alignItems="center"
            direction="row"
            key={i}
            spacing={1}
          >
            <Skeleton
              height={17}
              variant="circular"
              width={17}
            />
            <Skeleton
              variant="text"
              width={`calc(${width}% - (${theme.spacing(1)} + 17px))`}
            />
          </Stack>
        ))}
      </Stack>
    );
  }

  return children;
};

export default AutocompletePaperLoaderSkeleton;
