import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RecurringExpense } from '@house-id/houseid-types/dist/finances/recurringExpenses';

import HIDTypography from '../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../../../../../utils/string';
import BasicInfoSection from '../../../../Content/components/sections/BasicInfoSection';
import { useGetRecurringExpensesBankAccountsQuery } from '../api/api.recurringExpenses';
import { getBankAccountName } from '../utils.recurringExpenses';

type ExpenseInformationSectionProps = {
  expense: RecurringExpense;
};

const ExpenseInformationSection: FC<ExpenseInformationSectionProps> = ({
  expense,
}) => {
  const { t } = useTranslation(['forms_common', 'finances']);

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();

  const getAccountById = (accountId: string) => bankAccounts.find((account) => account.id === accountId);

  const account = getAccountById(expense.lastTransaction.accountId);
  const bankName = account ? getBankAccountName(account) : undefined;

  const fields = [
    { label: t('finances:total_sum'), value: formatMoney(expense.totalAmount, true) || EMPTY_VALUE },
    {
      label: t('finances:last_payment_date'),
      value: formatDate(new Date(expense.lastTransaction.date), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE,
    },
    { label: t('finances:last_amount'), value: formatMoney(expense.lastTransaction.amount.value) },
    account !== undefined && {
      label: t('finances:paid_from'),
      value: (
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <img src={account.provider.logoURL} style={{ width: 32, height: 32 }} />
          <HIDTypography>{bankName}</HIDTypography>
        </Stack>
      ),
    },
  ].filter(Boolean);

  return (
    <Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <HIDTypography variant="h6">
          {t('finances:cost')}
        </HIDTypography>
      </Stack>
      <BasicInfoSection items={fields} />
    </Stack>
  );
};

export default ExpenseInformationSection;
