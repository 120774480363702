import React, {
  FC,
} from 'react';
import {
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { getColorGradient } from '../../../../../../utils/style';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import BonusOffersBaseSlideLayout from './BonusOffersBaseSlideLayout';
import PurpleRocketImage from '../../../../../../assets/images/purple_rocket.png';
import PurpleCloudImage from '../../../../../../assets/images/purple_cloud.png';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import { getBonusOffersPath } from '../../../../modules/BonusOffers/navigation.bonusOffers';

const HouseIdBonusOffersSlide: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToOffers = () => propertyId ? navigate(getBonusOffersPath({ propertyId })) : undefined;

  const {
    isDownLg,
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();

  const imageHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownMd, R.always(160)],
    [() => isDownLg, R.always(200)],
    [R.T, R.always(183)],
  ])();

  return (
    <BonusOffersBaseSlideLayout
      sx={{
        position: 'relative',
        background: getColorGradient(theme.palette.common.premiumDark, theme.palette.common.premium),
      }}
      title={t('home:home_bonus_offer_from_house_id_card_title')}
      titleColor={theme.palette.common.white}
      onClick={handleNavigateToOffers}
    >
      <img
        src={PurpleCloudImage}
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: imageHeight,
        }}
      />
      <img
        src={PurpleRocketImage}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          height: imageHeight,
        }}
      />
    </BonusOffersBaseSlideLayout>
  );
};

export default HouseIdBonusOffersSlide;
