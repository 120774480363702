import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BonusOfferEntityPercentDiscount,
  BonusOfferEntityAmountDiscount,
} from '../types.bonusOffers';
import { FCC } from '../../../../../types/common';
import BonusOfferCardBadge from './BonusOfferCardBadge';
import { isTypeOf } from '../../../../../utils/object';
import { formatMoney } from '../../../../../utils/string';

type BonusOfferDiscountCardBadgeProps = {
  discount: BonusOfferEntityPercentDiscount | BonusOfferEntityAmountDiscount | undefined;
};

const BonusOfferDiscountCardBadge: FCC<BonusOfferDiscountCardBadgeProps> = ({
  discount,
}) => {
  const { t } = useTranslation(['bonus_offers']);

  const discountText = discount
    ? isTypeOf<BonusOfferEntityPercentDiscount>(discount, 'percent')
      ? t('bonus_offers:bonus_offers_discount', { percent: discount.percent })
      : t('bonus_offers:bonus_offers_amount_discount', { amount: formatMoney(discount.amount, false) })
    : undefined;

  return discount
    ? (
      <BonusOfferCardBadge variant="red">
        {discountText}
      </BonusOfferCardBadge>
    )
    : null;
};
export default BonusOfferDiscountCardBadge;
