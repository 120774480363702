import React from 'react';
import {
  Grid,
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import { FCC } from '../../../../../types/common';
import HIDTypography from '../../../../../components/HIDTypography';
import SellingProperty from '../../../../../components/icons/SellingProperty';
import SellingPropertyTasksListItem from './SellingPropertyTasksListItem';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyProgressQuery } from '../../../modules/PropertyProgress/api/api.propertyProgress';
import useGetHandlePropertyProgressCardPress from '../../../modules/PropertyProgress/hooks/useGetHandlePropertyProgressCardPress';

const SellingPropertyTasksList: FCC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['selling_property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: propertyProgressSummary } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const propertyProgressCategories = propertyProgressSummary?.categories || [];

  const handlePropertyProgressCardPress = useGetHandlePropertyProgressCardPress();

  return (
    <Card
      sx={{
        padding: theme.spacing(3),
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <SellingProperty size={112} />
        <HIDTypography variant="h6">
          {t('selling_property:selling_property_title')}
        </HIDTypography>
        <HIDTypography variant="body1">
          {t('selling_property:selling_property_description')}
        </HIDTypography>
      </Stack>
      <Grid container spacing={2}>
        {
          propertyProgressCategories.map((category, index) => (
            <Grid
              item
              alignItems="stretch"
              key={category.id}
              sm={6}
              xs={12}
            >
              <SellingPropertyTasksListItem
                category={category}
                count={index + 1}
                onClick={() => handlePropertyProgressCardPress(category)}
              />
            </Grid>
          ))
        }
      </Grid>
    </Card>
  );
};

export default SellingPropertyTasksList;
