import {
  format,
  intervalToDuration,
  isAfter,
  isBefore,
  isValid,
  setHours,
} from 'date-fns';

import {
  getCurrentLanguage,
  getCurrentLocale,
} from './locale';

export enum DateTimeFormats {
  FULL = 'full',
  DATE_ONLY = 'date-only',
  DATE_ONLY_TEXT = 'date-only-text',
  DATE_ONLY_TEXT_SHORT = 'date-only-text-short',
  TIME_ONLY = 'time-only',
  DAY_AND_DATE = 'day-and-date',
  DAY_AND_FULL = 'data-and-full',
  DAY_AND_MONTH = 'data-and-month',
  YEAR_ONLY = 'year-only',
  YEAR_ONLY_SHORT = 'year-only-short',
  MONTH_AND_YEAR = 'mont-and-year',
  MONTH_AND_YEAR_SHORT = 'mont-and-year-short',
  MONTH_SHORT_AND_YEAR_SHORT = 'mont-short-and-year-short',
  MONTH_ONLY_SHORT = 'month-only-short',
  MONTH_ONLY = 'month-only',
  MONTH_FULL = 'month-full',
  DATE_AND_TIME = 'date-and-time',
  DAY_OF_WEEK = 'day-of-week',
  DATE_ONLY_CALENDAR = 'date_only_calendar',
}

export const dateFormatsMap: Record<DateTimeFormats, { en: string, sv: string }> = {
  [DateTimeFormats.FULL]: {
    en: 'dd/MM/y hh:mma',
    sv: 'y-MM-dd HH:mm',
  },
  [DateTimeFormats.DATE_ONLY]: {
    en: 'dd/MM/y',
    sv: 'y-MM-dd',
  },
  [DateTimeFormats.DATE_ONLY_TEXT]: {
    en: 'dd MMMM y',
    sv: 'dd MMMM y',
  },
  [DateTimeFormats.DATE_ONLY_TEXT_SHORT]: {
    en: 'dd MMM yy',
    sv: 'dd MMM yy',
  },
  [DateTimeFormats.TIME_ONLY]: {
    en: 'hh:mma',
    sv: 'HH:mm',
  },
  [DateTimeFormats.DAY_AND_DATE]: {
    en: 'eeee dd MMM',
    sv: 'eeee dd MMM',
  },
  [DateTimeFormats.DAY_AND_FULL]: {
    en: 'EEEE d MMM y \'at\' hh:mm aaa',
    sv: 'EEEE d MMM y \'kl.\'HH:mm',
  },
  [DateTimeFormats.DAY_AND_MONTH]: {
    en: 'dd MMM',
    sv: 'dd MMM',
  },
  [DateTimeFormats.YEAR_ONLY]: {
    en: 'yyyy',
    sv: 'yyyy',
  },
  [DateTimeFormats.YEAR_ONLY_SHORT]: {
    en: 'yy',
    sv: 'yy',
  },
  [DateTimeFormats.MONTH_AND_YEAR]: {
    en: 'MMM yyyy',
    sv: 'MMM yyyy',
  },
  [DateTimeFormats.MONTH_AND_YEAR_SHORT]: {
    en: 'MMM yy',
    sv: 'MMM yy',
  },
  [DateTimeFormats.MONTH_SHORT_AND_YEAR_SHORT]: {
    en: 'MM/yy',
    sv: 'MM/yy',
  },
  [DateTimeFormats.MONTH_ONLY_SHORT]: {
    en: 'MMMMM',
    sv: 'MMMMM',
  },
  [DateTimeFormats.MONTH_ONLY]: {
    en: 'MMM',
    sv: 'MMM',
  },
  [DateTimeFormats.MONTH_FULL]: {
    en: 'MMMM',
    sv: 'MMMM',
  },
  [DateTimeFormats.DATE_AND_TIME]: {
    en: 'dd/MM/y, \'at\' hh:mma',
    sv: 'y-MM-dd, \'kl.\'HH:mm',
  },
  [DateTimeFormats.DAY_OF_WEEK]: {
    en: 'EEEE',
    sv: 'EEEE',
  },
  [DateTimeFormats.DATE_ONLY_CALENDAR]: {
    en: 'y-MM-dd',
    sv: 'y-MM-dd',
  },
};

export const formatDate = (date: Date | undefined, dateTimeFormat = DateTimeFormats.FULL) => {
  if (date === undefined || !isValid(date)) {
    return undefined;
  }

  const defaultFormat = dateFormatsMap[DateTimeFormats.FULL].sv;

  const currentLang = getCurrentLanguage();
  const currentLocale = getCurrentLocale();

  const dateFormat = dateFormatsMap?.[dateTimeFormat][currentLang];

  return format(date, dateFormat || defaultFormat, { locale: currentLocale });
};

export const getDateFormat = (dateTimeFormat: DateTimeFormats) => {
  const currentLang = getCurrentLanguage();
  return dateFormatsMap[dateTimeFormat][currentLang];
};

export const formatDateRange = (from: Date, to: Date) => from.getFullYear() !== to.getFullYear()
  ? `${formatDate(from, DateTimeFormats.DATE_ONLY_TEXT) || ''} – ${formatDate(to, DateTimeFormats.DATE_ONLY_TEXT) || ''}`
  : from.getMonth() !== to.getMonth()
    ? `${formatDate(from, DateTimeFormats.DAY_AND_MONTH) || ''} – ${formatDate(to, DateTimeFormats.DATE_ONLY_TEXT) || ''}`
    : `${format(from, 'dd') || ''} – ${formatDate(to, DateTimeFormats.DATE_ONLY_TEXT) || ''}`;

export const setMidday = (date: Date) => setHours(date, 12);

export const isAfterOrEqual = (date: Date, dateToCompare: Date) => !isBefore(date, dateToCompare);
export const isBeforeOrEqual = (date: Date, dateToCompare: Date) => !isAfter(date, dateToCompare);

export type RemainingTime = {
  unit: 'years' | 'months' | 'days' | 'hours' | 'minutes',
  value: number;
};

export const getRemainingTime = (expiredDate: Date) => {
  const start = new Date();
  const end = expiredDate;

  if (end > start) {
    const {
      days, hours, minutes, months, years,
    } = intervalToDuration({ start, end });

    if (years) {
      return { value: years + (months && months >= 6 ? 1 : 0), unit: 'years' } as RemainingTime;
    }

    if (months) {
      return { value: months + (days && days >= 15 ? 1 : 0), unit: 'months' } as RemainingTime;
    }

    if (days) {
      return { value: days + (hours && hours >= 12 ? 1 : 0), unit: 'days' } as RemainingTime;
    }

    if (hours) {
      return { value: hours + (minutes && minutes >= 30 ? 1 : 0), unit: 'hours' } as RemainingTime;
    }

    if (minutes) {
      return { value: minutes, unit: 'minutes' } as RemainingTime;
    }
  }

  return undefined;
};

export const compareByDate = (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime();
