import { createApi } from '@reduxjs/toolkit/query/react';

import {
  HIDAuthQuery,
} from '../../../api/HIDBaseQuery';
import { HIDApiTags } from '../../../api/HIDApiTags';

const userApiTags = [
  HIDApiTags.USER,
  HIDApiTags.USER_SETTINGS,
  HIDApiTags.USER_STATUS,
  HIDApiTags.USER_STORAGE,
  HIDApiTags.USER_TOKEN_DATA,
  HIDApiTags.SUBSCRIPTION_PLAN_STATUS,
  HIDApiTags.SUBSCRIPTION_PLAN_OFFER,
  HIDApiTags.SUBSCRIPTION,
];

export const userCommonApi = createApi({
  reducerPath: 'user',
  baseQuery: HIDAuthQuery,
  tagTypes: userApiTags,
  endpoints: () => ({}),
});
