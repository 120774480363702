import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Stack } from '@mui/material';
import HIDTypography from '../../../../../../../components/HIDTypography';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';
import { getPropertySpecificationPath } from '../../../../PropertyProgress/navigation.propertyProgress';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDLink from '../../../../../../../components/HIDLink';

const SellingPropertyInfoHousingFacts: FC<PropertyProgressCategorySectionProps> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();
  const { t } = useTranslation(['selling_property']);

  const handleNavigate = () => {
    if (propertyId) {
      navigate(getPropertySpecificationPath({ propertyId }));
    }
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <>
          <HIDTypography variant="body1">
            {t('selling_property:selling_property_information_housing_facts_text')}
          </HIDTypography>
          <Stack
            direction="row"
            justifyContent="flex-end"
          >
            <HIDLink
              label={t('selling_property:selling_property_information_go_to_action_button_label')}
              onClick={handleNavigate}
            />
          </Stack>
        </>
      }
      key={section.id}
      section={section}
    />
  );
};
export default SellingPropertyInfoHousingFacts;
