import React, { ReactNode } from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FCC } from '../types/common';
import HIDTypography from './HIDTypography';
import { getNoWrapStyle } from '../utils/style';

const SelectItem = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

type HIDSelectItemProps = {
  label: string;
  Icon?: React.ElementType;
  count?: number;
  RightIconComponent?: ReactNode;
  isSelected?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

const HIDSelectItem: FCC<HIDSelectItemProps> = ({
  label,
  Icon,
  count,
  RightIconComponent,
  isSelected,
  isLoading,
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const hasCount = Boolean(RightIconComponent || count !== undefined);

  return (
    <SelectItem
      alignItems="center"
      direction="row"
      justifyContent={hasCount ? 'space-between' : 'flex-start'}
      style={style}
      sx={{
        ...(isSelected ? { backgroundColor: theme.palette.grey[200] } : {}),
        ...sx,
      }}
      onClick={isLoading ? undefined : onClick}
    >
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        spacing={1.5}
      >
        {Icon ? <Icon /> : null}
        <HIDTypography isLoading={isLoading} sx={getNoWrapStyle(1)} variant="body1">
          {label}
        </HIDTypography>
      </Stack>
      {
        RightIconComponent || (
          count !== undefined
            ? (
              <Typography
                color={theme.palette.grey[500]}
                variant="subtitle2"
              >
                {t('common:items_count', { count })}
              </Typography>
            )
            : null
        )
      }
    </SelectItem>
  );
};

export default HIDSelectItem;
