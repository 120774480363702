import React, {
  FC,
} from 'react';
import {
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import BonusOffersBaseSlideLayout from './BonusOffersBaseSlideLayout';
import { getMoveInBonusOffersPath } from '../../../../modules/BonusOffers/navigation.bonusOffers';
import BonusOfferFromMoveIn from '../../../../../../assets/images/move_in_bonus_offer.jpg';

const MoveInBonusOffersSlide: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToOffers = () => propertyId ? navigate(getMoveInBonusOffersPath({ propertyId })) : undefined;

  const imageUrl = BonusOfferFromMoveIn;

  return (
    <BonusOffersBaseSlideLayout
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
      }}
      title={t('home:home_bonus_offer_from_move_in_card_title')}
      titleColor={theme.palette.common.white}
      onClick={handleNavigateToOffers}
    />
  );
};

export default MoveInBonusOffersSlide;
