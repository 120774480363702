import * as R from 'ramda';
import {
  RemappedBudgetSubcategory,
  BudgetCategory,
  BudgetTemplateCategory,
  PaymentInterval,
  PaymentType,
  BudgetTemplateSubcategory,
  RemappedBudgetCategory,
  BudgetSubcategory,
  BudgetTemplateType,
} from '@house-id/houseid-types/dist/finances/budgets';

import getCategoryAnnualAmountSum from '../utils/getCategoryAnnualAmountSum';

const useBudgetCategoriesDataMapper = () => {
  const getSubCategoriesOptionsList = (template: BudgetTemplateCategory) =>
    template.hasMultiSubcategories
      ? template.subcategories.map(({ name, id }) => ({ label: name, id })).sort((a, b) => a.label.localeCompare(b.label))
      : undefined;

  const getEmptySubcategoryEntity = (template: BudgetTemplateSubcategory): RemappedBudgetSubcategory => {
    const subcategory = {
      id: template.id,
      name: template.name,
      subcategoryId: template.id,
      description: template.description,
      paymentTypes: template.paymentTypes,
      paymentType: template.paymentTypes[0],
      currency: template.currency,
      type: template.type,
      paymentInterval: PaymentInterval.MONTHLY,
      amount: undefined,
      note: undefined,
      annualConsumption: undefined,
      averagePrice: undefined,
      firstPaymentMonth: 1,
      isNoteSupported: template.isNoteSupported,
      infoText: template.infoText,
      mortgages: template.paymentTypes.includes(PaymentType.MORTGAGE) ? [{
        debt: undefined,
        averageInterestRate: undefined,
        amortization: undefined,
      }] : undefined,
      paymentMonths: template.paymentTypes[0]
        ? template.paymentTypes[0] === PaymentType.AVERAGE
          ? [1, 4, 8, 12].map((n) => ({ month: n, amount: 0 }))
          : R.times((n) => ({ month: n + 1, amount: 0 }), 12)
        : undefined,
    };

    return subcategory;
  };

  const getEmptyCategoryEntity = (template: BudgetTemplateCategory): RemappedBudgetCategory => {
    const defaultSubcategories = template.subcategories.filter((item) => item.type === BudgetTemplateType.DEFAULT);

    return {
      name: template.name,
      categoryId: template.id,
      description: template.description,
      annualAmount: 0,
      hasMultiSubcategories: template.hasMultiSubcategories,
      subcategoriesOptionsList: getSubCategoriesOptionsList(template),
      subcategories: defaultSubcategories.map((item) => getEmptySubcategoryEntity(item)),
    };
  };

  const getSubcategoryEntityWithData = (
    template: BudgetTemplateSubcategory,
    data: BudgetSubcategory,
  ): RemappedBudgetSubcategory => {
    const { value, id } = data;

    const subcategory = {
      id,
      subcategoryId: template.id,
      name: template.name,
      description: template.description,
      type: template.type,
      paymentTypes: template.paymentTypes,
      isNoteSupported: template.isNoteSupported,
      infoText: template.infoText,
      note: undefined,
      ...value,
    };

    return subcategory;
  };

  const getCategoryEntityWithData = (
    template: BudgetTemplateCategory,
    data: BudgetCategory,
  ): RemappedBudgetCategory => {
    const subcategories = data.subcategories.map((item) => {
      const subcategoryTemplate = template.hasMultiSubcategories
        ? template.subcategories.find(({ id }) => id === item.categoryId) || template.subcategories[0]
        : template.subcategories[0];

      const subcategory = getSubcategoryEntityWithData(subcategoryTemplate, item);

      return subcategory;
    });

    return {
      name: template.name,
      categoryId: data.categoryId,
      description: template.description,
      hasMultiSubcategories: template.hasMultiSubcategories,
      annualAmount: getCategoryAnnualAmountSum(subcategories),
      subcategoriesOptionsList: getSubCategoriesOptionsList(template),
      subcategories,
    };
  };

  return {
    getEmptyCategoryEntity,
    getCategoryEntityWithData,
    getEmptySubcategoryEntity,
  };
};

export default useBudgetCategoriesDataMapper;
