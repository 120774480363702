export const ALL_DOCUMENTS_CATEGORY = 'all';

export const DEFAULT_CLASSIFICATION = 'miscellaneous';

export const WARRANTY_CLASSIFICATION = 'warranty';
export const PROTOCOLS_CLASSIFICATION = 'protocol';
export const MAINTENANCE_CLASSIFICATION = 'care_instruction';
export const MANUAL_CLASSIFICATION = 'manual';
export const DRAWINGS_CLASSIFICATION = 'blueprint';
export const SPECIFICATIONS_CLASSIFICATION = 'specification';
