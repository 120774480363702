import * as R from 'ramda';
import {
  PaymentInterval,
  PaymentType,
  RemappedBudgetSubcategory,
} from '@house-id/houseid-types/dist/finances/budgets';

const PaymentIntervalSumMultiplierMap = {
  [PaymentInterval.MONTHLY]: 12,
  [PaymentInterval.QUARTERLY]: 4,
  [PaymentInterval.HALF_YEARLY]: 2,
  [PaymentInterval.ANNUALLY]: 1,
};

const getAnnualAmountForAveragePaymentType = (subcategory: RemappedBudgetSubcategory) => {
  const {
    amount, firstPaymentMonth, paymentInterval, paymentMonths,
  } = subcategory;

  if (!paymentInterval) {
    return 0;
  }

  if (paymentInterval === PaymentInterval.QUARTERLY) {
    return R.sum((paymentMonths || []).map(({ amount }) => amount || 0));
  }

  if (paymentInterval === PaymentInterval.MONTHLY && firstPaymentMonth && firstPaymentMonth > 1) {
    const monthsNum = PaymentIntervalSumMultiplierMap[PaymentInterval.MONTHLY] - (firstPaymentMonth - 1);

    return monthsNum * (amount || 0);
  }

  return (amount || 0) * PaymentIntervalSumMultiplierMap[paymentInterval];
};

const getAnnualAmountForIndividualMonthPaymentType = (subcategory: RemappedBudgetSubcategory) =>
  R.sum((subcategory.paymentMonths || []).map(({ amount }) => amount || 0));

const AnnualAmountSumCalculationMap: Record<PaymentType, (subcategory: RemappedBudgetSubcategory) => number> = {
  [PaymentType.AVERAGE]: getAnnualAmountForAveragePaymentType,
  [PaymentType.INDIVIDUAL]: getAnnualAmountForIndividualMonthPaymentType,
  [PaymentType.MORTGAGE]: getAnnualAmountForIndividualMonthPaymentType,
  [PaymentType.ELECTRICAL_TRADE]: getAnnualAmountForIndividualMonthPaymentType,
};

const getCategoryAnnualAmountSum = (subcategories: Array<RemappedBudgetSubcategory>) =>
  R.sum(subcategories.map((item) => AnnualAmountSumCalculationMap[item.paymentType](item)));

export default getCategoryAnnualAmountSum;
