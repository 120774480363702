import { t } from 'i18next';
import React, { useState } from 'react';
import { Stack } from '@mui/material';

import HIDButton from '../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../types/common';
import ManageConnections from '../../../../../components/icons/ManageConnections';

export type CreateContentSaveMode = 'add-connections' | 'default';
export type WithSaveMode<T> = T & { saveMode?: CreateContentSaveMode };

type CreateContentPageBottomToolbarProps = {
  showAddConnections?: boolean;
  loading: boolean;
  disabled?: boolean;
  saveButtonLabel?: string;
  onCancel: () => void;
  onSave: (saveMode: CreateContentSaveMode) => void;
};

const CreateContentPageBottomToolbar: FCC<CreateContentPageBottomToolbarProps> = ({
  showAddConnections = false,
  loading,
  disabled,
  saveButtonLabel,
  onCancel,
  onSave,
  sx,
}) => {
  const [saveMode, setSaveMode] = useState<CreateContentSaveMode | undefined>();

  const handleSave = (entitySaveMode: CreateContentSaveMode = 'default') => {
    setSaveMode(entitySaveMode);
    onSave(entitySaveMode);
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
      spacing={2}
      sx={sx}
    >
      <HIDButton color="secondary" size="medium" onClick={onCancel}>
        {t('common:cancel')}
      </HIDButton>
      {showAddConnections && (
        <HIDButton
          Icon={ManageConnections}
          color="primary"
          disabled={disabled}
          loading={loading && saveMode === 'add-connections'}
          size="medium"
          onClick={() => handleSave('add-connections')}
        >
          {saveButtonLabel || t('common:save')}
        </HIDButton>
      )}
      <HIDButton
        color="primary"
        disabled={disabled}
        loading={loading && saveMode === 'default'}
        size="medium"
        onClick={() => handleSave()}
      >
        {saveButtonLabel || t('common:save')}
      </HIDButton>
    </Stack>
  );
};

export default CreateContentPageBottomToolbar;
