import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';
import {
  SellingPropertyArchiveData,
  SellingPropertyTransferData,
  SellingPropertyInformationData,
} from '../types.sellingProperty';

export const sellingPropertyApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSellingPropertyTransfer: builder.query<SellingPropertyTransferData, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/seller/transfer`,
      providesTags: () => [HIDApiTags.SELLING_PROPERTY_TRANSFER],
    }),
    getPropertyDataFromRealEstateAgency: builder.query<SellingPropertyInformationData, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/seller/realtor`,
    }),
    getPropertyArchiveData: builder.query<SellingPropertyArchiveData, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/progress/seller/archive`,
      providesTags: () => [HIDApiTags.SELLING_PROPERTY_ARCHIVE],
    }),
  }),
});

export const {
  useGetSellingPropertyTransferQuery,
  useGetPropertyDataFromRealEstateAgencyQuery,
  useGetPropertyArchiveDataQuery,
} = sellingPropertyApi;
