import React from 'react';
import { FCC } from '../../types/common';

const VerticalOvalImageCut: FCC<{ color: string }> = ({ color, style }) => (
  <svg
    fill="none"
    style={{
      position: 'absolute',
      top: 0,
      left: -1,
      height: '100%',
      ...style,
    }}
    viewBox="0 0 0.075 1"
  >
    <path
      d="M 0.075 1 C -0.02 0.6 -0.02 0.4 0.075 0 L 0 0 L 0 1 Z"
      fill={color}
    />
  </svg>
);

export default VerticalOvalImageCut;
