import React, { FC } from 'react';
import { t } from 'i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import {
  PropertyProgressCategorySectionProps,
  PropertyVarudeklareratInsuranceSectionData,
} from '../../../types.propertyProgress';
import { openUrlNewTab } from '../../../../../../../utils/file';
import VarudeklareradPropertyInsurance from
  '../../../../Content/modules/Insurance/components/varudeklareradPropertyInsurance/VarudeklareradPropertyInsurance';
import {
  ANTICIMEX_REPORT_URL,
  SF_READ_MORE_INSURANCE_URL,
} from '../../../../Content/modules/Insurance/constants.insurance';
import useGetLogAnalyticsEvent from '../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import {
  AnalyticsEvent,
  PartnersSource,
  EventReportSource,
} from '../../../../../../Analytics/types.analytics';

const PropertyVarudeklareratInsuranceSection: FC<PropertyProgressCategorySectionProps<PropertyVarudeklareratInsuranceSectionData>> = ({
  section,
}) => {
  const {
    type,
    documents,
    expirationDate,
    externalDocuments,
  } = section.data;
  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const handleAddInsurance = () => {
    openUrlNewTab(ANTICIMEX_REPORT_URL);
    logAnalyticsEvent(
      {
        event: AnalyticsEvent.REPORT_DAMAGE_INSURANCE,
        source: PartnersSource.ANTICIMEX,
        hidCategory: EntityType.INSURANCE,
        eventReportSource: EventReportSource.ALL,
      },
    );
  };

  const handleReadMore = () => {
    openUrlNewTab(SF_READ_MORE_INSURANCE_URL);
    logAnalyticsEvent(
      {
        event: AnalyticsEvent.READ_MORE_VARUDEKLARERAT,
        source: PartnersSource.SF,
        hidCategory: EntityType.INSURANCE,
        eventReportSource: EventReportSource.ALL,
      },
    );
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('insurances:insurance_report_error_or_damage')}
          viewAllButtonText={t('insurances:insurance_read_more_about_varudeklarerat')}
          onAddClick={handleAddInsurance}
          onViewAllClick={handleReadMore}
        >
          <VarudeklareradPropertyInsurance
            documents={documents}
            expirationDate={expirationDate}
            externalDocuments={externalDocuments}
            insuranceType={type}
          />
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default PropertyVarudeklareratInsuranceSection;
