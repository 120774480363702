import { HIDBlob } from '@house-id/houseid-types/dist/common';
import { resolveImageUrl } from './env';

export const downloadFile = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.setAttribute('href', url);
  if (fileName) {
    link.setAttribute('download', fileName);
  }
  link.click();
  document.body.removeChild(link);
};

export const downloadBlobs = (blobs: Array<HIDBlob>) => {
  const urls = blobs
    .map((blob) => blob.downloadUrl)
    .filter(Boolean);

  urls.forEach((url, index) => {
    setTimeout(() => downloadFile(resolveImageUrl(url)), index * 500);
  });
};
