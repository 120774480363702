import { PropertyProgressSection } from '@house-id/houseid-types/dist/propertyProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyType } from '@house-id/houseid-types/dist/property';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { FCC } from '../../../../../../../../types/common';
import BuildingEntitiesSection from './BuildingEntitiesSection';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import useGetCurrentProperty from '../../../../../../hooks/useCurrentProperty';
import HouseProfilingImage from '../../../../../../../../assets/images/configure_house_1.png';
import ApartmentProfilingImage from '../../../../../../../../assets/images/configure_apartment_1.png';
import { getPropertySpecificationDeepLink } from '../../../../../../../../utils/links';

type PropertyProductTypesSectionProps = {
  section: PropertyProgressSection;
};

const PropertyStructureBuildingsSection: FCC<PropertyProductTypesSectionProps> = ({
  section,
  style,
}) => {
  const { t } = useTranslation(['property_progress', 'buildings']);
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const { data: currentProperty } = useGetCurrentProperty();

  const handleOpenMobileAppDialog = () => openMobileAppDialog({
    unavailableFeature: EntityType.BUILDING,
    linkExtractor: getPropertySpecificationDeepLink,
  });

  const handleOpen = handleOpenMobileAppDialog;

  const imagePath = currentProperty?.type === PropertyType.HOUSE ? HouseProfilingImage : ApartmentProfilingImage;

  return (
    <BuildingEntitiesSection
      buildingEntityType={EntityType.BUILDING}
      description={t('property_progress:property_progress_housing_facts_buildings_description')}
      emptyStateAddText={t('buildings:property_structure_configure_house_structure_step_two_intro_title')}
      emptyStateDescription={t('buildings:property_structure_configure_house_structure_step_two_intro_description')}
      emptyStateImage={imagePath}
      emptyStateTitle={t('buildings:property_structure_configure_house_structure_step_two_intro_title')}
      parentRequired={false}
      section={section}
      showAllText={t('property_progress:property_progress_housing_facts_buildings_show_all_text')}
      style={style}
      onConfigure={handleOpen}
      onShowAll={handleOpen}
    />
  );
};

export default PropertyStructureBuildingsSection;
