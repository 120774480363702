import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Crown: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M3 9.53238C3 7.97779 4.69594 7.01756 6.02899 7.81739L6.75056 8.25034C7.49519 8.69711 8.44304 8.61234 9.09656 8.04051L10.683 6.65238C11.437 5.99259 12.563 5.99259 13.317 6.65238L14.9034 8.04051C15.557 8.61234 16.5048 8.69712 17.2494 8.25034L17.971 7.8174C19.3041 7.01757 21 7.97779 21 9.53238V16C21 17.1046 20.1046 18 19 18H5C3.89543 18 3 17.1046 3 16V9.53238Z"
        fill={fillColor}
      />
      <path
        clipRule="evenodd"
        d="M3.5 16.5V6.42857L8.22222 9.67857L12 5.5L15.7778 9.67857L20.5 6.42857V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H5.5C4.39543 18.5 3.5 17.6046 3.5 16.5Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Crown;
