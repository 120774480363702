import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';

const AppStoreLabel: Icon = ({
  iconColor: colorProp,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 136 41"
      width="136"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M124.749 0.50013H11.2566C10.8429 0.50013 10.4342 0.50013 10.0216 0.50213C9.67621 0.50413 9.33358 0.50994 8.98488 0.51483C8.22733 0.522732 7.47157 0.581806 6.72416 0.69154C5.9778 0.803663 5.25482 1.01505 4.57967 1.31854C3.90534 1.6246 3.2892 2.0223 2.75357 2.4972C2.21514 2.97077 1.76632 3.51815 1.42436 4.11829C1.08145 4.71724 0.843683 5.35907 0.719258 6.02161C0.593649 6.68332 0.526059 7.35265 0.517092 8.02361C0.506622 8.33021 0.505517 8.63783 0.5 8.94447V32.0587C0.505517 32.3692 0.506622 32.67 0.517092 32.9806C0.526061 33.6516 0.593652 34.3209 0.719258 34.9825C0.843339 35.6455 1.08112 36.2877 1.42436 36.8868C1.76616 37.485 2.21505 38.0302 2.75357 38.5011C3.28717 38.9781 3.90371 39.3761 4.57967 39.6798C5.25481 39.9841 5.97772 40.1968 6.72416 40.3106C7.4717 40.4195 8.22738 40.4786 8.98488 40.4874C9.33358 40.4942 9.67621 40.4981 10.0216 40.4981C10.4342 40.5001 10.843 40.5001 11.2566 40.5001H124.749C125.155 40.5001 125.567 40.5001 125.972 40.4981C126.316 40.4981 126.669 40.4942 127.012 40.4874C127.768 40.4791 128.523 40.42 129.269 40.3106C130.018 40.196 130.743 39.9834 131.421 39.6798C132.097 39.3759 132.713 38.978 133.246 38.5011C133.783 38.0284 134.233 37.4836 134.579 36.8868C134.92 36.2872 135.155 35.6451 135.277 34.9825C135.403 34.3208 135.473 33.6516 135.487 32.9806C135.491 32.67 135.491 32.3692 135.491 32.0587C135.5 31.6954 135.5 31.3341 135.5 30.9649V10.0363C135.5 9.67005 135.5 9.30677 135.491 8.94447C135.491 8.63783 135.491 8.33021 135.487 8.02357C135.473 7.35255 135.403 6.68337 135.277 6.02157C135.155 5.35941 134.919 4.71763 134.579 4.11825C133.883 2.91533 132.778 1.93616 131.421 1.31845C130.743 1.0157 130.018 0.80437 129.269 0.69145C128.523 0.581233 127.769 0.522138 127.012 0.51469C126.669 0.50981 126.316 0.50395 125.972 0.502C125.567 0.5 125.155 0.5 124.749 0.5V0.50013Z" fill="#595959" />
      <path d="M9.93001 39.6281C9.58569 39.6281 9.24969 39.6242 8.90807 39.6174C8.20037 39.6093 7.49433 39.5547 6.79574 39.4543C6.14433 39.355 5.5133 39.1703 4.92345 38.9063C4.339 38.6444 3.80594 38.3011 3.34469 37.8895C2.87676 37.4826 2.48718 37.0108 2.1914 36.4927C1.89236 35.9711 1.68541 35.4121 1.57775 34.8351C1.46149 34.2151 1.39859 33.5882 1.38959 32.9597C1.38243 32.7488 1.37305 32.0464 1.37305 32.0464V8.94104C1.37305 8.94104 1.38304 8.24949 1.38965 8.04631C1.39827 7.41886 1.4608 6.7929 1.57671 6.17385C1.68457 5.59525 1.89168 5.03463 2.19088 4.5114C2.48557 3.99361 2.87299 3.52143 3.3381 3.11316C3.80269 2.70102 4.33745 2.35593 4.92289 2.09048C5.51139 1.82731 6.14123 1.64391 6.79132 1.54642C7.49221 1.44497 8.2007 1.39012 8.91085 1.38233L9.93056 1.37012H126.07L127.102 1.38282C127.806 1.39022 128.508 1.44458 129.202 1.54544C129.859 1.64415 130.495 1.82883 131.09 2.09341C132.263 2.62837 133.218 3.47472 133.82 4.51384C134.114 5.03346 134.318 5.58951 134.424 6.16311C134.542 6.78724 134.607 7.41812 134.621 8.05071C134.624 8.33397 134.624 8.63824 134.624 8.94104C134.633 9.31612 134.633 9.67312 134.633 10.0331V30.9661C134.633 31.3295 134.633 31.6841 134.624 32.0415C134.624 32.3668 134.624 32.6648 134.62 32.9714C134.607 33.5927 134.542 34.2123 134.427 34.8253C134.321 35.4065 134.115 35.9699 133.816 36.4957C133.518 37.0082 133.131 37.4759 132.669 37.8817C132.207 38.2955 131.673 38.6409 131.087 38.9044C130.494 39.1704 129.858 39.3558 129.202 39.4543C128.504 39.5553 127.797 39.6098 127.09 39.6174C126.759 39.6242 126.412 39.6281 126.076 39.6281L124.85 39.6301L9.93001 39.6281Z" fill="black" />
      <path d="M33.2973 20.8008C33.3081 19.9662 33.5298 19.1478 33.9418 18.4218C34.3538 17.6959 34.9428 17.0859 35.6539 16.6488C35.2021 16.0036 34.6062 15.4727 33.9134 15.0981C33.2205 14.7236 32.4499 14.5157 31.6627 14.4911C29.9835 14.3148 28.3555 15.4959 27.4998 15.4959C26.6275 15.4959 25.31 14.5086 23.8913 14.5378C22.9736 14.5674 22.0793 14.8343 21.2954 15.3123C20.5115 15.7904 19.8649 16.4633 19.4184 17.2656C17.4844 20.6141 18.927 25.5351 20.7796 28.2417C21.7065 29.5671 22.7898 31.0475 24.2072 30.995C25.5943 30.9375 26.1123 30.1105 27.7866 30.1105C29.4454 30.1105 29.9314 30.995 31.3776 30.9616C32.866 30.9375 33.8038 29.6304 34.6981 28.2925C35.3641 27.3481 35.8766 26.3044 36.2166 25.2C35.3518 24.8343 34.6139 24.2221 34.0948 23.4398C33.5757 22.6575 33.2983 21.7397 33.2973 20.8008Z" fill={color} />
      <path d="M30.5659 12.7108C31.3774 11.7366 31.7772 10.4844 31.6804 9.22021C30.4406 9.35044 29.2953 9.94299 28.4728 10.8798C28.0707 11.3375 27.7627 11.8699 27.5664 12.4467C27.3702 13.0235 27.2896 13.6333 27.3291 14.2412C27.9493 14.2476 28.5628 14.1132 29.1234 13.8481C29.6841 13.583 30.1773 13.1942 30.5659 12.7108Z" fill={color} />
      <path d="M50.8311 27.6397H46.0977L44.9609 30.9961H42.9561L47.4395 18.5781H49.5225L54.0059 30.9961H51.9668L50.8311 27.6397ZM46.5879 26.0908H50.3399L48.4903 20.6436H48.4385L46.5879 26.0908Z" fill={color} />
      <path d="M63.6885 26.4699C63.6885 29.2834 62.1826 31.091 59.9102 31.091C59.3345 31.1211 58.762 30.9885 58.2582 30.7084C57.7544 30.4283 57.3397 30.0119 57.0616 29.507H57.0186V33.9914H55.1602V21.9426H56.959V23.4485H56.9932C57.2841 22.946 57.7059 22.5318 58.2135 22.2499C58.7211 21.9681 59.2957 21.8291 59.876 21.8479C62.1738 21.8479 63.6885 23.6643 63.6885 26.4699ZM61.7783 26.4699C61.7783 24.6369 60.8311 23.4319 59.3858 23.4319C57.9658 23.4319 57.0108 24.6623 57.0108 26.4699C57.0108 28.2942 57.9658 29.5158 59.3858 29.5158C60.8311 29.5158 61.7783 28.3196 61.7783 26.4699Z" fill={color} />
      <path d="M73.6533 26.4699C73.6533 29.2834 72.1475 31.091 69.875 31.091C69.2994 31.1211 68.7269 30.9885 68.2231 30.7084C67.7193 30.4283 67.3046 30.0119 67.0264 29.507H66.9834V33.9914H65.125V21.9426H66.9238V23.4484H66.958C67.249 22.946 67.6707 22.5318 68.1783 22.2499C68.686 21.9681 69.2605 21.8291 69.8408 21.8479C72.1387 21.8479 73.6533 23.6643 73.6533 26.4699ZM71.7432 26.4699C71.7432 24.6369 70.7959 23.4318 69.3506 23.4318C67.9307 23.4318 66.9756 24.6623 66.9756 26.4699C66.9756 28.2942 67.9307 29.5158 69.3506 29.5158C70.7959 29.5158 71.7432 28.3195 71.7432 26.4699H71.7432Z" fill={color} />
      <path d="M80.2393 27.5362C80.377 28.7676 81.5733 29.5762 83.208 29.5762C84.7744 29.5762 85.9014 28.7676 85.9014 27.6572C85.9014 26.6934 85.2217 26.1162 83.6123 25.7207L82.003 25.333C79.7227 24.7822 78.6641 23.7158 78.6641 21.9854C78.6641 19.8428 80.5313 18.3711 83.1826 18.3711C85.8066 18.3711 87.6055 19.8428 87.666 21.9854H85.79C85.6777 20.7461 84.6533 19.9981 83.1563 19.9981C81.6592 19.9981 80.6348 20.7549 80.6348 21.8565C80.6348 22.7344 81.2891 23.251 82.8897 23.6465L84.2578 23.9824C86.8057 24.5849 87.8643 25.6084 87.8643 27.4248C87.8643 29.748 86.0137 31.2031 83.0703 31.2031C80.3164 31.2031 78.457 29.7822 78.3369 27.5361L80.2393 27.5362Z" fill={color} />
      <path d="M91.875 19.7998V21.9424H93.5967V23.4141H91.875V28.4053C91.875 29.1807 92.2197 29.542 92.9766 29.542C93.1809 29.5384 93.385 29.5241 93.5879 29.499V30.9619C93.2476 31.0255 92.9018 31.0543 92.5557 31.0478C90.7227 31.0478 90.0078 30.3593 90.0078 28.6035V23.4141H88.6914V21.9424H90.0078V19.7998H91.875Z" fill={color} />
      <path d="M94.5938 26.4697C94.5938 23.6211 96.2715 21.8311 98.8877 21.8311C101.513 21.8311 103.183 23.6211 103.183 26.4697C103.183 29.3262 101.521 31.1084 98.8877 31.1084C96.2548 31.1084 94.5938 29.3262 94.5938 26.4697ZM101.289 26.4697C101.289 24.5156 100.394 23.3623 98.8877 23.3623C97.3818 23.3623 96.4873 24.5244 96.4873 26.4697C96.4873 28.4316 97.3818 29.5762 98.8877 29.5762C100.394 29.5762 101.289 28.4316 101.289 26.4697H101.289Z" fill={color} />
      <path d="M104.715 21.9425H106.487V23.4835H106.53C106.65 23.0022 106.932 22.5769 107.329 22.279C107.725 21.9811 108.212 21.8288 108.708 21.8478C108.922 21.847 109.136 21.8703 109.345 21.9171V23.6554C109.074 23.5728 108.792 23.5348 108.51 23.5431C108.24 23.5321 107.971 23.5797 107.721 23.6826C107.471 23.7855 107.246 23.9412 107.062 24.1391C106.878 24.337 106.739 24.5724 106.655 24.8291C106.571 25.0857 106.543 25.3576 106.573 25.6261V30.9962H104.715L104.715 21.9425Z" fill={color} />
      <path d="M117.913 28.3369C117.663 29.9805 116.062 31.1084 114.015 31.1084C111.381 31.1084 109.746 29.3437 109.746 26.5127C109.746 23.6729 111.39 21.8311 113.937 21.8311C116.441 21.8311 118.017 23.5518 118.017 26.2969V26.9336H111.622V27.0459C111.593 27.3791 111.634 27.7148 111.744 28.0306C111.855 28.3464 112.031 28.6352 112.261 28.8778C112.491 29.1203 112.771 29.3111 113.081 29.4374C113.39 29.5637 113.723 29.6226 114.058 29.6103C114.497 29.6515 114.937 29.5498 115.314 29.3203C115.691 29.0909 115.984 28.746 116.148 28.3369L117.913 28.3369ZM111.631 25.6348H116.157C116.174 25.3352 116.128 25.0354 116.024 24.7541C115.919 24.4729 115.757 24.2164 115.549 24.0006C115.34 23.7849 115.09 23.6145 114.812 23.5003C114.535 23.3861 114.237 23.3305 113.937 23.3369C113.634 23.3351 113.334 23.3933 113.054 23.508C112.774 23.6227 112.519 23.7918 112.305 24.0054C112.091 24.2191 111.921 24.473 111.805 24.7527C111.689 25.0323 111.63 25.3321 111.631 25.6348V25.6348Z" fill={color} />
      <path d="M46.3545 9.23089C46.7441 9.20293 47.1351 9.26179 47.4992 9.40323C47.8633 9.54466 48.1915 9.76514 48.4601 10.0488C48.7286 10.3324 48.9309 10.6721 49.0523 11.0434C49.1737 11.4146 49.2112 11.8082 49.1621 12.1957C49.1621 14.102 48.1318 15.1977 46.3545 15.1977H44.1992V9.23089H46.3545ZM45.126 14.3539H46.251C46.5294 14.3705 46.808 14.3249 47.0665 14.2203C47.3251 14.1157 47.557 13.9547 47.7455 13.7491C47.934 13.5436 48.0743 13.2986 48.1562 13.032C48.238 12.7653 48.2594 12.4838 48.2187 12.2079C48.2564 11.9331 48.2329 11.6533 48.1498 11.3886C48.0667 11.124 47.926 10.881 47.738 10.677C47.5499 10.4731 47.3191 10.3133 47.062 10.2091C46.8049 10.1048 46.528 10.0587 46.251 10.0741H45.126V14.3539Z" fill={color} />
      <path d="M50.2093 12.9443C50.181 12.6484 50.2148 12.3498 50.3086 12.0677C50.4025 11.7857 50.5542 11.5263 50.7542 11.3063C50.9541 11.0864 51.1978 10.9106 51.4697 10.7903C51.7415 10.67 52.0355 10.6079 52.3328 10.6079C52.6301 10.6079 52.9241 10.67 53.1959 10.7903C53.4678 10.9106 53.7115 11.0864 53.9114 11.3063C54.1114 11.5263 54.2631 11.7857 54.357 12.0677C54.4508 12.3498 54.4846 12.6484 54.4563 12.9443C54.4852 13.2405 54.4517 13.5395 54.3581 13.822C54.2645 14.1045 54.1129 14.3644 53.9129 14.5848C53.7129 14.8052 53.469 14.9813 53.1969 15.1019C52.9248 15.2224 52.6304 15.2847 52.3328 15.2847C52.0352 15.2847 51.7408 15.2224 51.4687 15.1019C51.1966 14.9813 50.9527 14.8052 50.7527 14.5848C50.5527 14.3644 50.4011 14.1045 50.3075 13.822C50.2139 13.5395 50.1804 13.2405 50.2093 12.9443ZM53.5423 12.9443C53.5423 11.9682 53.1038 11.3974 52.3343 11.3974C51.5618 11.3974 51.1273 11.9682 51.1273 12.9443C51.1273 13.9282 51.5618 14.4946 52.3343 14.4946C53.1038 14.4946 53.5423 13.9243 53.5423 12.9443H53.5423Z" fill={color} />
      <path d="M60.1016 15.1978H59.1797L58.249 11.8813H58.1787L57.252 15.1978H56.3389L55.0977 10.6948H55.999L56.8057 14.1308H56.8721L57.7979 10.6948H58.6504L59.5762 14.1308H59.6465L60.4492 10.6948H61.3379L60.1016 15.1978Z" fill={color} />
      <path d="M62.3818 10.6949H63.2373V11.4102H63.3037C63.4164 11.1533 63.6064 10.9379 63.8472 10.7941C64.0881 10.6503 64.3679 10.5852 64.6475 10.6079C64.8666 10.5915 65.0866 10.6245 65.2912 10.7046C65.4958 10.7847 65.6797 10.9098 65.8294 11.0707C65.9791 11.2315 66.0907 11.424 66.1559 11.6338C66.2211 11.8436 66.2382 12.0654 66.2061 12.2827V15.1977H65.3174V12.5059C65.3174 11.7823 65.0029 11.4224 64.3457 11.4224C64.1969 11.4155 64.0484 11.4408 63.9104 11.4966C63.7723 11.5525 63.648 11.6375 63.5459 11.7459C63.4437 11.8543 63.3663 11.9834 63.3187 12.1246C63.2712 12.2657 63.2547 12.4154 63.2705 12.5635V15.1978H62.3818L62.3818 10.6949Z" fill={color} />
      <path d="M67.6221 8.93701H68.5107V15.1978H67.6221V8.93701Z" fill={color} />
      <path d="M69.7463 12.9444C69.7181 12.6484 69.7519 12.3499 69.8458 12.0678C69.9397 11.7857 70.0914 11.5263 70.2914 11.3064C70.4914 11.0864 70.7351 10.9106 71.007 10.7903C71.2788 10.67 71.5728 10.6079 71.8701 10.6079C72.1674 10.6079 72.4614 10.67 72.7333 10.7903C73.0052 10.9106 73.2489 11.0864 73.4489 11.3064C73.6488 11.5263 73.8006 11.7857 73.8945 12.0678C73.9883 12.3499 74.0222 12.6484 73.9939 12.9444C74.0227 13.2406 73.9892 13.5396 73.8956 13.8221C73.802 14.1046 73.6503 14.3645 73.4503 14.5849C73.2503 14.8053 73.0064 14.9814 72.7342 15.1019C72.4621 15.2225 72.1678 15.2847 71.8701 15.2847C71.5725 15.2847 71.2782 15.2225 71.006 15.1019C70.7339 14.9814 70.49 14.8053 70.29 14.5849C70.09 14.3645 69.9383 14.1046 69.8446 13.8221C69.751 13.5396 69.7175 13.2406 69.7463 12.9444ZM73.0793 12.9444C73.0793 11.9683 72.6409 11.3975 71.8713 11.3975C71.0989 11.3975 70.6644 11.9683 70.6644 12.9444C70.6644 13.9283 71.0989 14.4947 71.8713 14.4947C72.6409 14.4947 73.0794 13.9244 73.0794 12.9444H73.0793Z" fill={color} />
      <path d="M74.9297 13.9243C74.9297 13.1138 75.5332 12.6465 76.6045 12.5801L77.8242 12.5098V12.1211C77.8242 11.6455 77.5098 11.377 76.9023 11.377C76.4063 11.377 76.0625 11.5591 75.9639 11.8774H75.1035C75.1943 11.104 75.9219 10.6079 76.9434 10.6079C78.0723 10.6079 78.709 11.1699 78.709 12.1211V15.1978H77.8535V14.5649H77.7832C77.6405 14.7919 77.4401 14.977 77.2025 15.1012C76.9648 15.2254 76.6985 15.2843 76.4307 15.2719C76.2416 15.2916 76.0506 15.2714 75.8698 15.2127C75.6891 15.154 75.5226 15.0581 75.3812 14.9311C75.2398 14.8042 75.1265 14.649 75.0488 14.4756C74.971 14.3021 74.9304 14.1144 74.9297 13.9243ZM77.8242 13.5395V13.1631L76.7246 13.2334C76.1045 13.2749 75.8232 13.4858 75.8232 13.8828C75.8232 14.2881 76.1748 14.5239 76.6582 14.5239C76.7999 14.5382 76.943 14.5239 77.079 14.4819C77.215 14.4398 77.3411 14.3707 77.4499 14.2789C77.5587 14.1871 77.6479 14.0743 77.7122 13.9473C77.7765 13.8202 77.8146 13.6816 77.8242 13.5395Z" fill={color} />
      <path d="M79.876 12.9444C79.876 11.5215 80.6074 10.6201 81.7451 10.6201C82.0265 10.6072 82.3058 10.6746 82.5504 10.8145C82.7949 10.9544 82.9945 11.161 83.126 11.4101H83.1924V8.93701H84.0811V15.1978H83.2295V14.4863H83.1592C83.0176 14.7338 82.811 14.9378 82.5617 15.0763C82.3125 15.2148 82.0301 15.2825 81.7451 15.272C80.5996 15.272 79.876 14.3706 79.876 12.9444ZM80.794 12.9444C80.794 13.8994 81.2442 14.4741 81.9971 14.4741C82.7461 14.4741 83.209 13.8911 83.209 12.9483C83.209 12.0098 82.7412 11.4185 81.9971 11.4185C81.249 11.4185 80.7939 11.9971 80.7939 12.9444H80.794Z" fill={color} />
      <path d="M87.7581 12.9443C87.7298 12.6484 87.7636 12.3498 87.8575 12.0677C87.9513 11.7857 88.1031 11.5263 88.303 11.3063C88.503 11.0864 88.7467 10.9106 89.0185 10.7903C89.2904 10.67 89.5844 10.6079 89.8816 10.6079C90.1789 10.6079 90.4729 10.67 90.7447 10.7903C91.0166 10.9106 91.2603 11.0864 91.4602 11.3063C91.6602 11.5263 91.8119 11.7857 91.9058 12.0677C91.9996 12.3498 92.0335 12.6484 92.0052 12.9443C92.034 13.2405 92.0006 13.5395 91.907 13.822C91.8134 14.1045 91.6617 14.3644 91.4617 14.5848C91.2617 14.8052 91.0178 14.9813 90.7457 15.1019C90.4736 15.2224 90.1793 15.2847 89.8816 15.2847C89.584 15.2847 89.2897 15.2224 89.0175 15.1019C88.7454 14.9813 88.5015 14.8052 88.3015 14.5848C88.1015 14.3644 87.9499 14.1045 87.8563 13.822C87.7627 13.5395 87.7292 13.2405 87.7581 12.9443ZM91.0911 12.9443C91.0911 11.9682 90.6526 11.3974 89.8831 11.3974C89.1106 11.3974 88.6761 11.9682 88.6761 12.9443C88.6761 13.9282 89.1107 14.4946 89.8831 14.4946C90.6526 14.4946 91.0911 13.9243 91.0911 12.9443Z" fill={color} />
      <path d="M93.1973 10.6949H94.0527V11.4102H94.1191C94.2318 11.1533 94.4218 10.9379 94.6627 10.7941C94.9035 10.6503 95.1833 10.5852 95.4629 10.6079C95.682 10.5915 95.902 10.6245 96.1066 10.7046C96.3112 10.7847 96.4951 10.9098 96.6448 11.0707C96.7945 11.2315 96.9061 11.424 96.9713 11.6338C97.0365 11.8436 97.0536 12.0654 97.0215 12.2827V15.1977H96.1328V12.5059C96.1328 11.7823 95.8184 11.4224 95.1611 11.4224C95.0124 11.4155 94.8639 11.4408 94.7258 11.4966C94.5878 11.5525 94.4634 11.6375 94.3613 11.7459C94.2592 11.8543 94.1817 11.9834 94.1342 12.1246C94.0866 12.2657 94.0702 12.4154 94.0859 12.5635V15.1978H93.1973V10.6949Z" fill={color} />
      <path d="M102.044 9.57373V10.7153H103.02V11.4639H102.044V13.7793C102.044 14.251 102.238 14.4575 102.681 14.4575C102.794 14.4572 102.907 14.4503 103.02 14.437V15.1772C102.86 15.2058 102.698 15.221 102.536 15.2226C101.548 15.2226 101.154 14.875 101.154 14.0068V11.4638H100.439V10.7153H101.154V9.57373H102.044Z" fill={color} />
      <path d="M104.232 8.93701H105.113V11.4185H105.184C105.302 11.1591 105.497 10.9425 105.743 10.7982C105.988 10.6539 106.273 10.5888 106.557 10.6118C106.775 10.6 106.992 10.6364 107.195 10.7184C107.397 10.8004 107.579 10.9261 107.727 11.0864C107.875 11.2468 107.986 11.4378 108.052 11.646C108.117 11.8541 108.136 12.0742 108.107 12.2905V15.1978H107.218V12.5098C107.218 11.7905 106.883 11.4263 106.255 11.4263C106.102 11.4137 105.949 11.4347 105.805 11.4878C105.661 11.5408 105.531 11.6247 105.423 11.7334C105.315 11.8421 105.232 11.9732 105.18 12.1173C105.128 12.2614 105.108 12.4152 105.121 12.5679V15.1977H104.232L104.232 8.93701Z" fill={color} />
      <path d="M113.29 13.982C113.169 14.3935 112.907 14.7495 112.551 14.9877C112.194 15.2258 111.765 15.331 111.339 15.2847C111.042 15.2926 110.747 15.2357 110.475 15.1182C110.202 15.0007 109.958 14.8253 109.76 14.6041C109.563 14.383 109.415 14.1214 109.328 13.8376C109.242 13.5538 109.218 13.2545 109.258 12.9605C109.219 12.6656 109.243 12.3657 109.33 12.081C109.416 11.7964 109.563 11.5336 109.76 11.3105C109.957 11.0874 110.199 10.9093 110.471 10.7881C110.743 10.6669 111.037 10.6054 111.335 10.608C112.588 10.608 113.343 11.464 113.343 12.878V13.1881H110.164V13.2379C110.15 13.4031 110.171 13.5695 110.225 13.7262C110.279 13.883 110.365 14.0267 110.478 14.1481C110.591 14.2696 110.728 14.3661 110.88 14.4315C111.033 14.4969 111.197 14.5297 111.363 14.5279C111.576 14.5534 111.791 14.5151 111.982 14.4179C112.172 14.3207 112.33 14.169 112.434 13.982L113.29 13.982ZM110.164 12.5308H112.438C112.449 12.3797 112.429 12.2279 112.378 12.0852C112.327 11.9425 112.247 11.812 112.142 11.7021C112.038 11.5923 111.912 11.5055 111.772 11.4473C111.632 11.3891 111.481 11.3609 111.33 11.3643C111.176 11.3624 111.023 11.3913 110.881 11.4492C110.739 11.5072 110.609 11.593 110.501 11.7017C110.392 11.8104 110.306 11.9398 110.248 12.0822C110.191 12.2246 110.162 12.3771 110.164 12.5308H110.164Z" fill={color} />
    </svg>
  );
};

export default AppStoreLabel;
