import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetBuildingEntitiesQuery } from '../api/api.buildings';
import { QueryHook } from '../../../../../types/common';
import { BuildingShortEntity } from '../types.buildings';
import { MAIN_BUILDING_TEMPLATE_ID } from '../constants.buildings';

type UseGetMainOrFirstBuilding = QueryHook<BuildingShortEntity | undefined>;

const useGetMainOrFirstBuilding: UseGetMainOrFirstBuilding = (_args, options) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: buildings = [],
    ...rest
  } = useGetBuildingEntitiesQuery(
    propertyId ? { propertyId, buildingEntityType: EntityType.BUILDING } : skipToken,
    options,
  );

  const mainOrFirstBuilding = buildings?.find(({ templateId }) => templateId === MAIN_BUILDING_TEMPLATE_ID)
    || R.head(buildings);

  return {
    data: mainOrFirstBuilding,
    ...rest,
  };
};

export default useGetMainOrFirstBuilding;
