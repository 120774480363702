import { useNavigate } from 'react-router';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { getCreateDocumentPath } from '../modules/Document/navigation.document';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { EntityUId } from '../types/types.content';
import { getCreateReceiptPath } from '../modules/Receipt/navigation.receipt';
import { getCreateNotePath } from '../modules/Note/navigation.note';
import { getCreatePhotosPath } from '../modules/Photo/navigation.photo';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { getCreateTimelineEventPath } from '../modules/Timeline/navigation.timeline';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

type AddEntityConnectionAction = (connections: Array<EntityUId>, initialData?: object) => void;

type UseGetAddEntityConnectionAction = (entityType: EntityType) => AddEntityConnectionAction;

const useGetAddEntityConnectionAction: UseGetAddEntityConnectionAction = (entityType) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [openSearchProductDialog] = useDialog(DialogNames.SEARCH_PRODUCTS_DIALOG);

  const possibleConnections: Record<string, AddEntityConnectionAction> = {
    [EntityType.DOCUMENT]: (connections: Array<EntityUId>, initialData?: object) => navigate(
      getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId }),
      { state: { connections, initialData } },
    ),
    [EntityType.RECEIPT]: (connections: Array<EntityUId>, initialData?: object) => navigate(
      getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }),
      { state: { connections, initialData } },
    ),
    [EntityType.NOTE]: (connections: Array<EntityUId>, initialData?: object) => navigate(
      getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId }),
      { state: { connections, initialData } },
    ),
    [EntityType.PRODUCT]: (connections: Array<EntityUId>, initialData?: object) => openSearchProductDialog({
      connections,
      initialData,
    }),
    [EntityType.PHOTO]: (connections: Array<EntityUId>, initialData?: object) => navigate(
      getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId }),
      { state: { connections, initialData } },
    ),
    [EntityType.TIMELINE_ENTRY]: (connections: Array<EntityUId>, initialData?: object) => navigate(
      getPathWithPropertyIdOrInit(getCreateTimelineEventPath, { propertyId }),
      { state: { connections, initialData } },
    ),
  };

  return possibleConnections[entityType] || R.always<void>;
};

export default useGetAddEntityConnectionAction;
