import {
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { alpha } from '@mui/material/styles';

import { ColorfulBannerLayout } from '../../../../../components/ColorfulBannerLayout';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import {
  useSetUserSettingsMutation,
  useGetUserSettingsQuery,
} from '../../../../../../Auth/api/api.settings';
import { useGetActiveAuthUser } from '../../../../../../../external-services/firebase';
import { UnavailableFeature } from '../../../../../components/dialogs/OpenMobileAppDialog';

const SellingPropertyHandoverBanner: FC = () => {
  const { t } = useTranslation(['property', 'selling_property']);
  const theme = useTheme();

  const { user: currentUser } = useGetActiveAuthUser();

  const [setUserSettings, { isLoading: isUpdating }] = useSetUserSettingsMutation();

  const {
    data: { propertyTransferBannerDisabled } = {},
    isLoading: isLoadingUserSettings,
  } = useGetUserSettingsQuery(
    currentUser && currentUser.uid
      ? { userUId: currentUser.uid }
      : skipToken,
  );

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const [openConfirmDeleteMessageModal] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const handleHide = () => openConfirmDeleteMessageModal({
    title: t('selling_property:selling_property_delete_banner_title'),
    onYes: () => setUserSettings({ propertyTransferBannerDisabled: true }),
  });

  if (!isLoadingUserSettings && propertyTransferBannerDisabled) {
    return null;
  }

  return (
    <ColorfulBannerLayout
      buttonBgHoverColor={alpha(theme.palette.common.white, 0.2)}
      buttonLabel={t('selling_property:selling_property_handover_banner_button_label')}
      cardBgColor={theme.palette.info.main}
      description={t('selling_property:selling_property_handover_banner_description')}
      isLoading={isUpdating}
      title={t('selling_property:selling_property_handover_banner_title')}
      onClick={() => openMobileAppDialog({
        unavailableFeature: UnavailableFeature.PROPERTY_TRANSFER,
      })}
      onClose={handleHide}
    />
  );
};

export default SellingPropertyHandoverBanner;
