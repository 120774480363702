import {
  Stack,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import * as R from 'ramda';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import {
  DynamicFact,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentFactProps = DynamicFact & DynamicInputCommonFieldProps<string>;

const DynamicContentFact: FC<DynamicContentFactProps> = ({
  title,
  value,
  source,
  unitInfo,
  description,
}) => {
  const units = unitInfo ? unitInfo.abbreviation : '';
  const formattedValue = !R.isNil(value) ? `${value} ${units || ''}`.trim() : '–';

  return (
    <Stack spacing={0.25}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <HIDInfo
          isMarkdown
          description={description}
          iconSize="small"
          label={title}
          labelVariant="body1"
        />
        <Typography variant="body1">
          {formattedValue}
        </Typography>
      </Stack>
      <DynamicContentSource source={source} />
    </Stack>
  );
};

export default DynamicContentFact;
