import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import {
  formatDate,
  DateTimeFormats,
} from '../../../utils/date';
import { formatMoney } from '../../../utils/string';
import {
  useGetFormatMonth,
  useGetRemainingTimeFromDateLabel,
} from '../../../utils/translation';
import {
  SubscriptionPlanOfferPeriodType,
  SubscriptionPlanOptionOfferMddExistingBuySettings,
  SubscriptionPlanOptionOfferMddFutureBuySettings,
  SubscriptionPlanOptionMddBuySettingType,
  SubscriptionPlanOptionOfferMddRegularBuySettings,
  SubscriptionPlanPeriodOptionOffer,
  SubscriptionPlanPeriodOptionOfferType,
  SubscriptionPlanOfferByPeriod,
} from '../types.subscriptionPlans';
import {
  getSubscriptionPlanOptionPeriodOfferPrices,
  getSubscriptionPlanMonths,
} from '../utils.subscriptionPlans';
import useGetCurrentUserSubscriptionPlanId from './useGetCurrentUserSubscriptionPlanId';
import { useGetSubscriptionPlansStaticSettings } from './useGetSubscriptionPlansStaticSettings';
import { SubscriptionPlanInfo } from './useGetSubscriptionPlansSettings';

export type SubscriptionPlanOfferPeriodTexts = {
  bannerText?: string;
  offerDetails?: string;
  shortOfferText?: string;
  disclaimer?: string;
  currentPricePerPeriod: string;
  basePricePerPeriod: string;
};

type SubscriptionPlanOfferBuySettingsTexts = {
  bannerText: unknown;
  offerText: string;
  shortOfferText?: string;
  disclaimer?: string;
};

type UseGetSubscriptionPlanOfferPeriodTextsGetter = () => (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  period: SubscriptionPlanOfferPeriodType,
  subscriptionPlanOffer?: SubscriptionPlanOfferByPeriod
) => SubscriptionPlanOfferPeriodTexts | undefined;

const useGetSubscriptionPlanOfferPeriodTextsGetter: UseGetSubscriptionPlanOfferPeriodTextsGetter = () => {
  const { t } = useTranslation(['subscriptions', 'forms_common', 'common']);
  const { data: { subscriptionPlanId: currentSubscriptionPlanId } = {} } = useGetCurrentUserSubscriptionPlanId();
  const subscriptionPlanSettings = useGetSubscriptionPlansStaticSettings();

  const formatMonth = useGetFormatMonth();
  const getRemainingTimeLabel = useGetRemainingTimeFromDateLabel();

  const formatForNumberMonths = (number: number) => number >= 1
    ? number === 1
      ? t('subscriptions:activate_subscription_for_month_single', { number })
      : t('subscriptions:activate_subscription_for_month_plural', { number })
    : '';

  const getSubscriptionPlanOfferPeriodTexts = (
    subscriptionPlanInfo: SubscriptionPlanInfo,
    period: SubscriptionPlanOfferPeriodType,
    subscriptionPlanOffer?: SubscriptionPlanOfferByPeriod,
  ) => {
    const subscriptionPlanId = subscriptionPlanInfo.id;

    if (subscriptionPlanInfo && currentSubscriptionPlanId) {
      const planOfferPrices = subscriptionPlanId
        ? getSubscriptionPlanOptionPeriodOfferPrices(
          subscriptionPlanInfo,
          period,
          subscriptionPlanOffer,
        )
        : undefined;

      const currentPlanName = subscriptionPlanSettings[currentSubscriptionPlanId].name;

      const periodLabel = period === SubscriptionPlanOfferPeriodType.YEAR
        ? t('common:annually')
        : t('common:per_month').toLowerCase();

      const trialPeriodMonthsLabel = formatMonth(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration));

      const discountPeriodLabel = period === SubscriptionPlanOfferPeriodType.YEAR
        ? t('subscriptions:activate_subscription_yearly')
        : t('subscriptions:activate_subscription_monthly');

      const currentPrice = period === SubscriptionPlanOfferPeriodType.YEAR
        ? formatMoney(planOfferPrices?.currentPricePerYear)
        : formatMoney(planOfferPrices?.currentPricePerMonth);

      const currentPriceWithPeriod = period === SubscriptionPlanOfferPeriodType.YEAR
        ? t('forms_common:per_year', { value: formatMoney(planOfferPrices?.currentPricePerYear) })
        : t('forms_common:per_month', { value: formatMoney(planOfferPrices?.currentPricePerMonth) });

      const basePriceWithPeriod = period === SubscriptionPlanOfferPeriodType.YEAR
        ? t('forms_common:per_year', { value: formatMoney(planOfferPrices?.basePricePerYear) })
        : t('forms_common:per_month', { value: formatMoney(planOfferPrices?.basePricePerMonth) });

      const getTrialOfferText = (
        trialTextKey: string,
        offer: SubscriptionPlanPeriodOptionOffer | undefined,
      ) =>
        offer && offer.config.type === SubscriptionPlanPeriodOptionOfferType.TRIAL
          ? offer.expirationTime
            ? t('subscriptions:subscription_trial_period_expiration', {
              // @ts-ignore
              trialName: t(trialTextKey, { months: formatMonth(getSubscriptionPlanMonths(offer.config.duration)) }),
              daysLeft: getRemainingTimeLabel(
                new Date(offer.expirationTime),
              ),
            })
            // @ts-ignore
            : t(trialTextKey, { months: formatMonth(getSubscriptionPlanMonths(offer.config.duration)) })

          : undefined;

      const getDiscountOfferText = (
        offer: SubscriptionPlanPeriodOptionOffer | undefined,
      ) =>
        offer && offer.config.type === SubscriptionPlanPeriodOptionOfferType.DISCOUNT
          ? offer.expirationTime
            ? t('subscriptions:subscription_discount_with_expiration', {
              percent: offer.config.percent,
              daysLeft: getRemainingTimeLabel(
                new Date(offer.expirationTime),
              ),
            })
            : t('subscriptions:subscription_discount', { percent: offer.config.percent })
          : undefined;

      const getTrialAndDiscountOfferText = (
        trialTextKey: string,
        offer: SubscriptionPlanPeriodOptionOffer | undefined,
      ) => {
        if (offer && offer.config.type === SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT) {
          const trialAndDiscountOfferText = t('subscriptions:subscription_discount_and_trial', {
            // @ts-ignore
            trialName: t(trialTextKey, { months: formatMonth(getSubscriptionPlanMonths(offer.config.duration)) }),
            percent: offer.config.percent,
          });

          return offer.expirationTime
            ? t('subscriptions:subscription_trial_period_expiration', {
              trialName: trialAndDiscountOfferText,
              daysLeft: getRemainingTimeLabel(new Date(offer.expirationTime)),
            })
            : trialAndDiscountOfferText;
        }
        return undefined;
      };

      const buySettingsTexts: SubscriptionPlanOfferBuySettingsTexts | undefined = planOfferPrices?.providerData?.buySettings?.type
        ? match(planOfferPrices?.providerData?.buySettings?.type)
          .with(SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN, () => {
            if (planOfferPrices.providerData) {
              const buySettings = planOfferPrices.providerData.buySettings as SubscriptionPlanOptionOfferMddExistingBuySettings;

              return match(planOfferPrices?.offer?.config?.type)
                .with(SubscriptionPlanPeriodOptionOfferType.DISCOUNT, () => ({
                  bannerText: getDiscountOfferText(planOfferPrices?.offer),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_discount',
                    {
                      basePriceWithPeriod, currentPrice, discountPeriodLabel, periodLabel,
                    },
                  ),
                  disclaimer:
                    planOfferPrices?.offer?.config?.type === SubscriptionPlanPeriodOptionOfferType.DISCOUNT
                      ? t(
                        'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_discount_disclaimer',
                        { percent: planOfferPrices?.offer?.config?.percent, discountPeriodLabel },
                      )
                      : undefined,
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL, () => ({
                  bannerText: getTrialOfferText(
                    'activate_subscription_plan_card_regular_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_existing_plan_payment_per_period_with_trial',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      basePriceWithPeriod,
                      periodLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_trial_disclaimer',
                    { trialPeriodMonthsLabel },
                  ),
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT, () => ({
                  bannerText: getTrialAndDiscountOfferText(
                    'activate_subscription_plan_card_regular_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_existing_plan_payment_per_period_with_trial_and_discount',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      discountedPriceWithPeriod: currentPriceWithPeriod,
                      periodLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_existing_plan_payment_per_period_with_trial_and_discount_disclaimer',
                    { trialPeriodMonthsLabel, discountedPriceWithPeriod: currentPriceWithPeriod },
                  ),
                }))
                .otherwise(() => ({
                  bannerText: undefined,
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_ordinary',
                    { periodLabel },
                  ),
                  disclaimer: undefined,
                }));
            }
          })
          .with(SubscriptionPlanOptionMddBuySettingType.REGULAR, () => {
            if (planOfferPrices.providerData) {
              const buySettings = planOfferPrices.providerData.buySettings as SubscriptionPlanOptionOfferMddRegularBuySettings;

              return match(planOfferPrices?.offer?.config?.type)
                .with(SubscriptionPlanPeriodOptionOfferType.DISCOUNT, () => ({
                  bannerText: getDiscountOfferText(planOfferPrices?.offer),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_discount',
                    {
                      basePriceWithPeriod,
                      currentPrice,
                      discountPeriodLabel,
                      periodLabel,
                    },
                  ),
                  disclaimer:
                    planOfferPrices?.offer?.config?.type === SubscriptionPlanPeriodOptionOfferType.DISCOUNT
                      ? t(
                        'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_discount_disclaimer',
                        { percent: planOfferPrices?.offer?.config?.percent, discountPeriodLabel },
                      )
                      : undefined,
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL, () => ({
                  bannerText: getTrialOfferText(
                    'activate_subscription_plan_card_regular_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_trial',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      basePriceWithPeriod,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  shortOfferText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_trial_short',
                    {
                      trialPeriodMonthsLabel,
                    },
                  ),
                  disclaimer: undefined,
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT, () => ({
                  bannerText: getTrialAndDiscountOfferText(
                    'activate_subscription_plan_card_regular_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_trial_and_discount',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      discountedPriceWithPeriod: currentPriceWithPeriod,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  shortOfferText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_with_trial_and_discount_short',
                    {
                      trialPeriodMonthsLabel,
                      discountedPriceWithPeriod: currentPriceWithPeriod,
                    },
                  ),
                  disclaimer: undefined,
                }))
                .otherwise(() => ({
                  bannerText: undefined,
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_ordinary',
                    {
                      periodLabel,
                    },
                  ),
                  disclaimer: undefined,
                }));
            }
          })
          .with(SubscriptionPlanOptionMddBuySettingType.FUTURE, () => {
            if (planOfferPrices.providerData) {
              const buySettings = planOfferPrices.providerData.buySettings as SubscriptionPlanOptionOfferMddFutureBuySettings;
              return match(planOfferPrices?.offer?.config?.type)
                .with(SubscriptionPlanPeriodOptionOfferType.DISCOUNT, () => ({
                  bannerText: getDiscountOfferText(planOfferPrices?.offer),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_discount',
                    {
                      basePriceWithPeriod,
                      currentPrice,
                      discountPeriodLabel,
                      periodLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer:
                    planOfferPrices?.offer?.config?.type === SubscriptionPlanPeriodOptionOfferType.DISCOUNT
                      ? t(
                        'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_discount_disclaimer',
                        { percent: planOfferPrices?.offer?.config?.percent, discountPeriodLabel },
                      )
                      : undefined,
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL, () => ({
                  bannerText: getTrialOfferText(
                    'activate_subscription_plan_card_future_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_trial',
                    {
                      basePriceWithPeriod,
                      periodLabel,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_trial_disclaimer',
                    { trialPeriodMonthsLabel },
                  ),
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT, () => ({
                  bannerText: getTrialAndDiscountOfferText(
                    'activate_subscription_plan_card_future_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_trial_and_discount',
                    {
                      discountedPriceWithPeriod: currentPriceWithPeriod,
                      periodLabel,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_future_payment_per_period_with_trial_and_discount_disclaimer',
                    { trialPeriodMonthsLabel, discountedPriceWithPeriod: currentPriceWithPeriod },
                  ),
                }))
                .otherwise(() => ({
                  bannerText: undefined,
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_regular_payment_per_period_ordinary',
                    { periodLabel, nextPaymentDate: formatDate(new Date(buySettings.nextCaptureDate), DateTimeFormats.DATE_ONLY) },
                  ),
                  disclaimer: t('subscriptions:activate_subscription_plan_card_regular_payment_per_period_ordinary_disclaimer'),
                }));
            }
          })
          .with(SubscriptionPlanOptionMddBuySettingType.UPGRADE, () => {
            if (planOfferPrices.providerData) {
              const buySettings = planOfferPrices.providerData.buySettings as SubscriptionPlanOptionOfferMddFutureBuySettings;
              return match(planOfferPrices?.offer?.config?.type)
                .with(SubscriptionPlanPeriodOptionOfferType.DISCOUNT, () => ({
                  bannerText: getDiscountOfferText(planOfferPrices?.offer),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_discount',
                    {
                      basePriceWithPeriod,
                      currentPrice,
                      discountPeriodLabel,
                      periodLabel,
                      planName: currentPlanName,
                      nextPaymentDate: formatDate(new Date(buySettings.nextRenewalDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer:
                    planOfferPrices?.offer?.config?.type === SubscriptionPlanPeriodOptionOfferType.DISCOUNT
                      ? t(
                        'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_discount_disclaimer',
                        { percent: planOfferPrices?.offer?.config?.percent, discountPeriodLabel, planName: currentPlanName },
                      )
                      : undefined,
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL, () => ({
                  bannerText: getTrialOfferText(
                    'activate_subscription_plan_card_upgrade_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_trial',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      basePriceWithPeriod,
                      periodLabel,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextRenewalDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_trial_disclaimer',
                    { trialPeriodMonthsLabel },
                  ),
                }))
                .with(SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT, () => ({
                  bannerText: getTrialAndDiscountOfferText(
                    'activate_subscription_plan_card_upgrade_payment_per_period_with_trial_banner',
                    planOfferPrices?.offer,
                  ),
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_trial_and_discount',
                    {
                      trialPrice: formatMoney(0),
                      forFirstMonthsLabel: formatForNumberMonths(getSubscriptionPlanMonths(planOfferPrices?.offer?.config.duration)),
                      discountedPriceWithPeriod: currentPriceWithPeriod,
                      periodLabel,
                      trialPeriodMonthsLabel,
                      nextPaymentDate: formatDate(new Date(buySettings.nextRenewalDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_trial_and_discount_disclaimer',
                    { trialPeriodMonthsLabel, planName: currentPlanName },
                  ),
                }))
                .otherwise(() => ({
                  bannerText: undefined,
                  offerText: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_with_discount',
                    {
                      basePriceWithPeriod,
                      currentPrice,
                      discountPeriodLabel,
                      periodLabel,
                      planName: currentPlanName,
                      nextPaymentDate: formatDate(new Date(buySettings.nextRenewalDate), DateTimeFormats.DATE_ONLY),
                    },
                  ),
                  disclaimer: t(
                    'subscriptions:activate_subscription_plan_card_upgrade_payment_per_period_ordinary_disclaimer',
                    { planName: currentPlanName },
                  ),
                }));
            }
          })
          .otherwise(() => ({
            bannerText: undefined,
            offerText: t('subscriptions:activate_subscription_plan_card_regular_payment_per_period_ordinary', { periodLabel }),
            disclaimer: undefined,
          }))
        : undefined;

      return {
        bannerText: buySettingsTexts?.bannerText as string | undefined,
        basePricePerPeriod: basePriceWithPeriod,
        currentPricePerPeriod: currentPriceWithPeriod,
        offerDetails: buySettingsTexts?.offerText,
        shortOfferText: buySettingsTexts?.shortOfferText,
        disclaimer: buySettingsTexts?.disclaimer,
      };
    }

    return undefined;
  };

  return getSubscriptionPlanOfferPeriodTexts;
};

export default useGetSubscriptionPlanOfferPeriodTextsGetter;
