import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PropertyDocumentsList from '../../../../PropertyProgress/components/PropertyDocumentsList';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import { PropertyProgressCategorySectionProps } from '../../../../PropertyProgress/types.propertyProgress';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { SellingPropertyInformationDocuments } from '../../../types.sellingProperty';

const SellingPropertyInfoSalesDocument: FC<PropertyProgressCategorySectionProps<SellingPropertyInformationDocuments>> = ({
  section,
}) => {
  const { t } = useTranslation(['selling_property']);

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <>
          <HIDTypography variant="body1">
            {t('selling_property:selling_property_information_sales_document_text')}
          </HIDTypography>
          <PropertyDocumentsList
            propertyDocuments={section.data.documents}
          />
        </>
      }
      key={section.id}
      section={section}
    />
  );
};

export default SellingPropertyInfoSalesDocument;
