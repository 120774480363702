import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const SmartphoneValue: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m17.06714,1.90461l-10.09535,0c-1.1151,0 -2.01907,0.90397 -2.01907,2.01908l0,16.1526c0,1.11514 0.90397,2.0191 2.01907,2.0191l10.09535,0c1.11514,0 2.0191,-0.90397 2.0191,-2.0191l0,-4.03813m-7.06674,2.01903l0.01007,0m-4.04827,-4.03813l4.48985,-4.17578l2.76293,2.5697l5.87127,-5.46068m0,0l-3.02862,0m3.02862,0l0,3.02863"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default SmartphoneValue;
