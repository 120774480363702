import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';

export const getForumPath = () => generateRoutePath(RouteNames.FORUM);
export const getPartnersForumPath = () => generateRoutePath(RouteNames.PARTNERS_FORUM);

export const getForumTopicPath = ({ topicId }: { topicId: string }) => generateRoutePath(RouteNames.FORUM_TOPIC, { topicId });
export const getPartnersForumTopicPath = ({ topicId }: { topicId: string }) =>
  generateRoutePath(RouteNames.PARTNERS_FORUM_TOPIC, { topicId });

export const getForumThreadPath = ({ topicId, threadId }: { topicId: string, threadId: string }) =>
  generateRoutePath(RouteNames.FORUM_THREAD, { topicId, threadId });
export const getPartnersForumThreadPath = ({ topicId, threadId }: { topicId: string, threadId: string }) =>
  generateRoutePath(RouteNames.PARTNERS_FORUM_THREAD, { topicId, threadId });

export const getExpertPath = () => generateRoutePath(RouteNames.FORUM_EXPERTS);
export const getPartnersExpertsPath = () => generateRoutePath(RouteNames.PARTNERS_FORUM_EXPERTS);

export const getMyQuestionsPath = () => generateRoutePath(RouteNames.FORUM_MY_QUESTIONS);
export const getMyBookmarksPath = () => generateRoutePath(RouteNames.FORUM_MY_BOOKMARKS);
