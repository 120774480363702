import React, { FC } from 'react';

import * as R from 'ramda';

import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import { EMPTY_VALUE } from '../../../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllDocumentCategoriesQuery } from '../../../api/api.document';
import { HIDDocument } from '../../../types.document';
import BasicInfoSection from '../../../../../components/sections/BasicInfoSection';
import { getDocumentType } from '../../../utils.document';

type DocumentBasicInfoSectionProps = {
  document: HIDDocument;
};

const DocumentBasicInfoSection: FC<DocumentBasicInfoSectionProps> = ({
  document,
}) => {
  const { t } = useTranslation(['documents', 'common']);

  const documentDateFormatted = document.date
    ? formatDate(new Date(document.date), DateTimeFormats.DATE_ONLY)
    : undefined;

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: categories = [] } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const documentType = getDocumentType(categories, document.categoryId, document.classificationType);

  const categoriesMap: Record<string, string> = R.fromPairs(
    R.map((category) => ([category.id, category.name]), categories),
  );

  const fields = [
    { label: t('common:name'), value: document.name || EMPTY_VALUE },
    { label: t('documents:documents_private_document'), value: document.movable ? t('common:yes') : t('common:no') },
    { label: t('common:category'), value: categoriesMap[document.categoryId] || EMPTY_VALUE },
    { label: t('common:type'), value: documentType?.name || EMPTY_VALUE },
    { label: t('common:date'), value: documentDateFormatted || EMPTY_VALUE },
  ];

  return (
    <BasicInfoSection items={fields} />
  );
};

export default DocumentBasicInfoSection;
