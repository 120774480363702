import {
  useMemo,
} from 'react';

import useGetEnum from '../../../../../../../../../hooks/useGetEnum';
import {
  EnumType,
  QueryHook,
} from '../../../../../../../../../types/common';
import { HIDSelectItem } from '../../../../../../../../../components/HIDFormSelect';

const useGetBudgetCategoriesList: QueryHook<Array<HIDSelectItem>> = () => {
  const {
    data: ExpensePaymentCategoryEnum,
    ...rest
  } = useGetEnum(EnumType.ExpensePaymentCategory);

  const categories = useMemo(() => {
    if (ExpensePaymentCategoryEnum) {
      return Object.entries(ExpensePaymentCategoryEnum)
        .map(([id, name]) => ({ id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return [];
  }, [ExpensePaymentCategoryEnum]);

  return {
    data: categories,
    ...rest,
  };
};

export default useGetBudgetCategoriesList;
