import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as R from 'ramda';
import NewFeaturesBigImage from '../../../../../assets/images/new_feature_big.jpg';
import NewFeaturesImage from '../../../../../assets/images/new_feature.jpg';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  useNavigateToMove,
} from '../../../navigation/navigation.property';

const NewFeaturesWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home']);

  const {
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();

  const imageStyles = R.cond([
    [() => isDownSm, R.always({})],
    [() => isDownMd, R.always(
      {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        backgroundImage: `url(${NewFeaturesImage})`,
        height: '100%',
      },
    )],
    [R.T, R.always(
      {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
        backgroundSize: 'cover',
        backgroundImage: `url(${NewFeaturesBigImage})`,
        height: '100%',
        width: '120%',
      },
    )],
  ])();

  const navigateToMove = useNavigateToMove();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.lightest,
        height: '100%',
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        style={imageStyles}
      >
        <Stack spacing={1.5} sx={{ padding: theme.spacing(3), width: isDownSm ? '100%' : 'unset' }}>
          <Stack
            alignSelf="flex-start"
            sx={{
              backgroundColor: theme.palette.warning.main,
              borderRadius: '2px',
              padding: theme.spacing(0, 0.25),
            }}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
            >
              {t('home:home_novelty')}
            </Typography>
          </Stack>
          <Typography variant="h5">
            {t('home:home_new_feature_on_the_web')}
          </Typography>
          <Typography variant="body1">
            {t('home:home_get_the_move_done_quickly_and_easily')}
          </Typography>
          <HIDButton
            sx={{
              alignSelf: isDownSm ? 'center' : 'unset',
            }}
            onClick={navigateToMove}
          >
            {t('home:hone_get_ready_to_move')}
          </HIDButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default NewFeaturesWidget;
