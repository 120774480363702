import {
  useEffect,
  useRef,
  useState,
} from 'react';
import * as R from 'ramda';
import { useTheme } from '@mui/material';
import { throttle } from 'throttle-debounce';

export const useGetBoxShadows = () => {
  const theme = useTheme();

  const topBoxShadow = `0 8px 5px -5px ${theme.palette.grey[300]}`;
  const bottomBoxShadow = `0 -8px 5px -5px ${theme.palette.grey[300]}`;
  const leftBoxShadow = `-8px 0px 5px -5px ${theme.palette.grey[300]}`;
  const rightBoxShadow = `8px 0px 5px -5px ${theme.palette.grey[300]}`;

  const topInsetBoxShadow = `inset 0 8px 5px -5px ${theme.palette.grey[300]}`;
  const bottomInsetBoxShadow = `inset 0 -8px 5px -5px ${theme.palette.grey[300]}`;
  const leftInsetBoxShadow = `inset -8px 0px 5px -5px ${theme.palette.grey[300]}`;
  const rightInsetBoxShadow = `inst 8px 0px 5px -5px ${theme.palette.grey[300]}`;

  return {
    topBoxShadow,
    bottomBoxShadow,
    leftBoxShadow,
    rightBoxShadow,
    topInsetBoxShadow,
    bottomInsetBoxShadow,
    leftInsetBoxShadow,
    rightInsetBoxShadow,
  };
};

type UseScrollWithShadowProps = {
  throttleTime?: number;
  showTop?: boolean;
  showBottom?: boolean;
};

const useScrollWithShadow = ({
  throttleTime = 100,
  showTop = true,
  showBottom = true,
}: UseScrollWithShadowProps | undefined = {}) => {
  const {
    topInsetBoxShadow,
    bottomInsetBoxShadow,
  } = useGetBoxShadows();

  const [scrollContainerProps, setScrollContainerProps] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const componentElement = scrollContainerRef.current;
    if (componentElement) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = componentElement;
      setScrollContainerProps({
        scrollTop,
        scrollHeight,
        clientHeight,
      });
    }
  };

  useEffect(() => {
    const resizeHandler = throttle(throttleTime, handleResize);

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
    if (currentTarget) {
      const { scrollTop, scrollHeight, clientHeight } = currentTarget;

      setScrollContainerProps({
        scrollTop,
        scrollHeight,
        clientHeight,
      });
    }
  };

  const getBoxShadow = () => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
    } = scrollContainerProps;

    const hasVerticalScrollbar = scrollHeight > clientHeight;
    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    const betweenBorders = showTop && showBottom
      ? `${topInsetBoxShadow}, ${bottomInsetBoxShadow}`
      : showTop
        ? topInsetBoxShadow
        : showBottom
          ? bottomInsetBoxShadow
          : 'none';

    return R.cond([
      [() => !hasVerticalScrollbar, R.always('none')],
      [() => isTop && showBottom, R.always(bottomInsetBoxShadow)],
      [() => isBetween, R.always(betweenBorders)],
      [() => isBottom && showTop, R.always(topInsetBoxShadow)],
      [R.T, R.always('none')],
    ])();
  };

  return {
    boxShadow: getBoxShadow(),
    onScrollHandler: throttle(throttleTime, handleScroll),
    scrollContainerRef,
  };
};

export default useScrollWithShadow;
