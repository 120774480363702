import React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import {
  EMPTY_VALUE,
  getUserName,
} from '../../../utils/string';
import { ForumThread } from '../types.forum';
import {
  DateTimeFormats,
  formatDate,
} from '../../../utils/date';
import { capitalize } from '../../../utils/url';
import ThreadViewsAndRepliesCount from '../pages/forumThread/components/ThreadViewsAndRepliesCount';
import { getNoWrapStyle } from '../../../utils/style';
import { useGetForumAuthorName } from '../utils/forumAuthor';
import { useGetForumTopicsQuery } from '../api/api.forum';
import { arrToMap } from '../../../utils/array';

const useGetForumThreadColumns = () => {
  const { t } = useTranslation(['common', 'forms_common', 'forum']);
  const theme = useTheme();

  const getAuthorName = useGetForumAuthorName();

  const { isDownLg, isDownMd, isDownSm } = useBreakPointsSizes();

  const { data: topics = [] } = useGetForumTopicsQuery();
  const topicsMap = arrToMap(({ id, name }) => ([id, name]), topics);

  const columns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: t('common:date'),
      flex: isDownMd ? 0.2 : 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { createdAt } = params?.row as ForumThread;
        return isDownSm
          ? (
            <Stack>
              <Typography noWrap variant="body2">
                {formatDate(new Date(createdAt), DateTimeFormats.DATE_ONLY)}
              </Typography>
              <Typography noWrap variant="body2">
                {formatDate(new Date(createdAt), DateTimeFormats.TIME_ONLY)}
              </Typography>
            </Stack>
          )
          : (
            <Typography sx={getNoWrapStyle(2)} variant="body2">
              {formatDate(new Date(createdAt), DateTimeFormats.DATE_AND_TIME)}
            </Typography>
          );
      },
    },
    {
      field: 'subject',
      headerName: t('forum:forum_question'),
      flex: 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { subject, topicId } = params?.row as ForumThread;
        return (
          <Stack>
            <Typography noWrap variant="body2">
              {subject || EMPTY_VALUE}
            </Typography>
            <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="caption">
              {topicsMap[topicId] || EMPTY_VALUE}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownMd && {
      field: 'author',
      headerName: t('forms_common:name'),
      flex: isDownLg ? 0.25 : 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { author } = params?.row as ForumThread;
        return (
          <Typography noWrap variant="body2">
            {getUserName(getAuthorName(author))}
          </Typography>
        );
      },
    },
    {
      field: 'comments_count',
      headerName: capitalize(t('forum:forum_replies')),
      flex: isDownLg ? 0.2 : 0.22,
      type: 'string',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridRenderCellParams) => {
        const { replyCount, usersReadCount } = params?.row as ForumThread;
        return replyCount + usersReadCount;
      },
      renderCell: (params: GridRenderCellParams) => {
        const { replyCount, usersReadCount } = params?.row as ForumThread;
        return (
          <ThreadViewsAndRepliesCount repliesCount={replyCount} viewsCount={usersReadCount} />
        );
      },
    },
  ].filter(Boolean) as Array<GridColDef>;

  return columns;
};

export default useGetForumThreadColumns;
