import React, { FC } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router';

import { useTranslation } from 'react-i18next';
import HIDHeader from '../../../components/layouts/HIDHeader';
import HIDButton from '../../../components/buttons/HIDButton';
import { getLoginPath } from '../../Auth/navigation/navigation.auth';

const NotAuthorizedHeader: FC = () => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HIDHeader
      RightComponent={
        <HIDButton
          size="small"
          onClick={() => navigate(getLoginPath(), { state: { from: location } })}
        >
          {t('auth:login_login_button')}
        </HIDButton>
      }
      showMenu={false}
    />
  );
};

export default NotAuthorizedHeader;
