import {
  Auth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  User,
} from 'firebase/auth';

import {
  getActiveAuth,
  useGetActiveAuthUser,
} from '../../../external-services/firebase';
import { getVerifyEmailReturnBackUrl } from '../../../utils/links';

function verifyEmail(user: User | undefined, propertyId: string) {
  if (!user) {
    return undefined;
  }

  const actionCodeSettings = {
    url: getVerifyEmailReturnBackUrl(propertyId),
    handleCodeInApp: true,
  };

  return sendEmailVerification(user, actionCodeSettings);
}

function requestEnrollSecondFactor(auth: Auth, user: User | undefined, phoneNumber: string, recaptchaContainerId: string) {
  if (!user) {
    return undefined;
  }

  // Just in case recaptcha was rendered already
  const recaptchaContainer = document.querySelector(`#${recaptchaContainerId}`);
  if (recaptchaContainer && recaptchaContainer.innerHTML) {
    recaptchaContainer.innerHTML = '';
  }

  const recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainerId);

  return multiFactor(user)
    .getSession()
    .then((multiFactorSession) => {
      // Specify the phone number and pass the MFA session.
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      // Send SMS verification code.
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    })
    .finally(() => {
      recaptchaVerifier.clear();
    });
}

function confirmEnrollSecondFactor(user: User | undefined, verificationId: string, verificationCode: string) {
  if (!user) {
    return undefined;
  }

  const mfaDisplayName = 'Phone';

  // Ask user for the verification code. Then:
  const cred = PhoneAuthProvider
    .credential(verificationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  // Complete enrollment.
  return multiFactor(user)
    .enroll(multiFactorAssertion, mfaDisplayName)
    .then(() => user.reload());
}

function unenrollSecondFactor(user: User | undefined) {
  if (!user) {
    return undefined;
  }

  const { enrolledFactors } = multiFactor(user);

  return Promise.all(enrolledFactors.map((factor) => multiFactor(user).unenroll(factor)))
    .then(() => user.reload());
}

export const useHasSecondFactor = () => {
  const { user } = useGetActiveAuthUser();

  if (!user) {
    return false;
  }

  const { enrolledFactors } = multiFactor(user);

  return enrolledFactors.length > 0;
};

const useGetEnrollSecondFactor = () => {
  const auth = getActiveAuth();
  const { user } = useGetActiveAuthUser();

  return {
    verifyEmail: (propertyId: string) => verifyEmail(user, propertyId),
    requestEnrollSecondFactor: (phoneNumber: string, recaptchaContainerId: string) =>
      requestEnrollSecondFactor(auth, user, phoneNumber, recaptchaContainerId),
    confirmEnrollSecondFactor: (verificationId: string, verificationCode: string) =>
      confirmEnrollSecondFactor(user, verificationId, verificationCode),
    removeSecondFactor: () => unenrollSecondFactor(user),
  };
};

export default useGetEnrollSecondFactor;
