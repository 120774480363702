import React, {
  FC,
  useRef,
} from 'react';
import {
  Box,
  Card,
  Grid,
  Stack,
  useTheme,
  Skeleton,
} from '@mui/material';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import {
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../../utils/routes';
import {
  useGetPropertyExternalServicesQuery,
} from '../../api/api.property';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../navigation/navigation.property';
import HomeLayout from '../Home/components/HomeLayout';
import PropertySettingsQuickNavigation, { PropertySettingsActionId } from '../../components/PropertySettingsQuickNavigation';
import ExternalServiceCard, { ExternalServiceCardRef } from './components/ExternalServiceCard';
import { getTypographyHeight } from '../../../../utils/style';
import { FCC } from '../../../../types/common';
import { getPathWithPropertyIdOrInit } from '../../../Auth/navigation/navigation.auth';
import useEntitySuggestionSection from '../../modules/Content/modules/Suggestion/hooks/useEntitySuggestionSection';

type ExternalServicesLoaderSkeletonProps = {
  itemsCount?: number;
  isLoading: boolean;
};

// TODO: remove and move loading logic into render
const ExternalServicesLoaderSkeleton: FCC<ExternalServicesLoaderSkeletonProps> = ({
  itemsCount = 4,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    const height = getTypographyHeight(theme.typography.body2);

    return (
      <Stack spacing={3}>
        {R.range(0, itemsCount).map((i) => (
          <Box
            height={150}
            key={i}
            sx={{
              borderRadius: theme.spacing(1.25),
              backgroundColor: theme.palette.primary.lightest,
              padding: theme.spacing(1.5),
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Skeleton
              height={height}
              sx={{ margin: theme.spacing(2) }}
              variant="rounded"
              width="30%"
            />
            <Skeleton
              height={height}
              sx={{ margin: theme.spacing(2) }}
              variant="rounded"
              width="70%"
            />
            <Stack direction="row-reverse">
              <Skeleton
                height={height + 10}
                sx={{ borderRadius: '20px', marginRight: theme.spacing(2) }}
                variant="rounded"
                width="100px"
              />
            </Stack>
          </Box>
        ))}
      </Stack>
    );
  }

  return children;
};

const ExternalServices: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { id: idFilter, suggestionKey } = useRouteQueryParams<{ id?: string, suggestionKey?: string }>();

  const { isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: externalServices = [], isLoading } = useGetPropertyExternalServicesQuery(propertyId ? { propertyId } : skipToken);

  const filteredExternalServices = idFilter
    ? externalServices.filter(({ id }) => id === idFilter)
    : externalServices;

  const suggestionExternalServiceRef = useRef<ExternalServiceCardRef>();

  const customFieldFocusActions: Record<string, () => void> = {
    connect_service: () => suggestionExternalServiceRef.current?.activate(),
  };

  const {
    SuggestionSection,
    refetchSuggestions,
  } = useEntitySuggestionSection({
    entityId: idFilter,
    entityType: EntityType.EXTERNAL_SERVICE,
    fieldRefs: {},
    customFieldSuggestionAction: customFieldFocusActions,
    initialSuggestion: suggestionKey,
  });

  return (
    <HomeLayout
      BodyLoaderSkeleton={ExternalServicesLoaderSkeleton}
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <PropertySettingsQuickNavigation currentPropertySettingsActionId={PropertySettingsActionId.EXTERNAL_SERVICES} />
          </Card>
          {SuggestionSection}
        </Stack>
      }
      isLoading={isLoading}
      sideDrawerElements={[
        <PropertySettingsQuickNavigation
          currentPropertySettingsActionId={PropertySettingsActionId.EXTERNAL_SERVICES}
          key={PropertySettingsQuickNavigation.name}
        />,
      ]}
      title={t('property:property_settings_external_services_action')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack spacing={2} sx={{ marginTop: isDownMd ? 2 : 0 }}>
        <Grid container rowSpacing={2}>
          {
            filteredExternalServices
              .map((service) => (
                <Grid
                  item
                  key={service.id}
                  xxs={12}
                >
                  <ExternalServiceCard
                    externalService={service}
                    ref={suggestionExternalServiceRef}
                    onExternalServiceSynced={() => refetchSuggestions()}
                  />
                </Grid>
              ))
          }
        </Grid>
      </Stack>
    </HomeLayout>
  );
};

export default ExternalServices;
