import React, { FC } from 'react';
import * as R from 'ramda';
import {
  Grid,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import PropertyProgressContentItem from '../../../components/PropertyProgressContentItem';
import NoContent from '../../../../Content/components/NoContent';
import { getFirstExternalImageUrl } from '../../../../Content/modules/Product/utils.products';
import { PropertyProductType } from '../../../types.propertyProgress';

type PropertyProductsListProps = {
  productType: PropertyProductType;
  hideNoContentPlaceholder?: boolean;
  onClick?: (documentId: string) => void;
};

const PropertyProductsList: FC<PropertyProductsListProps> = ({
  productType,
  hideNoContentPlaceholder = false,
  onClick = R.always,
}) => {
  const { t } = useTranslation(['products']);

  if (hideNoContentPlaceholder && !productType.products.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {productType.products.length
        ? (
          <Grid
            container
            spacing={1.5}
            xxs={12}
          >
            {productType.products
              ?.map((product) => (
                <Grid
                  item
                  key={product.id}
                  sm={productType.products.length > 1 ? 6 : 12}
                  xxs={12}
                >
                  <PropertyProgressContentItem
                    blob={R.head(product.blobs || [])}
                    description={productType.name}
                    externalImage={getFirstExternalImageUrl(product.externalMedia)}
                    key={product.id}
                    title={product.name}
                    onNavigate={() => onClick(product.id)}
                  />
                </Grid>
              ))}
          </Grid>
        )
        : (
          <NoContent
            height={150}
            title={t('products:product_no_products_of_current_type', { typeName: productType.name?.toLocaleLowerCase() })}
          />
        )}
    </Stack>
  );
};
export default PropertyProductsList;
