import React from 'react';
import {
  Typography,
  useTheme,
} from '@mui/material';
import {
  Bar,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import { formatMoney } from '../../../../../../../../../utils/string';
import BaseChartLayout from '../../../../../../../../../components/charts/BaseChartLayout';
import SummaryChartTooltip from '../../../../../../../../../components/charts/SummaryChartTooltip';
import SummaryChartInfo from '../../../../../../../../../components/charts/SummaryChartInfo';
import { FCC } from '../../../../../../../../../types/common';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import StyledChartWrapper from '../../../../../../../../../components/charts/StyledChartWrapper';

export type ReceiptsSummaryChartDataPoint = {
  name: string;
  amount: number;
  deductibleAmount: number;
};

type ReceiptsSummaryChartProps = {
  minHeight?: number;
  isLoading: boolean;
  data: Array<ReceiptsSummaryChartDataPoint>;
  totalAmount?: number;
  totalDeductible?: number;
  totalAmountVisible?: boolean;
  totalDeductibleVisible?: boolean;
};

const ReceiptsSummaryChart: FCC<ReceiptsSummaryChartProps> = ({
  minHeight = 220,
  data,
  isLoading,
  totalAmount = 0,
  totalDeductible = 0,
  totalAmountVisible = false,
  totalDeductibleVisible = false,
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts']);

  const deductibleTooltip = [
    t('receipts:receipts_deductible_tooltip1'),
    t('receipts:receipts_deductible_tooltip2'),
    t('receipts:receipts_deductible_tooltip3'),
  ].join(' ');

  return (
    <StyledChartWrapper sx={sx}>
      <SummaryChartInfo
        InfoLabelComponent={
          <HIDTypography isLoading={isLoading} skeletonWidth="200px" variant="body2">
            {t('common:of_which')}
            {' '}
            <Typography component="span" display="inline" variant="subtitle2">
              {formatMoney(totalDeductible)}
            </Typography>
            {' '}
            {t('receipts:receipts_deductible_label').toLowerCase()}
          </HIDTypography>
        }
        infoDescription={deductibleTooltip}
        isLoading={isLoading}
        label={t('receipts:receipts_price_label')}
        value={formatMoney(totalAmount)}
      />
      <BaseChartLayout
        ChartTooltipComponent={<SummaryChartTooltip />}
        data={data}
        isLoading={isLoading}
        minHeight={minHeight}
      >
        {totalAmountVisible && (
          <Bar
            dataKey="amount"
            fill={theme.palette.primary.main}
            name={t('receipts:receipts_price_label')}
          />
        )}
        {totalDeductibleVisible && (
          <Bar
            dataKey="deductibleAmount"
            fill={theme.palette.warning.main}
            name={t('receipts:receipts_deductible_label')}
          />
        )}
      </BaseChartLayout>
    </StyledChartWrapper>
  );
};

export default ReceiptsSummaryChart;
