import React, {
  FC,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useGetEntityInfo from '../../../../Content/hooks/useGetEntityInfo';
import {
  getCreateTimelineEventPath,
  getTimelinePath,
} from '../../../../Content/modules/Timeline/navigation.timeline';
import { StyledMUITimeline } from '../../../../Content/modules/Timeline/pages/Timeline/Timeline';
import GetStartedWizard from '../../../../Content/modules/Timeline/pages/Timeline/components/GetStartedWizard';
import TimelineItem, { isDateSeparator } from '../../../../Content/modules/Timeline/pages/Timeline/components/TimelineItem';
import { TimelineEvent } from '../../../../Content/modules/Timeline/types.timeline';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';
import {
  PropertyProgressCategorySectionProps,
  PropertyTimelineSectionData,
} from '../../../../PropertyProgress/types.propertyProgress';

const SellingPropertyArchiveTimeline: FC<PropertyProgressCategorySectionProps<PropertyTimelineSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['property', 'selling_property']);
  const [wizardOpen, setWizardOpen] = useState(false);

  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityTypeInfo = useGetEntityInfo();

  const handleCloseWizard = () => setWizardOpen(false);

  const handleAddEvent = () => navigate(getPathWithPropertyIdOrInit(getCreateTimelineEventPath, { propertyId }));
  const handleViewTimeline = () => navigate(getPathWithPropertyIdOrInit(getTimelinePath, { propertyId }));

  const handleViewTimelineItem = (event: TimelineEvent) => {
    if (event.entity) {
      const entityInfo = getEntityTypeInfo(event.entity.type);
      const viewEntityPath = entityInfo
        && entityInfo.getViewLink
        && entityInfo.getViewLink({ propertyId: event.entity.propertyId, id: event.entity.id });

      if (viewEntityPath) {
        navigate(viewEntityPath);
      }
    }
  };

  const events = section.data.events || [];

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_planning_timeline_add_event')}
          description={t('selling_property:selling_property_archive_section_timeline_description')}
          showButtons={Boolean(events.length)}
          viewAllButtonText={t('property:property_progress_property_planning_timeline_view_events')}
          onAddClick={handleAddEvent}
          onViewAllClick={handleViewTimeline}
        >
          <StyledMUITimeline>
            {
              events?.map((event, index) =>
                isDateSeparator(event)
                  ? (
                    <TimelineItem
                      isLast={index === events.length - 1}
                      item={event}
                      key={index}
                    />
                  )
                  : (
                    <TimelineItem
                      isLast={index === events.length - 1}
                      isSelected={false}
                      isSelectionMode={false}
                      item={event}
                      key={index}
                      onClick={() => handleViewTimelineItem(event)}
                    />
                  ))
            }
          </StyledMUITimeline>
          <GetStartedWizard open={wizardOpen} onClose={handleCloseWizard} />
        </PropertyProgressCategorySectionContent>
      }
      section={section}
    />
  );
};

export default SellingPropertyArchiveTimeline;
