import React from 'react';
import {
  Typography,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../types/common';

type BonusOfferCardBadgeProps = {
  variant: 'green' | 'red' | 'black';
};

const BonusOfferCardBadge: FCC<BonusOfferCardBadgeProps> = ({
  sx,
  variant,
  children,
}) => {
  const theme = useTheme();

  const bgColorMap = {
    black: theme.palette.common.black,
    green: theme.palette.primary.main,
    red: theme.palette.error.main,
  };

  return (
    <Typography
      fontWeight="bold"
      sx={{
        padding: theme.spacing(0.2, 1),
        whiteSpace: 'nowrap',
        color: theme.palette.common.white,
        backgroundColor: bgColorMap[variant],
        borderRadius: '5px',
        ...sx,
      }}
      variant="body1"
    >
      {children}
    </Typography>
  );
};

export default BonusOfferCardBadge;
