import {
  MenuItem,
  styled,
} from '@mui/material';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: 300,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export default StyledMenuItem;
