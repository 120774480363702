import React from 'react';
import {
  LinearProgress,
  LinearProgressProps,
  useTheme,
} from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';

import { FCC } from '../../types/common';

type HIDLinearProgressProps = {
  fillColor?: string;
  value: LinearProgressProps['value'],
  variant?: LinearProgressProps['variant'],
};

const HIDLinearProgress: FCC<HIDLinearProgressProps> = ({
  value,
  fillColor,
  variant = 'determinate',
  sx,
}) => {
  const theme = useTheme();

  return (
    <LinearProgress
      sx={{
        width: '100%',
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.primary.lighter,
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: fillColor || theme.palette.primary.main,
        },
        ...sx,
      }}
      value={value}
      variant={variant}
    />
  );
};

export default HIDLinearProgress;
