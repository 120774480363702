import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Wallet: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m19.0606,12.11542l0,-4.03461l-14.12121,0c-0.53502,0 -1.04814,-0.21257 -1.42646,-0.5909c-0.37831,-0.3783 -0.59086,-0.89141 -0.59086,-1.42644m0,0c0,-1.10953 0.90779,-2.01732 2.01732,-2.01732l12.10387,0l0,4.03465m-14.12119,-2.01733l0,12.10389c0,1.10955 0.90779,2.01734 2.01732,2.01734l14.12121,0l0,-4.03468m-2.01734,-4.03461c-0.53499,0 -1.04812,0.21257 -1.42644,0.5909c-0.37832,0.37832 -0.59082,0.89138 -0.59082,1.42644c0,1.10947 0.90779,2.01727 2.01727,2.01727l4.03468,0l0,-4.03461l-4.03468,0z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Wallet;
