import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  PaymentInterval,
  PaymentType,
} from '@house-id/houseid-types/dist/finances/budgets';

import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import {
  useGetBudgetTemplatesQuery,
} from '../../../api/api.budgets';

const useGetBudgetValidationSchema = () => {
  const { t } = useTranslation(['forms_common']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: budgetTemplates,
  } = useGetBudgetTemplatesQuery(propertyId ? { propertyId } : skipToken);

  const categoriesDataValidation: Yup.ObjectShape = Object.keys(budgetTemplates || {}).reduce((acc, id) => {
    acc[id] = Yup.object().shape({
      subcategories: Yup.array().of(
        Yup.object().shape({
          amount: Yup.number().when(['paymentType'], {
            is: (paymentType: PaymentType) => paymentType === PaymentType.AVERAGE,
            then: (sch) => sch.required(t('forms_common:forms_common_required_field_generic')),
            otherwise: (sch) => sch.notRequired(),
          }),
          annualConsumption: Yup.number().when(['paymentType'], {
            is: (paymentType: PaymentType) => paymentType === PaymentType.ELECTRICAL_TRADE,
            then: (sch) => sch.required(t('forms_common:forms_common_required_field_generic')),
            otherwise: (sch) => sch.notRequired(),
          }),
          averagePrice: Yup.number().when(['paymentType'], {
            is: (paymentType: PaymentType) => paymentType === PaymentType.ELECTRICAL_TRADE,
            then: (sch) => sch.required(t('forms_common:forms_common_required_field_generic')),
            otherwise: (sch) => sch.notRequired(),
          }),
          mortgages: Yup.array().when(['paymentType'], {
            is: (paymentType: PaymentType) => paymentType === PaymentType.MORTGAGE,
            then: (sch) => sch.of(
              Yup.object().shape({
                debt: Yup.number().required(t('forms_common:forms_common_required_field_generic')),
                averageInterestRate: Yup.number().required(t('forms_common:forms_common_required_field_generic')),
                amortization: Yup.number().required(t('forms_common:forms_common_required_field_generic')),
              }),
            ),
            otherwise: (sch) => sch.notRequired(),
          }),
          paymentMonths: Yup.array().when(['paymentType', 'paymentInterval'], {
            is: (paymentType: PaymentType, paymentInterval: PaymentInterval) =>
              paymentType === PaymentType.AVERAGE && paymentInterval !== PaymentInterval.QUARTERLY,
            then: (sch) => sch.notRequired(),
            otherwise: (sch) => sch.of(
              Yup.object().shape({
                amount: Yup.number().required(t('forms_common:forms_common_required_field_generic')),
              }),
            ),
          }),
        }),
      ),
    });

    return acc;
  }, {} as Yup.ObjectShape);

  const schema = Yup.object({
    name: Yup.string().required(t('forms_common:field_mandatory')),
    year: Yup.string().required(t('forms_common:field_mandatory')),
    categories: Yup.object().shape(categoriesDataValidation),
  });

  return schema;
};

export default useGetBudgetValidationSchema;
