import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { FCC } from '../../../../../types/common';
import { getNoWrapStyle } from '../../../../../utils/style';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';

type BonusOffersPageHeaderProps = {
  title?: string;
  ManageButton?: React.ReactElement;
};

const BonusOffersPageHeader: FCC<BonusOffersPageHeaderProps> = ({
  title,
  ManageButton,
  children,
}) => {
  const theme = useTheme();
  const { isDownMd, isDownLg } = useBreakPointsSizes();

  return (
    <Stack
      direction="row"
      flexWrap={isDownMd ? 'nowrap' : 'wrap'}
      justifyContent="space-between"
      sx={{
        gap: theme.spacing(1.5),
        padding: {
          xxs: theme.spacing(0.5, 0.5, 2.5, 0.5),
          md: theme.spacing(4, 4, 0.5, 4),
        },
      }}
    >
      <Typography
        component="h1"
        sx={getNoWrapStyle()}
        variant={isDownLg ? 'h4' : 'h3'}
      >
        {title}&nbsp;
      </Typography>
      {isDownMd ? ManageButton : children}
    </Stack>
  );
};

export default BonusOffersPageHeader;
