import {
  DateTimeFormats,
  formatDate,
} from '../../../../utils/date';
import { TimeIntervalsGroupingType } from '../Content/types/types.content';

const getYearQuarterByMonth = (month: number) => Math.ceil(month / 3);

export enum ChartDateLength {
  TINY = 'tiny',
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

const LongChartLabelFormatters = {
  [TimeIntervalsGroupingType.Monthly]: (date: Date) => formatDate(date, DateTimeFormats.MONTH_AND_YEAR),
  [TimeIntervalsGroupingType.Quarterly]: (date: Date) =>
    `${formatDate(date, DateTimeFormats.YEAR_ONLY)} Q${getYearQuarterByMonth(date.getMonth() + 1)}`,
  [TimeIntervalsGroupingType.Yearly]: (date: Date) => formatDate(date, DateTimeFormats.YEAR_ONLY),
};

const MediumChartLabelFormatters = {
  [TimeIntervalsGroupingType.Monthly]: (date: Date) => formatDate(date, DateTimeFormats.MONTH_AND_YEAR_SHORT),
  [TimeIntervalsGroupingType.Quarterly]: (date: Date) =>
    `${formatDate(date, DateTimeFormats.YEAR_ONLY_SHORT)} Q${getYearQuarterByMonth(date.getMonth() + 1)}`,
  [TimeIntervalsGroupingType.Yearly]: (date: Date) => formatDate(date, DateTimeFormats.YEAR_ONLY),
};

const ShortChartLabelFormatters = {
  [TimeIntervalsGroupingType.Monthly]: (date: Date) => formatDate(date, DateTimeFormats.MONTH_SHORT_AND_YEAR_SHORT),
  [TimeIntervalsGroupingType.Quarterly]: (date: Date) =>
    `${formatDate(date, DateTimeFormats.YEAR_ONLY_SHORT)} Q${getYearQuarterByMonth(date.getMonth() + 1)}`,
  [TimeIntervalsGroupingType.Yearly]: (date: Date) => formatDate(date, DateTimeFormats.YEAR_ONLY),
};

const TinyChartLabelFormatters = {
  [TimeIntervalsGroupingType.Monthly]: (date: Date) => formatDate(date, DateTimeFormats.MONTH_ONLY),
  [TimeIntervalsGroupingType.Quarterly]: (date: Date) =>
    `${formatDate(date, DateTimeFormats.YEAR_ONLY_SHORT)} Q${getYearQuarterByMonth(date.getMonth() + 1)}`,
  [TimeIntervalsGroupingType.Yearly]: (date: Date) => formatDate(date, DateTimeFormats.YEAR_ONLY),
};

export const formatChartDate = (
  chartDate: { year: number, month: number },
  groupingType: TimeIntervalsGroupingType,
  length?: ChartDateLength,
) => {
  const date = new Date(chartDate.year, chartDate.month - 1, 1);
  const formatters = length === 'long'
    ? LongChartLabelFormatters
    : length === 'medium'
      ? MediumChartLabelFormatters
      : length === 'short'
        ? ShortChartLabelFormatters
        : TinyChartLabelFormatters;

  return formatters[groupingType](date) || '';
};
