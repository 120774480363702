import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const All: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
  sx,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size} sx={sx}>
      <path
        d="M10 3H3V10H10V3Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M21 3H14V10H21V3Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M21 14H14V21H21V14Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M10 14H3V21H10V14Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default All;
