import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PropertyProgressCategorySectionProps,
  PropertyTasksSectionData,
} from '../../../../PropertyProgress/types.propertyProgress';
import PropertyTasksSection from '../../../../PropertyProgress/components/PropertyTasksSection';

const SellingPropertyHandoverTasks: FC<PropertyProgressCategorySectionProps<PropertyTasksSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['property', 'selling_property']);

  return (
    <PropertyTasksSection
      description={t('selling_property:selling_property_handover_todo_tasks_description')}
      infoDescription={t('selling_property:selling_property_handover_todo_tasks_modal_info_description')}
      infoTitle={t('selling_property:selling_property_handover_todo_tasks_read_more_label')}
      section={section}
      taskSectionTitle={t('property:property_progress_property_planning_not_connected_to_room_tasks_title')}
    />
  );
};

export default SellingPropertyHandoverTasks;
