import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';

import { BuildingEntityType } from '../../../../../../../../constants/entityType';
import { FCC } from '../../../../../../../../types/common';
import useGetBuildingTemplate from '../../../../../Buildings/hooks/useGetBuildingTemplate';
import { BuildingShortEntity } from '../../../../../Buildings/types.buildings';
import { getFloor } from '../../../../../Buildings/utils.buildings';
import useGetEntityInfo from '../../../../../Content/hooks/useGetEntityInfo';
import PropertyProgressContentItem from '../../../../components/PropertyProgressContentItem';
import HIDLink from '../../../../../../../../components/HIDLink';
import HIDTypography from '../../../../../../../../components/HIDTypography';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import { getPropertySpecificationDeepLink } from '../../../../../../../../utils/links';

type PropertyStructureBuildingsSectionItemProps = {
  buildingEntityType: BuildingEntityType;
  buildingEntity: BuildingShortEntity;
  height?: number
};

const PropertyStructureBuildingsSectionItem: FCC<PropertyStructureBuildingsSectionItemProps> = ({
  buildingEntityType,
  buildingEntity,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forms_common', 'buildings', 'common']);
  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const getEntityInfo = useGetEntityInfo();

  const buildingEntityInfo = getEntityInfo(buildingEntityType);

  const handleOpenMobileAppDialog = () => openMobileAppDialog({
    linkExtractor: getPropertySpecificationDeepLink,
    unavailableFeature: buildingEntityType,
  });

  const handleNavigationToBuilding = handleOpenMobileAppDialog;

  const handleNavigationToEditBuilding = handleOpenMobileAppDialog;

  const {
    data: buildingTemplate,
  } = useGetBuildingTemplate({ buildingEntityType, templateId: buildingEntity.templateId });

  const description = [
    buildingEntity?.floorId ? getFloor(buildingEntity?.floorId, buildingTemplate)?.label : undefined,
    buildingEntity?.area ? t('common:m2_value', { value: buildingEntity.area }) : undefined,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <PropertyProgressContentItem
      DescriptionComponent={
        description ? (
          <HIDTypography variant="body2">{description}</HIDTypography>
        ) : (
          <HIDLink
            label={t('forms_common:edit_details')}
            variant="body2"
            onClick={() => handleNavigationToEditBuilding()}
          />
        )
      }
      Icon={
        () => (
          <Card
            sx={{
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: '10px',
              borderColor: theme.palette.grey[300],
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              padding={1.5}
            >
              {
                buildingTemplate?.iconUrl
                  ? (
                    <img
                      height={36}
                      src={buildingTemplate?.iconUrl}
                      width={36}
                    />
                  )
                  : buildingEntityInfo
                    ? <buildingEntityInfo.Icon />
                    : undefined
              }
            </Stack>
          </Card>
        )
      }
      title={buildingEntity.name}
      onNavigate={handleNavigationToBuilding}
    />
  );
};
export default PropertyStructureBuildingsSectionItem;
