import React from 'react';
import {
  Breadcrumbs,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import HIDIconButton from './buttons/HIDIconButton';
import { FCC } from '../types/common';
import HIDLink from './HIDLink';

export type HIDBreadcrumbLink = {
  name: string,
  link: string,
};

type HIDBreadcrumbsProps = {
  links?: Array<HIDBreadcrumbLink>;
  title?: string;
  onBack?: () => void;
};

const HIDBreadcrumbs: FCC<HIDBreadcrumbsProps> = ({
  links,
  title,
  sx,
  onBack,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" direction="row" sx={sx}>
      <HIDIconButton
        Icon={ArrowBack}
        color="blank"
        sx={{ marginRight: theme.spacing(1) }}
        onClick={onBack || (() => navigate(-1))}
      />
      <Breadcrumbs aria-label="breadcrumb">
        {links?.map(({ name, link }) => (
          <HIDLink
            color="grey"
            href={link}
            key={name}
            label={name}
            underline="none"
            variant="body1"
          />
        ))}
        {title && (
          <Typography
            sx={{ color: theme.palette.grey[500] }}
            variant="body1"
          >
            {title}
          </Typography>
        )}
      </Breadcrumbs>
    </Stack>
  );
};

export default HIDBreadcrumbs;
