import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';

import {
  Add,
  Remove,
} from '@mui/icons-material';
import { FCC } from '../types/common';
import HIDIconButton from './buttons/HIDIconButton';
import HIDInfo from './HIDInfo';
import { hidSpacing } from '../utils/number';

type HIDCounterProps = {
  label: string;
  description?: string;
  value?: number;
  minCount?: number;
  onChange: (count: number) => void;
};

const HIDCounter: FCC<HIDCounterProps> = ({
  label,
  description,
  minCount = 0,
  value = 0,
  onChange,
}) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >
    <HIDInfo description={description} label={label} sx={{ flex: 1 }} />
    <HIDIconButton
      Icon={Remove}
      color="secondary"
      disabled={value === minCount}
      size="small"
      onClick={() => onChange(value - 1)}
    />
    <Typography sx={{ minWidth: hidSpacing(3.5) }} textAlign="center">{value}</Typography>
    <HIDIconButton
      Icon={Add}
      color="secondary"
      size="small"
      onClick={() => onChange(value + 1)}
    />
  </Stack>
);

export default HIDCounter;
