import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';
import { ALL_DOCUMENTS_CATEGORY } from './constants.document';

export const getDocumentCategoriesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.DOCUMENTS_CATEGORIES,
  { propertyId },
);

type GetDocumentCategoryPath = PropertyIdNavigation & {
  categoryId?: string;
  typeId?: string;
};

export const getDocumentCategoryPath = ({ propertyId, categoryId, typeId }: GetDocumentCategoryPath) => {
  const queryParams = typeId
    ? new URLSearchParams({ typeId: encodeURIComponent(typeId) || '' }).toString()
    : '';
  return `${generateRoutePath(
    RouteNames.DOCUMENTS,
    {
      propertyId,
      category: encodeURIComponent(categoryId || ALL_DOCUMENTS_CATEGORY),
    },
  )}?${queryParams}`;
};

type GetDocumentPath = PropertyIdNavigation & {
  id: string
  categoryId?: string,
};

export const getDocumentPath = ({
  propertyId,
  categoryId,
  id,
}: GetDocumentPath) => generateRoutePath(
  RouteNames.DOCUMENT,
  {
    propertyId,
    category: encodeURIComponent(categoryId || ALL_DOCUMENTS_CATEGORY),
    id,
  },
);

type GetCreateDocumentPath = PropertyIdNavigation & {
  categoryId?: string,
  typeId?: string,
  contentFileIds?: Array<string>,
};

export const getCreateDocumentPath = (
  {
    propertyId,
    categoryId,
    typeId,
    contentFileIds,
  }: GetCreateDocumentPath,
) => {
  const queryParams = { typeId: typeId ? encodeURIComponent(typeId) : undefined, contentFileIds };

  return generateRoutePath(
    RouteNames.DOCUMENT_CREATE,
    { propertyId, category: categoryId || ALL_DOCUMENTS_CATEGORY },
    queryParams,
  );
};

type GetUpdateDocumentPath = PropertyIdNavigation & {
  id: string;
  categoryId?: string;
  suggestionKey?: string;
};

export const getUpdateDocumentPath = ({
  propertyId,
  id,
  categoryId,
  suggestionKey,
}: GetUpdateDocumentPath) => generateRoutePath(
  RouteNames.DOCUMENT_UPDATE,
  {
    propertyId,
    id,
    category: categoryId || ALL_DOCUMENTS_CATEGORY,
  },
  { suggestionKey },
);
