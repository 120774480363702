import React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const SellingProperty: Icon = ({
  size,
}) => (
  <HIDSvgIcon
    fill="none"
    size={size}
    viewBox="0 0 100 100"
  >
    <path
      d="M84.761 81.263c-1.857 1.872-3.614 3.079-5.003 4.033-16.317 11.211-35.625 7.943-39.729 7.146-6.06-1.179-17.774-3.457-26.242-12.994C.975 65.022 2.97 42.29 12.908 27.286c1.818-2.745 11.42-17.977 30.936-20.421 11.812-1.477 25.58 1.504 35.55 9.502 20.929 16.791 19.438 50.711 5.367 64.896Z"
      fill="#F4FBF9"
    />
    <path
      d="M26.512 55.069c-21.575 2.308-35.91 8.227-19.233 8.535 31.309.579 10.001 5.065 26.461 5.512 16.462.446 41.973.318 58.829-4.284 16.857-4.603.701-6.439-6.062-6.485-22.266-.148 7.584-10.507-59.996-3.279l.001.001Z"
      fill="#D7EAEA"
    />
    <path
      d="m57.236 22.262-26.912-.722 15.03 16.025 26.913.722-15.031-16.025Z"
      fill="#184359"
    />
    <path
      d="m55.726 23.744 13.55 14.464-.624 23.416-51.278-1.373.665-24.958 37.687-11.549Z"
      fill="#ACD7D6"
    />
    <path
      d="m17.374 60.249 23.409.626.651-24.391-23.41-.626-.65 24.391Z"
      fill="#2FA7A7"
    />
    <path
      d="m30.359 21.542 26.913.721L41.408 37.46l-26.913-.721 15.864-15.197Z"
      fill="#184359"
    />
    <path
      d="m25.242 51.546 8.944.24a.679.679 0 0 0 .696-.661l.239-8.963a.678.678 0 0 0-.659-.697l-8.944-.24a.679.679 0 0 0-.696.66l-.239 8.964a.679.679 0 0 0 .659.697ZM59.605 51.322l5.51.147a.68.68 0 0 0 .696-.662l.208-7.815a.678.678 0 0 0-.659-.698l-5.511-.147a.679.679 0 0 0-.696.661l-.208 7.816a.68.68 0 0 0 .66.698Z"
      fill="#fff"
    />
    <path
      d="m46.076 60.765 10.511.281.515-19.339a.677.677 0 0 0-.659-.696l-9.154-.246a.679.679 0 0 0-.697.661l-.516 19.339Z"
      fill="#184359"
    />
    <rect
      fill="#4BC2A0"
      height="23.873"
      rx="3"
      transform="rotate(8.836 20.065 49.792) skewX(.037)"
      width="23.824"
      x="62.436"
      y="50.748"
    />
    <path
      d="m68.832 77.824-4.731-8.964 3.527.5 1.86 3.818 7.105-7.881 4.279-.579-12.04 13.106Z"
      fill="#fff"
    />
  </HIDSvgIcon>
);

export default SellingProperty;
