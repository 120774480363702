import React from 'react';
import { FCC } from '../../types/common';

const VerticalImagePlaceholder: FCC = ({ style }) => (
  <svg
    fill="currentColor"
    style={{
      width: 'inherit',
      visibility: 'visible',
      ...style,
    }}
    viewBox="0 0 150 205"
  >
    <path d="M5.291 0h139.377c1.396.057 2.861.688 3.775 1.485.841.862 1.483 2.219 1.543 3.545v13.351c.001.517 0 1.041 0 1.569v180.012c-.056 1.315-.684 2.703-1.555 3.563-.906.823-2.361 1.42-3.753 1.475H5.299c-1.394-.055-2.861-.688-3.777-1.487-.837-.86-1.479-2.217-1.541-3.543V5.038c.06-1.322.728-2.712 1.569-3.577C2.458.666 3.891.059 5.291 0Zm27.464 124.873c3.99-3.939 10.963-3.939 14.953 0l10.152 10.014 34.821-34.372c4.13-4.072 10.822-4.072 14.952 0l35.303 30.901V21.457c0-7.305-5.104-13.249-11.379-13.249H18.413c-6.274 0-11.378 5.944-11.378 13.249v123.87l25.72-20.454Zm69.894-11.441a3.561 3.561 0 0 0-4.985 0l-37.308 36.829a3.566 3.566 0 0 1-4.991.007L42.723 137.79a3.656 3.656 0 0 0-4.983 0L7.035 163.165v20.37c0 7.313 5.104 13.257 11.378 13.257H131.55c6.282 0 11.386-5.944 11.386-13.249v-34.29l-40.287-35.821ZM60.704 88.705c-7.776 0-14.1-6.242-14.1-13.919 0-7.676 6.324-13.918 14.1-13.918 7.777 0 14.1 6.242 14.1 13.918 0 7.677-6.323 13.919-14.1 13.919Zm0-20.878c-3.884 0-7.05 3.125-7.05 6.959 0 3.835 3.166 6.96 7.05 6.96 3.885 0 7.051-3.125 7.051-6.96 0-3.834-3.166-6.959-7.051-6.959Z" />
  </svg>
);

export default VerticalImagePlaceholder;
