// eslint-disable-next-line import/no-unused-modules
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ErrorBoundary } from '@sentry/react';

import './external-services/firebase';
import { store } from './store/store';
import './index.css';
import App from './App';
import theme from './theme';
import { initExternalServices } from './external-services';
import HIDErrorPage from './components/HIDErrorPage';

initExternalServices();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary fallback={<HIDErrorPage />}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </ErrorBoundary>,
);
