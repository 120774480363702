import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import ReactMarkdown from 'react-markdown';

import PropertyInsuranceHouseBackground from '../../../../../../../../assets/images/property_insurance_house.png';

import PropertyInsuranceCondominiumBackground from '../../../../../../../../assets/images/property_insurance_condominium.png';
import SfLogoShort from '../../../../../../../../assets/images/sf_short.png';
import HIDImage from '../../../../../../../../components/image/HIDImage';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { CustomInsuranceType } from '../../types.insurance';

const BackgroundImageMap = {
  [CustomInsuranceType.HOUSE]: PropertyInsuranceHouseBackground,
  [CustomInsuranceType.CONDOMINIUM]: PropertyInsuranceCondominiumBackground,
};

type VarudeklareradPropertyInsuranceCardProps = {
  insuranceType: CustomInsuranceType.HOUSE | CustomInsuranceType.CONDOMINIUM,
};

const VarudeklareradPropertyInsuranceCard: FC<VarudeklareradPropertyInsuranceCardProps> = ({
  insuranceType,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['insurances']);
  const imageUrl = BackgroundImageMap[insuranceType];

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const headerHeight = R.cond([
    [() => isDownXs, R.always(160)],
    [() => isDownSm, R.always(160)],
    [() => isDownMd, R.always(320)],
    [() => isDownLg, R.always(400)],
    [() => isDownXl, R.always(400)],
    [R.T, R.always(320)],
  ])();

  const logoHeight = R.cond([
    [() => isDownXs, R.always(48)],
    [() => isDownSm, R.always(64)],
    [() => isDownMd, R.always(120)],
    [() => isDownLg, R.always(160)],
    [() => isDownXl, R.always(160)],
    [R.T, R.always(120)],
  ])();

  return (
    <Stack spacing={2}>
      <Box sx={{
        backgroundImage: `url(${imageUrl})`,
        objectFit: 'cover',
        height: headerHeight,
      }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          padding={2}
          spacing={2}
          sx={{ height: headerHeight }}
        >
          <HIDImage
            style={{
              height: logoHeight,
            }}
            url={SfLogoShort}
          />
          <Typography color={theme.palette.common.white} textAlign="center" typography={{ sm: 'h3', xxs: 'h5' }}>
            {t(`insurances:insurance_varudeklarerad_congratulations_${insuranceType}`)}
          </Typography>
        </Stack>
      </Box>
      <Typography>
        {t(`insurances:insurance_varudeklarerad_description_${insuranceType}`)}
      </Typography>
      <Typography>
        <ReactMarkdown components={{ p: 'span' }}>
          {t(`insurances:insurance_varudeklarerad_features_${insuranceType}`)}
        </ReactMarkdown>
      </Typography>
    </Stack>
  );
};

export default VarudeklareradPropertyInsuranceCard;
