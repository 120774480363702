import {
  Stack,
  useTheme,
  Skeleton,
  Divider,
} from '@mui/material';
import React from 'react';
import * as R from 'ramda';

import { getTypographyHeight } from '../../utils/style';
import { FCC } from '../../types/common';
import HIDTypography from '../HIDTypography';

const DefaultEntityPreviewLoader = (
  <Skeleton
    sx={{ margin: '0 auto', height: { xs: '150px', md: '300px', lg: '400px' } }}
    variant="rounded"
    width="40%"
  />
);

type EntityDetailsLoaderSkeletonProps = {
  EntityPreviewLoader?: React.ReactElement | null;
  isLoading: boolean;
};

const EntityDetailsLoaderSkeleton: FCC<EntityDetailsLoaderSkeletonProps> = ({
  EntityPreviewLoader = DefaultEntityPreviewLoader,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    const styles = {
      padding: 2,
      marginTop: 2.5,
      borderRadius: theme.spacing(1.25),
      border: `1px solid ${theme.palette.grey[300]}`,
    };

    return (
      <Stack direction="column">
        <Stack direction="row" gap={2} sx={{ padding: theme.spacing(1.5, 0.5), maxWidth: '70%' }}>
          {R.range(0, 3).map((i) => (
            <Skeleton
              height={getTypographyHeight(theme.typography.body2)}
              key={i}
              variant="rounded"
              width="20%"
            />
          ))}
        </Stack>
        <Divider />
        {EntityPreviewLoader}
        <HIDTypography
          isLoading
          skeletonWidth="20%"
          sx={{ mt: 3 }}
          variant="h6"
        >
          {' '}
        </HIDTypography>
        <Stack
          gap={2}
          sx={styles}
        >
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="50%" />
        </Stack>
        <HIDTypography
          isLoading
          skeletonWidth="20%"
          sx={{ mt: 3 }}
          variant="h6"
        >
          {' '}
        </HIDTypography>
        <Stack
          gap={2}
          sx={styles}
        >
          <Skeleton variant="text" width="25%" />
          <Skeleton variant="text" width="50%" />
        </Stack>
      </Stack>
    );
  }

  return children;
};

export default EntityDetailsLoaderSkeleton;
