import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getNotesPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.NOTES,
  { propertyId },
);

type GetNotePath = { propertyId: string, id: string };
export const getNotePath = ({ propertyId, id }: GetNotePath) => generateRoutePath(
  RouteNames.NOTE,
  { propertyId, id },
);

type GetCreateNotePath = { propertyId: string, url?: string, name?: string };
export const getCreateNotePath = ({ propertyId, url, name }: GetCreateNotePath) => generateRoutePath(
  RouteNames.NOTE_CREATE,
  { propertyId },
  { url, name },
);

export const getUpdateNotePath = ({
  propertyId,
  id,
  suggestionKey,
}: {
  propertyId: string;
  id: string;
  suggestionKey?: string;
}) => generateRoutePath(
  RouteNames.NOTE_UPDATE,
  {
    propertyId,
    id,
  },
  { suggestionKey },
);
