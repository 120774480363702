import {
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { FCC } from '../../../../../types/common';
import AllDoneImage from '../../../../../assets/images/all_done.svg';

type NoContentProps = {
  title: string;
  description?: string;
  height?: number;
};

const NoContent: FCC<NoContentProps> = ({
  title,
  description,
  height,
  sx,
}) => (
  <Stack alignItems="center" spacing={2} sx={sx}>
    <img
      src={AllDoneImage}
      style={{
        height: height || 200,
      }}
    />
    <Typography textAlign="center" variant="subtitle2">
      {title}
    </Typography>
    {Boolean(description) && (
      <Typography textAlign="center" variant="body1">
        {description}
      </Typography>
    )}
  </Stack>
);

export default NoContent;
