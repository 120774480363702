import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'usehooks-ts';

import HIDInfo from '../../../../../../../../../components/HIDInfo';
import { FCC } from '../../../../../../../../../types/common';
import HIDTextField, { CurrencyAdornment } from '../../../../../../../../../components/HIDTextField';
import {
  formatMoney,
  parseFloatNumber,
} from '../../../../../../../../../utils/string';

export enum ImprovementType {
  BASIC = 'BASIC',
  REPAIR = 'REPAIR',
}

enum ImprovementCoverage {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

const getImprovementCoverageFromAmount = (amount: number, improvement: number | undefined) => !improvement
  ? undefined
  : improvement < amount
    ? ImprovementCoverage.PARTIAL
    : ImprovementCoverage.FULL;

type ImprovementFormProps = {
  label: string;
  description: string;
  improvementCoverage?: ImprovementCoverage;
  improvementValue?: string | number;
  amount: number;
  onImprovementCoverageChange: (coverage: ImprovementCoverage) => void;
  onImprovementValueChange: (value?: string) => void;
};

const ImprovementForm: FCC<ImprovementFormProps> = ({
  label,
  description,
  improvementCoverage,
  improvementValue,
  amount,
  sx,
  onImprovementCoverageChange,
  onImprovementValueChange,
}) => {
  const { t } = useTranslation(['receipts']);
  const theme = useTheme();

  return (
    <Stack direction="row" flexWrap="wrap" sx={sx}>
      <HIDInfo
        isMarkdown
        description={description}
        label={label}
        labelVariant="subtitle1"
        sx={{ minWidth: 246 }}
      />
      <RadioGroup
        sx={{ alignItems: 'flex-start' }}
        value={improvementCoverage || null}
        onChange={(_event, value) => onImprovementCoverageChange(value as ImprovementCoverage)}
      >
        <Stack alignItems="center" direction="row">
          <FormControlLabel
            control={<Radio />}
            label={t('receipts:receipts_improvement_expenses_full')}
            sx={{ marginRight: 1 }}
            value={ImprovementCoverage.FULL}
          />
          <Typography color={theme.palette.grey[500]} variant="body2">
            {`(${formatMoney(amount)})`}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          <FormControlLabel
            control={<Radio />}
            label={t('receipts:receipts_improvement_expenses_partial')}
            sx={{ marginRight: 1 }}
            value={ImprovementCoverage.PARTIAL}
          />
          {improvementCoverage === ImprovementCoverage.PARTIAL && (
            <HIDTextField
              endAdornment={<CurrencyAdornment />}
              showHelperText={false}
              sx={{ maxWidth: 104 }}
              type="number"
              value={improvementValue?.toString() || ''}
              onChange={({ target: { value } }) => onImprovementValueChange(value)}
            />
          )}
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

type ImprovementExpensesProps = {
  isImprovement?: boolean;
  amount?: number;
  basicImprovementValue?: string | number;
  repairImprovementValue?: string | number;
  onBlurImprovement?: () => void;
  onChangeIsImprovement: (isImprovement: boolean) => void;
  onChangeBasicImprovementValue: (value?: string) => void;
  onChangeRepairImprovementValue: (value?: string) => void;
};

const ImprovementExpenses: FCC<ImprovementExpensesProps> = ({
  isImprovement = false,
  amount = 0,
  basicImprovementValue: basicImprovementValueProp,
  repairImprovementValue: repairImprovementValueProp,
  onBlurImprovement,
  onChangeIsImprovement,
  onChangeBasicImprovementValue,
  onChangeRepairImprovementValue,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['receipts']);

  const [basicImprovementCoverage, setBasicImprovementCoverage] = useState<ImprovementCoverage | undefined>(
    getImprovementCoverageFromAmount(amount, parseFloatNumber(basicImprovementValueProp)),
  );
  const [basicImprovementValue, setBasicImprovementValue] = useState<number | string | undefined>(basicImprovementValueProp);

  const [repairImprovementCoverage, setRepairImprovementCoverage] = useState<ImprovementCoverage | undefined>(
    getImprovementCoverageFromAmount(amount, parseFloatNumber(repairImprovementValueProp)),
  );
  const [repairImprovementValue, setRepairImprovementValue] = useState<number | string | undefined>(repairImprovementValueProp);

  const handleIsImprovementChange = (_event: React.SyntheticEvent<Element, Event>, isImprovement: boolean) => {
    onChangeIsImprovement(isImprovement);
    setRepairImprovementCoverage(undefined);
    setRepairImprovementValue(undefined);
    setBasicImprovementCoverage(undefined);
    setBasicImprovementValue(undefined);
  };

  const handleBasicImprovementCoverageChange = (improvementCoverage: ImprovementCoverage) => {
    setBasicImprovementCoverage(improvementCoverage);

    if (improvementCoverage === ImprovementCoverage.FULL) {
      setRepairImprovementCoverage(undefined);
      setBasicImprovementValue(amount);
      setRepairImprovementValue(undefined);
    } else if (improvementCoverage === ImprovementCoverage.PARTIAL) {
      setBasicImprovementValue(undefined);
      if (repairImprovementCoverage === ImprovementCoverage.FULL) {
        setRepairImprovementCoverage(undefined);
      }
    }
  };

  const handleRepairImprovementCoverageChange = (improvementCoverage: ImprovementCoverage) => {
    setRepairImprovementCoverage(improvementCoverage);

    if (improvementCoverage === ImprovementCoverage.FULL) {
      setBasicImprovementCoverage(undefined);
      setRepairImprovementValue(amount);
      setBasicImprovementValue(undefined);
    } else if (improvementCoverage === ImprovementCoverage.PARTIAL) {
      setRepairImprovementValue(undefined);
      if (basicImprovementCoverage === ImprovementCoverage.FULL) {
        setBasicImprovementCoverage(undefined);
      }
    }
  };

  useUpdateEffect(() => {
    if (basicImprovementValue !== basicImprovementValueProp) {
      onChangeBasicImprovementValue(basicImprovementValue?.toString());
    }
  }, [basicImprovementValue]);

  useUpdateEffect(() => {
    if (repairImprovementValue !== repairImprovementValueProp) {
      onChangeRepairImprovementValue(repairImprovementValue?.toString());
    }
  }, [repairImprovementValue]);

  useUpdateEffect(() => {
    if (basicImprovementCoverage === ImprovementCoverage.FULL && basicImprovementValue !== amount) {
      setBasicImprovementValue(amount);
    }

    if (repairImprovementCoverage === ImprovementCoverage.FULL && repairImprovementValue !== amount) {
      setRepairImprovementValue(amount);
    }
  }, [amount]);

  const isValid = !amount || (!basicImprovementValue && !repairImprovementValue)
    // NOTE: if ImprovementCoverage is FULL, improvementValue will be updated by amount from props
    || (basicImprovementCoverage === ImprovementCoverage.FULL || repairImprovementCoverage === ImprovementCoverage.FULL)
    || amount >= (parseFloatNumber(basicImprovementValue, 0) + parseFloatNumber(repairImprovementValue, 0));

  return (
    <Stack>
      <FormControlLabel
        checked={isImprovement}
        control={<Checkbox />}
        label={
          <HIDInfo
            isMarkdown
            description={t('receipts:receipts_improvement_expenses_description')}
            label={t('receipts:receipts_improvement_expenses')}
            labelVariant="subtitle1"
          />
        }
        sx={{ width: 'fit-content' }}
        onBlur={onBlurImprovement}
        onChange={handleIsImprovementChange}
      />
      {isImprovement && (
        <Stack>
          <ImprovementForm
            amount={amount}
            description={t('receipts:receipts_improvement_expenses_basic_improvement_description')}
            improvementCoverage={basicImprovementCoverage}
            improvementValue={basicImprovementValue}
            label={t('receipts:receipts_improvement_expenses_basic_improvement')}
            onImprovementCoverageChange={handleBasicImprovementCoverageChange}
            onImprovementValueChange={setBasicImprovementValue}
          />
          <ImprovementForm
            amount={amount}
            description={t('receipts:receipts_improvement_expenses_repair_improvement_description')}
            improvementCoverage={repairImprovementCoverage}
            improvementValue={repairImprovementValue}
            label={t('receipts:receipts_improvement_expenses_repair_improvement')}
            onImprovementCoverageChange={handleRepairImprovementCoverageChange}
            onImprovementValueChange={setRepairImprovementValue}
          />
          <Typography color={theme.palette.error.main} sx={{ marginTop: 1 }} variant="body2">
            {isValid ? <>&nbsp;</> : t('receipts:receipts_improvement_expenses_error')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ImprovementExpenses;
