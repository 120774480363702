import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { FCC } from '../../../../../types/common';
import { BonusOfferEntity } from '../types.bonusOffers';
import BonusOfferCardBadge from './BonusOfferCardBadge';
import { getNoWrapStyle } from '../../../../../utils/style';
import BonusOfferCardImage from './BonusOfferCardImage';
import BonusOfferDiscountCardBadge from './BonusOfferDiscountCardBadge';

type BonusOfferCardProps = {
  isPrimary?: boolean;
  showDescription?: boolean;
  entity: BonusOfferEntity;
  onClick?: () => void;
};

const BonusOfferCard: FCC<BonusOfferCardProps> = ({
  sx,
  entity,
  isPrimary = false,
  showDescription = false,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'bonus_offers']);

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(0.5),
        border: 1,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        position: 'relative',
        height: '100%',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.lightest,
          boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.07)',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <BonusOfferCardImage
        imageUrl={entity.imageUrl}
        maxHeight={isPrimary ? '406px' : '180px'}
      />
      {Boolean(entity.points || entity.discount) && (
        <Stack
          spacing={1}
          sx={{
            position: 'absolute',
            top: theme.spacing(1.5),
            left: theme.spacing(1.5),
          }}
        >
          {Boolean(entity.points) && (
            <BonusOfferCardBadge variant="green">
              {t('common:points', { points: entity.points })}
            </BonusOfferCardBadge>
          )}
          {Boolean(entity.discount) && (
            <BonusOfferDiscountCardBadge discount={entity.discount} />
          )}
        </Stack>
      )}
      <Stack
        flex={1}
        justifyContent="space-between"
        spacing={1}
        sx={{
          padding: theme.spacing(1.5),
          borderTopWidth: 'inherit',
          borderTopStyle: 'inherit',
          borderTopColor: 'inherit',
        }}
      >
        <Typography fontWeight="bold" variant="body1">
          {entity.name}
        </Typography>
        {showDescription && (
          <Typography sx={{ maxHeight: 70, ...getNoWrapStyle(3) }} variant="body1">
            <ReactMarkdown skipHtml components={{ p: 'span' }}>
              {entity.description}
            </ReactMarkdown>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default BonusOfferCard;
