import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Theme,
  Stack,
  SxProps,
} from '@mui/material';

import { ContentSliderContext } from './useContentSliderContext';

type ContentSliderContainerProps<TSlide> = {
  slides: Array<TSlide>;
  sx?: SxProps<Theme>;
  sliderHeight?: number | string;
  slideSwapInterval?: number;
  isAutoStartPrevented?: boolean;
  renderSlide: (slide: TSlide) => React.ReactNode;
};

const ContentSliderContainer = <TSlide = string>({
  slides,
  sliderHeight = '100%',
  slideSwapInterval = 5000,
  isAutoStartPrevented = false,
  sx,
  renderSlide,
}: ContentSliderContainerProps<TSlide>) => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const mouseIsOver = useRef<boolean>(false);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const totalSlidesNum = slides.length;

  const startInterval = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (!mouseIsOver.current) {
        setActiveSlideIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex <= totalSlidesNum - 1) {
            return nextIndex;
          }

          return 0;
        });
      }
    }, slideSwapInterval);
  };

  const stopInterval = () => clearInterval(intervalRef.current);

  const restartInterval = () => {
    stopInterval();
    startInterval();
  };

  useEffect(() => {
    if (!isAutoStartPrevented && totalSlidesNum > 1) {
      startInterval();
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isAutoStartPrevented, totalSlidesNum]);

  const handleChangeSlide = useCallback((slideIndex: number) => {
    restartInterval();
    setActiveSlideIndex(slideIndex);
  }, []);

  const handleMouseEnter = () => {
    mouseIsOver.current = true;
  };

  const handleMouseLeave = () => {
    mouseIsOver.current = false;
    restartInterval();
  };

  const contextValue = useMemo(() => ({
    totalSlidesNum,
    activeSlideIndex,
    onChangeSlide: handleChangeSlide,
  }), [totalSlidesNum, activeSlideIndex, handleChangeSlide]);

  return (
    <Stack
      sx={{
        height: sliderHeight,
        justifyContent: 'space-between',
        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ContentSliderContext.Provider value={contextValue}>
        {renderSlide(slides[activeSlideIndex])}
      </ContentSliderContext.Provider>
    </Stack>
  );
};

export default ContentSliderContainer;
