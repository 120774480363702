import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDAvatar, { HIDAvatarProps } from '../../../../../../../../../components/HIDAvatar';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';

type BankAccountProps = {
  name?: string;
  bban?: string;
  logoURL: string;
  product?: string;
  lastSyncedAt?: string;
  avatarSize?: HIDAvatarProps['size'];
  isLoading?: boolean;
};

const BankAccount: FC<BankAccountProps> = ({
  name,
  bban,
  logoURL,
  product,
  lastSyncedAt,
  avatarSize = 'big',
  isLoading = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const accountName = name || bban;

  return (
    <Stack alignItems="center" direction="row" gap={2}>
      <HIDAvatar
        externalImage={logoURL}
        givenName={accountName}
        isLoading={isLoading}
        size={avatarSize}
      />
      <Stack alignItems="center" direction="row" flex={1}>
        <Stack flex={1}>
          <HIDTypography isLoading={isLoading} variant="subtitle1">
            {accountName}
          </HIDTypography>
          {product !== undefined && (
            <HIDTypography color={theme.palette.grey[500]} isLoading={isLoading} variant="body2">
              {product}
            </HIDTypography>
          )}
          {lastSyncedAt !== undefined && (
            <HIDTypography color={theme.palette.grey[500]} isLoading={isLoading} variant="body2">
              {t(
                'finances:expenses_manage_bank_accounts_account_last_synced',
                { date: formatDate(new Date(lastSyncedAt), DateTimeFormats.DATE_ONLY) },
              )}
            </HIDTypography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BankAccount;
