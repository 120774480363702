import React, {
  FC,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { useGetReceiptsSummaryQuery } from '../../api/api.receipt';
import HIDCircularProgress from '../../../../../../../../components/progress/HIDCircularProgress';
import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { ALL_RECEIPTS_CATEGORY } from '../../constants.receipt';
import { formatMoney } from '../../../../../../../../utils/string';
import { roundPercentage } from '../../../../../../../../utils/number';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import ReceiptsSummaryChart from '../Receipts/components/ReceiptsSummaryChart';
import { getReceiptsSummaryChartData } from '../../utils.receipt';
import {
  getCreateReceiptPath,
  getReceiptCategoryPath,
} from '../../navigation.receipt';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import ListCategoriesActions from '../../../../components/actions/ListCategoriesActions';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import FiltersSection, { ContentFilters } from '../../../../components/sections/FiltersSection';
import { TimeIntervalsGroupingType } from '../../../../types/types.content';
import SuggestionsIconButton from '../../../Suggestion/components/SuggestionsIconButton';
import { GroupedReceiptCategory } from '../../types.receipt';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../../hooks/useSearch';

type PercentageLabelColumnType = {
  percentage: number;
  label: string;
};

const ReceiptCategories: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts']);
  const { isDownSm } = useBreakPointsSizes();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { displaySearch } = useSearch(EntityType.RECEIPT);

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    groupingType: TimeIntervalsGroupingType.Yearly,
    columnsVisibilityMap: {
      totalAmount: { label: t('receipts:receipts_price_label'), visible: true },
      totalDeductible: { label: t('receipts:receipts_deductible_label'), visible: true },
    },
  });

  const totalAmountVisible = Boolean(filters.columnsVisibilityMap?.totalAmount?.visible);
  const totalDeductibleVisible = Boolean(filters.columnsVisibilityMap?.totalDeductible?.visible);

  const { data: receiptsSummary, isLoading } = useGetReceiptsSummaryQuery(
    propertyId
      ? {
        propertyId,
        from: filters.from,
        to: filters.to,
        groupingType: filters.groupingType,
      }
      : skipToken,
  );

  const summary = receiptsSummary?.summary;
  const totalAmount = receiptsSummary?.summary?.totalAmount;
  const totalDeductible = receiptsSummary?.summary?.totalDeductible;

  const receiptsCategories = receiptsSummary?.categories || [];
  const categories = receiptsCategories.length > 0
    ? [
      ...receiptsCategories,
      {
        categoryId: ALL_RECEIPTS_CATEGORY,
        label: t('receipts:receipts_receipts_categories_all_receipts'),
        totalAmount,
        totalDeductible,
        count: summary?.count || 0,
      },
    ]
    : [];

  const columns: Array<GridColDef> = [
    {
      field: 'label',
      headerName: t('receipts:receipts_receipts_categories_label_column'),
      flex: 0.4,
      type: 'string',
      sortable: !isDownSm,
      valueGetter: (params: GridValueGetterParams) => {
        const groupedReceiptCategory = params?.row as GroupedReceiptCategory;
        return {
          percentage: groupedReceiptCategory?.totalAmount
            ? Math.round(roundPercentage(groupedReceiptCategory.totalAmount / (totalAmount || 1), 2) * 100)
            : 0,
          label: groupedReceiptCategory.label,
        } as PercentageLabelColumnType;
      },
      sortComparator: (a: PercentageLabelColumnType, b: PercentageLabelColumnType) => a.label.localeCompare(b.label),
      renderCell: (params: GridRenderCellParams) => {
        const { percentage, label } = params.value as PercentageLabelColumnType;
        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            <HIDCircularProgress thickness={5} value={percentage} />
            <Typography noWrap variant="subtitle1">
              {label}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownSm && {
      field: 'quantity',
      headerName: t('receipts:receipts_quantity'),
      flex: 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { count } = params?.row as GroupedReceiptCategory;
        return (
          <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
            {t('common:items_count', { count })}
          </Typography>
        );
      },
    },
    !isDownSm && totalDeductibleVisible && {
      field: 'totalDeductible',
      headerName: t('receipts:receipts_deductible_label'),
      flex: 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { totalDeductible } = params?.row as GroupedReceiptCategory;
        return (
          <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
            {formatMoney(totalDeductible)}
          </Typography>
        );
      },
    },
    !isDownSm && totalAmountVisible && {
      field: 'totalAmount',
      headerName: t('receipts:receipts_price_label'),
      flex: 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { totalAmount } = params?.row as GroupedReceiptCategory;
        return (
          <Typography variant="subtitle2">
            {formatMoney(totalAmount)}
          </Typography>
        );
      },
    },
    isDownSm && (totalDeductibleVisible || totalAmountVisible) && {
      field: 'totalAmountAndDeductible',
      headerName: (
        <span style={{ textAlign: 'right' }}>
          {t('receipts:receipts_price_label')} /<br />{t('receipts:receipts_deductible_label')}
        </span>
      ) as unknown as string,
      flex: 0.4,
      type: 'string',
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const receiptCategory = params?.row as GroupedReceiptCategory;
        const { totalAmount, totalDeductible } = receiptCategory || {};

        return (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Stack alignItems="flex-end">
              {totalAmountVisible && (
                <Typography variant="subtitle1">
                  {formatMoney(totalAmount)}
                </Typography>
              )}
              {totalDeductibleVisible && (
                <Typography sx={{ color: theme.palette.grey[500] }} variant="body2">
                  {formatMoney(totalDeductible)}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const receiptCategory = params?.row as GroupedReceiptCategory;
    navigate(getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId: receiptCategory.categoryId }));
  };

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }));

  return (
    <HomeListLayout
      ListHeaderComponent={
        <ReceiptsSummaryChart
          data={getReceiptsSummaryChartData(summary?.values || [], filters.groupingType)}
          isLoading={isLoading}
          sx={{ marginBottom: 3 }}
          totalAmount={totalAmount}
          totalAmountVisible={totalAmountVisible}
          totalDeductible={totalDeductible}
          totalDeductibleVisible={totalDeductibleVisible}
        />
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListCategoriesActions
              onAdd={handleAdd}
              onSearch={displaySearch}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <FiltersSection
              showChartGroupingTypeFilers
              showColumnsVisibilityFilters
              showDateFilers
              filters={filters}
              onChange={setFilters}
            />
          </Card>
        </>
      }
      TitleRightComponent={<SuggestionsIconButton entityType={EntityType.RECEIPT} />}
      columns={columns}
      getRowId={(row: GroupedReceiptCategory) => row.categoryId}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'label',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={categories}
      sideDrawerElements={
        [
          <ListCategoriesActions
            key={ListCategoriesActions.name}
            onAdd={handleAdd}
            onSearch={displaySearch}
          />,
          <FiltersSection
            showChartGroupingTypeFilers
            showColumnsVisibilityFilters
            showDateFilers
            filters={filters}
            key={FiltersSection.name}
            onChange={setFilters}
          />,
        ]
      }
      title={t('receipts:receipts_receipts_categories_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
    />
  );
};

export default ReceiptCategories;
