import React, {
  PropsWithChildren,
  ReactElement,
} from 'react';
import {
  useTheme,
} from '@mui/material';
import {
  ComposedChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
  XAxisProps,
} from 'recharts';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';

import ChartLoaderSkeleton from '../skeletonLoaders/ChartLoaderSkeleton';

type BaseChartLayoutProps<TData> = {
  minHeight?: number;
  maxBarSize?: number;
  isLoading: boolean;
  fontSize?: number;
  data: Array<TData>;
  ChartTooltipComponent: ReactElement;
  xAxisInterval?: XAxisProps['interval'];
  xAxisFormatter?: XAxisProps['tickFormatter'];
  yAxisFormatter?: YAxisProps['tickFormatter'];
  yAxisWidth?: number;
  renderLegend?: ContentType;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showLegend?: boolean;
  showGrid?: boolean;
  showTooltip?: boolean;
};

const BaseChartLayout = <TData extends { name: string; }>({
  minHeight = 220,
  data,
  maxBarSize,
  isLoading,
  fontSize,
  ChartTooltipComponent,
  xAxisInterval = undefined,
  xAxisFormatter,
  yAxisFormatter,
  yAxisWidth,
  renderLegend,
  showXAxis = true,
  showYAxis = true,
  showLegend = true,
  showGrid = true,
  showTooltip = true,
  children,
}: PropsWithChildren<BaseChartLayoutProps<TData>>) => {
  const theme = useTheme();

  return (
    <ChartLoaderSkeleton height={minHeight} isLoading={isLoading}>
      <ResponsiveContainer
        height="100%"
        minHeight={minHeight}
        width="100%"
      >
        <ComposedChart data={data} maxBarSize={maxBarSize}>
          {showGrid && (
            <CartesianGrid strokeDasharray="3 3" />
          )}
          {showXAxis && (
            <XAxis
              dataKey="name"
              fontSize={fontSize}
              interval={xAxisInterval}
              stroke={theme.palette.grey[300]}
              tick={{ fill: theme.palette.grey[500] }}
              tickFormatter={xAxisFormatter}
              tickLine={{ stroke: theme.palette.grey[300] }}
            />
          )}
          {(showYAxis && (
            <YAxis
              fontSize={fontSize}
              stroke={theme.palette.grey[300]}
              tick={{ fill: theme.palette.grey[500] }}
              tickFormatter={yAxisFormatter}
              tickLine={{ stroke: theme.palette.grey[300] }}
              width={yAxisWidth}
            />
          ))}
          {showTooltip && (
            <Tooltip
              content={ChartTooltipComponent}
              cursor={{ fill: theme.palette.shadow[50] }}
            />
          )}
          {showLegend && (
            renderLegend
              ? (
                <Legend
                  align="left"
                  content={renderLegend}
                />
              )
              : (
                <Legend
                  align="left"
                  formatter={(value) => (
                    <span
                      style={{
                        color: theme.palette.common.black,
                        marginLeft: theme.spacing(0.5),
                        marginRight: theme.spacing(1),
                      }}
                    >
                      {value}
                    </span>
                  )}
                  iconType="circle"
                />
              )
          )}
          {children}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartLoaderSkeleton>
  );
};

export default BaseChartLayout;
