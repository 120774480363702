import {
  addMonths,
  setMonth,
} from 'date-fns';
import {
  EconomyAmountHistoryItem,
  DateBoundaries,
} from '@house-id/houseid-types/dist/finances/finances';

import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';

export const useGetFormatMonthDateLabel = () => {
  const { isDownXs } = useBreakPointsSizes();

  return (monthNumber: number) => formatDate(
    setMonth(new Date(), monthNumber - 1),
    isDownXs ? DateTimeFormats.MONTH_ONLY_SHORT : DateTimeFormats.MONTH_ONLY,
  ) || '';
};

export const getBoundaries = (monthCount: number, inclusive: boolean): DateBoundaries => {
  const now = new Date();

  const startMonthDelta = monthCount - (inclusive ? 1 : 0);
  const finishMonthDelta = inclusive ? 0 : 1;

  const start = {
    month: addMonths(now, -startMonthDelta).getMonth() + 1,
    year: addMonths(now, -startMonthDelta).getFullYear(),
  };

  const finish = {
    month: addMonths(now, -finishMonthDelta).getMonth() + 1,
    year: addMonths(now, -finishMonthDelta).getFullYear(),
  };

  return {
    start,
    finish,
  };
};

export const fillInGaps = (boundaries: DateBoundaries, monthPeriod: number) => (history: Array<EconomyAmountHistoryItem>) => {
  const { start } = boundaries;
  let historyIndex = 0;

  // NOTE: move history index to the "start"
  while (history[historyIndex]
    && (history[historyIndex].year < start.year
      || (history[historyIndex].year === start.year && history[historyIndex].month < start.month)
    )
  ) {
    historyIndex++;
  }

  return (new Array(monthPeriod))
    .fill(null)
    .map((_, index) => {
      const date = {
        month: start.month + index,
        year: start.year,
      };

      if (date.month > 12) {
        date.month %= 12;
        date.year++;
      }

      const historyItem = history[historyIndex] || {};

      if (date.year === historyItem.year && date.month === historyItem.month) {
        historyIndex++;
        return historyItem;
      }

      return {
        ...date,
        amount: 0,
      };
    });
};
