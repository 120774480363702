import { skipToken } from '@reduxjs/toolkit/query';

import { HIDSelectItem } from '../../../../../../../components/HIDFormSelect';
import { QueryHook } from '../../../../../../../types/common';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useGetBudgetsQuery } from '../api/api.budgets';
import { getBudgetName } from '../utils/utils.budget';

const useGetBudgetsList: QueryHook<Array<HIDSelectItem>> = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: budgets,
    ...rest
  } = useGetBudgetsQuery(
    propertyId ? { propertyId } : skipToken,
    {
      selectFromResult: ({ data: budgets = [], ...rest }) => ({
        data: budgets.map((budget) => ({
          id: budget.id,
          name: getBudgetName(budget),
        })),
        ...rest,
      }),
    },
  );

  return {
    data: budgets,
    ...rest,
  };
};

export default useGetBudgetsList;
