import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { SubscriptionFeature } from '../SubscriptionPlans/types.subscriptionPlans';

export enum EventReportSource {
  DATABASE = 'database',
  GA = 'ga',
  ALL = 'all',
}

export enum AnalyticsUserProperty {
  SUBSCRIPTION_PLAN = 'subscription_plan',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  IS_PREMIUM = 'is_premium',
  HAS_PROPERTY = 'has_property',
  HAS_MULTIPLE_PROPERTIES = 'has_multiple_properties',
  WAS_PREMIUM = 'was_premium',
  IS_ADMIN = 'is_admin',
  HAS_VERIFIED_PROPERTY = 'has_verified_property',
}

export type UserAnalyticsProperties = {
  dimensions: {
    p_01: string;
    p_02: string;
  };
  hasMultipleProperties: boolean;
  hasProperty: boolean;
  hasVerifiedProperty: boolean;
  isAdmin: boolean;
  isPremium: boolean;
  wasPremium: boolean;
};

export enum PartnersSource {
  HOUSE_ID = 'houseid',
  MOVERIA = 'moveria',
  MOVESTA = 'movesta',
  SF = 'svenskfast',
  ANTICIMEX = 'anticimex',
  IF = 'if',
  STEPLER = 'stepler',
  ORDNA = 'ordna',
}

export type HIDEventCategory = EntityType | SubscriptionFeature | HIDEventCustomCategory;

export enum HIDEventCustomCategory {
  SUBSCRIPTION_PLAN = 'subscription_plan',
  USER = 'user',
  AUTH = 'auth',
  ONBOARDING = 'onboarding',
  DYNAMIC_CONTENT = 'dynamic_content',
  FINANCE_COMMON = 'finance_common',
  SEARCH = 'search',
  ADMIN = 'admin',
  MOVE = 'move',
  FORUM = 'forum',
  PARTNERS = 'partners',
  SUPPORT = 'support',
  FEATURE_REQUEST = 'feature_request',
  DEFAULT = 'default',
}

export enum AnalyticsEvent {
  // CREATE_PROPERTY_ID = 'create_property_id',
  // CONFIRM_PROPERTY_ID = 'confirm_property_id',
  SIGN_UP = 'sign_up',
  DELETE_ACCOUNT = 'delete_account',
  // SAVE_ARTICLE = 'save_article',
  CLICK_ARTICLE = 'click_article',
  // CLICK_ARTICLE_HEART = 'click_article_heart',
  OPEN_PRODUCT_LINK = 'open_product_link',
  // OPEN_PRODUCT_PARTNER_LINK = 'open_product_partner_link',
  // SEND_PRODUCT_PARTNER_LEAD = 'send_product_partner_lead',
  CLICK_PRODUCT_ACCESSORY = 'click_product_accessory',
  SEARCH_FOR_PRODUCT = 'search_for_product',
  CREATE_PRODUCT = 'create_product',
  EXPERT_SERVICE_SUBMIT_FORM = 'expert_service_submit_form',
  // CLICK_BANNER = 'click_banner',
  // REDEEM_OFFER = 'redeem_offer',
  // OPEN_OFFER_LINK = 'open_offer_link',
  // OPEN_EXPERT_LINK = 'open_expert_link',
  // OPEN_EXPERT_PRODUCT_OFFER_LINK = 'open_expert_product_link',
  // CLICK_EXPERT = 'click_expert',
  // CLICK_EXPERT_EMAIL = 'click_expert_email',
  // CLICK_EXPERT_MAP = 'click_expert_map',
  // CLICK_EXPERT_CALL = 'click_expert_call',
  // SHARE_EXPERT = 'share_expert',
  // RATE_EXPERT = 'rate_expert',
  // CLICK_EXPERT_ARTICLE = 'click_expert_article',
  // CLICK_EXPERT_NEWS = 'click_expert_news',
  // CLICK_EXPERT_ADS = 'click_expert_ads',
  // EXPERT_SERVICE_CLICK_BANNER = 'expert_service_click_banner',
  // EXPERT_SERVICE_OPEN_FORM = 'expert_service_open_form',
  // EXPERT_SERVICE_SUBMIT_FORM = 'expert_service_submit_form',
  SYNC_EXTERNAL_SERVICES = 'sync_external_services',
  // START_SYNC_INSURANCE = 'start_sync_insurance',
  // SYNC_INSURANCE_SUCCESS = 'sync_insurance_success',
  // SYNC_INSURANCE_FAILURE = 'sync_insurance_failure',
  // SYNC_FIXED_EXPENSE_START = 'sync_fixed_expense_start',
  // SYNC_FIXED_EXPENSE_SUCCESS = 'sync_fixed_expense_success',
  // SYNC_FIXED_EXPENSE_FAILURE = 'sync_fixed_expense_failure',
  SCAN_RECEIPT = 'scan_receipt',
  CREATE_RECEIPT = 'create_receipt',
  CREATE_DOCUMENT = 'create_document',
  CREATE_PHOTO = 'create_photo',
  CREATE_NOTE = 'create_note',
  // START_SYNC_MORTGAGES = 'start_sync_mortgages',
  // // SYNC_MORTGAGES_SUCCESS = 'sync_mortgages_success',
  // // SYNC_MORTGAGES_FAILURE = 'sync_mortgages_failure',
  // OPEN_FINANCE_INTEREST_RATES = 'open_finance_interest_rates',
  RECOMMEND_FRIENDS = 'recommend_friends',
  READ_ABOUT_US = 'read_about_us',
  READ_POLICY_AND_INTEGRITY = 'read_policy_and_integrity',
  CREATE_TIMELINE_CUSTOM_EVENT = 'create_custom_event_item',
  // START_SYNC_SIMPLE_VALUATION = 'start_sync_simple_valuation',
  // SYNC_SIMPLE_VALUATION_FAILURE = 'sync_simple_valuation_failure',
  // SYNC_SIMPLE_VALUATION_SUCCESS = 'sync_simple_valuation_success',
  READ_MORE_ABOUT_MOVESTA = 'read_more_about_movesta',
  // START_SYNC_INDICATIVE_VALUATION = 'start_sync_indicative_valuation',
  // SYNC_INDICATIVE_VALUATION_FAILURE = 'sync_indicative_valuation_failure',
  // SYNC_INDICATIVE_VALUATION_SUCCESS = 'sync_indicative_valuation_success',
  // START_SYNC_BID_VALUATION = 'start_sync_bid_valuation',
  // SYNC_BID_VALUATION_FAILURE = 'sync_bid_valuation_failure',
  // SYNC_BID_VALUATION_SUCCESS = 'sync_bid_valuation_success',

  // PROPERTY_CHECK_NOT_BOOKED_READ_MORE_BUTTON = 'property_check_not_booked_read_more',
  // START_TRANSFER_PROPERTY = 'start_transfer_property',
  // CONFIRM_TRANSFER_PROPERTY = 'confirm_transfer_property',
  // BOOK_VALUATION_MEETING_CLICK = 'book_valuation_meeting_click',
  // BOOK_VALUATION_MEETING_SENT_FORM = 'book_valuation_meeting_sent_form',
  READ_MORE_VARUDEKLARERAT = 'read_more_varudeklarerat',
  // READ_MORE_ABOUT_SF = 'read_more_about_SF',
  REPORT_DAMAGE_INSURANCE = 'report_damage_insurance',
  // ORDNA_CHALLENGE_INTEREST_RATE = 'ordna_challenge_interest_rate',
  // ORDNA_CHALLENGE_INTEREST_RATE_START = 'ordna_challenge_interest_rate_start',
  ORDNA_CHALLENGE_INTEREST_READ_MORE = 'ordna_challenge_interest_read_more',
  // OPEN_APP_WITH_UTM_PARAMETERS = 'open_app_with_utm_params',
  OPEN_APP_FROM_WEB_APP = 'open_app_from_web_app',
  CREATE_CONTENT_FILE = 'create-content_file',
  OPEN_OFFER = 'open_offer',
  OPEN_OFFER_LINK = 'open_offer_link',
  REDEEM_OFFER = 'redeem_offer',

  OPEN_SUBSCRIPTION_OFFER = 'open_subscription_offer',
  OPEN_SUBSCRIPTION_FEATURE_OFFER_MODAL = 'open_subscription_feature_offer_modal',
  SUBSCRIPTION_FEATURE_OFFER_GO_TO_PLANS = 'subscription_feature_offer_go_to_plans',
  SUBSCRIPTION_USER_SETTINGS_GO_TO_PLANS = 'subscription_user_settings_go_to_plans',
  SUBSCRIPTIONS_ACTIVATE_PLAN = 'subscriptions_activate_plan',
  SUBSCRIPTIONS_ACTIVATE_PLAN_COMPLETED = 'subscriptions_activate_plan_completed',
  SUBSCRIPTIONS_ACTIVATE_PLAN_ERROR = 'subscriptions_activate_plan_error',
  SUBSCRIPTIONS_READ_MORE_ABOUT_PLAN = 'subscriptions_read_more_about_plan',
  SUBSCRIPTION_TRY_FEATURE_TRIAL = 'subscription_try_feature_trial',
  SUBSCRIPTION_FEATURE_DISABLED = 'subscription_feature_disabled',

  REQUEST_FOR_FEATURE = 'request_for_feature',
}
