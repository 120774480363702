import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HIDLogoIcon: Icon = ({
  iconColor: colorProp,
  fillColor: fillColorProp,
  size = 32,
  className,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.warning.main;

  return (
    <HIDSvgIcon
      className={className}
      size={size}
      viewBox="0 0 32 32"
    >
      <path
        clipRule="evenodd"
        d="M17.6422 3.00464C16.6651 2.27572 15.3084 2.2758 14.3316 3.00458L4.15874 10.5928C4.15868 10.5928 4.1588 10.5927 4.15874 10.5928C3.48128 11.0984 3.09115 11.8793 3.09115 12.6991V26.8894C3.09115 28.3319 4.28728 29.542 5.81394 29.542H26.1596C27.6865 29.542 28.8825 28.3318 28.8825 26.8894V12.6991C28.8825 11.8793 28.4925 11.0985 27.8148 10.5929L17.6422 3.00464ZM19.1058 1.03266C17.26 -0.344174 14.7136 -0.344265 12.8679 1.03265C12.8679 1.03268 12.868 1.03263 12.8679 1.03265L2.69503 8.6209C1.40533 9.58329 0.639404 11.0907 0.639404 12.6991V26.8894C0.639404 29.7347 2.9789 32 5.81394 32H26.1596C28.9947 32 31.3343 29.7348 31.3343 26.8894V12.6991C31.3343 11.0907 30.5685 9.58333 29.2785 8.62094L19.1058 1.03266C19.1058 1.03267 19.1058 1.03266 19.1058 1.03266Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.9868 15.2728C13.823 15.2728 12.0674 17.0289 12.0674 19.1969C12.0674 21.3648 13.823 23.121 15.9868 23.121C18.1504 23.121 19.9062 21.3648 19.9062 19.1969C19.9062 17.0289 18.1504 15.2728 15.9868 15.2728ZM9.59204 19.1969C9.59204 15.6634 12.454 12.7969 15.9868 12.7969C19.5194 12.7969 22.3816 15.6634 22.3816 19.1969C22.3816 22.7304 19.5194 25.5969 15.9868 25.5969C12.454 25.5969 9.59204 22.7304 9.59204 19.1969Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M24.9396 11.8422C24.9396 12.7258 24.2238 13.4422 23.341 13.4422C22.458 13.4422 21.7422 12.7258 21.7422 11.8422C21.7422 10.9586 22.458 10.2422 23.341 10.2422C24.2238 10.2422 24.9396 10.9586 24.9396 11.8422Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </HIDSvgIcon>
  );
};

export default HIDLogoIcon;
