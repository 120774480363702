import React, {
  FC,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DeleteOutline,
  EditOutlined,
  ThumbDownAltRounded,
  ThumbDownOffAltRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  HIDBlobModify,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';

import {
  ForumAuthorType,
  ForumReply,
  ForumThread,
  ForumVote,
} from '../../../types.forum';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import {
  FCC,
} from '../../../../../types/common';
import HIDTag from '../../../../../components/HIDTag';
import HIDButton from '../../../../../components/buttons/HIDButton';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import {
  useCreateBlobUploadUrlMutation,
  useDeleteForumReplyVoteMutation,
  useDeleteReplyMutation,
  useUpdateForumReplyVoteMutation,
  useUpdateReplyMutation,
  useUpdateThreadMutation,
  useUploadFileBlobMutation,
} from '../../../api/api.forum';
import ForumAuthorAvatar from '../../../components/ForumAuthorAvatar';
import { useGetForumAuthorName } from '../../../utils/forumAuthor';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import { Check } from '../../../../../components/icons/Icons';
import { getUserName } from '../../../../../utils/string';
import HIDTypography from '../../../../../components/HIDTypography';
import ForumTextEditor from './ForumTextEditor';
import ForumImageAttachments from '../../../components/ForumImageAttachments';
import HIDFilePickerButton from '../../../../../components/filePicker/HIDFilePickerButton';
import { ImageMimeTypes } from '../../../../../constants/mimeTypes';
import { getIsTypeOf } from '../../../../../utils/object';
import { getActiveAuth } from '../../../../../external-services/firebase';
import { useGetTokenDataQuery } from '../../../../Auth/api/api.user';

const getIsFile = getIsTypeOf<File>('arrayBuffer');

type VoteSectionProps = {
  vote?: ForumVote;
  score?: number;
  isDisabled: boolean;
  onVote: (vite: ForumVote) => void;
};

const VoteSection: FC<VoteSectionProps> = ({
  vote,
  score = 0,
  isDisabled,
  onVote,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['forum']);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={1}
    >
      <HIDIconButton
        Icon={vote === ForumVote.UP_VOTE ? ThumbDownAltRounded : ThumbDownOffAltRounded}
        disabled={isDisabled}
        sx={{
          transform: 'rotate(180deg)',
          color: theme.palette.primary.main,
        }}
        title={t('forum:forum_vote_up')}
        onClick={() => onVote(ForumVote.UP_VOTE)}
      />
      <Typography variant="h4">{score}</Typography>
      <HIDIconButton
        Icon={vote === ForumVote.DOWN_VOTE ? ThumbDownAltRounded : ThumbDownOffAltRounded}
        disabled={isDisabled}
        sx={{
          color: theme.palette.primary.main,
        }}
        title={t('forum:forum_vote_down')}
        onClick={() => onVote(ForumVote.DOWN_VOTE)}
      />
    </Stack>
  );
};

type ForumThreadReplyProps = {
  reply: ForumReply;
  thread: ForumThread;
  isAccepted?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const ForumThreadReply: FCC<ForumThreadReplyProps> = ({
  reply,
  thread,
  isAccepted = false,
  isDisabled = false,
  isLoading = false,
  style,
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forum']);

  const {
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const isExpert = reply.author.type === ForumAuthorType.EXPERT;

  const [updateThread, { isLoading: isThreadUpdating }] = useUpdateThreadMutation();

  const [updateReply] = useUpdateReplyMutation();
  const [isReplyUpdating, setIsReplyUpdating] = useState(false);
  const [deleteReply, { isLoading: isReplyDeleting }] = useDeleteReplyMutation();

  const [updateVote, { isLoading: isVoteUpdating }] = useUpdateForumReplyVoteMutation();
  const [deleteVote, { isLoading: isVoteDeleting }] = useDeleteForumReplyVoteMutation();

  const [createUploadFileContainer] = useCreateBlobUploadUrlMutation();
  const [uploadBlob] = useUploadFileBlobMutation();

  const isVoteLoading = isVoteUpdating || isVoteDeleting;

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const [myVote, setMyVote] = useState<ForumVote | undefined>(reply.me?.vote);
  const [isEditMode, setIsEditMode] = useState(false);
  const [replyBody, setReplyBody] = useState(reply.body);
  const [replyAttachments, setReplyAttachments] = useState<Array<HIDBlobModify | File>>(reply.blobs || []);

  const handleChangeAcceptedReply = (replyId: string, isAccepted: boolean) => {
    updateThread({
      id: reply.threadId,
      acceptedAnswerId: isAccepted ? replyId : undefined,
    });
  };

  const handleVote = (vote: ForumVote) => {
    if (vote && myVote === vote) {
      setMyVote(undefined);
      deleteVote({ threadId: reply.threadId, replyId: reply.id });
    } else {
      setMyVote(vote);
      updateVote({ threadId: reply.threadId, replyId: reply.id, vote });
    }
  };

  const handleDelete = () => deleteReply({ threadId: reply.threadId, id: reply.id });

  const handleUploadFiles = async (files: Array<File>) => {
    const containers = await createUploadFileContainer({ files }).unwrap();

    return Promise.all(
      containers.map(
        async (container, index) => {
          await uploadBlob({
            signedUploadUrl: container.signedUploadUrl,
            file: files[index],
            type: files[index].type,
          });

          return container.id;
        },
      ),
    );
  };

  const handleSaveReply = async () => {
    setIsReplyUpdating(true);

    const [fileAttachment, blobAttachment] = R.partition(getIsFile, replyAttachments || []);

    const leftReplyBlobs = blobAttachment;
    const threadFiles = fileAttachment;
    const newReplyBlobIds = await handleUploadFiles(threadFiles);

    updateReply({
      id: reply.id,
      threadId: reply.threadId,
      body: replyBody,
      blobs: [
        ...R.difference(reply.blobs || [], leftReplyBlobs || [])
          .map((blob) => ({ ...blob, action: ModifyActionType.DELETE })),
        ...newReplyBlobIds
          .map((blobId) => ({ id: blobId, action: ModifyActionType.CREATE } as HIDBlobModify)),
      ],
    })
      .unwrap()
      .then((updatedReply) => {
        setIsEditMode(false);
        setReplyBody(updatedReply.body);
        setReplyAttachments(updatedReply.blobs || []);
      })
      .finally(() => setIsReplyUpdating(false));
  };

  const getForumAuthorName = useGetForumAuthorName();
  const fullName = getUserName(getForumAuthorName(reply.author));

  const handleRemoveReplyAttachment = (indexToRemove: number) =>
    setReplyAttachments((replyAttachments.filter((_attachment, index) => index !== indexToRemove)));

  const forumCheckMarkHoverTitle = t('forum:forum_accepted_as_best_reply');

  const canEdit = reply.author.me || tokenData?.isAdmin;

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      spacing={2}
      style={style}
      sx={sx}
    >
      {!isDownMd && (
        <ForumAuthorAvatar author={reply.author} isLoading={isLoading} />
      )}
      <Stack
        spacing={2}
        sx={{
          flexGrow: 1,
          borderRadius: '8px',
          backgroundColor: isExpert ? theme.palette.warning.lightest : theme.palette.grey[50],
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ padding: theme.spacing(2, 2, 0, 2) }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            {
              isDownMd
                ? <ForumAuthorAvatar showLabel author={reply.author} isLoading={isLoading} />
                : <HIDTypography isLoading={isLoading} skeletonWidth={60} variant="subtitle1">{fullName}</HIDTypography>
            }
            {isExpert && (
              <HIDTag color={theme.palette.warning.light} label={t('forum:forum_expert')} />
            )}
          </Stack>
          <HIDTypography
            isLoading={isLoading}
            skeletonWidth={80}
            sx={{
              color: theme.palette.grey[500],
              textAlign: isDownSm ? 'right' : 'left',
            }}
          >
            {formatDate(new Date(reply.createdAt), DateTimeFormats.DATE_AND_TIME)}
          </HIDTypography>
        </Stack>
        <Stack style={{ margin: theme.spacing(0, 2) }}>
          {
            isEditMode
              ? (
                <ForumTextEditor
                  sx={{ marginTop: 2 }}
                  value={replyBody}
                  onChange={(value) => setReplyBody(value)}
                />
              )
              // eslint-disable-next-line react/no-danger
              : <span dangerouslySetInnerHTML={{ __html: reply.body }} />
          }
        </Stack>
        <Stack style={{ margin: theme.spacing(0, 2) }}>
          {replyAttachments.length > 0 && (
            <ForumImageAttachments
              attachments={replyAttachments}
              isEditable={isEditMode}
              sx={{ marginTop: 1, marginBottom: 2 }}
              onRemove={(_attachment, index) => handleRemoveReplyAttachment(index)}
            />
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ marginTop: isEditMode ? 0 : theme.spacing(1) }}
          sx={{ padding: theme.spacing(2) }}
        >
          <Stack
            alignItems="center"
            flexDirection="row"
            spacing={1}
            style={{ marginRight: 'auto' }}
          >
            {
              isEditMode
                ? isDownLg
                  ? (
                    <HIDIconButton
                      Icon={DeleteOutline}
                      color="red"
                      disabled={isDisabled || isReplyUpdating}
                      title={t('common:delete_label')}
                      onClick={handleDelete}
                    />
                  )
                  : (
                    <HIDButton
                      color="red"
                      disabled={isDisabled || isReplyUpdating}
                      loading={isReplyDeleting}
                      size="small"
                      onClick={handleDelete}
                    >
                      {t('common:delete_label')}
                    </HIDButton>
                  )
                : (
                  <VoteSection
                    isDisabled={isDisabled || isVoteLoading}
                    score={reply.score}
                    vote={myVote}
                    onVote={handleVote}
                  />
                )
            }
          </Stack>
          {
            isEditMode
              ? (
                <Stack alignItems="center" direction="row" spacing={1.5}>
                  <HIDFilePickerButton
                    checkForStorageLimit={false}
                    color="secondary"
                    disabled={isDisabled || isReplyUpdating}
                    mimeTypes={ImageMimeTypes}
                    size="small"
                    onFilesSelected={(newAttachments) => setReplyAttachments(replyAttachments.concat(newAttachments))}
                  />
                  <HIDButton
                    color="secondary"
                    disabled={isDisabled || isReplyUpdating}
                    size="small"
                    onClick={() => setIsEditMode(false)}
                  >
                    {t('common:cancel')}
                  </HIDButton>
                  <HIDButton
                    color="primary"
                    disabled={isDisabled || !replyBody.length}
                    loading={isReplyUpdating}
                    size="small"
                    onClick={handleSaveReply}
                  >
                    {t('common:save')}
                  </HIDButton>
                </Stack>
              )
              : (
                canEdit
                  ? (
                    <Stack alignItems="center" direction="row" spacing={1.5}>
                      {
                        isDownSm
                          ? (
                            <HIDIconButton
                              Icon={EditOutlined}
                              color="secondary"
                              disabled={isDisabled || isReplyDeleting}
                              title={t('common:update_label')}
                              onClick={() => setIsEditMode(true)}
                            />
                          )
                          : (
                            <HIDButton
                              color="primary"
                              disabled={isDisabled}
                              loading={isReplyDeleting}
                              size="small"
                              onClick={() => setIsEditMode(true)}
                            >
                              {t('common:update_label')}
                            </HIDButton>
                          )
                      }
                    </Stack>
                  )
                  : isAccepted && !thread.author.me
                    ? (
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.25}
                        title={forumCheckMarkHoverTitle}
                      >
                        {!isDownXs && (
                          <Typography sx={{ color: theme.palette.primary.main }}>
                            {t('forum:forum_best_reply')}
                          </Typography>
                        )}
                        <Check
                          fillColor={theme.palette.primary.main}
                          iconColor={theme.palette.common.transparent}
                          strokeWidth={1}
                        />
                      </Stack>
                    )
                    : null
              )
          }
          {Boolean(thread.author.me) && !isEditMode && (
            <HIDButton
              Icon={
                isAccepted && !isDownSm
                  ? () => <Check fillColor={theme.palette.common.white} iconColor={theme.palette.common.white} />
                  : undefined
              }
              color={isAccepted ? 'primary' : 'secondary'}
              disabled={isDisabled}
              loading={isThreadUpdating}
              size="small"
              onClick={() => handleChangeAcceptedReply(reply.id, !isAccepted)}
            >
              {t('forum:forum_best_reply')}
            </HIDButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForumThreadReply;
