import React, { FC } from 'react';
import * as R from 'ramda';
import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { HIDDocumentWithType } from '../../Content/modules/DynamicContent/types/types.dynamicContent';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { getDocumentPath } from '../../Content/modules/Document/navigation.document';
import PropertyProgressContentItem from './PropertyProgressContentItem';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';

type PropertyDocumentsListProps = {
  propertyDocuments?: Array<HIDDocumentWithType>;
  title?: string,
};

const PropertyDocumentsList: FC<PropertyDocumentsListProps> = ({
  propertyDocuments,
  title = '',
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateToDocument = (documentId: string) =>
    navigate(getPathWithPropertyIdOrInit(getDocumentPath, { id: documentId, propertyId }));

  return propertyDocuments?.length
    ? (
      <Stack spacing={1}>
        {Boolean(title) && (
          <Typography
            variant="subtitle1"
          >
            {title}
          </Typography>
        )}
        <Grid
          container
          spacing={1.5}
          xxs={12}
        >
          {
            propertyDocuments.map((document) => (
              <Grid
                item
                key={document.id}
                md={6}
                xxs={12}
              >
                <PropertyProgressContentItem
                  blob={R.head(document.blobs || [])}
                  description={document.classificationType?.name}
                  key={document.id}
                  title={document.name}
                  onNavigate={() => handleNavigateToDocument(document.id)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Stack>
    )
    : null;
};
export default PropertyDocumentsList;
