import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
} from 'react-router';

import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import HIDButton from '../../../../../components/buttons/HIDButton';
import RouteNames from '../../../../../routes/RouteNames';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import useGetSubscriptionPlanTrialPeriodInfo from '../../../../SubscriptionPlans/hooks/useGetSubscriptionTrialPeriodInfo';
import { useGetRemainingTimeLabel } from '../../../../../utils/translation';

const TrialPeriodHeaderBanner: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation(['home', 'common']);

  const { isDownSm } = useBreakPointsSizes();

  const {
    data: {
      isExpired,
      durationDays,
      remainingTime,
    } = {},
  } = useGetSubscriptionPlanTrialPeriodInfo();

  const isSubscriptionsRoute = location.pathname.includes(RouteNames.SUBSCRIPTION_PLANS);

  const [openStartTrialPeriodDialog] = useDialog(DialogNames.START_TRIAL_PERIOD_DIALOG);

  const handleReadMore = () => openStartTrialPeriodDialog({ trialPeriodDays: durationDays });

  const handleNavigate = () => navigate(RouteNames.SUBSCRIPTION_PLANS);

  const getTrialPeriodText = (remainingTimeValue: number, timeLeft: string) => remainingTimeValue > 1
    ? t('home:home_trial_period_time_left_plural', { timeLeft })
    : t('home:home_trial_period_time_left', { timeLeft });

  const getRemainingTimeLabel = useGetRemainingTimeLabel();
  const trialPeriodLabel = remainingTime ? getTrialPeriodText(remainingTime.value, getRemainingTimeLabel(remainingTime)) : '';

  return (
    <Stack
      alignItems="center"
      direction={isDownSm ? 'column' : 'row'}
      gap={isDownSm ? 1 : 2}
      justifyContent="center"
      sx={{
        width: 'inherit',
        height: 'inherit',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <Typography variant="body1">
        {
          isExpired
            ? t('home:home_trial_period_expired', { days: durationDays })
            : trialPeriodLabel
        }
      </Typography>
      <Stack direction="row" spacing={2}>
        <HIDButton color="secondary" size="small" onClick={handleReadMore}>
          {t('common:read_more')}
        </HIDButton>
        {
          !isSubscriptionsRoute && !isExpired && (
            <HIDButton size="small" onClick={handleNavigate}>
              {t('home:home_trial_period_start_upgrade_label')}
            </HIDButton>
          )
        }
      </Stack>
    </Stack>
  );
};

export default TrialPeriodHeaderBanner;
