import React, {
  FC,
  useMemo,
} from 'react';
import {
  Skeleton,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import { getRandomBetween } from '../utils/random';
import { getTypographyHeight } from '../utils/style';

export type HIDTypographyProps = TypographyProps & {
  isLoading?: boolean;
  skeletonWidth?: number | string;
  numberOfSkeletonLines?: number;
};

const HIDTypography: FC<HIDTypographyProps> = ({
  isLoading,
  skeletonWidth,
  numberOfSkeletonLines = 1,
  children,
  variant,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const randomSkeletonWidth = useMemo(
    () =>
      R.times(() => skeletonWidth || `${getRandomBetween(40, 100)}%`, numberOfSkeletonLines),
    [numberOfSkeletonLines, skeletonWidth],
  );

  return isLoading
    ? (
      <>
        {
          randomSkeletonWidth.map((width, index) => (
            <Skeleton
              height={variant && variant !== 'inherit' ? getTypographyHeight(theme.typography[variant]) : undefined}
              key={`${width}${index}`}
              sx={{
                display: 'inline-block',
                ...sx,
              }}
              width={width}
            />
          ))
        }
      </>
    )
    : (
      <Typography sx={sx} variant={variant} {...rest}>
        {children}
      </Typography>
    );
};

export default HIDTypography;
