import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as R from 'ramda';

import { useNavigateBackOr } from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import useDialog from '../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../hooks/useDialog/DialogNames';
import BonusOffersQuickNavigation from '../../components/BonusOffersQuickNavigation';
import { getBonusOffersPath } from '../../navigation.bonusOffers';
import { BonusOffersQuickNavigationCategories } from '../../types.bonusOffers';
import MyTotalBonusPointsSection from './components/MyTotalBonusPointsSection';
import {
  Suggestion,
  CurlyCircleCheckMark,
} from '../../../../../../components/icons/Icons';
import { hidSpacing } from '../../../../../../utils/number';
import BonusPointsSection from './components/BonusPointsSection';
import BonusPointsProgress from './components/BonusPointsProgress';
import PurchaseCheapestOffer from './components/PurchaseCheapestOffer';
import { useGetCheapestBonusOffersQuery } from '../../api/api.bonusOffers';
import { useGetStatisticsQuery } from '../../../../api/api.statistics';
import { useGetPropertyStatusQuery } from '../../../../api/api.property';

const MyTotalBonusPoints: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['bonus_offers']);

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: cheapestOffers,
    isLoading: isLoadingCheapestBonusOffers,
  } = useGetCheapestBonusOffersQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: propertyStatus,
    isLoading: isLoadingPropertyStatus,
  } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);

  const { data: statistics, isLoading: isLoadingStatistics } = useGetStatisticsQuery();

  const cheapestOffer = R.head(cheapestOffers || []);
  const averagePoints = Math.round(statistics?.earnedBonusPointsAverage || 0);

  const propertyPoints = propertyStatus?.bonusPoints?.available || 0;
  const cheapestOfferPoints = cheapestOffer?.points;

  const ableToPurchaseCheapestOffer = typeof cheapestOfferPoints === 'number'
    ? propertyPoints >= cheapestOfferPoints
    : false;

  const [openMyTotalBonusPointsDialog] = useDialog(DialogNames.MY_TOTAL_BONUS_POINTS_DIALOG);

  const handleReadMore = () => openMyTotalBonusPointsDialog();
  const handleNavigateToBonusOffers = () => propertyId ? navigate(getBonusOffersPath({ propertyId })) : undefined;

  const redeemBonusSteps = [
    t('bonus_offers:bonus_offers_redeem_bonus_one'),
    t('bonus_offers:bonus_offers_redeem_bonus_two'),
    t('bonus_offers:bonus_offers_redeem_bonus_three'),
  ].map((text, index) => `${index + 1}. ${text}`).join('\n');

  const isLoading = isLoadingCheapestBonusOffers || isLoadingStatistics || isLoadingPropertyStatus;

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <BonusOffersQuickNavigation
            currentCategory={BonusOffersQuickNavigationCategories.MY_BONUS_POINTS}
            key={BonusOffersQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getBonusOffersPath, { propertyId }),
          name: t('bonus_offers:bonus_offers_bonus_offers'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <BonusOffersQuickNavigation
          currentCategory={BonusOffersQuickNavigationCategories.MY_BONUS_POINTS}
          key={BonusOffersQuickNavigation.name}
        />,
      ]}
      title={t('bonus_offers:bonus_offers_my_points')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack spacing={2.5}>
        <BonusPointsSection
          averagePoints={averagePoints}
          propertyPoints={propertyPoints || 0}
        />
        {ableToPurchaseCheapestOffer
          ? (
            <PurchaseCheapestOffer
              onClick={handleNavigateToBonusOffers}
            />
          )
          : (
            cheapestOfferPoints && (
              <BonusPointsProgress
                current={propertyPoints || 0}
                title={t('bonus_offers:bonus_offers_points_left')}
                total={cheapestOfferPoints}
              />
            )
          )}
        <MyTotalBonusPointsSection
          IconComponent={
            <CurlyCircleCheckMark
              iconColor={theme.palette.primary.main}
              size={hidSpacing(4)}
            />
          }
          buttonColor="primary"
          buttonTitle={t('bonus_offers:bonus_offers_your_offers')}
          description={redeemBonusSteps}
          iconBgColor={theme.palette.primary.lighter}
          isLoading={isLoading}
          numberOfSkeletonLines={4}
          title={t('bonus_offers:bonus_offers_redeem_bonus')}
          onClick={handleNavigateToBonusOffers}
        />
        <MyTotalBonusPointsSection
          IconComponent={
            <Suggestion
              fillColor={theme.palette.warning.main}
              iconColor={theme.palette.common.black}
              size={hidSpacing(4)}
            />
          }
          buttonColor="secondary"
          buttonTitle={t('bonus_offers:bonus_offers_read_more')}
          description={t('bonus_offers:bonus_program_explanation_text')}
          iconBgColor={theme.palette.primary.lighter}
          isLoading={isLoading}
          numberOfSkeletonLines={7}
          title={t('bonus_offers:bonus_program_explanation_title')}
          onClick={handleReadMore}
        />
      </Stack>
    </HomeLayout>
  );
};

export default MyTotalBonusPoints;
