import React from 'react';
import {
  Skeleton,
  Stack,
  Box,
  Divider,
  useTheme,
} from '@mui/material';

import {
  FCC,
} from '../../../../types/common';
import MembersLoaderSkeleton from '../../../../components/skeletonLoaders/MembersLoaderSkeleton';
import { getTypographyHeight } from '../../../../utils/style';

type PropertySettingsLoaderSkeletonProps = {
  itemSizes?: ReadonlyArray<number>;
  isLoading: boolean;
};

const defaultItemSizes = [21, 30, 15];

const PropertySettingsLoaderSkeleton: FCC<PropertySettingsLoaderSkeletonProps> = ({
  itemSizes = defaultItemSizes,
  isLoading,
  children,
}) => {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Stack spacing={2} sx={{ marginTop: theme.spacing(2) }}>
        {itemSizes.map((size, i) => (
          <Box
            key={size + i}
            sx={{
              padding: 2,
              borderRadius: theme.spacing(1.25),
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Stack alignItems="center" direction="row" spacing={2}>
              <Skeleton
                height={44}
                variant="circular"
                width={44}
              />
              <Skeleton
                height={getTypographyHeight(theme.typography.body2)}
                variant="rounded"
                width={`${size}%`}
              />
            </Stack>
            <Divider sx={{ margin: theme.spacing(2, 0) }} />
            <MembersLoaderSkeleton isLoading />
          </Box>
        ))}
      </Stack>
    );
  }

  return children;
};

export default PropertySettingsLoaderSkeleton;
