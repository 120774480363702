import { PropertySource } from '@house-id/houseid-types/dist/property';

import useCurrentProperty from './useCurrentProperty';

const useIsSFProperty = () => {
  const { data: property } = useCurrentProperty();
  return property?.source?.toLowerCase() === PropertySource.SVENSKFAST.toLowerCase();
};

export default useIsSFProperty;
