import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';
import { PropertyIdNavigation } from '../../Property/types/types.property';
import { SupportTicketCategoryId } from '../../Property/types/types.support';

export const getLoginPath = () => generateRoutePath(RouteNames.LOGIN);
export const getSignUpPath = () => generateRoutePath(RouteNames.SIGN_UP);
export const getLoginWithSecondFactorPath = () => generateRoutePath(RouteNames.LOGIN_SECOND_FACTOR);

type FeedbackNavigation = { categoryId?: SupportTicketCategoryId };
export const getFeedbackPath = ({ propertyId, categoryId }: PropertyIdNavigation & FeedbackNavigation) =>
  generateRoutePath(RouteNames.FEEDBACK, { propertyId }, { categoryId });

export const getFeedbackOnboardingPath = (params?: FeedbackNavigation) =>
  generateRoutePath(RouteNames.FEEDBACK_ONBOARDING, {}, { categoryId: params?.categoryId });

export const getUserSettingsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(RouteNames.USER_SETTINGS, { propertyId });
export const getUserSettingsOnboardingPath = () => generateRoutePath(RouteNames.USER_SETTINGS_ONBOARDING);

export const getResetPasswordPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(RouteNames.RESET_PASSWORD, { propertyId });
export const getResetPasswordOnboardingPath = () => generateRoutePath(RouteNames.RESET_PASSWORD_ONBOARDING);

export const getUserDetailsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(RouteNames.USER_DETAILS, { propertyId });
export const getUserDetailsOnboardingPath = () => generateRoutePath(RouteNames.USER_DETAILS_ONBOARDING);

export const getInitAppPath = () => generateRoutePath(RouteNames.INIT, {});

export const getOnboardingPath = () => generateRoutePath(RouteNames.ONBOARDING, {});

export const getAboutPath = () => generateRoutePath(RouteNames.ABOUT);

export const getInviteCodePath = (code: string) => generateRoutePath(RouteNames.INVITE_CODE, { code });

export const getPathWithPropertyIdOrInit = <T>(
  getNavigatePath: (params: T) => string,
  params: Partial<T & { propertyId?: string }>,
) => params.propertyId ? getNavigatePath(params as T) : getInitAppPath();
