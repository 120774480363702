import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EMPTY_VALUE } from '../../../../../../utils/string';

type AnnotationSectionProps = {
  entity: { annotation?: string };
};

const AnnotationSection: FC<AnnotationSectionProps> = ({
  entity,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <Stack
      sx={{
        padding: 2.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
      }}
    >
      <Typography variant="subtitle1">
        {t('common:annotation')}
      </Typography>
      <Typography sx={{ marginTop: 0.5 }} variant="body1">
        {entity.annotation || EMPTY_VALUE}
      </Typography>
    </Stack>
  );
};

export default AnnotationSection;
